import React from 'react'
import HomeContentList from './HomeContentList'
import { INSTANCE_TYPE_ARTICLE } from '../app/links'

const Research = () => {
  return (
    <>
      <HomeContentList state="PUBLISHED" type={INSTANCE_TYPE_ARTICLE} />
    </>
  )
}

export default Research
