import React from 'react'
import { Box } from '@mui/material'

import { Button, Typography } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const NavigateToControl = ({
  familyObj,
  pyramid,
  navigateToFamily,
  exploredIndividualNode,
  navigateToIndividual,
  displayName,
  handleForceCloseModal,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography>Close and Navigate to:</Typography>
    {familyObj &&
    familyObj.id !== pyramid?.fathersFamily?.family &&
    familyObj.id !== pyramid?.mothersFamily?.family ? (
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => navigateToFamily(familyObj.id, true)}
        color="primary"
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
      >
        {familyObj.surname} family
      </Button>
    ) : exploredIndividualNode ? (
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => navigateToIndividual(exploredIndividualNode, true)}
        color="primary"
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
      >
        {displayName}
      </Button>
    ) : null}
    {pyramid?.fathersFamily && (
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => navigateToFamily(pyramid.fathersFamily.family, true)}
        color="primary"
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
      >
        {pyramid.fathersFamily?.surname} family [Paternal]
      </Button>
    )}
    {pyramid?.mothersFamily && (
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => navigateToFamily(pyramid.mothersFamily.family, true)}
        color="primary"
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
      >
        {pyramid.mothersFamily?.surname} family [Maternal]
      </Button>
    )}
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      onClick={handleForceCloseModal}
      color="secondary"
      variant="outlined"
      size="small"
      sx={{ mt: 1 }}
    >
      Cancel
    </Button>
  </Box>
)

export default NavigateToControl
