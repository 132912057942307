import React from 'react'
import {
  Box,
  IconButton as MuiIconButton,
  CircularProgress,
  styled,
} from '@mui/material'
import { usePermissions } from '../auth/authHooks'
import { PERMISSION_DENIED_TYPE_ERROR } from '../auth/permissionUtils'
import PropTypes from 'prop-types'

const StyledButton = styled(MuiIconButton, {
  shouldForwardProp: prop => prop !== 'white',
})(({ white, theme }) => ({
  background: 'rgba(255, 255, 255, 0.7)',
  ...(white && {
    background: 'white',
    boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 10%)',
  }),
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.7)',
    ...(white && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }),
  },
}))
const StyledButtonNoBackground = styled(MuiIconButton, {
  shouldForwardProp: prop => prop !== 'white',
})(({ theme }) => ({
  background: 'none',
  '&:hover': {
    background: 'none',
  },
}))

const ButtonProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.secondary.main,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}))

const IconButton = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    isLoading,
    color = 'primary',
    handleSubmit,
    innerRef,
    noBackground = false,
    white = false,
    permissionAction,
    permissionParams,
    ...rest
  } = props
  const checkPermissions = usePermissions()
  const { hasPermission, permissionDeniedType } = checkPermissions(
    permissionAction,
    permissionParams
  )

  if (permissionDeniedType === PERMISSION_DENIED_TYPE_ERROR) {
    //console.log('PERMISSION', 'IconButton', permissionDeniedType)
  }

  let ButtonComponent = noBackground ? StyledButtonNoBackground : StyledButton

  let isDisabled
  if (!hasPermission) {
    isDisabled = true
  } else if (rest.disabled !== undefined) {
    isDisabled = rest.disabled
  } else {
    isDisabled = false
  }

  const button = (
    <ButtonComponent
      color={color}
      onClick={handleSubmit}
      size="medium"
      className={className}
      white={white}
      ref={ref}
      {...rest}
      disabled={isDisabled}
    >
      {children}
    </ButtonComponent>
  )

  if (isLoading) {
    return (
      <Box sx={{ display: 'inline-flex', position: 'relative' }}>
        {button}
        {isLoading && <ButtonProgress size={24} />}
      </Box>
    )
  } else {
    return button
  }
})

IconButton.propTypes = {
  permissionAction: PropTypes.string.isRequired,
}

export default IconButton
