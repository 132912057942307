import React, { useCallback, useEffect, useState, useRef } from 'react'
import { makeStyles } from '@mui/styles'

import { Button } from 'src/modules/ui'
import TagForm from 'src/modules/writeArticle/TagForm'
import CreateCroppedPhotoDialog from './ImageCropper'
import { ACCEPT_UPLOAD_ALL, ACCEPT_UPLOAD_IMAGE, MEDIA_TYPE_PHOTO } from '.'
import MediaDropzone from './MediaDropzone'
import { useUploadMedia } from './hooks'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import { UploadMediaText } from './CreateMultiple'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const useStyles = makeStyles(theme => ({
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subTitle: { marginBottom: theme.spacing(1) },
  tagForm: {
    marginTop: theme.spacing(4),
  },
}))

const UploadPhoto = ({
  aspectRatio,
  cropAfterSelect,
  onSelect,
  onSelectCropped,
  presetTargets = [],
  subject,
  mediaType,
  youtubePreview,
  hideTags = false,
  defaultAllowNoTags = false,
  setCategory,
}) => {
  const classes = useStyles()
  const [previewTiles, setPreviewTiles] = useState([])
  const [targets, setTargets] = useState(presetTargets)
  const [photoToCrop, setPhotoToCrop] = useState(null)
  const { uploadMedia, uploading } = useUploadMedia()

  const handleChangeTags = tags => {
    setTargets(tags)
  }

  const noTagsDialogRef = useRef()

  const upload = async (event = null, allowNoTags = defaultAllowNoTags) => {
    if (
      !hideTags &&
      !allowNoTags &&
      targets.length === 0 &&
      (!subject || subject.links.length === 0)
    ) {
      noTagsDialogRef.current.showDialog(async () => {
        upload(null, (allowNoTags = true))
      })
      return
    }

    const media = await uploadMedia({
      previewTiles,
      targets,
      setCategory,
    })
    if (media) {
      handleSelect(media[0])
    }
  }

  // The user has made their initial selection
  const handleSelect = media => {
    if (cropAfterSelect && media.type === MEDIA_TYPE_PHOTO) {
      setPhotoToCrop(media)
    } else {
      if (onSelect) {
        onSelect(media)
      }
    }
  }

  // The user decided not to use this photo
  const handleCroppingCancelled = photo => {
    setPhotoToCrop(null)
  }

  // The user cropped and saved a new cropped photo
  const handleDoneCropping = croppedPhoto => {
    setPhotoToCrop(null)
    if (onSelectCropped) {
      onSelectCropped(croppedPhoto)
    }
  }

  // The user decided not to crop the photo
  const handleDidNotCrop = photo => {
    setPhotoToCrop(null)
    if (onSelect) {
      onSelect(photo)
    }
  }

  const updatePreviewFiles = useCallback(() => {
    setPreviewTiles(prevState => [...prevState, youtubePreview])
  }, [youtubePreview])

  useEffect(() => {
    if (youtubePreview) {
      updatePreviewFiles()
    }
  }, [youtubePreview, updatePreviewFiles])

  return (
    <div>
      <NoTagsConfirmationDialog ref={noTagsDialogRef} />
      <MediaDropzone
        previewTiles={previewTiles}
        onChangePreviewTiles={setPreviewTiles}
        maxFiles={1}
        acceptFiles={
          mediaType === MEDIA_TYPE_PHOTO
            ? ACCEPT_UPLOAD_IMAGE
            : ACCEPT_UPLOAD_ALL
        }
      />
      <CreateCroppedPhotoDialog
        aspectRatio={aspectRatio}
        photo={photoToCrop}
        onDoneCropping={handleDoneCropping}
        onDidNotCrop={handleDidNotCrop}
        onCroppingCancelled={handleCroppingCancelled}
      />
      {previewTiles.length > 0 && !hideTags && (
        <div className={classes.tagForm}>
          <UploadMediaText />
          <TagForm
            onChangeTags={handleChangeTags}
            presetTargets={presetTargets}
            subject={subject}
          />
        </div>
      )}
      {previewTiles.length > 0 && (
        <div className={classes.actionContainer}>
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{ instanceType: INSTANCE_TYPE_MEDIA }}
            disabled={previewTiles.length === 0 ? true : false}
            color="primary"
            onClick={upload}
            size="large"
            loading={uploading}
          >
            Upload and Select
          </Button>
        </div>
      )}
    </div>
  )
}

export default UploadPhoto
