// Zoom defaults
export const INITIAL_ZOOM_NAVIGATOR_MODE = 0.9
export const INITIAL_ZOOM_EXPLORE_MODE = 1

// Viewer focus modes
export const FOCUS_MODE_TOP_OF_TREE = 'TOP_OF_TREE'
export const FOCUS_MODE_EXPLORE_INDIVIDUAL = 'EXPLORE_INDIVIDUAL'
export const FOCUS_MODE_EXPLORE_LINEAGE = 'EXPLORE_LINEAGE'
export const FOCUS_MODE_SCROLL = 'SCROLL'

// Viewer side modes
export const SIZE_MODE_FIT_NODES = 'FIT_NODES'
export const SIZE_MODE_FIT_SCREEN = 'FIT_SCREEN'

// Viewer constants
export const MIN_VIEWER_HEIGHT = 400
export const FLEXIBLE_HEIGHT_PADDING = 50

// Individuals
export const INDVDL_NODE_WIDTH = 65
export const INDVDL_NODE_PADDING = 6
export const INDVDL_NODE_GRID_WIDTH = 100
export const INDVDL_NODE_HEIGHT = 95
export const INDVDL_NODE_GRID_HEIGHT = 130
export const EXPLORED_INDIVIDUAL_SIDE_PADDING = 2

// Centre of node is 0, 0, so calculate top and left of area to draw in.
export const FAR_LEFT = -INDVDL_NODE_WIDTH / 2
export const FAR_TOP = -INDVDL_NODE_HEIGHT / 2
export const INDVDL_NODE_WIDTH_INNER =
  INDVDL_NODE_WIDTH - 2 * INDVDL_NODE_PADDING
export const INDVDL_NODE_HEIGHT_INNER =
  INDVDL_NODE_HEIGHT - 2 * INDVDL_NODE_PADDING
export const INNER_LEFT = -INDVDL_NODE_WIDTH_INNER / 2
export const INNER_TOP = -INDVDL_NODE_HEIGHT_INNER / 2

// Unions between individuals
export const UNION_NODE_HEIGHT = 10

// Links between nodes
export const LINK_STROKE_WIDTH = 2

// Types of things that can be added when editing tree
export const ADD_TYPE_SELF = 'self'
export const ADD_TYPE_MOTHER = 'mother'
export const ADD_TYPE_FATHER = 'father'
export const ADD_TYPE_GRAND_FATHER = 'grandfather'
export const ADD_TYPE_GRAND_MOTHER = 'grandmother'
export const ADD_TYPE_SIBLING = 'sibling'
export const ADD_TYPE_CHILD = 'child'
export const ADD_TYPE_SPOUSE = 'spouse'
