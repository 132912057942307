import { Box, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'

import { generateTreeLink } from 'src/modules/app/links'

const UpdateGedcomButton = ({ treeSlug }, props) => {
  const history = useHistory()

  const useStyles = makeStyles(theme => ({
    buttonContainer: {
      marginLeft: theme.spacing(1),
    },
  }))
  const classes = useStyles()

  return (
    <Box className={classes.buttonContainer}>
      <MenuItem
        {...props}
        onClick={() => {
          history.push(generateTreeLink(treeSlug, 'update-tree'))
        }}
      >
        Update from GEDCOM
      </MenuItem>
    </Box>
  )
}

export default UpdateGedcomButton
