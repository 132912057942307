import { min } from 'lodash'
import { sum } from 'lodash'

export const normalise = goal =>
  (goal.progress.progress * 100) / goal.progress.targetDuringPeriod
export const normaliseMax100 = goal => min([normalise(goal), 100])

export const progressColor = value => {
  if (value <= 25) {
    return 'error'
  }
  if (value >= 100) {
    return 'success'
  }
  return 'info'
}

export const overallProgressFromGoals = goals => {
  if (!goals?.length) {
    return 0
  }

  return sum(goals.map(normaliseMax100)) / goals.length
}
