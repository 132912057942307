import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@mui/material'
import Clamp from 'react-multiline-clamp'
import { NavLink } from 'react-router-dom'

import { Thumbnail } from 'src/modules/photo'
import { Typography } from 'src/modules/ui'

import { defaultImage } from 'src/modules/content/ArticleCard'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { generateLinkForObject } from '../app/links'
import { formatIndividualName } from '../ui/individualUtils'

const SummaryContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  alignSelf: 'center',
}))

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  overflowWrap: 'break-word',
  flexShrink: 1,
}))

export const PhotoAlbum = ({ content }) => {
  const { id, type } = content

  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const albumRoute = generateLinkForObject(treeSlug, type, id)
  return <PhotoAlbumCard {...{ content, albumRoute }} />
}

export const PhotoAlbumCard = ({
  content: { author, id, media, title },
  albumRoute,
}) => {
  const displayName = formatIndividualName(author)
  return (
    <StyledNavLink exact to={albumRoute}>
      <Box sx={{ m: 1, width: 271 }}>
        <Thumbnail
          file={media.length > 0 ? media[0].fileMedium : defaultImage}
          type={media[0]?.type}
        />
        <SummaryContainer>
          <Typography variant="h4">
            <Clamp lines={2}>{title}</Clamp>
          </Typography>
          <Typography variant="body1">{displayName}</Typography>
        </SummaryContainer>
      </Box>
    </StyledNavLink>
  )
}

PhotoAlbum.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
  onDelete: PropTypes.func,
  user: PropTypes.object,
}

const PhotoAlbumContainer = PhotoAlbum

export default PhotoAlbumContainer
