import { Link, processIndividualsAndUnionsToGraphNodes } from './graphNode'
import {
  createUnion,
  isUnknownIndividual,
  parentsSiblingsAndChildrenOfIndividual,
  unionForParentsOfIndividual,
  unionID,
} from './nodeDirectory'
import { d3TreeLayout } from './layout'
import { findGraphNode, makeXCoordinates0based, treeHeight } from './graphOps'
import AddIndividualNode from '../treeViewers/AddIndividualNode'
import {
  ADD_TYPE_CHILD,
  ADD_TYPE_FATHER,
  ADD_TYPE_GRAND_FATHER,
  ADD_TYPE_GRAND_MOTHER,
  ADD_TYPE_MOTHER,
  ADD_TYPE_SIBLING,
  ADD_TYPE_SPOUSE,
} from '../treeViewers/constants'

const copyUnion = unionNode => {
  return {
    ...unionNode,
    children: [...unionNode.children],
    between: [...unionNode.between],
  }
}

export const createEditIndividualGraph = (
  editedIndividualNode,
  nodeDirectory,
  addGrandParents = false
) => {
  let [visibleNodes, coreLineageIDs] = parentsSiblingsAndChildrenOfIndividual(
    nodeDirectory,
    editedIndividualNode
  )

  let rootId = editedIndividualNode.id
  const parentId =
    editedIndividualNode.bioFather || editedIndividualNode.bioMother

  if (parentId) {
    rootId = parentId
    visibleNodes = addCreateOtherParentNode(visibleNodes, editedIndividualNode)
    const [addSibling, modifiedUnion] = createAddSiblingNode(
      nodeDirectory,
      editedIndividualNode
    )
    visibleNodes = visibleNodes.filter(n => n.id !== modifiedUnion.id)
    visibleNodes.push(addSibling, modifiedUnion)
  } else {
    const [addParent, ...otherNodes] =
      createAddParentsNodes(editedIndividualNode)
    rootId = addParent.id
    visibleNodes.push(addParent, ...otherNodes)
  }

  visibleNodes = createAddChildNodes(
    editedIndividualNode,
    nodeDirectory,
    visibleNodes
  )

  const graphNodes = processIndividualsAndUnionsToGraphNodes(
    visibleNodes,
    coreLineageIDs
  )
  let rootGraphNode = findGraphNode(graphNodes, rootId)
  const [nodes, links] = d3TreeLayout(graphNodes, rootGraphNode)

  if (addGrandParents) {
    if (!isUnknownIndividual(editedIndividualNode.bioFather)) {
      const node = addGrandParentNodes(
        editedIndividualNode.bioFather,
        rootGraphNode,
        nodeDirectory,
        -1
      )
      nodes.push(node)
      links.push(new Link(node, rootGraphNode))
    }
    if (!isUnknownIndividual(editedIndividualNode.bioMother)) {
      const node = addGrandParentNodes(
        editedIndividualNode.bioMother,
        rootGraphNode,
        nodeDirectory,
        1
      )
      nodes.push(node)
      links.push(new Link(node, rootGraphNode))
    }
  }

  makeXCoordinates0based(nodes)
  for (const graphNode of nodes.filter(n => n?.hideInvisible)) {
    graphNode.hideInvisible()
  }
  return [nodes, links, treeHeight(nodes)]
}

const addGrandParentNodes = (parentId, rootGraphNode, nodeDirectory, side) => {
  const parent = nodeDirectory[parentId]
  const union = unionForParentsOfIndividual(parent, nodeDirectory)

  let visibleNodes, grandParentId
  if (union) {
    visibleNodes = [union, ...union.between.map(id => nodeDirectory[id])]
    visibleNodes = addCreateOtherParentNode(
      visibleNodes,
      parent,
      ADD_TYPE_GRAND_MOTHER,
      ADD_TYPE_GRAND_FATHER
    )
    grandParentId = parent.bioFather || parent.bioMother
  } else {
    visibleNodes = createAddParentsNodes(
      parent,
      ADD_TYPE_GRAND_MOTHER,
      ADD_TYPE_GRAND_FATHER
    )
    grandParentId = visibleNodes.filter(n => !n.isUnion)[0].id
  }
  visibleNodes.push(nodeDirectory[parentId])

  const graphNodes = processIndividualsAndUnionsToGraphNodes(visibleNodes)
  const grandParentGraphNode = findGraphNode(graphNodes, grandParentId)

  grandParentGraphNode.children = [rootGraphNode.id]
  grandParentGraphNode.y = rootGraphNode.y - 1
  grandParentGraphNode.x = rootGraphNode.x + side

  return grandParentGraphNode
}

const createAddParentsNodes = (
  childNode,
  motherTypeToAdd = ADD_TYPE_MOTHER,
  fatherTypeToAdd = ADD_TYPE_FATHER
) => {
  const addFather = new AddIndividualNode({
    typeToAdd: fatherTypeToAdd,
    addedFromIndividualNode: childNode,
  })
  const addMother = new AddIndividualNode({
    typeToAdd: motherTypeToAdd,
    addedFromIndividualNode: childNode,
  })
  const addMotherFatherUnion = createUnion(
    unionID(addFather.id, addMother.id),
    addFather,
    addMother,
    [childNode.id]
  )
  return [addFather, addMother, addMotherFatherUnion]
}

const addCreateOtherParentNode = (
  visibleNodes,
  childNode,
  motherTypeToAdd = ADD_TYPE_MOTHER,
  fatherTypeToAdd = ADD_TYPE_FATHER
) => {
  let typeToAdd, addIndividualId
  if (isUnknownIndividual(childNode.bioFather)) {
    typeToAdd = fatherTypeToAdd
    addIndividualId = childNode.bioFather
  } else if (isUnknownIndividual(childNode.bioMother)) {
    typeToAdd = motherTypeToAdd
    addIndividualId = childNode.bioMother
  } else {
    return visibleNodes
  }

  visibleNodes = visibleNodes.filter(n => n.id !== addIndividualId)
  visibleNodes.push(
    new AddIndividualNode({
      typeToAdd,
      addedFromIndividualNode: childNode,
      id: addIndividualId,
    })
  )
  return visibleNodes
}

const createAddChildNodes = (
  editedIndividualNode,
  nodeDirectory,
  visibleNodes
) => {
  for (const uID of editedIndividualNode.unions) {
    const modifiedUnion = copyUnion(nodeDirectory[uID])
    const addChild = new AddIndividualNode({
      typeToAdd: ADD_TYPE_CHILD,
      addedFromIndividualNode: editedIndividualNode,
      otherParent: modifiedUnion.between
        .filter(id => id !== editedIndividualNode.id)
        .map(id => nodeDirectory[id])[0],
    })
    modifiedUnion.children.push(addChild.id)
    visibleNodes = visibleNodes.filter(n => n.id !== modifiedUnion.id)
    visibleNodes.push(modifiedUnion, addChild)
  }

  const addSpouseNode = new AddIndividualNode({
    typeToAdd: ADD_TYPE_SPOUSE,
    addedFromIndividualNode: editedIndividualNode,
  })
  const addUnknownChildNode = new AddIndividualNode({
    typeToAdd: ADD_TYPE_CHILD,
    addedFromIndividualNode: editedIndividualNode,
  })
  const addSpouseUnion = createUnion(
    unionID(editedIndividualNode.id, addSpouseNode.id),
    editedIndividualNode,
    addSpouseNode,
    [addUnknownChildNode.id]
  )
  visibleNodes.push(addSpouseNode, addSpouseUnion, addUnknownChildNode)

  return visibleNodes
}

const createAddSiblingNode = (nodeDirectory, editedIndividualNode) => {
  const bioFather = nodeDirectory[editedIndividualNode.bioFather]
  const bioMother = nodeDirectory[editedIndividualNode.bioMother]
  const addSibling = new AddIndividualNode({
    typeToAdd: ADD_TYPE_SIBLING,
    addedFromIndividualNode: bioFather || bioMother,
    otherParent: bioFather ? bioMother : undefined,
  })
  const modifiedUnion = copyUnion(
    unionForParentsOfIndividual(editedIndividualNode, nodeDirectory)
  )
  modifiedUnion.children.push(addSibling.id)
  return [addSibling, modifiedUnion]
}
