import React from 'react'
import { Box } from '@mui/material'

import { SimpleDialog } from 'src/modules/ui'
import SearchTreeControl from 'src/modules/viewer/SearchTreeControl'
import {
  TreeViewerActions,
  SearchButton,
} from 'src/modules/viewer/MobileTreeViewerMenu'

const PublicMobileTreeViewerMenu = ({
  handleDeselection,
  handleFamilySelected,
  handleIndividualSelected,
  nodeDirectory,
  allFamilies,
  handleForceCloseModal,
}) => {
  return (
    <TreeViewerActions
      handleForceCloseModal={handleForceCloseModal}
      closeButtonStyles={{
        mr: -1,
        zIndex: 100,
      }}
    >
      <SimpleDialog
        trigger={modalTrigger => (
          <SearchButton onClick={modalTrigger.onClick} />
        )}
      >
        <Box sx={{ width: '200px' }}>
          <SearchTreeControl
            {...{
              handleDeselection,
              handleFamilySelected,
              handleIndividualSelected,
              nodeDirectory,
              allFamilies,
              isPublic: true,
            }}
          />
        </Box>
      </SimpleDialog>
    </TreeViewerActions>
  )
}

export default PublicMobileTreeViewerMenu
