import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  selectPublicNodeDirectory,
  selectSharedByIndividual,
} from '../tree/treeSlice'
import { PublicContext } from '../contexts'
import { LoadingIndicator } from 'src/modules/ui'
import PublicSubTreeExplorer from '../tree/PublicSubTreeExplorer'

const PublicSubTreeDemo = () => {
  const { treeLoading } = useContext(PublicContext)
  const sharedBy = useSelector(selectSharedByIndividual)
  const publicNodeDirectory = useSelector(selectPublicNodeDirectory)

  if (treeLoading) {
    return <LoadingIndicator title={'Loading Tree'} />
  } else if (sharedBy && !isEmpty(publicNodeDirectory)) {
    return (
      <>
        <PublicSubTreeExplorer open={true} />
      </>
    )
  } else {
    return <></>
  }
}

export default PublicSubTreeDemo
