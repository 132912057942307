import React from 'react'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'

import IconButton from './IconButton'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'inherit',
  },
}))

const IconButtonLink = ({ children, to, ...props }) => {
  const classes = useStyles()
  return (
    <RouterLink className={classes.root} to={to}>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        color="primary"
        white
        {...props}
      >
        {children}
      </IconButton>
    </RouterLink>
  )
}

export default IconButtonLink
