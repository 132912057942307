import { EditTreeName } from './EditTreeName'
import React, { useCallback, useState } from 'react'
import { Stack } from '@mui/material'
import { updateTree } from './treesSlice'
import { useActionDispatcher, useSetTree } from '../app'
import { useHistory } from 'react-router-dom'
import { Button, Typography } from '../ui'
import { slugFromName } from './utils'
import { BLOG_TREE } from '../common/constants'
import { generateTreeLink } from '../app/links'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const UpdateBlogName = ({ initialName, treeSlug }) => {
  const [name, setName] = useState(initialName)
  const setTree = useSetTree()
  const history = useHistory()
  const [savingTree, setSavingTree] = useState(false)
  const dispatchUpdateTree = useActionDispatcher(updateTree)

  const handleTreeNameChanged = name => {
    if (name) {
      setName(name)
    }
  }

  const handleDoneNaming = useCallback(async () => {
    setSavingTree(true)
    const newSlug = slugFromName(name)
    await dispatchUpdateTree(
      { treeSlug: treeSlug, name },
      { successNotification: 'Tree name updated' }
    )
    setSavingTree(false)
    await setTree(newSlug, BLOG_TREE)
    const blogPath = generateTreeLink(newSlug, 'blog')
    history.push(blogPath)
    window.location.reload(true)
  }, [dispatchUpdateTree, history, name, setTree, treeSlug])

  return (
    <>
      <Typography color="textSecondary" variant="h1">
        Name your blog
      </Typography>
      <EditTreeName
        initialName={initialName}
        onTreeNameChanged={handleTreeNameChanged}
        isExistingTree={true}
      />
      <Stack direction="row" sx={{ mt: 4, justifyContent: 'center' }}>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          disabled={savingTree}
          onClick={handleDoneNaming}
        >
          Save
        </Button>
      </Stack>
    </>
  )
}

export default UpdateBlogName
