import { Box } from '@mui/system'
import { useTheme } from '@mui/material/styles'

import { Typography } from 'src/modules/ui'
import { Stack } from '@mui/material'

const AllTimeProgress = ({ value, width, indicatorSize }) => {
  const theme = useTheme()

  return (
    <Box sx={{ width, p: 1 }}>
      <Stack
        sx={{
          width: indicatorSize,
          height: indicatorSize,
          margin: '0 auto',
          background: theme.palette.grey[300],
          borderRadius: '50%',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            margin: 'auto',
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Box>
  )
}
export default AllTimeProgress
