import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { TARGET_TYPE_ORDER } from './targets'

const SLICE_NAME = 'goals'

export const createOrUpdateGoal = createWrappedAsyncThunk(
  `${SLICE_NAME}/createGoal`,
  ({ targetType, target, shouldRemind }) => {
    return api.post(`/goals/${getTreeSlugFromStore()}/`, {
      body: { targetType, target, shouldRemind },
    })
  }
)

export const setReported = createWrappedAsyncThunk(
  `${SLICE_NAME}/setReported`,
  () => api.patch(`/goals/${getTreeSlugFromStore()}/set-reported/`)
)

export const fetchGoals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchGoals`,
  () => {
    return api.get(`/goals/${getTreeSlugFromStore()}/assistant/`)
  }
)

export const fetchGoalsShortly = dispatch => {
  setTimeout(() => dispatch(fetchGoals()), 6000)
}

export const fetchAllTime = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAllTime`,
  () => {
    return api.get(`/goals/${getTreeSlugFromStore()}/all-time/`)
  }
)

export const fetchMonthByMonth = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchMonthByMonth`,
  () => {
    return api.get(`/goals/${getTreeSlugFromStore()}/month-by-month/`)
  }
)

const initialState = {
  goals: [],
  lastMonthReport: [],
  monthByMonth: [],
  allTimeReport: [],
}

const orderGoals = goals => {
  const goalsByTargetType = Object.fromEntries(
    goals.map(goal => [goal.targetType, goal])
  )
  return TARGET_TYPE_ORDER.map(
    targetType => goalsByTargetType[targetType]
  ).filter(goal => !!goal)
}

export const goalsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: {
    [createOrUpdateGoal.fulfilled]: (state, { payload }) => {
      let updated = false
      state.goals = state.goals.map(goal => {
        if (payload.id === goal.id) {
          updated = true
          return payload
        }
        return goal
      })
      if (!updated) {
        state.goals.push(payload)
      }
    },
    [fetchAllTime.fulfilled]: (state, { payload }) => {
      state.allTimeReport = orderGoals(payload)
    },
    [fetchGoals.fulfilled]: (state, { payload }) => {
      state.goals = orderGoals(payload.goals)
      state.lastMonthReport = orderGoals(payload.lastMonthReport)
    },
    [fetchMonthByMonth.fulfilled]: (state, { payload }) => {
      for (let reportRow of payload) {
        reportRow.goals = orderGoals(reportRow.goals)
      }
      state.monthByMonth = payload
    },
    [setReported.fulfilled]: state => {
      state.goals = state.goals.map(({ progress, ...goal }) => ({
        ...goal,
        progress: { ...progress, reportedToUser: true },
      }))
    },
  },
})

export const selectGoals = state => state[SLICE_NAME].goals
export const selectLastMonthReport = state => state[SLICE_NAME].lastMonthReport
export const selectAllTimeReport = state => state[SLICE_NAME].allTimeReport
export const selectMonthByMonth = state => state[SLICE_NAME].monthByMonth

export default goalsSlice.reducer
