import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AncestralFamilies } from './AncestralFamilies'

import {
  selectUserHomeIndividual,
  selectAuthorisedTreeSlug,
} from 'src/modules/auth/authSlice'
import { generateLinkForObject } from 'src/modules/app/links'
import { createPyramid } from 'src/modules/viewer/api/nodeDirectory'
import { withOverridableComponent } from 'src/overridable'
import { INSTANCE_TYPE_FAMILY } from 'src/modules/app/links'
import {
  selectFamilies,
  selectFamilyPyramidLoaded,
  selectIsFullNodeDirectoryIsLoaded,
  selectNodeDirectory,
} from '../viewer/viewerSlice'

const AncestralFamiliesContainer = ({
  trigger,
  openAncestralFamilies,
  setOpenAncestralFamilies,
}) => {
  const userHomeIndividual = useSelector(selectUserHomeIndividual)
  const nodeDirectory = useSelector(selectNodeDirectory)
  const families = useSelector(selectFamilies)
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const familyPyramidLoaded = useSelector(selectFamilyPyramidLoaded)
  const isFullNodeDirectoryIsLoaded = useSelector(
    selectIsFullNodeDirectoryIsLoaded
  )

  const loaded = familyPyramidLoaded || isFullNodeDirectoryIsLoaded

  const pyramid = useMemo(() => {
    return createPyramid(nodeDirectory, userHomeIndividual, families)
  }, [nodeDirectory, userHomeIndividual, families])

  const handleFamilySelected = familyId => {
    history.push(
      generateLinkForObject(treeSlug, INSTANCE_TYPE_FAMILY, familyId)
    )
  }

  return (
    <AncestralFamilies
      userHomeIndividual={userHomeIndividual}
      traceLineageTo={userHomeIndividual}
      family={pyramid}
      pyramidLoaded={loaded}
      onClickName={handleFamilySelected}
      trigger={trigger}
      openAncestralFamilies={openAncestralFamilies}
      setOpenAncestralFamilies={setOpenAncestralFamilies}
    />
  )
}

export default withOverridableComponent(
  AncestralFamiliesContainer,
  'AncestralFamiliesContainer'
)
