import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ShareIcon from '@mui/icons-material/Share'
import { Box, Icon, Menu, MenuItem, styled, Typography } from '@mui/material'
import copy from 'copy-to-clipboard'

import { useActionDispatcher, useNotification } from 'src/modules/app/hooks'
import LoadingIndicator from 'src/modules/ui/LoadingIndicator'
import {
  selectUser,
  selectAuthorisedTreeSlug,
} from 'src/modules/auth/authSlice'

import VisibilityControl, {
  getErrorNotificationText,
  visibilityMap,
} from './VisibilityControl'
import { setVisibility } from './visibilitySlice'
import { generatePublicLinkForObjectWithSiteUrl } from 'src/modules/app/links'
import { useShare, useShareEmbed } from '../app/hooks'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

import {
  generateLinkForPublicRoot,
  generateLinkForPublicShareRoot,
  generateShareLinkForObjectWithSiteUrl,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from '../app/links'
import { useLocation } from 'react-router-dom'
import { ConfirmDialog } from '../ui'
import {
  getVisibilityControlConfig,
  VISIBILITY_DEFAULT,
  VISIBILITY_PUBLIC,
  VISIBILITY_TREE,
} from './visibilityUtils'
import { usePermissions } from '../auth/authHooks'
import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../app/appConstants'
import FabButton from '../ui/FabButton'

const VisibilityRow = styled(Box)({
  display: 'flex',
})

const InstanceVisibilityControl = props => {
  return (
    <>
      <InstanceVisibilityContainer {...props} />
    </>
  )
}
// slideshow has been shamelessly added in here because we have a deadline and cbf to sort visibility - RGS 25/08/23
// to be refactored ;)
const InstanceVisibilityContainer = ({
  id,
  author = {},
  type,
  sx,
  visibility,
  overrideDisabled,
  iconSize,
  slideshow,
  ...props
}) => {
  const user = useSelector(selectUser)
  const treeAllowPublicVisibility = user.currentTree.allowPublicVisibility
  const location = useLocation()

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  if (treeAllowPublicVisibility && visibility === VISIBILITY_DEFAULT) {
    visibility = VISIBILITY_PUBLIC
  } else if (!treeAllowPublicVisibility && visibility === VISIBILITY_DEFAULT) {
    visibility = VISIBILITY_TREE
  }

  const [localVisibility, setLocalVisibility] = useState(visibility)

  const dispatchSetVisibility = useActionDispatcher(setVisibility)
  const { showSuccess } = useNotification()
  const handleShare = useShare()
  const handleShareEmbed = useShareEmbed()

  const handleChangeVisibility = useCallback(
    async (visibility, onClose) => {
      if (onClose) {
        onClose()
      }

      try {
        await dispatchSetVisibility(
          { instanceId: id, visibility },
          {
            errorNotification: getErrorNotificationText,
            successNotification: 'Visibility changed',
          }
        ).unwrap()
        setLocalVisibility(visibility)
      } catch (err) {
        return
      }
    },
    [dispatchSetVisibility, id]
  )

  const PERMITTED_SHARE_TYPES = [
    INSTANCE_TYPE_ARTICLE,
    INSTANCE_TYPE_DOCUMENT,
    INSTANCE_TYPE_MEDIA,
    INSTANCE_TYPE_PHOTO_ALBUM,
  ]

  const { Icon, label } = visibilityMap.hasOwnProperty(localVisibility)
    ? visibilityMap[localVisibility]
    : {}

  let dummyInstance
  if (type === INSTANCE_TYPE_MEDIA) {
    dummyInstance = { uploadedBy: author }
  } else {
    dummyInstance = { author: author }
  }
  const checkPermissions = usePermissions()
  const { hasPermission } = checkPermissions(ACTION_EDIT, {
    instanceType: type,
    instance: dummyInstance,
  })

  const {
    visibilityTooltip,
    shareButtonDisabled,
    visibilityControlDisabled,
    shareToolTip,
    privateTreeShare,
    hideShareButton,
  } = getVisibilityControlConfig(
    location.pathname,
    treeAllowPublicVisibility,
    localVisibility,
    type,
    overrideDisabled,
    hasPermission,
    label,
    PERMITTED_SHARE_TYPES
  )

  const handleClickShare = useCallback(() => {
    let url = ''
    if (privateTreeShare) {
      url = generateShareLinkForObjectWithSiteUrl(treeSlug, type, id)
    } else {
      url = generatePublicLinkForObjectWithSiteUrl(treeSlug, type, id)
    }
    copy(url)
    showSuccess('Copied url to clipboard')
  }, [id, showSuccess, type, treeSlug, privateTreeShare])

  useEffect(() => {
    if (!localVisibility && visibility) {
      setLocalVisibility(visibility)
    }
  }, [visibility, localVisibility])

  if (!localVisibility) {
    return <LoadingIndicator />
  }

  return (
    <VisibilityRow sx={sx}>
      <VisibilityControl
        {...{
          id,
          Icon,
          tooltip: visibilityTooltip,
          visibility: localVisibility,
          onChangeVisibility: handleChangeVisibility,
          isLoading: dispatchSetVisibility.status === 'loading',
          iconSize: iconSize,
          ...props,
          disabled: visibilityControlDisabled,
        }}
      />
      {!hideShareButton && (
        <Box sx={{ ml: 1 }}>
          <ShareButton
            onClickShareEmbed={
              type === INSTANCE_TYPE_ARTICLE ? handleShareEmbed : null
            }
            visibility={localVisibility}
            onConfirmShare={() => handleChangeVisibility(VISIBILITY_PUBLIC)}
            privateTreeShare={privateTreeShare}
            tooltip={shareToolTip}
            disabled={shareButtonDisabled}
            onClickShare={type === 'media' ? handleClickShare : handleShare}
            id={id}
            type={type}
            iconSize={iconSize}
            isPublic={visibility === VISIBILITY_PUBLIC}
          />
        </Box>
      )}
      {slideshow &&
        slideshow({
          visibility: localVisibility,
          handleChangeVisibility: handleChangeVisibility,
        })}
    </VisibilityRow>
  )
}

const SHARE_QUOTE = 'A little piece of magic from our past - '
const ICON_SIZE = 'small'

export const ShareButton = ({
  visibility = VISIBILITY_DEFAULT,
  privateTreeShare = false,
  tooltip = '',
  disabled,
  onClickShare,
  onClickShareEmbed,
  onConfirmShare = () => {},
  isPublic,
  type,
  id,
  shareSuffix = '',
  ...props
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const location = useLocation()
  const confirmDialogConfigDefault = {
    open: false,
    target: null,
  }
  const [confirmDialogConfig, setConfirmDialogConfig] = useState(
    confirmDialogConfigDefault
  )

  const [anchorEl, setAnchorEl] = useState()
  const open = anchorEl
  const handleClick = event => {
    if (privateTreeShare && visibility !== VISIBILITY_PUBLIC) {
      setConfirmDialogConfig({ open: true, target: event.target })
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleConfirmShare = () => {
    if (confirmDialogConfig?.target) {
      if (onConfirmShare) {
        onConfirmShare()
      }
      setAnchorEl(confirmDialogConfig.target)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getUrl = () => {
    if (isPublic && privateTreeShare) {
      if (type === 'media') {
        return generateShareLinkForObjectWithSiteUrl(treeSlug, type, id)
      } else {
        return generateLinkForPublicShareRoot(location.pathname)
      }
    } else if (!isPublic) {
      if (type === 'media') {
        return generatePublicLinkForObjectWithSiteUrl(treeSlug, type, id)
      } else {
        return generateLinkForPublicRoot(location.pathname)
      }
    } else {
      return window.location
    }
  }

  return (
    <>
      <FabButton
        disabled={disabled}
        toolTipOverride={tooltip}
        text={`Share ${shareSuffix}`}
        permissionAction={ACTION_ALL_ACCESS}
        icon={<ShareIcon fontSize="small" sx={{ mb: 0.1 }} />}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        // onClick={onClickShare}
        {...props}
      />
      <ConfirmDialog
        submitText={'Continue'}
        open={confirmDialogConfig.open}
        onConfirm={handleConfirmShare}
        onClose={() => setConfirmDialogConfig(confirmDialogConfigDefault)}
      >
        <Typography>
          Your archive is PRIVATE, sharing this page will make this page and its
          content public. Living individuals will not be shown. ALL other
          content will remain private. Do you want to continue?
        </Typography>
      </ConfirmDialog>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onClickShare(privateTreeShare)}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon fontSize="small" sx={{ mr: 1 }}>
              <img
                src="/copy-link-icon.svg"
                alt="copy link"
                width={'80%'}
                height="100%"
              />
            </Icon>
            Copy link
          </Box>
        </MenuItem>
        {onClickShareEmbed && (
          <MenuItem onClick={() => onClickShareEmbed(privateTreeShare)}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon fontSize="small" sx={{ mr: 1 }}>
                <img
                  src="/copy-link-icon.svg"
                  alt="embed link"
                  width={'80%'}
                  height="100%"
                />
              </Icon>
              Embed link
            </Box>
          </MenuItem>
        )}
        <MenuItem>
          <EmailShareButton
            url={getUrl()}
            subject="Check out We Are [XYZ]"
            body={SHARE_QUOTE}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <EmailIcon fontSize={ICON_SIZE} round sx={{ mr: 1 }} />
            Email
          </EmailShareButton>
        </MenuItem>
        <MenuItem>
          <FacebookShareButton
            url={getUrl()}
            quote={SHARE_QUOTE}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FacebookIcon fontSize={ICON_SIZE} round sx={{ mr: 1 }} />
            Facebook
          </FacebookShareButton>
        </MenuItem>
        <MenuItem>
          <TwitterShareButton
            url={getUrl()}
            title={SHARE_QUOTE}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TwitterIcon fontSize={ICON_SIZE} round sx={{ mr: 1 }} />
            Twitter
          </TwitterShareButton>
        </MenuItem>
        <MenuItem>
          <WhatsappShareButton
            url={getUrl()}
            title={SHARE_QUOTE}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <WhatsAppIcon fontSize={ICON_SIZE} round sx={{ mr: 1 }} />
            WhatsApp
          </WhatsappShareButton>
        </MenuItem>
      </Menu>
    </>
  )
}

export default InstanceVisibilityControl
