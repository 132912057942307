import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchMyPhotos,
  selectMyPhotos,
} from 'src/modules/myContent/myContentSlice'
import { selectUser } from 'src/modules/auth/authSlice'
import { MediaList } from 'src/modules/photo'

const MyPhotoList = () => {
  const dispatchFetchMyPhotos = useActionDispatcher(fetchMyPhotos)
  const media = useSelector(selectMyPhotos)
  const user = useSelector(selectUser)

  useEffect(() => {
    dispatchFetchMyPhotos({ author: user.id, page: 0 })
  }, [dispatchFetchMyPhotos, user])

  const onFetchMore = () => {
    if (dispatchFetchMyPhotos.status === 'loading') {
      return
    }
    dispatchFetchMyPhotos({ author: user.id, page: media.page + 1 })
  }

  const refreshMedia = () => {
    dispatchFetchMyPhotos({ author: user.id, page: 0 })
  }

  return (
    <MediaList
      onFetchMore={onFetchMore}
      media={media}
      status={dispatchFetchMyPhotos.status}
      refreshMedia={refreshMedia}
    />
  )
}

export default MyPhotoList
