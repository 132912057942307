import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useFullScreen } from './hooks'
import { ShareSlideshowButton } from './AlbumPage'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import { SnackbarProvider } from 'notistack'
import { useShare } from '../app'

const PhotoSlideshow = forwardRef(
  ({ options, onClose, media, visibility }, ref) => {
    const [photoIndex, setPhotoIndex] = useState(0)
    const [intervalFunc, setIntervalFunc] = useState(null)
    const { open, slideDuration, loop } = options
    const mediaCount = media?.length - 1
    const slideshowStartedRef = useRef(false)
    const enterFullScreen = useFullScreen(ref)
    const handleShare = useShare(true)

    const handleDisplayImage = idx => {
      let indexAbove = photoIndex + 1
      let indexBelow = photoIndex - 1
      if (photoIndex === 0) {
        indexBelow = mediaCount
      }
      if (photoIndex === mediaCount) {
        indexAbove = 0
      }
      if (idx === photoIndex || idx === indexBelow || idx === indexAbove) {
        return 'flex'
      } else return 'none'
    }

    const handleIndex = useCallback(() => {
      setPhotoIndex(prevState => {
        if (prevState >= mediaCount) {
          if (loop) {
            return 0
          } else {
            clearInterval(intervalFunc)
            return prevState
          }
        } else return prevState + 1
      })
    }, [intervalFunc, loop, mediaCount])

    const startInterval = useCallback(() => {
      clearInterval(intervalFunc)

      setIntervalFunc(setInterval(handleIndex, slideDuration * 1000))
    }, [handleIndex, slideDuration, intervalFunc])

    useEffect(() => {
      if (
        open &&
        !slideshowStartedRef.current &&
        !window.location.search.includes('slideshow=true')
      ) {
        slideshowStartedRef.current = true
        startInterval()
      }
    }, [open, startInterval])

    const onClickPrev = () => {
      if (photoIndex === 0) {
        setPhotoIndex(mediaCount)
      } else {
        setPhotoIndex(prevState => prevState - 1)
      }
      startInterval()
    }

    const onClickNext = () => {
      if (photoIndex === mediaCount) {
        setPhotoIndex(0)
      } else {
        setPhotoIndex(prevState => prevState + 1)
      }
      startInterval()
    }

    const onClickPause = () => {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
    }

    const onClickPlay = () => {
      startInterval()
    }

    const handleClose = () => {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
      setPhotoIndex(0)
      slideshowStartedRef.current = false
      onClose()
    }

    const startSlideshow = () => {
      handleShare(false, { slideshow: true, ...options }, true)
      enterFullScreen()
      onClickPlay()
      slideshowStartedRef.current = true
      sendEvent(ga4Events.ALBUM_SLIDESHOW_PLAYED_PUBLIC)
    }

    return (
      <Dialog
        ref={ref}
        id="slideshow-dialog"
        open={open}
        fullScreen={true}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
            overflow: 'hidden',
          },
        }}
      >
        <SnackbarProvider container={ref.current}>
          <Box
            sx={{
              height: '100vh',
              width: '100vw',
              position: 'absolute',
            }}
          >
            <Box
              sx={{
                backgroundImage: 'url("/circular-logo.png")',
                width: '500vw',
                height: '500vw',
                left: '-250vw',
                // top: '-250vh',
                position: 'relative',
                transform: 'rotate(-45deg)',
                transformOrigin: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  width: '200%',
                  height: '200%',
                  marginTop: '-10%',
                }}
              />
            </Box>
          </Box>
          {media?.length > 0 ? (
            media?.map((mediaItem, index) => {
              const display = handleDisplayImage(index)
              return (
                <Box
                  sx={{
                    display: display,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    opacity: index === photoIndex ? 1 : 0,
                    transitionDuration: '2s',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: 'fit-content',
                      height: 'fit-content',
                      position: 'relative',
                    }}
                  >
                    <img
                      alt={mediaItem?.title}
                      src={mediaItem.fileMedium}
                      style={{
                        height: 'auto',
                        width: 'auto',
                        maxWidth: '100%',
                        maxHeight: '90vh',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        minWidth: '100%',
                        width: 'fit-content',
                        height: 'fit-content',
                        p: 1,
                        bottom: 0,
                      }}
                    >
                      <Typography variant="subtitle1" color="white">
                        {mediaItem?.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
            })
          ) : (
            <CircularProgress />
          )}
          <Box
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
          >
            <ShareSlideshowButton
              fromPublic={window.location.href.includes('/public/')}
              values={options}
              visibility={visibility}
            />
          </Box>
          <Box
            onClick={startSlideshow}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transitionDuration: '0.6s',
              opacity: !intervalFunc && !slideshowStartedRef.current ? 1 : 0,
              pointerEvents:
                !intervalFunc && !slideshowStartedRef.current ? 'auto' : 'none',
            }}
          >
            <Typography color="white" variant="h2">
              Click to start
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(250,250,250,0.4)',
            }}
          >
            <IconButton onClick={handleClose} sx={{ borderRadius: 0 }}>
              <CloseIcon />
            </IconButton>
            <IconButton onClick={onClickPrev} border sx={{ borderRadius: 0 }}>
              <SkipPreviousIcon />
            </IconButton>
            {intervalFunc ? (
              <IconButton
                onClick={onClickPause}
                border
                sx={{ borderRadius: 0 }}
              >
                <PauseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={onClickPlay} border sx={{ borderRadius: 0 }}>
                <PlayArrowIcon />
              </IconButton>
            )}

            <IconButton onClick={onClickNext} sx={{ borderRadius: 0 }}>
              <SkipNextIcon />
            </IconButton>
          </Box>
        </SnackbarProvider>
      </Dialog>
    )
  }
)

export default PhotoSlideshow
