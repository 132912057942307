import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Fab, Popover } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import clsx from 'clsx'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import Tooltip from '@mui/material/Tooltip'

import { generateLinkForObject, useActionDispatcher } from 'src/modules/app'
import { IconButton, Typography, LoadingIndicator, Link } from 'src/modules/ui'
import {
  fetchBookmarks,
  selectBookmarks,
  addBookmark,
  markBookmarkDeleted,
  deleteBookmarks,
} from 'src/modules/app/appSlice'

import ClearIcon from '@mui/icons-material/Clear'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import SiteWideIcon from '../ui/SiteWideIcon'
import { ACTION_ALL_ACCESS } from './appConstants'

const useStyles = makeStyles(theme => ({
  heading: {
    display: 'flex',
  },
  loading: { padding: theme.spacing(2) },
  bookmarkLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bookmark: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  bookmarkRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '&:hover': {
      backgroundColor: theme.palette.backgroundGrey.main,
    },
  },
  bookmarkRow: {
    display: 'flex',
    alignItems: 'center',
  },
  bookmarkDeleteIcon: {
    width: '1.5rem',
    paddingRight: theme.spacing(1),
  },
  bookmarkIcon: {
    paddingRight: theme.spacing(1),
  },
  unSeen: {
    backgroundColor: theme.palette.lightGrey.main,
  },
  noBookmarks: {
    padding: theme.spacing(2),
  },
  root: {
    width: 400,
  },
}))

const Bookmarks = ({
  isFetching,
  next,
  bookmarks,
  onClose,
  onFetchMore,
  onOpen,
  onDelete,
  page,
  trigger,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    onOpen()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    onClose()
    setAnchorEl(null)
  }

  const bookmarksToDisplay = bookmarks.filter(
    bookmark => bookmark.deleted !== true
  )

  return (
    <>
      {trigger({ onClick: handleClick })}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.root}>
          {isFetching ? (
            <LoadingIndicator className={classes.loading} />
          ) : bookmarksToDisplay.length === 0 ? (
            <div className={classes.noBookmarks}>
              <Typography>No Bookmarks!</Typography>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={bookmarksToDisplay.length}
              next={onFetchMore}
              hasMore={next}
              height={250}
            >
              {bookmarksToDisplay.map(bookmark => (
                <Bookmark
                  key={bookmark.id}
                  {...bookmark}
                  handleDelete={() => onDelete(bookmark.id)}
                  handleClose={handleClose}
                />
              ))}
            </InfiniteScroll>
          )}
          {isFetching && page > 0 && (
            <LoadingIndicator className={classes.loading} />
          )}
        </div>
      </Popover>
    </>
  )
}

const Bookmark = ({
  handleClose,
  display,
  handleDelete,
  id,
  instanceType,
  target,
}) => {
  const classes = useStyles()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  return (
    <div className={classes.bookmarkRowContainer}>
      <Link
        className={classes.bookmarkLink}
        to={generateLinkForObject(treeSlug, instanceType, target)}
        onClick={handleClose}
      >
        <div className={classes.bookmarkRow}>
          <div className={clsx([classes.bookmark])}>
            <SiteWideIcon
              instanceType={instanceType}
              className={classes.bookmarkIcon}
            />
            <Typography className={classes.heading}>{display}</Typography>
          </div>
        </div>
      </Link>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleDelete}
        color="inherit"
      >
        <ClearIcon className={classes.bookmarkDeleteIcon} on />
      </IconButton>
    </div>
  )
}

const BookmarksContainer = ({ trigger }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { results: bookmarks, next, page } = useSelector(selectBookmarks)
  const dispatchFetchBookmarks = useActionDispatcher(fetchBookmarks)
  const dispatchDeleteBookmarks = useActionDispatcher(deleteBookmarks)

  const handleFetchMore = async () => {
    if (dispatchFetchBookmarks.status === 'loading') {
      return
    }
    await dispatchFetchBookmarks({ page: page + 1 })
  }

  const handleClose = async () => {
    const bookmarkIdsToDelete = bookmarks
      .filter(bookmark => bookmark.deleted === true)
      .map(bookmark => bookmark.id)
    if (bookmarkIdsToDelete.length > 0) {
      await dispatchDeleteBookmarks({ bookmarkIdsToDelete })
    }
  }

  const handleDelete = async id => {
    dispatch(markBookmarkDeleted(id))
  }

  const handleOpen = async () => {
    await dispatchFetchBookmarks({ page: 0 })
    setLoading(false)
  }

  return (
    <Bookmarks
      isFetching={loading}
      next={next}
      bookmarks={bookmarks}
      onClose={handleClose}
      onDelete={handleDelete}
      onOpen={handleOpen}
      onFetchMore={handleFetchMore}
      page={page}
      trigger={trigger}
    />
  )
}

export const BookmarkAddButton = ({
  type,
  targetId,
  fontSize,
  grey,
  ...props
}) => {
  const dispatchAddBookmark = useActionDispatcher(addBookmark)

  const handleClick = async () => {
    await dispatchAddBookmark(
      { type, targetId },
      { successNotification: 'Bookmark saved' }
    )
  }

  return (
    <Tooltip title="Bookmark">
      {grey ? (
        <Fab
          onClick={handleClick}
          color="inherit"
          white
          sx={{ height: 36, width: 36, boxShadow: 'none' }}
          {...props}
        >
          <BookmarkAddIcon fontSize="small" />
        </Fab>
      ) : (
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          onClick={handleClick}
          color="inherit"
          white
          {...props}
        >
          <BookmarkAddIcon fontSize={fontSize} />
        </IconButton>
      )}
    </Tooltip>
  )
}

export default BookmarksContainer
