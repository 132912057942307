import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'
import { differenceBy } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'

import {
  searchLocations,
  selectLocations,
} from '../writeArticle/writeArticleSlice'

const useStyles = makeStyles(theme => ({
  tag: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export const SelectLocation = ({ onSelectLocation, initialValues = null }) => {
  const classes = useStyles()
  const locations = useSelector(selectLocations)
  const dispatchSearchLocations = useActionDispatcher(searchLocations)

  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')

  // Populate all the options by making an empty search
  useEffect(() => {
    const initialise = async () => {
      await dispatchSearchLocations('')
    }
    initialise()
  }, [dispatchSearchLocations, setValue, initialValues])

  useEffect(() => {
    if (locations.length && initialValues && !value.length) {
      const presetLocations = locations.filter(l =>
        initialValues.includes(l.id)
      )
      if (presetLocations.length) {
        setValue(presetLocations)
        onSelectLocation(presetLocations.map(l => l.id))
      }
    }
  }, [locations, initialValues, onSelectLocation, value])

  const handleInputChange = (event, newInputValue) => {
    dispatchSearchLocations(newInputValue)
    setInputValue(newInputValue)
  }
  const handleChange = (event, newValue) => {
    const toAdd = differenceBy(newValue, value, 'id')
    const toRemove = differenceBy(value, newValue, 'id')
    toAdd.forEach(async subject => {
      setValue([...value, { id: subject.id, display: subject.display }])
      onSelectLocation([...value.map(v => v.id), subject.id])
    })
    toRemove.forEach(async subject => {
      let newValue = value.filter(v => v.id !== subject.id)
      setValue(newValue)
      onSelectLocation(newValue.map(v => v.id))
    })
  }

  const filteredOptions = locations.filter(o => o.id !== value)
  const noOptionsText =
    inputValue === '' ? 'Type to search...' : 'No matches found'

  return (
    <div className={classes.selectLocation}>
      <Autocomplete
        autoComplete
        freeSolo
        getOptionLabel={option => option.display}
        inputValue={inputValue}
        multiple={true}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={filteredOptions}
        renderInput={params => (
          <TextField {...params} label="Places" fullWidth />
        )}
        value={value}
      />
    </div>
  )
}

export default SelectLocation
