import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/system'

import {
  selectPublicNodeDirectory,
  selectPublicIndividualById,
  selectSharedByIndividual,
} from './treeSlice'
import {
  setPublicExploredIndividualId,
  selectExploredIndividualId,
  selectChosenFamilyId,
} from 'src/modules/viewer/exploreTreeSlice'
import {
  FOCUS_MODE_EXPLORE_LINEAGE,
  FOCUS_MODE_EXPLORE_INDIVIDUAL,
} from 'src/modules/viewer/treeViewers'

import { createExploredIndividualGraph } from 'src/modules/viewer/api/explore'
import { createLineageGraphCached } from 'src/modules/viewer/api/lineage'
import {
  SIZE_MODE_FIT_SCREEN,
  SIZE_MODE_FIT_NODES,
  TreeViewer,
} from 'src/modules/viewer/treeViewers'
import {
  selectArbitraryVisibleIndividualIds,
  selectSelectedIndividualIds,
} from '../../viewer/exploreTreeSlice'

import { generatePublicTreeRoot } from '../../app/links'
import { useRouteMatch } from 'react-router-dom'
import { PublicContext } from '../contexts'

import {
  INITIAL_ZOOM_EXPLORE_MODE,
  INITIAL_ZOOM_NAVIGATOR_MODE,
} from '../../viewer/treeViewers/constants'

import { processIndividualsAndUnionsToGraphNodes } from '../../viewer/api/graphNode'
import { DagManager } from '../../viewer/api/layout'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'

const PublicSubTreeExplorer = ({ navigatorMode }) => {
  const dispatch = useDispatch()
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug

  const publicTreeRoot = generatePublicTreeRoot(publicTreeSlug)
  const familiesRouteMatch = useRouteMatch(`${publicTreeRoot}/families`)
  const individualsRouteMatch = useRouteMatch(`${publicTreeRoot}/individuals`)

  // What to display
  const exploredPublicFamilyId = useSelector(selectChosenFamilyId)

  let exploredPublicIndividualId = useSelector(selectExploredIndividualId)
  const sharedByIndividual = useSelector(selectSharedByIndividual)

  const individualId = exploredPublicIndividualId
    ? exploredPublicIndividualId
    : sharedByIndividual

  const handleIndividualSelected = useCallback(
    individualId => {
      dispatch(setPublicExploredIndividualId(individualId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!familiesRouteMatch && !individualsRouteMatch) {
      handleIndividualSelected(sharedByIndividual?.id)
    }
  }, [
    individualsRouteMatch,
    familiesRouteMatch,
    sharedByIndividual,
    handleIndividualSelected,
  ])

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <PublicSubTreeView
          selectMenuConfig={{ select: true, explore: true }}
          familyId={exploredPublicFamilyId}
          individualId={individualId}
          focusMode={
            exploredPublicIndividualId
              ? FOCUS_MODE_EXPLORE_INDIVIDUAL
              : FOCUS_MODE_EXPLORE_LINEAGE
          }
          allowDragAndZoom={true}
          showNodeContextMenu={true}
          sizeMode={SIZE_MODE_FIT_SCREEN}
          doAddGreatGrandParents={true}
          source={'PublicTreeExplorer'}
          navigatorMode={navigatorMode}
          navigateToNodeOnDoubleClick={navigatorMode}
        />
      </Box>
    </>
  )
}

const isPrerender =
  navigator.userAgent.toLowerCase().indexOf('prerender') !== -1

const useStyles = makeStyles(theme => ({
  resetControls: {
    width: '26%',
    maxWidth: '150px',
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
    zIndex: 100,
    borderRadius: theme.shape.borderRadius,
  },
}))

export const PublicSubTreeView = ({
  allowDragAndZoom = false,
  familyId,
  individualId,
  focusMode,
  selectMenuConfig,
  showNodeContextMenu = false,
  navigateToNodeOnClick = false,
  onCloseViewerModal,
  sizeMode = SIZE_MODE_FIT_NODES,
  doAddGreatGrandParents = false,
  navigatorMode,
}) => {
  const dispatch = useDispatch()

  const exploredIndividualNode = useSelector(
    selectPublicIndividualById(individualId)
  )

  const sharedByIndividual = useSelector(selectSharedByIndividual)

  const classes = useStyles()

  const selectedIndividualIds = useSelector(selectSelectedIndividualIds)

  const nodeDirectory = useSelector(selectPublicNodeDirectory)

  const arbitraryVisibleNodeIds = useSelector(
    selectArbitraryVisibleIndividualIds
  )
  const arbitraryVisibleNodes = useMemo(() => {
    return Array.from(arbitraryVisibleNodeIds).map(id => nodeDirectory[id])
  }, [arbitraryVisibleNodeIds, nodeDirectory])

  // Layout
  const [nodes, links, nLayers] = useMemo(() => {
    if (isPrerender) {
      return []
    }

    if (familyId) {
      let { nodes, links, nLayers } = createLineageGraphCached(
        nodeDirectory,
        individualId,
        familyId
      )
      return [nodes, links, nLayers]
    } else if (exploredIndividualNode) {
      if (arbitraryVisibleNodes?.length) {
        const coreGraphNodes = processIndividualsAndUnionsToGraphNodes(
          arbitraryVisibleNodes
        )
        const dagManager = new DagManager(coreGraphNodes)
        return dagManager.layout()
      } else {
        return createExploredIndividualGraph(
          exploredIndividualNode,
          nodeDirectory,
          true
        )
      }
    } else {
      return []
    }
  }, [
    familyId,
    exploredIndividualNode,
    nodeDirectory,
    individualId,
    arbitraryVisibleNodes,
  ])

  const handleIndividualSelected = individualId => {
    dispatch(setPublicExploredIndividualId(individualId))
  }

  const handleCurrentIndividualSelected = () => {
    if (sharedByIndividual?.id) {
      handleIndividualSelected(sharedByIndividual.id)
    }
  }

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      {arbitraryVisibleNodeIds.size === 0 && (
        <div className={classes.resetControls}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCurrentIndividualSelected}
            size="small"
            sx={{ mt: 2 }}
          >
            Re-Center
          </Button>
        </div>
      )}
      <TreeViewer
        selectMenuConfig={selectMenuConfig}
        allowCreateSubTree={true}
        allowDragAndZoom={allowDragAndZoom}
        nodes={nodes}
        links={links}
        showNodeContextMenu={showNodeContextMenu}
        nLayers={nLayers}
        focusMode={focusMode}
        focusModeTarget={exploredIndividualNode}
        exploredIndividualSelector={selectPublicIndividualById}
        isInSelectionMode={true}
        navigateToNodeOnClick={false}
        onCloseViewerModal={onCloseViewerModal}
        sizeMode={sizeMode}
        treeBackgroundStyles={{ border: 'none' }}
        isPublic={true}
        isSubTree={arbitraryVisibleNodeIds.size > 0}
        exploreNodeOnClick={false}
        selectedIndividualIds={selectedIndividualIds}
        navigateToNodeOnDoubleClick={false}
        navigateToNodeOnDoubleClickHandler={false}
        onCreateSubTree={null}
        initialZoom={
          navigatorMode
            ? INITIAL_ZOOM_NAVIGATOR_MODE
            : INITIAL_ZOOM_EXPLORE_MODE
        }
        oneClickSelect={false}
      />
    </Box>
  )
}

export default PublicSubTreeExplorer
