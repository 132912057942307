import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React, { useEffect, useCallback, useState } from 'react'
import * as Yup from 'yup'

import { useNotification } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikTextField,
  Typography,
  FIELD_REQUIRED,
} from 'src/modules/ui'

import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLink from './FormLink'

import { useLocation } from 'react-router'
import { ACTION_LOGIN } from '../app/appConstants'
import { AuthState } from './Authprovider'

import { useActionDispatcher } from 'src/modules/app'
import { sendPageview, sendEvent, ga4Events } from '../analytics/AnalyticsUtils'
import { patchProfileAndState } from './authSlice'
import { UserLifecycleStates } from '../app/appConstants'
import { IconButton, useMediaQuery } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { styled } from '@mui/material/styles'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

const CantFindEmailHelp = props => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const tooltipText = (
    <>
      <p style={{ fontSize: 14 }}>
        The email will be from '<b>no-reply@verificationemail.com</b>' and have
        the subject '<b>Verify your email for WeAre.xyz</b>'.
        <br />
        Please check your spam or junk mail folder.
        <br />
        <br />
        You can request another email by clicking 'Request a new code' below but
        you will no longer be able to use the code from previous emails.
        <br />
        <br />
        If '<b>{props.email}</b>' is not your email address please click 'Go to
        login page' below and try again.
      </p>
      <p style={{ fontSize: 14 }}></p>
    </>
  )
  return (
    <Box
      //onMouseLeave={() => setTooltipOpen(false)}
      //onMouseOver={() => setTooltipOpen(true)}
      onClick={() => {
        if (!tooltipOpen) {
          sendEvent(ga4Events.VERIFY_EMAIL_PAGE_CANT_FIND_EMAIL_CLICKED)
        }
        setTooltipOpen(!tooltipOpen)
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      }}
      {...props}
    >
      <>
        {props.children}
        <CustomWidthTooltip
          title={tooltipText}
          placement={mobileBreakpoint ? 'top' : 'right'}
          open={tooltipOpen}
        >
          <IconButton>
            <InfoOutlinedIcon color="grey" />
          </IconButton>
        </CustomWidthTooltip>
      </>
    </Box>
  )
}

export const VerifyEmailForm = ({
  handleSubmit,
  isSubmitting,
  onClickBackToLogin,
  requestCode,
  queryCodeValidationError,
  user,
  isValid,
  dirty,
}) => (
  <div>
    <FormHeader title="Check your email" />

    <Typography variant="subtitle1">
      We've sent a verification code to {user.unverified?.email}
    </Typography>
    <Typography mt={2} variant="subtitle1">
      Please check your inbox - remember to look in your spam folder - and enter
      the code in the field below.
    </Typography>

    <Typography variant="subtitle1" sx={{ color: 'red' }}>
      {queryCodeValidationError}
    </Typography>

    <Form>
      <FormikTextField
        fullWidth
        label="Enter six-digit code"
        margin="normal"
        name="code"
        type="text"
        autoComplete="one-time-code"
      />
      <CantFindEmailHelp email={user.unverified?.email} sx={{ mt: 2 }}>
        <Typography variant="subtitle1">Can't find the email?</Typography>
      </CantFindEmailHelp>
      <FormActions style={{ alignItems: 'center', marginTop: 8 }}>
        <FormLink onClick={requestCode}>Request a new code</FormLink>
        <FormLink onClick={onClickBackToLogin}>Go to login page</FormLink>
        <Button
          permissionAction={ACTION_LOGIN}
          onClick={handleSubmit}
          color="primary"
          isLoading={isSubmitting}
          size="large"
          type="submit"
          disabled={!(isValid && dirty)}
          variant="outlined"
        >
          Submit Code
        </Button>
      </FormActions>
    </Form>
  </div>
)

const VerifyEmail = ({ onStateChange, user, autoRequestCode, ...props }) => {
  const { showError, showSuccess } = useNotification()

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(FIELD_REQUIRED),
  })
  const initialValues = { code: '' }

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const verificationCodeFromUrlQuery = urlParams.get('verificationCode')

  const [
    verificationCodeFromUrlQueryState,
    setVerificationCodeFromUrlQueryState,
  ] = useState(verificationCodeFromUrlQuery)
  const [verifyingCode, setVerifyingCode] = useState(false)
  const [autoRequestCodeDone, setAutoRequestCodeDone] = useState(false)

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__VERIFY_EMAIL)
  }, [user])

  const [queryCodeValidationError, setQueryCodeValidationError] = useState(null)

  const dispatchPatchProfileAndState = useActionDispatcher(patchProfileAndState)

  const handleSubmit = async ({ code }, { setFieldError }) => {
    setQueryCodeValidationError(null)
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
      sendEvent(ga4Events.EMAIL_VERIFIED_OK)
      dispatchPatchProfileAndState({
        userLifecycleState: UserLifecycleStates.EMAIL_VERIFIED,
      })
      onStateChange(AuthState.SignedIn, user)
    } catch (err) {
      if (
        err.code === 'CodeMismatchException' ||
        err.code === 'ExpiredCodeException'
      ) {
        sendEvent(ga4Events.VERIFY_EMAIL_PAGE_SUBMITTED_BAD_CODE)
        setFieldError('code', 'Bad code supplied')
        const longMessage = `Code ${
          err.code === 'ExpiredCodeException' ? 'has expired' : 'is invalid'
        }, please check for a more recent email or press 'Request a new code' below to receive a new email.`

        setQueryCodeValidationError(longMessage)

        showError('Bad code supplied')
      } else {
        sendEvent(ga4Events.VERIFY_EMAIL_PAGE_SUBMITTED_OTHER_ERROR)
        showError(err.message)
      }
    }
  }

  const requestCode = useCallback(async () => {
    setQueryCodeValidationError(null)
    try {
      await Auth.verifyCurrentUserAttribute('email').then(res => {
        showSuccess('New code requested, please check your email')
      })
    } catch (err) {
      if (err.code === 'LimitExceededException') {
        showError(
          'Cannot send verification code email as one has been requested too many times recently. Please try again soon.'
        )
      } else {
        showError(err.message)
      }
    }
  }, [showError, showSuccess])

  // not sure onload of this page is the best place to request a code, in case the user leaves and comes back later
  // and invalidates the code they are ready to type. Consider moving this to after signup?
  useEffect(() => {
    if (autoRequestCode && !autoRequestCodeDone) {
      setAutoRequestCodeDone(true)
      requestCode()
    }
  }, [autoRequestCode, autoRequestCodeDone, requestCode])

  //if a verificationCode was passed on the URL as a query parameter, submit it now
  useEffect(() => {
    if (verificationCodeFromUrlQueryState) {
      //console.debug(
      //  `VerifyEmail.useEffect(): verificationCodeFromUrlQueryState is truthy: ${verificationCodeFromUrlQueryState}...`
      //)
      setVerificationCodeFromUrlQueryState(null)
      //console.debug(
      //  `VerifyEmail.useEffect(): setting verifyingCode to true and submitting verificationCode: ${verificationCodeFromUrlQueryState}...`
      //)

      sendEvent(ga4Events.VERIFY_EMAIL_PAGE_OPENED_WITH_CODE)

      setQueryCodeValidationError(null)

      setVerifyingCode(true)

      Auth.verifyCurrentUserAttributeSubmit(
        'email',
        verificationCodeFromUrlQueryState
      )
        .then(res => {
          //console.debug(
          //  `VerifyEmail: verifyCurrentUserAttributeSubmit() returned success! Changing state to SignedIn...`,
          //  res
          //)
          sendEvent(ga4Events.EMAIL_VERIFIED_OK)

          dispatchPatchProfileAndState({
            userLifecycleState: UserLifecycleStates.EMAIL_VERIFIED,
          })
          onStateChange(AuthState.SignedIn, user)
        })
        .catch(err => {
          //console.debug(
          //  `VerifyEmail: verifyCurrentUserAttributeSubmit() errored`,
          //  err
          //)
          const badMessageEnd =
            "please check for a more recent email or press 'Request a new code' below to receive a new email."
          if (
            err.code === 'CodeMismatchException' ||
            err.code === 'ExpiredCodeException'
          ) {
            setQueryCodeValidationError(
              'Verification link not accepted, ' + badMessageEnd
            )
            showError('Bad code supplied')
          } else {
            setQueryCodeValidationError(
              'Verification link could not be validated, ' + badMessageEnd
            )
            showError(err.message)
          }

          sendEvent(ga4Events.VERIFY_EMAIL_PAGE_OPENED_WITH_BAD_CODE)
        })
        .finally(() => {
          setVerifyingCode(false)
        })
    }
  }, [
    verificationCodeFromUrlQueryState,
    onStateChange,
    showError,
    user,
    dispatchPatchProfileAndState,
  ])

  if (verifyingCode) {
    //console.debug(
    //  `VerifyEmail: verifyingCode is truthy, returning 'Verifying code...'`
    //)
    return <Typography>Verifying code...</Typography>
  } else {
    //console.debug(`VerifyEmail: verifyingCode falsy, rendering form`)
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        queryCodeValidationError={queryCodeValidationError}
      >
        {formik => (
          <VerifyEmailForm
            {...formik}
            {...props}
            queryCodeValidationError={queryCodeValidationError}
            requestCode={requestCode}
            user={user}
          />
        )}
      </Formik>
    )
  }
}

export default VerifyEmail
