import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { INSTANCE_TYPE_INDIVIDUAL } from '../../app/links'
import BaseIndividualsList from '../../page/BaseIndividualsList'
import {
  fetchPublicPageIndividuals,
  selectPublicIndividualsSearchTerm,
  selectPublicPageIndividuals,
  setPublicIndividualsSearchTerm,
  setPublicViewConfig,
} from '../page/pageSlice'
import { PublicContext } from '../contexts'
import PublicActionBar from '../page/PublicActionBar'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../../common/viewConfigUtils'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import IndividualSearchBox from '../../ui/IndividualSearchBox'

const PublicHomeIndividualList = () => {
  const { treeSlug } = useContext(PublicContext)
  const dispatch = useDispatch()
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatchFetchHomeIndividuals = useActionDispatcher(
    fetchPublicPageIndividuals
  )
  const homeIndividuals = useSelector(selectPublicPageIndividuals)
  const { next, results, updated } = homeIndividuals
  const type = INSTANCE_TYPE_INDIVIDUAL

  const makeArgs = useCallback(
    page => {
      const args = { page, type, treeSlug }
      return args
    },
    [type, treeSlug]
  )

  useEffect(() => {
    const fetchHomeIndividuals = async () => {
      await dispatchFetchHomeIndividuals(makeArgs(0))
    }

    if (!results.length) {
      fetchHomeIndividuals()
    }
  }, [dispatchFetchHomeIndividuals, makeArgs, type, results.length, updated])

  const handleFetchMore = () => {
    if (dispatchFetchHomeIndividuals.status === 'loading') {
      return
    }
    dispatchFetchHomeIndividuals(makeArgs(homeIndividuals.page + 1))
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    homeIndividuals,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setPublicViewConfig({ type, viewConfig }))
    dispatchFetchHomeIndividuals(makeArgs(0))
  }

  const search = useCallback(() => {
    dispatchFetchHomeIndividuals(makeArgs(0))
  }, [makeArgs, dispatchFetchHomeIndividuals])

  const refresh = useCallback(() => {
    dispatchFetchHomeIndividuals(makeArgs(0, null))
  }, [makeArgs, dispatchFetchHomeIndividuals])

  return (
    <>
      <PublicActionBar
        type={type}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        handleSetViewConfig={handleSetViewConfig}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <IndividualSearchBox
            searchTermAction={setPublicIndividualsSearchTerm}
            selector={selectPublicIndividualsSearchTerm}
            onChange={search}
            onClear={refresh}
            busy={dispatchFetchHomeIndividuals.status === 'loading'}
          />
        </Box>
      </PublicActionBar>
      <BaseIndividualsList
        {...{
          dispatchFetchIndividuals: dispatchFetchHomeIndividuals,
          next,
          handleFetchMore,
          individuals: homeIndividuals,
          results,
          type,
        }}
      />
    </>
  )
}

export default PublicHomeIndividualList
