import { useState } from 'react'
import { getSelectedBlocksType } from 'draftjs-utils'
import { RichUtils } from 'draft-js'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import TextFormatIcon from '@mui/icons-material/TextFormat'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { MenuIconButton } from './MenuIconButton'
import { TAG_FOR_BLOCK_TYPE } from './convert'
import parse from 'html-react-parser'
import { makeStyles } from '@mui/styles'
import {
  subtitleStyles,
  titleStyles,
  blockquoteStyles,
  bodyTextStyles,
} from '../../content/Article'

const useStyles = makeStyles(theme => ({
  menu: {
    '& p, blockquote, ol, ul': bodyTextStyles,
    '& h1, h2, h3, span': {
      fontFamily: 'IBM Plex Sans',
    },
    '& h4': titleStyles,
    '& h5': subtitleStyles,
    '& blockquote': blockquoteStyles,
  },
}))

const BLOCK_TYPES = [
  { label: 'Title', style: 'header-four' },
  { label: 'Subtitle', style: 'header-five' },
  { label: 'Heading1', style: 'header-one' },
  { label: 'Heading2', style: 'header-two' },
  { label: 'Heading3', style: 'header-three' },
  { label: 'Normal', style: 'unstyled' },
  { label: 'Blockquote', style: 'blockquote' },
]

export const BlockTypeDropdown = ({
  editorState,
  onChange,
  disableBlockquote,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const currentBlockType = getSelectedBlocksType(editorState)

  const toggleBlockType = blockStyle => {
    const newState = RichUtils.toggleBlockType(editorState, blockStyle)
    if (newState) {
      onChange(newState)
    }
  }

  return (
    <>
      <MenuIconButton onClick={handleClick}>
        <TextFormatIcon fontSize="small" />
        <ArrowDropDownIcon fontSize="small" />
      </MenuIconButton>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // Prevents the menu from stealing focus and thus making toolbar disappear
        autoFocus={false}
        disableAutoFocus={true}
      >
        {BLOCK_TYPES.map(({ label, style }) => {
          if (disableBlockquote && style === 'blockquote') return null
          return (
            <MenuItem
              selected={currentBlockType === style}
              onClick={() => toggleBlockType(style)}
              key={style}
            >
              {parse(
                `<${TAG_FOR_BLOCK_TYPE[style]}>${label}</${TAG_FOR_BLOCK_TYPE[style]}>`
              )}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
