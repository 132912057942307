import styled from '@emotion/styled'
import { TextField } from '@mui/material'

export const SearchTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FAF8F9',
  width: '100%',
  borderRadius: 20,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  border: `1px solid #CAC6C9`,
  '& .MuiInput-underline:before': {
    borderBottomWidth: 0,
  },
  '& .MuiInput-underline:after': {
    borderBottomWidth: 0,
  },
  '&& .MuiInput-underline:hover:before': {
    borderBottomWidth: 0,
  },
}))
