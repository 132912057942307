export const DEMO_TREE = 'DEMO'
export const USER_TREE = 'USER'
export const BLOG_TREE = 'BLOG'

export const getTreeType = user => {
  if (user.currentTree?.treeType === BLOG_TREE) {
    return BLOG_TREE
  }

  return USER_TREE
}

export const sortTypes = {
  PUBLISHED: '-published_at',
  USER: 'link-order',
  ALPHABETICAL: 'title',
  DATE_TAKEN: 'date_taken_sort',
  DATE_TAKEN_NEWEST: '-date_taken_sort',
  CREATED: '-created',
  FULL_NAME_ASC: 'full_name',
  FULL_NAME_DESC: '-full_name',
}
