import { createSlice } from '@reduxjs/toolkit'

import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { INSTANCE_TYPE_INFORMATION_REQUEST } from 'src/modules/app/links'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'

const SLICE_NAME = 'infoRequest'

// info request
export const createInformationRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/createInformationRequest`,
  body =>
    api.post(`/history/${getTreeSlugFromStore()}/information-request/`, {
      body,
    })
)

export const updateInformationRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/createInformationRequest`,
  ({ id, ...body }) =>
    api.patch(`/history/${getTreeSlugFromStore()}/information-request/${id}/`, {
      body,
    })
)

export const deleteInformationRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/createInformationRequest`,
  id => api.del(`/history/${getTreeSlugFromStore()}/information-request/${id}/`)
)

export const fetchInformationRequests = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchInformationRequest`,
  arg => {
    const argWithDefaults = Object.assign(
      {
        page: 0,
        sort: '-published_at',
        state: 'PUBLISHED',
        type: INSTANCE_TYPE_INFORMATION_REQUEST,
      },
      pickBy(arg)
    )

    const { page, sort, ...rest } = argWithDefaults
    const limit = 10
    const offset = page * limit

    const queryStringParameters = {
      limit,
      offset,
      ordering: sort,
      ...rest,
    }

    return api.get(`/history/${getTreeSlugFromStore()}/content/`, {
      queryStringParameters,
    })
  }
)

export const closeInformationRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/closeInformationRequest`,
  id =>
    api.post(
      `/history/${getTreeSlugFromStore()}/information-request/${id}/close/`
    )
)

export const reopenInformationRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/reopenInformationRequest`,
  id =>
    api.post(
      `/history/${getTreeSlugFromStore()}/information-request/${id}/reopen/`
    )
)

export const getUnseenInfoRequests = createWrappedAsyncThunk(
  `${SLICE_NAME}/getUnseenInfoRequests`,
  arg => {
    return api.get(
      `/history/${getTreeSlugFromStore()}/information-request-seen-by-user/`,
      {
        queryStringParameters: arg,
      }
    )
  }
)

export const createSeenInfoRequest = createWrappedAsyncThunk(
  `${SLICE_NAME}/createSeenInfoRequest`,
  arg => {
    return api.post(
      `/history/${getTreeSlugFromStore()}/information-request-seen-by-user/`,
      {
        body: arg,
      }
    )
  }
)

const initialState = {
  requests: {
    page: 0,
    count: 0,
    results: [],
  },
  mediaOpen: null,
  linkablePageOpen: null,
  unseenInfoRequests: {
    unseenCount: 0,
    unseenIds: [],
  },
}

export const informationRequestSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMediaOpen: (state, { payload }) => {
      state.mediaOpen = payload
    },
    setLinkablePageOpen: (state, { payload }) => {
      state.linkablePageOpen = payload
    },
    clearUnseenInfoRequests: state => {
      state.unseenInfoRequests = initialState.unseenInfoRequests
    },
  },
  extraReducers: {
    ...handlePaginatedAction(
      fetchInformationRequests,
      state => state.requests,
      {
        setOnRequest: initialState.requests,
      }
    ),
    [getUnseenInfoRequests.fulfilled]: (state, { payload }) => {
      state.unseenInfoRequests = payload
    },
  },
})

export const selectInformationRequests = state =>
  state.informationRequest.requests
export const selectMediaPageOpen = state => state.informationRequest.mediaOpen
export const selectLinkablePageOpen = state =>
  state.informationRequest.linkablePageOpen
export const selectUnseenInfoRequests = state =>
  state.informationRequest.unseenInfoRequests

export const { setMediaOpen, setLinkablePageOpen, clearUnseenInfoRequests } =
  informationRequestSlice.actions

export default informationRequestSlice.reducer
