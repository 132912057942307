import PublicArticle from './PublicArticle'

import {
  clearArticle,
  fetchDraftArticle,
  selectArticle,
} from '../../writeArticle/writeArticleSlice'
import { useLocation } from 'react-router-dom'
import {
  fetchPublicBlogPost,
  fetchPublicBlogPostStub,
  selectPublicContent,
} from './contentSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const BlogPreviewArticle = props => {
  const location = useLocation()
  const isDraft = location?.state?.isDraft
  const dispatch = useDispatch()

  const fetchArticleInPreview = args => {
    return fetchDraftArticle({ ...args, isPublicDirectory: true })
  }

  useEffect(() => {
    if (isDraft) {
      dispatch(clearArticle())
    }
  }, [isDraft, dispatch])

  return (
    <>
      <PublicArticle
        {...props}
        fetchPublicArticleStubAction={
          isDraft ? fetchArticleInPreview : fetchPublicBlogPostStub
        }
        fetchPublicArticleAction={
          isDraft ? fetchArticleInPreview : fetchPublicBlogPost
        }
        selectPublicContentSelector={
          isDraft ? selectArticle : selectPublicContent
        }
        showPreviewControls={!isDraft}
      />
    </>
  )
}

export default BlogPreviewArticle
