import React from 'react'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Typography } from './index'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const LoadingIndicator = ({ title, className }) => {
  const classes = useStyles()

  return (
    <div className={clsx([classes.root, className])}>
      {title && <Typography variant="h6">{title}</Typography>}
      <CircularProgress />
    </div>
  )
}

export default LoadingIndicator
