import React from 'react'
import { Stack, styled } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Form, FormikInput, IconButton, FIELD_REQUIRED } from 'src/modules/ui'
import ProfilePicture from './ProfilePicture'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  inputElement: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}))

const Icon = styled('img')({
  height: 23,
  width: 23,
})

const StyledInput = styled(FormikInput)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: theme.spacing(2),
}))

const StyledContainer = styled('div')(({ theme }) => ({
  flex: 1,
}))

const StyledForm = styled(Form)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
}))

const NewComment = ({
  contentId,
  onSubmit,
  responseName = 'comment',
  user,
  ...props
}) => {
  const classes = useStyles()

  const initialValues = { text: '' }
  const validationSchema = Yup.object().shape({
    text: Yup.string().required(FIELD_REQUIRED),
  })

  const handleSubmit = async ({ text }, { setErrors, resetForm }) => {
    try {
      await onSubmit({ contentId, text }).unwrap()
      resetForm()
    } catch (err) {
      setErrors(err.data)
    }
  }

  return (
    <StyledContainer {...props}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <StyledForm>
            <ProfilePicture user={user} size={40} sx={{ mr: 2 }} />
            <StyledInput
              fullWidth
              hideValidation
              inputProps={{ className: classes.inputElement }}
              multiline
              name="text"
              placeholder={`Write a ${responseName}...`}
            />
            <Stack sx={{ justifyContent: 'flex-end' }}>
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                isLoading={isSubmitting}
                handleSubmit={handleSubmit}
                disabled={!isValid}
              >
                <Icon src="/icon-arrow-right.svg" />
              </IconButton>
            </Stack>
          </StyledForm>
        )}
      </Formik>
    </StyledContainer>
  )
}
export default NewComment
