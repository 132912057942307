import { useActionDispatcher } from '../app'
import {
  createGedcomLine,
  deleteMediaOnSourceCitationLink,
  updateFactSource,
} from './pageSlice'
import React, { useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { DetailsAndNotes } from './FactsList'
import EditFactSourceDialog from './EditFactSourceDialog'
import EditButton from '../ui/EditButton'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { ConfirmDialog, IconButton } from '../ui'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { THUMB_SIZE_SMALL } from '../photo/Thumbnail'
import FactSourceImage from '../photo/FactSourceImage'

const GEDCOM_LINE_TAG_NAMES = {
  author: 'AUTH',
  title: 'TITL',
  note: 'NOTE',
  text: 'TEXT',
  page: 'PAGE',
  date: 'DATE',
}

const Source = params => {
  const {
    sourceObj,
    editSource,
    source,
    handleSourceClick,
    selectedSource,
    setNavigatorPhotos,
    handleDelete,
    refreshFacts,
    sourceCitation,
    linkMediaToSource,
  } = useSourceFunctions(params)

  return (
    <Box
      id={source?.id}
      boxShadow={2}
      borderRadius={1}
      my={0.6}
      p={1}
      display={sourceObj?.title ? 'auto' : 'none'}
      onClick={
        Boolean(handleSourceClick) ? () => handleSourceClick(source.id) : null
      }
      sx={{
        border: selectedSource === source?.id ? '2px solid' : '',
        cursor: Boolean(handleSourceClick) ? 'pointer' : 'auto',
      }}
      borderColor="primary"
    >
      <Box
        sx={{ display: 'flex', width: '100%' }}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Typography variant="body4">{sourceObj.title}</Typography>
          <Typography variant="body1">{sourceObj.author}</Typography>
          {sourceObj?.citation && (
            <SourceCitationDetails details={sourceObj?.citation} />
          )}
          {sourceObj.note && <DetailsAndNotes text={sourceObj.note} />}
        </Box>{' '}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            height: 'fit-content',
          }}
        >
          <EditFactSourceDialog
            key={source?.id}
            source={source}
            sourceObj={sourceObj}
            sourceCitation={sourceCitation}
            refreshFacts={refreshFacts}
            editSource={editSource}
            linkMediaToSource={linkMediaToSource}
            trigger={props => (
              <EditButton permissionAction={ACTION_ALL_ACCESS} {...props} />
            )}
          />
          <ConfirmDialog
            onConfirm={() => handleDelete(sourceCitation.id)}
            submitText="Remove"
            trigger={props => (
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                {...props}
                size="small"
              >
                <RemoveCircleIcon fontSize="1rem" />
              </IconButton>
            )}
          >
            <Typography>
              Are you sure you want to remove this source?
            </Typography>
          </ConfirmDialog>
        </Box>
      </Box>
      {sourceCitation?.citationMedia?.length > 0 && (
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-start"
          justifySelf="flex-start"
          maxWidth={THUMB_SIZE_SMALL * 6}
          spacing={1}
          mt={1}
        >
          {sourceCitation?.citationMedia?.map(mediaItem => (
            <FactSourceImage
              photo={mediaItem}
              isEditing={false}
              onClick={() =>
                setNavigatorPhotos(prevState => ({
                  media: sourceCitation?.citationMedia,
                  selectedPhoto: mediaItem,
                }))
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}

const SourceCitationDetails = ({ details }) => {
  const { date, note, page, text } = details

  return (
    <>
      {(date || note || page || text) && (
        <Box
          sx={{
            borderTop: '1px solid #ccc',
            py: 1,
            position: 'relative',
            width: '95%',
          }}
        >
          {page && <Typography>page: {page}</Typography>}
          {text && <Typography>text: {text}</Typography>}
          {note && <Typography>notes: {note}</Typography>}
          {date && <Typography>date: {date}</Typography>}
        </Box>
      )}
    </>
  )
}

const useSourceFunctions = ({
  sourceCitation,
  handleDelete,
  refreshFacts,
  linked = true,
  handleSourceClick,
  selectedSource,
  handleDialogWidth,
  setNavigatorPhotos,
  linkMediaToSource,
  updateSources,
}) => {
  const source = sourceCitation?.source || sourceCitation

  const dispatchUpdateFactSource = useActionDispatcher(updateFactSource)
  const dispatchCreateGedcomLine = useActionDispatcher(createGedcomLine)
  const dispatchDeleteSourceLink = useActionDispatcher(
    deleteMediaOnSourceCitationLink
  )

  const handleDisplaySource = () => {
    const gedLines = source?.gedcomParentLine || source?.gedcomLines
    const sourceObj = {}
    sourceObj.title = gedLines?.find(line => line.tagName === 'TITL')?.lineValue
    sourceObj.author = gedLines?.find(
      line => line.tagName === 'AUTH'
    )?.lineValue
    sourceObj.note = gedLines?.find(line => line.tagName === 'NOTE')?.lineValue

    let citations = {
      page: '',
      date: '',
      text: '',
      note: '',
    }
    const handleCitationData = citationLines => {
      citationLines?.forEach(item => {
        const tagName = item?.tagName?.toLowerCase()
        if (tagName === 'data') {
          handleCitationData(item?.childLines)
        }
        if (tagName in citations) {
          citations[tagName] = item?.lineValue
        }
      })
    }

    handleCitationData(sourceCitation?.sourceCitationGedcomParentLine)
    if (source.citationDetails) {
      sourceObj.citation = source.citationDetails
    } else {
      sourceObj.citation = citations
    }

    return sourceObj
  }

  const removeMediaFromSource = async (mediaIds, sourceCitationId) => {
    mediaIds.forEach(async mediaId => {
      try {
        await dispatchDeleteSourceLink({
          mediaId,
          instanceId: sourceCitationId,
        })
      } catch (err) {
        console.error(err)
      }
    })
  }

  const editSource = async fields => {
    let updatedSource = source
    const keys = Object.keys(fields)
    const citationKeys = Object.keys(fields?.citation)
    // Create a different note field for citation notes so it doesn't edit the wrong note
    citationKeys.push('citationNote')
    const noteIndex = citationKeys.indexOf('note')
    citationKeys.splice(noteIndex, 1)

    const allKeys = [...citationKeys, ...keys]

    allKeys.forEach(async (key, index) => {
      let fieldsObj = fields
      let gedcomParentLine = source?.gedcomParentLine

      if (!fields.hasOwnProperty(key)) {
        fieldsObj = fields['citation']
        gedcomParentLine = sourceCitation?.sourceCitationGedcomParentLine
      }
      let tagKey = key
      if (key === 'citationNote') {
        tagKey = 'note'
      }

      const tagName = GEDCOM_LINE_TAG_NAMES[tagKey]
      const lineValue = fieldsObj[tagKey]
      const gedLine = gedcomParentLine?.find(line => line.tagName === tagName)
      if (gedLine) {
        const updatedGedLine = await dispatchUpdateFactSource({
          id: gedLine.id,
          ...gedLine,
          lineValue,
        })
        if (!linked) {
          const lineIndex = gedcomParentLine?.indexOf(gedLine)
          updatedSource.gedcomParentLine[lineIndex] = updatedGedLine.payload
        }
      } else if (linked && tagName && lineValue) {
        let sourceId = source?.id
        if (!fields.hasOwnProperty(key)) {
          sourceId = sourceCitation?.id
        }

        dispatchCreateGedcomLine({
          sourceId,
          lineData: {
            tagName,
            lineValue,
          },
        })
      }
    })

    if (linked && fields?.citationMedia !== sourceCitation?.citationMedia) {
      const removedMedia = sourceCitation?.citationMedia
        .filter(
          originalObj =>
            !fields?.citationMedia.some(
              updatedObj => updatedObj.id === originalObj.id
            )
        )
        .map(removedObj => removedObj.id)
      const addedMedia = fields?.citationMedia
        .filter(
          updatedObj =>
            !sourceCitation?.citationMedia.some(
              originalObj => originalObj.id === updatedObj.id
            )
        )
        .map(addedObj => addedObj.id)

      if (addedMedia?.length) {
        linkMediaToSource(addedMedia, sourceCitation)
      }

      if (removedMedia?.length) {
        removeMediaFromSource(removedMedia, sourceCitation.id)
      }
    }

    if (!linked) {
      updatedSource.citationDetails = fields?.citation
      updatedSource.media = fields?.media
      updatedSource.citationMedia = fields?.citationMedia
      updateSources(updatedSource)
    }
  }

  useEffect(() => {
    if (source?.media?.length > 5) {
      handleDialogWidth()
    }
  }, [handleDialogWidth, source?.media])

  const sourceObj = handleDisplaySource()

  return {
    sourceObj,
    editSource,
    source,
    handleSourceClick,
    selectedSource,
    setNavigatorPhotos,
    handleDelete,
    refreshFacts,
    sourceCitation,
    linkMediaToSource,
  }
}

export default Source
