import { PrismicRichText, PrismicText } from '@prismicio/react'
import { Typography } from '../../ui'

const TitleAndRichText = ({ slice }) => {
  return (
    <div className="title-and-rich-text">
      <Typography variant="h3">
        <PrismicText field={slice.primary.title} />
      </Typography>
      <PrismicRichText field={slice.primary.text} />
    </div>
  )
}

export default TitleAndRichText
