import { Stack } from '@mui/material'
import { Typography } from 'src/modules/ui'

const ReportRow = ({ children, rowLabel }) => {
  return (
    <Stack direction="row" sx={{ mr: 6 }}>
      <Typography
        variant="h5"
        sx={{
          width: 120,
          display: 'block',
          alignSelf: 'center',
          textAlign: 'right',
          pr: 1,
        }}
      >
        {rowLabel}
      </Typography>
      {children}
    </Stack>
  )
}

export default ReportRow
