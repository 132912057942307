import { IconButton } from '@mui/material'
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export default function Refresh({ onClick }) {
  return (
    <IconButton
      permissionAction={ACTION_ALL_ACCESS}
      onClick={onClick}
      style={{ justifySelf: 'flex-end' }}
    >
      <RefreshIcon fontSize="medium" />
    </IconButton>
  )
}
