import React, { useCallback, useMemo, useState } from 'react'

import {
  Button,
  FormikTextField,
  FormikRadioGroup,
  ConfirmDialog,
} from 'src/modules/ui'

import { Form, Formik } from 'formik'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import { ACTION_EDIT } from '../app/appConstants'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import TagForm from '../writeArticle/TagForm'

export const GENDER_MALE = 'M'
export const GENDER_FEMALE = 'F'
export const GENDER_UNKNOWN = 'U'

export const STATE_ALIVE = 'ALIVE'
export const STATE_DECEASED = 'DECEASED'
export const STATE_UNKNOWN = 'UNKNOWN'

const setNullAndUndefinedToEmptyString = (data, ...keys) => {
  data = { ...data }
  for (const key of keys) {
    if (isUndefined(data[key]) || isNull(data[key])) {
      data[key] = ''
    }
  }
  return data
}
const setEmptyStringToNull = (data, ...keys) => {
  data = { ...data }
  for (const key of keys) {
    if (!data[key] && isEmpty(data[key])) {
      data[key] = null
    }
  }
  return data
}

export const CreateOrUpdateIndividualForm = ({
  disabled,
  individual,
  saving,
  onSubmit,
  submitButtonLabel = 'Submit',
  onChange,
  onDelete,
  defaultSurname,
  presetTargets = [],
}) => {
  const allPresetTargets = new Set([
    ...presetTargets,
    ...(individual ? individual.links.map(({ target }) => target) : []),
  ])

  const [targets, setTargets] = useState([...allPresetTargets])
  const initialValues = useMemo(() => {
    const initValuesObj = setNullAndUndefinedToEmptyString(
      { ...individual, surname: defaultSurname || individual?.surname } || {},
      'yearOfBirth',
      'yearOfDeath',
      'gender',
      'givenName',
      'surname',
      'knownAs',
      'placeOfBirthSingleLine',
      'placeOfDeathSingleLine',
      'dateOfBirthGed',
      'dateOfDeathGed'
    )
    return {
      ...initValuesObj,
    }
  }, [individual, defaultSurname])

  const handleChangeTags = (tags, values) => {
    setTargets(tags)
    handleValidate(values, tags)
  }

  const handleSubmit = useCallback(
    async data => {
      if (disabled) {
        return
      }
      data = prepData(data, targets)
      onSubmit && onSubmit(data)
    },
    [disabled, onSubmit, targets]
  )

  const handleDelete = useCallback(
    async data => {
      if (disabled) {
        return
      }
      onDelete && onDelete(data)
    },
    [disabled, onDelete]
  )

  const prepData = (values, targets) => {
    const data = setEmptyStringToNull(values, 'yearOfBirth', 'yearOfDeath')

    if ([STATE_ALIVE, STATE_UNKNOWN].includes(data.state)) {
      data.placeOfDeath = ''
      data.yearOfDeath = null
    }

    data.setTargets = targets

    return data
  }

  const handleValidate = (values, tags) => {
    const targetValues = tags || targets
    const data = prepData(values, targetValues)
    onChange && onChange(data)
    return {}
  }

  return (
    <Formik
      validate={handleValidate}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <Form>
          <>
            <Stack direction="row">
              <Box sx={{ width: '50%', minWidth: '50%' }}>
                <FormikRadioGroup
                  label="Gender"
                  value={values.gender}
                  optionLabelValues={[
                    ['Male', GENDER_MALE],
                    ['Female', GENDER_FEMALE],
                    ['Unknown', GENDER_UNKNOWN],
                  ]}
                  name="gender"
                  setFieldValue={setFieldValue}
                  disabled={disabled || isSubmitting || saving}
                />{' '}
                {/* radioGroupStyles={{ flexWrap: 'nowrap' }} */}
              </Box>
              <Box sx={{ display: 'inline-flex' }}>
                <Box sx={{ width: '50%', paddingRight: 1 }}>
                  <FormikTextField
                    fullWidth
                    label="Prefix"
                    margin="normal"
                    name="prefix"
                    disabled={disabled || isSubmitting || saving}
                  />
                </Box>
                <Box sx={{ width: '50%', paddingLeft: 1 }}>
                  <FormikTextField
                    fullWidth
                    label="Suffix"
                    margin="normal"
                    name="suffix"
                    disabled={disabled || isSubmitting || saving}
                  />
                </Box>
              </Box>
            </Stack>
            <Stack direction="row">
              <Box sx={{ width: '50%', paddingRight: 2 }}>
                <FormikTextField
                  fullWidth
                  label="Given name"
                  margin="normal"
                  name="givenName"
                  disabled={disabled || isSubmitting || saving}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormikTextField
                  fullWidth
                  label="Surname"
                  margin="normal"
                  name="surname"
                  disabled={disabled || isSubmitting || saving}
                />
              </Box>
            </Stack>
            <Stack direction="row">
              <Box sx={{ width: '50%', paddingRight: 2 }}>
                <FormikTextField
                  fullWidth
                  label="Known as"
                  margin="normal"
                  name="knownAs"
                  disabled={disabled || isSubmitting || saving}
                />
              </Box>
              <Box sx={{ width: '50%' }}>
                <FormikTextField
                  fullWidth
                  label="Title"
                  margin="normal"
                  name="title"
                  disabled={disabled || isSubmitting || saving}
                />
              </Box>
            </Stack>
            <FormikTextField
              fullWidth
              label="Alias"
              margin="normal"
              name="alias"
              disabled={disabled || isSubmitting || saving}
            />
            <FormikGedDatePicker
              fullWidth
              disabled={isSubmitting}
              label="Date of Birth (Preferred)"
              margin="normal"
              name="dateOfBirthGed"
              setFieldValue={setFieldValue}
            />
            <FormikTextField
              fullWidth
              label="Place of birth"
              margin="normal"
              name="placeOfBirthSingleLine"
              disabled={disabled || isSubmitting || saving}
            />
            <FormikRadioGroup
              label=""
              value={values.state}
              optionLabelValues={[
                ['Living', STATE_ALIVE],
                ['Deceased', STATE_DECEASED],
                ['Unknown', STATE_UNKNOWN],
              ]}
              name="state"
              setFieldValue={setFieldValue}
              disabled={disabled || isSubmitting || saving}
            />
            {values.state === STATE_DECEASED ? (
              <>
                <FormikGedDatePicker
                  fullWidth
                  disabled={isSubmitting}
                  label="Date of Death (Preferred)"
                  margin="normal"
                  name="dateOfDeathGed"
                  setFieldValue={setFieldValue}
                />
                <FormikTextField
                  fullWidth
                  label="Place of death"
                  disabled={
                    disabled ||
                    isSubmitting ||
                    saving ||
                    values.state !== STATE_DECEASED
                  }
                  margin="normal"
                  name="placeOfDeathSingleLine"
                />
              </>
            ) : null}
            <FormikTextField
              fullWidth
              label="General Notes"
              margin="normal"
              name="notes"
              multiline={true}
              minRows={3}
              disabled={disabled || isSubmitting || saving}
            />
            <TagForm
              subjectType={INSTANCE_TYPE_INDIVIDUAL}
              onChangeTags={tags => handleChangeTags(tags, values)}
              presetTargets={[...allPresetTargets]}
            />
          </>
          {onSubmit && (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              {onDelete ? (
                <ConfirmDialog
                  onConfirm={handleDelete}
                  submitText="Remove"
                  submitColor="error"
                  trigger={props => (
                    <Button
                      permissionAction={ACTION_EDIT}
                      permissionParams={{
                        instance: individual,
                        instanceType: INSTANCE_TYPE_INDIVIDUAL,
                      }}
                      {...props}
                      color="secondary"
                      variant="outlined"
                      isLoading={isSubmitting || saving}
                    >
                      Delete individual
                    </Button>
                  )}
                >
                  <Typography>
                    Deleting individuals with relations can create orphan nodes!
                    Are you sure you want to permanently delete{' '}
                    {individual.givenName}?
                  </Typography>
                </ConfirmDialog>
              ) : (
                <div></div>
              )}
              <Button
                permissionAction={ACTION_EDIT}
                permissionParams={{
                  instance: individual,
                  instanceType: INSTANCE_TYPE_INDIVIDUAL,
                }}
                isLoading={isSubmitting || saving}
                onClick={handleSubmit}
                color="primary"
              >
                {submitButtonLabel}
              </Button>
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  )
}
