import { Link } from 'react-router-dom'
import React from 'react'
import { usePermissions } from '../auth/authHooks'

const LinkWithPermissions = ({
  children,
  permissionAction,
  permissionParams,
  style,
  color,
  to,
}) => {
  const checkPermissions = usePermissions()
  const { hasPermission } = checkPermissions(permissionAction, permissionParams)

  if (hasPermission) {
    return (
      <Link style={style} color={color} to={to}>
        {children}
      </Link>
    )
  } else {
    return <>{children}</>
  }
}

export default LinkWithPermissions
