/*
 copys the config defaults to the state
 */
import pickBy from 'lodash/pickBy'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

export const updateStateWithViewConfigDefaults = (
  stateAccessor,
  viewConfigDefaults = [],
  stateKeyMap = {}
) => {
  viewConfigDefaults.forEach(config => {
    //state key map is used to map things like event to publicPageEvents

    const viewKey = stateKeyMap[config.viewKey] || config.viewKey

    if (stateAccessor[viewKey] !== undefined) {
      stateAccessor[viewKey].viewConfigDefaults = {
        hierarchical: config.hierarchical,
        ancestralOnly: config.ancestralOnly,
        sort: config.sort,
        showMap: config.showMap,
      }
    }
  })
}

export const getViewConfig = (stateAccessor, queryParamSettings = {}) => {
  if (!stateAccessor) {
    return queryParamSettings
  }

  if (stateAccessor.userHasUpdatedViewConfig === true) {
    return { ...stateAccessor }
  } else if (stateAccessor.viewConfigDefaults === undefined) {
    return { ...stateAccessor, ...queryParamSettings }
  } else {
    return { ...stateAccessor.viewConfigDefaults, ...queryParamSettings }
  }
}

export const useViewConfigQueryParams = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const sort = queryParams.get('sort')
  const hierarchical = queryParams.get('hierarchical') === 'true'
  const ancestralOnly = queryParams.get('ancestralOnly') === 'true'

  return useMemo(
    () =>
      pickBy({
        sort,
        hierarchical,
        ancestralOnly,
      }),
    [sort, hierarchical, ancestralOnly]
  )
}
