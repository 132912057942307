import { useDispatch, useSelector } from 'react-redux'
import { selectArticleIsSaving } from '../writeArticle/writeArticleSlice'
import Button from '../ui/Button'
import { makeStyles } from '@mui/styles'
import AiChatContainer from './AiChatContainer'
import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { clearTextGenerationResults, resetChatStream } from './servicesSlice'
import { DialogTitle } from '../ui'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import { ACTION_ALL_ACCESS, ACTION_MAKE_AI_REQUEST } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}))

const AiDialog = ({ trigger, children }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const handleShowModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    dispatch(clearTextGenerationResults())
  }

  return (
    <div>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle>AI Text Generator</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions sx={{ mr: 1, mb: 1, justifyContent: 'center' }}>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            variant="outlined"
            onClick={() => dispatch(resetChatStream())}
          >
            Reset Chat
          </Button>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            variant="outlined"
            onClick={handleCloseModal}
            sx={{ mr: 1 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const AiGeneratorButton = ({ actionText, isLoading }) => {
  const classes = useStyles()
  const isSaving = useSelector(selectArticleIsSaving)
  const showAlphaFeature = useIsAlphaFeatureUser()?.chatGPT

  return (
    <>
      {showAlphaFeature && (
        <AiDialog
          trigger={props => (
            <Button
              permissionAction={ACTION_MAKE_AI_REQUEST}
              {...props}
              className={classes.button}
              color="primary"
              size="large"
              isLoading={isLoading || isSaving}
            >
              {actionText}
            </Button>
          )}
        >
          <AiChatContainer />
        </AiDialog>
      )}
    </>
  )
}

export default AiGeneratorButton
