/* eslint react/prop-types: "warn", react/no-unused-prop-types: "warn", react/forbid-prop-types: "warn", react/destructuring-assignment: "warn",
          jsdoc/require-jsdoc: "warn", jsdoc/check-param-names: "warn", jsdoc/require-param-type: "warn", jsdoc/newline-after-description: "warn", jsdoc/check-alignment: "warn"
*/

import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Select, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

/**
 * @typedef {object} FormikSelectOptions
 * @property {string} key
 * @property {string} label
 */

/**
 * Callback for FormikSelect's onChange
 *
 * @callback FormikSelectOnChange
 * @param {string} values - the selected value
 */

/**
 * @param {object} props
 * @param {FormikSelectOptions[]} props.options
 * @param {FormikSelectOnChange} props.onChange
 * @param {string} props.label
 * @param {string} props.idPrefix
 */
const FormikSelect = ({ options, onChange, label, idPrefix, ...props }) => {
  const [field, meta] = useField(props)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="{idPrefix}-select-label">{label}</InputLabel>
      <Select
        sx={{ minWidth: 180 }}
        {...field}
        {...props}
        labelId="{idPrefix}-select-label"
        label={label}
        error={hasError}
        onChange={(e, node) => {
          const v = e.target.value
          if (onChange) {
            onChange(v)
          }
        }}
      >
        {options.map(o => {
          return (
            <MenuItem value={o.key} key={o.key}>
              {o.label ?? <>&nbsp;</>}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

FormikSelect.propTypes = {
  // eslint-disable-next-line
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  idPrefix: PropTypes.string,
}

export default FormikSelect
