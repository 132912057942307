import React, { useCallback, useMemo, useState } from 'react'
import { Dialog, useMediaQuery, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import {
  selectUserHomeIndividual,
  selectAuthorisedTreeSlug,
} from 'src/modules/auth/authSlice'
import {
  selectNodeDirectory,
  selectFamilies,
  selectFamilyByFamilyId,
  selectIndividualById,
  selectPartialCall,
} from './viewerSlice'
import {
  chooseFamilyId,
  clearSelectedIndividualIds,
  clearArbitraryVisibleIndividualIds,
  setExploredIndividualId,
  EXPLORE_VIEW_MODE_FAMILY,
  EXPLORE_VIEW_MODE_INDIVIDUAL,
  EXPLORE_VIEW_MODE_PREVIEW_SUBTREE,
  EXPLORE_VIEW_MODE_SUBTREE,
  selectArbitraryVisibleIndividualIds,
  selectExploredIndividualId,
  selectExploreViewMode,
  selectChosenFamilyId,
  selectSelectedIndividualIds,
} from 'src/modules/viewer/exploreTreeSlice'
import {
  ExploreTreeViewer,
  FOCUS_MODE_TOP_OF_TREE,
  FOCUS_MODE_EXPLORE_LINEAGE,
  FOCUS_MODE_EXPLORE_INDIVIDUAL,
} from './treeViewers'
import { processIndividualsAndUnionsToGraphNodes } from './api/graphNode'
import { DagManager } from './api/layout'
import { createExploredIndividualGraph } from './api/explore'
import { createLineageGraphCached } from './api/lineage'
import { createPyramid } from './api/nodeDirectory'

import TreeLoadingGuard from './TreeLoadingGuard'
import MobileTreeViewerMenu from './MobileTreeViewerMenu'
import TreeViewerMenu from './TreeViewerMenu'
import SearchTreeControl from './SearchTreeControl'
import { generateLinkForObject } from '../app/links'
import {
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
} from 'src/modules/app/links'
import { FamilyIdentifierTree } from '../ui/FamilyIdentifierTree'
import { formatIndividualName } from '../ui/individualUtils'

const useStyles = makeStyles(theme => ({
  exploreWindow: {
    position: 'relative',
  },

  searchControls: {
    width: '26%',
    maxWidth: '300px',
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
    padding: theme.spacing(2),
    zIndex: 100,
    background: 'white',
    borderRadius: theme.shape.borderRadius,
  },
}))

const Explore = ({
  closeAfterSubTreeCreated = false,
  onCreateSubTree,
  open = false,
  trigger,
  onCloseModal,
  traceLineageTo,
  selectMenuConfig = {},
  navigatorMode = false,
  openExplore,
  setOpenExplore,
  subTreeCaption,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const [modalOpenLocal, setModalOpenLocal] = useState(open)
  const [familyToPreview, setFamilyToPreview] = useState('')

  const modalOpen = openExplore || modalOpenLocal
  const setModalOpen = setOpenExplore || setModalOpenLocal

  const handleShowModal = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const history = useHistory()

  const handleForceCloseModal = useCallback(() => {
    dispatch(clearSelectedIndividualIds())
    dispatch(clearArbitraryVisibleIndividualIds())
    setModalOpen(false)
  }, [dispatch, setModalOpen])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    dispatch(clearSelectedIndividualIds())
    dispatch(clearArbitraryVisibleIndividualIds())
    if (onCloseModal) {
      onCloseModal()
    }
  }, [setModalOpen, onCloseModal, dispatch])

  const userHomeIndividual = useSelector(selectUserHomeIndividual)
  let exploreViewMode = useSelector(selectExploreViewMode)
  const selectedFamilyId = useSelector(selectChosenFamilyId)
  const familyObj = useSelector(selectFamilyByFamilyId(selectedFamilyId))

  const allFamilies = useSelector(selectFamilies)
  const nodeDirectory = useSelector(selectNodeDirectory)

  const arbitraryVisibleNodeIds = useSelector(
    selectArbitraryVisibleIndividualIds
  )
  const arbitraryVisibleNodes = useMemo(() => {
    return Array.from(arbitraryVisibleNodeIds).map(id => nodeDirectory[id])
  }, [arbitraryVisibleNodeIds, nodeDirectory])

  const selectedIndividualIds = useSelector(selectSelectedIndividualIds)

  let exploredIndividualNodeId = useSelector(selectExploredIndividualId)
  let exploredIndividualNode = useSelector(
    selectIndividualById(exploredIndividualNodeId)
  )

  if (!exploredIndividualNode && nodeDirectory[userHomeIndividual?.id]) {
    exploredIndividualNode = userHomeIndividual
  }

  const handleOnCreateSubTree = subTree => {
    if (closeAfterSubTreeCreated) {
      handleCloseModal()
    }
    if (onCreateSubTree) {
      onCreateSubTree(subTree)
    }
  }

  const handleFamilySelected = familyId => {
    setFamilyToPreview({})
    dispatch(chooseFamilyId(familyId))
  }

  const handleDeselection = () => {
    dispatch(setExploredIndividualId(userHomeIndividual.id))
  }

  const handleIndividualSelected = individualId => {
    dispatch(setExploredIndividualId(individualId))
  }

  const handleCurrentIndividualSelected = () =>
    handleIndividualSelected(userHomeIndividual.id)

  const navigateToFamily = (familyId, close) => {
    setModalOpen(false)
    history.push(
      generateLinkForObject(treeSlug, INSTANCE_TYPE_FAMILY, familyId)
    )
  }

  const navigateToIndividual = (individual, close) => {
    setModalOpen(false)
    history.push(
      generateLinkForObject(treeSlug, INSTANCE_TYPE_INDIVIDUAL, individual.id)
    )
  }

  const partialCallLoaded = useSelector(
    selectPartialCall(exploredIndividualNode?.id)
  )

  const [nodes, links, nLayers] = useMemo(() => {
    if (!exploredIndividualNode || !partialCallLoaded) {
      return []
    }
    if (exploreViewMode === EXPLORE_VIEW_MODE_FAMILY) {
      // Create the lineage graph relative to the most recently explored individual

      let { nodes, links, nLayers } = createLineageGraphCached(
        nodeDirectory,
        traceLineageTo || exploredIndividualNode,
        selectedFamilyId
      )

      return [nodes, links, nLayers]
    } else if (
      [EXPLORE_VIEW_MODE_SUBTREE, EXPLORE_VIEW_MODE_PREVIEW_SUBTREE].includes(
        exploreViewMode
      )
    ) {
      if (arbitraryVisibleNodes?.length) {
        const coreGraphNodes = processIndividualsAndUnionsToGraphNodes(
          arbitraryVisibleNodes
        )
        const dagManager = new DagManager(coreGraphNodes)
        return dagManager.layout()
      } else {
        return createExploredIndividualGraph(
          exploredIndividualNode,
          nodeDirectory,
          true
        )
      }
    } else if (exploreViewMode === EXPLORE_VIEW_MODE_INDIVIDUAL) {
      return createExploredIndividualGraph(
        exploredIndividualNode,
        nodeDirectory,
        true
      )
    } else {
      return []
    }
  }, [
    exploredIndividualNode,
    nodeDirectory,
    exploreViewMode,
    traceLineageTo,
    selectedFamilyId,
    arbitraryVisibleNodes,
    partialCallLoaded,
  ])

  const pyramid = useMemo(() => {
    if (
      navigatorMode &&
      allFamilies.length &&
      !isEmpty(nodeDirectory) &&
      exploredIndividualNode
    ) {
      return createPyramid(nodeDirectory, exploredIndividualNode, allFamilies)
    } else {
      return null
    }
  }, [allFamilies, navigatorMode, nodeDirectory, exploredIndividualNode])

  const focusMode = {
    [EXPLORE_VIEW_MODE_FAMILY]: FOCUS_MODE_EXPLORE_LINEAGE,
    [EXPLORE_VIEW_MODE_INDIVIDUAL]: FOCUS_MODE_EXPLORE_INDIVIDUAL,
    [EXPLORE_VIEW_MODE_PREVIEW_SUBTREE]: FOCUS_MODE_TOP_OF_TREE,
    [EXPLORE_VIEW_MODE_SUBTREE]: FOCUS_MODE_TOP_OF_TREE,
  }[exploreViewMode]

  const displayName = formatIndividualName(exploredIndividualNode)

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullScreen={navigatorMode && !mobileBreakpoint ? false : true}
        fullWidth={navigatorMode && !mobileBreakpoint ? false : true}
        maxWidth={'100%'}
      >
        <TreeLoadingGuard partialCallKey={exploredIndividualNode?.id}>
          <div className={classes.exploreWindow}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <div className={classes.searchControls}>
                <SearchTreeControl
                  {...{
                    handleDeselection,
                    handleFamilySelected,
                    handleIndividualSelected,
                    handleCurrentIndividualSelected,
                    nodeDirectory,
                    allFamilies,
                    setFamilyToPreview,
                  }}
                />
              </div>
            </Box>
            {modalOpen && (
              <>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                  <MobileTreeViewerMenu
                    {...{
                      handleDeselection,
                      handleFamilySelected,
                      handleIndividualSelected,
                      handleCurrentIndividualSelected,
                      nodeDirectory,
                      allFamilies,
                      familyObj,
                      pyramid,
                      navigateToFamily,
                      exploredIndividualNode,
                      navigateToIndividual,
                      displayName,
                      handleForceCloseModal,
                    }}
                  />
                </Box>
                <ExploreTreeViewer
                  nodes={nodes}
                  links={links}
                  allowCreateSubTree={!!onCreateSubTree}
                  navigatorMode={navigatorMode}
                  navigateToNodeOnDoubleClick={true}
                  navigateToNodeOnDoubleClickHandler={handleForceCloseModal}
                  selectMenuConfig={selectMenuConfig}
                  nLayers={nLayers}
                  selectedIndividualIds={selectedIndividualIds}
                  onCreateSubTree={handleOnCreateSubTree}
                  focusMode={focusMode}
                  subTreeCaption={subTreeCaption}
                  onCloseViewerModal={handleCloseModal}
                  isSubTree={
                    arbitraryVisibleNodeIds.size > 0 &&
                    (exploreViewMode === EXPLORE_VIEW_MODE_PREVIEW_SUBTREE ||
                      exploreViewMode === EXPLORE_VIEW_MODE_SUBTREE)
                  }
                />
                {!familyToPreview?.family && (
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <TreeViewerMenu
                      {...{
                        userHomeIndividual,
                        displayName,
                        exploredIndividualNode,
                        familyObj,
                        handleCloseModal,
                        handleFamilySelected,
                        handleForceCloseModal,
                        navigatorMode,
                        navigateToFamily,
                        navigateToIndividual,
                        pyramid,
                      }}
                    />
                  </Box>
                )}
              </>
            )}
          </div>
        </TreeLoadingGuard>
        {familyToPreview?.family && !mobileBreakpoint && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 99,
            }}
          >
            <FamilyIdentifierTree
              option={{ ...familyToPreview, id: familyToPreview.family }}
              fullScreen={true}
              explore={true}
            />
          </Box>
        )}
      </Dialog>
    </>
  )
}

export default Explore
