import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Box, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar } from 'src/modules/ui'
import MobileNavBar from 'src/modules/app/MobileNavBar'
import { setLastLocation } from '../app/appSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TextAnimation from '../ui/TextAnimation'
import { Logo } from '../app/NavBar'
import { useEmbed } from './hooks'
import { GradientButton } from './PublicNavBar'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { ga4Events } from '../analytics/AnalyticsUtils'

const PublicShareNavBar = ({ baseUrl }) => {
  const mobileBreakpoint = useMediaQuery(theme =>
    theme.breakpoints.down('1080px')
  )

  const embed = useEmbed()

  const history = useHistory()
  const dispatch = useDispatch()

  const [displayBtn, setDisplayBtn] = useState(false)

  useEffect(() => {
    const pathname = history.location.pathname
    return () => {
      dispatch(setLastLocation(pathname))
    }
  }, [dispatch, history.location.pathname])

  return (
    <>
      {!embed && (
        <AppBar
          leftSide={
            <>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <MobileNavBar
                  isPublic={true}
                  homeUrl={`${baseUrl}/home/tree`}
                  trigger={navBarTrigger => (
                    <IconButton
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={navBarTrigger.onClick}
                      color="inherit"
                      sx={{ mr: 1 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                />
              </Box>
              {mobileBreakpoint ? (
                <Link to={`${baseUrl}/`}>
                  <Logo alt="weare.xyz" src="/logo.png" />
                </Link>
              ) : (
                <div style={{ width: 240, position: 'relative' }}>
                  <TextAnimation
                    baseUrl={baseUrl}
                    setDisplayBtn={setDisplayBtn}
                  />
                </div>
              )}
            </>
          }
          rightSide={
            <>
              {!mobileBreakpoint ? (
                <GradientButton
                  text="Find Out More"
                  opacity={displayBtn ? 1 : 0}
                  gaEvent={ga4Events.PUBLIC_HEADER_BUTTON_FIND_OUT_MORE}
                />
              ) : null}
            </>
          }
        />
      )}
    </>
  )
}

export default PublicShareNavBar
