import FabButton from './FabButton'
import CodeIcon from '@mui/icons-material/Code'
import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import Button from './Button'
import copy from 'copy-to-clipboard'
import { template } from 'lodash'
import { generateLinkForPublicRoot } from '../app/links'
import { useSelector } from 'react-redux'
import { selectUser } from '../auth/authSlice'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const embedTemplate = template(
  '<iframe height="800px" width="800px" src="<%= url %>?embed=true">'
)

const EmbedCode = ({ buttonText }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const publicUrl = generateLinkForPublicRoot(window.location.pathname)
  const user = useSelector(selectUser)
  const isPublic = user?.currentTree?.allowPublicVisibility
  const embedHtml = embedTemplate({ url: publicUrl })

  const handleShowModal = () => {
    copy(embedHtml)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <FabButton
        permissionAction={ACTION_ALL_ACCESS}
        disabled={!isPublic}
        text={buttonText}
        icon={<CodeIcon fontSize="medium" sx={{ mb: 0.2 }} />}
        onClick={handleShowModal}
      />
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{buttonText}</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={1}>
            <div>
              The following code has been copied to the clipboard. Copy this to
              your blog or website.
            </div>
            <code>{embedHtml}</code>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            onClick={() => handleCloseModal()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EmbedCode
