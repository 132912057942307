import { Dialog, DialogContent, DialogActions } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Form } from 'formik'

import { Button, DialogTitle, Typography } from 'src/modules/ui'

import IndividualLineForInfoRequest from './IndividualLineForInfoRequest'
import TypeAheadInfoRequest from './TypeAheadInfoRequest'
import { Users } from '../accountAdmin/Users'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 2, 2),
  },
  askButton: {
    marginLeft: theme.spacing(0.5),
  },
  checkedErrMsg: {
    marginLeft: theme.spacing(3),
    color: theme.palette.error.main,
  },
  content: {
    padding: theme.spacing(0, 1, 1),
  },
}))

const SendInfoRequestDialog = ({
  open,
  onSubmit,
  isSubmitting,
  onClose,
  individuals,
  loading,
  onGoBack,
  checkedUserError,
  allowInvite,
  availableRoles,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'md'}
      onClose={onClose}
      className={classes.dialog}
      scroll={'paper'}
    >
      <Form>
        <DialogTitle onClose={onClose}>Ask Family!</DialogTitle>
        <DialogContent className={classes.content}>
          <Users
            allUsers={individuals}
            loading={loading}
            title="Ask Who?"
            IndividualComponent={IndividualLineForInfoRequest}
            TypeAheadComponent={TypeAheadInfoRequest}
            container="info_request"
            availableRoles={availableRoles}
            showSectionRegistered={false}
            showSectionPending={false}
            showSectionNotOnTree={false}
            showSectionInviteNotOnTree={false} // checkboxes don't work where there is no individual with id to use as the value
            showSectionNotOnTreeBottom={false} // checkboxes don't work where there is no individual with id to use as the value
            allowInvite={allowInvite}
          />
        </DialogContent>
        <div className={classes.footer}>
          {checkedUserError && (
            <Typography className={classes.checkedErrMsg}>
              {checkedUserError}
            </Typography>
          )}
          <DialogActions className={classes.actions}>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              size="large"
              onClick={onGoBack}
            >
              Back
            </Button>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              color="primary"
              onClick={onSubmit}
              isLoading={isSubmitting}
              className={classes.askButton}
              size="large"
            >
              Ask Family!
            </Button>
          </DialogActions>
        </div>
      </Form>
    </Dialog>
  )
}

export default SendInfoRequestDialog
