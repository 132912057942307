import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import { styled, Box, Divider } from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { Link, Typography, IconButton } from 'src/modules/ui'
import ContentTextBlock from './ContentTextBlock'
import LinkWithHash from 'src/modules/ui/LinkWithHash'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_INFORMATION_REQUEST,
  generateLinkForObject,
  generateTreeLink,
} from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { ARTICLE_STATE_DRAFT } from 'src/modules/writeArticle/WriteArticle'

import {
  subtitleStyles,
  titleStyles,
  blockquoteStyles,
  bodyTextStyles,
} from './Article'

import { formatIndividualName } from '../ui/individualUtils'
import { selectCurrentTree, selectIsBlogTree } from '../auth/authSlice'
import {
  generateBlogPreviewLink,
  generateTreeOverrideQueryString,
} from '../app/links'
import { BLOG_TREE } from '../common/constants'
import { formatDate } from '../../utils'
import { useHistory } from 'react-router-dom'
import { ARTICLE_STATE_PUBLISHED } from '../writeArticle/WriteArticle'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const defaultImage = '/image-default.svg'

//original height constant was 485 so 363 roughly 75%.
// changed to 291 so 60% of original height of 485
const CARD_HEIGHT = 291

export const CardRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: CARD_HEIGHT,
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  width: '100%',
  '& p, blockquote, ol, ul': bodyTextStyles,
  '& h1, h2, h3, blockquote, span': {
    fontFamily: 'IBM Plex Sans',
  },
  '& h4': titleStyles,
  '& h5': subtitleStyles,
  '& blockquote': blockquoteStyles,
}))

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  width: '25%',
  height: CARD_HEIGHT,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}))

export const ContentContainer = styled('div')({
  justifyContent: 'flex-start',
  display: 'flex',
  flexGrow: 1,
  width: '50%',
})

export const InnerContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const FooterContainer = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
})

export const FooterItemContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
})

const StyledLink = styled(Link)({
  textDecoration: 'none',
})

const StyledHashLink = styled(LinkWithHash)({
  textDecoration: 'none',
})

export const ArticleCard = ({
  disableUp,
  disableDown,
  handleMoveContentDown,
  handleMoveContentUp,
  isDraggable = false,
  PinPostDialog,
  content: {
    authorIndividual,
    author,
    comments,
    contentBlocks = [],
    id,
    modified,
    created,
    publishedAt,
    previewThumbnail,
    state,
    title,
    titleDraft,
    type,
    treeSlug,
  },
}) => {
  const { surname } = author
  const isBlogTree = useSelector(selectIsBlogTree)
  const currentTree = useSelector(selectCurrentTree)
  const history = useHistory()

  let url
  let showComments = !isBlogTree
  let linkText = state === 'DRAFT' ? '[Edit]' : '[Read More]'

  if (
    isBlogTree ||
    ((type === INSTANCE_TYPE_ARTICLE || type === INSTANCE_TYPE_DOCUMENT) &&
      state === ARTICLE_STATE_DRAFT)
  ) {
    const queryString =
      isBlogTree && currentTree?.slug !== treeSlug
        ? generateTreeOverrideQueryString(currentTree?.slug, BLOG_TREE)
        : ''
    if (state === 'PUBLISHED') {
      url = generateBlogPreviewLink(treeSlug, '', id, currentTree?.slug)
    } else {
      const path =
        type === INSTANCE_TYPE_DOCUMENT ? 'edit-document' : 'write-article'
      url = generateTreeLink(treeSlug, `${path}/${id}`, queryString)
    }
  } else {
    url = generateLinkForObject(treeSlug, type, id)
  }

  const displayName = formatIndividualName(author)

  const textContent = contentBlocks.filter(block => block.type === 'TEXT')

  const handleNavigate = () => {
    history.push(url)
  }

  return (
    <>
      <CardRoot onClick={handleNavigate}>
        <StyledImage src={previewThumbnail || defaultImage} />
        <ContentContainer sx={{ ml: 2 }}>
          <InnerContainer>
            <CardContent
              {...{
                disableDown,
                disableUp,
                handleMoveContentDown,
                handleMoveContentUp,
                id,
                isDraggable,
                textContent,
                title,
                titleDraft,
                url,
                linkText,
                PinPostDialog,
                state,
              }}
            />
            <FooterContainer>
              <FooterArticleAuthor
                {...{
                  authorIndividual,
                  displayName,
                  modified,
                  created,
                  publishedAt,
                  state,
                  surname,
                }}
              />
              {showComments && <FooterComments {...{ comments, type, url }} />}
            </FooterContainer>
          </InnerContainer>
        </ContentContainer>
      </CardRoot>
      <Divider sx={{ mb: 4, mt: 4 }} />
    </>
  )
}

const FooterArticleAuthor = ({
  authorIndividual,
  displayName,
  modified,
  created,
  publishedAt,
  state,
  surname,
}) => {
  const isBlogTree = useSelector(selectIsBlogTree)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  // there is a case where author individual could be null - e.g when you have a demo tree and the author is jean poole!
  const link = authorIndividual?.id ? (
    <StyledLink
      to={generateLinkForObject(
        treeSlug,
        INSTANCE_TYPE_INDIVIDUAL,
        authorIndividual.id
      )}
    >
      {displayName}
    </StyledLink>
  ) : (
    <>{displayName}</>
  )

  return (
    <FooterItemContainer>
      {isBlogTree && state === 'DRAFT' && (
        <Typography variant="subtitle3">
          {state}
          &nbsp;
        </Typography>
      )}
      <Typography variant="subtitle4">
        {link} {formatDate(created)}
        &nbsp;
      </Typography>
      {created !== modified && (
        <DateTime dateTime={state === 'DRAFT' ? modified : publishedAt} />
      )}
    </FooterItemContainer>
  )
}

export const DateTime = ({ dateTime }) => (
  <Typography variant="subtitle4">
    {'\u2022'}
    &nbsp; Last updated {formatDate(dateTime)}
  </Typography>
)

const commentType = {
  [INSTANCE_TYPE_INFORMATION_REQUEST]: 'Reply',
  [INSTANCE_TYPE_ARTICLE]: 'Comment',
  [INSTANCE_TYPE_DOCUMENT]: 'Comment',
}
const FooterComments = ({ comments, type, url }) => (
  <FooterItemContainer>
    <CommentIcon sx={{ mr: 1 }} />
    <Typography variant="subtitle4">
      <StyledHashLink smooth to={url + '#comments'}>
        {comments.length > 0
          ? `${pluralize(commentType[type])} (${comments.length})`
          : `Add ${commentType[type]}`}
      </StyledHashLink>
    </Typography>
  </FooterItemContainer>
)

export const CardContent = ({
  disableDown,
  disableUp,
  handleMoveContentDown,
  handleMoveContentUp,
  id,
  isDraggable,
  textContent,
  title,
  titleDraft,
  url,
  linkText,
  PinPostDialog,
  state,
}) => (
  <div>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        sx={{
          mb: 1,
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography
          onClick={e => e.preventDefault()}
          to={url}
          component={Link}
          color="primary"
          underline="hover"
          variant="h4"
        >
          {state === ARTICLE_STATE_PUBLISHED ? title : titleDraft}
        </Typography>
      </Box>
      {PinPostDialog && state === 'PUBLISHED' && (
        <div onClick={e => e.stopPropagation()}>
          <PinPostDialog contentId={id} />
        </div>
      )}
      {isDraggable && (
        <Box sx={{ display: 'flex' }}>
          <IconButton
            disabled={disableDown}
            permissionAction={ACTION_ALL_ACCESS}
            onClick={event => handleMoveContentDown({ id, event })}
            sx={{ mr: 2 }}
            white
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            disabled={disableUp}
            onClick={event => handleMoveContentUp({ id, event })}
            white
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      )}
    </Box>
    <ContentTextBlock textContentBlocks={textContent} parent="ArticleCard" />
    <Typography
      onClick={e => e.preventDefault()}
      component={Link}
      variant="subtitle3"
      underline="hover"
    >
      {linkText}
    </Typography>
  </div>
)

ArticleCard.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
}

const ArticleCardContainer = ArticleCard

export default ArticleCardContainer
