import PropTypes from 'prop-types'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { ConfirmDialog, Typography } from './'

const NoTagsConfirmationDialog = forwardRef(
  (
    { open, onConfirm = async () => {}, proceedWithoutTagsText, bodyText },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(open)
    const [modalContinueWithSave, setModalContinueWithSave] =
      useState(onConfirm)

    useImperativeHandle(ref, () => ({
      showDialog(callback) {
        if (callback) {
          setModalContinueWithSave(() => callback)
          // https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
        }
        setModalOpen(true)
      },
    }))

    return (
      <>
        <ConfirmDialog
          submitText="Add tags"
          cancelText={proceedWithoutTagsText}
          onCancel={modalContinueWithSave}
          onClose={() => setModalOpen(false)}
          open={modalOpen}
        >
          <Typography style={{ alignSelf: 'center' }}>{bodyText}</Typography>
        </ConfirmDialog>
      </>
    )
  }
)

NoTagsConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.any,
  proceedWithoutTagsText: PropTypes.string,
  bodyText: PropTypes.string,
}

NoTagsConfirmationDialog.defaultProps = {
  open: false,
  proceedWithoutTagsText: 'Save without tags',
  bodyText:
    'You have not tagged any families, individuals, artifacts, occasions or places',
}

export default NoTagsConfirmationDialog
