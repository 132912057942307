import React, { useEffect } from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@mui/styles'

import { Typography } from 'src/modules/ui'
import { useNotification } from 'src/modules/app/hooks'

import ImagePreviewTile from './ImagePreviewTile'
import { imgTypes } from './MediaDetail'
import { ACCEPT_UPLOAD_IMAGE } from './index'

const useStyles = makeStyles(theme => ({
  dropzone: {
    borderStyle: 'dashed',
    borderWidth: 2,
    cursor: 'pointer',
    backgroundColor: theme.palette.purpleShadow.main,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  inactiveDropzone: {
    borderColor: theme.palette.primary.main,
  },
  activeDropzone: {
    borderColor: theme.palette.lightGrey.main,
  },
  dropzoneText: {
    justifyContent: 'center',
    display: 'flex',
    margin: theme.spacing(8, 0),
  },
  subTitle: {
    marginTop: theme.spacing(1),
  },
  thumbsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    cursor: 'default',
    justifyContent: 'center',
    margin: theme.spacing(1),
    pointerEvents: 'none',
  },
  thumbsContainerInline: {
    display: 'inline-flex',
  },
  thumbsContainerFlex: {
    display: 'flex',
  },
}))

export const handleImgName = fileName => {
  let imgDisplayTitle = fileName
  imgTypes.forEach(type => {
    const titleType = fileName.substr(fileName.length - type.length)
    if (titleType.toLowerCase() === type.toLowerCase()) {
      imgDisplayTitle = fileName.substr(0, imgDisplayTitle.length - type.length)
    }
  })

  return imgDisplayTitle
}

const MediaDropzone = ({
  maxFiles,
  previewTiles,
  onChangePreviewTiles,
  hideCaptions,
  acceptFiles = ACCEPT_UPLOAD_IMAGE,
  ...props
}) => {
  const classes = useStyles()
  const { showError } = useNotification()

  const hideDropzone = maxFiles && previewTiles.length >= maxFiles

  const handleRemovePreviewTile = ({ id: existingId }) => {
    const updatedPreviewtiles = previewTiles.filter(
      ({ id }) => id !== existingId
    )
    onChangePreviewTiles(updatedPreviewtiles)
  }

  const handleUpdateTitle = ({ id: existingId, newTitle, oldTitle }) => {
    const updatedPreviewtiles = previewTiles.map(({ name, id, ...rest }) =>
      id === existingId && name === oldTitle
        ? { name: newTitle, id, ...rest }
        : { name, id, ...rest }
    )
    onChangePreviewTiles(updatedPreviewtiles)
  }

  const handleOnUpdateDates = ({ id: existingId, dateTakenGed }) => {
    const updatedPreviewtiles = previewTiles.map(({ id, ...rest }) =>
      id === existingId ? { id, ...rest, dateTakenGed } : { id, ...rest }
    )
    onChangePreviewTiles(updatedPreviewtiles)
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: acceptFiles,
      onDrop: async acceptedFiles => {
        const filesForPreview = await Promise.all(
          acceptedFiles.map(async file => {
            const imgNameVal = handleImgName(file?.name)
            return {
              id: uuid(),
              name: imgNameVal,
              description: '',
              file: Object.assign(file, {
                preview: URL.createObjectURL(file),
              }),
              dateTakenGed: '',
            }
          })
        )
        if (maxFiles === 1) {
          onChangePreviewTiles(filesForPreview)
        } else {
          onChangePreviewTiles([...previewTiles, ...filesForPreview])
        }
      },
      maxFiles,
      noClick: hideDropzone,
      noKeyboard: hideDropzone,
      ...props,
    })

  useEffect(() => {
    if (fileRejections.length > 0) {
      const { errors } = fileRejections[0]
      const { message } = errors[0]
      showError(message)
    }
  }, [fileRejections, showError, previewTiles])

  useEffect(() => {
    // Revoke the data URIs when componet closes to avoid memory leaks
    return () => {
      for (let { file } of previewTiles) {
        URL.revokeObjectURL(file.preview)
      }
    }
    // If `filesWithTitles` is included in dependencies then image previews
    // break when dragging multiple images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={clsx({
          [classes.dropzone]: !hideDropzone,
          [classes.activeDropzone]: !hideDropzone && !isDragActive,
          [classes.inactiveDropzone]: isDragActive,
        })}
        {...getRootProps()}
      >
        <div
          className={clsx({
            [classes.dropzoneText]: previewTiles.length === 0,
          })}
        >
          <Typography
            mt={previewTiles.length === 0 ? 0 : 1}
            style={{ textAlign: 'center' }}
          >
            {isDragActive
              ? 'Drop here.'
              : 'Drop files here or click to add photos/media (.jpg, .png, .heic, .pdf)'}
          </Typography>
          <input {...getInputProps()} />
          <div
            onClick={e => {
              e.stopPropagation()
            }}
            className={clsx({
              [classes.thumbsContainer]: true,
              [classes.thumbsContainerInline]: !hideDropzone,
              [classes.thumbsContainerFlex]: hideDropzone,
            })}
          >
            {previewTiles.map((tile, i) => {
              return (
                <ImagePreviewTile
                  {...{
                    ...tile,
                    key: i,
                    onUpdateTitle: handleUpdateTitle,
                    onRemovePreviewTile: handleRemovePreviewTile,
                    onUpdateDates: handleOnUpdateDates,
                    updateTileWithExifDate: handleOnUpdateDates,
                    hideCaptions,
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaDropzone
