import { useDispatch, useSelector } from 'react-redux'

import { selectUser } from 'src/modules/auth/authSlice'

import {
  ADD_TYPE_SELF,
  FLEXIBLE_HEIGHT_PADDING,
  FOCUS_MODE_SCROLL,
  INDVDL_NODE_GRID_HEIGHT,
  MIN_VIEWER_HEIGHT,
  SIZE_MODE_FIT_NODES,
} from './constants'
import { useStyles } from './viewerStyles'
import {
  clearClickedIndividualId,
  selectAddIndividualNode,
  selectClickedIndividualId,
  selectEditedIndividualId,
  setEditedIndividualId,
} from '../exploreTreeSlice'
import { selectIndividualById, selectNodeDirectory } from '../viewerSlice'
import { useSetUpAndScrollTreeContainer } from './hooks'
import AddIndividualNode from './AddIndividualNode'
import AddIndividualDialog from './AddIndividualDialog'
import { createEditIndividualGraph } from '../api/editIndividualGraph'
import EditTreeViewer from './EditTreeViewer'
import { useCallback } from 'react'

const EditTreeContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const editedIndividualId = useSelector(selectEditedIndividualId)
  const user = useSelector(selectUser)
  const editedIndividualNode =
    nodeDirectory[editedIndividualId || user.individualOnTree?.id]

  const addIndividualNode = useSelector(selectAddIndividualNode)

  const clickedIndividualId = useSelector(selectClickedIndividualId)
  let clickedIndividual = useSelector(selectIndividualById(clickedIndividualId))

  let nodes,
    links = [],
    nLayers = 1
  if (editedIndividualNode) {
    ;[nodes, links, nLayers] = createEditIndividualGraph(
      editedIndividualNode,
      nodeDirectory,
      true
    )
  } else {
    nodes = [
      new AddIndividualNode({
        user,
        x: 0,
        y: 0,
        typeToAdd: ADD_TYPE_SELF,
      }),
    ]
  }

  const [scrollRef, widthToFitAllNodes] = useSetUpAndScrollTreeContainer({
    sizeMode: SIZE_MODE_FIT_NODES,
    focusMode: FOCUS_MODE_SCROLL,
    graphNodes: nodes,
    targetIndividualNode: editedIndividualNode,
  })

  const heightToFitAllNodes = Math.max(
    INDVDL_NODE_GRID_HEIGHT * nLayers + FLEXIBLE_HEIGHT_PADDING,
    MIN_VIEWER_HEIGHT
  )

  const handleExplore = useCallback(() => {
    dispatch(clearClickedIndividualId())
    dispatch(setEditedIndividualId(clickedIndividual.id))
  }, [dispatch, clickedIndividual])

  return (
    <>
      <AddIndividualDialog addIndividualNode={addIndividualNode} />

      <div className={classes.treeBackground}>
        <div ref={scrollRef} className={classes.horizontalScrollContainer}>
          <EditTreeViewer
            clickedIndividual={clickedIndividual}
            nodes={nodes}
            links={links}
            nLayers={nLayers}
            width={widthToFitAllNodes}
            height={heightToFitAllNodes}
            showNodeContextMenu={true}
            exploreOveride={handleExplore}
          />
        </div>
      </div>
    </>
  )
}
export default EditTreeContainer
