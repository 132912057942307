import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Check from '@mui/icons-material/Check'
import Box from '@mui/material/Box' // Import Box component for layout
import Typography from '@mui/material/Typography'
import { sortTypes } from '../common/constants'
import {
  Checkbox,
  IconButton as MuiIconButton,
  styled,
  Tooltip,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from './Button'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const SORT_PUBLISHED = 'SORT_PUBLISHED'
export const SORT_CURATED = 'SORT_CURATED'
export const SORT_ALPHABETICAL = 'SORT_ALPHABETICAL'
export const SORT_DATE_TAKEN = 'SORT_DATE_TAKEN'

export const SORT_DATE_TAKEN_NEWEST = 'SORT_DATE_TAKEN_NEWEST'

export const SORT_CREATED = 'SORT_CREATED'

export const SORT_FULL_NAME_ASC = 'SORT_FULL_NAME_ASC'

export const SORT_FULL_NAME_DESC = 'SORT_FULL_NAME_DESC'

const sortDefinitions = {
  SORT_PUBLISHED: {
    label: 'Most Recent',
    description: 'sorts items from newest to oldest',
    value: sortTypes.PUBLISHED,
  },
  SORT_CURATED: {
    label: 'Curated',
    description: 'sorts in a user selected order',
    value: sortTypes.USER,
  },
  SORT_ALPHABETICAL: {
    label: 'Order By Title',
    description: 'sorts items from alphabetically by title',
    value: sortTypes.ALPHABETICAL,
  },
  SORT_DATE_TAKEN: {
    label: 'Date Taken',
    description: 'sorts media by the date taken',
    value: sortTypes.DATE_TAKEN,
  },
  SORT_DATE_TAKEN_NEWEST: {
    label: 'Date Taken (Newest)',
    description: 'sorts media by the date taken - newest first',
    value: sortTypes.DATE_TAKEN_NEWEST,
  },
  SORT_CREATED: {
    label: 'Date Added',
    description: 'sorts media by the date added to the archive',
    value: sortTypes.CREATED,
  },
  SORT_FULL_NAME_ASC: {
    label: 'By name (A-Z)',
    description: 'sorts by name ascending',
    value: sortTypes.FULL_NAME_ASC,
  },
  SORT_FULL_NAME_DESC: {
    label: 'By name (Z-A)',
    description: 'sorts by name descending',
    value: sortTypes.FULL_NAME_DESC,
  },
}

export const VIEW_TYPE_FLAT = 'VIEW_TYPE_FLAT'
export const VIEW_TYPE_HIERARCHICAL = 'VIEW_TYPE_HIERARCHICAL'

// Define the view type items with optional descriptions
const hierarchicalDefinitions = {
  VIEW_TYPE_FLAT: {
    label: 'Flat',
    description: 'displays items in a single-level list',
    value: false,
  },
  VIEW_TYPE_HIERARCHICAL: {
    label: 'Hierarchical',
    description: 'show items in a hierarchy e.g town, then street',
    value: true,
  }, // No description provided
}

export const FILTER_ANCESTRAL_ONLY = 'FILTER_ANCESTRAL_ONLY'
export const FILTER_ALL = 'FILTER_ALL'

const ancestralOnlyDefinitions = {
  FILTER_ANCESTRAL_ONLY: {
    label: 'Ancestral Families',
    description:
      'show items that are linked to your ancestral families or authored by you',
    value: true,
  },
  FILTER_ALL: {
    label: 'All In Archive',
    description: 'show all items in the archive',
    value: false,
  },
}

export const FILTER_PLACES_SHOW_MAP = 'FILTER_PLACES_SHOW_MAP'
export const FILTER_PLACES_LIST_ONLY = 'FILTER_PLACES_LIST_ONLY'

const showMapDefinitions = {
  FILTER_PLACES_SHOW_MAP: {
    label: 'Show Map',
    description: 'show a map of the places that have a location',
    value: true,
  },
  FILTER_PLACES_LIST_ONLY: {
    label: 'Hide Map',
    description: 'show just a list of places',
    value: false,
  },
}

const ViewConfigTitleMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  '&:hover': { backgroundColor: theme.palette.primary.main },
  pointerEvents: 'none',
}))

const ViewConfigTitle = () => {
  return (
    <>
      <ViewConfigTitleMenuItem>
        <Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>
          SETTINGS FOR THIS PAGE
        </Typography>
      </ViewConfigTitleMenuItem>
    </>
  )
}

const ViewConfigItemBlock = ({ items, selectedItem, handleItemClick }) => {
  if (items.length === 0) return null

  return (
    <>
      {items.map(item => (
        <MenuItem
          key={item.id}
          onClick={() => handleItemClick(item.value)}
          dense
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {item.label}
              </Typography>
              {item.description && (
                <Typography
                  variant="caption"
                  sx={{
                    whiteSpace: 'normal', // Allows text to wrap
                    maxWidth: '100%', // Ensure it doesn't grow beyond the container
                  }}
                >
                  {item.description}
                </Typography>
              )}
            </Box>
            {selectedItem === item.value && <Check />}
          </Box>
        </MenuItem>
      ))}
      <Divider />
    </>
  )
}

function findKeyByValue(definitions, valueToFind, options) {
  if (options.length === 0) return undefined

  const foundEntry = Object.entries(definitions).find(
    ([, value]) => value.value === valueToFind
  )
  return foundEntry ? foundEntry[1] : undefined // Return the key if found, otherwise undefined
}

function itemChanged(value, selectedItem) {
  if (selectedItem !== undefined && value !== selectedItem) {
    return true
  } else {
    return false
  }
}

function detectChange(
  sortValue,
  hierarchicalValue,
  ancestralOnlyValue,
  selectedSortValue,
  selectedHierarchicalValue,
  selectedAncestralFamiliesValue,
  showMapValue,
  selectedShowMapValue
) {
  const sortChanged = itemChanged(sortValue, selectedSortValue)
  const hierarchicalChanged = itemChanged(
    hierarchicalValue,
    selectedHierarchicalValue
  )
  const ancestralOnlyChanged = itemChanged(
    ancestralOnlyValue,
    selectedAncestralFamiliesValue
  )

  const showMapChanged = itemChanged(showMapValue, selectedShowMapValue)

  return (
    sortChanged || hierarchicalChanged || ancestralOnlyChanged || showMapChanged
  )
}

const getViewConfigValue = (
  sortValue,
  hierarchicalValue,
  ancestralOnlyValue,
  sortOptions = [],
  hierarchicalOptions = [],
  ancestralOnlyOptions = []
) => {
  const sortItem = findKeyByValue(sortDefinitions, sortValue, sortOptions)
  const hierarchicalItem = findKeyByValue(
    hierarchicalDefinitions,
    hierarchicalValue,
    hierarchicalOptions
  )
  const ancestralOnlyItem = findKeyByValue(
    ancestralOnlyDefinitions,
    ancestralOnlyValue,
    ancestralOnlyOptions
  )

  const list = [
    sortItem?.label,
    hierarchicalItem?.label,
    ancestralOnlyItem?.label,
  ]
  const filteredList = list.filter(item => item !== undefined)
  const resultString = filteredList.join('/')

  return resultString
}

export default function ViewConfigSelector({
  sortValue = sortTypes.PUBLISHED,
  hierarchicalValue = false,
  ancestralOnlyValue = false,
  sortOptions = [],
  hierarchicalOptions = [],
  ancestralOnlyOptions = [],
  showMapOptions = [],
  onChange,
  canSetDefalt = false,
  target,
  showMapValue = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedSortValue, setSelectedSortValue] = React.useState(undefined)
  const [selectedAncestralFamilies, setSelectedAncestralFamilies] =
    React.useState(undefined)
  const [selectedHierarchicalValue, setSelectedHierarchicalValue] =
    React.useState(undefined)
  const [selectedShowMapValue, setSelectedShowMapValue] =
    React.useState(undefined)

  const sortItems = sortOptions.map(id => {
    return { id, ...sortDefinitions[id] }
  })
  const hierarchicalItems = hierarchicalOptions.map(id => {
    return { id, ...hierarchicalDefinitions[id] }
  })
  const ancestralOnlyItems = ancestralOnlyOptions.map(id => {
    return { id, ...ancestralOnlyDefinitions[id] }
  })
  const showMapItems = showMapOptions.map(id => {
    return { id, ...showMapDefinitions[id] }
  })

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedSortValue(undefined)
    setSelectedAncestralFamilies(undefined)
    setSelectedHierarchicalValue(undefined)
    setSelectedShowMapValue(undefined)
  }

  const handleSave = ({ defaultSelected = false }) => {
    if (onChange) {
      onChange({
        hierarchical:
          selectedHierarchicalValue === undefined
            ? hierarchicalValue
            : selectedHierarchicalValue,
        sort: selectedSortValue === undefined ? sortValue : selectedSortValue,
        showMap:
          selectedShowMapValue === undefined
            ? showMapValue
            : selectedShowMapValue,
        ancestralOnly:
          selectedAncestralFamilies === undefined
            ? ancestralOnlyValue
            : selectedAncestralFamilies,
        defaultSelected,
        target,
      })
    }
    handleClose()
  }

  const handleShowMapCheckBoxChange = newShowMapValue => {
    if (onChange) {
      onChange({
        hierarchical: hierarchicalValue,
        sort: sortValue,
        showMap: newShowMapValue,
        ancestralOnly: ancestralOnlyValue,
        defaultSelected: false,
        target,
      })
    }
  }

  const valuesHaveChanged = detectChange(
    sortValue,
    hierarchicalValue,
    ancestralOnlyValue,
    selectedSortValue,
    selectedHierarchicalValue,
    selectedAncestralFamilies,
    showMapValue,
    selectedShowMapValue
  )

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="caption"
          display="block"
          sx={{ paddingRight: '1rem' }}
        >
          View by:
        </Typography>
        <MuiIconButton
          color="inherit"
          onClick={handleClick}
          aria-controls="view-config-menu"
          aria-haspopup="true"
        >
          <Typography variant="body3" display="block" sx={{ fontWeight: 500 }}>
            {getViewConfigValue(
              sortValue,
              hierarchicalValue,
              ancestralOnlyValue,
              sortOptions,
              hierarchicalOptions,
              ancestralOnlyOptions
            )}
          </Typography>
          <KeyboardArrowDownIcon />
        </MuiIconButton>
        {showMapOptions.length > 0 && (
          <Box>
            <Typography
              variant="body3"
              display="block"
              sx={{ marginLeft: 2, fontWeight: 500 }}
            >
              <>
                Show Map
                <Checkbox
                  label="Show map"
                  checked={showMapValue}
                  onChange={e => handleShowMapCheckBoxChange(!showMapValue)}
                />{' '}
              </>
            </Typography>
          </Box>
        )}
      </Box>

      <Menu
        id="view-config-menu"
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: '340px', paddingTop: '0px', maxWidth: '300px' },
        }}
      >
        <ViewConfigTitle />
        <ViewConfigItemBlock
          items={sortItems}
          selectedItem={selectedSortValue || sortValue}
          handleItemClick={setSelectedSortValue}
        />
        <ViewConfigItemBlock
          items={hierarchicalItems}
          selectedItem={
            selectedHierarchicalValue === undefined
              ? hierarchicalValue
              : selectedHierarchicalValue
          }
          handleItemClick={setSelectedHierarchicalValue}
        />
        <ViewConfigItemBlock
          items={ancestralOnlyItems}
          selectedItem={
            selectedAncestralFamilies === undefined
              ? ancestralOnlyValue
              : selectedAncestralFamilies
          }
          handleItemClick={setSelectedAncestralFamilies}
        />
        <ViewConfigItemBlock
          items={showMapItems}
          selectedItem={
            selectedShowMapValue === undefined
              ? showMapValue
              : selectedShowMapValue
          }
          handleItemClick={setSelectedShowMapValue}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-around', p: 1 }}>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {canSetDefalt && (
            <Tooltip title="Save these settings as default">
              {''}
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                size="small"
                variant="outlined"
                onClick={() => handleSave({ defaultSelected: true })}
              >
                Save
              </Button>
              {''}
            </Tooltip>
          )}
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={!valuesHaveChanged}
          >
            OK
          </Button>
        </Box>
      </Menu>
    </div>
  )
}
