import LinkOffIcon from '@mui/icons-material/LinkOff'
import React from 'react'
import { Entity, Modifier, RichUtils, SelectionState } from 'draft-js'
import { getEntityRange, getSelectionEntity } from 'draftjs-utils'

import { MenuIconButton } from './MenuIconButton'
import { EditorState } from 'draft-js'

export const handleRemoveEntity = (
  editorState,
  onEditorStateChange,
  entity,
  linkToContentId
) => {
  // console.debug(`RemoveLink.js .handleRemoveEntity() editorState:`, editorState)
  //console.debug(`SourceLink rendering: editorState.contentState():`, contentState)
  const contentState = editorState.getCurrentContent()

  // console.debug(`RemoveLink.js .handleRemoveEntity() contentState:`, contentState)
  // console.debug(`RemoveLink.js .handleRemoveEntity() contentState blocks`, contentState._map._root.entries[0][1]._list._tail.array)

  // console.debug(`RemoveLink.js .handleRemoveEntity(): entity:`, entity)
  // console.debug(`RemoveLink.js .handleRemoveEntity(): onEditorStateChange: '${onEditorStateChange}'`)

  const blockMap = contentState.getBlockMap()
  // console.debug(`RemoveLink.js .handleRemoveEntity() looping around blockMap entries...`)
  blockMap.forEach(draftjsContentBlock => {
    // console.debug(`RemoveLink.js .handleRemoveEntity()  calling draftjsContentBlock.findEntityRanges() for draftjsContentBlock:`, draftjsContentBlock)
    draftjsContentBlock.findEntityRanges(
      character => {
        const entityKey = character.getEntity()
        if (entityKey !== null) {
          const e = Entity.get(entityKey)
          if (e.getType() === 'LINK') {
            // console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges filter found link entity`, e)
            // console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges filter found link entity in contentBlock`, draftjsContentBlock)
            const linkData = e.getData()
            if (
              linkData &&
              linkData.instanceType === 'source' &&
              linkData.contentId === linkToContentId
            ) {
              // console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges filter found link entity for source id '${linkToContentId}'`, e)
              return true
            } else {
              // console.debug(`RemoveLink.js .handleRemoveEntity() link entity does not have instanceType 'source' and contentId '${linkToContentId}'`, linkData)
            }
          }
        }
        // console.debug(`RemoveLink.js .handleRemoveEntity() draftjsContentBlock does not contain Entity with linkData instanceType 'source' and contentId '${linkToContentId}', deleting nothing.`, draftjsContentBlock)
        return false
      },
      (start, end) => {
        // console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges callback: start: ${start}, end: ${end}, contentBlock:`, draftjsContentBlock)

        const selection = SelectionState.createEmpty(
          draftjsContentBlock.getKey()
        )
        const updatedSelection = selection.merge({
          // anchorOffset is the start of the block
          anchorOffset: start,
          // focusOffset is the end
          focusOffset: end,
        })
        // console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges callback: calling Modifier.applyEntity()... updatedSelection:`, updatedSelection)

        const contentStateAfterApplyEntity = Modifier.applyEntity(
          contentState,
          updatedSelection,
          null
        )

        //console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges callback: applyEntity() done, returned contentStateAfterApplyEntity:`, contentStateAfterApplyEntity)

        const newEditorState = EditorState.createWithContent(
          contentStateAfterApplyEntity
        )
        onEditorStateChange(newEditorState)
        //console.debug(`RemoveLink.js .handleRemoveEntity() findEntityRanges callback: onEditorStateChange() done.`, contentStateAfterApplyEntity)
      }
    )
  })

  // console.debug(`RemoveLink.js .handleRemoveEntity() contentState:`, contentState)
}

const RemoveLink = ({ editorState, modalHandler, onChange }) => {
  // Taken from https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/controls/Link/index.js
  const handleRemoveLink = () => {
    const currentEntity = getSelectionEntity(editorState)
    let selection = editorState.getSelection()
    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity)
      const isBackward = selection.getIsBackward()
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        })
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        })
      }
      onChange(RichUtils.toggleLink(editorState, selection, null))
    }
  }

  const contentState = editorState.getCurrentContent()
  const currentEntity = getSelectionEntity(editorState)
  let link
  if (
    currentEntity &&
    contentState.getEntity(currentEntity).get('type') === 'LINK'
  ) {
    link = true
  }

  return (
    <MenuIconButton onClick={handleRemoveLink} disabled={!link}>
      <LinkOffIcon fontSize="small" />
    </MenuIconButton>
  )
}

export default RemoveLink
