import { useCallback, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { debounce } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'
import RichTextField from 'src/modules/ui/editor/RichTextField'
import {
  htmlToEditorState,
  editorStateToHtml,
} from 'src/modules/ui/editor/convert'
import { stripHtml } from 'src/utils'

import {
  deleteSource,
  setArticleIsSaving,
  updateContentBlockText,
} from './writeArticleSlice'
import { useDispatch } from 'react-redux'
import { getDeletedSourceIds } from '../content/SourcesCommon'
import { RichUtils } from 'draft-js'

const textIsEmpty = textContent => !stripHtml(textContent)

const ContentBlockTextEditor = ({
  onDialogVisible,
  contentId,
  id,
  isFocused,
  textContent,
  textHint = 'Click to add text...',
  onFocus,
  onBlur,
  sources,
  editSource,
  ...editorProps
}) => {
  const dispatch = useDispatch()
  const dispatchUpdateContentBlockText = useActionDispatcher(
    updateContentBlockText
  )

  const [editorState, setEditorState] = useState(() =>
    htmlToEditorState(textContent)
  )

  const [showEmptyMessage, setShowEmptyMessage] = useState(
    () => textIsEmpty(textContent) && !isFocused
  )

  useEffect(() => {
    if (!isFocused && !editorState.getCurrentContent().hasText()) {
      setShowEmptyMessage(true)
    } else {
      setShowEmptyMessage(false)
    }
  }, [isFocused, editorState, setShowEmptyMessage])

  const debouncedSaveChanges = useRef(
    debounce(async editorState => {
      const html = editorStateToHtml(editorState)
      await dispatchUpdateContentBlockText({
        contentId,
        id,
        textContent: html,
      }).then(({ payload }) => {
        const deletedSourceIds = getDeletedSourceIds(
          payload.textContent,
          payload.sources
        )

        deletedSourceIds.forEach(async deletedSourceId => {
          await dispatch(
            deleteSource({
              contentId: contentId,
              contentBlockId: payload.id,
              sourceId: deletedSourceId,
            })
          )
        })
      })
      dispatch(setArticleIsSaving(false))
    }, 1000),
    []
  )

  const saveChanges = useCallback(
    (...args) => debouncedSaveChanges.current(...args),
    [debouncedSaveChanges]
  )

  const handleEditorStateChange = useCallback(
    state => {
      if (!state.getCurrentContent().equals(editorState.getCurrentContent())) {
        dispatch(setArticleIsSaving(true))
        saveChanges(state)
      }
      if (showEmptyMessage) {
        setShowEmptyMessage(false)
      }
      setEditorState(state)
    },
    [dispatch, editorState, saveChanges, showEmptyMessage]
  )

  const handleOnFocus = useCallback(() => onFocus && onFocus(id), [onFocus, id])

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      const currentBlockType = RichUtils.getCurrentBlockType(editorState)
      if (currentBlockType === 'blockquote') {
        const newState = RichUtils.insertSoftNewline(editorState)
        handleEditorStateChange(newState)
      }
    }
  }

  return (
    <div onKeyDown={e => handleKeyPress(e)}>
      {/*
      Previously this box overlaid the RichTextField with hint text. That's okay if the hint text is only 1 line tall, but 
      if it is several lines then this box becomes larger than its parent and crashes into the next block. 
      That's because position:absolute removes the block from flow so its height does not affect its parent's height. 
      So instead of overlaying we're now using logic on the showEmptyMessage
      flag to show either of the hint or the RichTextField. 
      A side-effect of this is that if the hint text is tall the page jumps when the user clicks
      because the RichTextField will only be 1 line tall. 
      Seems that there is no CSS solution to this as overlaid blocks are outside the flow and
      provide no height information to their container. 
      JavaScript would be an option but is quite messy involving refs and a setTimeout to run after the elements have been
      rendered and heights calculated.*/}
      <Box
        sx={{
          display: showEmptyMessage ? 'block' : 'none',
        }}
        onClick={handleOnFocus}
      >
        <Typography
          sx={{
            opacity: 0.6,
          }}
        >
          {textHint ? parse(textHint) : 'Click to add text...'}
        </Typography>
      </Box>
      {!showEmptyMessage && (
        <RichTextField
          onDialogVisible={onDialogVisible}
          textContent={textContent}
          onEditorStateChange={handleEditorStateChange}
          editorState={editorState}
          isFocused={isFocused}
          onFocus={handleOnFocus}
          onBlur={onBlur}
          showEmptyMessage={showEmptyMessage}
          contentId={contentId}
          contentBlockId={id}
          sources={sources}
          editSource={editSource}
          {...editorProps}
        ></RichTextField>
      )}
    </div>
  )
}

export default ContentBlockTextEditor
