import { Tooltip as MuiTooltip } from '@mui/material'

const Tooltip = ({ children, title, ...props }) => {
  return (
    <MuiTooltip
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top'],
              },
            },
          ],
        },
      }}
      title={title}
      placement="top"
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
