import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  InputLabel,
  Stack,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PhotoListNavigatorDialog } from 'src/modules/photo/MediaNavigator'
import { useActionDispatcher } from 'src/modules/app'
import LinkChips from 'src/modules/content/LinkChips'
import ContentHeaderMeta from 'src/modules/content/ContentHeaderMeta'
import { setLinkablePageOpen } from 'src/modules/informationRequest/informationRequestSlice'
import { Container, PageTitle, Typography } from 'src/modules/ui'
import { MediaList } from 'src/modules/photo'

import {
  selectAlbum,
  fetchAlbum,
  resetAlbumState,
  selectAlbumMedia,
  fetchContentBlockMedia,
} from './photoSlice'
import EditAlbum from './EditAlbum'
import { INSTANCE_TYPE_PHOTO_ALBUM } from 'src/modules/app/links'
import EditButton from '../ui/EditButton'
import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../app/appConstants'
import {
  Button,
  ConfirmDialog,
  FormikCheckbox,
  FormikInput,
  // FormikRadioGroup
} from '../ui'
import { Form, Formik } from 'formik'
import PhotoSlideshow from './PhotoSlideshow'
import * as Yup from 'yup'
import {
  VISIBILITY_PUBLIC,
  getVisibilityControlConfig,
} from '../visibility/visibilityUtils'
import { useShare } from '../app'
import { useFullScreen } from './hooks'
import { selectUser } from '../auth/authSlice'
import { visibilityMap } from '../visibility/VisibilityControl'
import FabButton from '../ui/FabButton'
import ShareIcon from '@mui/icons-material/Share'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'

// if I don't import PhotoListNavigatorDialog the images go weird in the PhotoListNavigatorDialog.
// I have no idea why. I'm not using it in this file. I'm not using it in the AlbumPage.js file either.
// eslint-disable-next-line
const importForNofuckingReason = PhotoListNavigatorDialog

export const ShareSlideshowButton = ({
  visibility,
  values = {},
  handleChangeVisibility,
  fromPublic,
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const handleShare = useShare(fromPublic, null)

  const user = useSelector(selectUser)
  const treeAllowPublicVisibility = user?.currentTree?.allowPublicVisibility
  const location = useLocation()

  const { label } = visibilityMap.hasOwnProperty(visibility)
    ? visibilityMap[visibility]
    : {}

  const { privateTreeShare } = getVisibilityControlConfig(
    location.pathname,
    treeAllowPublicVisibility,
    visibility,
    INSTANCE_TYPE_PHOTO_ALBUM,
    false,
    true,
    label,
    [INSTANCE_TYPE_PHOTO_ALBUM]
  )

  const confirmShare = () => {
    handleShare(privateTreeShare, { slideshow: true, ...values })
    if (handleChangeVisibility) {
      handleChangeVisibility(VISIBILITY_PUBLIC)
    }
    sendEvent(ga4Events.ALBUM_SLIDESHOW_SHARED)
  }

  const handleClick = () => {
    if (privateTreeShare && visibility !== VISIBILITY_PUBLIC) {
      setConfirmDialogOpen(true)
    } else {
      confirmShare()
    }
  }

  return (
    <>
      <FabButton
        onClick={handleClick}
        permissionAction={ACTION_ALL_ACCESS}
        text="Share"
        icon={<ShareIcon fontSize="small" sx={{ mb: 0.1 }} />}
        color="primary"
        style={{
          backgroundImage:
            'linear-gradient(90deg,rgb(86,148,171),rgb(160,210,118))',
        }}
      />
      <ConfirmDialog
        submitText={'Continue'}
        open={confirmDialogOpen}
        onConfirm={confirmShare}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <Typography>
          Your archive is PRIVATE, sharing this page will make this page and its
          content public. Living individuals will not be shown. ALL other
          content will remain private. Do you want to continue?
        </Typography>
      </ConfirmDialog>
    </>
  )
}

const CreateSlideshowDialog = ({
  trigger,
  configureSlideshow,
  visibility,
  handleChangeVisibility,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)
  const handleShare = useShare(false, null)

  const user = useSelector(selectUser)
  const treeAllowPublicVisibility = user?.currentTree?.allowPublicVisibility
  const location = useLocation()

  const { label } = visibilityMap.hasOwnProperty(visibility)
    ? visibilityMap[visibility]
    : {}

  const { privateTreeShare, shareButtonDisabled } = getVisibilityControlConfig(
    location.pathname,
    treeAllowPublicVisibility,
    visibility,
    INSTANCE_TYPE_PHOTO_ALBUM,
    false,
    true,
    label,
    [INSTANCE_TYPE_PHOTO_ALBUM]
  )

  const handleSubmit = data => {
    handleShare(privateTreeShare, { slideshow: true, ...data }, true)
    configureSlideshow({
      open: true,
      ...data,
    })
    sendEvent(ga4Events.ALBUM_SLIDESHOW_PREVIEWED)
  }

  const validationSchema = Yup.object().shape({
    slideDuration: Yup.number()
      .min(1, 'Slides must show for at least 1 second')
      .max(100, "Slides can't show for more than 100 seconds")
      .required('Required'),
  })

  const initialValues = {
    transition: 'fade',
    slideDuration: 3,
    loop: true,
  }

  return (
    <>
      {trigger({
        onClick: handleShowModal,
        permissionAction: ACTION_ALL_ACCESS,
        disabled: shareButtonDisabled,
      })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullWidth={false}
        scroll="paper"
      >
        <DialogContent sx={{ padding: 4 }}>
          <Typography variant="h4">Create Slideshow</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack py={2}>
                  {/* <FormikRadioGroup
                    label="Transition"
                    value={values.transition}
                    optionLabelValues={[['Fade', 'fade']]}
                    name="transition"
                    setFieldValue={setFieldValue}
                  />{' '} */}
                  <InputLabel>Slide duration (seconds)</InputLabel>
                  <FormikInput
                    type="number"
                    name={`slideDuration`}
                    sx={{ width: 60 }}
                    inputProps={{ min: 1, max: 100 }}
                  ></FormikInput>
                  <FormikCheckbox
                    label="Would you like to loop the slideshow?"
                    name={`loop`}
                    fullWidth={false}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    onClick={() => handleSubmit(values)}
                    permissionAction={ACTION_ALL_ACCESS}
                    sx={{ marginRight: 2 }}
                  >
                    Preview
                  </Button>
                  <ShareSlideshowButton
                    handleChangeVisibility={handleChangeVisibility}
                    visibility={visibility}
                    values={values}
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

const AlbumPage = () => {
  const [slideshowOptions, setSlideshowOptions] = useState({ open: false })
  const dispatch = useDispatch()
  const history = useHistory()
  const album = useSelector(selectAlbum)
  const { id } = useParams()
  const dispatchFetchAlbum = useActionDispatcher(fetchAlbum)
  const slideshowContainerRef = useRef(null)
  const enterFullScreen = useFullScreen(slideshowContainerRef)
  const albumMedia = useSelector(selectAlbumMedia)
  const dispatchFetchContentBlockMedia = useActionDispatcher(
    fetchContentBlockMedia
  )

  useEffect(() => {
    dispatch(resetAlbumState({ id }))
  }, [id, dispatch])

  useEffect(() => {
    dispatchFetchAlbum(
      { id, allMedia: false },
      {
        errorNotification: false,
      }
    )
  }, [dispatchFetchAlbum, id])

  useEffect(() => {
    if (album) {
      dispatch(
        setLinkablePageOpen({
          target: album.id,
          instanceType: INSTANCE_TYPE_PHOTO_ALBUM,
          display: album.title,
        })
      )
    }
    return () => dispatch(setLinkablePageOpen())
  }, [dispatch, album])

  useEffect(() => {
    if (album?.albumContentBlockId && album.id === id) {
      dispatchFetchContentBlockMedia({
        id: album?.albumContentBlockId,
        page: 0,
      })
    }
  }, [dispatchFetchContentBlockMedia, album, id])

  const handleReportDeleted = () => {
    history.goBack()
  }
  const handleRefresh = () => {
    dispatchFetchAlbum(id)
  }

  const configureSlideshow = options => {
    if (options.open && album?.media?.length === 0) {
      dispatchFetchAlbum(
        { id, allMedia: true },
        {
          errorNotification: false,
        }
      )
    }
    setSlideshowOptions(options)
    enterFullScreen()
  }

  const onFetchMore = () => {
    if (
      dispatchFetchContentBlockMedia.status === 'loading' ||
      !album?.albumContentBlockId
    ) {
      return
    }
    dispatchFetchContentBlockMedia({
      id: album.albumContentBlockId,
      page: albumMedia.page + 1,
    })
  }

  const refreshMedia = () => {
    if (album?.albumContentBlockId) {
      dispatchFetchContentBlockMedia({
        id: album.albumContentBlockId,
        page: 0,
      })
    }
  }

  return (
    <>
      <PhotoSlideshow
        ref={slideshowContainerRef}
        media={album?.media}
        options={slideshowOptions}
        configureSlideshow={configureSlideshow}
        visibility={album?.visibility}
        onClose={() =>
          setSlideshowOptions(prevState => ({ ...prevState, open: false }))
        }
      />
      <Container>
        {dispatchFetchAlbum.status === 'error' ? (
          <Typography>Error fetching album</Typography>
        ) : dispatchFetchAlbum.status === 'loading' ? (
          <Typography>Loading album...</Typography>
        ) : album ? (
          <>
            <PageTitle
              backButton
              type={INSTANCE_TYPE_PHOTO_ALBUM}
              title={album.title}
              extraAction={
                <EditAlbum
                  album={album}
                  onFinishedDeleting={handleReportDeleted}
                  onFinishedUpdating={handleRefresh}
                  trigger={props => (
                    <Box>
                      <EditButton
                        permissionAction={ACTION_EDIT}
                        permissionParams={{
                          instanceType: INSTANCE_TYPE_PHOTO_ALBUM,
                          instance: album,
                        }}
                        white
                        {...props}
                      />
                    </Box>
                  )}
                />
              }
            />
            <ContentHeaderMeta
              {...album}
              slideshow={props => (
                <CreateSlideshowDialog
                  configureSlideshow={configureSlideshow}
                  trigger={props => (
                    <Button sx={{ ml: 1 }} {...props}>
                      Slideshow
                    </Button>
                  )}
                  {...props}
                />
              )}
            />

            {album.links.length > 0 && (
              <Stack direction="row" sx={{ mt: 2 }}>
                <LinkChips links={album.links} />
              </Stack>
            )}

            <Box sx={{ mt: 5 }}>
              {albumMedia?.results && id === album?.id ? (
                <MediaList
                  onFetchMore={onFetchMore}
                  media={albumMedia}
                  status={dispatchFetchContentBlockMedia.status}
                  refreshMedia={refreshMedia}
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </>
        ) : null}
      </Container>
    </>
  )
}

export default AlbumPage
