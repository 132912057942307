/*
 slice for accessing third party services thru the backend
 */

import { createSlice } from '@reduxjs/toolkit'
import config from '../../config'
import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { getUserSession } from '../../api'
const SLICE_NAME = 'services'

export const MESSAGE_USER = 'user'
export const MESSAGE_ASSISTANT = 'assistant'
export const CHAT_STREAM_RUNNING = 'chat_stream_running'
export const CHAT_STREAM_ENDED = 'chat_stream_ended'
export const CHAT_STREAM_ABORTED = 'chat_stream_aborted'

const openAiChatGenerationStream = async (url, data, headers, dispatch) => {
  try {
    const controller = new AbortController()
    const signal = controller.signal

    headers['Content-Type'] = 'application/json'

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      signal,
      body: JSON.stringify(data),
    })

    const reader = response.body.getReader()
    let content = ''

    const processStream = async () => {
      const { done, value } = await reader.read()

      if (done) {
        dispatch(endChatStream())
        return
      }

      // Convert bytes to string and append to result
      const chunk = new TextDecoder('utf-8').decode(value)

      dispatch(postChatChunk(chunk))

      content += chunk

      // Continue reading the stream
      processStream()
    }

    processStream()
    return {
      abort: () => controller.abort(),
      status: CHAT_STREAM_RUNNING,
      content,
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const openAiChatGeneration = createWrappedAsyncThunk(
  `${SLICE_NAME}/openAiChatGeneration`,
  async ({ inputValue, messageCount, hidePrompt }, { dispatch, getState }) => {
    const { headers } = await getUserSession()
    const endPoint = config.api.endpoint
    const url = `${endPoint}/services/${getTreeSlugFromStore()}/stream/openai/chat-generator/`
    dispatch(addUserPromptToChatStream({ inputValue, hidePrompt }))
    dispatch(startChatStream())

    const messages = getState()
      .services.chatStreamMessages.slice(messageCount || -5)
      .map(({ content, role }) => ({ content, role }))

    const response = openAiChatGenerationStream(
      url,
      messages,
      headers,
      dispatch
    )

    return response
  }
)

export const aiWizardHandler = createWrappedAsyncThunk(
  `${SLICE_NAME}/aiWizardHandler`,
  async ({
    values = [],
    selectedWizard,
    selectedWizardTitle,
    images = [],
    selectedInstanceId,
  }) => {
    const response = await api.post(
      `/history/${getTreeSlugFromStore()}/ai-wizard/handler/`,
      {
        body: {
          values,
          selectedWizard,
          images,
          selectedInstanceId,
          selectedWizardTitle,
        },
      }
    )

    return response
  }
)

export const aiWizardLoader = createWrappedAsyncThunk(
  `${SLICE_NAME}/aiWizardLoader`,
  async wizardId => {
    const response = await api.get(
      `/history/${getTreeSlugFromStore()}/ai-wizard/loader/${wizardId}/`
    )

    return response
  }
)

export const openAiBiographyPromptGeneration = createWrappedAsyncThunk(
  `${SLICE_NAME}/openAiBiographyPromptGeneration`,
  async ({ prompt = '', facts = [], target }, { dispatch }) => {
    const response = await api.post(
      `/services/${getTreeSlugFromStore()}/openai/biography-prompt-generation/`,
      {
        body: { prompt, facts, target },
      }
    )

    dispatch(
      openAiChatGeneration({
        inputValue: response,
        messageCount: -2,
        hidePrompt: true,
      })
    )

    return response
  }
)

export const openAiTextGeneration = createWrappedAsyncThunk(
  `${SLICE_NAME}/openAiTextGeneration`,
  params => {
    return api.post(
      `/services/${getTreeSlugFromStore()}/openai/text-generator/`,
      {
        body: params,
      }
    )
  }
)

export const geocodeService = createWrappedAsyncThunk(
  `${SLICE_NAME}/geocodeService`,
  ({ query }) => {
    const queryStringParameters = {
      q: query,
    }
    return api.get(`/services/geocode/`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  textGenerationResults: undefined,
  chatStreamMessages: [],
  chatStreamIndex: 0,
  geoCodeResults: [],
  wizardDefintions: {},
  selectedInstancesForWizard: [],
}

export const servicesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    saveSelectedInstance: (state, action) => {
      state.selectedInstancesForWizard.push(action.payload)
    },
    clearTextGenerationResults: (state, action) => {
      state.textGenerationResults = undefined
    },
    startChatStream: state => {
      state.chatStreamMessages[state.chatStreamIndex] = {
        role: MESSAGE_ASSISTANT,
        content: '',
        status: CHAT_STREAM_RUNNING,
        chatStreamIndex: state.chatStreamIndex,
      }
      state.chatStreamAbort = null
    },
    resetChatStream: state => {
      state.chatStreamIndex = 0
      state.chatStreamMessages = []
    },
    endChatStream: state => {
      let message = state.chatStreamMessages[state.chatStreamIndex]
      if (message) {
        message.status = CHAT_STREAM_ENDED
        state.chatStreamMessages[state.chatStreamIndex] = message
      }
      state.chatStreamIndex = state.chatStreamIndex + 1
    },
    postChatChunk: (state, action) => {
      const newContent = action?.payload
      if (newContent) {
        let message = state.chatStreamMessages[state.chatStreamIndex]
        if (!message) {
          message = {
            role: MESSAGE_ASSISTANT,
            content: '',
            status: CHAT_STREAM_RUNNING,
            chatStreamIndex: state.chatStreamIndex,
          }
        }
        message.content = message.content + newContent
        state.chatStreamMessages[state.chatStreamIndex] = message
      }
    },
    addUserPromptToChatStream: (state, action) => {
      const newContent = action?.payload.inputValue
      if (newContent) {
        state.chatStreamMessages[state.chatStreamIndex] = {
          role: MESSAGE_USER,
          content: newContent,
          chatStreamIndex: state.chatStreamIndex,
          hidePrompt: action?.payload?.hidePrompt || false,
        }
        state.chatStreamIndex = state.chatStreamIndex + 1
      }
    },
    abortChatStream: state => {
      if (state.chatStreamAbort) {
        let message = state.chatStreamMessages[state.chatStreamIndex]
        if (message) {
          message.status = CHAT_STREAM_ABORTED
          state.chatStreamMessages[state.chatStreamIndex] = message
        }
        state.chatStreamIndex = state.chatStreamIndex + 1
        state.chatStreamAbort()
      }
    },
  },
  extraReducers: {
    [aiWizardLoader.fulfilled]: (state, { payload, meta }) => {
      state.wizardDefintions[meta.arg] = payload
    },
    [openAiTextGeneration.fulfilled]: (state, { payload }) => {
      state.textGenerationResults = payload
    },
    [openAiChatGeneration.fulfilled]: (state, { payload }) => {
      state.chatStreamAbort = payload.abort
    },
    [geocodeService.fulfilled]: (state, { payload }) => {
      state.geoCodeResults = payload
    },
  },
})

export const {
  saveSelectedInstance,
  resetChatStream,
  clearTextGenerationResults,
  startChatStream,
  endChatStream,
  postChatChunk,
  addUserPromptToChatStream,
  abortChatStream,
} = servicesSlice.actions

export const selectGeocodeServiceResults = state =>
  state[SLICE_NAME].geoCodeResults || []

export const selectTextGenerationResults = state =>
  state[SLICE_NAME].textGenerationResults

export const selectChatMessage = state =>
  state[SLICE_NAME].chatStreamMessages || []

export const selectWizardDefinitionById = wizardId => state =>
  state[SLICE_NAME].wizardDefintions[wizardId]

export const selectedInstances = state =>
  state[SLICE_NAME].selectedInstancesForWizard

export default servicesSlice.reducer
