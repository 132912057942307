import React from 'react'
import { SnackbarContent } from 'notistack'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { IconButton } from '../ui'
import { ACTION_ALL_ACCESS } from './appConstants'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#313131', // dark grey
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    color: '#fff',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  },
  lessPadding: {
    paddingLeft: `${8 * 2.5}px`,
  },
  info: {
    backgroundColor: '#2196f3', // nice blue
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 0',
    width: '400px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  secondMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0',
    fontSize: '0.8rem',
    maxWidth: '400px',
    overflowWrap: 'break-word',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: '16px',
    marginRight: '-8px',
  },
}))

const TwoLineInfoSnackBar = React.forwardRef((props, forwardedRef) => {
  const { message, secondMessage, closeSnackbar, id, secondMessageAction } =
    props
  const classes = useStyles()

  let secondMessageActionComponent = null

  if (typeof secondMessageAction === 'function') {
    secondMessageActionComponent = secondMessageAction({ closeSnackbar, id })
  }

  return (
    <SnackbarContent
      ref={forwardedRef}
      role="alert"
      className={clsx(classes.root, classes.info)}
    >
      <div className={classes.messageContainer}>
        <div className={classes.message}>
          <div>{message}</div>
          <div>
            <IconButton
              size="small"
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => closeSnackbar(id)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {secondMessage && (
          <div className={classes.secondMessage}>
            <div>{secondMessage}</div>
            <div>{secondMessageActionComponent}</div>
          </div>
        )}
      </div>
    </SnackbarContent>
  )
})

export default TwoLineInfoSnackBar
