import { useSelector } from 'react-redux'

import { makeStyles } from '@mui/styles'

import { withOverridableComponent } from 'src/overridable'
import { selectNodeDirectory } from '../viewer/viewerSlice'
import { useSubTreeNodes } from 'src/modules/viewer/hooks'
import { SubTreeViewer } from 'src/modules/viewer/treeViewers'
import { Box, Typography } from '@mui/material'
import { imageCaptionStyles } from './Article'
import LoadingGuard from '../viewer/LoadingGuard'

export const useSubTreeStyles = makeStyles(theme => ({
  subTreeViewer: {
    position: 'relative',
  },
  mediaCaption: {
    display: 'inline-block',
    color: theme.palette.textSecondary,
    fontSize: '0.875rem',
    paddingTop: theme.spacing(1),
  },
}))

const SubTreeBlock = ({ contentId, id, subTree }) => {
  const classes = useSubTreeStyles()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const [visibleNodes, selectedIndividualIds] = useSubTreeNodes(
    nodeDirectory,
    subTree
  )

  let isLoadingOveride = false
  if (visibleNodes.length === 0) {
    isLoadingOveride = true
  }

  if (!subTree) return null
  return (
    <div className={classes.subTreeViewer}>
      <LoadingGuard isLoadingOveride={isLoadingOveride}>
        {subTree && (
          <SubTreeViewer
            visibleNodes={visibleNodes}
            selectedIndividualIds={selectedIndividualIds}
            preview
          />
        )}
      </LoadingGuard>
      {subTree?.caption && (
        <Box py={2} textAlign="center">
          <Typography
            className={classes.mediaCaption}
            style={imageCaptionStyles}
          >
            {subTree.caption}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default withOverridableComponent(SubTreeBlock, 'SubTreeBlock')
