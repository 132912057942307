import { makeStyles } from '@mui/styles'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DeleteIcon from '@mui/icons-material/Delete'

import { useActionDispatcher } from 'src/modules/app'
import { ConfirmDialog, IconButton, Typography } from 'src/modules/ui'
import { ToolbarContainer } from 'src/modules/ui/ToolbarContainer'

import { deleteBlock, moveBlockDown, moveBlockUp } from './writeArticleSlice'
import { Box } from '@mui/material'
import { useCallback } from 'react'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(-1),
  },
}))

const BlockToolbar = ({
  contentId,
  first,
  last,
  id,
  onMoveBlockUp,
  onMoveBlockDown,
  onDeleteBlock,
  show,
  allowDelete = true,
}) => {
  const classes = useStyles()
  const dispatchDeleteBlock = useActionDispatcher(deleteBlock)
  const dispatchMoveBlockUp = useActionDispatcher(moveBlockUp)
  const dispatchMoveBlockDown = useActionDispatcher(moveBlockDown)

  const handleDelete = useCallback(async () => {
    await dispatchDeleteBlock({ contentId, id })
    onDeleteBlock && onDeleteBlock(id)
  }, [dispatchDeleteBlock, onDeleteBlock, contentId, id])

  const handleMoveUp = useCallback(async () => {
    await dispatchMoveBlockUp({ contentId, id })
    onMoveBlockUp && onMoveBlockUp()
  }, [dispatchMoveBlockUp, onMoveBlockUp, contentId, id])

  const handleMoveDown = useCallback(async () => {
    await dispatchMoveBlockDown({ contentId, id })
    onMoveBlockDown && onMoveBlockDown()
  }, [dispatchMoveBlockDown, onMoveBlockDown, contentId, id])

  return (
    show && (
      <ToolbarContainer className={classes.root} elevation={1}>
        <Box>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            onClick={handleMoveUp}
            noBackground
            disabled={first}
            isLoading={dispatchMoveBlockUp.status === 'loading'}
          >
            <ArrowDropUpIcon />
          </IconButton>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            onClick={handleMoveDown}
            noBackground
            disabled={last}
            isLoading={dispatchMoveBlockDown.status === 'loading'}
          >
            <ArrowDropDownIcon />
          </IconButton>
          {allowDelete && (
            <ConfirmDialog
              onConfirm={handleDelete}
              trigger={props => (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  noBackground
                  {...props}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            >
              <Typography>Delete block?</Typography>
            </ConfirmDialog>
          )}
        </Box>
      </ToolbarContainer>
    )
  )
}

export default BlockToolbar
