import React, { useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import { useNotification } from 'src/modules/app/hooks'

import { Button, Typography, DialogTitle } from 'src/modules/ui'
import TagForm from 'src/modules/writeArticle/TagForm'
import ChooseContent from 'src/modules/writeArticle/ChooseContent'
import MediaDropzone from './MediaDropzone'

import { useUploadMedia } from './hooks'
import { INSTANCE_TYPE_PHOTO_ALBUM } from 'src/modules/app/links'
import { ACCEPT_UPLOAD_ALL } from './index'
import { TabLayout } from '../ui'
import YouTubeLinkDialog from './YouTubeLinkDialog'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const useStyles = makeStyles(theme => ({
  actions: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  albumForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  albumSelect: {
    marginTop: theme.spacing(1),
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  subTitle: {
    marginTop: theme.spacing(1),
  },
  uploadForm: {
    background: 'white',
  },
}))

const ACTION_CONSTANTS = {
  PAGE: 'add-to-page',
  ALBUM: 'existing-album',
  NEW_ALBUM: 'new-album',
}

export const UploadMediaText = () => (
  <Box mt={4} mb={1.5}>
    <Typography color="red" variant="subtitle2">
      Add TAGs below so your media is displayed in the relevant media sections
      of your site.
    </Typography>
  </Box>
)

const CreateMultiplePhotosDialog = ({
  open = false,
  presetTargets,
  trigger,
  onFinishedUploading,
  defaultAllowNoTags = false,
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleOnFinishedUploading = () => {
    handleCloseModal()
    if (onFinishedUploading) {
      onFinishedUploading()
    }
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={false}
        scroll="paper"
      >
        <CreateMultiplePhotos
          presetTargets={presetTargets}
          onFinishedUploading={handleOnFinishedUploading}
          onClose={handleCloseModal}
          defaultAllowNoTags={defaultAllowNoTags}
        />
      </Dialog>
    </>
  )
}

const CreateMultiplePhotos = ({
  onFinishedUploading,
  presetTargets = [],
  onClose,
  defaultAllowNoTags = false,
}) => {
  const classes = useStyles()

  const [action, setAction] = useState(ACTION_CONSTANTS.PAGE)
  const [albumId, setAlbumId] = useState(undefined)
  const [albumTitle, setAlbumTitle] = useState('')
  const [previewTiles, setPreviewTiles] = useState([])

  const { uploadMedia, uploading } = useUploadMedia()
  const { showError } = useNotification()

  const [targets, setTargets] = useState(presetTargets)
  const handleChangeTags = tags => {
    setTargets(tags)
  }

  const noTagsDialogRef = useRef()

  const handleUploadMedia = async (
    event = null,
    allowNoTags = defaultAllowNoTags
  ) => {
    if ([ACTION_CONSTANTS.NEW_ALBUM, ACTION_CONSTANTS.PAGE].includes(action)) {
      if (!albumTitle && action === ACTION_CONSTANTS.NEW_ALBUM) {
        showError('Album title should not be blank')
        return false
      } else if (!allowNoTags && targets.length === 0) {
        noTagsDialogRef.current.showDialog(async () => {
          //call this function again but with allowNoTags=true
          handleUploadMedia(event, (allowNoTags = true))
        })
        return
      }
    }
    if (!albumId && action === ACTION_CONSTANTS.ALBUM) {
      showError('You must select an album')
      return
    }
    try {
      await uploadMedia({
        albumId,
        albumTitle,
        previewTiles,
        targets,
      })
      if (onFinishedUploading) {
        onFinishedUploading()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const addYouTubeVideo = async youtubeVideoPreview => {
    setPreviewTiles([...previewTiles, youtubeVideoPreview])
  }

  const tabs = [
    {
      label: 'Upload New',
      component: (
        <>
          <MediaDropzone
            previewTiles={previewTiles}
            onChangePreviewTiles={setPreviewTiles}
            acceptFiles={ACCEPT_UPLOAD_ALL}
          />
          {previewTiles.length !== 0 && (
            <FormControl component="fieldset" className={classes.formControl}>
              <UploadMediaText />
              <RadioGroup
                value={action}
                onChange={(e, value) => {
                  setAction(value)
                }}
              >
                <FormControlLabel
                  value={ACTION_CONSTANTS.PAGE}
                  control={<Radio />}
                  label="Add to page media section"
                />
                {action === ACTION_CONSTANTS.PAGE && (
                  <TagForm
                    onChangeTags={handleChangeTags}
                    presetTargets={presetTargets}
                  ></TagForm>
                )}
                <FormControlLabel
                  value={ACTION_CONSTANTS.ALBUM}
                  control={<Radio />}
                  label="Add to existing album"
                />
                <>
                  {action === ACTION_CONSTANTS.ALBUM && (
                    <ChooseContent
                      className={classes.albumSelect}
                      instanceType={INSTANCE_TYPE_PHOTO_ALBUM}
                      onSelectContent={setAlbumId}
                    ></ChooseContent>
                  )}
                </>
                <FormControlLabel
                  value={ACTION_CONSTANTS.NEW_ALBUM}
                  control={<Radio />}
                  label="Add to new album"
                />
                {action === ACTION_CONSTANTS.NEW_ALBUM && (
                  <>
                    <TextField
                      placeholder="Album title"
                      value={albumTitle}
                      onChange={e => setAlbumTitle(e.target.value)}
                      className={classes.albumForm}
                    />
                    <Typography
                      className={classes.subTitle}
                      variant="subtitle2"
                    >
                      Add album to page media section of:
                    </Typography>
                    <TagForm
                      onChangeTags={handleChangeTags}
                      presetTargets={presetTargets}
                    ></TagForm>
                  </>
                )}
              </RadioGroup>
            </FormControl>
          )}
        </>
      ),
    },
    {
      label: (
        <YouTubeLinkDialog
          open={false}
          onAddURL={addYouTubeVideo}
          trigger={props => (
            <Typography onClick={props.onClick}>Add YouTube Video</Typography>
          )}
        />
      ),
      component: <Box sx={{ m: 3, mt: 0 }}></Box>,
      onClick: e => {
        e.preventDefault()
      },
    },
  ]

  return (
    <div className={classes.uploadForm}>
      <DialogTitle onClose={onClose}>Upload Media</DialogTitle>
      <DialogContent className={classes.content}>
        <NoTagsConfirmationDialog
          ref={noTagsDialogRef}
          proceedWithoutTagsText="Upload without tags"
        />
        <TabLayout tabs={tabs} sx={{ overflow: 'auto' }} />
      </DialogContent>
      {previewTiles.length > 0 && (
        <DialogActions className={classes.actions}>
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{
              instanceType: INSTANCE_TYPE_MEDIA,
            }}
            isLoading={uploading}
            color="primary"
            onClick={handleUploadMedia}
            size="large"
          >
            Upload
          </Button>
        </DialogActions>
      )}
    </div>
  )
}

export default CreateMultiplePhotosDialog
