import { memoize, isUndefined } from 'lodash'
import { processIndividualsAndUnionsToGraphNodes } from './graphNode'
import {
  buildMinimumGraph,
  createLayersMap,
  graphNodesCentroid,
  makeXCoordinates0based,
} from './graphOps'
import { DagManager } from './layout'
import {
  defaultVisibleFromGenerationalLinks,
  findGenerationalLinksToFamily,
} from './nodeDirectory'

const shouldCollapse = (showTotalGenerations, nLayers) => {
  return !isUndefined(showTotalGenerations) && nLayers > showTotalGenerations
}

const addIdsToSet = (set, graphNodes) => {
  for (let graphNode of graphNodes) {
    set.add(graphNode.id)
  }
}

export const createLineageGraph = (
  nodeDirectory,
  traceLineageTo,
  familyID,
  showTotalGenerations = undefined
) => {
  try {
    if (!familyID) {
      return []
    }
    const generationalLinks = []

    if (traceLineageTo) {
      findGenerationalLinksToFamily(
        generationalLinks,
        nodeDirectory,
        traceLineageTo,
        familyID
      )
    }

    let dagManager, visibleNodes
    let nodes, links, nLayers
    let moreBefore,
      moreAfter,
      didCollapse = false

    if (generationalLinks.length) {
      visibleNodes = defaultVisibleFromGenerationalLinks(
        generationalLinks,
        nodeDirectory,
        familyID,
        traceLineageTo,
        true
      )
      const visibleGraphNodes =
        processIndividualsAndUnionsToGraphNodes(visibleNodes)
      dagManager = new DagManager(visibleGraphNodes)
      ;[nodes, links, nLayers] = dagManager.layout()
      centreAllNodes(nodes)
      shiftNodesHorizontally(nodes)
      if (shouldCollapse(showTotalGenerations, nLayers)) {
        didCollapse = true
        ;[nodes, links, moreBefore, moreAfter] = collapseRelated(
          nodes,
          links,
          familyID,
          traceLineageTo,
          showTotalGenerations
        )
        nLayers = showTotalGenerations
      }
    } else {
      dagManager = createArbitraryLineageGraph(
        nodeDirectory,
        familyID,
        traceLineageTo
      )
      ;[nodes, links, nLayers] = dagManager.layout()
      if (shouldCollapse(showTotalGenerations, nLayers)) {
        didCollapse = true
        ;[nodes, links, moreBefore, moreAfter] = collapseUnrelated(
          nodes,
          links,
          showTotalGenerations
        )
        nLayers = showTotalGenerations
      }
    }

    makeXCoordinates0based(nodes)
    return { nodes, links, nLayers, moreBefore, moreAfter, didCollapse }
  } catch (error) {
    console.error('An error occurred in createLineageGraph:', error)
    return {}
  }
}

function createArbitraryLineageGraph(nodeDirectory, familyID, traceLineageTo) {
  const nodes = []
  for (let id of Object.keys(nodeDirectory)) {
    nodes.push(nodeDirectory[id])
  }
  const familyMemberIds = nodes
    .filter(n => n.family === familyID)
    .map(n => n.id)
  let visibleNodes = buildMinimumGraph(nodes, familyMemberIds)
  let visibleGraphNodes = processIndividualsAndUnionsToGraphNodes(visibleNodes)
  let dagManager = new DagManager(visibleGraphNodes)

  let distanceToTarget = 1000
  if (traceLineageTo) {
    distanceToTarget = dagManager.distanceFromIndividualToGraph(
      nodeDirectory,
      traceLineageTo
    )
  }

  if (distanceToTarget <= 4) {
    visibleNodes = buildMinimumGraph(
      nodes,
      familyMemberIds.concat(traceLineageTo.id)
    )
    let visibleGraphNodes =
      processIndividualsAndUnionsToGraphNodes(visibleNodes)
    dagManager = new DagManager(visibleGraphNodes)
  }
  return dagManager
}

const collapseRelated = (
  nodes,
  links,
  familyID,
  traceLineageTo,
  showTotalGenerations
) => {
  const [layers, minLayer, maxLayer] = createLayersMap(nodes)

  const reducedVisibleIDs = new Set()
  let layersStillToAdd = showTotalGenerations - 1
  let moreAfter = false

  let traceLineageToLayer

  // From the top, find the person we're tracing lineage to, and add their
  // descendants if they're from the same family.
  for (let layer = minLayer; layer <= maxLayer; layer++) {
    const graphNodes = layers.get(layer)
    // Find the layer that contains the "traceLineageTo" individual:
    if (graphNodes.find(g => g.individualIDs.has(traceLineageTo.id))) {
      traceLineageToLayer = layer
      layersStillToAdd--
      addIdsToSet(reducedVisibleIDs, graphNodes)
    } else if (traceLineageToLayer) {
      // If they've already been found, add subsequent layers from the same family
      if (graphNodes.some(g => g.containsFamilyMember(familyID))) {
        if (layersStillToAdd > 0) {
          addIdsToSet(reducedVisibleIDs, graphNodes)
          layersStillToAdd--
        } else {
          moreAfter = true
        }
      }
    }
  }

  layersStillToAdd += 1
  const removedLayers = []
  let moreBefore = false
  let firstFamilyAncestorLayer

  // From the bottom, work up deciding who to add
  for (let layer = maxLayer; layer >= minLayer; layer--) {
    let graphNodes = layers.get(layer)
    // Ignore lower layers
    if (layer >= traceLineageToLayer) {
      continue
    }
    if (
      graphNodes.some(g => g.containsFamilyMember(familyID)) ||
      layer === minLayer
    ) {
      // Stop adding after all layers used up, recording that there were
      // more layers
      if (layersStillToAdd === 0) {
        moreBefore = true
        break
      }

      if (!firstFamilyAncestorLayer) {
        firstFamilyAncestorLayer = graphNodes
      }
      if (layersStillToAdd === 1) {
        // If this is the last layer to add, only add the root node,
        // and recentre it
        const { x: centreX } = graphNodesCentroid(layers.get(layer + 1))
        graphNodes = graphNodes.filter(g => g.children.length)
        graphNodes[0].x = centreX
      }
      addIdsToSet(reducedVisibleIDs, graphNodes)
      layersStillToAdd--
    } else {
      // If this layer has no family members, hide it
      removedLayers.push(layer)
    }
  }

  const reducedVisible = nodes.filter(n => reducedVisibleIDs.has(n.id))
  const reducedLinks = []
  for (let link of links) {
    if (
      reducedVisibleIDs.has(link.sourceGraphNode.id) &&
      reducedVisibleIDs.has(link.targetGraphNode.id)
    ) {
      reducedLinks.push(link)
    }
  }
  if (removedLayers.length) {
    const lastHiddenLayerUnion = firstFamilyAncestorLayer.find(
      graphNode => graphNode.children.length
    )
    const traceLineageToGraphNode = nodes.find(graphNode =>
      graphNode.individualIDs.has(traceLineageTo.id)
    )
    // This will be the sole bottom node. Move it up to just underneath
    // the ancestors
    traceLineageToGraphNode.y -= removedLayers.length - 0.25
    traceLineageToGraphNode.x = lastHiddenLayerUnion.x

    // Add a new node to stand in for the collapsed generations. This node's
    // children are all the individuals in the layer below it
    const midX = graphNodesCentroid(layers.get(traceLineageToLayer)).x - 0.5
    const collapsedGenerations = {
      id: 'collapsed-generations',
      x: midX,
      y: traceLineageToGraphNode.y - 0.7,
      isUnion: true,
      width: 1,
      isCollapsedGenerations: true,
      between: [],
      individualIDs: new Set(),
      children: [traceLineageToGraphNode.id],
      hiddenGenerations: removedLayers.length,
      lastHiddenLayerUnion: lastHiddenLayerUnion,
      innerNodes: [],
    }
    reducedVisible.push(collapsedGenerations)
  }

  return [reducedVisible, reducedLinks, moreBefore, moreAfter]
}

const collapseUnrelated = (nodes, links, showTotalGenerations) => {
  const layers = new Map()
  for (let graphNode of nodes) {
    if (!layers.has(graphNode.y)) {
      layers.set(graphNode.y, [])
    }
    layers.get(graphNode.y).push(graphNode)
  }
  const minLayer = Math.min(...layers.keys())
  const maxLayer = Math.max(...layers.keys())
  const maxLayerToShow = minLayer + showTotalGenerations
  const moreAfter = maxLayerToShow < maxLayer
  const moreBefore = false
  const reducedVisibleIDs = new Set()

  for (let layer = minLayer; layer < maxLayerToShow; layer++) {
    const graphNodes = layers.get(layer)
    addIdsToSet(reducedVisibleIDs, graphNodes)
  }

  const reducedVisible = nodes.filter(n => reducedVisibleIDs.has(n.id))
  const reducedLinks = []
  for (let link of links) {
    if (
      reducedVisibleIDs.has(link.sourceGraphNode.id) &&
      reducedVisibleIDs.has(link.targetGraphNode.id)
    ) {
      reducedLinks.push(link)
    }
  }

  return [reducedVisible, reducedLinks, moreBefore, moreAfter]
}

const centreAllNodes = nodes => {
  const [layers, minLayer, maxLayer] = createLayersMap(nodes)
  const { x: globalCentre } = graphNodesCentroid(nodes)
  for (let layer = minLayer; layer <= maxLayer; layer++) {
    const layerNodes = layers.get(layer)
    const { x: layerCentre } = graphNodesCentroid(layerNodes)
    const diff = globalCentre - layerCentre
    for (let graphNode of layerNodes) {
      graphNode.x += diff
    }
  }
}

/**
 * Given the default centred layout for family lineage views, arrange
 * layers so that children always appear beneath their parents, not
 * to one side.
 *
 * @param {*} nodes
 * @param {*} links
 */
const shiftNodesHorizontally = nodes => {
  const [layers, minLayer, maxLayer] = createLayersMap(nodes)

  for (let layer = minLayer; layer <= maxLayer; layer++) {
    const graphNodes = layers.get(layer)

    const linkNode = graphNodes.find(graphNode => !!graphNode.children.length)
    const nextLayer = layers.get(layer + 1)
    const nextLayerLength = nextLayer?.length
    if (nextLayerLength) {
      const unionX = linkNode.x
      let diff
      let [minX, maxX] = minMaxTargetX(nextLayer, linkNode)
      if (unionX > maxX) {
        diff = unionX - maxX
        if (nextLayerLength === 1) {
          diff -= 0.5
        }
      } else if (unionX < minX) {
        diff = unionX - minX - 0.5
        if (nextLayerLength === 1) {
          diff += 1
        }
      }
      if (diff) {
        for (let graphNode of nextLayer) {
          graphNode.x += diff
        }
      }
    }
  }
}

function minMaxTargetX(graphNodes, parentNode) {
  const xPositions = targetPositions(graphNodes, parentNode)
  const maxX = Math.max(...xPositions)
  const minX = Math.min(...xPositions)
  return [minX, maxX]
}

function targetPositions(graphNodes, parentNode) {
  const positions = []
  for (let graphNode of graphNodes) {
    const [, targetIdx] = parentNode.findIndividualLinkSourceTarget(graphNode)
    if (isUndefined(targetIdx)) {
      continue
    }
    positions.push(graphNode.x + targetIdx)
  }
  return positions
}

/*
 memoized version of createLineageGraph, cache key is made up of everything bar node directory which is assumed to be the same
 */
export const createLineageGraphCached = memoize(
  createLineageGraph,
  (nodeDirectory, traceLineageTo, familyID, showTotalGenerations) =>
    JSON.stringify([traceLineageTo, familyID, showTotalGenerations])
)

/*
 clears the cache on any memoized functions
 */
export const clearLinegageCache = () => {
  createLineageGraphCached.cache.clear()
}
