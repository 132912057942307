import React from 'react'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_ARTEFACT } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import {
  fetchLinkedPageArtefacts,
  selectLinkedPageArtefacts,
} from './pageSlice'

const Artefacts = () => {
  const { linkedPageId } = useParams()

  return (
    <LinkedPageList
      action={fetchLinkedPageArtefacts}
      selector={selectLinkedPageArtefacts}
      target={linkedPageId}
      type={INSTANCE_TYPE_ARTEFACT}
    />
  )
}

export default Artefacts
