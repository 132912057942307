import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ordinal from 'ordinal'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  useMediaQuery,
  styled,
} from '@mui/material'

import { TabLayout, Typography, IconButton } from 'src/modules/ui'
import { useEmbed } from '../public/hooks'
import { LoadingIndicator } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const GenerationRow = styled('div')(({ theme, embed }) => ({
  alignItems: 'center',
  display: 'flex',
  // justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: !embed && { display: 'none' },
}))

const ANCESTRAL_PADDING = 25

const FamilyChip = ({ surname, family, onClickName }) => {
  return (
    <Chip
      size="small"
      color="primary"
      label={surname || '?'}
      sx={{ mx: 0.25, my: 0.5, ...(!surname && { px: 0.25 }) }}
      {...{
        ...(surname ? { onClick: () => onClickName(family) } : {}),
      }}
    />
  )
}

const MobileAncestralFamilies = ({ family, side, onClickName, name }) => {
  const data = family[side]
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 'fit-content',
      }}
    >
      <Box>
        <Chip size="small" color="primary" label={name} />
      </Box>
      {data.map((gen, i) => (
        <Box
          key={`${side}-${i}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            {ordinal(i + 1)} Generation
          </Typography>
          <Box
            key={`generationNames-${i}`}
            sx={{ display: 'flex', minWidth: 60 }}
            className="generationNames"
          >
            {gen.map(({ surname, family }, i) => {
              return (
                <FamilyChip
                  key={`${family}-${i}`}
                  {...{ surname, family, onClickName }}
                />
              )
            })}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const GenerationRowLabels = ({ data, embed }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 'fit-content',
        paddingTop: `${ANCESTRAL_PADDING}px`,
      }}
    >
      {data.map((gen, i) => (
        <GenerationRow key={`${'fatherSide'}-${i}`} sx={{ mx: 0.25, my: 0.65 }}>
          <Box key={`leftSideBar-${i}`} sx={{ mr: 2 }}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {ordinal(i + 1)} Generation
            </Typography>
          </Box>
        </GenerationRow>
      ))}
    </Box>
  )
}

const AncestralFamiliesSide = ({ family, side, onClickName }) => {
  const data = family[side]
  const embed = useEmbed()

  return (
    <>
      {data.map((gen, i) => (
        <div key={`${side}-${i}`}>
          <GenerationRow
            key={`${side}-${i}`}
            embed={embed}
            sx={{ justifyContent: side === 'fatherSide' && 'flex-end' }}
          >
            {/* {side === 'fatherSide' ? (
              <Box key={`leftSideBar-${i}`} sx={{ mr: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  {ordinal(i + 1)} Generation
                </Typography>
              </Box>
            ) : null} */}

            <Box
              key={`generationNames-${i}`}
              sx={{ display: 'flex', minWidth: 60 }}
              className="generationNames"
            >
              {gen.map(({ surname, family }, i) => {
                return (
                  <FamilyChip
                    key={`${family}-${i}`}
                    {...{ surname, family, onClickName }}
                  />
                )
              })}
            </Box>
          </GenerationRow>
        </div>
      ))}
    </>
  )
}

const AncestorsTextContainer = styled('div')(({ theme, embed }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    width: 110,
    flexDirection: 'column',
  },
  [theme.breakpoints.down('md')]: !embed && {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  marginRight: theme.spacing(2),
}))

const Family = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  overflowX: 'scroll',
  position: 'relative',
}))

const Divider = styled('div')(({ theme, embed }) => ({
  margin: theme.spacing(0, 0.5),
  borderRight: `dashed 1px ${theme.palette.lightGrey.main}`,
  position: 'relative',
  height: '100%',
  [theme.breakpoints.up('md')]: { display: 'block' },
  [theme.breakpoints.down('md')]: !embed && { display: 'none' },
}))

const FamilyFather = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: ANCESTRAL_PADDING,
  '& .generationNames': {
    flexDirection: 'row-reverse',
  },
}))

const getName = individual => {
  if (!individual) {
    return ''
  }

  if (individual.knownAs) {
    return `${individual?.knownAs} ${individual?.surname}`
  } else if (individual?.givenName) {
    return `${individual?.givenName} ${individual?.surname}`
  } else return 'Home Person'
}

const Families = ({ family, clickNameHandler, name, embed }) => {
  const individualRef = useRef()
  const familyContainerRef = useRef()

  const centreIndividualChip = () => {
    if (familyContainerRef?.current && individualRef?.current) {
      familyContainerRef.current.scrollLeft =
        individualRef.current.offsetLeft -
        familyContainerRef.current.offsetWidth / 2 +
        individualRef.current.offsetWidth / 2
    }
  }

  useEffect(() => {
    if (individualRef?.current) {
      centreIndividualChip()
    }
  }, [individualRef])

  return (
    <Family ref={familyContainerRef}>
      <FamilyFather>
        <AncestralFamiliesSide
          family={family}
          side="fatherSide"
          onClickName={clickNameHandler}
        />
      </FamilyFather>
      <Box
        ref={individualRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Chip size="small" color="primary" label={name} />
        <Divider embed={embed} />
      </Box>
      <div
        style={{
          paddingTop: ANCESTRAL_PADDING,
        }}
      >
        <AncestralFamiliesSide
          family={family}
          side="motherSide"
          onClickName={clickNameHandler}
        />
      </div>
    </Family>
  )
}

export const AncestralFamilies = ({
  open = false,
  userHomeIndividual,
  trigger,
  traceLineageTo,
  family,
  pyramidLoaded = false,
  onClickName,
  openAncestralFamilies,
  setOpenAncestralFamilies,
  displayAsDialog = true,
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [modalOpenLocal, setModalOpenLocal] = useState(open)
  const modalOpen = openAncestralFamilies || modalOpenLocal
  const setModalOpen = setOpenAncestralFamilies || setModalOpenLocal
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)
  const embed = useEmbed()

  const name =
    userHomeIndividual?.id === traceLineageTo?.id
      ? 'You'
      : getName(traceLineageTo)

  const clickNameHandler = family => {
    handleCloseModal()
    onClickName(family)
  }
  if (!family) {
    return <></>
  }

  const tabs = [
    {
      label: 'Paternal Side',
      component: (
        <MobileAncestralFamilies
          family={family}
          side="fatherSide"
          onClickName={clickNameHandler}
          name={name}
        />
      ),
    },
    {
      label: 'Maternal Side',
      component: (
        <MobileAncestralFamilies
          family={family}
          side="motherSide"
          onClickName={clickNameHandler}
          name={name}
        />
      ),
    },
  ]

  const dialogContent = pyramidLoaded ? (
    <>
      <Box
        sx={{
          display: 'flex',
          pb: { xs: 0, md: 2 },
        }}
      >
        <AncestorsTextContainer embed={embed}>
          <Typography variant="subtitle2" color="textSecondary">
            Ancestors
          </Typography>
        </AncestorsTextContainer>
        {!embed && (
          <Box
            className="noneDisplayOnPrint"
            sx={{
              display: {
                xs: 'block',
                md: 'none',
                position: 'absolute',
                right: 12,
                top: 12,
              },
            }}
          >
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              onClick={handleCloseModal}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      {!embed && (
        <Box
          className="noneDisplayOnPrint"
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <TabLayout tabs={tabs} />
        </Box>
      )}
      {!mobileBreakpoint && (
        <Box sx={{ display: 'flex' }}>
          <GenerationRowLabels
            data={
              family['fatherSide'].length > family['motherSide'].length
                ? family['fatherSide']
                : family['motherSide']
            }
          />
          <Families
            family={family}
            clickNameHandler={clickNameHandler}
            embed={embed}
            name={name}
          />
        </Box>
      )}
    </>
  ) : (
    <LoadingIndicator />
  )

  const dialogElement = (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullScreen={mobileBreakpoint ? true : false}
      >
        <DialogContent sx={{ pb: 3, overflowX: 'hidden' }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  )

  const standaloneElement = <>{dialogContent}</>

  return <>{displayAsDialog ? dialogElement : standaloneElement}</>
}

AncestralFamilies.propTypes = {
  trigger: PropTypes.func,
  family: PropTypes.object,
  open: PropTypes.bool,
  onClickName: PropTypes.func,
}

AncestralFamiliesSide.propTypes = {
  family: PropTypes.object,
  side: PropTypes.string,
  onClickName: PropTypes.func,
}
