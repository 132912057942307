import React, { useEffect } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Checkbox } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormikContext } from 'formik'

import clsx from 'clsx'

import {
  FormikCheckbox,
  Typography,
  FormikInput,
  IconButton,
} from 'src/modules/ui'
import { formatIndividualTagsAndSearch } from '../ui/individualUtils'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabled: {
    color: 'gray',
    '& *': {
      color: 'gray',
    },
  },
  alreadyAsked: {
    color: 'green',
    '& *': {
      color: 'green',
    },
  },
  invited: {
    marginBottom: '10px',
  },
  checkboxContainer: {
    width: '8%',
    position: 'relative',
    top: 10,
  },
  checkedCheckboxContainer: {
    position: 'relative',
    right: '10px',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '41%',
  },
  emailOuterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '41%',
    height: 42,
  },
  emailTextContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    height: '48px',
    textWrap: true,
    '& *': {
      padding: '1px',
    },
  },
  invitedDisplayContainer: {
    width: '100%',
  },
  inputLabel: {
    '&.focused': {
      color: 'purple',
    },
    '&.shrink': {
      backgroundColor: 'grey',
    },
  },
  inputElement: {
    fontSize: '0.875rem',
  },
  removeButton: {
    position: 'relative',
    top: 6,
  },
  noUserEmailContainer: {
    padding: '2px',
    width: '100%',
    '& > *': {
      height: '100%',
      width: '100%',
      position: 'relative',
      top: '5px',
      right: '5px',
    },
  },
}))

const IndividualLine = ({ individual, handleDelete }) => {
  const classes = useStyles()
  const { setFieldError } = useFormikContext()

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.alreadyAsked]:
          individual.user?.asked && individual.user?.state !== 'DISABLED',
        [classes.disabled]: individual.user?.state === 'DISABLED',
      })}
    >
      <div className={classes.checkboxContainer}>
        {individual.user?.state === 'DISABLED' ? null : individual.asked ? (
          <div className={classes.checkedCheckboxContainer}>
            <Checkbox disabled={true} checked={true} />
          </div>
        ) : (
          <FormikCheckbox
            name="checkedUsers"
            value={individual.id}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            hideValidation
            onChange={() => setFieldError('checkedUsers', false)}
          />
        )}
      </div>

      <div className={classes.nameContainer}>
        <Typography variant="body1">
          {formatIndividualTagsAndSearch(individual)}
        </Typography>
      </div>
      <div className={classes.emailOuterContainer}>
        {!individual.user ? (
          <NoUser individual={individual} />
        ) : (
          <WithUser individual={individual} />
        )}
        {individual.relationship === 'Other' &&
          !individual.user &&
          handleDelete && (
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              className={classes.removeButton}
              onClick={() => handleDelete(individual.id)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
      </div>
    </div>
  )
}

const NoUser = ({ individual, userEmail, handleEmailChange }) => {
  const classes = useStyles()
  const { setFieldValue, values } = useFormikContext()
  const emailValue = values.inviteEmails[individual.id]
  const emailFilled = emailValue ? true : false
  const checkedUsers = values.checkedUsers

  useEffect(() => {
    if (emailFilled && !checkedUsers.includes(individual.id)) {
      setFieldValue('checkedUsers', [...checkedUsers, individual.id])
    } else if (!emailFilled && checkedUsers.includes(individual.id)) {
      setFieldValue(
        'checkedUsers',
        checkedUsers.filter(u => u !== individual.id)
      )
    }
    // missing checkedUsers dependency otherwise checkbox won't uncheck
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFilled, individual.id, setFieldValue])

  useEffect(() => {
    if (!checkedUsers.includes(individual.id)) {
      setFieldValue(`inviteEmails.${individual.id}`, '')
    }
  }, [checkedUsers, individual.id, setFieldValue])

  return (
    <div className={classes.noUserEmailContainer}>
      <FormikInput
        placeholder="Add email to ask and invite"
        name={`inviteEmails.${individual.id}`}
        fullWidth
        disableUnderline={false}
      />
    </div>
  )
}

const WithUser = ({ individual }) => {
  return (
    <>
      {individual.user.state === 'INVITED' ? (
        <InvitedUser individual={individual} />
      ) : (
        <ActiveUser individual={individual} />
      )}
    </>
  )
}

const ActiveUser = ({ individual }) => {
  const classes = useStyles()
  return (
    <div className={classes.emailTextContainer}>
      <Typography
        className={classes.emailText}
        variant="body1"
        id={`${individual.id}-emailText`}
      >
        {individual.user.email}
      </Typography>
    </div>
  )
}

const InvitedUser = ({ individual }) => {
  const classes = useStyles()
  return (
    <div className={classes.invitedDisplayContainer}>
      <Typography
        className={classes.emailText}
        variant="body1"
        id={`${individual.id}-emailText`}
      >
        {individual.user.email}
      </Typography>
    </div>
  )
}

export default IndividualLine
