import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'

import { Typography } from 'src/modules/ui'
import { UserContext } from './Users'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    textAlign: 'left',
  },
  header: {
    marginBottom: 0,
  },
  group: {
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const RelationSection = ({ relation, availableRoles, showRolesHelpDialog }) => {
  const classes = useStyles()
  const { IndividualComponent } = useContext(UserContext)

  const sortedRelations = relation.slice().sort((a, b) => {
    // first put all the individuals that have linked users at the top, then sort by surname
    if (!a.user !== !b.user) {
      return !a.user
    } else {
      return (a.surname || '').localeCompare(b.surname || '')
    }
  })

  return (
    <div className={classes.root}>
      <Typography
        className={classes.header}
        variant="h6"
        style={{ textTransform: 'none' }}
      >
        {sortedRelations[0].relationship ?? 'Other tree individuals'}
      </Typography>
      <div className={classes.group}>
        {sortedRelations.map(ind => (
          <IndividualComponent
            individual={ind}
            key={`indLine-${ind.id}`}
            availableRoles={availableRoles}
            showRolesHelpDialog={showRolesHelpDialog}
          />
        ))}
      </div>
    </div>
  )
}

export default RelationSection
