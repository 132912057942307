import React, { useState, useRef, useEffect } from 'react'
import { LinearProgress, styled, TextareaAutosize } from '@mui/material'
import { Button } from '../ui'
import { useActionDispatcher } from '../app'
import {
  CHAT_STREAM_ENDED,
  MESSAGE_USER,
  openAiChatGeneration,
  abortChatStream,
  selectChatMessage,
  CHAT_STREAM_RUNNING,
} from './servicesSlice'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePicture from '../content/ProfilePicture'
import { selectUserIndividualOnTree } from '../auth/authSlice'
import copy from 'copy-to-clipboard'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const PublicLogo = styled('img')({
  display: 'block',
  width: 50,
  marginTop: 4,
})

const Profile = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  flex: '0 0 50px',
}))

const MessageContainer = styled('div')(({ theme, role }) => ({
  marginBottom: '10px',
  whiteSpace: 'pre-wrap',
  padding: theme.spacing(2),
  display: 'flex',
  backgroundColor: role === MESSAGE_USER ? 'white' : 'whitesmoke',
  borderBottom: `1px solid lightgrey`,
  justifyContent: 'space-between',
}))

const MessageContent = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  flexDirection: 'column',
  gap: 4,
  display: 'flex',
}))

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  ...theme.typography.subtitle2,
}))

const MessageButtonBar = styled('div')(({ theme }) => ({
  justifyContent: 'center',
}))

const StopGeneratingButton = ({ messages }) => {
  const dispatch = useDispatch()
  if (!messages || messages?.length === 0) {
    return null
  }

  const message = messages[messages.length - 1]

  if (message.role === MESSAGE_USER || message.status !== CHAT_STREAM_RUNNING) {
    return null
  }

  return (
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      variant="outlined"
      onClick={() => dispatch(abortChatStream())}
      sx={{ mr: 1 }}
    >
      Stop
    </Button>
  )
}

const CopyToClipBoardButton = ({ message }) => {
  if (message.role === MESSAGE_USER || message.status !== CHAT_STREAM_ENDED) {
    return null
  }

  return (
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      color="primary"
      variant="contained"
      onClick={() => {
        const stripDoublenewLines = message.content.replace(/\n\s*\n/g, '\n')
        copy(stripDoublenewLines, { format: 'text/plain' })
      }}
      type="submit"
    >
      Copy to Clipboard
    </Button>
  )
}

const SendButton = ({ messages, sendMessage, inputValue }) => {
  const message = messages ? messages[messages.length - 1] : {}

  if (message?.status === CHAT_STREAM_RUNNING) {
    return null
  }

  return (
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      disabled={!inputValue}
      onClick={sendMessage}
      style={{ minWidth: '80px', marginRight: '2px' }}
    >
      Send
    </Button>
  )
}

const ChatMessage = ({ message }) => {
  const user = useSelector(selectUserIndividualOnTree)

  let profile = null
  if (message.role === MESSAGE_USER) {
    profile = <ProfilePicture user={user} />
  } else {
    profile = <PublicLogo alt="weare.xyz" src="/logo.png" />
  }

  let messageContent = message.content
  if (!message.content) {
    messageContent = (
      <>
        <LinearProgress />
      </>
    )
  }

  return (
    <MessageContainer key={message?.id} role={message.role}>
      <Profile>{profile}</Profile>
      <MessageContent>
        <div>{messageContent}</div>
        <MessageButtonBar>
          <CopyToClipBoardButton message={message} />
        </MessageButtonBar>
      </MessageContent>
    </MessageContainer>
  )
}

const AiChatContainer = () => {
  const dispatchOpenAiChatGeneration = useActionDispatcher(openAiChatGeneration)
  const messages = useSelector(selectChatMessage)

  const [inputValue, setInputValue] = useState('')

  const messagesContainerRef = useRef(null)

  // Scroll to the bottom of the messages container
  const scrollToBottom = () => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight
  }

  // Handle sending a message
  const sendMessage = () => {
    if (inputValue.trim() === '') return

    dispatchOpenAiChatGeneration({ inputValue })
    setInputValue('')
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <div
      style={{
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        justifyContent: 'flex-end',
      }}
    >
      <div style={{ flex: '1', overflowY: 'auto' }} ref={messagesContainerRef}>
        {messages
          .filter(m => m.hidePrompt !== true)
          .map(message => (
            <ChatMessage key={message.chatStreamIndex} message={message} />
          ))}
      </div>
      <div
        style={{
          borderTop: '1px solid #ccc',
          display: 'flex',
          height: 'fit-content',
          alignItems: 'center',
        }}
      >
        <StyledTextarea
          minRows={2}
          id="prompt"
          name="prompt"
          placeholder="Enter a prompt e.g write a history of the USS Saratoga"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              sendMessage()
            }
          }}
          style={{ flex: '1', marginRight: '10px', padding: '0.5rem' }}
        />
        <SendButton
          messages={messages}
          sendMessage={sendMessage}
          inputValue={inputValue}
        />
        <StopGeneratingButton messages={messages} />
      </div>
    </div>
  )
}

export default AiChatContainer
