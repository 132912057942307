import React from 'react'
import { makeStyles } from '@mui/styles'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import VideocamIcon from '@mui/icons-material/Videocam'
import clsx from 'clsx'

import { ConfirmDialog, Typography, IconButton } from 'src/modules/ui'
import { defaultImage } from 'src/modules/content/ArticleCard'
import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_VIDEO_YOUTUBE } from '.'
import { useCorsImageProps } from '../app'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const THUMB_SIZE_MICRO = 'micro'
export const THUMB_SIZE_SMALL = 'small'
export const THUMB_SIZE_PAGE_TITLE = 'page-title'
export const THUMB_SIZE_MEDIUM = 'medium'

const PHOTO_DEFAULT_WIDTH = 271
const PHOTO_MICRO_WIDTH = 44
const PHOTO_SMALL_WIDTH = 120
const PHOTO_PAGE_TITLE_WIDTH = 100
const PHOTO_MEDIUM_WIDTH = 180

/*
 Wanted the width of the thumbnail for other container components so I could constrain width to the width of the thumbnail but
 the widths constants and logic were all encapsulated in the this thumbnail component.  To get round this I have  added this utility
 function which gets the widths for the different sizes of the thumbnail.  This sort of replicates the class logic at the start of
 the component, so it's a bit crap but at least its in (nearly) one place!!!
 -Spency on the block 2022
 */
export const getThumbnailWidth = (size, galleryProportions) => {
  switch (size) {
    case THUMB_SIZE_MICRO:
      return PHOTO_MICRO_WIDTH
    case THUMB_SIZE_SMALL:
      return PHOTO_SMALL_WIDTH
    case THUMB_SIZE_PAGE_TITLE:
      return PHOTO_PAGE_TITLE_WIDTH
    case THUMB_SIZE_MEDIUM:
      return PHOTO_MEDIUM_WIDTH
    default:
      if (!galleryProportions) {
        return PHOTO_DEFAULT_WIDTH
      } else {
        return 'auto'
      }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& .hover-overlay': {
      display: 'none',
    },
    '&:hover .hover-overlay': {
      display: 'flex',
    },
    cursor: 'pointer',
  },
  photo: {
    height: PHOTO_DEFAULT_WIDTH,
    width: PHOTO_DEFAULT_WIDTH,
    cursor: 'pointer',
  },
  photoMicro: {
    height: PHOTO_MICRO_WIDTH,
    width: PHOTO_MICRO_WIDTH,
  },
  photoSmall: {
    height: PHOTO_SMALL_WIDTH,
    width: PHOTO_SMALL_WIDTH,
  },
  photoPageTitle: {
    height: PHOTO_PAGE_TITLE_WIDTH,
    width: PHOTO_PAGE_TITLE_WIDTH,
  },
  photoMedium: {
    height: PHOTO_MEDIUM_WIDTH,
    width: PHOTO_MEDIUM_WIDTH,
  },
  galleryPhoto: {
    height: 266,
    width: 'auto',
  },
  clickablePhoto: {
    cursor: 'pointer',
  },
  iconEditMedia: {
    position: 'absolute',
    background: 'white',
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),
  },
  iconEditMediaSmall: {
    bottom: 'auto',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  videoIcon: {
    position: 'absolute',
    background: 'white',
    right: '50%',
    top: '50%',
  },
  deleteIcon: {
    position: 'absolute',
    background: 'white',
    right: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  photoImg: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  photoImgRadius: {
    borderRadius: theme.shape.borderRadius,
  },
  photoImgProportional: {
    objectFit: 'cover',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  photoRound: {
    borderRadius: '50%',
  },
  iconOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.5)',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  iconOverlayBottomRight: {
    position: 'absolute', // Keeps the item positioned absolutely relative to its nearest positioned ancestor.
    display: 'flex', // Uses Flexbox for layout.
    alignItems: 'flex-end', // Aligns children of the container to the end of the container's cross axis, which is the bottom in this case.
    justifyContent: 'flex-end', // Aligns children of the container to the end of the container's main axis, which is the right in this case.
    color: 'white', // Sets the text color.
    bottom: 0, // Positions the container at the bottom of its nearest positioned ancestor.
    right: 0, // Positions the container at the right of its nearest positioned ancestor.
  },
}))

const Thumbnail = ({
  children,
  className,
  file,
  galleryProportions = false,
  maintainProportions,
  onClick,
  onClear,
  round,
  selectable = false,
  selected,
  size,
  type,
  withEditMedia,
  moreComponent,
}) => {
  let corsProps = useCorsImageProps()
  corsProps = type === MEDIA_TYPE_VIDEO_YOUTUBE ? {} : corsProps

  const classes = useStyles()
  const handleOnClick = e => {
    if (onClick) {
      onClick(e)
    }
  }
  const handleClearOnClick = e => {
    if (onClear) {
      onClear(e)
    }
  }
  const isVideo = type === MEDIA_TYPE_VIDEO

  return (
    <ConfirmDialog
      submitText="Replace"
      onConfirm={handleOnClick}
      secondaryText="Clear"
      onConfirmSecondary={handleClearOnClick}
      trigger={props => (
        <div
          onClick={!withEditMedia ? handleOnClick : props.onClick}
          className={clsx(
            className,
            classes.root,
            !galleryProportions ? classes.photo : classes.galleryPhoto,
            size === THUMB_SIZE_MICRO ? classes.photoMicro : false,
            size === THUMB_SIZE_SMALL ? classes.photoSmall : false,
            size === THUMB_SIZE_PAGE_TITLE ? classes.photoPageTitle : false,
            size === THUMB_SIZE_MEDIUM ? classes.photoMedium : false,
            !withEditMedia && onClick ? classes.clickablePhoto : false
          )}
          style={{ cursor: withEditMedia ? 'cursor' : 'auto' }}
        >
          {withEditMedia && (
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              {...props}
              color="primary"
              size={size === THUMB_SIZE_PAGE_TITLE ? undefined : 'small'}
              className={clsx(
                classes.iconEditMedia,
                withEditMedia && onClick ? classes.clickablePhoto : false,
                size === THUMB_SIZE_SMALL ? classes.iconEditMediaSmall : false
              )}
              white
            >
              <PhotoLibraryIcon
                fontSize={size === THUMB_SIZE_PAGE_TITLE ? undefined : 'small'}
              />
            </IconButton>
          )}
          {isVideo && (
            <div className={classes.videoIcon}>
              <VideocamIcon color="primary" />
            </div>
          )}

          <img
            src={file || defaultImage}
            className={clsx({
              [classes.photoImg]: !maintainProportions,
              [classes.photoImgRadius]:
                !maintainProportions && !galleryProportions,
              [classes.photoImgProportional]: maintainProportions,
              [classes.photoRound]: round,
            })}
            {...corsProps}
            alt=""
          />
          {selected && (
            <div className={classes.iconOverlay}>
              <CheckCircleIcon fontSize="large" />
            </div>
          )}
          {selectable &&
            (selected ? (
              <div className={clsx(classes.iconOverlay, 'hover-overlay')}>
                <RemoveCircleIcon fontSize="large" />
              </div>
            ) : (
              <div className={clsx(classes.iconOverlay, 'hover-overlay')}>
                <AddCircleIcon fontSize="large" />
              </div>
            ))}
          {moreComponent && (
            <div className={classes.iconOverlayBottomRight}>
              {moreComponent}
            </div>
          )}
          {children}
        </div>
      )}
    >
      <Typography>Clear or replace preview image?</Typography>
    </ConfirmDialog>
  )
}

export default Thumbnail
