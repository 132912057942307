// import '@mux-elements/mux-video'
// re-enable by adding  "@mux-elements/mux-video": "^0.1.4", to package.json
import config from 'src/config'
import YouTube from 'react-youtube'
import { Box } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { MEDIA_TYPE_VIDEO_YOUTUBE } from '.'
import { MediaTypeOverlay, YouTubeOverlayIcon } from './PhotoGallery'

export const VideoPlayer = ({
  playbackId,
  youtubeVideoId,
  title,
  type,
  timestamp,
}) => {
  const [showButton, setShowButton] = useState(false)
  const handleResize = debounce(() => {
    const ytVideoPlayer = document.getElementsByClassName('yt-video-player')[0]
    let width = window.innerWidth - 120

    if (ytVideoPlayer) {
      if (window.innerWidth < 900) {
        const mobileWidth = window.innerWidth - 120
        if (mobileWidth / 1.77 < window.innerHeight * 0.9) {
          width = window.innerWidth - 120
          ytVideoPlayer.style.width = `${width}px`
        } else {
          width = window.innerHeight * 0.9 * 1.77
          ytVideoPlayer.style.width = `${width}px`
        }
      } else {
        width = window.innerWidth - 500
        ytVideoPlayer.style.width = `${width}px`
      }
      ytVideoPlayer.style.height = `${width / 1.77}px`
    }
  }, 100)

  const restartVideo = e => {
    // This prevents related videos from showing as best as possible
    e.target.playVideo()
    e.target.pauseVideo()
  }

  const onReady = useCallback(
    e => {
      // Start video at the timestamp saved with it
      if (timestamp) {
        setShowButton(true)
        const ytVideoPlayer = e.target
        ytVideoPlayer?.seekTo(timestamp, true)
        ytVideoPlayer.pauseVideo()
      }
    },
    [timestamp]
  )

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', () => handleResize())

    return () => {
      window.removeEventListener('resize', () => handleResize())
    }
  }, [handleResize])

  return (
    <>
      {youtubeVideoId && type === MEDIA_TYPE_VIDEO_YOUTUBE ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <YouTube
            className="yt-video-player"
            videoId={youtubeVideoId}
            onEnd={restartVideo}
            onReady={onReady}
            onPlay={() => (showButton ? setShowButton(false) : null)}
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                rel: 0,
                modestbranding: 0,
                showinfo: 0,
              },
            }}
            title={title}
          />
          {showButton && (
            <MediaTypeOverlay>
              <YouTubeOverlayIcon
                editable={false}
                sx={{ height: '32%', pointerEvents: 'none' }}
              />
            </MediaTypeOverlay>
          )}
        </Box>
      ) : (
        <mux-video
          playback-id={playbackId}
          env-key={config.mux.envKey}
          metadata-video-title={title}
          stream-type="vod"
          controls
        ></mux-video>
      )}
    </>
  )
}
