import React, { useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import MediaNavigator from 'src/modules/photo/MediaNavigator'
import { generatePublicTreeHome } from 'src/modules/app/links'
import { PublicContext } from '../contexts'

const PublicMediaPage = () => {
  const { treeSlug } = useContext(PublicContext)
  const { id } = useParams()
  const history = useHistory()

  const handleReportBack = () => history.push(generatePublicTreeHome(treeSlug))

  return (
    <MediaNavigator
      canRequestNext={false}
      canRequestPrev={false}
      onBack={handleReportBack}
      photo={{ id }}
    />
  )
}

export default PublicMediaPage
