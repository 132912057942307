import React, { useCallback, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import HowToRegIcon from '@mui/icons-material/HowToReg'

import {
  Box,
  Dialog,
  DialogContent,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { Button, DialogTitle } from 'src/modules/ui'

import { CreateOrUpdateIndividualForm } from './CreateOrUpdateIndividualForm'
import {
  addIndividual,
  fetchIndividualsForTarget,
  selectNodeDirectory,
} from './viewerSlice'
import { useSelector } from 'react-redux'
import SelectFamilyOrIndividualPartial from './SelectFamilyOrIndividualPartial'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useActionDispatcher } from '../app'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import {
  ACTION_CREATE,
  ACTION_EDIT_TREE_INDIVIDUALS,
} from '../app/appConstants'
import SiteWideIcon from '../ui/SiteWideIcon'
import Menu from '@mui/material/Menu'

export const ChooseOrAddIndividualDialog = ({
  open = false,
  trigger,
  onChosenOrAdded,
  individual,
  defaultSurname,
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleChosenOrAdded = individual => {
    onChosenOrAdded && onChosenOrAdded(individual)
    handleCloseModal()
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} onClose={handleCloseModal} scroll="paper">
        <DialogTitle onClose={handleCloseModal}>Select Individual</DialogTitle>
        <DialogContent>
          <ChooseOrAddIndividual
            individual={individual}
            defaultSurname={defaultSurname}
            onChosenOrAdded={handleChosenOrAdded}
            onCancel={handleCloseModal}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export const ChooseOrAddIndividual = ({
  onChosenOrAdded,
  individual,
  onCancel,
  defaultSurname,
}) => {
  const nodeDirectory = useSelector(selectNodeDirectory)
  const [chosenIndividual, setChosenIndividual] = useState(null)
  const [addedIndividualAttributes, setAddedIndividualAttributes] = useState({})
  const [searchHidden, setSearchHidden] = useState(false)
  const slug = useSelector(selectAuthorisedTreeSlug)
  const dispatchFetchIndividualsForTarget = useActionDispatcher(
    fetchIndividualsForTarget
  )

  const handleExistingChosen = individualId => {
    const fetchData = async () => {
      await dispatchFetchIndividualsForTarget({
        treeSlug: slug,
        target: individualId,
      })

      setChosenIndividual(nodeDirectory[individualId])
    }

    if (individualId) {
      fetchData()
    }
  }

  const handleFormSubmit = async () => {
    let chosenOrAddedIndividual
    if (chosenIndividual) {
      chosenOrAddedIndividual = chosenIndividual
    } else {
      chosenOrAddedIndividual = addedIndividualAttributes
    }
    onChosenOrAdded && onChosenOrAdded(chosenOrAddedIndividual)
  }

  const handleIndividualFormChange = useCallback(
    individualAttributes => {
      setAddedIndividualAttributes(individualAttributes)
    },
    [setAddedIndividualAttributes]
  )

  const handleDeselectIndividual = () => {
    setChosenIndividual(null)
  }

  const hideSearchWithoutOptions = () => {
    setSearchHidden(true)
  }

  return (
    <Stack>
      {!searchHidden ? (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Choose existing
            </Typography>
            <SelectFamilyOrIndividualPartial
              onSelectIndividual={handleExistingChosen}
              onDeselect={handleDeselectIndividual}
              showSelectFamily={false}
              maintainExploredIndividualState={false}
              nodeDirectory={nodeDirectory}
              individual={individual}
              hideSearchWithoutOptions={hideSearchWithoutOptions}
              overrideBlur={true}
              autoSelect={false}
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_INDIVIDUAL }}
              isLoading={dispatchFetchIndividualsForTarget.status === 'loading'}
              onClick={handleFormSubmit}
              color="primary"
            >
              {chosenIndividual ? 'Choose existing' : 'Create individual'}
            </Button>
          </Stack>
          <Box sx={{ mb: 1.5 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Or create a new one
            </Typography>
            <CreateOrUpdateIndividualForm
              defaultSurname={defaultSurname}
              disabled={!!chosenIndividual}
              onChange={handleIndividualFormChange}
            />
          </Box>
        </>
      ) : (
        <Box sx={{ mb: 1.5 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" sx={{ mb: 1 }}>
              Create an individual
            </Typography>
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_INDIVIDUAL }}
              isLoading={dispatchFetchIndividualsForTarget.status === 'loading'}
              onClick={handleFormSubmit}
              color="primary"
            >
              {chosenIndividual ? 'Choose existing' : 'Create individual'}
            </Button>
          </Stack>

          <CreateOrUpdateIndividualForm
            defaultSurname={defaultSurname}
            disabled={!!chosenIndividual}
            onChange={handleIndividualFormChange}
          />
        </Box>
      )}
    </Stack>
  )
}

const AddIndividualOnlyDialogComponent = ({
  modalOpen,
  handleCloseModal,
  handleIndividualFormChange,
  handleFormSubmit,
  presetTargets,
  defaultSurname,
}) => {
  return (
    <Dialog open={modalOpen} onClose={handleCloseModal} scroll="paper">
      <DialogTitle onClose={handleCloseModal}>Add Individual</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 1.5 }}>
          <CreateOrUpdateIndividualForm
            presetTargets={presetTargets}
            defaultSurname={defaultSurname}
            onChange={handleIndividualFormChange}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ mt: '1rem' }}
          >
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_INDIVIDUAL }}
              onClick={handleFormSubmit}
              color="primary"
            >
              Create individual
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

/*
 * This is a dialog that allows the user to add an individual only with out having a target individual to connect to/
 * eg when adding someone to the tree
 */
export const AddIndividualOnlyDialog = ({
  open = false,
  trigger,
  defaultSurname = '',
  onIndividualAdded,
  presetTargets,
}) => {
  const [addedIndividualAttributes, setAddedIndividualAttributes] = useState({})
  const [modalOpen, setModalOpen] = useState(open)
  const dispatchAddIndividual = useActionDispatcher(addIndividual)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => {
    setAddedIndividualAttributes({})
    setModalOpen(false)
  }

  const handleIndividualFormChange = useCallback(
    individualAttributes => {
      setAddedIndividualAttributes(individualAttributes)
    },
    [setAddedIndividualAttributes]
  )

  const handleFormSubmit = () => {
    dispatchAddIndividual({
      individualAttributes: addedIndividualAttributes,
    }).then(response => {
      onIndividualAdded && onIndividualAdded(response.payload)
      handleCloseModal()
    })
  }

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <AddIndividualOnlyDialogComponent
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        handleIndividualFormChange={handleIndividualFormChange}
        handleFormSubmit={handleFormSubmit}
        presetTargets={presetTargets}
        defaultSurname={defaultSurname}
      />
    </>
  )
}

export const AddIndividualOnlyManualDialog = ({
  modalOpen,
  setModalOpen,
  defaultSurname = '',
  onIndividualAdded,
  presetTargets = [],
}) => {
  const [addedIndividualAttributes, setAddedIndividualAttributes] = useState({})
  const dispatchAddIndividual = useActionDispatcher(addIndividual)

  const handleCloseModal = () => {
    setAddedIndividualAttributes({})
    setModalOpen(false)
  }

  const handleIndividualFormChange = useCallback(
    individualAttributes => {
      setAddedIndividualAttributes(individualAttributes)
    },
    [setAddedIndividualAttributes]
  )

  const handleFormSubmit = () => {
    dispatchAddIndividual({
      individualAttributes: addedIndividualAttributes,
    }).then(response => {
      onIndividualAdded && onIndividualAdded(response.payload)
      handleCloseModal()
    })
  }

  return (
    <>
      <AddIndividualOnlyDialogComponent
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        handleIndividualFormChange={handleIndividualFormChange}
        handleFormSubmit={handleFormSubmit}
        presetTargets={presetTargets}
        defaultSurname={defaultSurname}
      />
    </>
  )
}

export const SelectIndividualOnlyManualDialog = ({
  modalOpen,
  setModalOpen,
  onIndividualSelected,
}) => {
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleSelectIndividual = individualId => {
    if (individualId) {
      onIndividualSelected && onIndividualSelected({ id: individualId })
    }
    handleCloseModal()
  }

  return (
    <Dialog open={modalOpen} onClose={handleCloseModal} scroll="paper">
      <DialogTitle onClose={handleCloseModal}>Select Individual</DialogTitle>
      <DialogContent>
        <Box
          sx={{ display: 'flex', flexDirection: 'columns', minWidth: '500px' }}
        >
          <Typography
            variant="caption"
            display="block"
            sx={{ paddingRight: '1rem' }}
          >
            Search:
          </Typography>
          <SelectFamilyOrIndividualPartial
            individualLabel={''}
            onSelectIndividual={handleSelectIndividual}
            title={''}
            showSelectFamily={false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export const AddOrSelectIndividualButton = ({
  setAddIndividualModalOpen,
  setSelectIndividualModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectSelectIndividual = () => {
    setSelectIndividualModalOpen(true)
    handleClose()
  }
  const selectAddIndividual = () => {
    setAddIndividualModalOpen(true)
    handleClose()
  }

  return (
    <>
      <Button
        permissionAction={ACTION_EDIT_TREE_INDIVIDUALS}
        endIcon={
          <SiteWideIcon
            instanceType={INSTANCE_TYPE_INDIVIDUAL}
            fontSize={'large'}
          />
        }
        color="primary"
        onClick={handleClick}
      >
        Select or Add Individual
      </Button>
      <Menu
        id="view-config-menu"
        variant="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: '340px', paddingTop: '0px', maxWidth: '300px' },
        }}
      >
        <MenuItem onClick={selectAddIndividual} disableRipple>
          <Stack direction={'row'} spacing={1}>
            <EditIcon />
            <div>Add individual</div>
          </Stack>
        </MenuItem>
        <MenuItem onClick={selectSelectIndividual} disableRipple>
          <Stack direction={'row'} spacing={1}>
            <HowToRegIcon />
            <div>Select existing individual</div>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  )
}
