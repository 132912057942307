import React from 'react'
import { useField } from 'formik'
import { TextField } from '@mui/material'

const FormikTextField = ({ helperText, ...props }) => {
  const [field, meta] = useField(props)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  return (
    <TextField
      {...field}
      {...props}
      error={hasError}
      helperText={hasError ? error : helperText}
    />
  )
}

export default FormikTextField
