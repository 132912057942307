import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import FabButton from './FabButton'
import PropTypes from 'prop-types'

export default function EditButton({
  fontSize,
  permissionAction,
  permissionParams,
  text = 'Edit',
  loading = false,
  ...props
}) {
  return (
    <FabButton
      permissionAction={permissionAction}
      permissionParams={permissionParams}
      text={text}
      loading={loading}
      icon={<EditIcon fontSize="small" white sx={{ mb: 0.3 }} />}
      {...props}
    />
  )
}

EditButton.propTypes = {
  permissionAction: PropTypes.string.isRequired,
}
