import { EditorState } from 'draft-js'

import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'

import { MenuIconButton } from './MenuIconButton'

const OPTION_UNDO = 'undo'
const OPTION_REDO = 'redo'

export const HistoryOptions = ({ editorState, onChange }) => {
  const undoDisabled = editorState.getUndoStack().size === 0
  const redoDisabled = editorState.getRedoStack().size === 0

  const handleUndoRedo = action => {
    const newState = EditorState[action](editorState)
    if (newState) {
      onChange(newState)
    }
  }

  return (
    <>
      <MenuIconButton
        disabled={undoDisabled}
        onClick={() => handleUndoRedo(OPTION_UNDO)}
      >
        <UndoIcon fontSize="small" />
      </MenuIconButton>
      <MenuIconButton
        disabled={redoDisabled}
        onClick={() => handleUndoRedo(OPTION_REDO)}
      >
        <RedoIcon fontSize="small" />
      </MenuIconButton>
    </>
  )
}
