import React, { useEffect, useState } from 'react'

import TypeAheadBase from './TypeAheadBase'

const TypeAheadInfoRequest = ({ otherUsers = [] }) => {
  const alreadyInvitedUsers = otherUsers.filter(u => u.user)
  const notYetInvitedUsers = otherUsers.filter(u => !u.user)
  const [value, setValue] = useState(alreadyInvitedUsers)

  useEffect(() => {
    if (alreadyInvitedUsers.length && !value.length) {
      setValue(alreadyInvitedUsers)
    }
  }, [alreadyInvitedUsers, setValue, value])

  return (
    <>
      {notYetInvitedUsers.length ? (
        <TypeAheadBase
          notYetInvitedUsers={notYetInvitedUsers}
          value={value}
          setValue={setValue}
        />
      ) : null}
    </>
  )
}

export default TypeAheadInfoRequest
