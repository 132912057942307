import { createDefaultThemeParams, themeBuilder } from '../../../ui/theme'

export const pioneer = themeId => {
  let params = createDefaultThemeParams()

  const light = '#F5F5F5'
  const lightGrey = '#D3D3D3'
  const accent = '#2C3E50'

  const titleFont = 'Playfair Display'
  const bodyFont = 'Lora'

  params.typography.fontFamily = bodyFont
  params.typography.body1.fontFamily = bodyFont
  params.typography.body2.fontFamily = bodyFont
  params.typography.body3.fontFamily = bodyFont
  params.typography.subtitle1.fontFamily = titleFont
  params.typography.subtitle2.fontFamily = titleFont
  params.typography.subtitle3.fontFamily = titleFont
  params.typography.subtitle3.color = accent
  params.typography.h1.fontFamily = titleFont
  params.typography.h1Left.fontFamily = titleFont
  params.typography.h2.fontFamily = titleFont
  params.typography.h3.fontFamily = titleFont
  params.typography.h4.fontFamily = titleFont
  params.typography.h5.fontFamily = titleFont
  params.typography.h6.fontFamily = titleFont
  params.colors.darkPurple = accent
  params.colors.secondaryGrey = lightGrey
  params.colors.grey = light
  params.colors.profileGrey = accent
  params.backgroundImage = `/${themeId}.jpg`

  const theme = themeBuilder(params)

  return theme
}
