import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function HtmlHeader({
  title = 'We Are [...]',
  type = 'website',
  image = `${window.location.origin}/og-preview-image.jpg`,
  url = window.location,
  description = 'We Are [showcasing your family history like never before]',
  author,
  published_time,
  modified_time,
  first_name,
  last_name,
  structuredDataType,
  blogAboutMeUrl,
  taggedIndividualNames,
}) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:type" content={type} />
        <meta name="og:image" content={image} />
        <meta name="og:url" content={url} />
        <meta name="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta name="og:site_name" content="weare.xyz" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:type" content={type} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@wearedotxyz" />

        {published_time ? (
          <meta name="og:published_time" content={published_time} />
        ) : null}

        {modified_time ? (
          <meta name="og:modified_time" content={modified_time} />
        ) : null}

        {author ? <meta name="og:author" content={author} /> : null}

        {first_name ? <meta name="og:first_name" content={first_name} /> : null}

        {last_name ? <meta name="og:last_name" content={last_name} /> : null}

        {structuredDataType ? (
          <script type="application/ld+json">
            {`
              {
                 "@context": "https://schema.org"
                ,"@type": "${structuredDataType}"
                ${
                  structuredDataType === 'Person'
                    ? `,"name": ${JSON.stringify(title)}`
                    : ''
                }
                ${
                  structuredDataType === 'Article'
                    ? `
                      ,"headline": ${JSON.stringify(title)}
                      ,"datePublished": "${published_time}"
                      ,"dateModified": "${modified_time}"
                      ${
                        blogAboutMeUrl
                          ? `,"author": [{ "@type": "Person", "name": ${JSON.stringify(
                              author
                            )}, "url": "${blogAboutMeUrl}"}]`
                          : ''
                      }
                      ${
                        taggedIndividualNames
                          ? `,"about": [ ${taggedIndividualNames.map(
                              name =>
                                `{"@type": "Person", "name": ${JSON.stringify(
                                  name
                                )}}`
                            )}]`
                          : ''
                      }`
                    : ''
                }
              }
          `}
          </script>
        ) : (
          ''
        )}
      </Helmet>
    </div>
  )
}
