import clsx from 'clsx'
import React, { useState } from 'react'
import { Box, styled } from '@mui/material'

import Typography from './Typography'

const TabLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}))

const Toolbar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1.5, 0),
}))

const Tabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const TabPanels = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  width: '100%',
  flexGrow: 1,
})

const TabPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  width: '100%',
}))

const TabLayout = ({ tabs, ...props }) => {
  const [tabIdx, setTabIdx] = useState(0)

  return (
    <TabLayoutRoot {...props}>
      <Toolbar>
        <Tabs>
          {tabs.map(({ label, ...props }, idx) => (
            <TabButton
              key={idx}
              selected={tabIdx === idx}
              onClick={() => setTabIdx(idx)}
              {...props}
            >
              {label}
            </TabButton>
          ))}
        </Tabs>
      </Toolbar>
      <TabPanels>
        {tabs.map(({ component }, idx) => (
          <TabPanel
            key={idx}
            sx={{
              display: tabIdx === idx ? 'flex' : 'none',
            }}
          >
            {component}
          </TabPanel>
        ))}
      </TabPanels>
    </TabLayoutRoot>
  )
}

const StyledTabButton = styled(Box)(({ theme }) => ({
  borderBottom: '2px solid transparent',
  margin: theme.spacing(0, 1),
  paddingBottom: 3,
  '&:hover': {
    cursor: 'pointer',
  },
  '&:hover, &.selected': {
    borderBottom: `2px solid ${theme.palette.profileGrey.main}`,
  },
}))

const TabButton = ({ children, onClick, onPress, selected }) => {
  return (
    <StyledTabButton
      className={clsx({
        selected: selected,
      })}
      onClick={onClick}
    >
      <Typography component="span">{children}</Typography>
    </StyledTabButton>
  )
}

export default TabLayout
