import React from 'react'
import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { Typography, IconButton } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const DialogTitle = ({ onClose, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ml: 3,
        mr: 3,
        mt: 3,
      }}
    >
      <Typography variant="h7">{children}</Typography>
      {onClose && (
        <Box sx={{ mr: -1, ml: 'auto' }}>
          <IconButton permissionAction={ACTION_ALL_ACCESS} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default DialogTitle
