import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app/hooks'
import {
  fetchAllTime,
  selectAllTimeReport,
  selectMonthByMonth,
  fetchMonthByMonth,
} from './goalsSlice'
import { Typography } from 'src/modules/ui'
import Goal from './Goal'
import { ASSISTANT_WIDTH_PX, N_GOALS_WRAP } from './constants'
import ReportRow from './ReportRow'
import { Container } from 'src/modules/ui'
import { LoadingIndicator } from '../ui'
import { HUMAN_READABLE_TARGETS, presentTargetTypesInOrder } from './targets'
import { Box } from '@mui/system'
import AllTimeProgress from './AllTimeProgress'

const TwelveMonthReport = () => {
  let monthByMonthReport = useSelector(selectMonthByMonth)
  let allTimeReport = useSelector(selectAllTimeReport)

  const targetTypes = presentTargetTypesInOrder(allTimeReport)

  const dispatchFetchMonthByMonth = useActionDispatcher(fetchMonthByMonth)
  const dispatchFetchAllTime = useActionDispatcher(fetchAllTime)
  useEffect(() => {
    dispatchFetchAllTime()
    dispatchFetchMonthByMonth()
  }, [dispatchFetchAllTime, dispatchFetchMonthByMonth])

  const isLoading =
    dispatchFetchAllTime.status === 'loading' ||
    dispatchFetchMonthByMonth.status === 'loading'

  const itemWidth = ASSISTANT_WIDTH_PX / N_GOALS_WRAP
  const indicatorSize = 60
  const hasMonthlyGoals = !!monthByMonthReport[0]?.goals?.length

  return (
    <Container>
      <Box
        sx={{
          minWidth: 600,
          maxWidth: 1000,
          margin: '0 auto',
        }}
      >
        <Typography variant="h2" sx={{ mb: 4 }}>
          Goals Report
        </Typography>
        {!hasMonthlyGoals && (
          <Typography sx={{ textAlign: 'center' }}>
            You have not defined any monthly goals
          </Typography>
        )}
        {!!targetTypes.length && (
          <>
            <ReportRow rowLabel="">
              {targetTypes.map(targetType => (
                <Typography
                  sx={{ width: itemWidth, textAlign: 'center', p: 1 }}
                >
                  {HUMAN_READABLE_TARGETS[targetType]}
                </Typography>
              ))}
            </ReportRow>

            <ReportRow rowLabel="All time">
              {allTimeReport.map(goal => (
                <AllTimeProgress
                  value={goal.progress || 0}
                  width={itemWidth}
                  indicatorSize={indicatorSize}
                />
              ))}
            </ReportRow>

            {hasMonthlyGoals &&
              monthByMonthReport.map(reportRow => (
                <ReportRow
                  rowLabel={`${reportRow.monthDisplay} ${reportRow.year}`}
                >
                  {reportRow.goals.map(goal => (
                    <Goal
                      goal={goal}
                      key={goal.id}
                      link={false}
                      title={false}
                      width={itemWidth}
                      indicatorSize={indicatorSize}
                    ></Goal>
                  ))}
                </ReportRow>
              ))}
          </>
        )}
        {isLoading && <LoadingIndicator />}
      </Box>
    </Container>
  )
}
export default TwelveMonthReport
