import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  styled,
} from '@mui/material'
import { Button, Typography } from '../ui'
import React, { useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import {
  VISIBILITY_PUBLIC,
  VISIBILITY_TREE,
} from '../visibility/visibilityUtils'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_TREE } from '../app/links'
import { sendPageview, ga4Events } from '../analytics/AnalyticsUtils'

export const TreeVisibilitySelectorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '50%',
}))

const useStyles = makeStyles(theme => ({
  cardContent: {
    textAlign: 'left',
  },
  cardBody: {
    paddingLeft: theme.spacing(2),
  },
}))

const TreeVisibilitySelector = ({ onNext = () => {} }) => {
  const styles = useStyles()

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__CREATE_TREE__SELECT_VISIBILITY)
  }, [])

  return (
    <TreeVisibilitySelectorContainer>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h1" color="primary">
          What type of Archive?
        </Typography>
      </Box>
      <Card>
        <CardContent className={styles.cardContent}>
          <CardHeader title={'A Public Archive'} />
          <div className={styles.cardBody}>
            <Typography component={'span'} variant="body2">
              <ul>
                <li>
                  all content <b>public</b>
                </li>
                <li>excluding names and data for the living</li>
                <li>allows content to be easily shared and discussed</li>
                <li>
                  family members can be given a login to have greater access
                </li>
              </ul>
            </Typography>
          </div>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
            onClick={() => onNext(VISIBILITY_PUBLIC)}
            color="primary"
          >
            Select Public
          </Button>
        </CardActions>
      </Card>
      <Card>
        <CardContent className={styles.cardContent}>
          <CardHeader title={'A Private Archive'} />
          <div className={styles.cardBody}>
            <Typography component={'span'} variant="body2">
              <ul>
                <li>
                  all content <b>private</b>
                </li>
                <li>
                  selected content can still be made public to share online
                </li>
                <li>all names and data for the living will remain hidden</li>
                <li>
                  family members can be given a login to have greater access
                </li>
              </ul>
            </Typography>
          </div>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
            onClick={() => onNext(VISIBILITY_TREE)}
            color="primary"
          >
            Select Private
          </Button>
        </CardActions>
      </Card>
    </TreeVisibilitySelectorContainer>
  )
}

export default TreeVisibilitySelector
