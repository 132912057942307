import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, styled, useTheme } from '@mui/material'

import { selectNodeDirectory } from '../viewerSlice'
import Explore from '../Explore'
import { createLineageGraphCached } from '../api/lineage'
import {
  EXPLORE_VIEW_MODE_FAMILY,
  selectChosenFamilyId,
  setExploreViewMode,
} from 'src/modules/viewer/exploreTreeSlice'
import { useStyles } from './viewerStyles'
import TreeViewer from './TreeViewer'
import {
  FOCUS_MODE_EXPLORE_INDIVIDUAL,
  FOCUS_MODE_SCROLL,
  FOCUS_MODE_TOP_OF_TREE,
} from './constants'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FabButton from '../../ui/FabButton'
import EmbedCode from '../../ui/EmbedCode'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const ViewerTop = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  zIndex: 2,
  position: 'relative',
  width: '100%',
  pointerEvents: 'none',
  '& > .inner': {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: theme.maxWidth,
    position: 'relative',
    width: '100%',
  },
}))

const LineageViewer = ({
  family,
  traceLineageTo,
  showTotalGenerations,
  previewViewer = false,
  explore = false,
  allowDragAndZoom = false,
  disableZoom = false,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedFamilyId = useSelector(selectChosenFamilyId)
  const nodeDirectory = useSelector(selectNodeDirectory)

  const { nodes, links, nLayers } = createLineageGraphCached(
    nodeDirectory,
    traceLineageTo,
    family?.id || selectedFamilyId,
    showTotalGenerations
  )

  const exploreTrigger =
    ({ buttonText, ...props }) =>
    ({ onClick }) => {
      return (
        <Stack
          direction="row"
          justifyContent="right"
          width={theme.maxWidth}
          pr={2}
          mx="auto"
          gap={1}
          sx={{ pointerEvents: 'visible' }}
        >
          <EmbedCode buttonText={'Embed Tree'} />
          <FabButton
            permissionAction={ACTION_ALL_ACCESS}
            text={buttonText}
            icon={<FullscreenIcon fontSize="medium" sx={{ mb: 0.2 }} />}
            {...props}
            onClick={event => {
              // Stuff that happens during exploring can reset the explore view
              // mode. Explicitly set it to family before entering.
              dispatch(setExploreViewMode(EXPLORE_VIEW_MODE_FAMILY))
              onClick(event)
            }}
          />
        </Stack>
      )
    }

  return (
    <div className={classes.shadowContainer}>
      {!previewViewer && (
        <ViewerTop>
          <Box className="inner">
            <Stack direction="row">
              <Explore
                selectMenuConfig={{ edit: true, visit: true, explore: true }}
                traceLineageTo={traceLineageTo}
                trigger={exploreTrigger({ buttonText: 'Full Screen' })}
              />
            </Stack>
          </Box>
        </ViewerTop>
      )}
      <TreeViewer
        selectMenuConfig={{ edit: true, visit: true, explore: true }}
        allowDragAndZoom={allowDragAndZoom}
        disableZoom={disableZoom}
        exploreNodeOnClick={false}
        focusMode={
          previewViewer
            ? explore
              ? FOCUS_MODE_EXPLORE_INDIVIDUAL
              : FOCUS_MODE_TOP_OF_TREE
            : FOCUS_MODE_SCROLL
        }
        focusModeTarget={traceLineageTo}
        links={links}
        navigateToNodeOnClick={!previewViewer}
        nLayers={nLayers}
        nodes={nodes}
        treeBackgroundStyles={previewViewer ? { border: 'none' } : {}}
        nodesInactive={previewViewer}
      />
      {!previewViewer && <div className={classes.innerShadow} />}
    </div>
  )
}

export default LineageViewer
