import { createSlice } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import {
  createComment,
  createCommentFulfilled,
  deleteComment,
  deleteCommentFulfilled,
} from 'src/modules/content/contentSlice'
import { updateStateAfterPhotoAltered } from 'src/modules/home/homeSlice'
import {
  cropExistingPhoto,
  rotatePhotoLeft,
  rotatePhotoRight,
  revertToOriginal,
} from 'src/modules/photo/photoSlice'

const SLICE_NAME = 'myContent'

export const fetchMyContent = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchMyContent`,
  arg => {
    const argWithDefaults = Object.assign(
      {
        page: 0,
        sort: '-published_at',
        state: 'PUBLISHED',
      },
      pickBy(arg)
    )

    const { page, sort, ...rest } = argWithDefaults
    const limit = CONTENT_PAGE_SIZE
    const offset = page * limit

    const queryStringParameters = {
      limit,
      offset,
      ordering: sort,
      ...rest,
    }

    return api.get(`/history/${getTreeSlugFromStore()}/content/`, {
      queryStringParameters,
    })
  }
)

export const fetchMyPhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchMyPhotos`,
  ({ author, page }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit
    const queryStringParameters = pickBy({
      uploaded_by: author,
      limit,
      offset,
    })
    return api.get(`/history/${getTreeSlugFromStore()}/media/`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  content: {
    page: 0,
    count: 0,
    results: [],
  },
  media: {
    page: 0,
    count: 0,
    results: [],
  },
}

const CONTENT_PAGE_SIZE = 10
const PHOTO_PAGE_SIZE = 10

export const myContentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    ...handlePaginatedAction(fetchMyContent, state => state.content, {
      setOnRequest: initialState.content,
    }),
    ...handlePaginatedAction(fetchMyPhotos, state => state.media, {
      setOnRequest: initialState.media,
    }),
    [createComment.fulfilled]: createCommentFulfilled(
      state => state.content.results
    ),
    [deleteComment.fulfilled]: deleteCommentFulfilled(
      state => state.content.results
    ),
    [cropExistingPhoto.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoLeft.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoRight.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [revertToOriginal.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
  },
})

export const selectMyContent = state => state.myContent.content
export const selectMyPhotos = state => state.myContent.media

export default myContentSlice.reducer
