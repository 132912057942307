import { useDispatch } from 'react-redux'

import { useCallback, useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'

import {
  clearAddIndividualNode,
  clearClickedIndividualId,
} from '../exploreTreeSlice'
import { useActionDispatcher } from 'src/modules/app'
import {
  addChild,
  addFather,
  addMother,
  addPartner,
  addSelf,
} from '../viewerSlice'
import {
  ADD_TYPE_CHILD,
  ADD_TYPE_FATHER,
  ADD_TYPE_GRAND_FATHER,
  ADD_TYPE_GRAND_MOTHER,
  ADD_TYPE_MOTHER,
  ADD_TYPE_SELF,
  ADD_TYPE_SIBLING,
  ADD_TYPE_SPOUSE,
} from './constants'
import { ChooseOrAddIndividual } from '../ChooseOrAddIndividual'
import { isUnknownIndividual } from '../api/nodeDirectory'

const AddIndividualDialog = ({ addIndividualNode }) => {
  const dispatch = useDispatch()

  const handleCloseDialog = useCallback(() => {
    dispatch(clearAddIndividualNode())
    dispatch(clearClickedIndividualId())
  }, [dispatch])

  const [saving, setSaving] = useState(false)

  const dispatchAddFather = useActionDispatcher(addFather)
  const dispatchAddMother = useActionDispatcher(addMother)
  const dispatchAddPartner = useActionDispatcher(addPartner)
  const dispatchAddChild = useActionDispatcher(addChild)
  const dispatchAddSelf = useActionDispatcher(addSelf)
  const handleAddIndividual = useCallback(
    async individualAttributes => {
      setSaving(true)

      const dispatchFn = {
        [ADD_TYPE_CHILD]: dispatchAddChild,
        [ADD_TYPE_MOTHER]: dispatchAddMother,
        [ADD_TYPE_FATHER]: dispatchAddFather,
        [ADD_TYPE_GRAND_MOTHER]: dispatchAddMother,
        [ADD_TYPE_GRAND_FATHER]: dispatchAddFather,
        [ADD_TYPE_SELF]: dispatchAddSelf,
        [ADD_TYPE_SIBLING]: dispatchAddChild,
        [ADD_TYPE_SPOUSE]: dispatchAddPartner,
      }[addIndividualNode.typeToAdd]

      let replacingUnknownSpouseId
      let unknownSpousesChildIds
      if (addIndividualNode.typeToAdd === ADD_TYPE_SPOUSE) {
        //viewerSlice.addPartner.fulfilled() replaces references to a replaced unknown parent with the newly-added parent
        //in each child, so when initiateNodeDirectory() is called it will not recreate the unknown parent

        //console.debug(`AddIndividualDialog.handleAddIndividual(): called with ADD_TYPE_SPOUSE, addIndividualNode.id: ${addIndividualNode.id}, addIndividualNode.addedFromIndividualNode?.id: ${addIndividualNode.addedFromIndividualNode?.id}. addIndividualNode: `, addIndividualNode)
        if (isUnknownIndividual(addIndividualNode.id)) {
          replacingUnknownSpouseId = addIndividualNode.id
          unknownSpousesChildIds = addIndividualNode.children
        }
      }
      const dispatchResponse = await dispatchFn({
        individualId: addIndividualNode.addedFromIndividualNode?.id,
        otherParentId: addIndividualNode.otherParent?.id,
        individualAttributes,
        replacingUnknownSpouseId,
        unknownSpousesChildIds,
      })
      //console.debug(`AddIndividualDialog.handleAddIndividual(): call to dispatchFn() returned:`, res)

      if (addIndividualNode.typeToAdd === ADD_TYPE_SPOUSE) {
        // the above will have added the spouse but not connect any existing children between the spouse and this 'unknown'

        //the response from dispatchAddPartner will include both people, find the other one
        const newIndividualExternalId = dispatchResponse.payload.filter(
          ind => ind.id !== addIndividualNode.addedFromIndividualNode?.id
        )[0].id

        //console.debug(`AddIndividualDialog.handleAddIndividual(): newIndividualExternalId: '${newIndividualExternalId}'`)

        for (const childId of addIndividualNode.children) {
          const individualAttributes = { id: childId }
          //console.debug(`AddIndividualDialog.handleAddIndividual(): calling dispatchAddChild() with newIndividualExternalId '${newIndividualExternalId}', individualAttributes:`, individualAttributes)
          await dispatchAddChild({
            individualId: newIndividualExternalId,
            individualAttributes: individualAttributes,
            otherParentId: addIndividualNode.otherParent?.id,
          })
        }
      }

      setSaving(false)
      handleCloseDialog()
    },
    [
      addIndividualNode,
      dispatchAddChild,
      dispatchAddFather,
      dispatchAddMother,
      dispatchAddPartner,
      dispatchAddSelf,
      handleCloseDialog,
    ]
  )

  const getDefaultSurname = () => {
    if (addIndividualNode) {
      const addedFrom = addIndividualNode?.addedFromIndividualNode

      if (
        addIndividualNode.typeToAdd === 'child' ||
        addIndividualNode.typeToAdd === 'sibling'
      ) {
        const addedFromGender = addedFrom?.gender
        const otherParentGender = addIndividualNode?.otherParent?.gender

        if (addedFromGender === 'M') {
          return addedFrom?.surname
        } else if (otherParentGender === 'M') {
          return addIndividualNode.otherParent.surname
        } else if (addedFrom) {
          return addedFrom.surname
        } else return addIndividualNode.otherParent.surname
      } else if (
        (addIndividualNode.typeToAdd === 'grandfather' && addedFrom) ||
        (addIndividualNode.typeToAdd === 'father' && addedFrom)
      ) {
        return addedFrom.surname
      }
    }
  }

  return (
    <Dialog open={!!addIndividualNode} onClose={handleCloseDialog}>
      <DialogContent>
        <ChooseOrAddIndividual
          defaultSurname={getDefaultSurname()}
          saving={saving}
          onChosenOrAdded={handleAddIndividual}
          individual={addIndividualNode?.addedFromIndividualNode}
        ></ChooseOrAddIndividual>
      </DialogContent>
    </Dialog>
  )
}

export default AddIndividualDialog
