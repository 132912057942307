import { EditorState } from 'draft-js'
import { convertFromHTML, convertToHTML } from 'draft-convert'
import { generateLink } from 'src/modules/app'

const BLOCK_TYPE_FOR_TAG = {
  h1: 'header-one',
  h2: 'header-two',
  h3: 'header-three',
  h4: 'header-four',
  h5: 'header-five',
  h6: 'header-six',
  li: 'unordered-list-item',
  blockquote: 'blockquote',
  pre: 'code-block',
  div: 'unstyled',
  p: 'unstyled',
}
export const TAG_FOR_BLOCK_TYPE = Object.fromEntries(
  Object.entries(BLOCK_TYPE_FOR_TAG).map(([k, v]) => [v, k])
)
// Disambiguate <div> and <p> - standardise on `p`
TAG_FOR_BLOCK_TYPE['unstyled'] = 'p'

/**
 * Given HTML, return a structured editor state object that defines
 * all the draft-js entities etc.
 *
 * This function is mainly used to initialise the editor from stored
 * a stored HTML string. After that, the editor state should be
 * represented by an `EditorState` object, and not continually
 * updated from the raw HTML.
 */
export const htmlToEditorState = html => {
  const contentState = convertFromHTML({
    htmlToEntity,
    htmlToBlock,
  })(html)
  const editorState = EditorState.createWithContent(contentState)
  return editorState
}

/**
 * Detect <a> tags. If they contain extra data about the entity that's
 * represented, ensure that this is passed to draft-js so that we can present
 * them properly.
 */
export const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    const config = {
      url: node.getAttribute
        ? node.getAttribute('href') || node.href
        : node.href,
      title: node.innerHTML,
      targetOption: node.target,
    }
    if (node.dataset.contentType || node.dataset.instanceType) {
      let { contentDisplay, contentType, instanceType, contentId } =
        node.dataset

      // Backwards compatibility
      instanceType = contentType || instanceType

      const url = generateLink(instanceType, contentId)
      Object.assign(config, {
        url,
        contentDisplay,
        contentId,
        instanceType,
      })
    }
    return createEntity('LINK', 'MUTABLE', config)
  }
}

/**
 * Support text alignment on blocks
 */
const htmlToBlock = (nodeName, node) => {
  if (node.style.textAlign) {
    return {
      ...node,
      data: {
        ...node.data,
        'text-align': node.style.textAlign,
      },
    }
  }

  // Nothing to do, so request default conversion:
  return undefined
}

/**
 * Given a draft-js editor state, convert it to HTML for storage.
 *
 * This is run every time we want to save an editor's state.
 *
 * We need to interpret data from the editor into attributes on the
 * HTML that can be interpreted *back* to draft-js form. We do this
 * for entities and text alignment.
 */
export const editorStateToHtml = editorState => {
  const html = convertToHTML({
    entityToHTML,
    blockToHTML,
  })(editorState.getCurrentContent())
  return html
}

/**
 * Keep text alignment data
 */
const blockToHTML = block => {
  const alignment = block.data['text-align']
  if (alignment) {
    const tag = TAG_FOR_BLOCK_TYPE[block.type]
    return `<${tag} style="text-align: ${alignment}">${block.text}</${tag}>`
  }

  // Allow default converstion
  return undefined
}

/**
 * When forming HTML for link entities, add data attributes that store
 * entity properties.
 */
const entityToHTML = (entity, text) => {
  if (entity.type === 'LINK') {
    const targetOption = entity.data.targetOption || '_self'
    const attrs = [
      { key: 'href', value: entity.data.url },
      { key: 'target', value: targetOption },
    ]
    if (entity.data.instanceType) {
      attrs.push({ key: 'data-instance-type', value: entity.data.instanceType })
      attrs.push({ key: 'data-content-id', value: entity.data.contentId })
      attrs.push({
        key: 'data-content-display',
        value: entity.data.contentDisplay,
      })
    }
    const attrsText = attrs
      .map(({ key, value }) => `${key}="${value}"`)
      .join(' ')
    return `<a ${attrsText}>${text}</a>`
  }
}
