/**
 * Layout functions must take an Array of GraphNode instances, and return
 * an array of:
 * - the same graph nodes with x and y coordinates added
 * - an array of Link instances between the graph nodes
 * - the total number of layers in the graph
 */
import * as dagre from 'dagre'
import {
  coordQuad,
  dagConnect,
  decrossOpt,
  decrossTwoLayer,
  layeringSimplex,
  sugiyama,
} from 'd3-dag'
import { flextree } from 'd3-flextree'

import { buildGraphLibGraph, createDirectoryById, treeHeight } from './graphOps'
import { fixIntermarriage, Link } from './graphNode'
import { groupBy } from 'lodash'
import { sortBy } from 'lodash'

/**
 * Tree that can construct itself from a root GraphNode. It then
 * recurses so that each node is itself a Tree object, with a
 * `children` array consisting of Tree objects.
 */
class RecursiveTree {
  constructor(graphNode, graphNodeDirectory, unvisitedGraphNodes) {
    this.graphNode = graphNode
    this.id = graphNode.id

    if (!unvisitedGraphNodes.has(graphNode.id)) {
      console.debug(
        `have visited ${graphNode.id} already so stop here to avoid recursion issues`
      )
    } else {
      // Record this graphNode has been visited:
      unvisitedGraphNodes.delete(graphNode.id)

      this.children = graphNode.children
        .map(id => graphNodeDirectory.get(id))
        .filter(graphNode => !!graphNode)
        .map(
          graphNode =>
            new RecursiveTree(
              graphNode,
              graphNodeDirectory,
              unvisitedGraphNodes
            )
        )
    }
  }

  get size() {
    let [width, height] = this.graphNode.size
    return [width, height]
  }
}

const pairedLinkGroupsLeftToRight = links => {
  const groups = sortBy(
    Object.entries(groupBy(links, l => l.sourceIdx)),
    ([sourceIdx]) => sourceIdx
  ).map(([, links]) => links)

  if (groups.length < 2) {
    return []
  }

  const pairs = []
  for (var i = 0; i < groups.length - 1; i++) {
    pairs.push([groups[i], groups[i + 1]])
  }
  return pairs
}

const rightMostTargetOfLinks = links => {
  return sortBy(links, l => -l.targetGraphNode.x)[0].targetGraphNode.x
}

const raiseLinks = (links, raiseBy = 1) => {
  for (let link of links) {
    link.verticalOffset += raiseBy
  }
}

export const setVerticalLinkOffsets = (graphNode, links) => {
  const raised = []
  let nRaisedRight = 1
  for (const [leftLinks, rightLinks] of pairedLinkGroupsLeftToRight(links)) {
    const parentX = graphNode.x
    const rightMostTarget = rightMostTargetOfLinks(leftLinks)
    if (rightMostTarget < parentX) {
      raised.push(leftLinks)
      raised.forEach(l => raiseLinks(l))
    } else {
      raiseLinks(rightLinks, nRaisedRight++)
    }
  }
}

export const setVerticalLinkOffsetForComplexChildLinks =
  graphNodesWithLinksMap => {
    for (const [graphNode, links] of graphNodesWithLinksMap.entries()) {
      setVerticalLinkOffsets(graphNode, links)
    }
  }

export const d3TreeLayout = (graphNodes, rootGraphNode) => {
  if (!graphNodes || !graphNodes.length || !rootGraphNode) {
    return [[], [], 0]
  }

  const graphNodeDirectory = createDirectoryById(graphNodes)

  // Unvisited starts as all graph node IDs, and visited ones will be removed
  const unvisitedGraphNodes = new Set(Array.from(graphNodeDirectory.keys()))

  const rootLinkedGraphNode = new RecursiveTree(
    rootGraphNode,
    graphNodeDirectory,
    unvisitedGraphNodes
  )

  // Remove any graphnodes which were not reachable by traversing the tree
  graphNodes = graphNodes.filter(gn => !unvisitedGraphNodes.has(gn.id))

  const layout = flextree()
  const tree = layout.hierarchy({
    size: [1, 1],
    children: [rootLinkedGraphNode],
  })
  layout(tree)
  const allLinks = []

  const graphNodesWithComplexChildLinks = new Map()

  tree.each(node => {
    if (!node.data.graphNode) {
      return
    }
    node.data.graphNode.x = node.x
    node.data.graphNode.y = node.y

    const nodeLinks = []
    for (let child of node.children || []) {
      const link = new Link(node.data.graphNode, child.data.graphNode)
      allLinks.push(link)
      nodeLinks.push(link)
    }
    if (node.data.graphNode.hasMultiplePartnerships) {
      graphNodesWithComplexChildLinks.set(node.data.graphNode, nodeLinks)
    }
  })
  setVerticalLinkOffsetForComplexChildLinks(graphNodesWithComplexChildLinks)

  graphNodes = graphNodes.filter(gn => gn.x !== undefined && gn.y !== undefined)

  return [graphNodes, allLinks, treeHeight(graphNodes)]
}

/**
 * Create a Directed Acyclic Graph out of a set of visible individual nodes,
 * each of which defines its linkages to other nodes.
 */
export class DagManager {
  /**
   * Given a set of visible nodes, link them into a "directed acyclic graph"
   * without performing any layout steps.
   *
   * The end result is a DAG object with roots at layer 0 (top of the graph).
   * The descendant nodes will have a layer attribute, but no meaningful
   * coordinates.
   *
   * @returns
   */
  constructor(graphNodes) {
    this.graphNodes = graphNodes
    this.dag = undefined
    this.nLayers = 1

    this.visibleNodeDirectory = createDirectoryById(this.graphNodes)
    this.updateDag()
  }

  updateDag() {
    // Build linkages between visible nodes and their visible children.
    const linkages = []
    const dupe = {}

    //added some defensive code round here to remove potential duplicates
    //this could point to a bug further up but dupes cause
    // dagConnect() below to proper crash
    for (let graphNode of this.graphNodes) {
      for (let id of graphNode.children) {
        const key = `${graphNode.id}-${id}`
        if (dupe[key]) {
          console.log('ignoring duplicate' + key)
        } else {
          linkages.push([graphNode.id, id])
          dupe[key] = key
        }
      }
    }

    if (!linkages.length) {
      return
    }

    this.dag = dagConnect()(linkages)
    for (let bareGraphNode of this.dag.descendants()) {
      bareGraphNode.data = this.visibleNodeDirectory.get(bareGraphNode.data.id)
    }

    const layoutNoop = layers => {
      // Give meaningless coordinates to each node
      for (const layer of layers) {
        for (const node of layer) {
          node.x = 0
          node.y = 0
        }
      }
    }

    // Use d3-dag to give every node a layer and determine the topology, but no
    // other steps
    const layoutFn = sugiyama()
      .nodeSize(() => [1, 1])
      .layering(layeringSimplex())
      .decross(layoutNoop)
      .coord(layoutNoop)
    layoutFn(this.dag)
    this.nLayers = Math.max(...this.dag.descendants().map(d => d.layer)) + 1
  }

  /**
   * State whether graphNodes represent an "N-ary tree". That is, a directed graph
   * with one root, from which all other nodes are reachable.
   *
   * It is much simpler to draw an N-ary tree than a generalised directed acyclic
   * graph with multiple roots, so this information can be used to choose a
   * simpler drawing algorithm.
   */
  get isNaryTree() {
    if (this.dag) {
      return this.dag.roots().length === 1
    } else {
      // Graph is too simple to have a dag (e.g. one node), therefore is true
      return true
    }
  }

  /**
   * Given an individual node, state the number of hops required to
   * connect it to the members of this graph.
   */
  distanceFromIndividualToGraph(nodeDirectory, individualNode) {
    // Build a graph containing every node
    const g = buildGraphLibGraph(Object.values(nodeDirectory), true)
    const paths = dagre.graphlib.alg.dijkstra(g, individualNode.id)
    return Math.min(...this.individualIDs.map(id => paths[id].distance))
  }

  get individualIDs() {
    const ids = []
    for (let graphNode of this.graphNodes) {
      ids.push(...graphNode.individualIDs)
    }
    return ids
  }

  layout() {
    if (this.isNaryTree) {
      let rootId
      if (this.dag) {
        rootId = this.dag.roots()[0].data.id
      } else {
        rootId = this.graphNodes[0].id
      }
      const root = this.visibleNodeDirectory.get(rootId)
      const newNodes = fixIntermarriage(root, this.graphNodes)
      this.graphNodes.push(...newNodes)
      return d3TreeLayout(this.graphNodes, root)
    } else {
      return this.layoutAnyDag()
    }
  }

  /**
   * This is a deterministic, algorithmic layout function which will
   * attempt to lay out an arbitrary directed acyclic graph of nodes.
   *
   * It will do its best to ensure a pleasing layout, but makes no
   * guarantees of correctness.
   *
   * Notes on d3-dag options
   * Decross operators
   * - decrossOpt is slow - will just break for large trees
   * - decrossTwoLayer is the remaining choice. Options:
   *   - .order(mean / median seems to be same)
   *   - .order(decrossOpt()) - won't finish for some graphs
   *
   * Coordinate assignment
   * - coordGreedy is shit - tons of confusion
   * - coordCenter might be useful for ancestry, but in anything complex, it
   *   confuses
   * - coordQuad is probably the best all rounder. Options:
   *   - vertical, curve, component - none of these make much difference
   */
  layoutAnyDag(coordOverride = undefined) {
    if (!this.graphNodes.length) {
      return []
    }
    if (this.graphNodes.length === 1) {
      return [this.graphNodes, [], 1]
    }
    const decross =
      this.graphNodes.length < 30 ? decrossOpt() : decrossTwoLayer()

    const coord = coordOverride || coordQuad()
    // Set up the parameters of the tree drawing algorithm
    let treeFn = sugiyama()
      .nodeSize(n => {
        let width = n.data.individualNodesInDisplayOrder.length
        return [width, n.data.height]
      })
      .layering(layeringSimplex())
      .decross(decross)
      .coord(coord)

    treeFn(this.dag)

    const graphNodesWithComplexChildLinks = new Map()
    const nodes = this.dag.descendants().map(dagNode => {
      const graphNode = dagNode.data
      if (graphNode.hasMultiplePartnerships) {
        graphNodesWithComplexChildLinks.set(graphNode, [])
      }
      graphNode.x = dagNode.x
      graphNode.y = dagNode.y
      return graphNode
    })

    let links = []
    const crossedLinkTargetIds = new Set()
    let reLinkRequired = false
    for (const link of this.dag.links()) {
      const linkObj = new Link(link.source.data, link.target.data, true)
      links.push(linkObj)
      if (linkObj.sourceGraphNode.hasMultiplePartnerships) {
        graphNodesWithComplexChildLinks
          .get(linkObj.sourceGraphNode)
          .push(linkObj)
      }

      if (linkObj.potentialCross) {
        // If the target node is seen twice, we have a potential criss
        // cross of links. Reverse the node order to counter this.
        if (crossedLinkTargetIds.has(linkObj.targetGraphNode.id)) {
          linkObj.targetGraphNode.flipHorizontalAfterFix()
          // Now that orders have been changed, we need to recalculate the links
          reLinkRequired = true
        } else {
          crossedLinkTargetIds.add(linkObj.targetGraphNode.id)
        }
      }
    }
    setVerticalLinkOffsetForComplexChildLinks(graphNodesWithComplexChildLinks)

    if (reLinkRequired) {
      links = this.dag
        .links()
        .map(link => new Link(link.source.data, link.target.data, false))
    }

    return [nodes, links, this.nLayers]
  }
}
