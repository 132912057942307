import { Box, Stack } from '@mui/material'
import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ButtonLink, NavLink } from 'src/modules/ui'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  useTreeLink,
} from '../app/links'

import MyContentLayout from './MyContentLayout'
import MyContentList from './MyContentList'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const MyArticles = () => {
  const myArticlesLink = useTreeLink('my-articles')
  const draftsLink = `${myArticlesLink}/drafts`
  const writeLink = useTreeLink('write-article')
  return (
    <MyContentLayout
      backButton
      createButton={false}
      pageTitle="My Articles and Documents"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink exact to={myArticlesLink} replace>
              Published
            </NavLink>
            <NavLink to={draftsLink} replace>
              Unpublished
            </NavLink>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <ButtonLink permissionAction={ACTION_ALL_ACCESS} to={writeLink}>
            Write Article
          </ButtonLink>
        </Box>
      </Stack>
      <Switch>
        <Route
          exact
          path={myArticlesLink}
          render={() => (
            <MyContentList
              sort="-published_at"
              state="PUBLISHED"
              type={[INSTANCE_TYPE_ARTICLE, INSTANCE_TYPE_DOCUMENT]}
            />
          )}
        />
        <Route
          path={draftsLink}
          render={() => (
            <MyContentList
              sort="-modified"
              state="DRAFT"
              type={[INSTANCE_TYPE_ARTICLE, INSTANCE_TYPE_DOCUMENT]}
            />
          )}
        />
      </Switch>
    </MyContentLayout>
  )
}

export default MyArticles
