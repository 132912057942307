import { getSelectionInlineStyle } from 'draftjs-utils'
import { RichUtils } from 'draft-js'

import { ToggleButton } from '@mui/material'
import { selectedStyles } from './editorUtils'
import { EditorToggleButtonGroup } from './EditorToggleButtonGroup'

export const InlineFormatOptions = ({ editorState, onChange, formats }) => {
  const currentStyles = editorState
    ? selectedStyles(getSelectionInlineStyle(editorState))
    : []

  const toggleInlineStyle = (e, styles) => {
    let styleToToggle = styles.find(s => !currentStyles.includes(s))
    if (!styleToToggle) {
      styleToToggle = currentStyles.find(s => !styles.includes(s))
    }

    let newState = RichUtils.toggleInlineStyle(editorState, styleToToggle)
    if (newState) {
      onChange(newState)
    }
  }

  return (
    <EditorToggleButtonGroup
      value={currentStyles}
      size="small"
      onChange={toggleInlineStyle}
    >
      {formats.map(([format, IconComponent], idx) => (
        <ToggleButton value={format} key={idx}>
          <IconComponent fontSize="small" />
        </ToggleButton>
      ))}
    </EditorToggleButtonGroup>
  )
}
