import { Badge, Box, CircularProgress, Fab } from '@mui/material'
import React from 'react'
import { usePermissions } from '../auth/authHooks'
import { PERMISSION_DENIED_TYPE_ERROR } from '../auth/permissionUtils'
import { HasAccessTooltip } from './Button'

export default function FabButton({
  text,
  icon,
  disabled = false,
  permissionAction,
  permissionParams,
  toolTipOverride,
  overridePermissions = false,
  loading = false,
  ...props
}) {
  const checkPermissions = usePermissions()

  const { hasPermission, permissionDeniedReason, permissionDeniedType } =
    checkPermissions(permissionAction, permissionParams)

  if (permissionDeniedType === PERMISSION_DENIED_TYPE_ERROR) {
    //console.log('PERMISSION', 'Button', permissionDeniedType)
  }

  //hack her with tooltipoverride could not be arsed at this stage to rewrite the  visibilityUtils - RGS 14/8/2023
  let buttonDisabled =
    disabled || (toolTipOverride === undefined && !hasPermission) || loading

  return (
    <HasAccessTooltip
      toolTipText={toolTipOverride || permissionDeniedReason}
      featureAvailable={hasPermission}
    >
      <Fab
        disabled={buttonDisabled}
        variant="extended"
        color="white"
        key="fab"
        sx={{
          pl: 1.4,
          pr: 0.5,
          height: 36,
          cursor: 'pointer',
          boxShadow: 'none',
        }}
        {...props}
      >
        {text}
        <Badge color="secondary">
          <Box
            sx={{
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ml: 0.5,
              pr: 0.5,
            }}
          >
            {loading ? <CircularProgress size={'0.9rem'} /> : icon}
          </Box>
        </Badge>
      </Fab>
    </HasAccessTooltip>
  )
}
