import { getSelectedBlocksMetadata, setBlockData } from 'draftjs-utils'

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'

import { ToggleButton } from '@mui/material'

import { EditorToggleButtonGroup } from './EditorToggleButtonGroup'

const OPTION_TEXT_ALIGN = 'text-align'

const TEXT_ALIGN_LEFT = 'left'
const TEXT_ALIGN_CENTRE = 'center'
const TEXT_ALIGN_RIGHT = 'right'
const TEXT_ALIGN_JUSTIFY = 'justify'

export const TextAlignOptions = ({ editorState, onChange }) => {
  const alignmentState =
    getSelectedBlocksMetadata(editorState).get(OPTION_TEXT_ALIGN)

  const addBlockAlignmentData = (e, value) => {
    if (alignmentState !== value) {
      onChange(setBlockData(editorState, { [OPTION_TEXT_ALIGN]: value }))
    } else {
      onChange(setBlockData(editorState, { [OPTION_TEXT_ALIGN]: undefined }))
    }
  }

  return (
    <EditorToggleButtonGroup
      value={alignmentState}
      exclusive
      onChange={addBlockAlignmentData}
      size="small"
    >
      <ToggleButton value={TEXT_ALIGN_LEFT}>
        <FormatAlignLeftIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={TEXT_ALIGN_CENTRE}>
        <FormatAlignCenterIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={TEXT_ALIGN_RIGHT}>
        <FormatAlignRightIcon fontSize="small" />
      </ToggleButton>
      <ToggleButton value={TEXT_ALIGN_JUSTIFY}>
        <FormatAlignJustifyIcon fontSize="small" />
      </ToggleButton>
    </EditorToggleButtonGroup>
  )
}
