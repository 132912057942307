import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

export const usePrerender = dependeciesLoaded => {
  const [prerenderState, setPrerenderState] = useState(false)
  useEffect(() => {
    if (dependeciesLoaded) {
      setPrerenderState(true)
      window.prerenderReady = true
    }
  }, [dependeciesLoaded])

  return prerenderState
}

export const useEmbed = () => {
  const location = useLocation()
  // Remove first ? char
  const querystring = qs.parse(location.search.substr(1))
  const { embed } = querystring

  return embed
}
