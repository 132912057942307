import { Box, MenuItem } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const DummyMenuButton = ({ title }) => {
  const classes = useStyles()

  return (
    <Box className={classes.buttonContainer}>
      <MenuItem>{title}</MenuItem>
    </Box>
  )
}

export default DummyMenuButton
