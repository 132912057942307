import React from 'react'
import { useSelector } from 'react-redux'

import { CreateOrUpdateTree } from 'src/modules/trees/CreateOrUpdateTree'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

const Gedcom = () => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  return <CreateOrUpdateTree treeSlugToUpdate={treeSlug} />
}

export default Gedcom
