/* eslint react/no-unused-prop-types: "warn",
          jsdoc/require-jsdoc: "warn", jsdoc/check-param-names: "warn", jsdoc/require-param-type: "warn", jsdoc/newline-after-description: "warn", jsdoc/check-alignment: "warn"
*/
import parse from 'html-react-parser'
import { makeStyles } from '@mui/styles'
import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'

import { PhotoListNavigatorDialog } from 'src/modules/photo/MediaNavigator'

import ArticlePhoto from './ArticlePhoto'
import { options as defaultOptions } from './parseOptions'
import { makeParseOptions } from './parseOptions'
import RefreshDialog from 'src/modules/ui/RefreshDialog'
import { useLocation } from 'react-router-dom'
import useDetectPrint from 'react-detect-print'

export const MEDIA_COLUMN_SIZE_MAP = {
  SMALL: 300,
  MEDIUM: 400,
  LARGE: 500,
}

const useStyles = makeStyles(theme => ({
  photo: {
    marginRight: '0 !important',
    marginBottom: '0 !important',
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  photoColumnBlock: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      display: 'flex',
    },
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
  photoColumn: {
    flexShrink: 0,
    maxWidth: '85%',
    minWidth: '15%',
  },
  photoColumnBlockPrinting: {
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
  photoColumnPrinting: {
    flexShrink: 0,
    maxWidth: '40%',
    minWidth: '15%',
  },
  photoColumnText: {
    flexGrow: 1,
    wordBreak: 'break-word',
    '& > *:first-child': {
      marginTop: 0,
    },
  },
  photoColumnLeft: {
    marginRight: theme.spacing(4),
  },
  photoColumnRight: {
    marginLeft: theme.spacing(4),
  },
  mediaImage: {
    objectFit: 'cover',
    display: 'block',
    width: '100%',
    height: 'auto',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  textOnlyBlock: {
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
}))

const TextBlock = ({
  layout,
  textContent,
  sources,
  isPublic,
  treeSlug,
  enableLinks,
  ...props
}) => {
  const LayoutMap = {
    TEXT_BLOCK: TextOnly,
    MEDIA_COLUMN: PhotoColumn,
  }

  const location = useLocation()

  //these options are used in READ/VIEW mode, not EDIT mode which uses draft.js
  defaultOptions.isPublic = isPublic
  defaultOptions.treeSlug = treeSlug
  defaultOptions.sources = sources

  const options = makeParseOptions({
    url: location.pathname,
    enableLinks,
  })

  const Block = LayoutMap[layout] || TextOnly
  const text = parse(textContent, options)

  return <Block text={text} {...props} />
}

const TextOnly = ({ text, classes }) => {
  const localClasses = useStyles()
  let mixedClasses
  if (classes) {
    mixedClasses = {
      ...localClasses,
      ...classes,
    }
  } else {
    mixedClasses = localClasses
  }
  return <div className={mixedClasses.textOnlyBlock}>{text}</div>
}

const PhotoColumn = ({
  allArticlePhotos,
  media,
  mediaColumnPosition,
  mediaColumnSize,
  mediaColumnMeta,
  text,
  allowDetailsScreenOnLinks = true,
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isPrinting = useDetectPrint()
  const [showComponent, setshowComponent] = useState(false)
  const [triggerReload, setTriggerReload] = useState(false)
  const [clickedPhoto, setClickedMedia] = useState(null)
  const classes = useStyles()
  const rootRef = useRef()
  const photoColRef = useRef()
  const textRef = useRef()

  const positionMap = {
    LEFT: classes.photoColumnLeft,
    RIGHT: classes.photoColumnRight,
  }

  const handleDialogClose = () => {
    setClickedMedia(null)
  }

  const colWidth =
    (MEDIA_COLUMN_SIZE_MAP[mediaColumnSize] / rootRef?.current?.offsetWidth) *
      100 || mediaColumnMeta

  // need to use the height of the photo column if it is taller than the text height to prevent text from blocks underneath filling the white space caused by the photo column being taller than the text
  const handleBlockHeight = useCallback(() => {
    const photoColHeight = photoColRef?.current?.offsetHeight
    const textHeight = textRef?.current?.offsetHeight
    if (!mobileBreakpoint && photoColHeight > textHeight) {
      rootRef.current.style.minHeight = `${photoColHeight}px`
    }
  }, [mobileBreakpoint])

  useLayoutEffect(() => {
    handleBlockHeight()
    setshowComponent(true)
    window.addEventListener('resize', handleBlockHeight)
    return () => {
      window.removeEventListener('resize', handleBlockHeight)
    }
  }, [handleBlockHeight])

  const float = mediaColumnPosition?.toLowerCase()

  const photoColumn = (
    <div
      ref={photoColRef}
      // eslint-disable-next-line
      onLoad={handleBlockHeight}
      className={clsx(
        isPrinting ? classes.photoColumnPrinting : classes.photoColumn,
        positionMap[mediaColumnPosition]
      )}
      style={{
        width: mobileBreakpoint ? '100%' : `${colWidth}%`,
        float,
      }}
    >
      {media?.map((photo, idx) => (
        <ArticlePhoto
          className={classes.photo}
          imageClassName={classes.mediaImage}
          photo={photo}
          onClick={
            allowDetailsScreenOnLinks ? () => setClickedMedia(photo) : null
          }
          key={idx}
          mediaColumnSize={mediaColumnSize}
        />
      ))}
      {!!clickedPhoto && (
        <PhotoListNavigatorDialog
          isArticle
          media={allArticlePhotos}
          initialPhoto={clickedPhoto}
          onClose={handleDialogClose}
          handleDelete={() => setTriggerReload(true)}
        />
      )}
      <RefreshDialog refreshModalOpen={triggerReload} />
    </div>
  )

  return (
    <div
      ref={rootRef}
      style={{ visibility: showComponent ? 'visible' : 'hidden' }}
      className={
        isPrinting ? classes.photoColumnBlockPrinting : classes.photoColumnBlock
      }
    >
      {photoColumn}
      <div
        // eslint-disable-next-line
        onLoad={handleBlockHeight}
        ref={textRef}
        className={classes.photoColumnText}
      >
        {text}
      </div>
    </div>
  )
}

export default TextBlock
