import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  innerShadow: {
    position: 'absolute',
    boxShadow: '0px -1px 5px 0px inset rgb(0 0 0 / 70%)',
    bottom: 0,
    top: -5,
    left: -5,
    right: -5,
    pointerEvents: 'none',
  },
  treeViewerBackground: {
    opacity: 0,
    cursor: 'grab',
  },
  zoomControl: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    width: '100px',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(6),
      left: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
  },
  zoomControlButton: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  treeViewer: {
    position: 'relative',
    marginTop: theme.spacing(5),
  },
  treeBackground: {
    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  viewWindow: {
    position: 'relative',
    zIndex: 1,
  },
  treeControl: {
    padding: theme.spacing(2, 2, 1, 2),
    borderRadiusBottom: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    width: '250px',
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(2),
  },
  treeControlButton: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  shadowContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  horizontalScrollContainer: {
    overflowX: 'scroll',
  },
}))
