import React from 'react'
import { Avatar, styled } from '@mui/material'
import {
  formatIndividualInitials,
  formatIndividualName,
} from '../ui/individualUtils'

export const StyledAvatar = styled(Avatar)(({ size, theme }) => ({
  height: size,
  width: size,
  borderRadius: 80 / 2,
  objectFit: 'cover',
  backgroundColor: theme.palette.lightGrey.main,
  color: theme.palette.profileGrey.main,
  fontFamily: "'At Hauss Std', sans-serif",
}))

const ProfilePicture = ({ size = 50, user, ...props }) => {
  let photo
  let initials = []

  photo = user?.photo?.fileThumbnail

  initials = formatIndividualInitials(user)

  if (photo) {
    return (
      <StyledAvatar
        alt={formatIndividualName(user)}
        src={photo}
        size={size}
        {...props}
      />
    )
  } else {
    return (
      <StyledAvatar size={size} {...props}>
        {initials}
      </StyledAvatar>
    )
  }
}
export default ProfilePicture
