import { styled } from '@mui/styles'
import { Box } from '@mui/system'
import IconButton from '../IconButton'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const MenuButtonBox = styled(Box)(({ theme }) => ({
  padding: '-1px',
  display: 'inline-flex',
  borderRadius: theme.shape.borderRadius,
}))

export const MenuIconButton = ({ children, ...props }) => {
  return (
    <MenuButtonBox>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        style={{ background: 'none' }}
        color="grey"
        {...props}
      >
        {children}
      </IconButton>
    </MenuButtonBox>
  )
}
