import { useEffect, useMemo, useRef } from 'react'

import { minMaxX } from '../api/graphOps'
import {
  EXPLORED_INDIVIDUAL_SIDE_PADDING,
  SIZE_MODE_FIT_NODES,
  FOCUS_MODE_SCROLL,
} from './constants'
import { INDVDL_NODE_GRID_WIDTH } from './constants'

/**
 * Perform calculations based sizeMode, focusMode and the configuration of
 * graphNodes to figure out the horizontal width of that will contain all the
 * nodes.
 *
 * If required by focusMode, create a ref that can be attached to an element
 * that will scroll the graph node corresponding to targetIndividualNode into
 * view.
 *
 * @returns A ref to attach to the scroll container, and its total width
 */
export const useSetUpAndScrollTreeContainer = ({
  sizeMode,
  focusMode,
  graphNodes,
  initialZoom = 1,
  targetIndividualNode,
}) => {
  const scrollRef = useRef()

  const fitToNodes = sizeMode === SIZE_MODE_FIT_NODES && graphNodes?.length
  const doScroll = focusMode === FOCUS_MODE_SCROLL && fitToNodes

  const [widthToFitAllNodes, relativeXPositionOfExplored] = useMemo(() => {
    if (!fitToNodes) {
      return []
    }
    let [minX, maxX] = minMaxX(graphNodes)

    // Add a padding factor to give extra space
    minX -= EXPLORED_INDIVIDUAL_SIDE_PADDING
    maxX += EXPLORED_INDIVIDUAL_SIDE_PADDING
    const widthInXCoords = maxX - minX

    // Expand the minimum width of the tree's containing div
    let widthToFitAllNodes =
      widthInXCoords * INDVDL_NODE_GRID_WIDTH * initialZoom

    const focusModeTargetGraphNode = graphNodes.find(
      ({ id }) => id && id.includes(targetIndividualNode?.id)
    )
    let relativeXPositionOfExplored
    if (focusModeTargetGraphNode) {
      const exploredX = focusModeTargetGraphNode.x
      relativeXPositionOfExplored = (exploredX - minX) / widthInXCoords
    } else {
      relativeXPositionOfExplored = 0.5
    }
    return [widthToFitAllNodes, relativeXPositionOfExplored]
  }, [fitToNodes, graphNodes, initialZoom, targetIndividualNode])

  // Called every time
  useEffect(() => {
    if (scrollRef.current && doScroll) {
      const halfScreen = scrollRef.current.clientWidth / 2
      let pixelPositionOfExplored =
        relativeXPositionOfExplored * widthToFitAllNodes
      let pixelPositionOfLeftEdge = pixelPositionOfExplored - halfScreen

      // Zero length timeout to ensure this happens after render
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollLeft = Math.max(pixelPositionOfLeftEdge, 0)
        }
      })
    }
  })

  return [scrollRef, widthToFitAllNodes || INDVDL_NODE_GRID_WIDTH]
}
