import React from 'react'
import { Box, Link as MuiLink, Stack, useMediaQuery } from '@mui/material'
import pluralize from 'pluralize'
import { useSelector } from 'react-redux'

import { Link, Typography } from 'src/modules/ui'
import ProfilePicture from 'src/modules/content/ProfilePicture'
import InstanceVisibilityControl from 'src/modules/visibility/InstanceVisibilityControl'
import { formatDate } from 'src/utils'
import { generateLinkForObject } from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_INFORMATION_REQUEST,
  PUBLIC_SHARE,
} from '../app/links'
import { DEFAULT_ARTICLE_DISPLAY_CONFIG } from './Article'
import { formatIndividualName } from '../ui/individualUtils'
import { useUrlTypeFromUrl } from '../app'
import useDetectPrint from 'react-detect-print'

export const PublishedAt = ({ publishedAt }) => {
  return (
    <>
      <Typography
        color="textSecondary"
        sx={{ pl: 2, display: { xs: 'none', md: 'block' } }}
      >
        •
      </Typography>
      {publishedAt && (
        <Typography color="textSecondary" sx={{ pl: 2 }}>
          Last updated {formatDate(publishedAt)}
        </Typography>
      )}
    </>
  )
}

const IndividualLink = ({ children, individual, ...props }) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  if (individual?.id) {
    return (
      <Link
        to={generateLinkForObject(
          treeSlug,
          INSTANCE_TYPE_INDIVIDUAL,
          individual.id
        )}
        {...props}
      >
        {children}
      </Link>
    )
  } else {
    return children || null
  }
}

export const AuthorLink = ({
  type = INSTANCE_TYPE_ARTICLE,
  author,
  size = 40,
  fontSize,
  hidePhoto,
  ...props
}) => {
  const displayName = formatIndividualName(author)
  const urlType = useUrlTypeFromUrl()
  const profilePic = <ProfilePicture user={author} size={size} sx={{ mr: 1 }} />

  const clickableAuthorLink = (
    <IndividualLink
      individual={{ ...author, id: author.individualOnTreeId }}
      underline="none"
    >
      {profilePic}
    </IndividualLink>
  )

  const readOnlyAuthorLink = profilePic

  let authorLink = clickableAuthorLink
  if (urlType === PUBLIC_SHARE) {
    authorLink = readOnlyAuthorLink
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} {...props}>
      {type === INSTANCE_TYPE_INFORMATION_REQUEST ? (
        <Typography mr={1}>From: </Typography>
      ) : (
        !hidePhoto && authorLink
      )}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Typography color="textPrimary" sx={{ fontWeight: 400, mr: 1 / 2 }}>
          {displayName}
        </Typography>
      </Box>
    </Box>
  )
}

const CommentsLink = ({ children, showLink }) => {
  if (showLink) {
    return (
      <MuiLink href="#comments" underline="hover">
        {children}
      </MuiLink>
    )
  } else {
    return children
  }
}

export const HeaderComments = ({ linkToComments, commentCount }) => {
  return (
    <>
      <Typography
        color="textSecondary"
        sx={{ pl: 2, display: { xs: 'none', md: 'block' } }}
      >
        •
      </Typography>
      <CommentsLink showLink={linkToComments}>
        <Typography color="textSecondary" sx={{ pl: 2 }}>
          {pluralize('comment', commentCount, true)}
        </Typography>
      </CommentsLink>
    </>
  )
}

const ContentHeaderMeta = ({
  urlType,
  actions,
  authorIndividual,
  author,
  commentCount,
  id,
  linkToComments = true,
  publishedAt,
  created,
  type,
  visibility,
  config = DEFAULT_ARTICLE_DISPLAY_CONFIG,
  sx,
  slideshow,
}) => {
  let mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isPrinting = useDetectPrint()
  mobileBreakpoint = isPrinting ? false : mobileBreakpoint // don't use mobile breakpoint when printing

  return (
    <Stack
      direction={mobileBreakpoint ? 'column-reverse' : 'row'}
      alignItems={isPrinting ? 'center' : 'flex-start'}
      sx={sx}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: { xs: 2, md: 0 },
          mb: { xs: 1, md: 0 },
          justifyContent: isPrinting ? 'flex-start' : 'space-evenly',
          width: { xs: '100%', md: 'auto' },
        }}
      >
        <AuthorLink author={author} />
        <IndividualLink
          individual={authorIndividual}
          underline="hover"
        ></IndividualLink>
        <Typography color="primary.main"> {formatDate(created)}</Typography>
        <Box sx={{ display: { sx: 'block', md: 'flex' } }}>
          {!!publishedAt && <PublishedAt publishedAt={publishedAt} />}
          {commentCount != null && config.showComments && (
            <HeaderComments
              linkToComments={linkToComments}
              commentCount={commentCount}
            />
          )}
        </Box>
      </Box>
      {!isPrinting && (
        <Box
          className="noneDisplayOnPrint"
          sx={{ display: 'flex', mt: { xs: 1, md: 0 } }}
        >
          {((visibility != null &&
            type !== INSTANCE_TYPE_INFORMATION_REQUEST) ||
            (!!actions && type !== INSTANCE_TYPE_INFORMATION_REQUEST)) && (
            <Typography
              color="textSecondary"
              sx={{
                pl: 2,
                display: { xs: 'none', md: 'block' },
                alignSelf: 'center',
              }}
            >
              •
            </Typography>
          )}
          {visibility != null && (
            <InstanceVisibilityControl
              id={id}
              author={author}
              visibility={visibility}
              type={type}
              sx={{ pl: 2, mr: 1 }}
              iconSize="medium"
              slideshow={slideshow}
            />
          )}
          {actions}
        </Box>
      )}
      {type === INSTANCE_TYPE_INFORMATION_REQUEST ? null : (
        <Box sx={{ flexGrow: 1 }} />
      )}
    </Stack>
  )
}

export default ContentHeaderMeta
