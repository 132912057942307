import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Map from '../../map/Map'
import { Box, Dialog } from '@mui/material'
import { DisplayCaption } from '../../writeArticle/EditablePhoto'
import {
  MEDIA_ROW_HEIGHT_LARGE,
  MEDIA_ROW_HEIGHT_MAP,
} from '../../writeArticle/contentBlockConstants'
import { MapContainer } from '../../writeArticle/MapBlock'

const PublicMapBlock = ({ mediaRowMeta, mapBlock }) => {
  const containerRef = useRef()
  const location = useLocation()
  const [mapOpen, setMapOpen] = useState(false)
  let publicMap = mapBlock

  const openMap = () => {
    setMapOpen(true)
  }

  const closeMap = () => {
    setMapOpen(false)
  }

  const rowHeight = MEDIA_ROW_HEIGHT_MAP[MEDIA_ROW_HEIGHT_LARGE] || mediaRowMeta
  return (
    <Box ref={containerRef}>
      <Dialog
        open={mapOpen}
        fullScreen
        PaperProps={{
          sx: {
            backgroundColor: 'black',
          },
        }}
      >
        <Map
          isEditing={location?.pathname?.includes('write-article')}
          closeMap={closeMap}
          currentMap={publicMap}
        />
      </Dialog>
      <MapContainer onClick={openMap} rowHeight={rowHeight}>
        <Map currentMap={publicMap} isArticle={true} />
      </MapContainer>
      {mapBlock?.title && (
        <Box py={2}>
          <DisplayCaption photo={{ ...mapBlock, caption: mapBlock?.title }} />
        </Box>
      )}
    </Box>
  )
}

export default PublicMapBlock
