import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Autocomplete, Stack } from '@mui/material'
import { Box, styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'

import { generateLinkForObject, useActionDispatcher } from 'src/modules/app'
import { generateTreeLink } from 'src/modules/app/links'
import {
  clearNameCompletionResults,
  searchNameCompletion,
  selectNameCompletionResults,
} from './searchSlice'
import { useHistory } from 'react-router-dom'
import { capitalize, isString } from 'lodash'
import { SearchTextField } from './SearchTextField'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

const SITE_SEARCH_OPTION = 'site-search'

const SearchResultPreviewThumbnail = styled('img')(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  objectFit: 'cover',
  marginRight: theme.spacing(1),
}))

const SearchResult = ({ display, instanceType, previewThumbnail }) => {
  return (
    <Stack direction="row">
      <SearchResultPreviewThumbnail
        src={previewThumbnail.fileThumbnail || '/person-placeholder.png'}
        alt={display}
      />
      <Stack>
        <Box sx={{ fontSize: 13 }}>{display}</Box>
        <Box sx={{ fontSize: 9 }}>{capitalize(instanceType)}</Box>
      </Stack>
    </Stack>
  )
}

const SearchSite = ({ inputValue }) => {
  return (
    <Stack direction="row">
      <Box sx={{ mr: 1, ml: 1 }}>
        <SearchIcon />
      </Box>

      <Box sx={{ fontSize: 13 }}>Search for {inputValue}</Box>
    </Stack>
  )
}

export const IndividualFamilySearch = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [inputValue, setInputValue] = useState('')
  const dispatchSearchNameCompletion = useActionDispatcher(searchNameCompletion)
  const [searchAction, setSearchAction] = useState(null)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const results = [...useSelector(selectNameCompletionResults)]
  if (inputValue) {
    // Add a special option to search entire site
    results.push({
      id: SITE_SEARCH_OPTION,
    })
  }

  const handleInputChange = useCallback(
    (e, value) => {
      setInputValue(value)
      if (value) {
        if (searchAction) {
          searchAction.abort()
        }
        setSearchAction(
          dispatchSearchNameCompletion({ query: value, sort: ['type'] })
        )
      } else {
        dispatch(clearNameCompletionResults())
      }
    },
    [dispatch, dispatchSearchNameCompletion, searchAction]
  )

  const handleSelectResult = useCallback(
    (e, value) => {
      if (!value) {
        return
      }
      if (isString(value) || value.id === SITE_SEARCH_OPTION) {
        // User has pressed enter or selected site search
        history.push(generateTreeLink(treeSlug, `search?q=${inputValue}`))
      } else {
        history.push(
          generateLinkForObject(treeSlug, value.instanceType, value.id)
        )
      }
      setInputValue('')
      dispatch(clearNameCompletionResults())
    },
    [dispatch, history, inputValue, treeSlug]
  )

  const handleOnBlur = useCallback(() => {
    dispatch(clearNameCompletionResults())
  }, [dispatch])

  return (
    <Autocomplete
      freeSolo
      filterOptions={(options, state) => options}
      options={[...results]}
      noOptionsText={'No results'}
      onBlur={handleOnBlur}
      getOptionLabel={option => option?.display || inputValue}
      renderOption={(props, searchResult) => (
        <li {...props} key={searchResult.id}>
          {searchResult.id === SITE_SEARCH_OPTION ? (
            <SearchSite inputValue={inputValue} />
          ) : (
            <SearchResult {...searchResult} />
          )}
        </li>
      )}
      onInputChange={handleInputChange}
      onChange={handleSelectResult}
      renderInput={params => (
        <Box sx={{ width: 200 }}>
          <SearchTextField {...params} placeholder={'Search...'} />
        </Box>
      )}
      inputValue={inputValue}
    />
  )
}
