import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { fetchGoalsShortly } from 'src/modules/goals/goalsSlice'
import {
  createInformationRequest,
  updateInformationRequest,
} from 'src/modules/informationRequest/informationRequestSlice'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'

const SLICE_NAME = 'accountAdmin'

// generic info on roles with permissions and help texts
export const fetchPermissionsInfo = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPermissionsInfo`,
  id => api.get(`/account/permissions-info/`)
)

// these are user-specific, the data includes a 'manageable' flag
export const fetchAvailableRoles = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAvailableRoles`,
  ({ treeSlug }) => {
    return api.get(`/account/${treeSlug}/user/tree-roles/`)
  },
  {
    getPendingMeta: () => ({
      treeSlug: getTreeSlugFromStore(),
    }),
  }
)

export const fetchAllUsersAndAliveIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAllUsersAndAliveIndividuals`,
  ({ treeSlug }) => {
    return api.get(`/tree/${treeSlug}/living-individuals/`)
  },
  {
    getPendingMeta: () => ({
      treeSlug: getTreeSlugFromStore(),
    }),
  }
)

export const updateUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateUser`,
  ({ id, params }) =>
    api.patch(`/account/${getTreeSlugFromStore()}/user/${id}/`, {
      body: { ...params },
    })
)

// Added to remove a user from tree, function not currently used but will be implemented in future
export const removeUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/removeUser`,
  params =>
    api.patch(`/account/${getTreeSlugFromStore()}/user/remove-from-tree/`, {
      body: params,
    })
)

export const disableUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/disableUser`,
  id => api.post(`/account/${getTreeSlugFromStore()}/user/${id}/disable/`)
)

export const enableUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/enableUser`,
  id => api.post(`/account/${getTreeSlugFromStore()}/user/${id}/enable/`)
)

export const inviteUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/inviteUser`,
  (params, { dispatch }) => {
    fetchGoalsShortly(dispatch)
    return api.post(`/account/${getTreeSlugFromStore()}/user/invite/`, {
      body: params,
    })
  }
)

export const resendInvite = createWrappedAsyncThunk(
  `${SLICE_NAME}/resendInvite`,
  params =>
    api.patch(`/account/${getTreeSlugFromStore()}/user/resend-invite/`, {
      body: params,
    })
)
export const cancelInvite = createWrappedAsyncThunk(
  `${SLICE_NAME}/cancelInvite`,
  params =>
    api.patch(`/account/${getTreeSlugFromStore()}/user/cancel-invite/`, {
      body: params,
    })
)

const handleUpdate = (state, payload) => {
  const index = state.usersByRelationship.results.findIndex(
    u => u.user?.id === payload.id
  )
  if (index !== -1) {
    state.usersByRelationship.results[index].user = payload
  }

  const index2 = state.allUsersAndAliveIndividuals.results.findIndex(
    u => u.user?.id === payload.id
  )
  if (index2 !== -1) {
    state.allUsersAndAliveIndividuals.results[index2].user = payload
  }
}

export const initialState = {
  usersByRelationship: {
    loading: false,
    treeSlug: null,
    results: [],
  },
  unrelatedUsers: {
    loading: false,
    treeSlug: null,
    results: [],
  },
  allUsersAndAliveIndividuals: {
    loading: false,
    treeSlug: null,
    results: [],
  },
  availableRoles: {
    loading: false,
    treeSlug: null,
    results: {}, // {'sdf3qdx': { id: 'sdf3qdx', name: 'SuperAdmin', description: 'all power' }}
  },
  permissionsInfo: {
    loading: null,
    results: {}, // {'sdf3qdx': { id: 'sdf3qdx', name: 'SuperAdmin', description: 'all power' }}
  },
}

const informationRequestUpdate = (state, { payload }) => {
  const { infoRequestTos } = payload
  // Update individual users after info request as they can now be invited
  infoRequestTos.forEach(recipient => {
    state.usersByRelationship.results
      .filter(individual => individual.id === recipient.id)
      .forEach(individual => (individual.user = recipient.user))
  })
}

export const accountAdminSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: {
    [fetchAllUsersAndAliveIndividuals.pending]: (state, { meta }) => {
      state.allUsersAndAliveIndividuals.loading = true
      state.allUsersAndAliveIndividuals.treeSlug = meta.treeSlug
      state.allUsersAndAliveIndividuals.results = []
    },
    [fetchAllUsersAndAliveIndividuals.fulfilled]: (state, { payload }) => {
      state.allUsersAndAliveIndividuals.loading = false
      state.allUsersAndAliveIndividuals.results = payload
    },
    [fetchAllUsersAndAliveIndividuals.rejected]: state => {
      state.allUsersAndAliveIndividuals.loading = false
    },

    [fetchAvailableRoles.pending]: (state, { meta }) => {
      state.availableRoles.loading = true
      state.availableRoles.treeSlug = meta.treeSlug
      state.availableRoles.results = []
    },
    [fetchAvailableRoles.fulfilled]: (state, { payload }) => {
      state.availableRoles.loading = false
      //convert the array into an object so that it is easy to find a role by id
      state.availableRoles.results = payload.reduce(
        // eslint-disable-next-line no-sequences
        (acc, curr) => ((acc[curr.id] = curr), acc),
        {}
      )
    },
    [fetchAvailableRoles.rejected]: state => {
      state.availableRoles.loading = false
    },

    [fetchPermissionsInfo.pending]: (state, { meta }) => {
      state.permissionsInfo.loading = true
      state.permissionsInfo.results = []
    },
    [fetchPermissionsInfo.fulfilled]: (state, { payload }) => {
      state.permissionsInfo.loading = false
      //convert the array into an object so that it is easy to find a role by id
      state.permissionsInfo.results = payload
    },
    [fetchPermissionsInfo.rejected]: state => {
      state.permissionsInfo.loading = false
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      handleUpdate(state, payload)
    },
    [disableUser.fulfilled]: (state, { payload }) => {
      handleUpdate(state, payload)
    },
    [enableUser.fulfilled]: (state, { payload }) => {
      handleUpdate(state, payload)
    },
    [inviteUser.fulfilled]: (state, { meta, payload }) => {
      const indexByRelationship = state.usersByRelationship.results.findIndex(
        u => u.id === meta.arg.individual_id
      )
      const indexNoRelationship = state.unrelatedUsers.results.findIndex(
        u => u.id === meta.arg.individual_id
      )
      if (indexByRelationship > -1) {
        state.usersByRelationship.results[indexByRelationship].user = payload
      } else if (indexNoRelationship > -1) {
        state.unrelatedUsers.results[indexNoRelationship].user = payload
      }
      sendEvent(ga4Events.USER_INVITE_SENT)
    },
    [createInformationRequest.fulfilled]: informationRequestUpdate,
    [updateInformationRequest.fulfilled]: informationRequestUpdate,
  },
})

export const selectUsersByRelationship = state =>
  state.accountAdmin.usersByRelationship
export const selectAllUsersAndAliveIndividuals = state =>
  state.accountAdmin.allUsersAndAliveIndividuals
export const selectAvailableRoles = state => state.accountAdmin.availableRoles
export const selectPermissionsInfo = state => state.accountAdmin.permissionsInfo
export default accountAdminSlice.reducer
