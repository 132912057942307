import React from 'react'

import PageContentList from './PageContentList'
import { useLinkedPageTargets } from './hooks'
import { INSTANCE_TYPE_DOCUMENT } from '../app/links'

const Documents = () => {
  const { target, presetTargets } = useLinkedPageTargets()

  return (
    <>
      <PageContentList
        presetTargets={presetTargets}
        target={target}
        state="PUBLISHED"
        type={INSTANCE_TYPE_DOCUMENT}
      />
    </>
  )
}

export default Documents
