import { Paper } from '@mui/material'

export const ToolbarContainer = ({ fixed, ...props }) => (
  <Paper
    {...props}
    sx={{
      ...(!fixed && {
        backgroundColor: 'purpleShadow.main',
        p: 0.5,
      }),
      zIndex: 1000,
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
      '& > *': {
        ml: 0.5,
      },
      '& > *:first-of-type': {
        ml: 0,
      },
    }}
  />
)
