import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import LinkedPage from 'src/modules/page/LinkedPage'
import { INSTANCE_TYPE_EVENT } from 'src/modules/app/links'
import {
  getHistoryRoute,
  getDocumentsRoute,
  getPhotosRoute,
  getWelcomeRoute,
  getLocationsRoute,
  getArtefactsRoute,
  getEventsRoute,
  getPeopleRoute,
} from './Page'
import { useIsAlphaFeatureUser } from '../auth/hooks'

const EventsPage = () => {
  const { linkedPageId } = useParams()
  const showFeature = useIsAlphaFeatureUser()?.documents

  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const routes = [
    getWelcomeRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
      localLabelOverride: 'Description',
      makeDefault: true,
    }),
    getHistoryRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
    }),
    showFeature &&
      getDocumentsRoute({
        pageType: INSTANCE_TYPE_EVENT,
        linkedPageId,
        treeSlug,
      }),
    getPhotosRoute({ pageType: INSTANCE_TYPE_EVENT, linkedPageId, treeSlug }),
    getEventsRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
    }),
    getArtefactsRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      localLabelOverride: 'Artifacts',
      treeSlug,
    }),
    getLocationsRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
    }),
    getPeopleRoute({
      pageType: INSTANCE_TYPE_EVENT,
      linkedPageId,
      treeSlug,
    }),
  ].filter(x => x)

  return (
    <LinkedPage
      pageType={INSTANCE_TYPE_EVENT}
      linkedPageId={linkedPageId}
      routes={routes}
      backButton
    />
  )
}

export default EventsPage
