import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchMyContent,
  selectMyContent,
} from 'src/modules/myContent/myContentSlice'
import { selectUser } from 'src/modules/auth/authSlice'
import { BaseContentList } from 'src/modules/page'

const MyContentList = props => {
  const dispatchFetchContent = useActionDispatcher(fetchMyContent, {
    initialStatus: 'loading',
  })

  const content = useSelector(selectMyContent)
  const user = useSelector(selectUser)
  const { results, next } = content

  const { author = user.id, recipient, sort, state, type } = props

  useEffect(() => {
    if (user.id) {
      dispatchFetchContent({
        author,
        page: 0,
        recipient,
        sort,
        state,
        type,
      })
    }
  }, [dispatchFetchContent, author, recipient, sort, state, type, user.id])

  const handleFetchMore = () => {
    if (dispatchFetchContent.status === 'loading') {
      return
    }
    dispatchFetchContent({
      author,
      page: content.page + 1,
      recipient,
      sort,
      state,
      type,
    })
  }

  return (
    <BaseContentList
      {...{
        content,
        dispatchFetchContent,
        handleFetchMore,
        loading: dispatchFetchContent.status === 'loading',
        next,
        results,
        type,
        user,
      }}
    />
  )
}

export default MyContentList
