import React, { useState, useEffect } from 'react'
import { Dialog } from '@mui/material'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

import {
  selectAllUsersAndAliveIndividuals,
  fetchAllUsersAndAliveIndividuals,
  selectAvailableRoles,
  fetchAvailableRoles,
} from 'src/modules/accountAdmin/accountAdminSlice'
import { LoadingIndicator } from 'src/modules/ui'

import {
  updateInformationRequest,
  createInformationRequest,
} from './informationRequestSlice'
import SendInfoRequestDialog from './SendInfoRequestDialog'
import CreateInfoRequestDialog from './CreateInfoRequestDialog'
import { selectUser } from '../auth/authSlice'

const InformationRequestDialog = ({
  informationRequest,
  linkTarget,
  onClose,
  onInfoRequestSent = () => {},
  open,
  initialMedia,
  allowInviteNew = true,
}) => {
  // * INFO REQUEST
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const id = informationRequest ? informationRequest.id : null
  const title = informationRequest ? informationRequest.title : ''
  const links = informationRequest
    ? informationRequest.links
    : linkTarget
    ? [linkTarget]
    : []
  let photoContent
  if (informationRequest && informationRequest.contentBlocks?.length > 1) {
    photoContent = informationRequest?.contentBlocks.filter(
      c => c.type === 'MEDIA_ROW'
    )[0]?.media
  } else {
    photoContent = initialMedia || []
  }

  const dispatchInfoRequest = useActionDispatcher(
    !id ? createInformationRequest : updateInformationRequest
  )

  // * LOCAL STATE AND FUNCTIONALITY
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [sendModalOpen, setSendModalOpen] = useState(false)
  const anyModalOpen = createModalOpen || sendModalOpen

  useEffect(() => {
    setCreateModalOpen(open)
  }, [open])

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false)
    onClose()
  }
  const handleCloseSendModal = () => {
    setSendModalOpen(false)
    onClose()
  }
  const handleGoBack = () => {
    setCreateModalOpen(true)
    setSendModalOpen(false)
  }

  // * USERS/INDIVIDUALS
  const dispatchFetchAllUsersAndAliveIndividuals = useActionDispatcher(
    fetchAllUsersAndAliveIndividuals
  )
  const allUsersAndAliveIndividuals = useSelector(
    selectAllUsersAndAliveIndividuals
  )

  const user = useSelector(selectUser)

  const dispatchFetchAvailableRoles = useActionDispatcher(fetchAvailableRoles)

  const availableRoles = useSelector(selectAvailableRoles)

  useEffect(() => {
    if (treeSlug) {
      dispatchFetchAvailableRoles({ treeSlug })
    }
  }, [dispatchFetchAvailableRoles, treeSlug])

  const [filteredIndividuals, setFilteredIndividuals] = useState([])
  const [nonUsers, setNonUsers] = useState([])
  const [allowAndCanInvite, setAllowAndCanInvite] = useState(null) // derived from prop allowInviteNew and whether there is an availableRoles entry with manageable=true
  useEffect(() => {
    if (availableRoles.loading || allUsersAndAliveIndividuals.loading) {
      return
    }
    const invite =
      allowInviteNew &&
      availableRoles.results &&
      Object.values(availableRoles.results) &&
      Object.values(availableRoles.results).some(r => r.manageable)

    const toIds = informationRequest
      ? informationRequest.infoRequestTos.map(to => to.id)
      : []
    const individuals = allUsersAndAliveIndividuals.results
      .filter(
        //remove current user
        i => i.user?.id !== user.id
      )
      .filter(i => invite || i.user) // if invite is not true remove anyone with no user already set
      .map(user => ({
        ...user,
        asked: toIds.includes(user.id),
      }))

    setFilteredIndividuals(individuals)
    setNonUsers(individuals.filter(i => i.user === null).map(i => i.id))
    setAllowAndCanInvite(invite)
  }, [
    allUsersAndAliveIndividuals,
    allowInviteNew,
    availableRoles,
    user.id,
    informationRequest,
  ])

  useEffect(() => {
    if (treeSlug && treeSlug !== allUsersAndAliveIndividuals.treeSlug) {
      dispatchFetchAllUsersAndAliveIndividuals({ treeSlug })
    }
  }, [
    dispatchFetchAllUsersAndAliveIndividuals,
    treeSlug,
    allUsersAndAliveIndividuals.treeSlug,
  ])

  const initialInviteEmails = nonUsers.reduce((schema, field) => {
    return {
      ...schema,
      [field]: '',
    }
  }, {})

  // * SUBMISSION
  const handleSubmit = async (values, { setErrors }) => {
    if (createModalOpen) {
      // Passed validation so open send dialog
      setSendModalOpen(true)
      setCreateModalOpen(false)
    } else if (sendModalOpen) {
      // Submit to server
      try {
        const recipients = values.checkedUsers.map(id => ({
          id,
          email: values.inviteEmails[id] || null,
        }))
        const photoIds = values.media.map(p => p.id)
        const params = {
          id,
          media: photoIds,
          recipients: recipients || null,
          title: values.title,
          targets: values.links.map(l => l.target),
        }
        await dispatchInfoRequest(params, {
          successNotification: informationRequest
            ? 'Question updated'
            : 'Question sent',
        }).unwrap()
        onInfoRequestSent()
        setSendModalOpen(false)
        onClose()
      } catch (err) {
        setErrors({ inviteEmails: err.data.recipients })
      }
    }
  }

  // * VALUES/VALIDATION
  const initialValues = {
    checkedUsers: [],
    inviteEmails: initialInviteEmails,
    media: photoContent,
    id,
    links,
    title,
  }

  const createValidationSchema = Yup.object().shape({
    title: Yup.string().required('Question required'),
    media: Yup.array(),
  })

  const inviteEmailsSchema = nonUsers.reduce((schema, field) => {
    return {
      ...schema,
      [field]: Yup.string()
        .email('Not a valid email address')
        .test(
          'testusers',
          'Please provide email address',
          (_value, context) => {
            // this field can't be blank if user is checked
            const [values, parentValues] = context.from
            const checkedUsers = parentValues.value.checkedUsers
            const inviteEmails = values.value
            if (
              nonUsers.includes(field) &&
              checkedUsers.includes(field) &&
              !inviteEmails[field]
            ) {
              return false
            } else {
              return true
            }
          }
        ),
    }
  }, {})

  const checkedUsersNewSchema = Yup.array().test(
    'newRequestMustCheckOneUser',
    'Select at least one recipient',
    (_value, context) => {
      const { checkedUsers } = context.from[0].value
      const someAskedAlready = filteredIndividuals.some(u => u.asked)
      return someAskedAlready || checkedUsers.length > 0
    }
  )

  const sendValidationSchema = Yup.object().shape({
    checkedUsers: checkedUsersNewSchema,
    inviteEmails: Yup.object().shape(inviteEmailsSchema),
  })

  const validationSchema = createModalOpen
    ? createValidationSchema
    : sendModalOpen
    ? sendValidationSchema
    : null

  if (!anyModalOpen) {
    return null
  } else if (allUsersAndAliveIndividuals.loading) {
    return (
      <Dialog open={open} onClose={handleCloseCreateModal}>
        <LoadingIndicator />
      </Dialog>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, isSubmitting, errors, values }) => (
        <>
          <CreateInfoRequestDialog
            id={id}
            open={createModalOpen}
            onSubmit={handleSubmit}
            onClose={handleCloseCreateModal}
            media={values.media}
          />
          <SendInfoRequestDialog
            open={sendModalOpen}
            onSubmit={handleSubmit}
            onClose={handleCloseSendModal}
            isSubmitting={isSubmitting}
            loading={allUsersAndAliveIndividuals.loading}
            individuals={filteredIndividuals}
            onGoBack={handleGoBack}
            checkedUserError={errors.checkedUsers}
            allowInvite={allowAndCanInvite} // derived from prop allowInviteNew and whether there is an availableRoles entry with manageable=true
            availableRoles={availableRoles}
          />
        </>
      )}
    </Formik>
  )
}

export default InformationRequestDialog
