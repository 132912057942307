import { useSelector } from 'react-redux'
import { Box } from '@mui/system'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { Typography, Link } from 'src/modules/ui'
import { selectUser } from 'src/modules/auth/authSlice'
import { generateLinkForObject } from 'src/modules/app/links'

import {
  HUMAN_READABLE_TARGETS,
  TARGET_ADD_INDIVIDUAL_PHOTOS,
  TARGET_ADD_MEDIA,
  TARGET_ADD_OWN_PHOTO,
  TARGET_CREATE_ALBUMS,
  TARGET_INVITE_USERS,
  TARGET_PUBLISH_ARTICLES,
} from './targets'
import { normaliseMax100 } from './utils'
import { generateTreeLink, INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import Progress from './Progress'
import { Stack } from '@mui/material'

const GoalTitle = ({ link, targetType }) => {
  const currentTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const title = HUMAN_READABLE_TARGETS[targetType]
  const user = useSelector(selectUser)

  if (!link) {
    targetType = 'does not exist'
  }

  let url
  switch (targetType) {
    case TARGET_ADD_OWN_PHOTO:
    case TARGET_ADD_INDIVIDUAL_PHOTOS:
      url = generateLinkForObject(
        currentTreeSlug,
        INSTANCE_TYPE_INDIVIDUAL,
        user.individualOnTree?.id
      )
      break
    case TARGET_ADD_MEDIA:
    case TARGET_CREATE_ALBUMS:
      url = generateTreeLink(currentTreeSlug, 'home/media')
      break
    case TARGET_PUBLISH_ARTICLES:
      url = generateTreeLink(currentTreeSlug, 'my-articles')
      break
    case TARGET_INVITE_USERS:
      url = generateTreeLink(currentTreeSlug, 'user-admin')
      break

    default:
      url = undefined
  }

  return (
    <Typography sx={{ textAlign: 'center' }}>
      {!!url && <Link to={url}>{title}</Link>}
      {!url && title}
    </Typography>
  )
}

const Goal = ({ goal, link = true, title = true, width, indicatorSize }) => {
  const value = normaliseMax100(goal)
  return (
    <Box sx={{ width, p: 1 }}>
      <Box
        sx={{
          position: 'relative',
          width: indicatorSize,
          height: indicatorSize,
          margin: '0 auto',
        }}
      >
        <Progress value={value} indicatorSize={indicatorSize} />
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: indicatorSize,
            height: indicatorSize,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            {goal.progress.progress} / {goal.progress.targetDuringPeriod}
          </Typography>
        </Stack>
      </Box>
      {title && (
        <Box sx={{ mt: 1 }}>
          <GoalTitle targetType={goal.targetType} link={link} />
        </Box>
      )}
    </Box>
  )
}

export default Goal
