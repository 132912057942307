import React from 'react'
import LockIcon from '@mui/icons-material/Lock'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

import PhotoGallery, { PhotoWithTitleV2 } from 'src/modules/photo/PhotoGallery'
import { VISIBILITY_TREE } from '../../visibility/visibilityUtils'

const PublicPhotoGallery = ({ media = [], onClickPhoto }) => (
  <PhotoGallery
    media={media}
    onClickPhoto={onClickPhoto}
    GalleryPhoto={PublicOrPrivatePhoto}
  />
)

const PublicOrPrivatePhoto = props => {
  const { visibility } = props.photo

  return (
    <>
      {visibility !== VISIBILITY_TREE ? (
        <PhotoWithTitleV2 {...props} />
      ) : (
        <PrivatePhoto {...props} />
      )}
    </>
  )
}

export const PrivatePhoto = ({ photo }) => {
  const { height, width, marginRight } = photo
  return (
    <Box
      sx={{
        height,
        width: width || height,
        marginRight,
        flexDirection: 'column',
        backgroundImage: 'url(/image-default-3.svg)',
        backgroundSize: 'cover',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <LockIcon />
      <Typography variant="subtitle5">Private</Typography>
    </Box>
  )
}

export default PublicPhotoGallery
