import { dayCount } from './gedcomDateUtils'
import { titleCase } from 'title-case'

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

export const convertUIGedDate = str => {
  if (str) {
    const dateObj = dateParse(str)
    if (dateObj) {
      const gedStr = stringParse(dateObj).toLowerCase()
      return titleCase(gedStr)
    } else return str
  } else return ''
}

const handleFactYear = (dateArr, type) => {
  const year = dateArr[dateArr.length - 1]
  dateArr.pop()
  dateArr.unshift(year)
  return dateArr
}

// Dates on a fact to be dispayed as YYYY type, DD MMM e.g. 1999, 01 Oct or 1999 Abt, 01 Oct
export const convertGedDateToFactDate = str => {
  if (str) {
    const dateObj = dateParse(str)
    const dateStr = convertUIGedDate(str)
    let dateArr = dateStr.split(' ')
    const isBetweenDate = dateStr.includes('and')
    if (dateArr.length > 1 && !isBetweenDate) {
      dateArr = handleFactYear(dateArr, dateObj.type)
      if (dateObj.type !== 'exact' && dateArr.length > 2) {
        dateArr[1] = `${dateArr[1]},`
      }
    } else if (isBetweenDate) {
      let date0 = dateArr.splice(0, dateArr.indexOf('and'))
      let date1 = dateArr.splice(dateArr.indexOf('and'))
      date0 = handleFactYear(date0)
      date1.shift()
      date1 = handleFactYear(date1)

      if (date0.length > 2) {
        date0[1] = `${date0[1]},`
      }
      if (date1.length > 1) {
        date1[0] = `${date1[0]},`
      }

      dateArr = [...date0, 'and', ...date1]
    }
    return {
      type: dateObj.type,
      date: dateArr,
    }
  }
}

export const validateBetween = dateStr => {
  const splitStr = dateStr.split('AND')
  const startDateStr = splitStr[0].substring(4, splitStr[0].length)
  const endDateStr = splitStr[1]

  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)

  if (startDate >= endDate) {
    return false
  } else return true
}

const getDateType = type => {
  if (type === 'abt' || type === 'abo' || type === 'cal' || type === 'est') {
    return 'about'
  } else if (type === 'bef' || type === 'aft' || type === 'bet') {
    return 'range'
  } else {
    return 'exact'
  }
}

const validateDays = (day, month, year) => {
  const maxDay = dayCount(month, Number(year))
  if (maxDay.length < day) {
    return 0
  } else return Number(day)
}

const dateParse = gedcomStr => {
  //try and force phrase like date ranges
  const phraseSplit = gedcomStr.split('-')
  if (phraseSplit.length === 2) {
    gedcomStr = `bet ${phraseSplit[0].trim()} and ${phraseSplit[1].trim()}`
  }

  let dateObj = {}
  // Replace '/' between numbers
  let replaceStr = gedcomStr.replace(/[/]/g, '$')
  // Remove brackets from date string
  replaceStr = replaceStr.replace(/[\])}[{(]/g, '')
  // remove date ordinals
  replaceStr = replaceStr.replace(/(\d+)(st|nd|rd|th)/g, '$1')
  // Replace any other potential separators
  replaceStr = replaceStr
    .replace(/ /g || /('.'||'-'||'_')/g || /' '/g, '$')
    .toLowerCase()

  const splitStr = replaceStr.split('$')

  let typeAbreviation = splitStr[0].toLowerCase().substring(0, 3)
  const dateType = getDateType(typeAbreviation)

  if (!Number(splitStr[0]) && dateType !== 'exact') {
    splitStr.shift()
  }

  if (typeAbreviation === 'bet') {
    dateObj.type = dateType
    const sliceIdx = splitStr.indexOf('and')
    const startDate = splitStr.slice(0, sliceIdx)
    const endDate = splitStr.slice(sliceIdx + 1, splitStr.length)

    const startYear = startDate[startDate.length - 1]
    const startMonth = startDate[startDate.length - 2]
    const startDay = startDate[startDate.length - 3]
    const endYear = endDate[endDate.length - 1]
    const endMonth = endDate[endDate.length - 2]
    const endDay = endDate[endDate.length - 3]

    const startMonthInt =
      months.findIndex(el => el === startMonth?.toLowerCase().substring(0, 3)) +
      1
    const endMonthInt =
      months.findIndex(el => el === endMonth?.toLowerCase().substring(0, 3)) + 1
    dateObj.startDate = {
      year: Number(startYear),
      month: startMonthInt !== 0 ? startMonthInt : Number(startMonth),
      day: validateDays(startDay, startMonthInt, startYear),
    }
    dateObj.endDate = {
      year: Number(endYear),
      month: endMonthInt !== 0 ? endMonthInt : Number(endMonth),
      day: validateDays(endDay, endMonthInt, endYear),
    }
  } else if (splitStr.length <= 3 && splitStr.length > 0) {
    const year = splitStr[splitStr.length - 1]
    const month = splitStr[splitStr.length - 2]
    const day = splitStr[splitStr.length - 3]
    const monthInt =
      months.findIndex(el => el === month?.toLowerCase().substring(0, 3)) + 1
    if (dateType !== 'range') {
      dateObj.year = Number(year)
      dateObj.month = monthInt !== 0 ? monthInt : Number(month)
      dateObj.day = validateDays(day, monthInt, year)
      dateObj.type = dateType
    } else if (typeAbreviation === 'aft') {
      dateObj.type = dateType
      dateObj.startDate = {
        year: Number(year),
        month: monthInt !== 0 ? monthInt : Number(month),
        day: validateDays(day, monthInt, year),
      }
    } else if (typeAbreviation === 'bef') {
      dateObj.type = dateType
      dateObj.endDate = {
        year: Number(year),
        month: monthInt !== 0 ? monthInt : Number(month),
        day: validateDays(day, monthInt, year),
      }
    }
  }

  if (dateObj.type) {
    if (dateObj.type === 'exact' && !dateObj.year) {
      return null
    } else return dateObj
  } else return null
}

const createDateStr = gedcomObj => {
  let dateString = ''
  const day = gedcomObj.day
  const month = months[gedcomObj.month - 1]
  const year = gedcomObj.year

  if (day) {
    dateString += `${day} `
  }
  if (month) {
    dateString += `${month.toUpperCase()} `
  }
  if (year) {
    dateString += year
  }

  return dateString
}

const stringParse = gedcomObj => {
  let dateString = ''
  const type = gedcomObj.type

  if (type) {
    if (type !== 'range') {
      // exact or approximate date
      if (type === 'about') {
        dateString = 'ABT '
      }

      const formDateStr = createDateStr(gedcomObj)
      if (formDateStr) {
        dateString += formDateStr
      } else {
        dateString = ''
      }
    } else {
      // date range
      const startDate = gedcomObj.startDate
      const endDate = gedcomObj.endDate
      if (startDate) {
        // const formDateStr = createDateStr(startDate)

        dateString = createDateStr(startDate)

        if (endDate) {
          const endDateStr = createDateStr(endDate)
          // between...and...
          if (dateString && endDateStr) {
            dateString = `BET ${dateString} AND `
            dateString += endDateStr
          } else if (dateString && !endDateStr) {
            dateString = `AFT ${dateString}`
          } else if (!dateString && endDateStr) {
            dateString = `BEF ${endDateStr}`
          } else {
            dateString = ''
          }
        } else {
          // after...
          if (dateString) {
            dateString = `AFT ${dateString}`
          } else {
            dateString = ''
          }
        }
      } else {
        if (endDate) {
          dateString = createDateStr(endDate)

          // before...
          if (dateString) {
            dateString = `BEF ${dateString}`
          }
        } else {
          dateString = ''
        }
      }
    }
  }

  return dateString
}

export const gedcomStrToDateObj = gedcomStr => {
  return dateParse(gedcomStr)
}

export const gedcomObjToDateStr = gedcomObj => {
  return stringParse(gedcomObj)
}
