import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLinkedFacts,
  resetPageToInitialState,
  selectLinkedFacts,
  selectLinkedPageArticle,
  setLinkedPageArticle,
} from './pageSlice'
import PinnedArticle, {
  PINNED_ARTICLE_DISPLAY_CONFIG,
  PinnedArticleButtons,
} from './PinnedArticle'

import { Box } from '@mui/material'
import { fetchIndividual, selectIndividualById } from '../viewer/viewerSlice'
import { isUndefined } from 'lodash'
import { formatIndividualWithYears } from '../ui/individualUtils'
import { useActionDispatcher } from '../app'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'

const LINKED_PINNED_ARTICLE_CONFIG = {
  ...PINNED_ARTICLE_DISPLAY_CONFIG,
  showInLineHeaderAuthor: true,
  showInLineHeaderShare: false,
  showInLineHeaderEdit: true,
  showInLineHeaderAddToBlog: true,
  showInLineHeaderUnPin: true,
}

const IndividualPinnedArticle = ({ pageType }) => {
  const { linkedPageId } = useParams()
  const dispatch = useDispatch()
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const linkedFacts = useSelector(selectLinkedFacts)
  const cachedPinnedArticleData = useSelector(selectLinkedPageArticle)

  // For creation of entities linked to individuals, preselect the
  // family as well:
  const targets = [linkedPageId]
  const individual = useSelector(selectIndividualById(linkedPageId))
  if (!isUndefined(individual)) {
    targets.push(individual.family)
  }

  const handleFacts = () => {
    dispatchFetchLinkedFacts({ target: linkedPageId })
  }

  useEffect(() => {
    if (
      !cachedPinnedArticleData.articleId &&
      cachedPinnedArticleData?.loading
    ) {
      dispatch(fetchIndividual({ individualId: linkedPageId })).then(
        response => {
          dispatch(setLinkedPageArticle(response.payload.pinnedArticle))
        }
      )
    }
  }, [
    dispatch,
    cachedPinnedArticleData.articleId,
    cachedPinnedArticleData?.loading,
    linkedPageId,
  ])

  //check to make sure the cached pinned article is for the same individual
  const cachedPinnedArticle =
    individual?.pinnedArticle?.id === cachedPinnedArticleData?.articleId
      ? cachedPinnedArticleData
      : {}

  return (
    <Box>
      <PinnedArticle
        cachedPinnedArticleData={cachedPinnedArticle}
        onArticleLoaded={setLinkedPageArticle}
        config={LINKED_PINNED_ARTICLE_CONFIG}
        pinTarget={linkedPageId}
      >
        <>
          <PinnedArticleButtons
            articleTitle={formatIndividualWithYears(individual || {})}
            createButtonText={'Create a Profile Article'}
            pinButtonText={'Pin an Existing Article'}
            pinTarget={individual}
            pinTargetType={INSTANCE_TYPE_INDIVIDUAL}
            onArticleChanged={resetPageToInitialState}
            presetTargets={targets}
            onArticleLoaded={setLinkedPageArticle}
            onOpenAIDialog={handleFacts}
            promptData={linkedFacts}
          />
        </>
      </PinnedArticle>
    </Box>
  )
}

export default IndividualPinnedArticle
