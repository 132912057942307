import React from 'react'
import { makeStyles } from '@mui/styles'

import { PageTitle } from 'src/modules/ui'

import ArticleHeaderMeta from './ArticleHeaderMeta'
import LinkChips from './LinkChips'
import { INSTANCE_TYPE_ARTICLE } from '../app/links'

const useStyles = makeStyles(theme => ({
  author: {
    marginBottom: theme.spacing(0),
  },
  date: {
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
}))

export const ArticleHeader = (
  { article, published = true, subtitle, subtitleContainerMb },
  config
) => {
  const { id, comments, title, links } = article
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <PageTitle
        targetId={id}
        type={INSTANCE_TYPE_ARTICLE}
        backButton={published}
        title={title}
        subtitle={subtitle}
        subtitleContainerMb={subtitleContainerMb}
      />
      <ArticleHeaderMeta
        {...article}
        config={config}
        commentCount={comments.length}
      />
      <LinkChips links={links} sx={{ my: 2 }} />
    </div>
  )
}

export default ArticleHeader
