import React, { useCallback, useEffect, useState } from 'react'
import { Stack, useMediaQuery } from '@mui/material'
import { debounce } from 'lodash'
import { MEDIA_ROW_COVER_GAP_BETWEEN_IMAGES } from '../writeArticle/contentBlockConstants'

/**
 * A media row set with 'cover' will be full width (minus padding) but the height needs to be calculated depending on the
 * aspect ratio of all images in the row.
 */
export const useCalculateCoverHeight = (coverElement, photos) => {
  const [coverHeight, setCoverHeight] = useState(null)

  const calculateCoverHeight = useCallback(() => {
    if (!coverElement || !photos) {
      return
    }

    const totalAr = photos?.reduce(
      (accAr, { mediumImageWidth, mediumImageHeight, width, height }) => {
        const ar = (mediumImageWidth || width) / (mediumImageHeight || height)
        return accAr + ar
      },
      0
    )
    const coverWidth = coverElement.offsetWidth
    const availableWidth = coverWidth - 32 * (photos?.length - 1)
    const _coverHeight = availableWidth / totalAr

    setCoverHeight(_coverHeight)
  }, [coverElement, photos])

  const debouncedCalculateCoverHeight = useCallback(
    e => debounce(calculateCoverHeight, 50)(e),
    [calculateCoverHeight]
  )

  useEffect(() => {
    calculateCoverHeight()
  }, [calculateCoverHeight, coverElement, photos])

  useEffect(() => {
    window.addEventListener('resize', debouncedCalculateCoverHeight)
    return () =>
      window.removeEventListener('resize', debouncedCalculateCoverHeight)
  }, [debouncedCalculateCoverHeight])

  return coverHeight
}

const CoverMediaRow = React.forwardRef(
  ({ isPrinting = false, children }, ref) => {
    let mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))

    let direction = 'row'
    if (!isPrinting && mobileBreakpoint) {
      direction = 'column'
    }

    return (
      <Stack
        direction={direction}
        ref={ref}
        style={{
          gap: MEDIA_ROW_COVER_GAP_BETWEEN_IMAGES,
        }}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
      </Stack>
    )
  }
)

export default CoverMediaRow
