import { useSelector } from 'react-redux'

import { createAtomicFamilyGraph } from '../api/explore'
import { selectNodeDirectory } from '../viewerSlice'
import TreeViewer from './TreeViewer'
import { FOCUS_MODE_SCROLL } from './constants'
import { makeXCoordinates0based } from '../api/graphOps'

const SelectMyselfViewer = ({ selectedIndividual }) => {
  const nodeDirectory = useSelector(selectNodeDirectory)
  const [nodes, links, nLayers] = selectedIndividual
    ? createAtomicFamilyGraph(selectedIndividual, nodeDirectory)
    : []
  makeXCoordinates0based(nodes)

  return (
    <TreeViewer
      allowDragAndZoom={false}
      exploreNodeOnClick={false}
      focusMode={FOCUS_MODE_SCROLL}
      focusModeTarget={selectedIndividual}
      links={links}
      nLayers={nLayers}
      nodes={nodes}
      widthMultiplier={1.25}
    />
  )
}

export default SelectMyselfViewer
