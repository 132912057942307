import React, { useContext, useMemo } from 'react'

import {
  getTreeRoute,
  getHistoryRoute,
  getDocumentsRoute,
  getPhotosRoute,
  getEventsRoute,
  getArtefactsRoute,
  getLocationsRoute,
  getFactsRoute,
  getWelcomeRoute,
  getFansRoute,
} from 'src/modules/page/Page'
import PublicPage, { routeComponentMap } from './PublicPage'
import { PublicContext } from '../contexts'
import { usePublicPageUrlSegments } from './hooks'
import { generatePublicTreeRoot } from '../../app/links'
import { useIsAlphaFeatureUser } from '../../auth/hooks'

const BasePublicPageWithTree = ({
  photo,
  subtitle,
  title,
  treeTitle,
  pinnedArticle,
  pinnedArticleLabelOveride,
  families,
}) => {
  const { pageType, linkedPageId } = usePublicPageUrlSegments()
  let { treeSlug } = useContext(PublicContext)
  treeSlug = generatePublicTreeRoot(treeSlug).substring(1)
  const showFeature = useIsAlphaFeatureUser()?.documents

  const routes = useMemo(() => {
    const defaultRoutes = [
      getWelcomeRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        localLabelOverride: pinnedArticleLabelOveride,
        disabled: true,
      }),
      getTreeRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        makeDefault: true,
        treeSlug,
      }),
      getFactsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      getHistoryRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      showFeature &&
        getDocumentsRoute({
          routeComponentMap,
          pageType,
          linkedPageId,
          treeSlug,
          disabled: true,
        }),
      getPhotosRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      getEventsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      getArtefactsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      getLocationsRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        disabled: true,
      }),
      getFansRoute({
        routeComponentMap,
        pageType,
        linkedPageId,
        treeSlug,
        localLabelOverride: 'FANs',
        disabled: true,
      }),
    ].filter(x => x)

    let decoratedRoutes = defaultRoutes
    if (pinnedArticle) {
      decoratedRoutes[0].disabled = false
      decoratedRoutes[0].componentParams = {
        id: pinnedArticle?.id,
      }
    }
    return decoratedRoutes
  }, [
    pageType,
    linkedPageId,
    treeSlug,
    pinnedArticle,
    pinnedArticleLabelOveride,
    showFeature,
  ])

  return (
    <PublicPage
      linkedPageId={linkedPageId}
      photoUrl={photo}
      subtitle={subtitle}
      title={title}
      routes={routes}
      pageType={pageType}
      links={families}
    />
  )
}

export default BasePublicPageWithTree
