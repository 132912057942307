import React, { useCallback, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { Feed } from 'src/modules/page/Feed'
import { useLinkedPageTargets } from 'src/modules/page/hooks'
import PublicContentCard from '../content/PublicContentCard'
import { PublicContext } from '../contexts'
import { fetchPublicPageFeed, selectPublicFeed } from './pageSlice'

const PublicFeed = () => {
  const { treeSlug } = useContext(PublicContext)
  const { target } = useLinkedPageTargets()
  const dispatchFetchPageFeed = useActionDispatcher(fetchPublicPageFeed)
  const feed = useSelector(selectPublicFeed)

  const dispatchFetchFeed = useCallback(
    ({ before } = {}) => {
      if (before) {
        dispatchFetchPageFeed({ target, before, treeSlug })
      } else {
        dispatchFetchPageFeed({ target, treeSlug })
      }
    },
    [dispatchFetchPageFeed, target, treeSlug]
  )

  useEffect(() => {
    dispatchFetchFeed()
  }, [dispatchFetchFeed])

  const handleFetchMore = () => {
    if (dispatchFetchPageFeed.status === 'loading') {
      return
    }
    const lastItem = feed.results[feed.results.length - 1]
    if (lastItem) {
      const before = lastItem.publishedAt
      dispatchFetchFeed({ before })
    }
  }
  return (
    <Feed
      feedActionDispatcher={dispatchFetchPageFeed}
      {...{ feed, handleFetchMore, Card: PublicContentCard }}
    />
  )
}

export default PublicFeed
