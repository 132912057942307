import { LoadingIndicator } from '../ui'
import React from 'react'
import { Box } from '@mui/material'
/*
 * checks if the tree is loaded before displaying children
 * */
const LoadingGuard = ({ children, title, mt, isLoadingOveride = false }) => {
  return (
    <>
      {isLoadingOveride ? (
        <Box mt={mt || 0}>
          <LoadingIndicator title={title || 'Loading Tree'} />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

export default LoadingGuard
