import { useCallback, useState } from 'react'
import { useField } from 'formik'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material'
import { matchSorter } from 'match-sorter'
import { TIMEZONES } from './timezones'

export const FormikTimezoneAutocomplete = ({
  name,
  value,
  label = '',
  onChange,
  hideValidation,
}) => {
  let [{ ...field }, { touched, error }] = useField({
    type: 'text',
    name: name,
    value: value,
  })
  const [timezoneInput, setTimezoneInput] = useState('')
  const fuzzyFilterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue)
  }

  const hasError = Boolean(touched && error)

  const handleTimezoneInputChange = useCallback((event, newValue) => {
    setTimezoneInput(newValue)
  }, [])

  return (
    <FormControl sx={{ width: '100%' }} error={hasError}>
      <Autocomplete
        sx={{ width: '100%' }}
        onChange={onChange}
        name={name}
        value={field.value}
        getOptionLabel={label => label}
        inputValue={timezoneInput}
        onInputChange={handleTimezoneInputChange}
        options={TIMEZONES}
        filterOptions={fuzzyFilterOptions}
        renderInput={params => (
          <TextField {...params} label="Search timezones..." fullWidth={true} />
        )}
      />
      {hasError && !hideValidation && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default FormikTimezoneAutocomplete
