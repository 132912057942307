import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import { INSTANCE_TYPE_ARTICLE } from 'src/modules/app/links'
import { selectUser } from 'src/modules/auth/authSlice'
import { Typography } from 'src/modules/ui'
import { BaseContentList } from 'src/modules/page'
import { useParams } from 'react-router-dom'
import {
  fetchUser,
  selectCurrentTree,
  selectTreeBySlug,
} from '../auth/authSlice'
import { pinArticle } from '../content/contentSlice'
import { ConfirmDialog } from '../ui'
import PushPinIcon from '@mui/icons-material/PushPin'
import {
  fetchHomeBlogPost,
  resetHomePageState,
  selectHomeBlogPost,
} from './homeSlice'
import { BLOG_TREE } from '../common/constants'
import { HasAccessTooltip } from '../ui/Button'
import FabButton from '../ui/FabButton'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const PinContentButton = ({ contentId, pinTargetId = '' }) => {
  const dispatchPinArticle = useActionDispatcher(pinArticle)
  const tree = useSelector(selectCurrentTree)
  const dispatch = useDispatch()
  const dispatchFetchUser = useActionDispatcher(fetchUser)

  const handlePinArticle = async () => {
    if (tree?.pinnedArticle?.id === contentId) {
      await dispatchPinArticle({
        pinTargetId: pinTargetId || tree?.id,
      })
    } else {
      await dispatchPinArticle({
        pinTargetId: pinTargetId || tree?.id,
        articleId: contentId,
      })
    }
    await dispatchFetchUser()
    dispatch(resetHomePageState())
  }

  const dialogText =
    tree?.pinnedArticle?.id === contentId
      ? 'Are you sure you want to unpin this article?'
      : 'Are you sure you want to make this your pinned article?'

  return (
    <>
      <ConfirmDialog
        submitText={tree?.pinnedArticle?.id === contentId ? 'Unpin' : 'Pin'}
        onConfirm={handlePinArticle}
        trigger={props => (
          <HasAccessTooltip customTitle="Edit" featureAvailable={true}>
            <FabButton
              permissionAction={ACTION_ALL_ACCESS}
              text={tree?.pinnedArticle?.id === contentId ? 'Unpin' : 'Pin'}
              icon={<PushPinIcon fontSize="small" white sx={{ mb: 0.3 }} />}
              {...props}
            />
          </HasAccessTooltip>
        )}
      >
        <Typography>{dialogText}</Typography>
      </ConfirmDialog>
    </>
  )
}

const HomeBlogPostList = ({ fetchPosts, postsSelector, state, type }) => {
  const params = useParams()
  const treeSlug = params?.slug
  const dispatchBlogPosts = useActionDispatcher(fetchPosts)
  const dispatchFetchArticle = useActionDispatcher(fetchHomeBlogPost)
  const content = useSelector(postsSelector)
  const pinnedPost = useSelector(selectHomeBlogPost)
  const user = useSelector(selectUser)
  const tree = useSelector(selectTreeBySlug(treeSlug))
  const dispatch = useDispatch()
  const [contentCache, setContentCache] = useState([])
  const { next, results } = content
  const [loading, setLoading] = useState()
  const hasPinnedArticle = Boolean(state === 'PUBLISHED' && tree?.pinnedArticle)

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true)
      await dispatchBlogPosts({
        page: 0,
        state,
        type,
        block_limit: 1,
        block_type: 'TEXT',
        sort: state === 'DRAFT' ? '-modified' : content.sort,
        treeSlug: treeSlug,
      })
      if (hasPinnedArticle && tree?.treeType === BLOG_TREE) {
        await dispatchFetchArticle({
          id: tree?.pinnedArticle?.id,
          treeSlug: treeSlug,
        })
      }
      setLoading(false)
    }
    if (!results.length && !pinnedPost) {
      setLoading(true)
      dispatch(resetHomePageState())
      fetchContent()
    }
  }, [
    dispatchBlogPosts,
    treeSlug,
    state,
    type,
    content.sort,
    results.length,
    tree?.pinnedArticle,
    dispatchFetchArticle,
    dispatch,
    pinnedPost?.id,
    tree?.treeType,
    hasPinnedArticle,
    pinnedPost,
  ])

  useEffect(() => {
    setContentCache(results)
  }, [results])

  const handleFetchMore = () => {
    if (dispatchBlogPosts.status === 'loading') {
      return
    }
    dispatchBlogPosts({
      page: content.page + 1,
      state,
      type,
      block_limit: 1,
      block_type: 'TEXT',
      sort: content.sort,
    })
  }
  return (
    <>
      <BaseContentList
        actions={<Actions sort={content.sort} type={type} />}
        {...{
          content,
          contentCache,
          dispatchFetchContent: dispatchBlogPosts,
          handleFetchMore,
          loading,
          next,
          results: contentCache,
          type,
          user,
          pinnedContent: pinnedPost,
          state,
          treeType: tree?.treeType,
          PinPostDialog: PinContentButton,
          hasPinnedArticle: hasPinnedArticle,
        }}
      />
    </>
  )
}

export const Actions = ({ type }) => {
  return (
    <Box sx={{ mb: 2 }}>
      {type === INSTANCE_TYPE_ARTICLE && (
        <Stack direction="row" alignItems="center">
          <Typography color="textPrimary">Recent</Typography>
        </Stack>
      )}
    </Box>
  )
}

export default HomeBlogPostList
