import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PublicTreeExplorer, { PublicTreeView } from '../tree/PublicTreeExplorer'
import { usePublicPageUrlSegments } from './hooks'
import {
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_FAMILY,
} from 'src/modules/app/links'
import {
  ExploredIndividualViewer,
  FOCUS_MODE_SCROLL,
} from 'src/modules/viewer/treeViewers'

import {
  setPublicExploredIndividualId,
  choosePublicFamilyId,
} from 'src/modules/viewer/exploreTreeSlice'
import { selectExploredIndividualId } from 'src/modules/viewer/exploreTreeSlice'
import {
  fetchPublicIndividualsForLineage,
  fetchPublicIndividualsForTarget,
  selectPublicFamilyById,
  selectPublicIndividualById,
  selectPublicNodeDirectory,
  selectPublicPartialCall,
  selectSharedByIndividual,
} from '../tree/treeSlice'
import { LoadingIndicator } from 'src/modules/ui'
import { Stack, useTheme } from '@mui/material'
import { clearExploredNode } from '../../viewer/exploreTreeSlice'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FabButton from '../../ui/FabButton'
import { useEmbed } from '../hooks'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const PublicTree = () => {
  const theme = useTheme()
  const { slug, pageType, linkedPageId } = usePublicPageUrlSegments()
  const sharedByIndividual = useSelector(selectSharedByIndividual)
  const embed = useEmbed()
  const dispatch = useDispatch()
  const individualNodeId = useSelector(selectExploredIndividualId)
  const individualNode = useSelector(
    selectPublicIndividualById(individualNodeId)
  )

  const partialTreeKey =
    pageType === INSTANCE_TYPE_FAMILY
      ? sharedByIndividual?.id + '-' + linkedPageId
      : linkedPageId

  const partialTreeLoaded = useSelector(selectPublicPartialCall(partialTreeKey))

  const publicNodeDirectory = useSelector(selectPublicNodeDirectory)

  const currentPageFamily = useSelector(selectPublicFamilyById(linkedPageId))
  const currentPageIndividual = useSelector(
    selectPublicIndividualById(linkedPageId)
  )

  useEffect(() => {
    if (slug && pageType === INSTANCE_TYPE_INDIVIDUAL) {
      dispatch(
        fetchPublicIndividualsForTarget({
          treeSlug: slug,
          target: linkedPageId,
        })
      )
    }

    if (slug && sharedByIndividual && pageType === INSTANCE_TYPE_FAMILY) {
      dispatch(
        fetchPublicIndividualsForLineage({
          treeSlug: slug,
          target: sharedByIndividual.id,
          familyId: linkedPageId,
        })
      )
    }
  }, [slug, dispatch, pageType, linkedPageId, sharedByIndividual])

  useEffect(() => {
    if (pageType === INSTANCE_TYPE_INDIVIDUAL) {
      dispatch(setPublicExploredIndividualId(linkedPageId))
    } else {
      dispatch(clearExploredNode())
      dispatch(choosePublicFamilyId(linkedPageId))
    }
  }, [dispatch, pageType, linkedPageId])

  const fullScreen = (
    <PublicTreeExplorer
      reset={currentPageFamily || currentPageIndividual}
      trigger={navigatorTrigger => (
        <Stack
          direction="row"
          justifyContent="right"
          width={theme.maxWidth}
          mx="auto"
          mt={2}
          pr={2}
          gap={1}
        >
          <FabButton
            permissionAction={ACTION_ALL_ACCESS}
            text="Full Screen"
            icon={<FullscreenIcon fontSize="medium" sx={{ mb: 0.2 }} />}
            onClick={navigatorTrigger.onClick}
            style={{ pointerEvents: 'visible' }}
          />
        </Stack>
      )}
    />
  )

  if (!partialTreeLoaded) {
    return <LoadingIndicator title={'Loading Tree'} />
  } else if (pageType === INSTANCE_TYPE_INDIVIDUAL && individualNode) {
    return (
      <>
        {!embed && fullScreen}
        <ExploredIndividualViewer
          exploredIndividualNode={individualNode}
          doAddGreatGrandParents={false}
          nodeDirectory={publicNodeDirectory}
          isPublic={true}
        />
      </>
    )
  } else if (pageType === INSTANCE_TYPE_FAMILY) {
    return (
      <>
        {!embed && fullScreen}
        <PublicTreeView
          allowDragAndZoom={false}
          focusMode={FOCUS_MODE_SCROLL}
          familyId={linkedPageId}
          individualId={sharedByIndividual}
          navigateToNodeOnClick={true}
          source={'public tree'}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default PublicTree
