import { createSlice } from '@reduxjs/toolkit'
import { getTreeSlugFromStore } from '../auth/utils'
import api, { createWrappedAsyncThunk } from 'src/api'

const SLICE_NAME = 'map'

const initialState = {}

export const fetchMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchMap`,
  params => {
    return api.get(`/history/${getTreeSlugFromStore()}/mapblock/${params.id}/`)
  }
)

export const createMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/createMap`,
  map => {
    const slug = getTreeSlugFromStore()

    return api.post(`/history/${slug}/mapblock/`, {
      body: map,
    })
  }
)

// called by MapSidePanel.saveMap()
export const updateMap = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateMap`,
  ({ id, map }) => {
    const slug = getTreeSlugFromStore()

    return api.patch(`/history/${slug}/mapblock/${id}/`, {
      body: map,
    })
  }
)

export const prepareMapBlocks = article => {
  article.contentBlocks?.forEach(cb => {
    if (cb.type === 'MAP') {
      prepareMapBlock(cb.mapBlock)
    }
  })
}

export const prepareMapBlock = mapBlock => {
  if (!mapBlock) {
    return
  }
  mapBlock.mapLinks?.forEach((mapLink, index) => {
    //parse JSON in each Feature to an object and create an empty properties object
    if (mapLink.target?.feature?.geometry) {
      mapLink.target.feature.geometry = JSON.parse(
        mapLink.target.feature.geometry
      )
      mapLink.target.feature.type = 'Feature'
      mapLink.target.feature.properties = {}
      mapLink.target.feature.id = mapLink.id
    }

    // legacy workaround... sometimes the old Address (v1) serializer is used which incorrectly
    // returns fields named latiGed and longGed when they do not contain gedcom format values.
    // Work around this and rename latiGed/longGed to lati/long.
    if (mapLink.instanceType === 'location' && mapLink.target.address) {
      // Place / location serializer uses the old AddressSerializer v1 which returns latiGed/longGed
      if (!mapLink.target.address.lati && mapLink.target.address.latiGed) {
        mapLink.target.address.lati = mapLink.target.address.latiGed
        delete mapLink.target.address.latiGed
      }
      if (!mapLink.target.address.long && mapLink.target.address.longGed) {
        mapLink.target.address.long = mapLink.target.address.longGed
        delete mapLink.target.address.longGed
      }
    }
  })

  // console.debug(
  //   `mapSlice.prepareMapBlock(): mapping mapBlock.mapLayerImageLinks:`,
  //   mapBlock.mapLayerImageLinks
  // )

  if (mapBlock.mapLayerImageLinks) {
    mapBlock.mapLayerImageLinks.forEach(mapLayerImageLink => {
      if (mapLayerImageLink.target?.mapImageCornersGeoPosition) {
        const geojsonobj = JSON.parse(
          mapLayerImageLink.target.mapImageCornersGeoPosition
        )

        // console.debug(`mapSlice.prepareMapBlock(): geojsonobj:`, geojsonobj)
        const res = { ...mapLayerImageLink }
        res.target.coordinates = geojsonobj.coordinates[0].slice(0, 4)
        delete res.target.mapImageCornersGeoPosition
        return res
      }
    })
  }

  // console.debug(
  //   `mapSlice.prepareMapBlock(): inflated geojson in mapBlock.mapLayerImageLinks:`,
  //   mapBlock.mapLayerImageLinks
  // )
}

export const mapSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMap.pending]: state => {
      state.currentMap = {}
    },
    [fetchMap.fulfilled]: (state, { meta, payload }) => {
      // parse any nested geojson geometry into objects
      prepareMapBlock(payload)
    },
  },
})

export default mapSlice.reducer
