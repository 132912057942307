import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import awsConfig from './awsConfig'

Amplify.configure(awsConfig)

// see https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced
export const AuthState = {
  SignIn: 'signIn', // AmplifyAuthState.SignIn,
  SignedIn: 'authenticated',
  SignedOut: 'idle',
  ResetPassword: 'resetPassword',
  VerifyContact: 'verifyUser',
  ForgotPassword: 'forgotPassword',
}

const Authprovider = ({ children }) => {
  return <Authenticator.Provider>{children}</Authenticator.Provider>
}

export default Authprovider
