import { useSelector } from 'react-redux'
import { selectUser } from './authSlice'
import { BLOG_TREE } from '../common/constants'
import { getExportTokenIfPresent } from '../auth/awsConfig'

export const useIsAlphaFeatureUser = () => {
  // left these in for future use in features
  const user = useSelector(selectUser)
  const weareUser = user?.email.indexOf('@weare.xyz') !== -1
  const alphaUser = user?.isAlphaFeatureUser
  const devEnvironment = process.env.REACT_APP_STAGE === 'dev'
  //const local = process.env.NODE_ENV === 'development'

  const features = {
    assistant: devEnvironment,
    documents: true,
    chatGPT: true,
    export: true,
    updateGedcom: weareUser,
    blog: true,
    maps: true,
    exportMode: !!getExportTokenIfPresent(),
    filterDropDown: true,
    aiWizard: alphaUser || weareUser,
    onePlaceStudy: true,
    tocBlock: true,
  }

  return features
}

const getSecondsUntilDate = date => {
  const currentDate = new Date()
  const futureDate = new Date(date)
  const timeDifference = futureDate.getTime() - currentDate.getTime()
  const secondsDifference = timeDifference / 1000
  return secondsDifference
}

export const useTimeBeforeUserReload = () => {
  const user = useSelector(selectUser)

  if (user) {
    const userAbilities = user?.abilities
    const userExpiryDate = new Date(userAbilities?.expiryDate)

    let earliestExpiryDate = userExpiryDate
    user?.trees?.forEach(treeObj => {
      const treeExpiry = new Date(treeObj?.abilities?.expiryDate)

      if (treeExpiry < earliestExpiryDate) {
        earliestExpiryDate = treeExpiry
      }
    })
    const seconds = getSecondsUntilDate(earliestExpiryDate)

    return seconds
  }
}

export const useAvailableFeatures = () => {
  const user = useSelector(selectUser)
  const userAbilities = user?.abilities

  const tree = user?.currentTree
  const treeAbilities = tree?.abilities

  let features = {
    user: {
      createTree: false,
      editTree: false,
    },
    tree: {
      createTree: false,
      editTree: false,
    },
  }

  const userExpiryDate = userAbilities?.expiryDate
    ? new Date(userAbilities?.expiryDate)
    : null
  const treeExpiryDate = treeAbilities?.expiryDate
    ? new Date(treeAbilities?.expiryDate)
    : null

  if (tree?.treeType === BLOG_TREE) {
    features = {
      user: {
        createTree: true,
        editTree: true,
      },
      tree: {
        createTree: true,
        editTree: true,
      },
    }
  } else {
    if (!userExpiryDate || userExpiryDate > new Date()) {
      features.user = {
        createTree: userAbilities?.features?.includes('CREATE_TREE'),
        editTree: userAbilities?.features?.includes('EDIT_TREE'),
      }
    }
    if (!treeExpiryDate || treeExpiryDate > new Date()) {
      features.tree = {
        createTree: treeAbilities?.features?.includes('CREATE_TREE'),
        editTree: treeAbilities?.features?.includes('EDIT_TREE'),
      }
    }
  }

  return features
}
