import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Feed from 'src/modules/page/Feed'
import { useActionDispatcher } from 'src/modules/app'
import { selectUser } from 'src/modules/auth/authSlice'
import { fetchHomeFeed, selectHomeFeed } from 'src/modules/home/homeSlice'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { generateTreeLink } from '../app/links'

const NewsFeed = () => {
  const dispatchFetchHomeFeed = useActionDispatcher(fetchHomeFeed)
  const homeFeed = useSelector(selectHomeFeed)
  const user = useSelector(selectUser)
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const { homeIndividual } = user

  const dispatchFetchFeed = useCallback(
    ({ before } = {}) => {
      if (before) {
        dispatchFetchHomeFeed({ before })
      } else {
        dispatchFetchHomeFeed()
      }
    },
    [dispatchFetchHomeFeed]
  )

  useEffect(() => {
    if (
      history.action !== 'REPLACE' &&
      homeFeed.results.length === 0 &&
      !homeFeed.hasMore &&
      !homeFeed.before
    ) {
      history.push(generateTreeLink(treeSlug, `home/tree`))
    }
  }, [homeFeed, history, treeSlug])

  return (
    <Feed
      dispatchFetchFeed={dispatchFetchFeed}
      feed={homeFeed}
      feedActionDispatcher={dispatchFetchHomeFeed}
      user={user}
      showUploadNotAddMedia
      presetTargets={[homeIndividual.id, homeIndividual.family]}
    />
  )
}

export default NewsFeed
