import React, { useEffect } from 'react'
import Auth from '@aws-amplify/auth'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Link } from '@mui/material'
import { Formik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { Container, Typography, LoadingIndicator } from 'src/modules/ui'
import qs from 'qs'

import { useActionDispatcher } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikCheckbox,
  FormikPasswordField,
  FormikTextField,
  FIELD_REQUIRED,
} from 'src/modules/ui'
import FormActions from './FormActions'
import FormHeader from './FormHeader'
import {
  activateInvitee,
  respondToInvite,
  selectInvitedUser,
} from './authSlice'
import { checkUserAuthState } from './utils'
import {
  passwordPolicyDescription,
  passwordPolicyRegEx,
  passwordWhitespaceDescription,
  passwordWhitespaceRegEx,
} from './passwordPolicySettings'
import PasswordPolicy from './PasswordPolicy'
import config from '../../config'
import { ACTION_LOGIN } from '../app/appConstants'

const SignUpFromInvite = ({ onStateChange }) => {
  const invitedUser = useSelector(selectInvitedUser)
  const { token } = useParams()
  const dispatchRespondToInvite = useActionDispatcher(respondToInvite, {
    initialStatus: 'loading',
  })

  useEffect(() => {
    dispatchRespondToInvite(
      { invite_token: token },
      {
        errorNotification: err => err.inviteToken,
      }
    )
  }, [dispatchRespondToInvite, token])

  return (
    <div>
      {dispatchRespondToInvite.status === 'loading' ? (
        <Container>
          <LoadingIndicator />
        </Container>
      ) : dispatchRespondToInvite.status === 'error' ? (
        <>
          <Container>
            <Typography>
              There was an error with your invite or it has expired
            </Typography>
          </Container>
        </>
      ) : invitedUser ? (
        <ActivateUser onStateChange={onStateChange} user={invitedUser} />
      ) : null}
    </div>
  )
}

const ActivateUser = ({ onStateChange, user }) => {
  const location = useLocation()
  // Remove first ? char
  const querystring = qs.parse(location.search.substr(1))
  const { redir } = querystring
  const history = useHistory()
  const dispatchActivateInvitee = useActionDispatcher(activateInvitee)

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(FIELD_REQUIRED)
      .matches(passwordWhitespaceRegEx, passwordWhitespaceDescription)
      .matches(passwordPolicyRegEx, passwordPolicyDescription),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'You must agree to the terms and conditions'
    ),
  })
  const initialValues = {
    email: user.email,
    password: '',
    passwordConfirmation: '',
    acceptTerms: false,
    id: user.id,
    redir: redir,
  }

  const handleSubmit = async ({ id, password, redir }, { setErrors }) => {
    try {
      await dispatchActivateInvitee({ password, id }).unwrap()
      const activatedUser = await Auth.signIn(user.email, password)
      await checkUserAuthState(activatedUser, onStateChange)
      redir ? history.push(redir) : history.push('/home')
    } catch (err) {
      setErrors(err.data)
    }
  }
  const givenName = user.trees[0]?.userIndividual?.givenName

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      user={user}
    >
      {formik => <ActivateUserForm {...formik} givenName={givenName} />}
    </Formik>
  )
}

const ActivateUserForm = ({ handleSubmit, isSubmitting, givenName }) => {
  return (
    <div>
      <FormHeader
        title={`Welcome to We Are [...]${givenName ? `, ${givenName}` : ''}!`}
        subtitle="Complete the form below to join your relatives."
      />
      <Form>
        <FormikTextField
          fullWidth
          label="Email"
          margin="normal"
          name="email"
          type="text"
          disabled={true}
        />
        <FormikPasswordField
          fullWidth
          label="Password"
          margin="normal"
          name="password"
        />

        <FormikPasswordField
          fullWidth
          label="Confirm Password"
          margin="normal"
          name="passwordConfirmation"
        />

        <PasswordPolicy
          variant="hint"
          sx={{ display: 'block', paddingTop: '1rem' }}
        />

        <FormikCheckbox
          name="acceptTerms"
          label={
            <>
              I accept the{' '}
              <Link
                href={`${config.publicUrl}/terms-conditions/`}
                target="_blank"
              >
                terms and conditions
              </Link>{' '}
            </>
          }
        />
        <FormActions>
          <Button
            permissionAction={ACTION_LOGIN}
            color="primary"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            size="large"
            type="submit"
          >
            Sign Up
          </Button>
        </FormActions>
      </Form>
    </div>
  )
}

export default SignUpFromInvite
