/**
 * An enhancement of FormikRichTextField that debounces a call to save,
 * and adds a saving status indicator
 *
 * Expects to be inside a <Formik... with `initialValues[fieldName]` optionally set
 *
 */

import { useState, useRef } from 'react'
import { Box, CircularProgress } from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import { InputLabel } from '@mui/material'

import { green } from '@mui/material/colors'

import { debounce } from 'lodash'

import { TOOLBAR_STYLE_FIXED } from 'src/modules/ui/editor/RichTextField'

import FormikRichTextField from 'src/modules/ui/FormikRichTextField'
import { editorStateToHtml } from 'src/modules/ui/editor/convert'

export const SavingFormikRichTextField = ({
  fieldName,
  label,
  placeholderText,
  doSave,
  richTextBodyClass,
  dontOverrideRichTextBodyStyle,
  debounceMs = 1000,
}) => {
  //const SAVE_STATE_LOADING = 1
  //const SAVE_STATE_CLEAN = 2
  const SAVE_STATE_DIRTY = 3
  const SAVE_STATE_SAVING = 4
  const SAVE_STATE_SAVED = 5

  const [saveState, setSaveState] = useState(null)

  if (dontOverrideRichTextBodyStyle && richTextBodyClass) {
    console.error(
      `both dontOverrideRichTextBodyStyle and richTextBodyClass are provided, richTextBodyClass will be ignored`
    )
  }

  const TranscriptionSaveStatus = () => {
    let content = <>&nbsp;</>
    switch (saveState) {
      case SAVE_STATE_SAVING:
        content = (
          <>
            <Box sx={{ mr: 1 }}>Saving...</Box>
            <CircularProgress size="1em" />
          </>
        )
        break
      case SAVE_STATE_SAVED:
        content = (
          <>
            <Box sx={{ mr: 1 }}>Saved</Box>
            <CheckIcon
              style={{
                height: '120%', //there's some built-in padding in the svg
                stroke: green[300],
                strokeWidth: 3,
              }}
            />
          </>
        )
        break
      case SAVE_STATE_DIRTY:
        content = <>Save pending...</>
        break
      default:
      //case (SAVE_STATE_LOADING):
      //case (SAVE_STATE_CLEAN):
    }

    return (
      <Box
        sx={{ mt: 2 }}
        style={{
          lineHeight: 1,
          display: 'flex',
          alignItems: 'center',
          height: '1em',
        }}
      >
        {content}
      </Box>
    )
  }

  const debouncedSaveChanges = useRef(
    debounce(newValue => {
      setSaveState(SAVE_STATE_SAVING)
      doSave(newValue)
      setSaveState(SAVE_STATE_SAVED)
    }, debounceMs),
    []
  )

  const handleEditorContentChange = newEditorState => {
    setSaveState(SAVE_STATE_DIRTY)
    debouncedSaveChanges.current(editorStateToHtml(newEditorState))
  }

  return (
    <>
      <InputLabel
        sx={{ mt: 3 }}
        htmlFor={`${fieldName}-rtf`}
        id={`${fieldName}-rtf-label`}
        shrink="true"
      >
        {label}
      </InputLabel>
      <FormikRichTextField
        id={`${fieldName}-rtf`}
        showToolbar
        name={fieldName}
        placeholder={placeholderText}
        showUnderline
        //wrapperClassName={classes.editorContainer}
        toolbarStyle={TOOLBAR_STYLE_FIXED}
        bodyStyle={richTextBodyClass}
        handleEditorStateContentChange={handleEditorContentChange}
        dontOverrideRichTextBodyStyle={dontOverrideRichTextBodyStyle}
      />
      <TranscriptionSaveStatus />
    </>
  )
}
