import React from 'react'
import { Box, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const CreateAlbumRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxHeight: `calc(100vh - ${theme.spacing(8)})`,
}))

const SidePanel = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  borderRight: `1px solid ${theme.palette.lightGrey.main}`,
  backgroundColor: theme.palette.purpleShadow.main,
  width: 340,
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  overflowY: 'auto',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'auto',
  width: '100%',
}))

const CloseButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1.5),
  top: theme.spacing(1.5),
}))

const AlbumModalLayout = ({ onClose, content, sidePanel }) => {
  return (
    <CreateAlbumRoot>
      <CloseButton>
        <IconButton permissionAction={ACTION_ALL_ACCESS} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseButton>
      {sidePanel && <SidePanel>{sidePanel}</SidePanel>}
      <Content>{content}</Content>
    </CreateAlbumRoot>
  )
}

export default AlbumModalLayout
