import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Checkbox, FormControlLabel, TextareaAutosize } from '@mui/material'

const FormContainer = styled('div')(({ theme }) => ({
  maxWidth: '700px',
  minWidth: '500px',
}))

const useStyles = makeStyles(theme => ({
  resultsContainer: {
    overflow: 'scroll',
    maxHeight: '300px',
  },
  buttonBar: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  addtionalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  meta_fields: {
    display: 'flex',
  },
  textGenerationCreateButtonText: {
    display: 'flex',
    flexDirection: 'column',
  },
  factList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflow: 'scroll',
    maxHeight: '200px',
    borderStyle: 'solid',
  },
  factLabel: {
    display: 'block',
    backgroundColor: theme.palette.lightPurple,
    paddingLeft: '5px',
  },
}))

const AiBiographyPromptForm = ({ individual, allFactOptions, onChange }) => {
  const classes = useStyles()
  const [factOptions, setFactOptions] = useState([])
  const [prompt, setPrompt] = useState('')

  const handleSetFacts = f => {
    const str = f.target.value
    let newOptions = factOptions
    if (factOptions.includes(str)) {
      newOptions = factOptions.filter(o => o !== str)
    } else {
      newOptions = [...factOptions, str]
    }
    setFactOptions(newOptions)
    onChange({ facts: newOptions, prompt: prompt })
  }

  const handleChangePrompt = f => {
    setPrompt(f.target.value)
    onChange({ facts: factOptions, prompt: f.target.value })
  }

  useEffect(() => {
    if (allFactOptions.length !== !factOptions.length) {
      setFactOptions(allFactOptions)
    }
  }, [factOptions, setFactOptions, allFactOptions])

  return (
    <FormContainer>
      <div>
        <label>Select which facts to include</label>
        <div className={classes.factList}>
          {factOptions?.map((str, idx) => {
            return (
              <FormControlLabel
                key={idx}
                className={classes.factLabel}
                control={<Checkbox key={idx + 'cb'} defaultChecked />}
                label={str}
                value={str}
                name="facts"
                onChange={handleSetFacts}
                sx={{ textTransform: 'capitalize', height: 30 }}
              />
            )
          })}
        </div>
        <div className={classes.addtionalContainer}>
          <label>Additional facts</label>
          <TextareaAutosize
            minRows={10}
            id="prompt"
            name="prompt"
            style={{ flex: '1', marginRight: '10px', padding: '0.5rem' }}
            placeholder={`Enter additonal information about ${individual?.display} as a list\n\ne.g \n\nworked at Smiths London, in the accounts department\nloved football\nsupported united`}
            value={prompt}
            onChange={handleChangePrompt}
          />
        </div>
      </div>
    </FormContainer>
  )
}

export default AiBiographyPromptForm
