import React, { useContext } from 'react'
import { Box } from '@mui/material'

import { Typography } from 'src/modules/ui'
import {
  formatIndividualName,
  formatIndividualWithYears,
} from '../ui/individualUtils'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'
import { generatePublicLinkForObject, useObjectLink } from '../app/links'
import { Link } from '../ui'
import { GeneralLink, ShowHideLinks } from '../content/LinkChips'
import { useLinkedPageTargets } from './hooks'
import { useSelector } from 'react-redux'
import { selectCurrentTree } from '../auth/authSlice'
import { useIsPublic } from '../app'
import { PublicContext } from '../public/contexts'

const IndividualCard = ({ individual, onIndividualActionClick }) => {
  const isPublic = useIsPublic()
  const publicContext = useContext(PublicContext)
  const privateIndividualLink = useObjectLink(
    INSTANCE_TYPE_INDIVIDUAL,
    individual.id
  )
  const publicIndividualLink = generatePublicLinkForObject(
    publicContext?.treeSlug,
    INSTANCE_TYPE_INDIVIDUAL,
    individual.id
  )

  const individualLink = isPublic ? publicIndividualLink : privateIndividualLink

  const displayName = formatIndividualName(individual)
  const { target } = useLinkedPageTargets()
  const currentLoggedInTree = useSelector(selectCurrentTree)

  //remove links for the target we are looking at e.g event
  const links = individual?.links?.filter(link => link.target !== target)

  let blurFilter = ''
  if (currentLoggedInTree?.demoMode) {
    blurFilter = individual?.state === 'DECEASED' ? '' : 'blur(4px)'
  }

  return (
    <Box
      boxShadow={2}
      borderRadius={1}
      my={0.6}
      p={1}
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        maxWidth: '250px',
        minWidth: '250px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Link to={individualLink}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {individual.hasPhoto && !individual.photo ? (
              <img
                alt="Loading Spinner"
                src="/loading-spinner.svg"
                height={145}
              />
            ) : (
              <img
                alt={displayName}
                src={
                  individual.photo
                    ? individual.photo.fileMedium
                    : individual.gender === 'F'
                    ? '/female-placeholder.png'
                    : '/person-placeholder.png'
                }
                height={145}
                style={{ borderRadius: '50%', filter: blurFilter }}
              />
            )}

            <Typography variant="subtitle6" ml={1} sx={{ filter: blurFilter }}>
              {formatIndividualWithYears({
                ...individual,
                shortenName: true,
                replacement: '?',
              })}
            </Typography>
          </Box>
        </Link>
        <ShowHideLinks
          links={links}
          LinkType={GeneralLink}
          title="Links"
          blurFilter={blurFilter}
        />
      </Box>
    </Box>
  )
}

export default IndividualCard
