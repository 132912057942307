import React from 'react'
import { makeStyles } from '@mui/styles'
import { PhotoAlbum as Gallery } from 'react-photo-album'
import Clamp from 'react-multiline-clamp'
import clsx from 'clsx'
import { Box } from '@mui/material'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { MEDIA_TYPE_PDF, MEDIA_TYPE_VIDEO_YOUTUBE } from '.'
import { PictureAsPdf } from '@mui/icons-material'
import { handleImgName } from './MediaDropzone'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    '& .hover-overlay': {
      opacity: 0,
      transition: '0.3s opacity',
    },
    '&:hover .hover-overlay': {
      opacity: 1,
    },
  },
  titleOverlay: {
    position: 'absolute',
    bottom: 6.5,
    color: 'white',
    background: 'rgba(0,0,0,0.7)',
    textAlign: 'left',
    minHeight: 'auto',
    width: '90%',
    padding: theme.spacing(3 / 2),
  },
}))

const PhotoGallery = ({
  media = [],
  onClickPhoto,
  GalleryPhoto = PhotoWithTitleV2,
}) => {
  const classes = useStyles()

  const photos = media?.map(
    (
      {
        fileMedium,
        mediumImageHeight,
        mediumImageWidth,
        description,
        visibility,
        title,
        type,
      },
      index
    ) => ({
      src: fileMedium,
      height: mediumImageHeight,
      width: mediumImageWidth,
      title: title,
      className: classes.root,
      type,
      visibility,
    })
  )

  if (!media) {
    return null
  }

  return (
    <Gallery
      layout="rows"
      spacing={8}
      rowConstraints={{ maxPhotos: 5 }}
      photos={photos}
      renderPhoto={GalleryPhoto}
      onClick={({ index }) => onClickPhoto(index)}
    />
  )
}

export const MediaTypeOverlay = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      bottom: 6.5,
      color: 'white',
      textAlign: 'left',
      height: '100%',
      width: '100%',
      display: 'flex',
      pointerEvents: 'none',
      zIndex: 4,
    }}
  >
    {children}
  </Box>
)

export const YouTubeOverlayIcon = ({ editable, sx }) => (
  <>
    {editable ? (
      <YouTubeIcon
        fontSize="large"
        sx={{
          height: '32%',
          width: 'auto',
          color: 'rgba(220,220,220,0.5)',
          alignSelf: 'center',
          mx: 'auto',
          ...sx,
        }}
      />
    ) : (
      <img
        src="/youtube-logo.png"
        alt="YouTube"
        style={{ height: '50%', margin: 'auto', ...sx }}
      />
    )}
  </>
)

export const PDFOverlayIcon = () => (
  <>
    <PictureAsPdf
      fontSize="large"
      sx={{
        height: '15%',
        width: 'auto',
        color: '#ad0e00',
        alignSelf: 'end',
        ml: 'auto',
        backgroundColor: 'white',
        borderRadius: '25px',
        border: '2px solid #ad0e00',
        padding: '4px',
        mr: '4px',
        mb: '4px',
      }}
    />
  </>
)

const Overlay = ({ title }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.titleOverlay, 'hover-overlay')}>
      <Clamp lines={3}>{handleImgName(title)}</Clamp>
    </div>
  )
}

export const PhotoWithTitle = ({ photo }) => {
  const { title, className, type } = photo

  const overlayIcon = (
    <>
      <MediaTypeOverlay type={type}>
        {type === MEDIA_TYPE_VIDEO_YOUTUBE && (
          <YouTubeOverlayIcon editable={false} />
        )}
        {type === MEDIA_TYPE_PDF && <PDFOverlayIcon />}
      </MediaTypeOverlay>
    </>
  )

  return (
    // if margin is set here then it must also be set to the same value in the call to <Gallery above and in MediaList.js
    <div className={className}>
      <img alt="" {...photo} />
      {title && <Overlay {...photo} />}
      {overlayIcon}
    </div>
  )
}

//needed to use a new gallery component and have to create a new render component left the old on in for now above
export const PhotoWithTitleV2 = props => {
  const photo = props.photo
  const { title, className, type } = photo

  const overlayIcon = (
    <>
      <MediaTypeOverlay type={type}>
        {type === MEDIA_TYPE_VIDEO_YOUTUBE && (
          <YouTubeOverlayIcon editable={false} />
        )}
        {type === MEDIA_TYPE_PDF && <PDFOverlayIcon />}
      </MediaTypeOverlay>
    </>
  )

  return (
    // if margin is set here then it must also be set to the same value in the call to <Gallery above and in MediaList.js
    <div className={className} style={props.wrapperStyle}>
      {props.renderDefaultPhoto({ wrapped: true })}
      {title && <Overlay {...photo} />}
      {overlayIcon}
    </div>
  )
}

export default PhotoGallery
