import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MenuItem as MuiMenuItem, SwipeableDrawer, Box } from '@mui/material'

import NavigatorViewerWithPyramid from '../viewer/NavigatorViewerWithPyramid'
import AncestralFamiliesContainer from '../home/AncestralFamiliesContainer'
import { Logo } from './NavBar'
import { HelpPage } from '../help/HelpPage'
import config from '../../config'
import AddContentDialog from './AddContentDialog'
import { NavBarContext } from './contexts'
import { BLOG_TREE } from '../common/constants'
import { useSelector } from 'react-redux'
import { selectUser } from '../auth/authSlice'

const MenuItem = ({ children, ...props }) => (
  <MuiMenuItem
    sx={{
      color: 'primary.main',
      fontWeight: 'bold',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
    {...props}
  >
    {children}
  </MuiMenuItem>
)

const MobileNavBar = ({ trigger, homeUrl, isPublic = false, routes }) => {
  const { treeType } = useContext(NavBarContext)
  const user = useSelector(selectUser)
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const [openAncestralFamilies, setOpenAncestralFamilies] = useState(false)
  const handleOpenAncestralFamilies = () => setOpenAncestralFamilies(true)

  const [openExplore, setOpenExplore] = useState(false)
  const handleOpenExplore = () => setOpenExplore(true)

  return (
    <>
      {trigger({ onClick: handleDrawerOpen })}
      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '25vh',
            pt: 4,
          }}
        >
          <Link
            to={homeUrl}
            style={{
              marginBottom: 8,
            }}
          >
            <Logo
              alt="weare.xyz"
              src="/logo.png"
              sx={{
                width: '70%',
                pl: 2,
                mr: 'auto',
              }}
            />
          </Link>
          {!routes ? (
            <>
              <MenuItem
                onClick={() => {
                  handleDrawerClose()
                  history.push(homeUrl)
                }}
                divider
              >
                {treeType === BLOG_TREE ? 'Blog-Home' : 'Home'}
              </MenuItem>
              {treeType !== BLOG_TREE && (
                <>
                  <MenuItem
                    divider
                    onClick={() => {
                      handleOpenAncestralFamilies()
                      handleDrawerClose()
                    }}
                  >
                    Families
                  </MenuItem>

                  <MenuItem
                    divider
                    onClick={() => {
                      handleOpenExplore()
                      handleDrawerClose()
                    }}
                  >
                    Navigator
                  </MenuItem>
                  {!isPublic && (
                    <AddContentDialog
                      trigger={props => (
                        <MenuItem
                          divider
                          onClick={() => {
                            props.onClick()
                          }}
                        >
                          Add Content
                        </MenuItem>
                      )}
                    />
                  )}
                </>
              )}

              {!user && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleDrawerClose()
                      window.location.href = config.publicUrl
                    }}
                    divider
                  >
                    Sign Up
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDrawerClose()
                      history.push('/login')
                    }}
                    divider
                  >
                    Login
                  </MenuItem>
                </>
              )}
              {!isPublic && (
                <HelpPage
                  trigger={helpTrigger => (
                    <MenuItem
                      divider
                      onClick={() => {
                        helpTrigger.onClick()
                      }}
                    >
                      Help
                    </MenuItem>
                  )}
                />
              )}
              <MenuItem
                onClick={() => {
                  handleDrawerClose()
                  history.push('/tree-admin')
                }}
                divider
              >
                Trees
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDrawerClose()
                  history.push('/blog-admin')
                }}
                divider
              >
                Blogs
              </MenuItem>
            </>
          ) : (
            <>
              {routes?.map(route => (
                <MenuItem
                  divider
                  onClick={() => {
                    handleDrawerClose()
                    history.push(route.to)
                  }}
                >
                  {route.title}
                </MenuItem>
              ))}
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <AncestralFamiliesContainer
        openAncestralFamilies={openAncestralFamilies}
        setOpenAncestralFamilies={setOpenAncestralFamilies}
      />
      <NavigatorViewerWithPyramid
        openExplore={openExplore}
        setOpenExplore={setOpenExplore}
      />
    </>
  )
}

export default MobileNavBar
