import React from 'react'
import { makeStyles } from '@mui/styles'

import ContentHeaderMeta from 'src/modules/content/ContentHeaderMeta'
import { PageTitle } from 'src/modules/ui'
import { INSTANCE_TYPE_ARTICLE } from 'src/modules/app/links'
import { ShareButton } from '../../visibility/InstanceVisibilityControl'
import { Box } from '@mui/system'
import { useShare, useUrlTypeFromUrl } from '../../app'
import LinkChips from '../../content/LinkChips'
import {
  PUBLIC_ROOT,
  generateTreeLink,
  generateTreeOverrideQueryString,
  isBlogPost,
} from '../../app/links'
import { UnpublishButton } from '../../writeArticle/WriteArticle'
import EditButton from '../../ui/EditButton'
import { Link, useLocation } from 'react-router-dom'
import { BLOG_TREE } from '../../common/constants'
import { selectCurrentTree } from '../../auth/authSlice'
import { useSelector } from 'react-redux'
import { getQueryParams } from '../../../utils'
import { useMediaQuery } from '@mui/material'
import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../../app/appConstants'

const useStyles = makeStyles(theme => ({
  author: {
    marginBottom: theme.spacing(0),
  },
  date: {
    marginBottom: theme.spacing(1),
  },
  header: {
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
}))

export const PublicArticleHeader = ({
  article,
  backButton,
  treeSlug,
  subtitle,
  subtitleContainerMb,
  showPreviewControls,
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const urlType = useUrlTypeFromUrl()
  const { id, title, titleDraft, visibility, links, state, ...articleRest } =
    article
  const classes = useStyles()
  const handleShare = useShare(true)
  const currentTree = useSelector(selectCurrentTree)
  const location = useLocation()
  const { treeOverride } = getQueryParams(location)

  const queryString =
    currentTree?.slug !== treeSlug
      ? generateTreeOverrideQueryString(currentTree?.slug, BLOG_TREE)
      : treeOverride
      ? generateTreeOverrideQueryString(treeOverride, BLOG_TREE)
      : ''

  const editUrl = generateTreeLink(treeSlug, `write-article/${id}`, queryString)
  const isBlog = isBlogPost(window.location.pathname)

  return (
    <div className={classes.header}>
      <PageTitle
        targetId={id}
        title={location?.state?.isDraft ? titleDraft : title}
        backButton={backButton}
        type={INSTANCE_TYPE_ARTICLE}
        subtitle={subtitle}
        subtitleContainerMb={subtitleContainerMb}
      />
      <ContentHeaderMeta
        urlType={urlType}
        {...articleRest}
        linkToComments={false}
        actions={
          <Box sx={{ ml: 1, gap: 0.5, display: 'flex' }}>
            {!location?.state?.isDraft && (
              <ShareButton
                onClickShare={handleShare}
                isPublic={true}
                shareSuffix={isBlog ? 'Post' : ''}
              />
            )}
            {showPreviewControls && state === 'PUBLISHED' && (
              <>
                {!mobileBreakpoint && (
                  <>
                    <Link
                      style={{ textDecoration: 'none' }}
                      color="primary"
                      to={editUrl}
                    >
                      <EditButton
                        permissionAction={ACTION_EDIT}
                        permissionParams={{
                          instace: article,
                          instanceType: INSTANCE_TYPE_ARTICLE,
                        }}
                        fontSize="small"
                      />
                    </Link>
                    <UnpublishButton
                      permissionAction={ACTION_ALL_ACCESS}
                      contentType={article?.type}
                      treeSlug={treeSlug}
                    />
                  </>
                )}
              </>
            )}
          </Box>
        }
      />
      {urlType === PUBLIC_ROOT && (
        <>
          <LinkChips links={links} sx={{ my: 2 }} />{' '}
        </>
      )}
    </div>
  )
}

export default PublicArticleHeader
