export const TARGET_ADD_OWN_PHOTO = 'ADD_OWN_PHOTO'
export const TARGET_ADD_INDIVIDUAL_PHOTOS = 'ADD_INDIVIDUAL_PHOTOS'
export const TARGET_ADD_MEDIA = 'ADD_MEDIA'
export const TARGET_CREATE_ALBUMS = 'CREATE_ALBUMS'
export const TARGET_PUBLISH_ARTICLES = 'PUBLISH_ARTICLES'
export const TARGET_INVITE_USERS = 'INVITE_USERS'

export const TRACKING_PERIOD_INITIAL = 'INITIAL'
export const TRACKING_PERIOD_MONTHLY = 'MONTHLY'

// Goals should always be displayed in this order:
export const TARGET_TYPE_ORDER = [
  TARGET_ADD_OWN_PHOTO,
  TARGET_ADD_INDIVIDUAL_PHOTOS,
  TARGET_ADD_MEDIA,
  TARGET_CREATE_ALBUMS,
  TARGET_PUBLISH_ARTICLES,
  TARGET_INVITE_USERS,
]

// All goals except "add own photo" can be monthly
export const MONTHLY_TARGET_TYPES = TARGET_TYPE_ORDER.slice(1)

export const HUMAN_READABLE_TARGETS = {
  [TARGET_ADD_OWN_PHOTO]: 'Add own profile picture',
  [TARGET_ADD_INDIVIDUAL_PHOTOS]: 'Add profile pictures',
  [TARGET_ADD_MEDIA]: 'Upload photos',
  [TARGET_CREATE_ALBUMS]: 'Create albums',
  [TARGET_PUBLISH_ARTICLES]: 'Publish articles',
  [TARGET_INVITE_USERS]: 'Invite users',
}

export const presentTargetTypesInOrder = goals => {
  const presentTargetTypes = new Set(goals.map(goal => goal.targetType))
  return TARGET_TYPE_ORDER.filter(targetType =>
    presentTargetTypes.has(targetType)
  )
}
