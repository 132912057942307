import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogActions, DialogContent, Stack } from '@mui/material'
import { Box } from '@mui/system'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { generateTreeLink } from 'src/modules/app/links'
import { Button, Typography } from 'src/modules/ui'

import {
  setReported,
  selectLastMonthReport,
  fetchAllTime,
  selectAllTimeReport,
} from './goalsSlice'
import Goal from './Goal'
import { overallProgressFromGoals } from './utils'
import { ASSISTANT_WIDTH_PX, N_GOALS_WRAP } from './constants'
import Progress from './Progress'
import ReportRow from './ReportRow'
import { HUMAN_READABLE_TARGETS, presentTargetTypesInOrder } from './targets'
import AllTimeProgress from './AllTimeProgress'
import MessageBox from './MessageBox'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const LastMonthReport = () => {
  let lastMonthReport = useSelector(selectLastMonthReport)
  let allTimeReport = useSelector(selectAllTimeReport)

  const dispatchFetchAllTime = useActionDispatcher(fetchAllTime)
  useEffect(() => {
    if (lastMonthReport.length) {
      dispatchFetchAllTime()
    }
  }, [dispatchFetchAllTime, lastMonthReport])

  // Actions when the user dismisses the report
  const [openIfReportPresent, setOpenIfReportPresent] = useState(true)
  const [saving, setSaving] = useState(false)
  const dispatchSetReported = useActionDispatcher(setReported)
  const handleDismiss = useCallback(async () => {
    setSaving(true)
    await dispatchSetReported()
    setOpenIfReportPresent(false)
    setSaving(false)
  }, [dispatchSetReported])

  // The report is always for the previous month:
  const now = new Date()
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  const lastMonthName = lastMonth.toLocaleString(navigator.language || 'utc', {
    month: 'long',
  })

  // Allow user to navigate to full report
  const history = useHistory()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const handleViewGoals = useCallback(async () => {
    await handleDismiss()
    history.push(generateTreeLink(treeSlug, 'goals'))
  }, [handleDismiss, history, treeSlug])

  // Normally
  if (!lastMonthReport.length || !openIfReportPresent) {
    return <></>
  }

  const itemWidth = ASSISTANT_WIDTH_PX / N_GOALS_WRAP
  const indicatorSize = 60
  const targetTypes = presentTargetTypesInOrder(allTimeReport)

  // Conditionals for messaging
  const overallProgress = overallProgressFromGoals(lastMonthReport)
  const metAllTargets = overallProgress >= 100
  const metNoTargets = lastMonthReport.every(
    goal => goal.progress.progress < goal.progress.targetDuringPeriod
  )
  const metSomeTargets = !metAllTargets && !metNoTargets

  return (
    <Dialog open={openIfReportPresent} onClose={handleDismiss} maxWidth="sm">
      <DialogContent>
        <Stack direction="row" sx={{ alignContent: 'center' }}>
          <Box sx={{ display: 'inline-block', mr: 1 }}>
            <img alt="Gene" src="/gene.png" width={40} height={24} />
          </Box>

          <Typography variant="h4">
            Assistant "Gene" - {lastMonthName} Goals Report
          </Typography>
        </Stack>

        <Stack
          direction="row"
          sx={{ justifyContent: 'center', mt: 3, mb: 3, gap: 1 }}
        >
          <Box sx={{ width: 100, textAlign: 'right' }}>
            <Typography>Monthly goals completed</Typography>
          </Box>
          <Progress
            value={overallProgress}
            indicatorSize={indicatorSize}
          ></Progress>
        </Stack>

        {metNoTargets && (
          <MessageBox>
            <Typography>
              Busy month, hard to find time, taking a breather, having fun in
              the sun... good for you! Hope you're up for a little elephant
              nibbling this month; your goals will reset and you can push on if
              the mood takes you! Good luck and best wishes, Gene.
            </Typography>
          </MessageBox>
        )}
        {metSomeTargets && (
          <MessageBox>
            <Typography>
              A tricky month, but you managed to find some time, well done! Each
              nibble at that elephant builds your family history story for
              future generations. Tweak your goals to suit you or leave them to
              reset as they are. Good luck and best wishes, Gene.
            </Typography>
          </MessageBox>
        )}
        {metAllTargets && (
          <MessageBox>
            <Typography>
              Outstanding! Your family and future generations will be forever in
              your debt! Onwards, tweak your goals and tuck into your elephant
              and reveal your wondrous backstory! Good luck and best wishes,
              Gene
            </Typography>
          </MessageBox>
        )}

        <Stack direction="row" sx={{ justifyContent: 'center' }}>
          <Box sx={{ margin: '0 auto' }}>
            <ReportRow rowLabel="">
              {targetTypes.map(targetType => (
                <Typography
                  sx={{ width: itemWidth, textAlign: 'center', p: 1 }}
                >
                  {HUMAN_READABLE_TARGETS[targetType]}
                </Typography>
              ))}
            </ReportRow>

            <ReportRow rowLabel={lastMonthName}>
              {lastMonthReport.map(goal => (
                <Goal
                  goal={goal}
                  key={goal.id}
                  link={false}
                  title={false}
                  width={itemWidth}
                  indicatorSize={indicatorSize}
                ></Goal>
              ))}
            </ReportRow>

            <ReportRow rowLabel={'All time'}>
              {allTimeReport.map(goal => (
                <AllTimeProgress
                  value={goal.progress}
                  width={itemWidth}
                  indicatorSize={60}
                />
              ))}
            </ReportRow>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          isLoading={saving}
          onClick={handleViewGoals}
        >
          View goals report
        </Button>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          isLoading={saving}
          onClick={handleDismiss}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default LastMonthReport
