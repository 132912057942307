import React, { useRef } from 'react'
import Masonry from 'react-masonry-css'
import { Box, styled } from '@mui/material'

import { ContentInlineComments } from 'src/modules/content/ContentInlineComments'
import { useContainerDimensions } from 'src/utils'

const breakpointColumnsObj = {
  default: 4,
  1024: 3,
  900: 2,
  700: 1,
}

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .masonry-grid': {
    display: 'flex',
    marginLeft: -30,
    width: 'auto',
  },
  '& .masonry-grid_column': {
    paddingLeft: 30,
    backgroundClip: 'paddingBox',
  },
  '& .masonry-grid_column > .MuiBox-root': {
    marginBottom: 32,
  },
  '& .masonry-grid_column > .MuiBox-root > div': {
    position: 'relative',
  },
}))

const MasonryCardList = ({
  contentData,
  user,
  cardComponent = ContentInlineComments,
  onDelete,
  urlOverride,
}) => {
  const Card = cardComponent
  const firstCardRef = useRef()
  const { width } = useContainerDimensions(firstCardRef)
  return (
    <Root>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {contentData.length > 0 && (
          <Box ref={firstCardRef} sx={{ overflow: 'hidden' }}>
            <Card
              content={contentData[0]}
              user={user}
              width={width}
              onDelete={onDelete}
              urlOverride={urlOverride}
            />
          </Box>
        )}
        {contentData.slice(1).map(content => (
          <Card
            content={content}
            key={content.id || `${content.type}:${content.publishedAt}`}
            user={user}
            width={width}
            urlOverride={urlOverride}
          />
        ))}
      </Masonry>
    </Root>
  )
}

export default MasonryCardList
