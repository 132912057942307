import GenerationalSection from './GenerationalSection'

const UsersByRelationship = ({
  users,
  availableRoles,
  showRolesHelpDialog,
}) => {
  if (users.length === 0) {
    return null
  }

  const order = {
    siblings: 0,
    spousesandcoparents: 1,
    parents: 2,
    children: 3,
    grandparents: 4,
    grandchildren: 5,
    greatgrandparents: 6,
    unclesandaunts: 7,
    cousins: 8,
  }
  let relationships = [...new Set(users.map(ind => ind.relationship))]
  relationships = relationships.sort((a, b) => {
    const aAsObjField = a ?? ''.split(' ').join('').toLowerCase()
    const bAsObjField = b ?? ''.split(' ').join('').toLowerCase()
    return order[aAsObjField] - order[bAsObjField]
  })

  const usersByRelationship = relationships.map(r =>
    users.filter(u => u.relationship === r)
  )

  return (
    <>
      {usersByRelationship.map(g => (
        <GenerationalSection
          generation={g}
          key={`genSection-${g[0].relationship}`}
          availableRoles={availableRoles}
          showRolesHelpDialog={showRolesHelpDialog}
        />
      ))}
    </>
  )
}

export default UsersByRelationship
