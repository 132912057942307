import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'

import { generateBlogLink, INSTANCE_TYPE_ARTICLE } from '../app/links'
import Refresh from '../ui/Refresh'
import { fetchHomeBlogPosts, selectHomeBlogPosts } from './homeSlice'
import { ViewBlogButton } from '../ui/actionButtons'
import HomeBlogPostList from './HomeBlogPostList'
import { useParams } from 'react-router-dom'
import AddContentDialog from '../app/AddContentDialog'
import { blogCards, cardComponentMap } from './DraftBlogPosts'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { ShareButton } from '../visibility/InstanceVisibilityControl'
import { useShare } from '../app'

export const ActionButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: { display: 'flex' },
  [theme.breakpoints.down('md')]: { display: 'none' },
  justifyContent: 'flex-end',
  '& > *': {
    margin: theme.spacing(0, 1),
  },
}))

const BlogPosts = () => {
  const { slug } = useParams()
  const handleShare = useShare()

  const treeSlug = slug

  const dispatchFetchHomeBlogPosts = useActionDispatcher(fetchHomeBlogPosts)

  const fetchBlogPosts = useMemo(() => {
    const fetchPosts = async () => {
      await dispatchFetchHomeBlogPosts({
        page: 0,
        state: 'PUBLISHED',
        type: INSTANCE_TYPE_ARTICLE,
        block_limit: 1,
        block_type: 'TEXT',
        sort: '',
        treeSlug,
      })
    }
    return fetchPosts
  }, [dispatchFetchHomeBlogPosts, treeSlug])

  useEffect(() => {
    fetchBlogPosts()
  }, [fetchBlogPosts])

  return (
    <>
      <ActionButtons>
        <div />
        <div>
          <ViewBlogButton to={generateBlogLink(treeSlug, 'write-article')} />{' '}
          <AddContentDialog
            customCards={blogCards}
            cardComponents={cardComponentMap}
            trigger={props => (
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                className={'joyride_first_time_user_addcontent'}
                color="primary"
                size="large"
                {...props}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Add Blog Post
              </Button>
            )}
          />
          <ShareButton
            onClickShare={handleShare}
            iconSize={'medium'}
            style={{ marginLeft: 4 }}
          />
        </div>
        <Refresh onClick={fetchBlogPosts} />
      </ActionButtons>
      <HomeBlogPostList
        state={'PUBLISHED'}
        type={INSTANCE_TYPE_ARTICLE}
        fetchPosts={fetchHomeBlogPosts}
        postsSelector={selectHomeBlogPosts}
      />
    </>
  )
}

export default BlogPosts
