import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import Button from './Button'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const ConfirmDialog = ({
  children,
  onOpen,
  onClose = () => {},
  maxWidth,
  onConfirm = async () => {},
  open,
  submitColor,
  submitText,
  cancelColor,
  cancelVariant,
  cancelText,
  onCancel = async () => {},
  secondaryText,
  onConfirmSecondary = async () => {},
  title,
  trigger,
  getPhotoInstances,
  submitDisabled = false,
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const handleShowModal = useCallback(() => {
    if (getPhotoInstances) {
      getPhotoInstances()
    }
    setModalOpen(true)
    onOpen && onOpen()
  }, [getPhotoInstances, onOpen])

  const closeModal = () => {
    setModalOpen(false)
    onClose()
  }

  const handleCloseModal = () => {
    if (!isSubmitting) {
      closeModal()
    }
  }
  const handleCancel = () => {
    if (!isSubmitting) {
      handleCloseModal()
      if (onCancel) {
        onCancel()
      }
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      await onConfirm()
      closeModal()
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSubmitSecondary = async () => {
    setIsSubmitting(true)
    try {
      await onConfirmSecondary()
      closeModal()
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} maxWidth={maxWidth} onClose={handleCloseModal}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent sx={{ mr: 1, pr: 1, ml: 1, pl: 1 }}>
          {children}
        </DialogContent>
        <DialogActions sx={{ mr: 1, mb: 1 }}>
          {cancelText && (
            <Button
              color={cancelColor}
              variant={cancelVariant}
              onClick={handleCancel}
              permissionAction={ACTION_ALL_ACCESS}
              sx={{ mr: 1 }}
            >
              {cancelText}
            </Button>
          )}
          {secondaryText && (
            <Button
              color={cancelColor}
              variant={cancelVariant}
              onClick={handleSubmitSecondary}
              permissionAction={ACTION_ALL_ACCESS}
              sx={{ mr: 1 }}
            >
              {secondaryText}
            </Button>
          )}
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            disabled={submitDisabled}
            color={submitColor}
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            {submitText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmDialog.propTypes = {
  maxWidth: PropTypes.string,
  onConfirm: PropTypes.any,
  open: PropTypes.bool,
  submitColor: PropTypes.string,
  submitText: PropTypes.string,
  cancelColor: PropTypes.string,
  cancelVariant: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  secondaryText: PropTypes.string,
  onConfirmSecondary: PropTypes.any,
  successNotification: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.func,
  validationSchema: PropTypes.object,
}

ConfirmDialog.defaultProps = {
  maxWidth: 'md',
  open: false,
  submitColor: 'primary',
  submitText: 'Submit',
  cancelColor: 'secondary',
  cancelVariant: 'outlined',
  cancelText: 'Cancel',
}

export default ConfirmDialog
