import { Box, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { ConfirmDialog } from '../ui'
import { useActionDispatcher } from '../app'
import { downloadMediaFromLatestGedcomForTree } from '../auth/authSlice'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(theme => ({
  downloadContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const DownloadGedcomMediaTreeButton = ({ treeSlug, manageConfig, tree }) => {
  const classes = useStyles()
  const downloadMediaFromLatestGedcomForTreeAction = useActionDispatcher(
    downloadMediaFromLatestGedcomForTree
  )

  const disableMenuItem =
    !tree.userCanInitiateObjeDownload || tree.gedcomImportInProgress // user has TREE_SETTINGS permission and user has a paid or VIP subscription and not in progress
  const numItemsToBeDownloaded = tree.remainingDownloadableObjesCount

  return (
    <Box className={classes.downloadContainer}>
      <ConfirmDialog
        onConfirm={() =>
          downloadMediaFromLatestGedcomForTreeAction(treeSlug, {
            successNotification: 'Downloads queued',
          })
        }
        submitText={manageConfig.downloadMediaFromLatestGedcomPrompt}
        title="Download remaining media items from gedcom"
        trigger={props => (
          <MenuItem disabled={disableMenuItem} {...props}>
            {manageConfig.downloadMediaFromLatestGedcomPrompt}
          </MenuItem>
        )}
      >
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          This will download and upload {numItemsToBeDownloaded} media items
          from your .ged file into your archive. This might create duplicates if
          you have already uploaded items yourself.
        </Typography>
      </ConfirmDialog>
    </Box>
  )
}

export default DownloadGedcomMediaTreeButton
