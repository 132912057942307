import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { styled, Box, Divider } from '@mui/material'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

import { useActionDispatcher } from 'src/modules/app'
import { createComment, deleteComment } from 'src/modules/content/contentSlice'

import {
  Button,
  ConfirmDialog,
  IconButton,
  Link,
  ReadableDateTime,
  Typography,
} from 'src/modules/ui'

import NewComment from './NewComment'
import ProfilePicture from './ProfilePicture'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  generateLinkForObject,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_INFORMATION_REQUEST,
} from 'src/modules/app/links'
import { formatIndividualName } from '../ui/individualUtils'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const getResponseNameForType = type =>
  type === INSTANCE_TYPE_INFORMATION_REQUEST ? 'reply' : 'comment'

const Icon = styled('img')({})

const StyledLink = styled(Link)({
  textDecoration: 'none',
})

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  marginBottom: theme.spacing(2),
}))

const StyledCommentsContainer = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  display: 'flex',
  paddingBottom: theme.spacing(3),
}))

const NameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: '2px',
})

const TextContainer = styled('div')({
  flexGrow: 1,
  flexDirection: 'column',
})

const MoreCommentsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'flex-start',
})

const Comment = ({ author, canDelete, text, created, onDelete }) => {
  const { individualOnTree: individual } = author
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const displayName = formatIndividualName(author)

  return (
    <StyledCommentsContainer>
      <ProfilePicture size={40} sx={{ mr: 2 }} user={author} />
      <TextContainer>
        <NameContainer>
          <Typography>
            <StyledLink
              to={generateLinkForObject(
                treeSlug,
                INSTANCE_TYPE_INDIVIDUAL,
                individual?.id
              )}
            >
              {displayName}
            </StyledLink>
            &nbsp;
          </Typography>
          <Typography
            sx={{ fontSize: '0.825rem', color: 'secondaryGrey.main' }}
          >
            {'\u2022'}
            &nbsp;
            <ReadableDateTime inputStr={created} />
          </Typography>
        </NameContainer>
        {text.split('\n').map((value, index) => (
          <Typography key={index} sx={{ color: 'text.secondary' }}>
            {value}
            <br />
          </Typography>
        ))}
      </TextContainer>
      <ButtonContainer>
        {canDelete && (
          <ConfirmDialog
            onConfirm={onDelete}
            trigger={props => (
              <IconButton permissionAction={ACTION_ALL_ACCESS} {...props}>
                <Icon src="/icon-delete.svg" width={18} height={18} />
              </IconButton>
            )}
          >
            <Typography>Delete this comment?</Typography>
          </ConfirmDialog>
        )}
      </ButtonContainer>
    </StyledCommentsContainer>
  )
}

export const Comments = ({
  canComment,
  initialPageSize,
  contentId,
  comments,
  gutter = 0,
  onDelete,
  onSubmitComment,
  pageSize,
  responseName = 'comment',
  user,
}) => {
  const [visibleCount, setVisibleCount] = useState(
    initialPageSize || pageSize || comments.length
  )
  const hasMoreComments = comments.length > visibleCount
  const moreCommentsText = pluralize(
    `${responseName}`,
    comments.length - visibleCount,
    true
  )
  const handleViewMore = () => {
    const commentsRemaining = comments.length - visibleCount
    const nextPageSize = Math.min(
      pageSize || commentsRemaining,
      commentsRemaining
    )
    setVisibleCount(visibleCount + nextPageSize)
  }

  const handleDelete = ({ id }) => {
    onDelete({ id, contentId })
  }

  return (
    <Box id="comments" sx={{ mx: gutter }}>
      {canComment && (
        <NewComment
          contentId={contentId}
          user={user}
          onSubmit={onSubmitComment}
          responseName={responseName}
        />
      )}

      {comments.length > 0 && (
        <Box>
          <Divider variant="middle" sx={{ mb: 1, mx: -gutter }} />
          <Box sx={{ my: 1.5 }}>
            <Typography variant="subtitle2">
              Recent {pluralize(responseName)}
            </Typography>
          </Box>
          {comments.slice(0, visibleCount).map((comment, index) => (
            <Comment
              canDelete={
                user.currentTree.currentUserIsAdmin ||
                comment.author.id === user.id
              }
              key={index}
              onDelete={() => handleDelete({ id: comment.id })}
              {...comment}
            />
          ))}
          {hasMoreComments &&
          initialPageSize &&
          visibleCount === initialPageSize ? (
            <StyledButton color="primary" onClick={handleViewMore}>
              View {moreCommentsText}
            </StyledButton>
          ) : hasMoreComments ? (
            <MoreCommentsContainer>
              <StyledButton color="primary" onClick={handleViewMore}>
                View More Comments
              </StyledButton>
              <Typography>
                {visibleCount}/{comments.length}
              </Typography>
            </MoreCommentsContainer>
          ) : null}
        </Box>
      )}
    </Box>
  )
}

Comments.propTypes = {
  canComment: PropTypes.bool,
  comments: PropTypes.array,
  initialPageSize: PropTypes.number,
  onDelete: PropTypes.func,
  onSubmitComment: PropTypes.func,
  pageSize: PropTypes.number,
  responseName: PropTypes.string,
  user: PropTypes.object,
}

Comments.defaultProps = {
  pageSize: 5,
}

export const CommentsContainer = ({ user, ...props }) => {
  const dispatchCreateComment = useActionDispatcher(createComment)
  const dispatchDeleteComment = useActionDispatcher(deleteComment)

  const handleSubmitComment = ({ contentId, text }) =>
    dispatchCreateComment({ contentId, text, user })

  const handleDeleteComment = ({ id, contentId }) => {
    dispatchDeleteComment({ id, contentId })
  }
  return (
    <Comments
      {...props}
      onDelete={handleDeleteComment}
      onSubmitComment={handleSubmitComment}
      user={user}
    />
  )
}

export default CommentsContainer
