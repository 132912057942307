import React, { useState } from 'react'
import { Dialog, Box, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { TabLayout, Typography, Button, IconButton } from 'src/modules/ui'

import { useUploadMedia } from './hooks'
import UploadAlbum from './UploadAlbum'
import ThumbnailGallery from './ThumbnailGallery'
import TagForm from '../writeArticle/TagForm'
import YouTubeLinkDialog from './YouTubeLinkDialog'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const Root = styled(Box)(({ theme }) => ({
  maxHeight: `calc(100vh - ${theme.spacing(8)})`,
  display: 'flex',
  flexDirection: 'column',
}))

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 3),
}))

const ThumbnailGalleryContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2.5, 1.5, 3),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  flexGrow: 1,
  minHeight: 200,
}))

const AddMediaDialog = ({
  album,
  open = false,
  trigger,
  onFinishedUploading,
  previewTiles,
  setPreviewTiles,
  targets,
  showYouTube = true,
  showAddTagsOnNewUploads = true,
  shouldTagImages = false,
}) => {
  const { uploadMedia, uploading } = useUploadMedia()
  const [modalOpen, setModalOpen] = useState(open)
  const [existing, setExisting] = useState([])
  const [presetTargets, setPresetTargets] = useState(targets)

  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => {
    setModalOpen(false)
    setPreviewTiles([])
    setExisting([])
  }

  const doUploadMedia = async values => {
    try {
      const media = await uploadMedia({
        previewTiles,
        targets: presetTargets,
        ...values,
      })
      if (onFinishedUploading) {
        if (album) {
          onFinishedUploading(media)
        } else {
          onFinishedUploading()
        }
      }
    } catch (err) {
    } finally {
      handleCloseModal()
    }
  }

  const handleSelectExisting = selected => {
    setExisting(selected)
  }

  const handleUploadNew = () =>
    doUploadMedia({
      ...(album ? { albumId: album.id } : {}),
    })

  const handleUploadExisting = () =>
    doUploadMedia({
      existingMedia: existing,
      targets: shouldTagImages ? targets : [],
      ...(album ? { albumId: album.id } : {}),
    })

  const handleChangeTags = tags => {
    setPresetTargets(tags)
  }

  const addYouTubeVideo = async youtubeVideoPreview => {
    setPreviewTiles([...previewTiles, youtubeVideoPreview])
  }

  const tabs = [
    {
      label: 'Upload New',
      component: (
        <Box sx={{ m: 1.5, mt: 0 }}>
          <UploadAlbum {...{ previewTiles, setPreviewTiles }} hideCaptions />
          {previewTiles.length > 0 && (
            <>
              {showAddTagsOnNewUploads && (
                <Box mt={4}>
                  <Typography variant="subtitle2">
                    Add TAGs below so your media is displayed in the relevant
                    media sections of your site.
                  </Typography>
                  <Typography my={1.5} variant="subtitle2">
                    You can create TAGs for special Artefacts, Places and
                    Occasions.
                  </Typography>
                  <TagForm
                    onChangeTags={handleChangeTags}
                    presetTargets={presetTargets}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 1.5,
                }}
              >
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={uploading}
                  onClick={handleUploadNew}
                  size="large"
                >
                  Add Media
                </Button>
              </Box>
            </>
          )}
        </Box>
      ),
    },
    {
      label: 'From Library',
      component: (
        <ThumbnailGalleryContainer>
          <ThumbnailGallery
            onSelect={handleSelectExisting}
            cropAfterSelect={false}
            open
            multiple
            showFilterInPopover
          />
          {existing.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1.5 }}>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                isLoading={uploading}
                onClick={handleUploadExisting}
                size="large"
              >
                Add Media
              </Button>
            </Box>
          )}
        </ThumbnailGalleryContainer>
      ),
    },
  ]

  if (showYouTube) {
    tabs.splice(1, 0, {
      label: (
        <YouTubeLinkDialog
          open={false}
          onAddURL={addYouTubeVideo}
          trigger={props => (
            <Typography onClick={props.onClick}>Add YouTube Video</Typography>
          )}
        />
      ),
      component: <Box sx={{ m: 3, mt: 0 }}></Box>,
      onClick: e => {
        e.preventDefault()
      },
    })
  }
  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={false}
        scroll="paper"
      >
        <Root>
          <Title>
            <Typography variant="h7">Add Media</Typography>
            <Box sx={{ mr: -1, ml: 'auto' }}>
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Title>
          <TabLayout tabs={tabs} sx={{ overflow: 'auto' }} />
        </Root>
      </Dialog>
    </>
  )
}

export default AddMediaDialog
