import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  fetchPublicIndividualsForTarget,
  selectPublicNodeDirectory,
  selectSharedByIndividual,
} from '../tree/treeSlice'
import { PublicContext } from '../contexts'
import { LoadingIndicator } from 'src/modules/ui'
import { ExploredIndividualViewer } from 'src/modules/viewer/treeViewers'
import PublicTreeExplorer from '../tree/PublicTreeExplorer'
import { Stack, useTheme } from '@mui/material'
import FabButton from '../../ui/FabButton'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { useEmbed } from '../hooks'
import { usePublicHomePageUrlSegments } from '../page/hooks'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const PublicHomePageTree = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { treeLoading } = useContext(PublicContext)
  const sharedBy = useSelector(selectSharedByIndividual)
  const publicNodeDirectory = useSelector(selectPublicNodeDirectory)
  const embed = useEmbed()
  const { slug } = usePublicHomePageUrlSegments()

  useEffect(() => {
    if (slug && sharedBy) {
      dispatch(
        fetchPublicIndividualsForTarget({ treeSlug: slug, target: sharedBy.id })
      )
    }
  }, [slug, sharedBy, dispatch])

  const fullScreen = (
    <PublicTreeExplorer
      trigger={navigatorTrigger => (
        <Stack
          direction="row"
          justifyContent="right"
          width={theme.maxWidth}
          mx="auto"
          mt={2}
          pr={2}
        >
          <FabButton
            permissionAction={ACTION_ALL_ACCESS}
            text="Full Screen"
            icon={<FullscreenIcon fontSize="medium" sx={{ mb: 0.2 }} />}
            onClick={navigatorTrigger.onClick}
            style={{ pointerEvents: 'visible' }}
          />
        </Stack>
      )}
    />
  )

  if (treeLoading) {
    return <LoadingIndicator title={'Loading Tree'} />
  } else if (sharedBy && !isEmpty(publicNodeDirectory)) {
    return (
      <>
        {!embed && fullScreen}
        <ExploredIndividualViewer
          exploredIndividualNode={sharedBy}
          doAddGreatGrandParents={false}
          nodeDirectory={publicNodeDirectory}
          isPublic={true}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default PublicHomePageTree
