import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TreeViewerForIndividual } from '../viewer'
import TreeLoadingGuard from '../viewer/TreeLoadingGuard'
import { fetchIndividualsForTarget } from '../viewer/viewerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

const LinkedTree = () => {
  const dispatch = useDispatch()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { linkedPageId } = useParams()

  useEffect(() => {
    if (linkedPageId) {
      dispatch(fetchIndividualsForTarget({ treeSlug, target: linkedPageId }))
    }
  }, [treeSlug, linkedPageId, dispatch])

  return (
    <TreeLoadingGuard partialCallKey={linkedPageId}>
      <TreeViewerForIndividual
        individualId={linkedPageId}
        containingComponent="LinkedTree"
      />
    </TreeLoadingGuard>
  )
}

export default LinkedTree
