import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useActionDispatcher } from 'src/modules/app'
import {
  Button,
  Form,
  FormikPasswordField,
  FIELD_REQUIRED,
} from 'src/modules/ui'

import { changePassword } from './authSlice'
import {
  passwordPolicyDescription,
  passwordPolicyRegEx,
  passwordWhitespaceDescription,
  passwordWhitespaceRegEx,
} from './passwordPolicySettings'
import PasswordPolicy from './PasswordPolicy'
import { ACTION_LOGIN } from '../app/appConstants'

const ChangePasswordForm = ({ handleSubmit, isSubmitting }) => {
  return (
    <Form>
      <FormikPasswordField
        label="Current password"
        margin="normal"
        name="oldPassword"
        fullWidth
      />
      <FormikPasswordField
        label="New password"
        margin="normal"
        name="newPassword"
        fullWidth
      />
      <PasswordPolicy
        variant="hint"
        sx={{ display: 'block', paddingTop: '1rem' }}
      />
      <Button
        permissionAction={ACTION_LOGIN}
        color="primary"
        onClick={handleSubmit}
        isLoading={isSubmitting}
        size="large"
        type="submit"
        sx={{ mt: 1 }}
      >
        Update Password
      </Button>
    </Form>
  )
}
const ChangePassword = ({ ...props }) => {
  const dispatchChangePassword = useActionDispatcher(changePassword)

  const initialValues = { oldPassword: '', newPassword: '' }

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(FIELD_REQUIRED),
    newPassword: Yup.string()
      .required(FIELD_REQUIRED)
      .matches(passwordWhitespaceRegEx, passwordWhitespaceDescription)
      .matches(passwordPolicyRegEx, passwordPolicyDescription),
  })

  const handleSubmit = async (
    { oldPassword, newPassword },
    { resetForm, setErrors }
  ) => {
    try {
      await dispatchChangePassword(
        {
          oldPassword,
          newPassword,
        },
        {
          successNotification: 'Password has been changed',
        }
      ).unwrap()
      resetForm()
    } catch (error) {
      const { name, message } = error
      const fieldErrors = {}
      if (name === 'InvalidParameterException') {
        fieldErrors['newPassword'] = passwordPolicyDescription
      } else if (name === 'NotAuthorizedException') {
        fieldErrors['oldPassword'] = 'Password is incorrect'
      } else if (name === 'InvalidPasswordException') {
        fieldErrors['newPassword'] = message
      } else if (name === 'LimitExceededException') {
        fieldErrors['oldPassword'] =
          'You have tried to login too many times - please wait a while'
        fieldErrors['newPassword'] =
          'You have tried to login too many times - please wait a while'
      }
      setErrors(fieldErrors)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <ChangePasswordForm
          {...formik}
          {...props}
          isSubmitting={dispatchChangePassword.status === 'loading'}
        />
      )}
    </Formik>
  )
}

export default ChangePassword
