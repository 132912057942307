import React, { useCallback, useEffect, useState } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { styled } from '@mui/material'
import { Amplify } from 'aws-amplify'
import Auth from '@aws-amplify/auth'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { Container } from 'src/modules/ui'

import AuthNavBar from './AuthNavBar'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import ResetPassword from './ResetPassword'
import SignUp from './SignUp'
import SignUpFromInvite from './SignUpFromInvite'
import VerifyEmail from './VerifyEmail'
import awsConfig, { getExportTokenIfPresent } from './awsConfig'
import {
  checkUserAuthState,
  INVITE_ROUTE,
  isLoginRoute,
  LOGIN_ROUTE,
  SIGNUP_ROUTE,
} from './utils'
import { fetchUser, selectUser } from './authSlice'
import { PUBLIC_ROOT } from 'src/modules/app/links'
import { useQuery } from '../app'
import { PUBLIC_BLOG } from '../app/links'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { AuthState } from './Authprovider'
import { useNotification } from 'src/modules/app/hooks'
import { NewUserSurvey } from './NewUserSurvey'
import { patchProfileAndState } from './authSlice'
import { UserLifecycleStates } from '../app/appConstants'

Amplify.configure(awsConfig)

const AuthBody = styled('div')(({ theme }) => ({
  paddingTop: theme.headerHeight,
}))

const AuthComponent = ({ children }) => {
  const { user, route } = useAuthenticator(context => [context.user])
  const [loading, setLoading] = useState(true)
  const [authState, setAuthState] = useState()
  const [cognitoUser, setCognitoUser] = useState()
  const [prefillEmail, setPrefillEmail] = useState()
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const weareUser = useSelector(selectUser)
  const location = useLocation()
  const pathname = location.pathname
  const history = useHistory()
  const queryParams = useQuery()

  useEffect(() => {
    handleStateChange(route, user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const checkAuthenticatedUser = async () => {
      const exportToken = getExportTokenIfPresent()
      if (exportToken) {
        handleStateChange(AuthState.SignedIn, { username: exportToken })
        setLoading(false)
        return
      }

      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        if (cognitoUser) {
          await checkUserAuthState(cognitoUser, handleStateChange)
        }
      } catch (err) {
        if (
          err.includes('not authenticated') &&
          !isLoginRoute(pathname) &&
          queryParams.get('login') !== 'true'
        ) {
          if (
            pathname.substring(pathname.length - 5) === '/blog' ||
            pathname.includes('/blog/')
          ) {
            let slug = pathname.split('/')[1]
            if (pathname.substring(pathname.length - 9) === '/about-me') {
              slug += '/about-me'
            }
            history.push(`/${PUBLIC_BLOG}/${slug}`)
          } else {
            history.push(`/${PUBLIC_ROOT}${pathname}`)
          }
        }
      } finally {
        setLoading(false)
      }
    }
    checkAuthenticatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const loggedIn = Boolean(authState === AuthState.SignedIn && cognitoUser)
    if (loggedIn) {
      dispatchFetchUser()
    }
  }, [authState, cognitoUser, dispatchFetchUser])

  const handleStateChange = useCallback(
    (nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setCognitoUser(authData)
    },
    [setAuthState, setCognitoUser]
  )

  const handleClickForgottenPassword = ({ email }) => {
    setPrefillEmail(email)
    handleStateChange(AuthState.ForgotPassword, {})
  }
  const handleClickGoToLogin = ({ email }) => {
    setPrefillEmail(email)
    handleStateChange(AuthState.SignIn, {})
  }

  const handleClickGoToLoginFromOtherRoute = ({ email }) => {
    setPrefillEmail(email)
    handleStateChange(AuthState.SignIn, {})
    //signup is its own route, move away from it
    history.push(LOGIN_ROUTE)
  }

  const handleClickGoToSignUpFromOtherRoute = ({ email }) => {
    setPrefillEmail(email)
    //login is its own route, move away from it
    history.push(SIGNUP_ROUTE)
  }

  const { showSuccess } = useNotification()

  const dispatchPatchProfileAndState = useActionDispatcher(patchProfileAndState)

  const handleStateChangeAndRequestEmailVerificationCode = (
    nextAuthState,
    authData
  ) => {
    handleStateChange(nextAuthState, authData)
    if (nextAuthState === AuthState.VerifyContact) {
      // sign up is successful, user will be moved to 'Verify Email Address'
      // screen, request a code email for them
      // verifyCurrentUserAttribute() requests the verification email from Cognito
      Auth.verifyCurrentUserAttribute('email').then(res => {
        dispatchPatchProfileAndState({
          userLifecycleState: UserLifecycleStates.AWAITING_EMAIL_VERIFICATION,
        })
        showSuccess('Verification code sent, please check your email')
      })
    }
  }

  return loading ? null : authState === AuthState.SignedIn &&
    cognitoUser &&
    weareUser &&
    weareUser.newUserSurveySource !== undefined &&
    weareUser.newUserSurveySource !== null ? (
    children
  ) : (
    <>
      <AuthNavBar />
      <AuthBody>
        <Container maxWidth="sm">
          <Switch>
            <Route path={INVITE_ROUTE}>
              <SignUpFromInvite
                onStateChange={handleStateChangeAndRequestEmailVerificationCode}
              />
            </Route>
            <Route path={SIGNUP_ROUTE}>
              <SignUp
                onStateChange={handleStateChangeAndRequestEmailVerificationCode}
                onClickGoToLogin={handleClickGoToLoginFromOtherRoute}
                prefillEmail={prefillEmail}
              />
            </Route>
            <Route>
              {[AuthState.SignIn, AuthState.SignedOut, undefined].includes(
                authState
              ) ? (
                <Login
                  onClickForgottenPassword={handleClickForgottenPassword}
                  onStateChange={handleStateChange}
                  onClickGoToSignUpPage={handleClickGoToSignUpFromOtherRoute}
                  prefillEmail={prefillEmail}
                />
              ) : authState === AuthState.VerifyContact ? (
                <VerifyEmail
                  onClickBackToLogin={handleClickGoToLogin}
                  onStateChange={handleStateChange}
                  user={cognitoUser}
                />
              ) : authState === AuthState.ForgotPassword ? (
                <ForgotPassword
                  onClickBackToLogin={handleClickGoToLogin}
                  onStateChange={handleStateChange}
                  user={cognitoUser}
                  prefillEmail={prefillEmail}
                />
              ) : authState === AuthState.ResetPassword ? (
                <ResetPassword
                  onClickBackToLogin={handleClickGoToLogin}
                  onStateChange={handleStateChange}
                  user={cognitoUser}
                />
              ) : weareUser &&
                (!('newUserSurveySource' in weareUser) ||
                  weareUser.newUserSurveySource === null) ? (
                <NewUserSurvey />
              ) : null}
            </Route>
          </Switch>
        </Container>
      </AuthBody>
    </>
  )
}

export default AuthComponent
