import React, { useEffect } from 'react'
import { Box, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Formik } from 'formik'
import * as Yup from 'yup'

import TagForm from 'src/modules/writeArticle/TagForm'
import {
  Typography,
  Button,
  FormikTextField,
  FIELD_REQUIRED,
} from 'src/modules/ui'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_PHOTO_ALBUM } from '../app/links'

const useStyles = makeStyles(theme => ({
  albumForm: {
    width: '100%',
    margin: theme.spacing(0, 0, 2, 0),
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tag: {
    width: '100%',
  },
  tags: {
    margin: 0,
    flexDirection: 'column',
    display: 'flex',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

const AlbumForm = ({
  action,
  album,
  handleChangeTags,
  editForm,
  formTitle,
  onSubmit,
  initialValues,
  disableSubmit = false,
  presetTargets,
  previewTiles = [],
  subject,
  submitText,
  onClose,
  selectedTargets,
  uploading,
}) => {
  const classes = useStyles()

  useEffect(() => {
    // Revoke the data URIs when componet closes to avoid memory leaks
    return () => {
      for (let { file } of previewTiles) {
        URL.revokeObjectURL(file.preview)
      }
    }
    // If `previewTiles` is included in dependencies then image previews
    // break when dragging multiple images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    albumTitle: Yup.string().required(FIELD_REQUIRED),
  })

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Typography className={classes.title} variant="h7">
        {formTitle}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, values: { albumTitle } }) => (
          <>
            <FormikTextField
              multiline
              label="ALBUM NAME"
              name="albumTitle"
              fullWidth
              margin="normal"
              className={classes.albumForm}
              variant="filled"
            />
            <Typography className={classes.subTitle} color="textPrimary">
              Add album to page media section of:
            </Typography>
            <TagForm
              className={classes.tags}
              onChangeTags={handleChangeTags}
              presetTargets={presetTargets}
              tagClassName={classes.tag}
              subject={subject}
              inputVariant="filled"
            />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <Box sx={{ width: '100%', mb: 3, mt: 2 }}>
                {action}
                <Button
                  permissionAction={ACTION_CREATE}
                  permissionParams={{
                    instanceType: INSTANCE_TYPE_PHOTO_ALBUM,
                  }}
                  sx={{ mt: 1 }}
                  disabled={
                    (editForm &&
                      disableSubmit &&
                      albumTitle === album?.title) ||
                    (editForm && !albumTitle)
                  }
                  fullWidth
                  onClick={handleSubmit}
                  isLoading={uploading || isSubmitting}
                >
                  {submitText || formTitle}
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </Stack>
  )
}

export default AlbumForm
