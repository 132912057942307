import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { Box } from '@mui/system'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import HeightIcon from '@mui/icons-material/Height'

import clsx from 'clsx'

import { useActionDispatcher } from 'src/modules/app'
import { MEDIA_COLUMN_SIZE_MAP } from 'src/modules/content/TextBlock'
import {
  TOOLBAR_STYLE_FLOATING_LEFT,
  TOOLBAR_STYLE_FLOATING_RIGHT,
} from 'src/modules/ui/editor/RichTextField'
import { ToolbarContainer } from 'src/modules/ui/ToolbarContainer'

import { addMediaToBlock, updateContentBlock } from './writeArticleSlice'
import ContentBlockTextEditor from './ContentBlockTextEditor'
import { AddMediaIcon } from './PhotoWithFloatingControls'
import { ArticleControlsSelect } from './ArticleControlsSelect'
import {
  MEDIA_COLUMN_ALIGNMENT_LEFT,
  MEDIA_COLUMN_ALIGNMENT_RIGHT,
} from './contentBlockConstants'
import { MEDIA_ARRANGEMENT_COLUMN, SortablePhotos } from './SortablePhotos'
import ResizePhotoGroupControl from './ResizePhotoGroupControl'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
  },
  blockContents: {
    display: 'flex',
    flexDirection: 'row',
  },
  blockContentsLeft: {
    flexDirection: 'row-reverse',
  },
  columnControls: {
    position: 'absolute',
    top: theme.spacing(-5.5),
    right: 108,
    padding: theme.spacing(0.7),
  },
  columnControlsLeft: {
    left: theme.spacing(-1),
    right: 'auto',
  },
  photoColumn: {
    flexShrink: 0,
    marginLeft: theme.spacing(0.5),
    minWidth: '15%',
    maxWidth: '85%',
  },
  photoColumnLeft: {
    flexShrink: 0,
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
  toolbarControls: {
    top: theme.spacing(-6),
  },
}))

export const RESIZE_DRAG_SIZE = 40

const PhotoColumn = ({
  contentId,
  id,
  mediaColumnPosition,
  mediaColumnSize,
  mediaColumnMeta,
  media,
  isHovered,
  isFocused,
  onFocus,
  onBlur,
  sources,
  editSource,
  textContent,
  textHint,
  presetTargets,
}) => {
  const classes = useStyles()
  const [showComponent, setshowComponent] = useState(false)
  const [photoCache, setPhotoCache] = useState(media)
  const rootRef = useRef()
  const colContainerRef = useRef()
  const addMediaContainerRef = useRef()

  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)
  const dispatchAddPhotoToBlock = useActionDispatcher(addMediaToBlock)

  useEffect(() => {
    setPhotoCache(media)
  }, [media])

  const handleSelectPhoto = useCallback(
    (photo, order = 0) => {
      try {
        dispatchAddPhotoToBlock(
          {
            contentId,
            id,
            mediaId: photo.id,
            order,
            existingMocbId: photo.templatePlaceholderMocbId,
          },
          {
            errorNotification: err => err.data && err.data.photo,
          }
        ).unwrap()
      } catch (err) {}
    },
    [contentId, dispatchAddPhotoToBlock, id]
  )

  const isLeft = mediaColumnPosition === MEDIA_COLUMN_ALIGNMENT_LEFT
  const showControls = isHovered || isFocused

  const handleOnFocus = useCallback(() => onFocus && onFocus(id), [onFocus, id])

  const handleResize = mouseDownEvent => {
    const containerWidth = rootRef.current.offsetWidth
    const containerLeft = (window.innerWidth - containerWidth) / 2
    const windowWidth = window.innerWidth
    let colWidth
    let colWidthPercentage

    function onMouseMove(mouseMoveEvent) {
      colWidth = mouseMoveEvent.pageX - containerLeft - RESIZE_DRAG_SIZE / 2
      if (!isLeft) {
        colWidth =
          windowWidth -
          mouseMoveEvent.pageX -
          containerLeft -
          RESIZE_DRAG_SIZE * 0.7
      }
      colWidthPercentage = ((colWidth / containerWidth) * 100).toFixed(2)
      colContainerRef.current.style.width = `${colWidthPercentage}%`
      if (addMediaContainerRef?.current) {
        addMediaContainerRef.current.style.height = `${
          (containerWidth / 100) * colWidthPercentage * 0.75
        }px`
      }
    }

    function onMouseUp() {
      dispatchUpdateContentBlock({
        contentId,
        id: id,
        mediaColumnSize: 'CUSTOM',
        mediaColumnMeta: colWidthPercentage,
      })
      document.body.removeEventListener('mousemove', onMouseMove)
    }
    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  useLayoutEffect(() => {
    //only show the component when the dom is loaded so the widths are available and we avoid jumping
    setshowComponent(true)
  }, [setshowComponent])

  const colWidth =
    (MEDIA_COLUMN_SIZE_MAP[mediaColumnSize] / rootRef?.current?.offsetWidth) *
      100 || mediaColumnMeta
  return (
    <div
      ref={rootRef}
      className={classes.root}
      style={{ visibility: showComponent ? 'visible' : 'hidden' }}
    >
      {showControls && (
        <ToolbarContainer
          elevation={1}
          className={clsx(classes.columnControls, {
            [classes.columnControlsLeft]: isLeft,
          })}
        >
          <PhotoColumnControls
            contentId={contentId}
            contentBlockId={id}
            mediaColumnPosition={mediaColumnPosition}
            mediaColumnSize={mediaColumnSize}
          />
        </ToolbarContainer>
      )}
      <div
        className={clsx(classes.blockContents, {
          [classes.blockContentsLeft]: isLeft,
        })}
      >
        <Box sx={{ flexGrow: 1, wordBreak: 'break-word' }}>
          <ContentBlockTextEditor
            contentId={contentId}
            id={id}
            isFocused={isFocused}
            isHovered={isHovered}
            onBlur={onBlur}
            sources={sources}
            editSource={editSource}
            onFocus={handleOnFocus}
            textContent={textContent}
            textHint={textHint}
            textBlockProps={{
              layout: 'MEDIA_COLUMN',
              media: media,
              mediaColumnPosition,
              mediaColumnSize,
            }}
            toolbarClass={classes.toolbarControls}
            toolbarStyle={
              mediaColumnPosition === MEDIA_COLUMN_ALIGNMENT_RIGHT
                ? TOOLBAR_STYLE_FLOATING_LEFT
                : TOOLBAR_STYLE_FLOATING_RIGHT
            }
          />
        </Box>
        <ResizePhotoGroupControl
          onMouseDown={handleResize}
          width={RESIZE_DRAG_SIZE}
          show={isHovered}
          alignment="vertical"
        />
        <div
          ref={colContainerRef}
          className={clsx(classes.photoColumn, {
            [classes.photoColumnLeft]: isLeft,
          })}
          style={{
            width: `${colWidth}%`,
          }}
        >
          <SortablePhotos
            contentId={contentId}
            contentBlockId={id}
            media={media}
            arrangement={MEDIA_ARRANGEMENT_COLUMN}
            handleAddMedia={handleSelectPhoto}
            presetTargets={presetTargets}
            mediaColumnSize={mediaColumnSize}
            placeholderStyles={{
              height: (rootRef?.current?.offsetWidth / 100) * colWidth * 0.75,
              minHeight: (rootRef?.current?.offsetWidth / 100) * 15 * 0.75,
              maxHeight: (rootRef?.current?.offsetWidth / 100) * 85 * 0.75,
            }}
          />
          {!photoCache.length && (
            <div
              ref={addMediaContainerRef}
              style={{
                height: (rootRef?.current?.offsetWidth / 100) * colWidth * 0.75,
                minHeight: (rootRef?.current?.offsetWidth / 100) * 15 * 0.75,
                maxHeight: (rootRef?.current?.offsetWidth / 100) * 85 * 0.75,
              }}
            >
              <AddMediaIcon
                handleSelect={handleSelectPhoto}
                height={'100%'}
                presetTargets={presetTargets}
                width={'100%'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const PhotoColumnControls = ({
  contentId,
  contentBlockId,
  mediaColumnPosition,
  mediaColumnSize,
}) => {
  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)
  const handleMediaColumnSizeChange = event => {
    const size = event.target.value
    dispatchUpdateContentBlock({
      contentId,
      id: contentBlockId,
      mediaColumnSize: size,
    })
  }
  const handleMediaColumnPositionChange = event => {
    const position = event.target.value
    dispatchUpdateContentBlock({
      contentId,
      id: contentBlockId,
      mediaColumnPosition: position,
    })
  }
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <HeightIcon
        fontSize="small"
        color="grey"
        sx={{ transform: 'rotate(90deg)' }}
      />
      <FormControl>
        <Select
          value={mediaColumnSize}
          variant="standard"
          onChange={handleMediaColumnSizeChange}
          input={<ArticleControlsSelect />}
        >
          <MenuItem value="SMALL">Small</MenuItem>
          <MenuItem value="MEDIUM">Medium</MenuItem>
          <MenuItem value="LARGE">Large</MenuItem>
          <MenuItem value="CUSTOM" disabled sx={{ display: 'none' }}>
            Custom
          </MenuItem>
        </Select>
      </FormControl>
      {mediaColumnPosition === 'LEFT' ? (
        <AlignHorizontalLeftIcon fontSize="small" color="grey" />
      ) : (
        <AlignHorizontalRightIcon fontSize="small" color="grey" />
      )}
      <FormControl>
        <Select
          value={mediaColumnPosition}
          variant="standard"
          onChange={handleMediaColumnPositionChange}
          input={<ArticleControlsSelect />}
        >
          <MenuItem value="LEFT">Left</MenuItem>
          <MenuItem value="RIGHT">Right</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}

export default PhotoColumn
