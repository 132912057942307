import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useActionDispatcher } from 'src/modules/app'
import {
  fetchPublicPageBlogPosts,
  selectPublicBlogPosts,
  setSort,
} from './pageSlice'
import { BaseContentList } from 'src/modules/page'
import PublicArticleCard from './PublicArticleCard'
import { Actions } from 'src/modules/page/PageContentList'
import { Actions as HomeActions } from '../../home/HomeContentList'
import { PublicContext } from '../contexts'
import {
  generateBlogLinkForObject,
  INSTANCE_TYPE_ARTICLE,
} from '../../app/links'
import { selectTree } from '../tree/treeSlice'
import {
  fetchPublicBlogPost,
  resetPublicContent,
} from '../content/contentSlice'
import PushPinIcon from '@mui/icons-material/PushPin'

const instanceTypeMap = {
  [INSTANCE_TYPE_ARTICLE]: PublicArticleCard,
}

export const PinnedPostIcon = ({ contentId, pinTargetId = '' }) => {
  const tree = useSelector(selectTree)

  return (
    <>
      {tree?.pinnedArticle?.id === contentId && (
        <PushPinIcon fontSize="small" />
      )}
    </>
  )
}

const PublicPageBlogPostList = ({
  target,
  state,
  pageType,
  block_limit,
  block_type,
  limit = 4,
}) => {
  const [pinnedPost, setPinnedPost] = useState()
  const { treeSlug } = useContext(PublicContext)
  const dispatch = useDispatch()
  const dispatchFetchPageBlogPosts = useActionDispatcher(
    fetchPublicPageBlogPosts
  )
  const dispatchFetchPinnedPost = useActionDispatcher(fetchPublicBlogPost)
  const content = useSelector(selectPublicBlogPosts)
  const tree = useSelector(selectTree)
  const { next, results } = content
  const [modifiedResults, setModifiedResults] = useState(results)
  const [loading, setLoading] = useState()
  const [loaded, setLoaded] = useState(false)
  const location = useLocation()
  const isHome = location.pathname.includes('home')
  const sort = isHome ? '' : content.sort

  const updateResults = useCallback(() => {
    if (tree?.pinnedArticle) {
      results.forEach((post, index) => {
        if (post.id === tree.pinnedArticle?.id) {
          setPinnedPost(post)
          let newResults = [...results]
          newResults.splice(index, 1)
          setModifiedResults(newResults)
        }
      })
    } else {
      setModifiedResults(results)
    }
  }, [results, tree?.pinnedArticle])

  useEffect(() => {
    const fetchPageBlogPosts = async () => {
      setLoading(true)
      await dispatchFetchPageBlogPosts({
        target,
        treeSlug,
        page: 0,
        state,
        limit,
        pageType,
        block_limit,
        block_type,
        sort: sort,
      })
      updateResults()
      setLoaded(true)
      setLoading(false)
    }
    if (loaded === false) {
      setLoading(true)
      dispatch(resetPublicContent())

      fetchPageBlogPosts()
    }
  }, [
    dispatchFetchPageBlogPosts,
    state,
    pageType,
    target,
    treeSlug,
    sort,
    limit,
    block_limit,
    block_type,
    dispatchFetchPinnedPost,
    tree?.pinnedArticle,
    dispatch,
    results,
    modifiedResults.length,
    pinnedPost,
    updateResults,
    loaded,
  ])

  const handleFetchMore = async () => {
    if (dispatchFetchPageBlogPosts.status === 'loading') {
      return
    }
    await dispatchFetchPageBlogPosts({
      target,
      treeSlug,
      page: content.page + 1,
      state,
      pageType,
      block_limit,
      block_type,
      limit,
      sort: sort,
    })
  }

  const handleSetSortFilter = filter => {
    dispatch(setSort(filter))
  }

  useEffect(() => {
    updateResults()
  }, [results, updateResults])

  return (
    <BaseContentList
      actions={
        isHome ? (
          <HomeActions type={pageType} />
        ) : (
          <Actions
            onSetSort={handleSetSortFilter}
            sort={content.sort}
            type={pageType}
          />
        )
      }
      {...{
        content,
        instanceTypeMap,
        dispatchFetchContent: dispatchFetchPageBlogPosts,
        handleFetchMore,
        loading,
        next,
        results: modifiedResults,
        type: INSTANCE_TYPE_ARTICLE,
        urlOverride: generateBlogLinkForObject,
        pinnedContent: pinnedPost,
        PinPostDialog: PinnedPostIcon,
        state: 'PUBLISHED',
        hasPinnedArticle: Boolean(tree?.pinnedArticle),
      }}
    />
  )
}

export default PublicPageBlogPostList
