import { Box, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { withOverridableComponent } from 'src/overridable'
import { Typography } from 'src/modules/ui'
import {
  MEDIA_MARGIN_RIGHT,
  MEDIA_ROW_HEIGHT_MAP,
} from '../writeArticle/contentBlockConstants'
import { useCorsImageProps } from '../app'
import { MEDIA_ROW_MIN_HEIGHT } from '../writeArticle/MediaRow'
import { imageCaptionStyles } from './Article'
import { MEDIA_TYPE_PDF, MEDIA_TYPE_VIDEO_YOUTUBE } from '../photo'
import {
  MediaTypeOverlay,
  PDFOverlayIcon,
  YouTubeOverlayIcon,
} from '../photo/PhotoGallery'
import useDetectPrint from 'react-detect-print'

import AnnotatedImage from '../photo/AnnotatedImageFabricTS'

const usePhotoStyles = makeStyles(theme => ({
  photo: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    '&:last-child': {
      marginRight: 0,
    },
    '@media print': {
      height: 'fit-content',
      breakInside: 'avoid',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  mediaCaption: {
    display: 'inline-block',
    color: theme.palette.textSecondary,
    fontSize: '0.875rem',
    paddingTop: theme.spacing(1),
  },
  imgPrintStyle: {
    '@media print': {
      height: '200px !important',
      width: 'auto !important',
    },
  },
}))

export const ArticlePhoto = ({
  className,
  children,
  editable,
  height,
  ImageProps = {},
  onClick,
  imageClassName,
  photo,
  handleLoaded = () => {},
  mediaColumnSize,
  mediaRowHeight,
  showTitleIfNoCaption,
  marginRight,
  isWithinCarousel = false,
  flexOverride,
  forcePrintStyle = false,
  allowAnnotationViewer = false,
}) => {
  let mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isPrinting = useDetectPrint()
  mobileBreakpoint = isPrinting ? false : mobileBreakpoint // don't use mobile breakpoint when printing

  const classes = usePhotoStyles()
  let corsProps = useCorsImageProps()
  const isYouTubeLink = photo.type === MEDIA_TYPE_VIDEO_YOUTUBE
  const isPDF = photo.type === MEDIA_TYPE_PDF
  corsProps = isYouTubeLink ? {} : corsProps
  // Note that the img is requested cross origin so that access control
  // headers are cached for subsequent requests. This becomes important
  // when displaying the image in a canvas for cropping.

  if (photo.allowDetailsScreen === false) {
    // allowDetailsScreen must be false, not null
    if (isYouTubeLink) {
      onClick = e => {
        window.open(`https://youtube.com/watch?v=${photo.youtubeVideoId}`)
      }
    } else {
      onClick = undefined
    }
  }

  let flexSetting = null
  if (!isWithinCarousel) {
    if (flexOverride) {
      flexSetting = flexOverride
    } else {
      flexSetting =
        (photo.mediumImageWidth || photo.width) /
        (photo.mediumImageHeight || photo.height)
    }
  }

  const imgStyle = mobileBreakpoint
    ? {
        height: 'auto',
        width: '100%',
        boxShadow: photo.boxShadow,
        borderRadius: photo.borderRadius,
      }
    : {
        height: height
          ? height === 'auto'
            ? 'auto'
            : parseInt(height)
          : MEDIA_ROW_HEIGHT_MAP[mediaRowHeight],
        width: mobileBreakpoint
          ? '100%'
          : mediaColumnSize
          ? '100%'
          : height === 'auto'
          ? '100%'
          : 'auto',
        boxShadow: photo.boxShadow,
        borderRadius: photo.borderRadius,
      }

  const containsAnnotations = !!photo.links?.find(link => link.annotationsSvg)

  return (
    <Box
      className={clsx(classes.photo, className)}
      style={{ marginRight: editable ? 0 : marginRight ?? MEDIA_MARGIN_RIGHT }}
      sx={{
        minHeight: MEDIA_ROW_MIN_HEIGHT,
        width: mobileBreakpoint
          ? '100%'
          : mediaColumnSize
          ? '100%'
          : 'min-content',
        position: 'relative',
        flex: flexSetting,
      }}
    >
      {!allowAnnotationViewer || !containsAnnotations ? (
        <img
          // refers to browser image dragging, not drag and drop behaviour:
          draggable={false}
          src={photo.fileMedium || photo.fileThumbnail}
          alt=""
          className={clsx(
            imageClassName,
            forcePrintStyle ? classes.imgPrintStyle : '',
            onClick ? classes.clickable : ''
          )}
          onClick={onClick}
          onLoad={handleLoaded}
          style={imgStyle}
          {...ImageProps}
          {...corsProps}
        />
      ) : (
        <Box
          className={clsx(
            imageClassName,
            forcePrintStyle ? classes.imgPrintStyle : '',
            onClick ? classes.clickable : ''
          )}
          style={{
            ...imgStyle,
            aspectRatio: `${photo.width} / ${photo.height}`,
          }}
          onClick={onClick}
          {...ImageProps}
          {...corsProps}
        >
          <AnnotatedImage
            file={photo.fileMedium || photo.fileThumbnail}
            type={photo.type}
            initialLinks={photo.links}
            imageWidthPx={photo.width}
            imageHeightPx={photo.height}
            annotationSvg={photo.annotationsSvg}
            originalImageWidthPx={photo.mediumImageWidth}
            originalImageHeightPx={photo.mediumImageHeight}
            editingImageAnnotations={false} //editingImageAnnotations}
            enableZoom={false}
            handleImageLoaded={handleLoaded}
          />
        </Box>
      )}
      {isYouTubeLink && (
        <MediaTypeOverlay>
          <YouTubeOverlayIcon editable={editable} />
        </MediaTypeOverlay>
      )}
      {isPDF && (
        <MediaTypeOverlay>
          <PDFOverlayIcon />
        </MediaTypeOverlay>
      )}

      {children}
      {!editable && (
        <Typography className={classes.mediaCaption} style={imageCaptionStyles}>
          {photo.caption ?? (showTitleIfNoCaption ? photo.title : '')}
        </Typography>
      )}
    </Box>
  )
}

export default withOverridableComponent(ArticlePhoto, 'ArticlePhoto')
