import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'
import Footer from '../app/Footer'
import PublicMediaDetail from './photo/PublicMediaDetail'
import PublicContentPhotos from './content/PublicContentPhotos'
import PublicArticlePhoto from './content/PublicArticlePhoto'
import PublicSubTreeBlock from './content/PublicSubTreeBlock'
import PublicPhotoNavigator from './photo/PublicPhotoNavigator'
import {
  PATH_SEGMENT_ARTICLE,
  PATH_SEGMENT_DOCUMENT,
  PATH_SEGMENT_MEDIA,
  PATH_SEGMENT_PHOTO_ALBUM,
  PUBLIC_SHARE,
} from '../app/links'
import { ComponentOverrideContext } from 'src/overridable'
import { PublicContext } from './contexts'
import { Content } from './Public'
import PublicShareNavBar from './PublicShareNavBar'
import PublicArticle from './content/PublicArticle'
import { useEmbed } from './hooks'
import PublicMediaPage from './photo/PublicMediaPage'
import PublicAlbumPage from './photo/PublicAlbumPage'

const Share = () => {
  const {
    url,
    params: { treeSlug },
  } = useRouteMatch(`/${PUBLIC_SHARE}/:treeSlug/`)

  const embed = useEmbed()

  return (
    <ComponentOverrideContext.Provider
      value={{
        MediaDetail: PublicMediaDetail,
        ContentPhotos: PublicContentPhotos,
        ArticlePhoto: PublicArticlePhoto,
        SubTreeBlock: PublicSubTreeBlock,
        PhotoListNavigatorDialog: PublicPhotoNavigator,
      }}
    >
      <PublicContext.Provider value={{ treeSlug }}>
        <PublicShareNavBar baseUrl={url} />
        <Content style={{ paddingTop: embed && 0 }}>
          <Switch>
            <Route
              path={`${url}/${PATH_SEGMENT_ARTICLE}/:id`}
              render={props => <PublicArticle {...props} />}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_DOCUMENT}/:id`}
              render={props => <PublicArticle {...props} />}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_MEDIA}/:id`}
              render={props => <PublicMediaPage {...props} />}
            />
            <Route
              path={`${url}/${PATH_SEGMENT_PHOTO_ALBUM}/:id`}
              render={props => <PublicAlbumPage {...props} />}
            />
            <Redirect to={'/'} />
          </Switch>
          <Footer />
        </Content>
      </PublicContext.Provider>
    </ComponentOverrideContext.Provider>
  )
}

export default Share
