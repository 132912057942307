import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectFamilyByFamilyId } from 'src/modules/viewer/viewerSlice'
import { selectUserHomeIndividual } from 'src/modules/auth/authSlice'

import { LineageViewer } from 'src/modules/viewer/treeViewers'
import TreeLoadingGuard from '../viewer/TreeLoadingGuard'
import { PYRAMID } from '../viewer/viewerSlice'

const LineageTree = () => {
  const { linkedPageId } = useParams()
  const familyObj = useSelector(selectFamilyByFamilyId(linkedPageId))
  const homeIndividual = useSelector(selectUserHomeIndividual)

  return (
    <TreeLoadingGuard partialCallKey={homeIndividual?.id + '-' + linkedPageId}>
      <TreeLoadingGuard
        title={'Loading family lineage ... '}
        partialCallKey={PYRAMID}
      />
      {homeIndividual && (
        <LineageViewer
          family={familyObj}
          traceLineageTo={homeIndividual}
          showTotalGenerations={40}
          allowDragAndZoom={true}
          disableZoom={true}
        />
      )}
    </TreeLoadingGuard>
  )
}

export default LineageTree
