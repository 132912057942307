import React, { useState } from 'react'
import { useField } from 'formik'
import clsx from 'clsx'
import { FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'

import RichTextField from './editor/RichTextField'

const useStyles = makeStyles(theme => ({
  editor: {
    fontFamily: 'At Hauss Std, sans-serif',
  },
  underline: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 1,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.42)',
    },
    '&:hover::after': {
      backgroundColor: theme.palette.primary.main,
      height: 2,
    },
  },
  underlineActive: {
    '&::after': {
      backgroundColor: theme.palette.primary.main,
      height: 2,
    },
  },
}))

const FormikRichTextField = ({
  editorClassName,
  showUnderline,
  handleEditorStateContentChange,
  ...props
}) => {
  const classes = useStyles()
  const [{ value }, meta, { setTouched, setValue }] = useField(props)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)
  const [hasFocus, setHasFocus] = useState(false)

  var previousEditorState = value

  const handleStateChange = state => {
    setValue(state)
    setTouched(true)
    if (handleEditorStateContentChange) {
      if (
        !state
          .getCurrentContent()
          .equals(previousEditorState.getCurrentContent())
      ) {
        handleEditorStateContentChange(state)
      }
    }
    previousEditorState = state
  }

  const handleFocus = () => setHasFocus(true)
  const handleBlur = () => setHasFocus(false)

  return (
    <>
      <RichTextField
        editorClassName={clsx(
          classes.editor,
          {
            [classes.underline]: showUnderline,
            [classes.underlineActive]: hasFocus && showUnderline,
          },
          editorClassName
        )}
        editorState={value}
        onBlur={handleBlur}
        onEditorStateChange={handleStateChange}
        onFocus={handleFocus}
        {...props}
      />
      {hasError && <FormHelperText error>{error}</FormHelperText>}
    </>
  )
}

export default FormikRichTextField
