export const MEDIA_TYPE_VIDEO = 'VIDEO'
export const MEDIA_TYPE_VIDEO_YOUTUBE = 'VIDEO_YOUTUBE'
export const MEDIA_TYPE_PHOTO = 'PHOTO'
export const MEDIA_TYPE_PDF = 'PDF'
export const MEDIA_TYPE_ALL = 'ALL'
export const ACCEPT_UPLOAD_ALL = 'image/*,application/pdf'
export const ACCEPT_UPLOAD_IMAGE = 'image/*'

export const PDF_PREVIEW_IMAGE = '/pdflogo.png'

export const MEDIA_CATEGORY_DOCUMENT_PAGE = 'DOCUMENT_PAGE'

export { default as AlbumPage } from './AlbumPage'
export { default as Thumbnail } from './Thumbnail'
export { default as MediaDetail } from './MediaDetail'
export { default as MediaList } from './MediaList'
export { default as MediaNavigatorDialog } from './MediaNavigator'
export { default as SelectMedia } from './SelectMedia'
export { default as MediaPage } from './MediaPage'
