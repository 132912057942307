import { Box } from '@mui/system'

import ContentPhotos from 'src/modules/content/ContentPhotos'
import EditAlbumDialog from 'src/modules/photo/EditAlbum'
import EditButton from '../ui/EditButton'
import { INSTANCE_TYPE_DOCUMENT } from '../app/links'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const EditableGalleryBlock = ({ ...params }) => {
  //console.debug(`EditableGalleryBlock: called with params:`, params)

  const handleReportDeleted = params => {
    // console.debug(
    //   `EditableGalleryBlock.handleReportDeleted(): called with params`,
    //   params
    // )
  }
  const handleRefresh = params => {
    // console.debug(
    //   `EditableGalleryBlock.handleRefresh(): called with params`,
    //   params
    // )
  }

  const album = {
    id: params.contentId,
    title: 'title from EditableGalleryBlock',
    media: params.media,
    links: [],
  }

  return (
    <>
      <EditAlbumDialog
        album={album}
        contentBlockId={params.id}
        onFinishedDeleting={handleReportDeleted}
        onFinishedUpdating={handleRefresh}
        trigger={props => (
          <Box sx={{ mb: 1 }}>
            <EditButton
              permissionAction={ACTION_ALL_ACCESS}
              text={
                params.media && params.media.length > 0
                  ? 'Edit page images'
                  : 'Add page images'
              }
              white
              {...props}
              style={{ zIndex: 0 }}
            />
          </Box>
        )}
        hideSidePanel={true}
        allowAddFromYouTube={false}
        showAddTagsOnNewUploads={false}
        addMediaButtonLabel="Add page images"
        hideDateTakenDialog={true}
        autoOpenAddMediaDialog={!params.media || params.media.length === 0}
      />
      <ContentPhotos
        media={params.media}
        mediaDetailOfferEnterTranscription={
          params.contentType === INSTANCE_TYPE_DOCUMENT
        }
        mediaDetailEditDialogTitle={
          params.mediaDetailEditDialogTitle ??
          params.contentType === INSTANCE_TYPE_DOCUMENT
            ? 'Edit page'
            : null
        }
        mediaDetailShowTranscription={
          params.mediaDetailShowTranscription === undefined
            ? params.contentType === INSTANCE_TYPE_DOCUMENT
            : params.mediaDetailShowTranscription
        }
        mediaDetailShowTakenOnDate={
          params.mediaDetailShowTakenOnDate === undefined
            ? params.contentType !== INSTANCE_TYPE_DOCUMENT
            : params.mediaDetailShowTakenOnDate
        }
        mediaDetailHideTags={
          params.mediaDetailHideTags === undefined
            ? false
            : params.mediaDetailHideTags
        }
        mediaDetailDefaultAllowNoTags={
          params.mediaDetailDefaultAllowNoTags === undefined
            ? params.contentType === INSTANCE_TYPE_DOCUMENT
            : params.mediaDetailDefaultAllowNoTags
        }
      />
    </>
  )
}

export default EditableGalleryBlock
