import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { enableMapSet } from 'immer'
//import localStore from 'store'
// import throttle from 'lodash/throttle'

import createRootReducer from './reducers'
import { initialState as initialPageState } from 'src/modules/page/pageSlice'

import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

enableMapSet()

const createStore = () => {
  const browserHistory = createBrowserHistory()

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history: browserHistory })

  //commented out getting local store values, not used atm but left in for reference
  const store = configureStore({
    reducer: createRootReducer(routerReducer),
    middleware: getDefaultMiddleware({ serializableCheck: false }).concat(
      routerMiddleware
    ),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [sentryReduxEnhancer],
    preloadedState: {
      page: {
        ...initialPageState,
        content: {
          ...initialPageState.currentPageState.content,
          // ...localStore.get('sort', {}),
        },
      },
    },
  })

  //commented out setting local store values, not used atm but left in for reference
  // store()
  // .subscribe
  // // throttle(() => {
  // //   // const { sort } = store.getState().page.currentPageState.content
  // //   // localStore.set('sort', { sort })
  // // }, 1000)

  const history = createReduxHistory(store)

  return { store, history }
}

export default createStore
