import {
  FAR_LEFT,
  FAR_TOP,
  INDVDL_NODE_WIDTH,
  INDVDL_NODE_WIDTH_INNER,
  INNER_LEFT,
  INNER_TOP,
} from './constants'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchIndividualPhoto } from '../../public/tree/treeSlice'

/*
 so here we are using this component to display the image for the tree node.  When the tree is privare we just use the photo on the individual
 but when it is public we go and get the photo and store it in this component so it updqtes.  Getting public images is expensive as they are
 signed.
 */
const IndividualTreeImage = ({
  individual,
  preview,
  isSelected,
  mouseOver,
  opacity,
  blurFilter,
  isPublic,
  nodeDirectory,
}) => {
  const [localIndividual, setLocalIndividual] = useState(individual)
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true // Track the mounted state of the component

    if (
      isPublic &&
      localIndividual.hasPhoto === true &&
      !localIndividual.photo
    ) {
      dispatch(fetchIndividualPhoto({ targetId: localIndividual.id })).then(
        ({ payload }) => {
          if (!payload.status || payload.status === 200) {
            if (isMounted) {
              setLocalIndividual({ ...localIndividual, photo: payload.photo })
            }
          }
        }
      )
    }

    return () => {
      isMounted = false // Update the mounted state to false
    }
  }, [localIndividual, dispatch, isPublic])

  const photoIndividual = nodeDirectory[individual.id] || localIndividual

  return (
    <>
      <image
        href={
          photoIndividual.photo
            ? photoIndividual.photo.fileThumbnail
            : photoIndividual.gender === 'F'
            ? '/female-placeholder.png'
            : '/person-placeholder.png'
        }
        width={INDVDL_NODE_WIDTH_INNER}
        height={INDVDL_NODE_WIDTH_INNER}
        x={INNER_LEFT}
        y={INNER_TOP}
        filter={blurFilter}
        opacity={mouseOver ? 1 : opacity}
      />
      {!preview && isSelected && (
        <image
          href="/selected-tick.svg"
          width={20}
          height={20}
          x={FAR_LEFT + INDVDL_NODE_WIDTH - 10}
          y={FAR_TOP - 10}
        />
      )}
    </>
  )
}

export default IndividualTreeImage
