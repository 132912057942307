import Auth from '@aws-amplify/auth'
import { matchPath } from 'react-router-dom'
import { store } from 'src'
import { AuthState } from './Authprovider'

export const SIGNUP_ROUTE = '/signup'
export const INVITE_ROUTE = '/invite/:token'
export const LOGIN_ROUTE = '/login'

const AUTH_ROUTES = [SIGNUP_ROUTE, INVITE_ROUTE, LOGIN_ROUTE]

// call onStateChange() with arg based on user properties
// onStateChange is sometimes Auth.js.handleStateChange() which changes local (Auth.js)
// state which switches out local component
export const checkUserAuthState = async (user, onStateChange) => {
  if (user.challengeName) {
    onStateChange(AuthState.ResetPassword, user)
  } else {
    const { unverified } = await Auth.verifiedContact(user)
    if (unverified.email) {
      onStateChange(AuthState.VerifyContact, { ...user, unverified })
    } else {
      onStateChange(AuthState.SignedIn, user)
    }
  }
}

export const getTreeSlugFromStore = (debug = 'unknown', ignoreNull = false) => {
  const slug = store.getState().auth.authorisedTreeSlug
  if (slug === null && !ignoreNull) {
    console.log(
      `Tried to use authorised tree slug before initiation in ${debug}`
    )
  }
  return slug
}

export const isLoginRoute = path => {
  if (path === '' || path === '/') {
    return true
  }
  for (let route of AUTH_ROUTES) {
    if (matchPath(path, route)) {
      return true
    }
  }
  return false
}
