import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { createPyramid } from 'src/modules/viewer/api/nodeDirectory'
import {
  selectPublicFamilies,
  selectPublicFamilyPyramidLoaded,
  selectPublicNodeDirectory,
  selectSharedByIndividual,
} from './treeSlice'
import { PublicContext } from '../contexts'
import { INSTANCE_TYPE_FAMILY } from 'src/modules/app/links'
import { AncestralFamilies } from 'src/modules/home/AncestralFamilies'
import { generatePublicLinkForObject } from '../../app/links'

const PublicAncestralFamiliesContainer = ({
  trigger,
  openAncestralFamilies,
  setOpenAncestralFamilies,
  displayAsDialog = true,
}) => {
  const nodeDirectory = useSelector(selectPublicNodeDirectory)
  const history = useHistory()
  const sharedByIndividual = useSelector(selectSharedByIndividual)
  const families = useSelector(selectPublicFamilies)
  const familyPyramidLoaded = useSelector(selectPublicFamilyPyramidLoaded)
  let { treeSlug } = useContext(PublicContext)

  const pyramid = useMemo(() => {
    return createPyramid(nodeDirectory, sharedByIndividual, families)
  }, [families, nodeDirectory, sharedByIndividual])

  const handleFamilySelected = familyId => {
    history.push(
      generatePublicLinkForObject(treeSlug, INSTANCE_TYPE_FAMILY, familyId)
    )
  }

  return (
    <AncestralFamilies
      displayAsDialog={displayAsDialog}
      traceLineageTo={sharedByIndividual}
      family={pyramid}
      pyramidLoaded={familyPyramidLoaded}
      onClickName={handleFamilySelected}
      trigger={trigger}
      openAncestralFamilies={openAncestralFamilies}
      setOpenAncestralFamilies={setOpenAncestralFamilies}
    />
  )
}

export default PublicAncestralFamiliesContainer
