import React from 'react'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { styled } from '@mui/material'

import { SelectMedia, Thumbnail } from 'src/modules/photo'
import { useActionDispatcher } from 'src/modules/app'

import { updateArticleThumbnail } from './writeArticleSlice'
import { MEDIA_TYPE_PHOTO } from '../photo'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  backButton: {
    marginBottom: theme.spacing(1),
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    margin: 0,
  },
  home: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  photo: {
    marginLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  uploadPhoto: {
    flexDirection: 'column',
    height: 100,
    width: 100,
    backgroundImage: 'url(/image-default-2.svg)',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
}))

const Icon = styled('img')({})

const SetThumbnail = ({ article, id, presetTargets }) => {
  const classes = useStyles()
  const { thumbnailDraft } = article
  const dispatchUpdateArticleThumbnail = useActionDispatcher(
    updateArticleThumbnail
  )

  const handleSelectCropped = photo => {
    dispatchUpdateArticleThumbnail({
      id: id,
      thumbnail: photo.id,
    })
  }

  const handleRemoveThumnail = () => {
    dispatchUpdateArticleThumbnail({
      id: id,
      thumbnail: null,
    })
  }

  return (
    handleSelectCropped && (
      <SelectMedia
        aspectRatio={1}
        onSelect={() => {}}
        onSelectCropped={handleSelectCropped}
        cropAfterSelect={true}
        mediaType={MEDIA_TYPE_PHOTO}
        presetTargets={presetTargets}
        trigger={props =>
          thumbnailDraft?.original ? (
            <Thumbnail
              className={classes.photo}
              size="small"
              file={thumbnailDraft.fileThumbnail}
              withEditMedia
              onClear={handleRemoveThumnail}
              {...props}
            />
          ) : (
            <div className={classes.uploadPhoto} {...props}>
              <Icon src="/icon-photo-2.svg" />
              <Typography variant="subtitle5">Override thumbnail</Typography>
            </div>
          )
        }
      />
    )
  )
}

export default SetThumbnail
