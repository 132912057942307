import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconButton, Box, useMediaQuery } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar } from 'src/modules/ui'
import MobileNavBar from 'src/modules/app/MobileNavBar'
import { setLastLocation } from '../app/appSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Logo, useStyles } from '../app/NavBar'
import { useEmbed } from './hooks'
import { GradientButton } from './PublicNavBar'
import { Typography } from '../ui'
import { getQueryParams } from '../../utils'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import ShareIcon from '@mui/icons-material/Share'
import { useShare } from '../app'
import { PublicContext } from './contexts'
import { generatePublicLinkWithSiteUrl } from '../app/links'
import { ga4Events } from '../analytics/AnalyticsUtils'

const PublicBlogNavBar = ({
  baseUrl,
  title,
  theme,
  shouldShowImage,
  url,
  backgroundImage,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug
  const handleShare = useShare(
    true,
    generatePublicLinkWithSiteUrl(`/blog/${publicTreeSlug}`)
  )

  const mobileBreakpoint = useMediaQuery(theme =>
    theme.breakpoints.down('1080px')
  )

  const { blogPreview } = getQueryParams(location)

  const embed = useEmbed()

  const history = useHistory()
  const dispatch = useDispatch()

  const shareBlog = e => {
    e.preventDefault()
    handleShare()
  }

  useEffect(() => {
    const pathname = history.location.pathname
    return () => {
      dispatch(setLastLocation(pathname))
    }
  }, [dispatch, history.location.pathname])

  const routes = [
    {
      title: 'Home',
      to: url,
    },
    {
      title: 'About Me',
      to: `${url}/about-me`,
    },
  ]

  return (
    <>
      {!embed && (
        <AppBar
          backgroundColor={theme.palette.grey.main}
          shouldShowImage={shouldShowImage}
          backgroundImageOverride={`url("${backgroundImage}")`}
          leftSide={
            <>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <MobileNavBar
                  isPublic={true}
                  homeUrl={url}
                  routes={routes}
                  trigger={navBarTrigger => (
                    <IconButton
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={navBarTrigger.onClick}
                      color="inherit"
                      sx={{ mr: 1 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                />
              </Box>
              {mobileBreakpoint ? (
                <Link to={`${baseUrl}/`}>
                  <Logo alt="weare.xyz" src="/logo.png" />
                </Link>
              ) : (
                <div style={{ maxWidth: '50vw' }}>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      whiteSpace: 'nowrap',
                      fontSize: { xs: 16, md: '3rem' },
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    variant="h1"
                  >
                    {title}
                  </Typography>
                </div>
              )}
            </>
          }
          rightSide={
            <>
              {!blogPreview && (
                <>
                  {!location?.state?.isDraft &&
                    routes.map(route => (
                      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Link
                          to={route.to}
                          className={classes.navBarLinkStyle}
                          style={{ color: theme?.palette?.primary?.main }}
                        >
                          {route.title}
                        </Link>
                      </Box>
                    ))}{' '}
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 0.2 }}>
                    <Link
                      onClick={e => shareBlog(e)}
                      className={classes.navBarLinkStyle}
                      style={{
                        color: theme?.palette?.primary?.main,
                        textDecoration: 'underline',
                      }}
                    >
                      Share Blog
                    </Link>
                    <ShareIcon fontSize="small" sx={{ mt: 0.2 }} />
                  </Box>
                </>
              )}

              {!mobileBreakpoint ? (
                <Box sx={{ display: { xs: 'none', md: 'block' }, ml: 2 }}>
                  <GradientButton
                    text="Find Out More"
                    gaEvent={ga4Events.PUBLIC_HEADER_BUTTON_FIND_OUT_MORE}
                  />
                </Box>
              ) : null}
            </>
          }
        />
      )}
    </>
  )
}

export default PublicBlogNavBar
