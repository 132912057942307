import {
  fixIntermarriage,
  parentsAndSiblingsOfIndividualAsGraphNodes,
  processIndividualsAndUnionsToGraphNodes,
} from './graphNode'
import {
  exploreDownwards,
  parentsSiblingsAndChildrenOfIndividual,
} from './nodeDirectory'
import { d3TreeLayout, setVerticalLinkOffsets } from './layout'
import { Link } from './graphNode'
import {
  centreParentNode,
  findGraphNode,
  makeXCoordinates0based,
  minMaxX,
  shiftTreeRightOfOtherTree,
  shiftTreeUp,
  treeHeight,
} from './graphOps'

export const LEFT_SIDE = 'LEFT'
export const RIGHT_SIDE = 'RIGHT'

/**
 * Given an individual node, and the directory of all nodes, produce the default
 * set of ancestors and descendants that give a good initial exploration of the
 * individual's family.
 */
export const createExploredIndividualGraph = (
  exploredIndividualNode,
  nodeDirectory,
  doAddGreatGrandParents = false
) => {
  let rootIDs = [
    exploredIndividualNode.bioFather,
    exploredIndividualNode.bioMother,
  ].filter(id => !!id)

  // If no mother and father known, start from individual
  if (rootIDs.length === 0) {
    rootIDs = [exploredIndividualNode.id]
  }

  // For display and positioning, define core lineage individuals as root individuals,
  // explored individual and their spouse, plus all their genetic offspring
  const coreLineageIDs = new Set(rootIDs)
  coreLineageIDs.add(exploredIndividualNode.id)

  // Arbitrarily choose one individual from which to start. Whether it's
  // biological mother or father, the other one will be pulled in.
  const rootIndividualNode = nodeDirectory[rootIDs[0]]

  const centralVisibleNodes = exploreDownwards(
    nodeDirectory,
    rootIndividualNode,
    3,
    coreLineageIDs
  )
  let centralGraphNodes = processIndividualsAndUnionsToGraphNodes(
    centralVisibleNodes,
    coreLineageIDs
  )
  const centralRoot = findGraphNode(centralGraphNodes, rootIDs[0])

  const exploredIndividualGraphNode = findGraphNode(
    centralGraphNodes,
    exploredIndividualNode.id
  )
  if (exploredIndividualGraphNode) {
    centralGraphNodes = removeGrandNiecesAndNephews(
      centralRoot,
      exploredIndividualGraphNode,
      centralGraphNodes
    )
    centralGraphNodes.push(
      ...exploredIndividualGraphNode.addStepChildren(
        nodeDirectory,
        exploredIndividualNode
      )
    )
  } else {
    console.error(
      `explore.js createExploredIndividualGraph(): findGraphNode(graphNodes: '${centralGraphNodes}', exploredIndividualNode') returned null. exploredIndividualNode:`,
      exploredIndividualNode
    )
  }

  const newGraphNodes = fixIntermarriage(centralRoot, centralGraphNodes)
  centralGraphNodes.push(...newGraphNodes)

  const existingGraphNodes = new Set(centralGraphNodes.map(n => n.id))
  existingGraphNodes.delete(centralRoot.id)

  let [fatherSide, fatherRoot] = buildParentSide(
    nodeDirectory,
    exploredIndividualNode.bioFather,
    LEFT_SIDE,
    existingGraphNodes
  )
  let [motherSide, motherRoot] = buildParentSide(
    nodeDirectory,
    exploredIndividualNode.bioMother,
    RIGHT_SIDE,
    existingGraphNodes
  )

  let [centralNodes, centralLinks] = d3TreeLayout(
    centralGraphNodes,
    centralRoot
  )
  const [fatherNodes, fatherLinks] = d3TreeLayout(fatherSide, fatherRoot)
  fatherNodes.length && shiftTreeUp(fatherNodes)

  const [motherNodes, motherLinks] = d3TreeLayout(motherSide, motherRoot)
  motherNodes.length && shiftTreeUp(motherNodes)

  // Manually centre parents directly over explored individual
  if (
    exploredIndividualGraphNode &&
    centralRoot.id !== exploredIndividualGraphNode.id
  ) {
    let [sourceIdx, targetIdx] = centralRoot.findIndividualLinkSourceTarget(
      exploredIndividualGraphNode
    )
    const targetIndividualX = exploredIndividualGraphNode.leftX + targetIdx
    const sourceUnionX = centralRoot.leftX + sourceIdx + 0.5

    const diff = targetIndividualX - sourceUnionX
    centralRoot.x += diff

    if (centralRoot.hasMultiplePartnerships) {
      const linksFromRoot = centralLinks.filter(
        l => l.sourceGraphNode.id === centralRoot.id
      )
      // reset links
      linksFromRoot.forEach(link => (link.verticalOffset = 0))
      setVerticalLinkOffsets(centralRoot, linksFromRoot)
    }
  }

  let minimumMove = 0
  if (fatherRoot) {
    minimumMove = fatherRoot.rightX - centralRoot.rightX
  }
  shiftTreeRightOfOtherTree(centralNodes, fatherNodes, undefined, minimumMove)
  centralNodes.push(...fatherNodes)

  shiftTreeRightOfOtherTree(motherNodes, centralNodes, centralRoot.x)
  centralNodes.push(...motherNodes)
  const links = centralLinks.concat(fatherLinks).concat(motherLinks)

  let exploredIndividualX
  if (exploredIndividualGraphNode) {
    // For each parent side, create a new link to be drawn, and ensure the
    // parent's children IDs are consistent with the central root's ID.
    // Additionally, set parent visibility to reflect all visible nodes
    // rather than just the central visible nodes.
    exploredIndividualX = exploredIndividualGraphNode.individualXCoord(
      exploredIndividualNode
    )
  }

  if (fatherRoot) {
    const fatherLink = new Link(fatherRoot, centralRoot)
    links.push(fatherLink)
    fatherLink.verticalOffset = findSiblingLinkVerticalOffset(
      fatherLinks,
      fatherRoot,
      fatherLink.sourceIdx
    )

    fatherRoot.children.push(centralRoot.id)
    if (exploredIndividualX && !doAddGreatGrandParents) {
      centreParentNode(fatherRoot, exploredIndividualX, LEFT_SIDE)
    }
    centralRoot.centralInnerNode.setParentsAsVisible(fatherRoot)
  }
  if (motherRoot) {
    const motherLink = new Link(motherRoot, centralRoot)
    links.push(motherLink)
    motherLink.verticalOffset = findSiblingLinkVerticalOffset(
      motherLinks,
      motherRoot,
      motherLink.sourceIdx
    )

    motherRoot.children.unshift(centralRoot.id)
    if (!doAddGreatGrandParents) {
      centreParentNode(motherRoot, centralRoot.x, RIGHT_SIDE)
    }
    centralRoot.centralInnerNode.setParentsAsVisible(motherRoot)
  }

  if (doAddGreatGrandParents) {
    if (fatherRoot) {
      addGreatGrandParents(
        nodeDirectory,
        fatherRoot,
        centralNodes,
        links,
        centralRoot.leftX,
        LEFT_SIDE
      )
    }
    if (motherRoot) {
      addGreatGrandParents(
        nodeDirectory,
        motherRoot,
        centralNodes,
        links,
        centralRoot.rightX,
        RIGHT_SIDE
      )
    }
  }

  makeXCoordinates0based(centralNodes)
  return [centralNodes, links, treeHeight(centralNodes)]
}

/**
 * Create simple tree of individual's parents, siblings, and children
 */
export const createAtomicFamilyGraph = (selectedIndividual, nodeDirectory) => {
  const [visibleNodes] = parentsSiblingsAndChildrenOfIndividual(
    nodeDirectory,
    selectedIndividual
  )
  const graphNodes = processIndividualsAndUnionsToGraphNodes(visibleNodes)
  const rootId =
    selectedIndividual.bioFather ||
    selectedIndividual.bioMother ||
    selectedIndividual.id
  const root = findGraphNode(graphNodes, rootId)
  const [nodes, links] = d3TreeLayout(graphNodes, root)
  return [nodes, links, 3]
}

/**
 * Take one parent, find their parents, and from those parents build the
 * GraphNode tree of their descendants.
 *
 * Remove the GraphNode corresponding to the parent, plus any descendants,
 * as these will be drawn by the central tree.
 */
function buildParentSide(nodeDirectory, parentId, side, existingIDs) {
  if (!parentId) {
    return []
  }
  const parentNode = nodeDirectory[parentId]

  // The grandparent can be either father or mother as available
  const grandParentIDs = [parentNode.bioFather, parentNode.bioMother].filter(
    id => !!id
  )
  const grandparentRootId = grandParentIDs[0]
  if (!grandparentRootId) {
    return []
  }
  const grandparentNode = nodeDirectory[grandparentRootId]
  const coreLineageIDs = new Set(grandParentIDs)

  let parentSide = exploreDownwards(
    nodeDirectory,
    grandparentNode,
    2,
    coreLineageIDs
  )
  parentSide = processIndividualsAndUnionsToGraphNodes(
    parentSide,
    coreLineageIDs,
    side
  )

  const parentRoot = findGraphNode(parentSide, grandparentRootId)

  const parentGraphNode = parentSide.find(graphNode =>
    graphNode.individualIDs.has(parentId)
  )

  /*
  parentSide []
explore.js:279 parentRoot undefined
explore.js:280 parentId px25lh64259x
explore.js:285 parentGraphNode undefined
explore.js:276 parentSide []
explore.js:279 parentRoot undefined
explore.js:280 parentId px25lh64259x
explore.js:285 parentGraphNode undefined
   */

  // Remove parent node and descendants
  const idsToRemove = new Set([parentGraphNode.id, ...parentGraphNode.children])
  parentSide = parentSide.filter(graphNode => !idsToRemove.has(graphNode.id))
  // Remove parent node from parent root's children
  parentRoot.children = parentRoot.children.filter(
    childID => childID !== parentGraphNode.id
  )
  const newGraphNodes = fixIntermarriage(parentRoot, parentSide, existingIDs)
  parentSide.push(...newGraphNodes)

  return [parentSide, parentRoot]
}

function addGreatGrandParents(
  nodeDirectory,
  rootGraphNode,
  graphNodes,
  links,
  limitX,
  side
) {
  // Find the inner node with the explored individual's parents (not step parents)
  const centralInnerNode = rootGraphNode.innerNodes.find(n => n.isLineageRoot)

  const [father, mother] = [
    centralInnerNode?.malePartner,
    centralInnerNode?.femalePartner,
  ]
  const allSiblings = [rootGraphNode]

  if (father) {
    const [fatherParentRootGraphNode, fatherSiblingGraphNodes] =
      parentsAndSiblingsOfIndividualAsGraphNodes(
        nodeDirectory,
        father,
        rootGraphNode
      )
    if (fatherParentRootGraphNode) {
      allSiblings.push(...fatherSiblingGraphNodes)
      rootGraphNode.centralInnerNode.setParentsAsVisible(
        fatherParentRootGraphNode
      )

      fatherParentRootGraphNode.y = rootGraphNode.y - 1
      fatherParentRootGraphNode.x = side === LEFT_SIDE ? limitX - 2 : limitX
      graphNodes.push(fatherParentRootGraphNode)

      const verticalOffset = side === LEFT_SIDE ? -1 : 1

      let siblingX = rootGraphNode.leftX - 0.5
      for (const siblingGraphNode of fatherSiblingGraphNodes.reverse()) {
        siblingGraphNode.y = rootGraphNode.y
        siblingGraphNode.x = siblingX
        siblingX -= 1

        const link = new Link(fatherParentRootGraphNode, siblingGraphNode)
        link.verticalOffset -= verticalOffset
        links.push(link)
        graphNodes.push(siblingGraphNode)
      }
      const link = new Link(fatherParentRootGraphNode, rootGraphNode)
      link.verticalOffset -= verticalOffset
      links.push(link)
    }
  }

  if (mother) {
    const [motherParentRootGraphNode, motherSiblingGraphNodes] =
      parentsAndSiblingsOfIndividualAsGraphNodes(
        nodeDirectory,
        mother,
        rootGraphNode
      )
    if (motherParentRootGraphNode) {
      allSiblings.push(...motherSiblingGraphNodes)
      rootGraphNode.centralInnerNode.setParentsAsVisible(
        motherParentRootGraphNode
      )

      motherParentRootGraphNode.y = rootGraphNode.y - 1
      motherParentRootGraphNode.x = side === LEFT_SIDE ? limitX : limitX + 2
      graphNodes.push(motherParentRootGraphNode)

      let siblingX = rootGraphNode.rightX + 0.5
      for (const siblingGraphNode of motherSiblingGraphNodes) {
        siblingGraphNode.y = rootGraphNode.y
        siblingGraphNode.x = siblingX
        siblingX += 1
        links.push(new Link(motherParentRootGraphNode, siblingGraphNode))
        graphNodes.push(siblingGraphNode)
      }
      links.push(new Link(motherParentRootGraphNode, rootGraphNode))
    }
  }

  // Shift siblings to centre
  const [minX, maxX] = minMaxX(allSiblings)
  let diff
  if (side === LEFT_SIDE) {
    diff = limitX - maxX
  } else {
    diff = limitX - minX
  }
  for (let siblingGraphNode of allSiblings) {
    siblingGraphNode.x += diff
  }
}

function removeGrandNiecesAndNephews(
  parentsGraphNode,
  exploredIndividualGraphNode,
  visibleNodes
) {
  const visibleNodeDirectory = new Map()
  visibleNodes.forEach(i => {
    visibleNodeDirectory.set(i.id, i)
  })

  const cutNodeIDs = new Set()
  for (const childGraphNodeID of parentsGraphNode.children) {
    if (childGraphNodeID === exploredIndividualGraphNode.id) {
      continue
    }
    const siblingGraphNode = visibleNodeDirectory.get(childGraphNodeID)
    for (let cousinGraphNodeID of siblingGraphNode?.children || []) {
      const cousinGraphNode = visibleNodeDirectory.get(cousinGraphNodeID)
      if (!cousinGraphNode) {
        continue
      }
      for (let grandNieceOrNephewID of cousinGraphNode.children) {
        cutNodeIDs.add(grandNieceOrNephewID)
      }
      cousinGraphNode.hideChildren()
    }
  }

  return visibleNodes.filter(n => !cutNodeIDs.has(n.id))
}

const findSiblingLinkVerticalOffset = (parentLinks, parentRoot, sourceIdx) => {
  const sibling = parentLinks.find(
    l => l.sourceIdx === sourceIdx && l.sourceGraphNode.id === parentRoot.id
  )
  return sibling?.verticalOffset || 0
}
