import MapboxDraw from '@mapbox/mapbox-gl-draw'
import {
  FeatureCollection,
  Feature,
  GeoJsonProperties,
  Polygon,
  Position,
} from 'geojson'

import {
  GeoJSONSource,
  ImageSource,
  Layer,
  LayerSpecification,
  ImageSourceSpecification,
  LngLat,
  LngLatBounds,
  Map,
  Marker,
} from 'mapbox-gl'
import rhumbDistance from '@turf/rhumb-distance'
import rhumbBearing from '@turf/rhumb-bearing'
import rhumbDestination from '@turf/rhumb-destination'
import pointToLineDistance from '@turf/point-to-line-distance'
import center from '@turf/center'
import {
  feature as turfFeature,
  lineString as turfLineString,
  point as turfPoint,
} from '@turf/helpers'

import { TxCenterExported } from '../photo/TxRectModeDemo'

import { LayerImageWeAreLink } from './MapTypes'

const DELAY_BETWEEN_SNAP_STEPS = false

export const IMAGE_CUSTOM_MARKER = 'custom-marker'

export const TXRECT_MODE_OPTS = {
  //featureId: featureId, // required

  canTrash: true,

  canScale: true,
  canRotate: false,
  //canRotate: true,

  singleRotationPoint: true,
  rotationPointRadius: 1.2, // extend rotation point outside polygon

  rotatePivot: TxCenterExported.Center, // rotate around center
  scaleCenter: TxCenterExported.Opposite, // scale around opposite vertex

  canSelectFeatures: true,
}

export type ArrayOfTwoNumbers = [number, number] // | Position
export type ArrayOfTwoOrMoreNumbers = [number, number, ...number[]] | Position
export type FourCoordinates = [
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers
]
export type FiveCoordinates = [
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers,
  ArrayOfTwoNumbers
]

export type RectCoordinates = FourCoordinates | FiveCoordinates

export const getImageSourceId = (imageId: string) => {
  return `image-source-${imageId}`
}
export const getImageLayerId = (imageId: string) => {
  return `image-layer-${imageId}`
}

export const getViewOnlyImageMouseSourceId = (imageId: string) => {
  return `image-mouse-source-${imageId}`
}

export const getViewOnlyImageMouseLayerId = (imageId: string) => {
  return `image-mouse-layer-${imageId}`
}

export const getImageBorderRectangleId = (imageId: string) => {
  return `image-border-rect-${imageId}`
}
export const getImageBorderRectangleOutlineSourceId = (imageId: string) => {
  return `image-border-rect-outline-source-${imageId}`
}
export const getImageBorderRectangleOutlineLayerId = (imageId: string) => {
  return `image-border-rect-outline-layer-${imageId}`
}
export const getImageReferencePointsSourceId = (imageId: string) => {
  return `image-reference-points-source-${imageId}`
}
export const getImageReferencePointsLayerId = (imageId: string) => {
  return `image-reference-points-layer-${imageId}`
}
export const getImageGlobeReferencePointsSourceId = (imageId: string) => {
  return `image-globe-reference-points-source-${imageId}`
}
export const getImageGlobeReferencePointsLayerId = (imageId: string) => {
  return `image-globe-reference-points-layer-${imageId}`
}
export const featureIsImageBorderRectangle = (feature: Feature) => {
  if (
    feature &&
    feature.id &&
    typeof feature.id === 'string' &&
    feature.id.startsWith(`image-border-rect-`)
  ) {
    return true
  }
}
export const mapboxFeatureIsImageBorderRectangle = (feature: Feature) => {
  console.debug(`mapboxFeatureIsImageBorderRectangle(): called with: `, feature)
  if (
    feature &&
    feature.properties &&
    feature.properties.id &&
    typeof feature.properties.id === 'string' &&
    feature.properties.id.startsWith(`image-border-rect-`)
  ) {
    return true
  }
}
export const imageBorderRectangleIdToImageId = (
  imageBorderRectangleId: string
) => {
  if (
    imageBorderRectangleId &&
    imageBorderRectangleId.startsWith(`image-border-rect-`)
  ) {
    return imageBorderRectangleId.substring('image-border-rect-'.length)
  }
}

// export const debugMapLayers = (mapinst: Map) => {
//   console.debug(`debugMapLayers(): listing map layers:`)
//   mapinst.getStyle().layers.forEach(layer => {
//     console.debug(`debugMapLayers():   ${layer.id}`)
//   })
// }

/**
 * Loops around the given dict of MapLayerImageInfos, finds the Mapbox Source for each and gets the current corner coordinates.
 *
 * If a Mapbox source is not found for an imageId that MapLayerImageInfo item is removed from the given list
 */
export const updateMapLayerImageCoordinates = (
  mapinst: Map,
  mapLayerImageLinks: LayerImageWeAreLink[]
) => {
  const debug = false

  if (debug)
    console.debug(
      `MapImageHelpers.updateMapLayerImageCoordinates(): called with mapLayerImageLinks:`,
      mapLayerImageLinks
    )

  // the existing dict is stored in react state and this can't change it so make a copy
  const updatedMapLayerImageLinks = mapLayerImageLinks.flatMap(
    mapLayerImageLink => {
      if (debug)
        console.debug(
          `MapImageHelpers.updateMapLayerImageCoordinates(): checking mapLayerImageLink:`,
          mapLayerImageLink
        )

      const imageId = mapLayerImageLink.target?.id
      if (!imageId) {
        if (debug)
          console.debug(
            `MapImageHelpers.updateMapLayerImageCoordinates(): called with mapLayerImageLinks containing link with null target id, skipping:`,
            mapLayerImageLink
          )
        return null
      }
      //const imageLayerId = getImageLayerId(imageId)
      const imageSourceId = getImageSourceId(imageId)

      const imageSource = mapinst.getSource(
        imageSourceId
      ) as ImageSourceSpecification
      if (imageSource) {
        if (debug)
          console.debug(
            `MapImageHelpers.updateMapLayerImageCoordinates(): found imageSource for imageId '${imageId}':`,
            imageSource
          )
        if (imageSource.coordinates) {
          const newCoords = imageSource.coordinates.slice(
            0,
            4
          ) as FourCoordinates
          const imageLayerId = getImageLayerId(imageId)
          const opacity =
            ((mapinst.getPaintProperty(
              imageLayerId,
              'raster-opacity'
            ) as number) ?? 0.5) * 100
          const clonedMapLayerImageLink = {
            ...mapLayerImageLink, // includes target
            opacityOnMap: opacity,
          }

          clonedMapLayerImageLink.target.coordinates = newCoords

          if (debug)
            console.debug(
              `MapImageHelpers.updateMapLayerImageCoordinates(): updated coordinates for imageId '${imageId}':`,
              clonedMapLayerImageLink
            )
          return clonedMapLayerImageLink
        } else {
          console.error(
            `MapImageHelpers.updateMapLayerImageCoordinates(): Source for imageId '${imageId}' has no coordinates:`,
            imageSource
          )

          return null
        }
      } else {
        //remove from list
        if (debug)
          console.debug(
            `MapImageHelpers.updateMapLayerImageCoordinates(): could not find Source for imageSourceId '${imageSourceId}' - removing it from updatedMapLayerImageLinks`
          )
        return null
      }
    }
  )

  if (debug)
    console.debug(
      `MapImageHelpers.updateMapLayerImageCoordinates(): returning updatedMapLayerImageLinks:`,
      updatedMapLayerImageLinks
    )
  return updatedMapLayerImageLinks
}

export const degreesToRads = (deg: number) => (deg * Math.PI) / 180.0

/**
 * Uses turfjs' rhumBearing to get the bearing between two of a set of coordinates.
 *
 * Cover up tiny precision issues by rounding if the bearing is fractionally close to 0 or 90.
 *
 */
export const getRoundedBearingBetweenCorners = (
  newBorderRectCoords: number[][],
  cornerA: number,
  cornerB: number
) => {
  // if (newBorderRectCoords[cornerA][1] === newBorderRectCoords[cornerB][1]) {
  //   return newBorderRectCoords[cornerA][0] < newBorderRectCoords[cornerB][0]
  //     ? 90
  //     : 180
  // }

  //use turfjs' rhumBearing() not bearing().
  //using bearing(), between [-96.1214810723086,37.40396907897599] and [-94.8662146675395,37.40396907897599] is 89.61874659484722 and not 90

  let bearingBetween0and1 = rhumbBearing(
    newBorderRectCoords[cornerA],
    newBorderRectCoords[cornerB]
  )
  if (
    bearingBetween0and1 > 89.99999999 &&
    bearingBetween0and1 < 90.00000000001
  ) {
    bearingBetween0and1 = 90
  } else if (
    (bearingBetween0and1 > 359.99999999 ||
      bearingBetween0and1 > -0.00000000001) &&
    bearingBetween0and1 < 0.00000000001
  ) {
    bearingBetween0and1 = 0
  }
  return bearingBetween0and1
}

export const getRoundedBearingBetween0and1 = (
  newBorderRectCoords: number[][]
) => {
  return getRoundedBearingBetweenCorners(newBorderRectCoords, 0, 1)
}

const calcPercentageWithinRect = (
  borderRectCoords: number[][],
  pointCoords: number[],
  corner0toCorner1LengthMeters: number,
  corner0toCorner3LengthMeters: number
) => {
  const debug = false
  if (debug) console.debug(`MapImageHelpers.calcPercentageWithinRect(): called`)

  const line01 = turfLineString([borderRectCoords[0], borderRectCoords[1]])

  const minDistanceToLine01metres = pointToLineDistance(pointCoords, line01, {
    units: 'metres',
  })

  const line03 = turfLineString([borderRectCoords[0], borderRectCoords[3]])

  const minDistanceToLine03metres = pointToLineDistance(pointCoords, line03, {
    units: 'metres',
  })

  const percentage0to1 =
    minDistanceToLine03metres / corner0toCorner1LengthMeters
  const percentage0to3 =
    minDistanceToLine01metres / corner0toCorner3LengthMeters
  if (debug)
    console.debug(
      `MapImageHelpers.calcPercentageWithinRect(): line 0-1 is ${corner0toCorner1LengthMeters}m long, point is ${minDistanceToLine03metres}m from start: ${
        percentage0to1 * 100
      }%.`
    )

  if (debug)
    console.debug(
      `MapImageHelpers.calcPercentageWithinRect(): line 0-3 is ${corner0toCorner3LengthMeters}m long, point is ${minDistanceToLine01metres}m from start: ${
        percentage0to3 * 100
      }%.`
    )

  return [percentage0to1, percentage0to3]
}

/** Get image border rect Feature from MapboxDraw based on its id */
export const getImageBorderRectFeature = (
  mapboxDraw: MapboxDraw,
  imageId: string
): Feature<Polygon> | undefined => {
  const imageBorderRectangleId = getImageBorderRectangleId(imageId)
  const imageBorderFeature = mapboxDraw.get(imageBorderRectangleId)
  // console.debug(
  //   `getImageBorderRectFeature(): existing image border`,
  //   imageBorderFeature
  // )
  return imageBorderFeature as Feature<Polygon>
}

export const getSideLengthsInMetresFromFeature = (
  mapinst: Map,
  imageId: string,
  mapboxDraw: MapboxDraw,
  borderRectFeature?: Feature<Polygon>
) => {
  if (!borderRectFeature) {
    borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
  }
  if (!borderRectFeature) {
    console.error(
      `getSideLengthsInMetresFromFeature(): could not find borderRect feature for imageId: ${imageId}`
    )
    return
  }
  // console.debug(
  //   `getSideLengthsInMetresFromFeature(): got borderRectFeature for imageId: ${imageId}`,
  //   borderRectFeature
  // )
  const imageWidthPx = getImageProperty({
    propName: 'imageWidthPx',
    imageId,
    borderRectFeature,
    mapinst,
  })
  const imageHeightPx = getImageProperty({
    propName: 'imageHeightPx',
    imageId,
    borderRectFeature,
    mapinst,
  })
  const metersPerPixel = getImageProperty({
    propName: 'metersPerPixel',
    imageId,
    borderRectFeature,
    mapinst,
  })

  const corner0toCorner1LengthMeters = imageWidthPx * metersPerPixel
  const corner0toCorner3LengthMeters = imageHeightPx * metersPerPixel
  return [corner0toCorner1LengthMeters, corner0toCorner3LengthMeters]
}

/**
 * Called after an image reference point is dragged
 */
export const calcPercentageWithinImageLayer = (
  mapinst: Map,
  imageId: string,
  pointCoords: number[],
  corner0toCorner1LengthMeters: number,
  corner0toCorner3LengthMeters: number
): number[] | undefined => {
  const imageSourceId = getImageSourceId(imageId)
  const imageSource = mapinst.getSource(
    imageSourceId
  ) as ImageSourceSpecification

  if (!imageSource) {
    console.error(
      `calcPercentageWithinImageLayer(): imageSource for imageId ${imageId} not found`
    )
    return
  }
  if (imageSource.coordinates) {
    const imageCornerCoords = imageSource.coordinates.slice(0, 4)
    // console.debug(
    //   `calcPercentageWithinImageLayer(): got imageCornerCoords from imageSource.coordinates for imageId ${imageId}:`,
    //   imageCornerCoords
    // )

    const res = calcPercentageWithinRect(
      imageCornerCoords,
      pointCoords,
      corner0toCorner1LengthMeters,
      corner0toCorner3LengthMeters
    )
    // console.debug(
    //   `calcPercentageWithinImageLayer(): calcPercentageWithinRect() returned res:`,
    //   res
    // )
    return res
  } else {
    // console.debug(
    //   `calcPercentageWithinImageLayer(): imageSource.coordinates not set in imageSource for imageId '${imageId}' - source:`,
    //   imageSource
    // )
  }
}

/* Given a set of coordinates, find the outside edges */
export const getOutsideEdges = (coordinates: number[][]) => {
  const min = (a: number | undefined | null, b: number) => {
    // avoid calling Math.min() with a null/undefined
    if (a === undefined || a === null) {
      return b
    }
    if (b === undefined || b === null) {
      return a
    }
    return Math.min(a, b)
  }

  const max = (a: number | undefined | null, b: number) => {
    // avoid calling Math.min() with a null/undefined
    if (a === undefined || a === null) {
      return b
    }
    if (b === undefined || b === null) {
      return a
    }
    return Math.max(a, b)
  }

  let minX!: number, maxX!: number, minY!: number, maxY!: number

  coordinates.forEach(([x, y]) => {
    minX = min(minX, x)
    maxX = max(maxX, x)
    minY = min(minY, y)
    maxY = max(maxY, y)
  })

  return [minX, maxX, minY, maxY]
}

// only used for scaling up image by mouse wheel + shift
// will be unused when that uses mouse pointer location as scale point
export const getCenterCoords = (rectCoords: number[][]) => {
  // const [minX, maxX, minY, maxY] = getOutsideEdges(rectCoords)

  // const width = maxX - minX
  // const height = maxY - minY

  // // console.debug(
  // //   `getCenterCoords(): outer box width: ${width}, height: ${height}`
  // // )

  // const centerCoords = [minX + width / 2, minY + height / 2]
  // return centerCoords

  const feature = turfFeature({
    type: 'Polygon',
    coordinates: [rectCoords],
  })

  return center(feature).geometry.coordinates
}

export const getGeojsonFromSource = (
  geoJsonSource: GeoJSONSource
): undefined | GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  if ('_data' in geoJsonSource) {
    return geoJsonSource._data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
  }
}

const getImageReferencePointsSource = (
  mapinst: Map,
  imageId: string
): GeoJSONSource | undefined => {
  const imageReferencePointsSourceId = getImageReferencePointsSourceId(imageId)
  return mapinst.getSource(imageReferencePointsSourceId) as GeoJSONSource
}

export const getImageReferencePointsGeojson = (
  mapinst: Map,
  imageId: string
) => {
  const source = getImageReferencePointsSource(mapinst, imageId)
  if (source) {
    const geojson = getGeojsonFromSource(source)

    return geojson
  }
}

//image properties are stored in two places: the layer's metadata and separately the border rect feature's properties
//this section is an attempt to consolidate them into one place in a phased, non-breaking manner
//one api - getImageProperty/updateImageProperty - accesses either place depending on which of the two lists the property name is in.
//The goal is to move everything to image layer metadata as sometimes draw is not available.
const IMAGE_PROPERTIES_LAYER_METADATA = [
  'referencePoint1GlobalCoords',
  'referencePoint2GlobalCoords',
  'referencePoint1Percentage0to1',
  'referencePoint1Percentage0to3',
  'referencePoint2Percentage0to1',
  'referencePoint2Percentage0to3',
  'globalReferencePoint1',
  'globalReferencePoint2',
]
const IMAGE_PROPERTIES_BORDER_RECT_FEATURE = [
  'imageWidthPx',
  'imageHeightPx',
  'centerCoords',
  'metersPerPixel',
  'bearingCorner0toCorner1',
]
// const IMAGE_PROPERTIES_LAYER_METADATA = [
//   'referencePoint1GlobalCoords',
//   'referencePoint2GlobalCoords',
//   'referencePoint1Percentage0to1',
//   'referencePoint1Percentage0to3',
//   'referencePoint2Percentage0to1',
//   'referencePoint2Percentage0to3',
//   'globalReferencePoint1',
//   'globalReferencePoint2',
//   'imageWidthPx',
//   'imageHeightPx',
//   'centerCoords',
//   'metersPerPixel',
//   'bearingCorner0toCorner1',
// ]
// const IMAGE_PROPERTIES_BORDER_RECT_FEATURE: string[] = []
export const getImageProperty = ({
  propName,
  imageId,
  borderRectFeature,
  mapboxDraw,
  imageLayer,
  mapinst,
}: {
  propName: string
  imageId?: string
  borderRectFeature?: Feature
  mapboxDraw?: MapboxDraw
  imageLayer?: LayerSpecification
  mapinst?: Map
}): any | undefined => {
  const debug = false
  if (IMAGE_PROPERTIES_BORDER_RECT_FEATURE.includes(propName)) {
    if (!borderRectFeature) {
      if (!mapboxDraw) {
        console.error(
          `getImageProperty(): called with border rect feature prop '${propName}' but no mapboxDraw.`
        )
        return
      }
      if (!imageId) {
        console.error(
          `getImageProperty(): called for with border rect feature prop '${propName}' but no imageId.`
        )
        return
      }

      borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
    }
    if (borderRectFeature && borderRectFeature.properties) {
      return (
        borderRectFeature.properties[propName] ??
        borderRectFeature.properties[`user_${propName}`]
      )
    } else {
      if (debug)
        console.debug(
          `getImageProperty(): called with border rect feature prop '${propName}' but could not find borderRectFeature or it has no properties`,
          borderRectFeature
        )
    }
  } else if (IMAGE_PROPERTIES_LAYER_METADATA.includes(propName)) {
    if (!imageLayer) {
      if (!mapinst) {
        console.error(
          `getImageProperty(): called for IMAGE_PROPERTIES_LAYER_METADATA prop with no imageLayer or mapinst`
        )
        return
      }
      if (!imageId) {
        console.error(
          `getImageProperty(): called for IMAGE_PROPERTIES_LAYER_METADATA prop with no imageLayer or imageId`
        )
        return
      }
      const imageLayerId = getImageLayerId(imageId)
      imageLayer = mapinst.getLayer(imageLayerId)
    }
    if (imageLayer && imageLayer.metadata) {
      const metadata: { [key: string]: any } = imageLayer.metadata
      return metadata[propName]
    } else {
      if (debug)
        console.debug(
          `getImageProperty(): called with IMAGE_PROPERTIES_LAYER_METADATA prop '${propName}' but could not find imageLayer or it has no metadata`,
          imageLayer
        )
    }
  } else {
    console.error(
      `getImageProperty(): called with propName '${propName}' that is not in IMAGE_PROPERTIES_LAYER_METADATA nor IMAGE_PROPERTIES_BORDER_RECT_FEATURE`
    )
  }
}

// this implementation of updateImageProperties just does props stored in image layer metadata so is much faster
// export const updateImageProperties = ({
//   properties,
//   mapinst,
//   imageId,
//   imageLayer,
// }: {
//   properties: { [key: string]: any }
//   mapinst?: Map
//   imageId?: string
//   imageLayer?: Layer
// }) => {
//   if (!imageLayer) {
//     if (!mapinst) {
//       console.error(
//         `updateImageProperties(): called with no imageLayer or mapinst`
//       )
//       return
//     }
//     if (!imageId) {
//       console.error(
//         `updateImageProperties(): called with no imageLayer or imageId`
//       )
//       return
//     }
//     const imageLayerId = getImageLayerId(imageId)
//     imageLayer = mapinst.getLayer(imageLayerId)
//   }

//   if (!imageLayer.metadata) {
//     imageLayer.metadata = {}
//   }
//   Object.assign(imageLayer.metadata, properties)

//   return imageLayer.metadata
// }

export const updateImageProperty = ({
  propName,
  propValue,
  mapinst,
  imageId,
  borderRectFeature,
  mapboxDraw,
  imageLayer,
}: {
  propName: string
  propValue: any
  mapinst?: Map
  imageId?: string
  borderRectFeature?: Feature
  mapboxDraw?: MapboxDraw
  imageLayer?: Layer
}) => {
  const debug = false
  if (debug)
    console.debug(
      `updateImageProperty(): called with propName '${propName}' and value:`,
      propValue
    )

  if (IMAGE_PROPERTIES_BORDER_RECT_FEATURE.includes(propName)) {
    if (!borderRectFeature) {
      if (!mapboxDraw) {
        console.error(
          `updateImageProperty(): called for IMAGE_PROPERTIES_BORDER_RECT_FEATURE prop with border rect feature prop '${propName}' but no mapboxDraw.`
        )
        return
      }
      if (!imageId) {
        console.error(
          `updateImageProperty(): called for IMAGE_PROPERTIES_BORDER_RECT_FEATURE prop with border rect feature prop '${propName}' but no mapboxDraw.`
        )
        return
      }

      borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
    }
    if (borderRectFeature) {
      if (!borderRectFeature.properties) {
        borderRectFeature.properties = {}
      }
      borderRectFeature.properties[propName] = propValue

      // feature needs to be re-added to MapboxDraw to 'save' the prop change
      if (mapboxDraw) {
        mapboxDraw.add(borderRectFeature)
      }
    }
  } else if (IMAGE_PROPERTIES_LAYER_METADATA.includes(propName)) {
    console.debug(
      `updateImageProperty(): propName '${propName}' is in IMAGE_PROPERTIES_LAYER_METADATA...`
    )

    if (!imageLayer) {
      if (!mapinst) {
        console.error(
          `updateImageProperty(): called for IMAGE_PROPERTIES_LAYER_METADATA prop with no imageLayer or mapinst`
        )
        return
      }
      if (!imageId) {
        console.error(
          `updateImageProperty(): called for IMAGE_PROPERTIES_LAYER_METADATA prop with no imageLayer or imageId`
        )
        return
      }
      const imageLayerId = getImageLayerId(imageId)
      imageLayer = mapinst.getLayer(imageLayerId) as Layer
    }
    if (imageLayer) {
      if (!imageLayer.metadata) {
        imageLayer.metadata = {}
      }
      const metadata: { [key: string]: any } = imageLayer.metadata as {
        [key: string]: any
      }
      metadata[propName] = propValue
    } else {
      console.error(
        `updateImageProperty(): called for IMAGE_PROPERTIES_LAYER_METADATA prop '${propName}' but could not find imageLayer for imageId '${imageId}'`
      )
    }
  } else {
    console.error(
      `getImageProperty(): propName '${propName}' that is not in IMAGE_PROPERTIES_LAYER_METADATA nor IMAGE_PROPERTIES_BORDER_RECT_FEATURE`
    )
  }
}

export const updateImageProperties = ({
  properties,
  mapinst,
  imageId,
  borderRectFeature,
  mapboxDraw,
  imageLayer,
}: {
  properties: { [key: string]: any }
  mapinst?: Map
  imageId?: string
  borderRectFeature?: Feature
  mapboxDraw?: MapboxDraw
  imageLayer?: Layer
}) => {
  const debug = false
  if (debug)
    console.debug(
      `updateImageProperties(): called with properties:`,
      properties
    )
  Object.keys(properties).forEach(propName => {
    const propValue = properties[propName]
    console.debug(
      `updateImageProperties(): calling updateImageProperty() with propName '${propName}' and value:`,
      propValue
    )
    updateImageProperty({
      propName,
      propValue,
      mapinst,
      imageId,
      borderRectFeature,
      mapboxDraw,
      imageLayer,
    })
  })
}

export const getImageProperties = ({
  propertyNames,
  mapinst,
  imageId,
  borderRectFeature,
  mapboxDraw,
  imageLayer,
}: {
  propertyNames: string[]
  mapinst?: Map
  imageId?: string
  borderRectFeature?: Feature
  mapboxDraw?: MapboxDraw
  imageLayer?: LayerSpecification
}) => {
  // console.debug(
  //   `getImageProperties(): called with propertyNames:`,
  //   propertyNames
  // )
  const res: { [key: string]: any } = {}
  propertyNames.forEach(propName => {
    res[propName] = getImageProperty({
      propName,
      mapinst,
      imageId,
      borderRectFeature,
      mapboxDraw,
      imageLayer,
    })
  })
  return res
}

export const updateImageReferencePointPosition = (
  imageLayer: LayerSpecification,
  newBorderRectCoords: RectCoordinates,
  imageReferencePointIdx: number,
  corner0toCorner1LengthMetres: number,
  corner0toCorner3LengthMetres: number,
  selectedImageReferenceMarkers?: Marker[]
) => {
  const debug = false
  if (debug)
    console.debug(
      `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): corner0toCorner1LengthMetres: ${corner0toCorner1LengthMetres}, corner0toCorner3LengthMetres: ${corner0toCorner3LengthMetres}`
    )

  const pointPercentage0to1 = getImageProperty({
    propName: `referencePoint${imageReferencePointIdx + 1}Percentage0to1`,
    imageLayer,
  })
  const pointPercentage0to3 = getImageProperty({
    propName: `referencePoint${imageReferencePointIdx + 1}Percentage0to3`,
    imageLayer,
  })

  if (debug)
    console.debug(
      `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): pointPercentage0to1: ${pointPercentage0to1}, pointPercentage0to3: ${pointPercentage0to3}; imageLayer.metadata:`,
      imageLayer.metadata
    )

  if (!pointPercentage0to1 || !pointPercentage0to3) {
    console.error(
      `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): at least one percentage null: pointPercentage0to1: ${pointPercentage0to1}, pointPercentage0to3: ${pointPercentage0to3}; imageLayer.metadata:`,
      imageLayer.metadata
    )
    return
  }

  const newPointPosition = calcNewPointPosition(
    newBorderRectCoords,
    pointPercentage0to1,
    pointPercentage0to3,
    corner0toCorner1LengthMetres,
    corner0toCorner3LengthMetres
  )

  if (debug)
    console.debug(
      `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): calcNewPointPosition() returned newPointPosition: ${newPointPosition}`
    )

  //const propName = `referencePoint${imageReferencePointIdx + 1}GlobalCoords`
  updateImageProperties({
    properties: {
      [`referencePoint${imageReferencePointIdx + 1}GlobalCoords`]:
        newPointPosition,
    },
    imageLayer,
  })

  if (selectedImageReferenceMarkers) {
    selectedImageReferenceMarkers[imageReferencePointIdx].setLngLat([
      newPointPosition[0],
      newPointPosition[1],
    ])

    if (debug)
      console.debug(
        `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): updated position of marker ${imageReferencePointIdx} to ${newPointPosition}`,
        selectedImageReferenceMarkers[imageReferencePointIdx]
      )
  } else {
    if (debug)
      console.debug(
        `updateImageReferencePointPosition(idx: ${imageReferencePointIdx}): called with null selectedImageReferenceMarkers`,
        selectedImageReferenceMarkers
      )
  }
}

const calcNewPointPosition = (
  newBorderRectCoords: RectCoordinates,
  pointPercentage0to1: number,
  pointPercentage0to3: number,
  corner0toCorner1LengthMetres: number,
  corner0toCorner3LengthMetres: number
): ArrayOfTwoOrMoreNumbers => {
  const debug = false

  if (debug)
    console.debug(
      `calcNewPointPosition(): corner0toCorner1LengthMetres: ${corner0toCorner1LengthMetres}, corner0toCorner3LengthMetres: ${corner0toCorner3LengthMetres}`
    )

  const bearingBetween0and1 = getRoundedBearingBetween0and1(newBorderRectCoords)

  if (debug)
    console.debug(
      `calcNewPointPosition(): pointPercentage0to1: ${pointPercentage0to1}, pointPercentage0to3: ${pointPercentage0to3}`
    )

  if (debug)
    console.debug(
      `calcNewPointPosition(): bearingBetween0and1 - ${newBorderRectCoords[0]} and ${newBorderRectCoords[1]}: ${bearingBetween0and1}`
    )

  if (!corner0toCorner1LengthMetres || !corner0toCorner3LengthMetres) {
    console.error(
      `calcNewPointPosition(): called with empty corner0toCorner1LengthMetres or corner0toCorner3LengthMetres - putting point in corner0`
    )
    return newBorderRectCoords[0]
  }

  // borderRect may have been rotated bearingBetween0and1 degrees from level, we need to move the point
  // keeping its relative position within the rect.
  // - calculate the distance in metres along the 0-1 line - use turf.js distance()
  // - calculate the distance in metres along the 0-3 line
  // use turfjs.destination() to walk along the rectangle - start at corner0 and move along the 0-1 line
  // the calculated number of metres, then rotate 90 degrees and use destination() to move the 0-3
  // number of metres

  // An alternative to this would be to plot the points where they would be if the image was
  // level (NOT rotated, line0-1 at bearing 90) and then use a lib to rotate those points around the
  // rect center point.
  // As we know the offset ratios of the point, to calculate where it would be when the rectangle is
  // level we would need to know the length in degrees of corners0-1 and corners0-3.
  // The rect's coordinates other than corner0 and center cannot be simply minused to determine
  // 'side length in degrees' or 'width in degrees' as they are rotated - trigonometry will be needed
  // to split out the width vs height components.
  // Also consider that global degrees are not square and the ratio of a degree changes across the globe
  // turf.js' distance() function will take that into account.
  // - get the distance in metres of 0-1 and 0-3 by two calls to turfjs.distance()
  // - plot the point's non-rotated location using the offset ratios and distances - two calls to destination()

  const corner0 = newBorderRectCoords[0]

  if (debug)
    console.debug(
      `calcNewPointPosition(): newBorderRectCoords: ${newBorderRectCoords}`
    )

  if (debug)
    console.debug(
      `calcNewPointPosition(): rect sides: distance0to1metres: ${corner0toCorner1LengthMetres}m x distance0to3metres: ${corner0toCorner3LengthMetres}m`
    )

  const pointOffset0to1metres =
    corner0toCorner1LengthMetres * pointPercentage0to1
  const pointOffset0to3metres =
    corner0toCorner3LengthMetres * pointPercentage0to3

  if (debug)
    console.debug(
      `calcNewPointPosition(): reference point offset from sides in m: ${pointOffset0to1metres}m x ${pointOffset0to3metres}m`
    )

  const bearingFromCorner0to3 = getRoundedBearingBetweenCorners(
    newBorderRectCoords,
    0,
    3
  )

  const positionOnLine0to3 = rhumbDestination(
    corner0,
    pointOffset0to3metres,
    bearingFromCorner0to3,
    { units: 'meters' }
  )

  if (debug)
    console.debug(
      `calcNewPointPosition(): ${pointOffset0to3metres}m away from corner0 (${corner0}) at bearing '${bearingFromCorner0to3}' degrees puts positionOnLine0to3 at coords: ${positionOnLine0to3.geometry.coordinates}`
    )

  const positionOfMovedPoint = rhumbDestination(
    positionOnLine0to3,
    pointOffset0to1metres,
    bearingBetween0and1,
    { units: 'meters' }
  )

  if (debug)
    console.debug(
      `calcNewPointPosition(): ${pointOffset0to1metres}m away from positionOnLine0to3 (${positionOnLine0to3.geometry.coordinates}) at bearing '${bearingBetween0and1}' degrees puts the point at positionOfMovedPoint: ${positionOfMovedPoint.geometry.coordinates}`
    )
  return [
    positionOfMovedPoint.geometry.coordinates[0],
    positionOfMovedPoint.geometry.coordinates[1],
  ]
}

/**
 * called by onImageBorderRectMoved()
 *           rotateImageAndContents
 *
 */
export const repositionImageReferencePoints = (
  mapinst: Map,
  imageId: string,
  newBorderRectCoords: RectCoordinates,
  corner0toCorner1LengthMeters: number,
  corner0toCorner3LengthMeters: number,
  selectedImageReferenceMarkers?: Marker[]
) => {
  const debug = false
  if (debug) {
    console.debug(
      `repositionImageReferencePoints(): called for imageId: ${imageId} with corner0toCorner1LengthMeters: ${corner0toCorner1LengthMeters}m, corner0toCorner3LengthMeters: ${corner0toCorner3LengthMeters}m, newBorderRectCoords:`,
      newBorderRectCoords
    )

    if (!selectedImageReferenceMarkers) {
      console.debug(
        `repositionImageReferencePoints(): called with null selectedImageReferenceMarkers`,
        selectedImageReferenceMarkers
      )
    } else {
      console.debug(
        `repositionImageReferencePoints(): called with selectedImageReferenceMarkers:`,
        selectedImageReferenceMarkers
      )
    }
  }

  const imageLayerId = getImageLayerId(imageId)
  const imageLayer = mapinst.getLayer(imageLayerId) as LayerSpecification

  if (!imageLayer) {
    console.error(
      `repositionImageReferencePoints(): did not get imageLayer for imageId: ${imageId}, imageLayerId ${imageLayerId}`
    )
    return
  }
  if (debug)
    console.debug(
      `repositionImageReferencePoints(): called for imageId: ${imageId}, imageLayer.metadata:`,
      imageLayer.metadata
    )

  updateImageReferencePointPosition(
    imageLayer,
    newBorderRectCoords,
    0,
    corner0toCorner1LengthMeters,
    corner0toCorner3LengthMeters,
    selectedImageReferenceMarkers
  )
  updateImageReferencePointPosition(
    imageLayer,
    newBorderRectCoords,
    1,
    corner0toCorner1LengthMeters,
    corner0toCorner3LengthMeters,
    selectedImageReferenceMarkers
  )
}

/**
 *
 * given a rotated rectangle and a scaleFactor, scale the rectangle by the given factor and return the coords
 *
 * turf.js is used to measure the angle of the first edge, and get the length in meters of the first and second edges
 *
 * Called by scaleImageAndContents()
 *             called by scaleImageAndContentsToGlobeReferencePoints()
 *                         called by snapReferencePointsTogether()
 *
 */
const scaleRotatedRectCoordinates = (
  previousBoundingRectCoords: RectCoordinates,
  scaleFactor: number,
  fixedPointCoords: number[] | undefined
): FourCoordinates => {
  const debug = false

  if (debug)
    console.debug(
      `scaleRotatedRectCoordinates(): scaling border rect by ${scaleFactor} x, previousBoundingRectCoords:`,
      previousBoundingRectCoords
    )

  const previousDiagonalMetersBetweenCorners0And1 = rhumbDistance(
    previousBoundingRectCoords[0],
    previousBoundingRectCoords[1],
    { units: 'meters' }
  )
  const previousDiagonalMetersBetweenCorners1And2 = rhumbDistance(
    previousBoundingRectCoords[1],
    previousBoundingRectCoords[2],
    { units: 'meters' }
  )

  const bearingBetween0and1 = getRoundedBearingBetween0and1(
    previousBoundingRectCoords
  )

  const newDiagonalMetersBetweenCorners0And1 =
    previousDiagonalMetersBetweenCorners0And1 * scaleFactor
  const newDiagonalMetersBetweenCorners1And2 =
    previousDiagonalMetersBetweenCorners1And2 * scaleFactor

  if (debug)
    console.debug(
      `scaleRotatedRectCoordinates(): scaling border rect by ${scaleFactor} x; bearingBetween0and1:`,
      bearingBetween0and1
    )

  let corner0Coords = previousBoundingRectCoords[0]
  if (fixedPointCoords) {
    // fixedPointCoords is specified, calculate the relative distance of the
    // given point from the edges of the rect and scale the rect out to keep
    // the relative distance the same

    // the point to fix is fixedPointPct01 % between corners 0 and 1, and
    // fixedPointPct02 % between corners 0 and 2
    const fixedPointOffsetFromCorner0X =
      fixedPointCoords[0] - previousBoundingRectCoords[0][0]
    const fixedPointOffsetFromCorner0Y =
      fixedPointCoords[1] - previousBoundingRectCoords[0][1]

    const scaledFixedPointOffsetFromCorner0X =
      fixedPointOffsetFromCorner0X * scaleFactor
    const scaledFixedPointOffsetFromCorner0Y =
      fixedPointOffsetFromCorner0Y * scaleFactor

    corner0Coords = [
      fixedPointCoords[0] - scaledFixedPointOffsetFromCorner0X,
      fixedPointCoords[1] - scaledFixedPointOffsetFromCorner0Y,
    ]

    if (debug)
      console.debug(
        `scaleRotatedRectCoordinates(): fixedPointCoords is '${fixedPointCoords}', scaled corner0Coords by ${scaleFactor} from ${previousBoundingRectCoords[0]} to ${corner0Coords}.`
      )
  }

  // uses turfjs.destination() to calculate the corner points of a rotated
  // rectangle by walking around the rectangle starting from corner 0
  return plotGroundRectangleFromCorner0(
    corner0Coords,
    newDiagonalMetersBetweenCorners0And1,
    newDiagonalMetersBetweenCorners1And2,
    bearingBetween0and1
  )
}

export const scaleImageAndContents = (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  diagonalRatio: number,
  alsoScaleBorderRect: boolean,
  fixedPointCoords: number[] | undefined,
  imageReferencePointMarkers: Marker[] | undefined
) => {
  const debug = false
  // get the existing border rect from mapDraw as this is rotated and fits the image
  const borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
  if (!borderRectFeature) {
    console.error(
      `scaleImageAndContents(): could not get borderRect feature for imageId ${imageId}`
    )
    return
  }
  //: Array<[Position]>
  const previousImageRotatedRectCoords =
    borderRectFeature.geometry.coordinates[0].slice(0, 4) as RectCoordinates //assert there will be at least 4 coords
  //.map(point => point.toArray())

  if (debug)
    console.debug(
      `scaleImageAndContents(): scaling border rotated rect by ${diagonalRatio} x, existing border rect`,
      previousImageRotatedRectCoords
    )
  const scaledBorderRectCoords = scaleRotatedRectCoordinates(
    previousImageRotatedRectCoords,
    diagonalRatio,
    fixedPointCoords
  )

  console.debug(
    `scaleImageAndContents(): after scaling, border rect has new coordinates`,
    scaledBorderRectCoords
  )

  const imageSourceId = getImageSourceId(imageId)
  const imageSource = mapinst.getSource(imageSourceId) as ImageSource

  if (debug)
    console.debug(
      `scaleImageAndContents(): imageSource prev coordinates:`,
      imageSource.coordinates
    )

  imageSource.setCoordinates([...scaledBorderRectCoords])

  if (debug)
    console.debug(
      `scaleImageAndContents(): imageSource new coordinates:`,
      imageSource.coordinates
    )

  // const imageWidthPx = borderRectFeature.properties?.imageWidthPx
  // const imageHeightPx = borderRectFeature.properties?.imageHeightPx
  // const metersPerPixel = borderRectFeature.properties?.metersPerPixel

  const distance0to1metres = rhumbDistance(
    scaledBorderRectCoords[0],
    scaledBorderRectCoords[1],
    {
      units: 'meters',
    }
  )
  const distance0to3metres = rhumbDistance(
    scaledBorderRectCoords[0],
    scaledBorderRectCoords[3],
    {
      units: 'meters',
    }
  )

  if (debug)
    console.debug(
      `scaleImageAndContents(): calling repositionImageReferencePoints(distance0to1metres: ${distance0to1metres})...`
    )
  // move image reference points to account for the scaling
  repositionImageReferencePoints(
    mapinst,
    imageId,
    scaledBorderRectCoords,
    distance0to1metres,
    distance0to3metres,
    imageReferencePointMarkers
  )

  if (alsoScaleBorderRect) {
    const newBoundingRectCoords = [
      ...scaledBorderRectCoords,
      scaledBorderRectCoords[0],
    ]

    if (debug)
      console.debug(
        `scaleImageAndContents(): calling moveImageBorderRect()... newBoundingRectCoords:`,
        newBoundingRectCoords
      )

    // calls recalcFeatureUserPropsForResize()
    moveImageBorderRect(mapinst, mapboxDraw, imageId, newBoundingRectCoords)
  } else {
    if (debug)
      console.debug(`scaleImageAndContents(): alsoScaleBorderRect not set`)
  }

  if (debug) console.debug(`scaleImageAndContents(): done.`)
} // end scaleImageAndContents

const getGlobeReferencePointsForImageId = (
  imageId: string
): number[][] | undefined => {
  //return globeReferencePointsRef.current
  return undefined
}

const scaleImageAndContentsToGlobeReferencePoints = (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  targetReferencePointCoordinates: number[][],
  alsoScaleDrawnBorderRect: boolean,
  imageReferencePointMarkers: Marker[] | undefined
) => {
  const debug = false
  if (!imageId) {
    console.error(
      `MapImageHelpers.scaleImageAndContentsToGlobeReferencePoints(): imageId not provided`
    )
    return
  }

  if (!mapinst) {
    console.error(
      `MapImageHelpers.scaleImageAndContentsToGlobeReferencePoints(): mapinst not provided`
    )
    return
  }

  if (debug)
    console.debug(
      `MapImageHelpers.scaleImageAndContentsToGlobeReferencePoints(): imageId provided: ${imageId}`
    )

  if (!targetReferencePointCoordinates) {
    const coords = getGlobeReferencePointsForImageId(imageId)
    if (!coords) {
      return
    }
    targetReferencePointCoordinates = coords
  }

  const imageSourceId = getImageSourceId(imageId)
  if (!imageSourceId) {
    console.error(
      `MapImageHelpers.scaleImageAndContentsToGlobeReferencePoints(): getImageSourceId(imageId: ${imageId}) returned null`
    )
    return
  }

  console.debug(
    `MapImageHelpers.scaleImageAndContentsToGlobeReferencePoints(): getting imageSourceId: ${imageSourceId}...`
  )
  const imageSource = mapinst.getSource(
    imageSourceId
  ) as ImageSourceSpecification

  const imageLayerId = getImageLayerId(imageId)
  const imageLayer = mapinst.getLayer(imageLayerId) as LayerSpecification

  // const imageReferencePoints = [1, 2].map(
  //   idx => imageLayer.metadata[`referencePoint${idx}GlobalCoords`]
  // )
  const imageReferencePoints = [1, 2].map(idx =>
    getImageProperty({
      propName: `referencePoint${idx}GlobalCoords`,
      imageId,
      imageLayer,
    })
  )

  if (debug)
    console.debug(
      `scaleImageAndContentsToGlobeReferencePoints(): imageSource.coordinates is:`,
      imageSource.coordinates
    )

  const widthBetweenGlobePointsDegrees =
    targetReferencePointCoordinates[1][0] -
    targetReferencePointCoordinates[0][0]
  const heightBetweenGlobePointsDegrees =
    targetReferencePointCoordinates[1][1] -
    targetReferencePointCoordinates[0][1]
  const diagonalDistanceBetweenGlobePointsDegrees = Math.sqrt(
    Math.pow(widthBetweenGlobePointsDegrees, 2) +
      Math.pow(heightBetweenGlobePointsDegrees, 2)
  )

  const widthBetweenImagePointsDegrees =
    imageReferencePoints[1][0] - imageReferencePoints[0][0]
  const heightBetweenImagePointsDegrees =
    imageReferencePoints[1][1] - imageReferencePoints[0][1]
  const diagonalDistanceBetweenImageReferencePointsDegrees = Math.sqrt(
    Math.pow(widthBetweenImagePointsDegrees, 2) +
      Math.pow(heightBetweenImagePointsDegrees, 2)
  )

  if (debug) {
    console.debug(
      `scaleImageAndContentsToGlobeReferencePoints(): widthBetweenImagePointsDegrees: ${widthBetweenImagePointsDegrees}, widthBetweenGlobePointsDegrees: ${widthBetweenGlobePointsDegrees}`
    )
    console.debug(
      `scaleImageAndContentsToGlobeReferencePoints(): heightBetweenImagePointsDegrees: ${heightBetweenImagePointsDegrees}, heightBetweenGlobePointsDegrees: ${heightBetweenGlobePointsDegrees}`
    )
  }

  const diagonalRatio =
    diagonalDistanceBetweenGlobePointsDegrees /
    diagonalDistanceBetweenImageReferencePointsDegrees

  const widthRatio =
    widthBetweenGlobePointsDegrees / widthBetweenImagePointsDegrees
  const heightRatio =
    heightBetweenGlobePointsDegrees / heightBetweenImagePointsDegrees

  if (debug) {
    console.debug(
      `scaleImageAndContentsToGlobeReferencePoints(): diagonalRatio: ${diagonalRatio}, widthRatio: ${widthRatio}, heightRatio: ${heightRatio}`
    )

    console.debug(
      `scaleImageAndContentsToGlobeReferencePoints(): calling scaleImageAndContents()...`
    )
  }
  scaleImageAndContents(
    mapinst,
    mapboxDraw,
    imageId,
    diagonalRatio,
    alsoScaleDrawnBorderRect,
    undefined,
    imageReferencePointMarkers
  )
  console.debug(
    `scaleImageAndContentsToGlobeReferencePoints(): scaleImageAndContents() done.`
  )
} // end scaleImageAndContents

/**
 * Creates a GeoJSON Polygon Feature with the given coordinates and adds it to MapboxDraw
 *
 *
 * Called by addEditableImageToGlobe()
 *
 */
export const addRectangleToMapboxDraw = (
  mapboxDraw: MapboxDraw,
  newFeatureId: string,
  //coordinates: number[][],
  coordinates: FourCoordinates,
  overlayImageSourceId: number,
  imageWidthPx: number,
  imageHeightPx: number,
  metersPerPixel: number,
  centerCoords: number[],
  bearingCorner0toCorner1: number
) => {
  const debug = false
  if (!mapboxDraw) {
    console.error(`addRectangleToMapboxDraw(): called with null mapboxDraw`)
    return
  }
  if (!newFeatureId) {
    console.error(`addRectangleToMapboxDraw(): called with null id`)
    return
  }
  if (debug) {
    console.debug(
      `addRectangleToMapboxDraw(): called with mapboxDraw`,
      mapboxDraw
    )
    console.debug(
      `addRectangleToMapboxDraw(): called with newFeatureId`,
      newFeatureId
    )
    console.debug(
      `addRectangleToMapboxDraw(): called with coordinates`,
      coordinates
    )
  }
  let existingDrawItemWithId
  try {
    existingDrawItemWithId = mapboxDraw.get(newFeatureId)
  } catch (error) {}
  if (existingDrawItemWithId) {
    console.error(
      `addRectangleToMapboxDraw(): mapboxDraw already contains feature with id '${addRectangleToMapboxDraw}'`
    )
    return
  }

  const fiveCoords: FiveCoordinates = [...coordinates, coordinates[0]]

  const imageBorderGeoJson: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        id: newFeatureId,
        type: 'Feature',
        properties: {
          // these from TxRectModeDemo
          overlaySourceId: overlayImageSourceId, // somehow this gets renamed or copied to user_overlaySourceId
          //                                        and used by TxRect to move the image around with the border
          type: 'overlay',
          //groundAspectRatio: groundAspectRatio,
          imageWidthPx: imageWidthPx,
          imageHeightPx: imageHeightPx,
          metersPerPixel: metersPerPixel, //updated on resize
          centerCoords: centerCoords, //updated on drag
          bearingCorner0toCorner1: bearingCorner0toCorner1,
        },
        geometry: {
          coordinates: [fiveCoords],
          type: 'Polygon',
        },
      },
    ],
  }
  mapboxDraw.add(imageBorderGeoJson)

  if (debug)
    console.debug(
      `addRectangleToMapboxDraw(): added rectangle around image '${overlayImageSourceId}' with id '${newFeatureId}', feature:`,
      imageBorderGeoJson
    )

  // changemode to txrect not needed here because TxRectModeDemo._txEdit()
  // does it when the rect is selected

  return imageBorderGeoJson
}

//const BOTTOM_LINE_METHOD='WALK_CORNERS' as string   // perhaps if large enough this can make an irregular rhombus with one side at an angle and the other vertical
const BOTTOM_LINE_METHOD = 'MIDDLE_OUT' as string
const WALK_CORNERS_BALANCE_LAST_LINE = false

const plotGroundRectangle = (
  centerCoords: number[] | LngLat,
  length0to1Metres: number,
  length0to3Metres: number,
  bearingCorner0toCorner1: number
): FourCoordinates | undefined => {
  const debug = false
  if (!centerCoords || !length0to1Metres || !length0to1Metres) {
    console.error(
      `plotGroundRectangleForImage(): called with missing param: centerCoords: ${centerCoords}, length0to1Metres: ${length0to1Metres}, length0to3Metres: ${length0to3Metres}, bearingCorner0toCorner1: ${bearingCorner0toCorner1}`
    )
    return
  }

  const centerPoint = Array.isArray(centerCoords)
    ? turfPoint(centerCoords)
    : turfPoint(centerCoords.toArray())

  if (debug)
    console.debug(
      `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}, heightMeters: ${length0to3Metres}, bearingCorner0toCorner1: ${bearingCorner0toCorner1}`
    )

  const bearingOfCenterToLine0to1 = bearingCorner0toCorner1 - 90

  //start at centerpoint and go 'up' half the height of the image
  const middleOfTopEdge = rhumbDestination(
    centerPoint,
    length0to3Metres / 2,
    bearingOfCenterToLine0to1,
    {
      units: 'meters',
    }
  ).geometry.coordinates

  if (debug)
    console.debug(
      `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}, length0to3Metres: ${length0to3Metres}, bearingCorner0toCorner1: ${bearingCorner0toCorner1}, middleOfTopEdge: ${middleOfTopEdge}`
    )

  const bearingMiddleOf0to1ToCorner0 = bearingCorner0toCorner1 - 180

  // from the middle of the top edge of the image, now go left half the width of the image
  const corner0 = rhumbDestination(
    middleOfTopEdge,
    length0to1Metres / 2,
    bearingMiddleOf0to1ToCorner0,
    {
      units: 'meters',
    }
  ).geometry.coordinates

  if (debug)
    console.debug(
      `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}; bearingMiddleOf0to1ToCorner0: ${bearingMiddleOf0to1ToCorner0}, corner0: ${corner0}`
    )

  const corner1 = rhumbDestination(
    corner0,
    length0to1Metres,
    bearingCorner0toCorner1,
    {
      units: 'meters',
    }
  ).geometry.coordinates

  //HACK!!! destination() is moving the latitude down for some reason. For now, kludge it. Possibly resulting in less distance than expected.
  // see https://github.com/Turfjs/turf/issues/2619
  //topRight[1] = corner0[1]

  if (debug)
    console.debug(
      `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}; bearingCorner0toCorner1: ${bearingCorner0toCorner1}, corner1: ${corner1}`
    )

  let corner2: Position, corner3: Position
  if (BOTTOM_LINE_METHOD === 'WALK_CORNERS') {
    const bearingCorner1toCorner2 = bearingCorner0toCorner1 + 90
    corner2 = rhumbDestination(
      corner1,
      length0to3Metres,
      bearingCorner1toCorner2,
      {
        units: 'meters',
      }
    ).geometry.coordinates

    if (debug)
      console.debug(
        `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to3Metres: ${length0to3Metres}; bearingCorner1toCorner2: ${bearingCorner1toCorner2}, corner2: ${corner2}`
      )

    const bearingCorner2toCorner3 = bearingCorner1toCorner2 + 90
    corner3 = rhumbDestination(
      corner2,
      length0to1Metres,
      bearingCorner2toCorner3,
      {
        units: 'meters',
      }
    ).geometry.coordinates

    //HACK!!! destination() is moving the latitude down for some reason. For now, kludge it. Possibly resulting in less distance than expected.
    // see https://github.com/Turfjs/turf/issues/2619
    //bottomLeft[1] = bottomRight[1]
    if (debug)
      console.debug(
        `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}; bearingCorner2toCorner3: ${bearingCorner2toCorner3}, corner3: ${corner3}`
      )

    if (
      BOTTOM_LINE_METHOD === 'WALK_CORNERS' &&
      WALK_CORNERS_BALANCE_LAST_LINE
    ) {
      //the last corner will be horizontally offset to the first due to degrees being different widths, and it looks a bit odd
      //with one corner out of place and the others square. Balance out the bottom corners so we get an even rhombus.
      const bottomLeftHorizonalOffset = corner3[0] - corner0[0]
      const tweakBottomHorizontals = bottomLeftHorizonalOffset / 2
      const oldBLx = corner3[0]
      const oldBRx = corner2[0]
      corner3[0] = corner3[0] - tweakBottomHorizontals
      corner2[0] = corner2[0] - tweakBottomHorizontals
      if (debug)
        console.debug(
          `plotGroundRectangle(): bottomLeft was ${bottomLeftHorizonalOffset} horizontal degrees inwards compared to the the topLeft, bumping both bottom corners across to even it out - moved bottom left ${tweakBottomHorizontals} degs towards the topleft from ${oldBLx} to ${corner3[0]} and bottom right ${tweakBottomHorizontals} degs inwards from ${oldBRx} to ${corner2[0]}`
        )
    }
  } else if (BOTTOM_LINE_METHOD === 'MIDDLE_OUT') {
    const middleOfBottomEdge = rhumbDestination(
      middleOfTopEdge,
      length0to3Metres,
      bearingCorner0toCorner1 + 90,
      {
        units: 'meters',
      }
    ).geometry.coordinates

    corner2 = rhumbDestination(
      middleOfBottomEdge,
      length0to1Metres / 2,
      bearingCorner0toCorner1,
      {
        units: 'meters',
      }
    ).geometry.coordinates

    if (debug)
      console.debug(
        `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to3Metres: ${length0to3Metres}; middleOfBottomEdge: ${middleOfBottomEdge}, corner2: ${corner2}`
      )

    const bearingCorner2toCorner3 = bearingCorner0toCorner1 + 180
    corner3 = rhumbDestination(
      corner2,
      length0to1Metres,
      bearingCorner2toCorner3,
      {
        units: 'meters',
      }
    ).geometry.coordinates

    if (debug)
      console.debug(
        `plotGroundRectangle(): centerPoint: ${centerCoords}, length0to1Metres: ${length0to1Metres}; bearingCorner2toCorner3: ${bearingCorner2toCorner3}, corner3: ${corner3}`
      )
  } else {
    console.error(
      `plotGroundRectangle(): unexpected BOTTOM_LINE_METHOD '${BOTTOM_LINE_METHOD}'`
    )
    return
  }
  const newCoords = [
    corner0, //nw
    corner1, //ne
    corner2, //se
    corner3, //sw
  ] as FourCoordinates

  //TODO alternatively, consider plotting a level rect then using transformRotate()

  // // TODO REMOVE getCenterCoords SANITY CHECK
  const reversedCenterCoords = getCenterCoords(newCoords)
  const xDiff = reversedCenterCoords[0] - centerPoint.geometry.coordinates[0]
  const yDiff = reversedCenterCoords[0] - centerPoint.geometry.coordinates[0]

  if (yDiff > 0.0001 || yDiff < -0.0001 || xDiff > 0.0001 || xDiff < -0.0001) {
    console.error(
      `plotGroundRectangle(): SANITY CHECK: getCenterCoords() returns different value: input: ${centerPoint.geometry.coordinates}, getCenterCoords() response: ${reversedCenterCoords}`
    )
  }

  // // TODO REMOVE plotGroundRectangle turfjs.center() SANITY CHECK
  // const reversedCenterCoords2 = center(newCoords).geometry.coordinates
  // const xDiff2 = reversedCenterCoords2[0] - centerPoint.geometry.coordinates[0]
  // const yDiff2 = reversedCenterCoords2[0] - centerPoint.geometry.coordinates[0]

  // if (
  //   yDiff2 > 0.0001 ||
  //   yDiff2 < -0.0001 ||
  //   xDiff2 > 0.0001 ||
  //   xDiff2 < -0.0001
  // ) {
  //   console.error(
  //     `calcRectGlobalCoordinates(): turfjs center() returns different value: input: ${centerPoint.geometry.coordinates}, center() response: ${reversedCenterCoords2}`
  //   )
  // }

  //TODO REMOVE SANITY CHECK
  // const sanityCheck_distance0to1metres = distance(corner0, corner1, {
  //   units: 'meters',
  // })
  // if (sanityCheck_distance0to1metres === length0to1Metres) {
  //   console.debug(
  //     `calcRectGlobalCoordinates(): SANITY CHECK: distance(corner0, corner1) is the expected length0to1Metres: ${length0to1Metres}`
  //   )
  // } else {
  //   console.error(
  //     `calcRectGlobalCoordinates(): SANITY CHECK: distance(corner0, corner1) is NOT the expected length0to1Metres: ${length0to1Metres} but ${sanityCheck_distance0to1metres}!`
  //   )
  // }

  const sanityCheck_rhumbDistance0to1metres = rhumbDistance(corner0, corner1, {
    units: 'meters',
  })
  if (sanityCheck_rhumbDistance0to1metres === length0to1Metres) {
    if (debug)
      console.debug(
        `plotGroundRectangle(): SANITY CHECK: rhumbDistance(corner0, corner1) is exactly the expected length0to1Metres: ${length0to1Metres}`
      )
  } else {
    const diff = sanityCheck_rhumbDistance0to1metres - length0to1Metres
    if (diff > 0.000001 || diff < -0.000001) {
      console.error(
        `plotGroundRectangle(): SANITY CHECK: rhumbDistance(corner0, corner1) is NOT the expected length0to1Metres: ${length0to1Metres} but ${sanityCheck_rhumbDistance0to1metres}!`
      )
    } else {
      if (debug)
        console.debug(
          `plotGroundRectangle(): SANITY CHECK: rhumbDistance(corner0, corner1) is within tolerance of the expected length0to1Metres: ${length0to1Metres} - ${sanityCheck_rhumbDistance0to1metres} = ${
            length0to1Metres - sanityCheck_rhumbDistance0to1metres
          }!`
        )
    }
  }

  if (debug)
    console.debug(`plotGroundRectangle(): returning newCoords`, newCoords)

  return newCoords
}

// called by scaleRotatedRectCoordinates(), called by scaleImageAndContents(), called by snap or mouse wheel resize
//
// uses turfjs.destination() to calculate the corner points of a rotated
// rectangle by walking around the rectangle starting from corner 0
const plotGroundRectangleFromCorner0 = (
  corner0coords: ArrayOfTwoOrMoreNumbers,
  diagonalMetersBetweenCorners0And1: number,
  diagonalMetersBetweenCorners1And2: number,
  bearingOfDiagonalBetween0and1: number
): FourCoordinates => {
  const coord0 = corner0coords
  const coord1 = rhumbDestination(
    coord0,
    diagonalMetersBetweenCorners0And1,
    bearingOfDiagonalBetween0and1,
    { units: 'meters' }
  ).geometry.coordinates
  const coord2 = rhumbDestination(
    coord1,
    diagonalMetersBetweenCorners1And2,
    bearingOfDiagonalBetween0and1 + 90,
    { units: 'meters' }
  ).geometry.coordinates
  const coord3 = rhumbDestination(
    coord2,
    diagonalMetersBetweenCorners0And1,
    bearingOfDiagonalBetween0and1 + 180,
    { units: 'meters' }
  ).geometry.coordinates

  //TODO plotGroundRectangleFromCorner0() should compensate for coord3 not being at the same
  //     X position as coord0 due to degree width changing by latitude

  return [
    coord0 as [number, number],
    coord1 as [number, number],
    coord2 as [number, number],
    coord3 as [number, number],
  ]
}

/**
 * Uses turf.js' destination() to plot a rectangle where the points produce a rectangle of the correct ratio on the floor
 * In global coordinates this will produce a rhombus unless at the equator
 *
 * called by: calcNewImageBorderCoords -
 *            adjustFeatureWidthForLatitude() - called by onImageBorderMoved()
 */
export const plotGroundRectangleForImage = (
  widthPx: number,
  heightPx: number,
  centerCoords: number[] | LngLat,
  metersPerPixel: number,
  bearingCorner0toCorner1: number
): FourCoordinates | undefined => {
  const debug = true
  if (
    !widthPx ||
    !heightPx ||
    !centerCoords ||
    !metersPerPixel ||
    !bearingCorner0toCorner1
  ) {
    console.error(
      `plotGroundRectangleForImage(): called with missing param: widthPx: ${widthPx}, heightPx: ${heightPx}, centerCoords: ${centerCoords}, metersPerPixel: ${metersPerPixel}, bearingCorner0toCorner1: ${bearingCorner0toCorner1}`
    )
    return
  }

  if (debug)
    console.debug(
      `plotGroundRectangleForImage(): called with widthPx: ${widthPx}, heightPx: ${heightPx}, centerCoords: ${centerCoords}, metersPerPixel: ${metersPerPixel}, bearingCorner0toCorner1: ${bearingCorner0toCorner1}`
    )

  const length0to1Metres = metersPerPixel * widthPx
  const length0to3Metres = metersPerPixel * heightPx

  return plotGroundRectangle(
    centerCoords,
    length0to1Metres,
    length0to3Metres,
    bearingCorner0toCorner1
  )
}

// Called when loading a non-georeferenced image and placing it in the center of the current map viewport.
// Returns coordinates for the image taking into account image ratio, current map view port ratio,
// and degree ratio variance by latitude
// calls calcRectGlobalCoordinates()
export const calcNewImageBorderCoords = (
  imageWidthPx: number,
  imageHeightPx: number,
  fitInsideCoords: LngLatBounds, //viewport
  bearingCorner0toCorner1 = 90
): [FourCoordinates, number, number[]] | undefined => {
  const debug = false
  if (debug)
    console.debug(
      `MapImageHelpers.calcImageBorderCoords(): called with imageWidth ${imageWidthPx}x${imageHeightPx}px, fitInsideCoords: ${fitInsideCoords}`
    )

  const imageAspectRatio = imageWidthPx / imageHeightPx

  // THIS ASSUMES NO ROTATION!
  const horizontalMeters = fitInsideCoords
    .getNorthWest()
    .distanceTo(fitInsideCoords.getNorthEast())
  const verticalMeters = fitInsideCoords
    .getNorthWest()
    .distanceTo(fitInsideCoords.getSouthWest())

  if (debug)
    console.debug(
      `MapImageHelpers.generateImageBorderCoords(): displayed ground metres: ${horizontalMeters}x${verticalMeters}m...`
    )

  const screenAspectRatio = horizontalMeters / verticalMeters
  if (debug)
    console.debug(
      `MapImageHelpers.generateImageBorderCoords(): screenAspectRatio: ${screenAspectRatio}...`
    )
  if (debug)
    console.debug(
      `MapImageHelpers.generateImageBorderCoords(): imageAspectRatio: ${imageAspectRatio}...`
    )

  let metersPerPixel
  // compare the aspect ratio of the image with the
  // aspect ratio of the map viewport to determine the
  // scale of the image whilst keeping all of it
  // inside the viewport
  if (screenAspectRatio > imageAspectRatio) {
    // screen is wider than the image, constrain on height
    metersPerPixel = verticalMeters / imageHeightPx
  } else {
    metersPerPixel = horizontalMeters / imageWidthPx
  }

  if (debug)
    console.debug(
      `MapImageHelpers.generateImageBorderCoords(): calculated metersPerPixel: ${metersPerPixel}...`
    )

  // padding!
  metersPerPixel = metersPerPixel * 0.6

  const centerCoords = fitInsideCoords.getCenter() //MapBox function.

  const imageCoordinates = plotGroundRectangleForImage(
    imageWidthPx,
    imageHeightPx,
    centerCoords,
    metersPerPixel,
    bearingCorner0toCorner1
  )

  if (debug)
    console.debug(
      `MapImageHelpers.generateImageBorderCoords(): calcRectGlobalCoordinates() returned imageCoordinates for ${metersPerPixel}m/px:`,
      imageCoordinates
    )
  //imageCoordinates will be non-null if any of the parameters were null/undefined, which they should not be.
  if (!imageCoordinates) {
    return undefined
  }
  return [imageCoordinates, metersPerPixel, centerCoords.toArray()]
}

// pixels are from MapMouseEvent.point or originalEvent.offsetX/offsetY
export const getImageLayerIdAtMapViewportPixels = (
  mapinst: Map,
  pixels: [number, number]
) => {
  const features = mapinst.queryRenderedFeatures(pixels)
  console.debug(
    `getImageLayerIdAtMapViewportPixels(): found features under viewport-relative-pixels ${pixels}:`,
    features
  )

  const borderRectFeature = features.find(feature =>
    feature.properties?.id?.startsWith('image-border-rect-')
  )
  if (borderRectFeature) {
    const borderRectId = borderRectFeature.properties?.id
    const imageId = borderRectId.split('image-border-rect-').pop()
    if (imageId) {
      const imageLayerId = getImageLayerId(imageId)

      console.debug(
        `getImageLayerIdAtMapViewportPixels(): returning layer for imageId '${imageId}' with layerId: ${imageLayerId}`,
        features
      )
      //return mapinst.getLayer(imageLayerId)
      return imageLayerId
    }
  }
}

/**
 * Only called from adjustFeatureWidthForLatitude()
 *
 */
const getFeatureProperties = (
  mapinst: Map,
  imageId: string,
  feature: Feature<Polygon, GeoJsonProperties>
) => {
  let centerCoords = getImageProperty({
    propName: 'centerCoords',
    borderRectFeature: feature,
  })
  if (centerCoords) {
    if (!Array.isArray(centerCoords)) {
      try {
        centerCoords = JSON.parse(centerCoords)
      } catch (error) {
        console.error(
          `getFeatureProperties(): error parsing non-array centerCoords as JSON: '${error}':`,
          centerCoords
        )
      }
    }
  } else {
    console.error(
      `getFeatureProperties(): feature.properties.centerCoords is not set and neither is feature.properties.user_centerCoords:`,
      feature
    )
  }

  const props = getImageProperties({
    propertyNames: [
      'imageWidthPx',
      'imageHeightPx',
      'metersPerPixel',
      'bearingCorner0toCorner1',
    ],
    imageId,
    borderRectFeature: feature,
    mapinst,
  })

  props['centerCoords'] = centerCoords
  return props
}

const ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE = true

/**
 * Mapbox Draw's dragging just translates coordinates as they were so, as degrees get narrower towards the poles,
 * the rectangle changes effective shape on the ground - towards the poles the width is fewer metres than it was at the equator.
 * For larger areas this affects the top differently to the bottom!
 *
 * This fn uses turfjs distance functions to re-lay-out the image on the globe using a center coordinate and metersPerPixel.
 *
 * For a non-rotated (level, top at 90 degrees) rectangle we could assume a relatively simple adjustment to stretch the top and bottom widths
 * based on the desired aspect ratio of the rect at ground level (image dimensions in pixels) and its given height in degrees.
 * But for a rotated rectangle the height is not meaningful so we do not have enough information to simply offset its corners.
 *
 * So for a rotated rectangle we effectively need to redraw it out from scratch using the desired globe position, the side lengths in metres,
 * and the rotation angle.
 *
 * If applyToFeature is false this fn just returns the new coordinates.
 * If applyToFeature is true the new coords are applied to the given feature and the feature re-added to MapboxDraw
 *
 * Called by:
 *   onImageBorderRectMoved
 *   rotateImageAndContents (called by snapReferencePointsTogether)
 */
export const adjustFeatureWidthForLatitude = (
  mapinst: Map,
  imageId: string,
  feature: Feature<Polygon>,
  applyToFeature = true,
  mapboxDraw?: MapboxDraw
): FourCoordinates | undefined => {
  const debug = true
  if (debug)
    console.debug(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): called with applyToFeature: ${applyToFeature}, feature:`,
      feature
    )

  if (!ENABLE_ADJUST_FEATURE_WIDTH_FOR_LATITUDE) {
    return
  }
  if (!feature.geometry?.coordinates) {
    console.error(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): called with feature without coordinates`,
      feature
    )
    return
  }
  if (!Array.isArray(feature.geometry.coordinates)) {
    console.error(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): called with feature with coordinates that are not an array`,
      feature
    )
    return
  }

  if (feature.geometry.coordinates.length === 0) {
    console.error(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): called with feature an empty coordinates array`,
      feature
    )
    return
  }

  if (!Array.isArray(feature.geometry.coordinates[0])) {
    console.error(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): called with feature with first element of coordinates that is not an array`,
      feature
    )
    return
  }

  console.debug(
    `useEditableMapImage.adjustFeatureWidthForLatitude(): calling plotGroundRectangleForImage() with user properties from feature`,
    feature.properties
  )

  const featureProperties = getFeatureProperties(mapinst, imageId, feature)

  const newRectBorderCoordsHorizontallyAdjusted = plotGroundRectangleForImage(
    featureProperties.imageWidthPx,
    featureProperties.imageHeightPx,
    featureProperties.centerCoords,
    featureProperties.metersPerPixel,
    featureProperties.bearingCorner0toCorner1
  )

  if (debug)
    console.debug(
      `useEditableMapImage.adjustFeatureWidthForLatitude(): plotGroundRectangleForImage() returned based on centerCoords ${featureProperties.centerCoords} and metersPerPixel ${featureProperties.metersPerPixel}. applyToFeature: ${applyToFeature}, newRectBorderCoordsHorizontallyAdjusted:`,
      newRectBorderCoordsHorizontallyAdjusted
    )

  if (newRectBorderCoordsHorizontallyAdjusted) {
    if (applyToFeature) {
      if (mapboxDraw) {
        const featureId = feature.id ?? feature.properties?.id
        if (featureId) {
          if (feature) {
            const adjustedNewBorderRectCoords: FiveCoordinates = [
              ...newRectBorderCoordsHorizontallyAdjusted,
              newRectBorderCoordsHorizontallyAdjusted[0],
            ]
            if (debug)
              console.debug(
                `useEditableMapImage.adjustFeatureWidthForLatitude(): applyToFeature is ${applyToFeature}, changing border rect coords from`,
                feature.geometry.coordinates[0],
                adjustedNewBorderRectCoords
              )

            feature.geometry.coordinates[0] = adjustedNewBorderRectCoords

            if (debug)
              console.debug(
                `useEditableMapImage.adjustFeatureWidthForLatitude(): changed feature, saving to MapboxDraw:`,
                feature
              )
            mapboxDraw.add(feature)
          }
        }
      } else {
        console.error(
          `useEditableMapImage.adjustFeatureWidthForLatitude(): applyToFeature true but mapboxDraw not passed`
        )
      }
    }

    // console.debug(
    //   `useEditableMapImage.adjustFeatureWidthForLatitude(): moving image reference points to newBorderRectCoords:`,
    //   newBorderRectCoords
    // )
    return newRectBorderCoordsHorizontallyAdjusted
  }
}

/**
 * Sets the borderRectFeature's coords to match that of the image Source
 *
 * Called only by onImageBorderRectDragEnd()
 */
export const moveImageBorderRectToImage = (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  borderRectFeature: Feature<Polygon>
) => {
  const debug = false

  const overlaySourceId =
    borderRectFeature.properties?.overlaySourceId ??
    borderRectFeature.properties?.user_overlaySourceId

  if (!overlaySourceId) {
    console.error(
      `useEditableMapImage.moveImageBorderRectToImage(): could not get overlaySourceId from borderRectFeature properties:`,
      borderRectFeature
    )

    return
  }
  const imageSource = mapinst.getSource(
    overlaySourceId
  ) as ImageSourceSpecification
  // console.debug(
  //   `useEditableMapImage.moveImageBorderRectToImage(): imageSource: ${imageSource}`
  // )
  if (!imageSource) {
    console.error(
      `useEditableMapImage.moveImageBorderRectToImage(): could not get imageSource using overlaySourceId '${overlaySourceId}' from borderRectFeature properties:`,
      borderRectFeature
    )
    return
  }
  const imageCoords = imageSource.coordinates
  if (!imageCoords) {
    console.error(
      `useEditableMapImage.moveImageBorderRectToImage(): imageSource with overlaySourceId '${overlaySourceId}' has no coordinates:`,
      imageSource
    )
    return
  }

  let rectCoords
  // images have 4 coordinates whereas polygons have 5
  if (imageCoords.length === 4) {
    rectCoords = [...imageCoords, imageCoords[0]]
  } else {
    rectCoords = [...imageCoords]
  }

  borderRectFeature.geometry.coordinates[0] = rectCoords

  mapboxDraw.add(borderRectFeature)

  if (debug)
    console.debug(
      `useEditableMapImage.moveImageBorderRectToImage(): updated borderRectFeature's coordinates to match that of image:`,
      imageCoords,
      borderRectFeature
    )
}

const rotateImageAndContents = async (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  bearingDiff: number,
  centerCoords: number[],
  alsoRotateDrawnBoundaryRect: boolean,
  imageReferencePointMarkers: Marker[] | undefined
) => {
  const debug = false
  if (debug)
    console.debug(
      `MapImageHelpers.rotateImageAndContents(): called with imageId: ${imageId}, bearingDiff: ${bearingDiff}, centerCoords: ${centerCoords}`
    )

  const borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)

  if (!borderRectFeature) {
    return
  }
  //update the feature's user property with the new bearing
  recalcImagePropertiesForRotate(
    mapinst,
    imageId,
    borderRectFeature,
    null,
    bearingDiff
  )

  //rebuild the rectangle's coordinates - will use the new bearing in the user property
  const adjustedRotatedBorderRectCoords = adjustFeatureWidthForLatitude(
    mapinst,
    imageId,
    borderRectFeature,
    false, // change the borderRectFeature
    mapboxDraw
  )

  if (!adjustedRotatedBorderRectCoords) {
    return
  }

  //move the image to the adjusted coords
  const imageSourceId = getImageSourceId(imageId)
  const imageSource = mapinst.getSource(imageSourceId) as ImageSource
  imageSource.setCoordinates(adjustedRotatedBorderRectCoords)

  const lengths = getSideLengthsInMetresFromFeature(
    mapinst,
    imageId,
    mapboxDraw,
    borderRectFeature
  )
  if (!lengths) {
    console.error(
      `MapImageHelpers.rotateImageAndContents(): getSideLengthsInMetresFromFeature() returned null, borderRectFeature:`,
      borderRectFeature
    )
    return
  }
  const [corner0toCorner1LengthMeters, corner0toCorner3LengthMeters] = lengths

  if (debug)
    console.debug(
      `MapImageHelpers.rotateImageAndContents(): calling repositionImageReferencePoints() corner0toCorner1LengthMeters: ${corner0toCorner1LengthMeters}, corner0toCorner3LengthMeters: ${corner0toCorner3LengthMeters}`
    )
  repositionImageReferencePoints(
    mapinst,
    imageId,
    adjustedRotatedBorderRectCoords,
    corner0toCorner1LengthMeters,
    corner0toCorner3LengthMeters,
    imageReferencePointMarkers
  )

  if (alsoRotateDrawnBoundaryRect && mapboxDraw) {
    // calls recalcFeatureUserPropsForResize()
    //   (which calls recalcFeatureUserCenterCoordsBasedOnRect())
    // and recalcFeatureUserPropsForRotate()
    moveImageBorderRect(
      mapinst,
      mapboxDraw,
      imageId,
      adjustedRotatedBorderRectCoords
    )
  }
}

const shiftPoints = <T extends ArrayOfTwoNumbers[]>(
  prevCoords: T,
  shiftX: number,
  shiftY: number
): T => {
  const res = prevCoords.map(
    prevCoord => [prevCoord[0] - shiftX, prevCoord[1] - shiftY] //as ArrayOfTwoNumbers
  )
  return res as T
}

/**
 *
 * some positional info is cached in the feature's properties to enable:
 *   ground size (in meters) remains static whilst image is dragged around the globe so changing degree width can be ignored
 *   center of the rect remains static whilst image is resized by scroll wheel or as part of snapping
 */
export const recalcImagePropertyCenterCoordsBasedOnRect = (
  mapinst: Map,
  imageId: string,
  feature: Feature<Polygon>
) => {
  const debug = true
  // image may have been dragged - calculate new centerCoords using turf.js
  const newCenterCoords = center(feature).geometry.coordinates
  if (debug) {
    //let previousCenterCoords =
    //  feature.properties?.centerCoords ?? feature.properties?.user_centerCoords
    let previousCenterCoords = getImageProperty({
      propName: 'centerCoords',
      imageId,
      borderRectFeature: feature,
      mapinst,
    })
    if (previousCenterCoords) {
      // take a copy due to suspicion of console.debug() logging the future-changed value
      previousCenterCoords = [...previousCenterCoords]
    }
    console.debug(
      `useEditableMapImage.recalcFeatureUserCenterCoordsBasedOnRect(): previousCenterCoords from user property: ${previousCenterCoords}, turf.js center() returns coords: ${newCenterCoords}, replacing feature user property. feature:`,
      feature
    )
  }

  updateImageProperty({
    propName: 'centerCoords',
    propValue: newCenterCoords,
    imageId,
    borderRectFeature: feature,
    mapinst,
  })

  // if (debug)
  //   console.debug(
  //     `useEditableMapImage.adjustFeatureWidthForLatitude(): calling newRectBorderCoordsHorizontallyAdjusted()... feature:`,
  //     feature
  //   )

  return newCenterCoords
}

/**
 * Called by: moveImageBorderRect()
 *            rotateImageAndContents() (called by snapReferencePointsTogether())
 *
 */
const recalcImagePropertiesForRotate = (
  mapinst: Map,
  imageId: string,
  feature: Feature<Polygon>,
  overrideBearingBetween0and1To: number | null = null,
  incrementBearingBetween0and1With: number | null = null
) => {
  const coords = feature.geometry.coordinates[0]

  let bearingBetween0and1 = overrideBearingBetween0and1To
  if (overrideBearingBetween0and1To === null) {
    if (incrementBearingBetween0and1With !== null) {
      let existingBearing = getImageProperty({
        propName: 'bearingCorner0toCorner1',
        imageId: imageId,
        borderRectFeature: feature,
      })
      if (existingBearing === undefined || existingBearing == null) {
        existingBearing = 90
      }
      bearingBetween0and1 = existingBearing + incrementBearingBetween0and1With
    } else {
      bearingBetween0and1 = getRoundedBearingBetween0and1(coords)
    }
  }

  if (!feature.properties) {
    feature.properties = {}
  }
  console.debug(
    `recalcFeatureUserPropsForRotate(): setting feature prop 'bearingCorner0toCorner1' to: ${bearingBetween0and1}`
  )

  updateImageProperty({
    propName: 'bearingCorner0toCorner1',
    propValue: bearingBetween0and1,
    imageId,
    borderRectFeature: feature,
    mapinst,
  })
}

/**
 * metersPerPixel is used to redraw the image to match the correct ground size/ratio as the image is dragged around
 *
 * called by: moveImageBorderRect() - snapReferencePointsTogether() or wheel scrolled over image to resize it
 *            EditableMapImage.onImageBorderRectMoved() - user drags image around or image first added
 *
 * This calls recalcFeatureUserCenterCoordsBasedOnRect()
 *
 * NOTE!! after this is called the feature must be 'saved' back to MapboxDraw by calling mapboxDraw.add(borderRectFeature)
 *
 * Perhaps you want to call recalcFeatureUserPropsForRotate() too
 */
export const recalcImagePropertiesForResize = (
  mapinst: Map,
  imageId: string,
  feature: Feature<Polygon>
) => {
  const debug = true
  // image resize will have changed center
  if (feature.properties) {
    const newCenterCoords = center(feature).geometry.coordinates
    if (debug)
      console.debug(
        `useEditableMapImage.recalcImagePropertiesForResize(): recalcCenterCoords is true, feature.properties?.user_centerCoords: ${feature.properties?.user_centerCoords}, turf.js center() returns coords: ${newCenterCoords}; feature:`,
        feature
      )

    const coords = feature.geometry.coordinates[0]
    //should this be rhumbDistance or distance?
    const metersFrom0to1 = rhumbDistance(coords[0], coords[1], {
      units: 'metres',
    })

    // calc metersPerPixel as we have image width/height in pixels
    const widthPx = getImageProperty({
      propName: 'imageWidthPx',
      imageId,
      borderRectFeature: feature,
      mapinst,
    })

    const newMetersPerPixel = metersFrom0to1 / widthPx

    updateImageProperty({
      propName: 'metersPerPixel',
      propValue: newMetersPerPixel,
      imageId: imageId,
      borderRectFeature: feature,
      mapinst,
    })

    recalcImagePropertyCenterCoordsBasedOnRect(mapinst, imageId, feature)
  }

  // if (debug)
  //   console.debug(
  //     `useEditableMapImage.adjustFeatureWidthForLatitude(): calling newRectBorderCoordsHorizontallyAdjusted()... feature:`,
  //     feature
  //   )
}

/**
 *
 * Moves the image border rectangle - a MapboxDraw polygon feature - to the given coords
 *
 * Called by scaleImageAndContents()
 *           rotateImageAndContents()
 *           moveImageAndContentsToFirstReferencePoint()
 *
 *
 * Calls recalcFeatureUserPropsForResize() (which calls
 *  recalcFeatureUserCenterCoordsBasedOnRect())
 * and recalcFeatureUserPropsForRotate()
 *
 */
const moveImageBorderRect = (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  newBoundingRectCoords: number[][]
) => {
  if (newBoundingRectCoords.length === 4) {
    //duplicate the first coord to the end
    newBoundingRectCoords = [...newBoundingRectCoords, newBoundingRectCoords[0]]
  }

  const borderRectFeature = getImageBorderRectFeature(mapboxDraw, imageId)
  if (borderRectFeature) {
    borderRectFeature.geometry.coordinates = [newBoundingRectCoords]

    //calls recalcFeatureUserCenterCoordsBasedOnRect()
    recalcImagePropertiesForResize(mapinst, imageId, borderRectFeature)

    recalcImagePropertiesForRotate(mapinst, imageId, borderRectFeature)

    mapboxDraw.add(borderRectFeature)
  }
}

/**
 *  called by snapReferencePointsTogether()
 *
 */
const moveImageAndContentsToFirstReferencePoint = (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  targetReferencePointCoordinates: number[][],
  alsoMoveDrawnBorderRect: boolean,
  selectedImageReferenceMarkers?: Marker[]
) => {
  const debug = false

  if (debug)
    console.debug(
      `moveImageAndContentsToFirstReferencePoint(): moving image ${imageId} to match its global reference points`,
      targetReferencePointCoordinates
    )

  const imageSourceId = getImageSourceId(imageId)
  const imageSource = mapinst.getSource(imageSourceId) as ImageSource

  const imageLayerId = getImageLayerId(imageId)
  const imageLayer = mapinst.getLayer(imageLayerId) as LayerSpecification

  if (!imageLayer) {
    console.error(
      `moveImageAndContentsToFirstReferencePoint(): did not get imageLayer for imageId: ${imageId}, imageLayerId ${imageLayerId}`
    )
    return
  }

  const imageReferencePoints = [1, 2].map(idx =>
    getImageProperty({
      propName: `referencePoint${idx}GlobalCoords`,
      imageId,
      imageLayer,
    })
  )

  const point0coords = imageReferencePoints[0]

  const degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Width =
    point0coords[0] - targetReferencePointCoordinates[0][0]
  const degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Height =
    point0coords[1] - targetReferencePointCoordinates[0][1]

  if (!(imageSource as ImageSourceSpecification).coordinates) {
    console.error(
      `moveImageAndContentsToFirstReferencePoint(): imageSource for imageSourceId ${imageSourceId} has no coordinates!?`,
      imageSource
    )
    return
  }
  const existingImageCoords = (
    (imageSource as ImageSourceSpecification).coordinates as RectCoordinates
  ).slice(0, 4) as FourCoordinates
  if (debug)
    console.debug(
      `moveImageAndContentsToFirstReferencePoint(): existing coords from imageSource:`,
      existingImageCoords
    )

  if (debug)
    console.debug(
      `moveImageAndContentsToFirstReferencePoint(): point0coords: ${point0coords}, targetReferencePointCoordinates[0]: ${targetReferencePointCoordinates[0]}, degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Width: ${degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Width}, ${degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Height}`
    )

  // shift the imageSource's coords (in degrees) by the num degrees needed to move
  // image reference point 0 to the same coords as globe reference point 0
  const newCoords = shiftPoints(
    existingImageCoords,
    degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Width,
    degreesBetweenImageReferencePoint0AndGlobeReferencePoint0Height
  )

  if (debug)
    console.debug(
      `moveImageAndContentsToFirstReferencePoint(): moving image to newCoords`,
      newCoords
    )

  imageSource.setCoordinates(newCoords)

  const lengths = getSideLengthsInMetresFromFeature(
    mapinst,
    imageId,
    mapboxDraw
  )
  if (!lengths) {
    return
  }
  const [corner0toCorner1LengthMeters, corner0toCorner3LengthMeters] = lengths

  if (debug)
    console.debug(
      `moveImageAndContentsToFirstReferencePoint(): calling repositionImageReferencePoints(corner0toCorner1LengthMeters: ${corner0toCorner1LengthMeters})...`
    )
  if (!selectedImageReferenceMarkers) {
    console.error(
      `moveImageAndContentsToFirstReferencePoint(): selectedImageReferenceMarkers not set, image reference markers won't be moved`
    )
    console.trace()
  }
  repositionImageReferencePoints(
    mapinst,
    imageId,
    newCoords,
    corner0toCorner1LengthMeters,
    corner0toCorner3LengthMeters,
    selectedImageReferenceMarkers
  )

  if (alsoMoveDrawnBorderRect && mapboxDraw) {
    moveImageBorderRect(mapinst, mapboxDraw, imageId, newCoords)
  }
} // end moveImageAndContentsToFirstReferencePoint()

/**
 *
 * Called from button click in SnapButtonJsx returned by useEditableMapImage
 */
export const snapReferencePointsTogether = async (
  mapinst: Map,
  mapboxDraw: MapboxDraw,
  imageId: string,
  globeReferencePoints: number[][],
  imageReferencePointMarkers: Marker[] | undefined
) => {
  if (!mapinst) {
    return
  }
  const debug = false

  const imageLayerId = getImageLayerId(imageId)
  const imageLayer = mapinst.getLayer(imageLayerId) as LayerSpecification

  if (!imageLayer) {
    console.error(
      `snapReferencePointsTogether(): did not get imageLayer for imageId: ${imageId}, imageLayerId ${imageLayerId}`
    )
    return
  }

  if (debug)
    console.debug(
      `snapReferencePointsTogether(): called with imageId: ${imageId}, imageLayer:`,
      imageLayer
    )

  const imageReferencePoints = [1, 2].map(idx =>
    getImageProperty({
      propName: `referencePoint${idx}GlobalCoords`,
      imageId,
      imageLayer,
    })
  )

  if (debug) {
    console.debug(
      `snapReferencePointsTogether(): imageReferencePoints: ${imageReferencePoints}`
    )

    console.debug(
      `snapReferencePointsTogether(): globeReferencePoints:`,
      globeReferencePoints
    )
  }

  if (globeReferencePoints.length !== 2) {
    console.error(
      `MapImageHelpers.snapReferencePointsTogether(): globeReferencePoints.length is not 2 but: ${globeReferencePoints.length}`
    )
    return
  }

  //get the bearing between the two IMAGE reference points
  const imageReferencePointsBearing = rhumbBearing(
    imageReferencePoints[0],
    imageReferencePoints[1]
  )

  if (debug)
    console.debug(
      `snapReferencePointsTogether(): imageReferencePointsBearing: ${imageReferencePointsBearing}`
    )

  //...and get the bearing between the two GLOBAL reference points
  const globeReferencePointsBearing = rhumbBearing(
    globeReferencePoints[0],
    globeReferencePoints[1]
  )

  if (debug)
    console.debug(
      `snapReferencePointsTogether(): globeReferencePointsBearing: ${globeReferencePointsBearing}`
    )

  const bearingDiff = globeReferencePointsBearing - imageReferencePointsBearing

  // rotate the image so the image reference point bearing is the same as the globals' bearing
  const imageSourceId = getImageSourceId(imageId)
  const imageSource = mapinst.getSource(
    imageSourceId
  ) as ImageSourceSpecification
  if (!imageSource) {
    console.error(
      `MapImageHelpers.snapReferencePointsTogether(): could not find imageSource under id '${imageSourceId}'`
    )
    return
  }

  const imageCenterCoords = getImageProperty({
    propName: 'centerCoords',
    imageId,
    mapinst,
    mapboxDraw,
  })

  if (debug) {
    console.debug(
      `MapImageHelpers.snapReferencePointsTogether(): image outer box imageCenterCoords: ${imageCenterCoords}`
    )

    console.debug(
      `MapImageHelpers.snapReferencePointsTogether(): calling rotateImageAndContents()...`
    )
  }

  //const [adjustedImgUrl, imageLevelOutsideRectCoordinates] =
  await rotateImageAndContents(
    mapinst,
    mapboxDraw,
    imageId,
    bearingDiff,
    imageCenterCoords,
    true, // alsoRotateDrawnBoundaryRect
    imageReferencePointMarkers // it will move these Markers if they are provided
  )

  if (debug)
    console.debug(
      `AnnotatedImage.onRotationStopped(): rotateImageAndContents() returned`
    )

  setTimeout(
    () => {
      console.debug(
        `AnnotatedImage.onRotationStopped(): calling scaleImageAndContentsToGlobeReferencePoints()...`
      )
      // then resize image to match width
      scaleImageAndContentsToGlobeReferencePoints(
        mapinst,
        mapboxDraw,
        imageId,
        globeReferencePoints,
        true, //alsoScaleDrawnBorderRect
        imageReferencePointMarkers
      )
      //then move image to position over the globe reference points
      setTimeout(
        () => {
          moveImageAndContentsToFirstReferencePoint(
            mapinst,
            mapboxDraw,
            imageId,
            globeReferencePoints,
            true,
            imageReferencePointMarkers
          )
        },
        DELAY_BETWEEN_SNAP_STEPS ? 1000 : 100
      )
    },
    DELAY_BETWEEN_SNAP_STEPS ? 1000 : 100
  )
}

// export const calculateDegreeRatioAtLatitude = (latiDegrees: number) => {
//   const latiRads = degreesToRads(latiDegrees)
//   const cos = Math.abs(Math.cos(latiRads))
//   const degreesRatioAtLatitude = 1 / cos
//   return degreesRatioAtLatitude
// }
