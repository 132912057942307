import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Box, Stack } from '@mui/material'

import { isEmpty } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'
import { selectUser } from 'src/modules/auth/authSlice'
import { updateTree } from 'src/modules/trees/treesSlice'
import { EditTreeName } from 'src/modules/trees/EditTreeName'
import { Button, Container, Typography } from 'src/modules/ui'

import { EditTreeContainer } from './treeViewers'
import { selectNodeDirectory } from './viewerSlice'
import { generateTreeHomeLink, INSTANCE_TYPE_TREE } from '../app/links'
import {
  clearAddIndividualNode,
  clearEditedIndividualId,
  setExploredIndividualId,
} from './exploreTreeSlice'
import { useSetTree } from '../app'
import {
  ACTION_ALL_ACCESS,
  ACTION_CREATE,
  ACTION_EDIT_TREE_INDIVIDUALS,
} from '../app/appConstants'

const STATE_EDITING = 'editing'
const STATE_NAMING = 'naming'

export function EditTree() {
  const dispatch = useDispatch()
  const history = useHistory()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const hasOneOrMoreIndividuals = !isEmpty(nodeDirectory)
  const setTree = useSetTree()

  const [isFirstEdit] = useState(isEmpty(nodeDirectory))

  const initialCreate = isEmpty(nodeDirectory)

  const user = useSelector(selectUser)
  const [state, setState] = useState(STATE_EDITING)

  useEffect(() => {
    dispatch(setExploredIndividualId(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [savingTree, setSavingTree] = useState(false)
  const dispatchUpdateTree = useActionDispatcher(updateTree)
  const [name, setName] = useState(user.currentTree.name)

  const handleTreeNameChanged = useCallback(name => {
    setName(name)
  }, [])

  const handleDoneNaming = useCallback(async () => {
    setTree(user.currentTree.slug, true)
    if (name !== user.currentTree.name) {
      setSavingTree(true)
      await dispatchUpdateTree(
        { treeSlug: user.currentTree.slug, name },
        { successNotification: 'Tree name updated' }
      )
      setSavingTree(false)
    }
    history.push(generateTreeHomeLink(user.currentTree.slug))
  }, [dispatchUpdateTree, history, name, user, setTree])

  const handleDoneEditing = useCallback(() => {
    dispatch(clearAddIndividualNode())
    dispatch(clearEditedIndividualId())
    setTree(user.currentTree.slug, true)
    if (isFirstEdit) {
      setState(STATE_NAMING)
    } else {
      history.goBack()
    }
  }, [dispatch, isFirstEdit, history, user, setTree])

  const handleBack = useCallback(() => {
    setState(STATE_EDITING)
  }, [])

  return (
    <Container>
      {state === STATE_EDITING && (
        <>
          {initialCreate && (
            <Typography variant="h1">
              Let's create your family archive
            </Typography>
          )}
          {hasOneOrMoreIndividuals && (
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Click a person to edit them or add their relations
            </Typography>
          )}
          <Box sx={{ mt: 4, position: 'relative' }}>
            <Button
              permissionAction={ACTION_EDIT_TREE_INDIVIDUALS}
              disabled={!hasOneOrMoreIndividuals}
              onClick={handleDoneEditing}
              sx={{ position: 'absolute', top: 16, right: 16, zIndex: 5 }}
            >
              Done
            </Button>
            <EditTreeContainer></EditTreeContainer>
          </Box>
        </>
      )}
      {state === STATE_NAMING && (
        <>
          <Typography variant="h1">Name your archive</Typography>
          <Box sx={{ width: 600, margin: '0 auto', mt: 4 }}>
            <EditTreeName
              initialName={user.currentTree.name}
              onTreeNameChanged={handleTreeNameChanged}
              isExistingTree={true}
              autoSave={true}
            />
            <Stack direction="row" sx={{ mt: 4, justifyContent: 'center' }}>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                onClick={handleBack}
                color="secondary"
              >
                Back
              </Button>
              <Button
                permissionAction={ACTION_CREATE}
                permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                disabled={savingTree}
                onClick={handleDoneNaming}
              >
                Done
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Container>
  )
}
