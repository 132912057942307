import api, { createWrappedAsyncThunk } from 'src/api'

const SLICE_NAME = 'visibility'

export const setVisibility = createWrappedAsyncThunk(
  `${SLICE_NAME}/setVisibility`,
  ({ instanceId, visibility }) => {
    return api.patch(`/visibility/${instanceId}/set/`, {
      body: { visibility },
    })
  }
)
