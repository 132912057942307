import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { useEffect, useState } from 'react'
import Button from './Button'
import { useNotification } from '../app/hooks'
import { dayCount } from '../common/gedcomDateUtils'
import {
  gedcomObjToDateStr,
  gedcomStrToDateObj,
  validateBetween,
} from '../common/gedcomDateParser'
import api from 'src/api'
import CloseIcon from '@mui/icons-material/Close'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const Selector = ({ data, label, value, defaultValue, onChange, disabled }) => {
  const menuItems = data.map((dataItem, index) => (
    <MenuItem key={index} value={dataItem.value}>
      {dataItem.text}
    </MenuItem>
  ))

  return (
    <FormControl sx={{ marginLeft: 1 }}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        fullWidth
        defaultValue={defaultValue}
        value={value}
        label={label}
        onChange={onChange}
        sx={{ minWidth: 100 }}
      >
        {menuItems}
      </Select>
    </FormControl>
  )
}

const dateType = [
  {
    value: 'exact',
    text: 'Exact',
  },
  {
    value: 'about',
    text: 'About',
  },
  {
    value: 'before',
    text: 'Before',
  },
  {
    value: 'after',
    text: 'After',
  },
  {
    value: 'between',
    text: 'Between',
  },
  {
    value: 'free-text',
    text: 'Free Text',
  },
]

export const months = [
  {
    value: 0,
    text: 'Unknown',
  },
  {
    value: 1,
    text: 'Jan',
  },
  {
    value: 2,
    text: 'Feb',
  },
  {
    value: 3,
    text: 'Mar',
  },
  {
    value: 4,
    text: 'Apr',
  },
  {
    value: 5,
    text: 'May',
  },
  {
    value: 6,
    text: 'Jun',
  },
  {
    value: 7,
    text: 'Jul',
  },
  {
    value: 8,
    text: 'Aug',
  },
  {
    value: 9,
    text: 'Sep',
  },
  {
    value: 10,
    text: 'Oct',
  },
  {
    value: 11,
    text: 'Nov',
  },
  {
    value: 12,
    text: 'Dec',
  },
]

const DatePickerForm = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isEnd,
  selectedType,
  textInput,
  setTextInput,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const selectedDate = isEnd ? endDate : startDate
  const setSelectedDate = isEnd ? setEndDate : setStartDate

  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const selectorProps = {
    selectedDate,
    defaultValue: 0,
    isEnd,
  }

  const updateDate = newDate => {
    const availableDays = dayCount(newDate.month, newDate.year)
    const index = availableDays.length - 1
    if (selectedDate.day > availableDays[index].value) {
      setSelectedDate({ ...newDate, day: availableDays[index].value })
    } else {
      setSelectedDate(newDate)
    }
  }

  const maxYear = new Date().getFullYear()

  const handleClearInput = () => {
    if (selectedType === 'free-text') {
      setTextInput('')
    } else {
      setSelectedDate({ year: '' })
    }
  }

  const tooltipText =
    'We only accept dates that fit the GEDCOM 5.5.1 specification, any other dates will be rejected.'

  return (
    <Box
      pl={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {selectedType === 'free-text' ? (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            placeholder="Write a date"
            value={textInput}
            fullWidth
            onChange={e => setTextInput(e.target.value)}
            sx={{ minWidth: 300, alignSelf: 'center' }}
          />
          <Tooltip
            title={tooltipText}
            placement={mobileBreakpoint ? 'top' : 'right'}
            arrow
            open={tooltipOpen}
          >
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              onMouseLeave={() => setTooltipOpen(false)}
              onMouseOver={() => setTooltipOpen(true)}
            >
              <InfoOutlinedIcon ml={1} color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <>
          <Selector
            label="Day"
            data={dayCount(selectedDate.month, selectedDate.year)}
            value={selectedDate.day}
            onChange={e => updateDate({ ...selectedDate, day: e.target.value })}
            {...selectorProps}
          />
          <Selector
            label="Month"
            data={months}
            value={selectedDate.month}
            onChange={e =>
              updateDate({ ...selectedDate, month: e.target.value })
            }
            setValue={e =>
              setSelectedDate({ ...selectedDate, month: e.target.value })
            }
            {...selectorProps}
          />
          <TextField
            sx={{ marginLeft: 1, width: '25%', minWidth: 100 }}
            label={selectedDate.year ? 'Year' : 'Unknown Year'}
            type={'number'}
            value={selectedDate.year}
            onChange={e =>
              updateDate({ ...selectedDate, year: e.target.value })
            }
            inputProps={{ min: 1, max: maxYear }}
          />
        </>
      )}
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => handleClearInput()}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

const WeAreDatePicker = ({ trigger, inputDate = '', onFinishSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState({})
  const [endDate, setEndDate] = useState({})
  const [selectedType, setSelectedType] = useState('exact')
  const [textInput, setTextInput] = useState(inputDate)

  const { showError } = useNotification()

  const handleCloseModal = () => {
    setStartDate({})
    setEndDate({})
    setSelectedType('exact')
    setModalOpen(false)
  }

  useEffect(() => {
    const setupDate = () => {
      let gedcomDateObj
      if (!inputDate) {
        setStartDate({
          year: '',
          month: '',
          day: '',
        })
        setSelectedType('exact')
        return
      }
      try {
        gedcomDateObj = gedcomStrToDateObj(inputDate)
      } catch (e) {
        console.error('Error parsing date', e)
      }

      const dateType = gedcomDateObj.type

      if (dateType === 'range') {
        const startDate = gedcomDateObj.startDate
        const endDate = gedcomDateObj.endDate

        if (!startDate) {
          setStartDate({
            year: endDate.year || '',
            month: endDate.month || '',
            day: endDate.day || '',
          })
          setSelectedType('before')
        } else if (!endDate) {
          setStartDate({
            year: startDate.year || '',
            month: startDate.month || '',
            day: startDate.day || '',
          })

          setSelectedType('after')
        } else {
          setStartDate({
            year: startDate.year || '',
            month: startDate.month || '',
            day: startDate.day || '',
          })
          setEndDate({
            year: endDate.year || '',
            month: endDate.month || '',
            day: endDate.day || '',
          })

          setSelectedType('between')
        }
      } else {
        setStartDate({
          year: gedcomDateObj.year || '',
          month: gedcomDateObj.month || '',
          day: gedcomDateObj.day || '',
        })
        setSelectedType(dateType)
      }
    }
    try {
      setupDate()
    } catch (err) {
      setSelectedType('free-text')
      setTextInput(inputDate)
    }
  }, [inputDate])

  const datePickerFormProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedType,
    textInput,
    setTextInput,
  }

  const validateDate = async dateStr => {
    const queryStringParameters = {
      dt: dateStr,
    }
    const validate = await api.get('/history/validate/geddate/', {
      queryStringParameters,
    })
    if (!validate.status) {
      showError("Oops, this isn't a correct date. Please try again")
    } else {
      if (selectedType === 'between') {
        const isValid = validateBetween(dateStr)
        if (!isValid) {
          showError('The first date must be earlier than the last date')
        } else return isValid
      } else return validate.status
    }
  }

  const yearExists = dateObj => {
    if ((!dateObj.year && dateObj.day) || (!dateObj.year && dateObj.month)) {
      showError("Oops, this isn't a correct date. Please try again")
      return false
    } else return true
  }

  const handleSubmit = async () => {
    let type = ''
    let dateObj = {}
    if (selectedType === 'exact' || selectedType === 'about') {
      if (!yearExists(startDate)) {
        return false
      } else {
        type = selectedType
        dateObj = {
          ...startDate,
          type,
        }
      }
    } else {
      type = 'range'
      dateObj = {
        type,
      }
      if (selectedType === 'after') {
        if (!yearExists(startDate)) {
          return false
        } else {
          dateObj = { ...dateObj, startDate }
        }
      } else if (selectedType === 'before') {
        if (!yearExists(startDate)) {
          return false
        } else {
          dateObj = { ...dateObj, endDate: startDate }
        }
      } else if (selectedType === 'between') {
        if (!yearExists(endDate)) {
          return false
        } else {
          dateObj = { ...dateObj, startDate, endDate }
        }
      }
    }

    let gedcomString = ''
    if (selectedType === 'free-text') {
      gedcomString = textInput
    } else {
      gedcomString = gedcomObjToDateStr(dateObj)
    }

    try {
      if (gedcomString) {
        const isValid = await validateDate(gedcomString)

        if (isValid) {
          onFinishSubmit(gedcomString)
          handleCloseModal()
        }
      } else {
        onFinishSubmit(gedcomString)
        handleCloseModal()
      }
    } catch (error) {
      showError(error.message)
      console.error(error.message)
    }
  }

  const handleShowModal = () => {
    setModalOpen(true)
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen}>
        <DialogContent
          sx={{
            mr: 1,
            // pr: 1,
            ml: 1,
            // pl: 1,
          }}
        >
          <DialogTitle>Select date</DialogTitle>
          <Box
            mt={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ minWidth: '22%' }}>
              <Selector
                data={dateType}
                defaultValue={'exact'}
                value={selectedType}
                onChange={e => setSelectedType(e.target.value)}
              />
            </Box>
            <DatePickerForm {...datePickerFormProps} />
          </Box>
          {selectedType === 'between' ? (
            <Box
              mt={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ minWidth: '22%' }}>
                <Typography ml={1}>And</Typography>
              </Box>
              <DatePickerForm {...datePickerFormProps} isEnd={true} />
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions sx={{ mr: 1, mb: 1 }}>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            color="secondary"
            variant="outlined"
            onClick={handleCloseModal}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button permissionAction={ACTION_ALL_ACCESS} onClick={handleSubmit}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default WeAreDatePicker
