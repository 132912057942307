import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  selectUser,
  selectLoggedInUserIsAdminOnCurrentTree,
} from 'src/modules/auth/authSlice'
import LinkChips from 'src/modules/content/LinkChips'
import { fetchContent, selectContent } from 'src/modules/content/contentSlice'
import Comments from 'src/modules/content/Comments'
import InformationRequestDialog from 'src/modules/informationRequest/InformationRequestDialog'
import { selectLinkedPageItem } from 'src/modules/page/pageSlice'
import { Container, PageTitle, Typography } from 'src/modules/ui'

import InformationRequestHeaderMeta from './InformationRequestHeaderMeta'
import InformationRequestBody from './InformationRequestBody'
import {
  createSeenInfoRequest,
  closeInformationRequest,
  reopenInformationRequest,
} from './informationRequestSlice'
import {
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_INFORMATION_REQUEST,
} from '../app/links'
import { formatIndividualName } from '../ui/individualUtils'
const useStyles = makeStyles(theme => ({
  body: {
    marginBottom: theme.spacing(4),
  },
  comments: {
    maxWidth: 600,
  },
  date: {
    marginBottom: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

export const InformationRequest = ({ informationRequest, className }) => {
  const { comments, contentBlocks, id, infoRequestTos, links, title } =
    informationRequest
  const user = useSelector(selectUser)
  const userIsAdmin = useSelector(selectLoggedInUserIsAdminOnCurrentTree)
  const userIsAuthor = user.id === informationRequest.author
  const canCloseAndReopen = userIsAdmin || userIsAuthor

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const fetchedLinkedPage = useSelector(selectLinkedPageItem)

  const dispatchFetchContent = useActionDispatcher(fetchContent)
  const dispatchCloseRequest = useActionDispatcher(closeInformationRequest)
  const dispatchReopenRequest = useActionDispatcher(reopenInformationRequest)
  const dispatchCreateSeenInfoRequest = useActionDispatcher(
    createSeenInfoRequest
  )
  useEffect(() => {
    dispatchCreateSeenInfoRequest({ id })
  }, [dispatchCreateSeenInfoRequest, id])

  const handleInfoRequestSent = () => {
    dispatchFetchContent(id)
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleCloseRequest = () =>
    dispatchCloseRequest(id, {
      successNotification: 'Request closed',
    })
  const handleReopenRequest = () =>
    dispatchReopenRequest(id, {
      successNotification: 'Request reopened',
    })

  const recipients = infoRequestTos.map((param, idx) => {
    return {
      target: param.id,
      instanceType: INSTANCE_TYPE_INDIVIDUAL,
      display: formatIndividualName(param),
    }
  })

  return (
    <>
      <Container className={className}>
        <div>
          <div className={classes.header}>
            <div className={classes.titleContainer}>
              <PageTitle
                backButton
                type={INSTANCE_TYPE_INFORMATION_REQUEST}
                entityTypeTitle="Ask Family Request"
                title={title || 'Ask Family Request'}
                fetchedLinkedPage={fetchedLinkedPage}
              />
              <InformationRequestHeaderMeta
                {...informationRequest}
                onClickCloseRequest={handleCloseRequest}
                canCloseAndReopen={canCloseAndReopen}
                onClickEdit={handleOpen}
                onClickReopenRequest={handleReopenRequest}
              />
              <Box sx={{ display: 'flex', mt: 1 }}>
                <Typography mr={1}>To:</Typography>
                <LinkChips links={recipients} />
              </Box>
              {links.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Related to:
                  </Typography>
                  <LinkChips links={links} />
                </Box>
              )}
            </div>
          </div>
          <InformationRequestBody
            className={classes.body}
            contentBlocks={contentBlocks}
          />
        </div>
        <Comments
          canComment
          comments={comments}
          className={classes.comments}
          contentId={id}
          responseName="reply"
          user={user}
          initialPageSize={5}
        />
      </Container>
      <InformationRequestDialog
        informationRequest={informationRequest}
        onInfoRequestSent={handleInfoRequestSent}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

const InformationRequestContainer = ({ match }) => {
  const { id } = match.params
  const dispatchFetchContent = useActionDispatcher(fetchContent)
  const informationRequest = useSelector(selectContent)

  useEffect(() => {
    dispatchFetchContent(id)
  }, [dispatchFetchContent, id])

  if (!informationRequest || informationRequest.id !== id) {
    return <Container />
  }

  return <InformationRequest informationRequest={informationRequest} />
}

export default InformationRequestContainer
