import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk } from 'src/api'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from 'src/modules/app/links'

const SLICE_NAME = 'search'
export const DEFAULT_SEARCH_LIMIT = 200

export const DEFAULT_TYPES = [
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_LOCATION,
]

export const searchNameCompletion = createWrappedAsyncThunk(
  `${SLICE_NAME}/searchNameCompletion`,
  ({ query, sort }) => {
    const queryStringParameters = {
      q: query,
      instanceTypes: [INSTANCE_TYPE_INDIVIDUAL, INSTANCE_TYPE_FAMILY],
      offset: 0,
      limit: DEFAULT_SEARCH_LIMIT,
      sort: sort || [],
    }
    return api.get(`/search/${getTreeSlugFromStore()}/name/`, {
      queryStringParameters,
    })
  }
)

export const searchSite = createWrappedAsyncThunk(
  `${SLICE_NAME}/searchSite`,
  ({ query, instanceTypes, page }) => {
    if (!instanceTypes?.length) {
      instanceTypes = DEFAULT_TYPES
    }
    const queryStringParameters = {
      q: query,
      instanceTypes: instanceTypes,
      offset: page * DEFAULT_SEARCH_LIMIT,
      limit: DEFAULT_SEARCH_LIMIT,
    }
    return api.get(`/search/${getTreeSlugFromStore()}`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  nameCompletionResults: [],
  siteResults: [],
  siteResultsCount: 0,
}

export const searchSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearNameCompletionResults: (state, action) => {
      state.nameCompletionResults = []
    },
  },
  extraReducers: {
    [searchNameCompletion.fulfilled]: (state, { payload }) => {
      state.nameCompletionResults = payload
    },
    [searchSite.fulfilled]: (state, { payload }) => {
      state.siteResults = payload.results
      state.siteResultsCount = payload.count
    },
  },
})
export const { clearNameCompletionResults } = searchSlice.actions

export const selectNameCompletionResults = state =>
  state[SLICE_NAME].nameCompletionResults
export const selectSiteSearchResults = state => state[SLICE_NAME].siteResults
export const selectSiteSearchResultsCount = state =>
  state[SLICE_NAME].siteResultsCount
export default searchSlice.reducer
