import { Button, Typography } from 'src/modules/ui'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import clsx from 'clsx'
import { formatDraftIndividualWithDates } from '../ui/individualUtils'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  draftIndividual: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: 10,
    boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.3)',
    transitionDuration: '0.6s',
  },
  fact: props => ({
    padding: '8px 0px',
    opacity: props.importState === 'Ignored' ? 0.5 : 1,
  }),
  factUnlimitedHeight: {
    height: 'inherit',
  },
  factContainer: {
    display: 'flex',
  },
  individualOverview: props => ({
    display: 'flex',
    alignItems: 'center',
    opacity: props.importState === 'Ignored' ? 0.5 : 1,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  }),
}))

export const DraftIndividual = ({
  individual,
  nodeDirectory,
  handleResolveIgnore,
  handleResolveNew,
  handleResolveMatch,
  importState,
}) => {
  const classes = useStyles({ importState })

  const sortByName = (a, b) => {
    if (a.givenName < b.givenName) {
      return -1
    }
    if (a.givenName > b.givenName) {
      return 1
    }
    return 0
  }
  const spouses = individual.spouses
    .filter(id => !!nodeDirectory[id])
    .map(id => nodeDirectory[id])
    .sort((a, b) => sortByName(a, b))
  const children = individual.children
    ? individual.children
        .filter(id => !!nodeDirectory[id])
        .map(id => nodeDirectory[id])
        .sort((a, b) => sortByName(a, b))
    : []

  return (
    <Box
      sx={{
        background: importState === 'Ignored' ? 'rgba(0,0,0,0.5)' : 'auto',
      }}
      className={classes.draftIndividual}
    >
      <Box mb={2} pb={2} className={classes.individualOverview}>
        {individual.hasPhoto && !individual.photo ? (
          <img
            alt="Loading Spinner"
            src="/loading-spinner.svg"
            width={56}
            height={56}
          />
        ) : (
          <img
            alt={`${individual.knownAs || individual.givenName} ${
              individual.surname
            }`}
            src={
              individual.photo
                ? individual.photo.fileThumbnail
                : individual.gender === 'F'
                ? '/female-placeholder.png'
                : '/person-placeholder.png'
            }
            width={56}
            height={56}
            style={{ borderRadius: '50%' }}
          />
        )}
        <Typography ml={1} variant="subtitle1" color="primary">
          {formatDraftIndividualWithDates(individual)}
        </Typography>
      </Box>
      <Box flexDirection="row" display="flex" justifyContent="space-between">
        <Box width="66%">
          <Box className={classes.factContainer}>
            <Box width="50%" className={classes.fact}>
              <Typography variant="body2" className={classes.factTitle}>
                Given name
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {individual.givenName}
              </Typography>
            </Box>
            <Box width="50%" className={classes.fact}>
              <Typography className={classes.factTitle}>Family name</Typography>
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {individual.surname}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.fact}>
            <Typography variant="body2" className={classes.factTitle}>
              Sex
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              className={classes.factValue}
            >
              {individual.gender}
            </Typography>
          </Box>
          <Box className={classes.factContainer}>
            <Box width="50%" className={classes.fact}>
              <Typography variant="body2" className={classes.factTitle}>
                Date of birth
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {individual.dateOfBirthGed}
              </Typography>
            </Box>
            <Box width="50%" className={classes.fact}>
              <Typography variant="body2" className={classes.factTitle}>
                Date of death
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {individual.dateOfDeathGed}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.fact}>
            <Typography variant="body2" className={classes.factTitle}>
              Father
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              className={classes.factValue}
            >
              {individual.bioFather &&
                nodeDirectory[individual.bioFather] &&
                formatDraftIndividualWithDates(
                  nodeDirectory[individual.bioFather]
                )}
            </Typography>
          </Box>
          <Box className={classes.fact}>
            <Typography variant="body2" className={classes.factTitle}>
              Mother
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              className={classes.factValue}
            >
              {individual.bioMother &&
                nodeDirectory[individual.bioMother] &&
                formatDraftIndividualWithDates(
                  nodeDirectory[individual.bioMother]
                )}
            </Typography>
          </Box>
          <Box className={classes.fact}>
            <Typography variant="body2" className={classes.factTitle}>
              Spouses
            </Typography>
            {spouses.map(spouse => (
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {formatDraftIndividualWithDates(spouse)}
              </Typography>
            ))}
          </Box>
          <Box className={clsx(classes.fact, classes.factUnlimitedHeight)}>
            <Typography variant="body2" className={classes.factTitle}>
              Children
            </Typography>
            {children.map(child => (
              <Typography
                color="primary"
                variant="body1"
                className={classes.factValue}
              >
                {formatDraftIndividualWithDates(child)}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {handleResolveMatch ? (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={handleResolveMatch}
              disabled={importState === 'Matched'}
            >
              This is a match
            </Button>
          ) : (
            <>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                sx={{ marginBottom: 1 }}
                onClick={handleResolveNew}
                disabled={importState === 'New'}
              >
                Import as new
              </Button>
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                onClick={handleResolveIgnore}
                disabled={importState === 'Ignored'}
              >
                Ignore and keep existing
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
