import { useContext } from 'react'
import { Divider } from '@mui/material'

import { Typography } from 'src/modules/ui'
import {
  CardRoot,
  StyledImage,
  CardContent,
  ContentContainer,
  DateTime,
  InnerContainer,
  FooterContainer,
  FooterItemContainer,
  defaultImage,
} from 'src/modules/content/ArticleCard'
import { generatePublicLinkForObject } from 'src/modules/app/links'
import { PublicContext } from '../contexts'
import { formatIndividualName } from '../../ui/individualUtils'
import { formatDate } from '../../../utils'
import { useHistory } from 'react-router-dom'

const PublicArticleCard = ({
  isDraggable = false,
  urlOverride,
  PinPostDialog,
  content: {
    authorIndividual,
    contentBlocks = [],
    id,
    publishedAt,
    created,
    modified,
    previewThumbnail,
    state,
    title,
    author,
    type,
    contentSlug,
  },
}) => {
  const { treeSlug } = useContext(PublicContext)
  const history = useHistory()

  const url = urlOverride
    ? urlOverride(treeSlug, type, id, contentSlug)
    : generatePublicLinkForObject(treeSlug, type, id)

  const displayName = formatIndividualName(author)
  const textContent = contentBlocks.filter(block => block.type === 'TEXT')

  const handleNavigate = () => {
    history.push(url)
  }
  return (
    <>
      <CardRoot onClick={handleNavigate}>
        <StyledImage src={previewThumbnail || defaultImage} />
        <ContentContainer sx={{ ml: 2 }}>
          <InnerContainer>
            <CardContent
              {...{
                id,
                isDraggable,
                state,
                textContent,
                title,
                url,
                author,
                PinPostDialog,
              }}
            />
            <FooterContainer>
              <FooterItemContainer>
                <Typography sx={{ color: 'primary.main' }} variant="subtitle4">
                  {displayName} {formatDate(created)}
                  &nbsp;
                </Typography>
                {created !== modified && <DateTime dateTime={publishedAt} />}
              </FooterItemContainer>
            </FooterContainer>
          </InnerContainer>
        </ContentContainer>
      </CardRoot>
      <Divider sx={{ mb: 4, mt: 4 }} />
    </>
  )
}

export default PublicArticleCard
