import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Popover } from '@mui/material'

import clsx from 'clsx'

import { useActionDispatcher } from 'src/modules/app'
import { IconButton, LoadingIndicator, Typography, Link } from 'src/modules/ui'
import { selectNodeDirectory } from 'src/modules/viewer/viewerSlice'
import { DraftIndividual } from './DraftIndividual'
import { resolveUpdate, selectDraftNodeDirectory } from './treesSlice'
import { Box } from '@mui/system'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  action: {
    marginBottom: theme.spacing(1),
  },
  generalActions: {
    padding: theme.spacing(1),
  },
  candidateActions: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  candidates: {
    borderRadius: '5px',
    padding: theme.spacing(1),
    width: '50%',
  },
  draftIndividual: {
    padding: theme.spacing(1),
    width: '52%',
  },
  navigate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  resolution: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1600,
    width: '80vw',
  },
  stateChip: {
    background: '#aaa',
    marginLeft: theme.spacing(1),
  },
  matchedWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: '5px',
    color: 'white',
    fontWeight: 600,
    background: 'green',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  matchedText: {},
  checkicon: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5), //bump it up a bit so it aligns with the text better
    stroke: 'white',
    strokeWidth: 0.8,
  },
}))

export const RESOLUTION_STATE_PROPOSED = 'PROPOSED'
export const RESOLUTION_STATE_NEW = 'RESOLVED_NEW'
export const RESOLUTION_STATE_EXISTING = 'RESOLVED_EXISTING'
export const RESOLUTION_STATE_IGNORED = 'RESOLVED_IGNORED'

export const READABLE_STATES = {
  RESOLVED_NEW: 'New',
  RESOLVED_EXISTING: 'Matched',
  RESOLVED_IGNORED: 'Ignored',
}

export const Resolution = ({ resolution, onResolved }) => {
  const classes = useStyles()
  const [candidateIndex, setCandidateIndex] = useState(0)

  const dispatchResolveUpdate = useActionDispatcher(resolveUpdate)

  const draftNodeDirectory = useSelector(selectDraftNodeDirectory)
  const masterNodeDirectory = useSelector(selectNodeDirectory)
  const candidate = resolution.candidateIndividuals[candidateIndex]

  // Whenever the resolution changes, reset the candidate index:
  useEffect(() => {
    setCandidateIndex(0)
  }, [setCandidateIndex, resolution.id])

  const handleResolveMatch = candidate => {
    const promise = dispatchResolveUpdate({
      id: resolution.id,
      resolvedIndividualId: candidate.individual.id,
      state: RESOLUTION_STATE_EXISTING,
    })

    promise.then(fulfilled => {
      const response = fulfilled.payload
      console.debug(
        `Resolution.js.handleResolveMatch(): called dispatchResolveUpdate(), it returned:`,
        response
      )
      onResolved &&
        onResolved(RESOLUTION_STATE_EXISTING, response.updatedRelativesCount)
    })
  }

  const handleResolveIgnore = () => {
    dispatchResolveUpdate({
      id: resolution.id,
      state: RESOLUTION_STATE_IGNORED,
    })
    onResolved && onResolved(RESOLUTION_STATE_IGNORED)
  }

  const handleResolveNew = () => {
    dispatchResolveUpdate({
      id: resolution.id,
      state: RESOLUTION_STATE_NEW,
    })
    onResolved && onResolved(RESOLUTION_STATE_NEW)
  }

  const [reasonsPopupAnchorEl, setReasonsPopupAnchorEl] = useState(null)

  if (!draftNodeDirectory || !masterNodeDirectory) {
    return <LoadingIndicator />
  }

  const viewingMatchedCandidate =
    candidate?.individual?.id === resolution?.resolvedIndividual?.id

  return (
    <div className={classes.resolution}>
      <div className={classes.draftIndividual}>
        <Box display="flex" flexDirection="row" mb={1}>
          <Typography variant="subtitle1">Imported record</Typography>
          <ResolutionState state={resolution.state} />
        </Box>
        <DraftIndividual
          individual={resolution.draftIndividual}
          nodeDirectory={draftNodeDirectory}
          handleResolveIgnore={handleResolveIgnore}
          handleResolveNew={handleResolveNew}
          importState={READABLE_STATES[resolution.state]}
        />
      </div>
      <div className={classes.candidates}>
        {candidate && (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              mb={1}
            >
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => setCandidateIndex(candidateIndex - 1)}
                disabled={candidateIndex === 0}
                style={{
                  padding: '0px 0px',
                  marginRight: 4,
                  visibility:
                    resolution.candidateIndividuals.length > 1
                      ? 'visible'
                      : 'hidden',
                }} // still need to layout the contents to keep a consistent height
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography
                variant={
                  resolution.candidateIndividuals.length > 1
                    ? 'subtitle1Bold'
                    : 'subtitle1'
                }
              >
                Candidate{' '}
                {resolution.candidateIndividuals.length > 1 && (
                  <>
                    {candidateIndex + 1} of{' '}
                    {resolution.candidateIndividuals.length}
                  </>
                )}
              </Typography>
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => setCandidateIndex(candidateIndex + 1)}
                disabled={
                  candidateIndex === resolution.candidateIndividuals.length - 1
                }
                style={{
                  padding: 0,
                  marginLeft: 4,
                  visibility:
                    resolution.candidateIndividuals.length > 1
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <ChevronRightIcon />
              </IconButton>
              <Box
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {resolution.candidateIndividuals.map((ci, i) => {
                  return (
                    <>
                      &nbsp;
                      {i !== candidateIndex ? (
                        <Link
                          component="button"
                          onClick={() => setCandidateIndex(i)}
                        >
                          {Math.trunc(ci.confidence * 100)}%
                        </Link>
                      ) : (
                        <>
                          <Popover
                            id="mouse-over-popover"
                            open={reasonsPopupAnchorEl !== null}
                            anchorEl={reasonsPopupAnchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={() => {
                              setReasonsPopupAnchorEl(null)
                            }}
                            disableRestoreFocus
                          >
                            {resolution.candidateIndividuals[i].mismatchReasons
                              .split('\n')
                              .map(r => {
                                return (
                                  <>
                                    <Typography>{r}</Typography>
                                  </>
                                )
                              })}
                          </Popover>
                          <Link
                            component="button"
                            style={{
                              fontWeight: 600,
                              cursor: 'default',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'none',
                              },
                            }}
                            onClick={e => {
                              if (
                                resolution.candidateIndividuals[i]
                                  .mismatchReasons
                              ) {
                                setReasonsPopupAnchorEl(e.currentTarget)
                              }
                            }}
                          >
                            {Math.trunc(ci.confidence * 100)}%
                          </Link>
                        </>
                      )}
                    </>
                  )
                })}
              </Box>
            </Box>
            <div className={classes.candidateNavigator}>
              <div
                className={clsx(
                  classes.currentCandidate,
                  viewingMatchedCandidate && classes.matchedCandidate
                )}
              >
                <DraftIndividual
                  individual={candidate.individual}
                  nodeDirectory={masterNodeDirectory}
                  handleResolveMatch={() => handleResolveMatch(candidate)}
                  importState={
                    viewingMatchedCandidate &&
                    resolution.state === RESOLUTION_STATE_EXISTING
                      ? 'Matched'
                      : null
                  }
                />
              </div>
            </div>
          </Box>
        )}
        {!candidate && (
          <Typography variant="h4" mt={4}>
            No candidate matches found.
          </Typography>
        )}
      </div>
    </div>
  )
}

const ResolutionState = ({ state }) => {
  const classes = useStyles()

  if (state === RESOLUTION_STATE_PROPOSED) {
    return <></>
  }
  return (
    <Typography ml={1} variant="subtitle1" color="primary">
      -{' '}
      <div className={classes.matchedWrapper}>
        <div className={classes.matchedText}>{READABLE_STATES[state]}</div>
        <CheckRoundedIcon className={classes.checkicon} />
      </div>
    </Typography>
  )
}
