import { template } from 'lodash'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from './links'
import { ACTION_CREATE, ACTION_EDIT_TREE_INDIVIDUALS } from './appConstants'

export const defaultContentCards = [
  {
    title: 'Upload Media',
    id: 'upload-media',
    text: template(
      'upload media and tag relevant Individuals, Families, Places, Occasions, Artefacts'
    ),
    img: INSTANCE_TYPE_MEDIA,
    component: INSTANCE_TYPE_MEDIA,
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_MEDIA },
  },
  {
    title: 'Create Album',
    id: 'create-album',
    text: template(
      'create an album and select images from your library and/or upload new ones'
    ),
    img: INSTANCE_TYPE_PHOTO_ALBUM,
    component: INSTANCE_TYPE_PHOTO_ALBUM,
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_PHOTO_ALBUM },
  },
  {
    title: 'Add article',
    id: 'add-article',
    text: template('create a multimedia article with our super simple editor'),
    path: 'write-article', //only used if there are no templates for the pageType, and the default component is used
    img: INSTANCE_TYPE_ARTICLE,
    component: 'selectArticleTemplate',
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_ARTICLE },
  },
  {
    title: 'Add occasion',
    id: 'add-occasion',
    text: template(
      'add a special occasion such as a wedding, anniversary or holiday'
    ),
    component: 'linkedPage',
    componentType: INSTANCE_TYPE_EVENT,
    img: INSTANCE_TYPE_EVENT,
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_EVENT },
  },
  {
    title: 'Add place',
    id: 'add-place',
    text: template(
      'add a special place like an ancestral farm, your childhood home or a school'
    ),
    component: 'linkedPage',
    componentType: INSTANCE_TYPE_LOCATION,
    img: INSTANCE_TYPE_LOCATION,
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_LOCATION },
  },
  {
    title: 'Add artifact',
    id: 'add-artifact',
    text: template(
      'add a treasured artifact like a diary, service medals, jewelry or furniture'
    ),
    component: 'linkedPage',
    componentType: INSTANCE_TYPE_ARTEFACT,
    img: INSTANCE_TYPE_ARTEFACT,
    permissionAction: ACTION_CREATE,
    permissionParams: { instanceType: INSTANCE_TYPE_ARTEFACT },
  },
  {
    title: 'Add Individual',
    id: 'add-individual',
    text: template('add a new individual to the archive'),
    component: 'addIndividual',
    componentType: INSTANCE_TYPE_INDIVIDUAL,
    img: INSTANCE_TYPE_INDIVIDUAL,
    permissionAction: ACTION_EDIT_TREE_INDIVIDUALS,
  },
]
