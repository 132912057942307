import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent, styled, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'

import { Button } from 'src/modules/ui'
import {
  selectPublicNodeDirectory,
  selectPublicIndividualById,
  selectPublicFamilies,
  selectSharedByIndividual,
  selectPublicFamilyById,
  fetchPublicIndividualsForTarget,
  fetchPublicIndividualsForLineage,
  selectTree,
} from './treeSlice'
import {
  setPublicExploredIndividualId,
  selectExploredIndividualId,
  selectChosenFamilyId,
  choosePublicFamilyId,
} from 'src/modules/viewer/exploreTreeSlice'
import {
  FOCUS_MODE_EXPLORE_LINEAGE,
  FOCUS_MODE_EXPLORE_INDIVIDUAL,
} from 'src/modules/viewer/treeViewers'
import { createExploredIndividualGraph } from 'src/modules/viewer/api/explore'
import { createLineageGraphCached } from 'src/modules/viewer/api/lineage'
import {
  SIZE_MODE_FIT_SCREEN,
  SIZE_MODE_FIT_NODES,
  TreeViewer,
} from 'src/modules/viewer/treeViewers'
import { clearChosenFamilyId } from '../../viewer/exploreTreeSlice'

import PublicMobileTreeViewerMenu from './PublicMobileTreeViewerMenu'
import TreeViewerMenu from '../../viewer/TreeViewerMenu'
import { generateLinkForObject } from '../../app'
import {
  generatePublicTreeRoot,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
} from '../../app/links'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { PublicContext } from '../contexts'
import { createPyramid } from '../../viewer/api/nodeDirectory'
import { isEmpty } from 'lodash'
import {
  INITIAL_ZOOM_EXPLORE_MODE,
  INITIAL_ZOOM_NAVIGATOR_MODE,
} from '../../viewer/treeViewers/constants'
import { formatIndividualName } from '../../ui/individualUtils'
import { VISIBILITY_PUBLIC } from '../../visibility/visibilityUtils'
import SelectFamilyOrIndividualPartial from '../../viewer/SelectFamilyOrIndividualPartial'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const PointerButton = styled(Button)({
  pointerEvents: 'visible',
})

const PublicTreeExplorer = ({
  open = false,
  trigger,
  openExplore,
  setOpenExplore,
  navigatorMode,
  reset,
}) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const context = useContext(PublicContext)
  const publicTreeSlug = context?.treeSlug
  const history = useHistory()
  const tree = useSelector(selectTree)

  const publicTreeRoot = generatePublicTreeRoot(publicTreeSlug)
  const familiesRouteMatch = useRouteMatch(`${publicTreeRoot}/families`)
  const individualsRouteMatch = useRouteMatch(`${publicTreeRoot}/individuals`)

  const [modalOpenLocal, setModalOpenLocal] = useState(open)

  const modalOpen = openExplore || modalOpenLocal
  const setModalOpen = setOpenExplore || setModalOpenLocal
  const handleShowModal = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])
  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  // What to display
  const exploredPublicFamilyId = useSelector(selectChosenFamilyId)
  const publicFamilyObj = useSelector(
    selectPublicFamilyById(exploredPublicFamilyId)
  )
  const allFamilies = useSelector(selectPublicFamilies)

  let exploredPublicIndividualId = useSelector(selectExploredIndividualId)
  const sharedByIndividual = useSelector(selectSharedByIndividual)

  const individualId = exploredPublicIndividualId
    ? exploredPublicIndividualId
    : sharedByIndividual
  const exploredIndividualNode = useSelector(
    selectPublicIndividualById(individualId)
  )

  const nodeDirectory = useSelector(selectPublicNodeDirectory)
  const families = useSelector(selectPublicFamilies).filter(
    f => f.visibility === VISIBILITY_PUBLIC
  )

  const handleFamilySelected = useCallback(
    familyId => {
      const fetchData = async (fId, indiId) => {
        await dispatch(
          fetchPublicIndividualsForLineage({
            treeSlug: tree?.slug,
            target: indiId,
            familyId: fId,
          })
        )
        dispatch(clearChosenFamilyId())
        dispatch(choosePublicFamilyId(familyId))
      }

      if (familyId && individualId) {
        if (individualId.id) {
          fetchData(familyId, individualId.id)
        } else {
          fetchData(familyId, individualId)
        }
      }
    },
    [dispatch, individualId, tree]
  )

  const handleIndividualSelected = useCallback(
    individualId => {
      const fetchData = async () => {
        await dispatch(
          fetchPublicIndividualsForTarget({
            treeSlug: tree?.slug,
            target: individualId,
          })
        )
        dispatch(clearChosenFamilyId())
        dispatch(setPublicExploredIndividualId(individualId))
      }

      fetchData()
    },
    [dispatch, tree]
  )

  const handleHomePersonSelected = () => {
    if (reset) {
      if (familiesRouteMatch) {
        handleFamilySelected(reset.id)
      } else if (individualsRouteMatch) {
        handleIndividualSelected(reset.id)
      }
    } else {
      handleIndividualSelected(sharedByIndividual?.id)
    }
  }

  const navigateToFamily = (familyId, close) => {
    setModalOpen(false)
    history.push(
      generateLinkForObject(publicTreeSlug, INSTANCE_TYPE_FAMILY, familyId)
    )
  }

  const navigateToIndividual = (individual, close) => {
    setModalOpen(false)
    history.push(
      generateLinkForObject(
        publicTreeSlug,
        INSTANCE_TYPE_INDIVIDUAL,
        individual.id
      )
    )
  }

  const pyramid = useMemo(() => {
    if (
      navigatorMode &&
      allFamilies.length &&
      !isEmpty(nodeDirectory) &&
      exploredIndividualNode
    ) {
      return createPyramid(nodeDirectory, exploredIndividualNode, allFamilies)
    } else {
      return null
    }
  }, [allFamilies, navigatorMode, nodeDirectory, exploredIndividualNode])

  useEffect(() => {
    if (!familiesRouteMatch && !individualsRouteMatch) {
      handleIndividualSelected(sharedByIndividual?.id)
    }
  }, [
    individualsRouteMatch,
    familiesRouteMatch,
    sharedByIndividual,
    handleIndividualSelected,
  ])

  const displayName = formatIndividualName(exploredIndividualNode)

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullScreen={navigatorMode && !mobileBreakpoint ? false : true}
        fullWidth={navigatorMode && !mobileBreakpoint ? false : true}
        maxWidth={'100%'}
      >
        {modalOpen && (
          <DialogContent sx={{ border: 'none' }}>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PublicMobileTreeViewerMenu
                  {...{
                    handleFamilySelected,
                    handleIndividualSelected,
                    nodeDirectory,
                    allFamilies: families,
                    handleForceCloseModal: handleCloseModal,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: '300px',
                  top: 1,
                  right: 1,
                  position: 'absolute',
                  padding: 2,
                  zIndex: 100,
                  background: 'white',
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <SelectFamilyOrIndividualPartial
                  onSelectFamily={handleFamilySelected}
                  onSelectIndividual={handleIndividualSelected}
                  nodeDirectory={nodeDirectory}
                  allFamilies={families}
                  sharedByIndividual={sharedByIndividual}
                  isPublic={true}
                />
                <PointerButton
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleHomePersonSelected}
                  sx={{ mt: 2 }}
                >
                  {navigatorMode ? 'Home Person' : 'Reset'}
                </PointerButton>
              </Box>
              <PublicTreeView
                familyId={exploredPublicFamilyId}
                individualId={individualId}
                focusMode={
                  exploredPublicIndividualId
                    ? FOCUS_MODE_EXPLORE_INDIVIDUAL
                    : FOCUS_MODE_EXPLORE_LINEAGE
                }
                allowDragAndZoom={true}
                showNodeContextMenu={!navigatorMode}
                selectMenuConfig={{
                  explore: !navigatorMode,
                  visit: !navigatorMode,
                }}
                sizeMode={SIZE_MODE_FIT_SCREEN}
                onCloseViewerModal={handleCloseModal}
                doAddGreatGrandParents={true}
                source={'PublicTreeExplorer'}
                navigatorMode={navigatorMode}
                navigateToNodeOnDoubleClick={navigatorMode}
                navigateToNodeOnDoubleClickHandler={handleCloseModal}
              />
              {!navigatorMode || !displayName ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 1,
                    left: 1,
                    zIndex: 100,
                    display: { xs: 'none', md: 'block' },
                  }}
                >
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    variant="outlined"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <TreeViewerMenu
                    {...{
                      userHomeIndividual: sharedByIndividual,
                      displayName,
                      exploredIndividualNode,
                      familyObj: publicFamilyObj,
                      handleCloseModal,
                      handleFamilySelected,
                      handleForceCloseModal: handleCloseModal,
                      navigatorMode,
                      navigateToFamily,
                      navigateToIndividual,
                      pyramid,
                    }}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}

const isPrerender =
  navigator.userAgent.toLowerCase().indexOf('prerender') !== -1

export const PublicTreeView = ({
  allowDragAndZoom = false,
  familyId,
  individualId,
  focusMode,
  selectMenuConfig,
  showNodeContextMenu = false,
  navigateToNodeOnClick = false,
  onCloseViewerModal,
  sizeMode = SIZE_MODE_FIT_NODES,
  doAddGreatGrandParents = false,
  source = 'Not Set',
  navigatorMode,
  navigateToNodeOnDoubleClick,
  navigateToNodeOnDoubleClickHandler,
}) => {
  const exploredIndividualNode = useSelector(
    selectPublicIndividualById(individualId)
  )
  const nodeDirectory = useSelector(selectPublicNodeDirectory)

  // Layout
  const [nodes, links, nLayers] = useMemo(() => {
    if (isPrerender) {
      return []
    }

    if (familyId) {
      let { nodes, links, nLayers } = createLineageGraphCached(
        nodeDirectory,
        individualId,
        familyId
      )
      return [nodes, links, nLayers]
    } else if (exploredIndividualNode) {
      const [nodes, links, nLayers] = createExploredIndividualGraph(
        exploredIndividualNode,
        nodeDirectory,
        doAddGreatGrandParents
      )
      return [nodes, links, nLayers]
    } else {
      return []
    }
  }, [
    familyId,
    exploredIndividualNode,
    nodeDirectory,
    doAddGreatGrandParents,
    individualId,
  ])

  if (isPrerender) {
    return <>Skipping tree render</>
  }

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden' }}>
      <TreeViewer
        allowDragAndZoom={allowDragAndZoom}
        nodes={nodes}
        links={links}
        nLayers={nLayers}
        focusMode={focusMode}
        focusModeTarget={exploredIndividualNode}
        exploredIndividualSelector={selectPublicIndividualById}
        isInSelectionMode={false}
        navigateToNodeOnClick={navigateToNodeOnClick}
        onCloseViewerModal={onCloseViewerModal}
        selectMenuConfig={selectMenuConfig}
        sizeMode={sizeMode}
        showNodeContextMenu={showNodeContextMenu}
        treeBackgroundStyles={{ border: 'none' }}
        isPublic={true}
        exploreNodeOnClick={navigatorMode ? true : false}
        navigateToNodeOnDoubleClick={navigateToNodeOnDoubleClick}
        navigateToNodeOnDoubleClickHandler={navigateToNodeOnDoubleClickHandler}
        initialZoom={
          navigatorMode
            ? INITIAL_ZOOM_NAVIGATOR_MODE
            : INITIAL_ZOOM_EXPLORE_MODE
        }
      />
    </Box>
  )
}

export default PublicTreeExplorer
