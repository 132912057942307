import { CentreContainer } from './CreateOrUpdateTree'
import React from 'react'
import { Container, LoadingIndicator } from '../ui'
import { selectCreatedTree } from './treesSlice'

import { useSelector } from 'react-redux'
import UpdateBlogName from './UpdateBlogName'

const CreateBlogWizard = () => {
  const createTreeResult = useSelector(selectCreatedTree)

  return (
    <Container>
      <CentreContainer>
        {!createTreeResult && <LoadingIndicator />}
        {createTreeResult && (
          <UpdateBlogName
            initialName={createTreeResult.tree.name}
            treeSlug={createTreeResult.tree.slug}
          />
        )}
      </CentreContainer>
    </Container>
  )
}

export default CreateBlogWizard
