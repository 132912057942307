import React from 'react'
import { useDispatch } from 'react-redux'

import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { EditorState, Modifier } from 'draft-js'
import { getEntityRange, getSelectionEntity } from 'draftjs-utils'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'

import { IconButton } from 'src/modules/ui'

import {
  selectArticle,
  saveSource,
  setSourceNumbersWithinArticleAction,
} from './writeArticleSlice'

import { editorStateToHtml } from 'src/modules/ui/editor/convert'
import { generateObjectPathSegment } from '../app/links'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

// Originally copied from InsertLink.js

//toolbar button, added in RichTextField.js
//see https://jpuri.github.io/react-draft-wysiwyg/#/docs:
//
//New toolbar component is passed following properties:
//     onChange: The function can be used to update editor state.
//     editorState: The current editor state. It is an instance of EditorState
//     translations: This is the map of all the translations in the editor.
//     modalHandler: In case the custom option has a dropdown or a popup. This can be used to control their opening and closing. Using this property
//     will ensure that all popups close when mouse is click anywhere else on the page. Example here.

const CreateSourceToolbarButton = ({
  editorState,
  modalHandler,
  onChange,
  contentBlockId,
  editSource, //fn which launches the edit dialog
}) => {
  //context needed to pass the current contentBlockId
  // console.debug(
  //   'CreateSourceToolbarButton: called with editorState, onChange, contentBlockId:',
  //   editorState,
  //   onChange,
  //   contentBlockId
  // )

  const dispatchSaveSource = useActionDispatcher(saveSource) //saveSource is a thunk imported from writeArticleSlice.js

  const article = useSelector(selectArticle)

  const dispatch = useDispatch()
  const dispatchSetSourceNumbersWithinArticle = readOnlyContentBlocks => {
    dispatch(
      setSourceNumbersWithinArticleAction({
        readOnlyContentBlocks: readOnlyContentBlocks,
      })
    ) // action not a thunk
  }

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  //passed to formik to be called when the form is submitted
  const handleCreateSourceSubmit = async (contentId, values, formikActions) => {
    //    console.debug(
    //      'CreateSourceToolbarButton.handleCreateSourceSubmit(): source creating/updating... values: ',
    //      values
    //    )

    //    console.debug(
    //      'CreateSourceToolbarButton.handleCreateSourceSubmit(): calling await dispatchSaveSource(). values:',
    //      values
    //    )
    const saveSourceResponse = await dispatchSaveSource({
      contentId: article.id,
      contentBlockId: contentBlockId,
      sourceToSave: values,
      alreadyInState: false,
    })
    //    console.debug(
    //      'CreateSourceToolbarButton.handleCreateSourceSubmit(): dispatchSaveSource() returned - saveSourceResponse:',
    //      saveSourceResponse
    //    )

    //    console.debug(
    //      'CreateSourceToolbarButton.handleCreateSourceSubmit(): calling insertLink() for saved source:',
    //      saveSourceResponse.payload
    //    )
    const updatedEditorState = insertLink(
      treeSlug,
      editorState,
      onChange,
      true,
      {
        text: ' ',
        id: saveSourceResponse.payload.id,
        instanceType: 'source',
      }
    )

    //    console.debug(
    //      'CreateSourceToolbarButton.handleCreateSourceSubmit(): insertLink() returned updatedEditorState, triggerUiUpdate:',
    //      updatedEditorState
    //    )

    // At this point, even after insertLink() has called onChange(), the global state slice's contentBlock.textContent does not yet contain the newly added source link.
    // So setSourceNumbersWithinArticle() takes a second parameter which is the contentBlocks to read the textContent from rather than using the current redux/slice state

    if (values.contentBlockId) {
      //make a copy of the state's contentBlocks taking just the textContent and replace the current block's textContent with the not-yet-commited content from the editor
      const contentBlocksCopy = article.contentBlocks.map(cb => {
        if (cb.id === values.contentBlockId) {
          return {
            id: cb.id,
            textContent: editorStateToHtml(updatedEditorState),
          }
        } else {
          return { id: cb.id, textContent: cb.textContent }
        }
      })

      //console.debug(
      //  `CreateSourceToolbarButton.handleCreateSourceSubmit(): calling dispatchSetSourceNumbersWithinArticle() for blocks`,
      //  contentBlocksCopy
      //)
      dispatchSetSourceNumbersWithinArticle(contentBlocksCopy)
      //      console.debug(
      //        `CreateSourceToolbarButton.handleCreateSourceSubmit(): dispatchSetSourceNumbersWithinArticle() returned`
      //      )
    } else {
      console.error(
        `reateSourceToolbarButton.handleCreateSourceSubmit(): values.contentBlockId is not set`
      )
    }
  }

  return (
    <div>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={() => {
          //          console.debug(
          //            `CreateSourceToolbarButton.IconButton.onClick(): calling editSource()`,
          //            editSource
          //          )
          //editSource is a SourceSubmitFunc created by SourcesUI.CreateSource.EditableSourceDialog mounted in WriteArticle.js
          editSource(null, true, 'Create source', handleCreateSourceSubmit)
        }}
      >
        <InfoIcon fontSize="small" />
      </IconButton>
    </div>
  )
}

const insertLink = (treeSlug, editorState, onChange, newLink, link) => {
  // Taken from https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/controls/Link/index.js
  //console.debug('CreateSource.insertLink(): called with link:', link)
  //console.debug('CreateSource.insertLink(): called with newLink:', newLink)
  let selection = editorState.getSelection()
  const currentEntity = getSelectionEntity(editorState)

  // Get current link if hovering over
  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity)
    const isBackward = selection.getIsBackward()
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      })
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      })
    }
  }

  // Create link entity
  let entityOptions
  if (link.instanceType) {
    const { display, text, instanceType, id } = link
    //console.debug('CreateSource.js.insertLink(): url: ' + url)
    let url2
    //TODO might need to pass in the source freeText or urlDescription as 'text'
    let title = text
    let display2 = display
    let targetOption
    if (link.instanceType === 'source') {
      //url2 = `#source_${id}`
      // title doesn't seem to appear anywhere     title = 'this is the text'
      //display2 = text
      //url2 = url
      url2 = ''
      targetOption = ''
      display2 = ''
      //const source = selectSource({state: , sourceId: id, contentBlockId: undefined})
    } else {
      url2 = generateObjectPathSegment(instanceType, id)
    }
    if (!url2) {
      url2 = ''
      targetOption = '_self'
    }
    //    console.debug('CreateSource.js.insertLink(): url2: ' + url2)
    //    console.debug('CreateSource.js.insertLink(): targetOption: ' + targetOption)
    entityOptions = {
      url: url2,
      targetOption, //: targetOption,
      title: title,
      instanceType,
      contentId: id,
      contentDisplay: display2,
    }
  } else if (link.url) {
    //    console.debug(
    //      'CreateSource.js.insertLink(): link.instanceType is not set, treating as URL'
    //    )
    const { text, url } = link
    entityOptions = {
      url,
      targetOption: '_blank',
      title: text,
    }
  }

  //  console.debug(
  //    'CreateSource.js.insertLink(): built entityOptions:',
  //    entityOptions
  //  )

  const entityKey = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', entityOptions)
    .getLastCreatedEntityKey()

  // Insert/replace text for entity into editor
  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${link.text}`,
    editorState.getCurrentInlineStyle(),
    entityKey
  )
  let newEditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters'
  )

  // insert a blank space after link
  if (newLink) {
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get('anchorOffset') + link.text.length,
      focusOffset: selection.get('anchorOffset') + link.text.length,
    })
    newEditorState = EditorState.acceptSelection(newEditorState, selection)
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      selection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined
    )
  }
  onChange(EditorState.push(newEditorState, contentState, 'insert-characters'))

  return newEditorState
}

export default CreateSourceToolbarButton
