import { ConfirmDialog } from 'src/modules/ui'
import { visibilityMap } from 'src/modules/visibility/VisibilityControl'
import { useActionDispatcher } from 'src/modules/app/hooks'
import { updateTree } from 'src/modules/trees/treesSlice'
import { useCallback, useRef } from 'react'
import { Box, Typography, Switch, Tooltip, styled } from '@mui/material'
import VisibilityInfo from './VisibilityInfo'
import PublicIcon from '@mui/icons-material/Public'
import LockIcon from '@mui/icons-material/Lock'
import { VISIBILITY_PUBLIC, VISIBILITY_TREE } from './visibilityUtils'
import { usePermissions } from '../auth/authHooks'

const VisibilitySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    color: theme.palette.primary.main,
  },
}))

const VisibilityControl = ({
  permissionAction,
  permissionParams,
  visibility,
  handleChangeVisibility,
}) => {
  const checkPermissions = usePermissions()
  const { hasPermission } = checkPermissions(permissionAction, permissionParams)

  return (
    <>
      {hasPermission ? (
        <>
          <Typography color={visibility ? 'disabled' : 'primary'}>
            Private
          </Typography>

          <VisibilitySwitch
            permissionAction={permissionAction}
            checked={visibility}
            color="primary"
            onChange={e => handleChangeVisibility(e.target.checked)}
          />
          <Typography color={visibility ? 'primary' : 'disabled'}>
            Public
          </Typography>
        </>
      ) : (
        <Typography color="primary">
          {visibility ? <>Public </> : <>Private</>}
        </Typography>
      )}
    </>
  )
}

const ToggleTreeVisibility = ({
  visibility,
  tooltip,
  permissionAction,
  permissionParams,
  onChangeVisibility,
  currentUserIsAdmin,
}) => {
  const handleChangeVisibility = useCallback(
    isVisible => {
      if (isVisible) {
        onChangeVisibility(VISIBILITY_PUBLIC)
      } else {
        onChangeVisibility(VISIBILITY_TREE)
      }
    },
    [onChangeVisibility]
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <VisibilityControl
        permissionAction={permissionAction}
        permissionParams={permissionParams}
        visibility={visibility}
        currentUserIsAdmin={currentUserIsAdmin}
        handleChangeVisibility={handleChangeVisibility}
      />
      <Tooltip title={tooltip}>
        {visibility ? (
          <PublicIcon sx={{ marginLeft: 1 }} color="primary" />
        ) : (
          <LockIcon sx={{ marginLeft: 1 }} color="primary" />
        )}
      </Tooltip>
    </Box>
  )
}

const TreeVisibilityControl = ({
  treeSlug,
  type,
  visibility,
  currentUserIsAdmin,
  permissionAction,
  permissionParams,
  ...props
}) => {
  const dispatchSetVisibility = useActionDispatcher(updateTree)

  const triggerRef = useRef()
  const setConfirmDialogTrigger = useCallback(({ onClick }) => {
    triggerRef.current = onClick
  }, [])

  const { label } =
    visibilityMap[visibility ? VISIBILITY_PUBLIC : VISIBILITY_TREE]

  const handleChangeVisibility = useCallback(
    async visibility => {
      try {
        await dispatchSetVisibility(
          {
            treeSlug: treeSlug,
            allowPublicVisibility:
              visibility === VISIBILITY_PUBLIC ? true : 'false',
          },
          {
            errorNotification: 'Something went wrong - please try again',
            successNotification: 'Visibility changed',
          }
        ).unwrap()
      } catch (err) {
        return
      }
    },
    [dispatchSetVisibility, treeSlug]
  )

  const setVisibilityPublic = useCallback(() => {
    handleChangeVisibility(VISIBILITY_PUBLIC)
  }, [handleChangeVisibility])

  const openConfirmDialogIfRequired = useCallback(
    visibility => {
      if (visibility === VISIBILITY_PUBLIC) {
        triggerRef.current()
      } else {
        handleChangeVisibility(visibility)
      }
    },
    [handleChangeVisibility]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <ConfirmDialog
        onConfirm={setVisibilityPublic}
        submitText="Make my archive public"
        title="You are about to publish your content"
        trigger={setConfirmDialogTrigger}
      >
        <Typography variant="body1">
          Your content will be available to view on the internet. You can hide
          specific articles, photos, individuals etc by using their individual
          privacy controls. Living individuals will never be publicly visible.
        </Typography>
      </ConfirmDialog>

      <ToggleTreeVisibility
        permissionAction={permissionAction}
        permissionParams={permissionParams}
        tooltip={label}
        visibility={visibility}
        onChangeVisibility={openConfirmDialogIfRequired}
        currentUserIsAdmin={currentUserIsAdmin}
      />
      <VisibilityInfo />
    </Box>
  )
}

export default TreeVisibilityControl
