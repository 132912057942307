/* eslint react/prop-types: "warn", react/no-unused-prop-types: "warn", react/forbid-prop-types: "warn", react/destructuring-assignment: "warn",
          jsdoc/require-jsdoc: "warn", jsdoc/check-param-names: "warn", jsdoc/require-param-type: "warn", jsdoc/newline-after-description: "warn", jsdoc/check-alignment: "warn"
*/

import PropTypes from 'prop-types'
import { difference } from 'lodash'

/**
 * To display a source we need to know which one of URL, photo or freeText is set.
 *
 * enum solution taken from here: https://www.sohamkamani.com/javascript/enums/
 */
export class SourceType {
  static FREE_TEXT = new SourceType('Free text')
  static URL = new SourceType('Link to a web page')
  static PHOTO = new SourceType('Photo')

  constructor(name) {
    this.name = name
  }

  toString() {
    return this.getKey()
  }
  getName() {
    return this.name
  }

  static getEntryByName(nom) {
    if (nom) {
      const entry = Object.entries(SourceType).find(
        ([key, val]) => val === nom
      )?.[0]
      //      console.debug(
      //        `SourceType.getEntryByName(): looked up name '${nom}': '${entry}'`
      //      )
      return entry
    } else {
      return undefined
    }
  }

  static getEntryKey(entry) {
    return Object.keys(SourceType).filter(key => SourceType[key] === entry)?.[0]
  }

  getKey() {
    const val = SourceType.getEntryKey(this)
    //console.debug(`SourceType.getKey(): returning key '${val}'`, this)
    return val
  }

  /**
   * @returns {{key, label}[]} array of objects {key, label}
   */
  static toArray() {
    return Object.keys(SourceType).map(key => {
      //console.debug(`SourceTypesToArray: called with key:`, key)
      const name = SourceType[key].name
      //console.debug(`SourceTypesToArray: key '${key}' has name '${name}'`)
      return { key: key, label: name }
    })
  }
}

export const SourceTypePropType = PropTypes.shape({
  key: PropTypes.string,
  name: PropTypes.string,
})

/**
 * @typedef {object} Source
 * @property {string} id
 * @property {string} [freeText]
 * @property {string} [url]
 * @property {string} [urlDescription]
 * @property {any} [media]
 * @property {string} [mediaId]
 * @property {SourceType} [type]
 * @property {boolean} firstSaveCalled
 * @property {boolean} saving
 * @property {boolean} lockDuringSave
 * @property {boolean} saveAgain
 * @property {boolean} [focusTextInput]
 * @property {boolean} [linkedInText]
 * @property {number} [numberWithinArticle]
 * @property {string} [contentBlockId]
 */

/*
export const SourcePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  freeText: PropTypes.string,
  url: PropTypes.string,
  urlDescription: PropTypes.string,
  media: PropTypes.shape({id: PropTypes.string}),
  mediaId: PropTypes.string,
  type: SourceTypePropType,
  firstSaveCalled: PropTypes.boolean,
  saving: PropTypes.boolean,
  lockDuringSave: PropTypes.boolean,
  saveAgain: PropTypes.boolean,
  focusTextInput: PropTypes.boolean,
  linkedInText: PropTypes.boolean,
  numberWithinArticle: PropTypes.number,
  contentBlockId: PropTypes.string
}).isRequired
*/

export const SourcePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  freeText: PropTypes.string,
  url: PropTypes.string,
  urlDescription: PropTypes.string,
  media: PropTypes.shape({ id: PropTypes.string }),
  mediaId: PropTypes.string,
  type: SourceTypePropType,
  firstSaveCalled: PropTypes.boolean,
  saving: PropTypes.boolean,
  lockDuringSave: PropTypes.boolean,
  saveAgain: PropTypes.boolean,
  focusTextInput: PropTypes.boolean,
  linkedInText: PropTypes.boolean,
  numberWithinArticle: PropTypes.number,
  contentBlockId: PropTypes.string,
})

/**
 * Determine and set the source type for a source depending on currently populated values
 *
 * @param {Source} source
 */
export const setSourceType = source => {
  if (!source.type) {
    source.type =
      source.freeText !== null
        ? SourceType.FREE_TEXT
        : source.media
        ? SourceType.PHOTO
        : SourceType.URL
  }
}

/**
 * type is not stored in the db as it can be easily derived - this derives it for every source in the article.
 * Calls setSourceNumbersWithinArticle()
 *
 * @param {object} articleState
 */
export const setSourceTypes = articleState => {
  if (articleState.contentBlocks) {
    articleState.contentBlocks.forEach((contentBlock /*: any*/) => {
      if (contentBlock.sources) {
        contentBlock.sources.forEach((source /*: Source*/) => {
          source.saving = false
          source.lockDuringSave = false
          source.contentBlockId = contentBlock.id
        })
      }
    })

    setSourceNumbersWithinArticle(articleState.contentBlocks)

    articleState.contentBlocks.forEach(contentBlock => {
      if (contentBlock.sources) {
        contentBlock.sources.forEach(source => setSourceType(source))
      }
    })
  } else {
    console.error(
      'SourcesCommon.js.setSourceTypes(): articleState.contentBlocks is null',
      articleState
    )
  }
}

export const getDeletedSourceIds = (html = '', serverSources = []) => {
  const sourceIdsInText = getSourceIdsFromHtml(html)
  const sourceIdsOnServer = serverSources.map(source => source.id)

  return difference(sourceIdsOnServer, sourceIdsInText)
}

export const getSourceIdsFromHtml = html => {
  if (html === undefined) {
    return []
  }

  const parser = new DOMParser()
  const htmlDoc = parser.parseFromString(`<div>${html}</div>`, 'text/xml')

  const linkElements = htmlDoc.getElementsByTagName('a')
  const sourceIds = []
  for (const anchor of linkElements) {
    if (anchor.getAttribute('data-instance-type') === 'source') {
      const sourceId = anchor.getAttribute('data-content-id')
      if (sourceId) {
        sourceIds.push(sourceId)
      }
    }
  }
  return sourceIds
}

/**
 * Optional second parameter in case this needs to find links in contentText that have not yet been committed to redux/slice state
 * Sources will be updated inside the first parameter's contentBlocks
 *
 * @param {object[]} updateableContentBlocks
 * @param {object[]} [readOnlyContentBlocks=updateableContentBlocks]
 */
//also sets .linkedInText = undefined
export const setSourceNumbersWithinArticle = (
  updateableContentBlocks,
  readOnlyContentBlocks = updateableContentBlocks
) => {
  //clear source numbers and .linkedInText first
  updateableContentBlocks.forEach(contentBlock => {
    if (contentBlock.sources) {
      contentBlock.sources.forEach(source => {
        source.numberWithinArticle = undefined
        source.linkedInText = undefined
      })
    }
  })

  let articleSourceNumber = 1

  //walk through the cb's text and find all links to sources so they can be numbered in order
  readOnlyContentBlocks.forEach(readOnlyContentBlock => {
    const updateableContentBlock = findContentBlockById(
      updateableContentBlocks,
      readOnlyContentBlock.id
    )

    const sourceIds = getSourceIdsFromHtml(readOnlyContentBlock.textContent)

    sourceIds.forEach(sourceId => {
      const source = findSourceById(updateableContentBlock.sources, sourceId)
      if (source) {
        source.linkedInText = true
        source.numberWithinArticle = articleSourceNumber++
      }
    })

    if (updateableContentBlock.sources) {
      //now find the remaining sources that weren't linked to and give them a number
      updateableContentBlock.sources
        .filter(s => s.linkedInText !== true)
        .forEach(source => {
          source.numberWithinArticle = articleSourceNumber++
          source.linkedInText = false
        })
    }
  })
}

export const findContentBlockById = (contentBlocks, contentBlockId) => {
  return contentBlocks.filter(cb => cb.id === contentBlockId)[0]
}
export const findSourceByIdInContentBlock = (
  contentBlocks,
  contentBlockId,
  sourceId
) => {
  const contentBlock = contentBlocks.filter(cb => cb.id === contentBlockId)[0]
  if (contentBlock) {
    return findSourceById(contentBlock.sources, sourceId)
  } else {
    return null
  }
}
export const findSourceById = (sources, sourceId) => {
  if (sources) {
    return sources.filter(s => s.id === sourceId)[0]
  } else {
    return null
  }
}
