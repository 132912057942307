import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import WorkIcon from '@mui/icons-material/Work'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import Diversity1Icon from '@mui/icons-material/Diversity1'
import RadarIcon from '@mui/icons-material/Radar'
import ElderlyIcon from '@mui/icons-material/Elderly'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import SportsIcon from '@mui/icons-material/Sports'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

export const actionTypes = {
  OPEN: 'OPEN',
  SELECT: 'SELECT',
  SUBMIT: 'SUBMIT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CANCEL: 'CANCEL',
  RETRY: 'RETRY',
  LOADED_DEFINITION: 'LOADED_DEFINITION',
}

export const wizardStates = {
  SELECTING: 'selecting',
  SELECTED: 'selected',
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
  ERROR: 'error',
  CANCEL: 'cancel',
  LOADING_DEFINITION: 'loading_definition',
}
export const aiWizardsDefault = [
  {
    id: 'quick_bio',
    title: 'Quick Biography',
    description:
      'Complete a simple set of questions and quickly create a biography article block',
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: AutoStoriesIcon,
  },
  {
    id: 'early_life',
    title: 'Early Life',
    description:
      "Complete a simple set of questions and quickly create a article block on someone's early life",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: ChildFriendlyIcon,
  },
  {
    id: 'education',
    title: 'Education',
    description: "create a article block on someone's education and training",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: HistoryEduIcon,
  },
  {
    id: 'working_life',
    title: 'Working Life',
    description: "create a article block on someone's working life or career",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: WorkIcon,
  },
  {
    id: 'personal_life',
    title: 'Personal Life',
    description:
      "create a article block on someone's personal life and relationships",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: Diversity1Icon,
  },
  {
    id: 'special_interests',
    title: 'Special Interests',
    description:
      "create a article block on someone's interests, hobbies and other activities",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: SportsIcon,
  },
  {
    id: 'military_history',
    title: 'Military History',
    description:
      'Fill in some questions about someones military service and create an article',
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: RadarIcon,
  },
  {
    id: 'later_life',
    title: 'Later Life',
    description: "create a article block on someone's later life and death",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: ElderlyIcon,
  },
  {
    id: 'peoples_reccolections',
    title: "People's Reccolections",
    description:
      "create a article block on other people's recollections and memories",
    preWizardInstanceSelector: INSTANCE_TYPE_INDIVIDUAL,
    icon: LightbulbIcon,
  },
]
