import { ToolbarContainer } from 'src/modules/ui/ToolbarContainer'

import { EditorToolbarDropdown } from './EditorToolbarDropdown'
import RemoveLink from './RemoveLink'
import InsertLink from './InsertLink'
import CreateSource from '../../writeArticle/CreateSource'
import { StandardOptions } from './StandardOptions'
import { Divider } from '@mui/material'

export const Toolbar = ({
  editorState,
  onChange,
  contentBlockId,
  editSource,
  fixed,
  disableBlockquote,
}) => {
  return (
    <ToolbarContainer elevation={fixed ? 0 : 1} fixed={fixed}>
      <StandardOptions
        editorState={editorState}
        onChange={onChange}
        disableBlockquote={disableBlockquote}
      />
      <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
      {!!editSource && (
        <CreateSource
          editorState={editorState}
          onChange={onChange}
          contentBlockId={contentBlockId}
          editSource={editSource}
        />
      )}
      <InsertLink editorState={editorState} onChange={onChange} />
      <RemoveLink editorState={editorState} onChange={onChange} />
      <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
      <EditorToolbarDropdown editorState={editorState} onChange={onChange} />
    </ToolbarContainer>
  )
}
