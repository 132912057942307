import { useCallback, useRef, useState } from 'react'

import { makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Box, Stack } from '@mui/material'

import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter'
import WidgetsIcon from '@mui/icons-material/Widgets'
import HeightIcon from '@mui/icons-material/Height'

import { ToolbarContainer } from 'src/modules/ui/ToolbarContainer'

import { useActionDispatcher } from 'src/modules/app'
import { addMediaToBlock, updateContentBlock } from './writeArticleSlice'
import { AddMediaIcon } from './PhotoWithFloatingControls'
import { ArticleControlsSelect } from './ArticleControlsSelect'
import {
  MEDIA_ROW_ALIGNMENT_CENTER,
  MEDIA_ROW_ALIGNMENT_LEFT,
  MEDIA_ROW_ALIGNMENT_RIGHT,
  MEDIA_ROW_ALIGNMENT_COVER,
  MEDIA_ROW_HEIGHT_LARGE,
  MEDIA_ROW_HEIGHT_MEDIUM,
  MEDIA_ROW_HEIGHT_SMALL,
  MEDIA_ROW_HEIGHT_MAP,
} from './contentBlockConstants'
import { SortablePhotos } from './SortablePhotos'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme => ({
  rowControls: {
    position: 'absolute',
    top: theme.spacing(-3.85),
    left: theme.spacing(-1),
    padding: theme.spacing(0.7),
  },
}))

export const MEDIA_ROW_MIN_HEIGHT = 80
const MediaRow = ({
  contentId,
  id,
  mediaRowAlignment,
  mediaRowHeight,
  mediaRowMeta,
  media,
  isHovered,
  isFocused,
  presetTargets,
  blocksEditable = true,
  addMediaHideTags,
  addMediaSetCategory,
  photoButtons,
  contentType,
  multiRow,
}) => {
  const classes = useStyles()
  const containerRef = useRef()

  const dispatchAddPhotoToBlock = useActionDispatcher(addMediaToBlock)

  const handleAddMedia = useCallback(
    (photo, order = 0) => {
      try {
        dispatchAddPhotoToBlock(
          {
            contentId,
            id,
            mediaId: photo.id,
            order,
            existingMocbId: photo.templatePlaceholderMocbId,
          },
          {
            errorNotification: err => err.data && err.data.photo,
          }
        ).unwrap()
      } catch (err) {}
    },
    [dispatchAddPhotoToBlock, contentId, id]
  )

  const showControls = (isHovered || isFocused) && blocksEditable
  const rowHeight = MEDIA_ROW_HEIGHT_MAP[mediaRowHeight] || mediaRowMeta
  const proportionalWidth = rowHeight * 1.33
  const mediaIsEmpty = isEmpty(media)
  const isCover = mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER

  return (
    <div>
      {showControls && (
        <ToolbarContainer elevation={1} className={classes.rowControls}>
          <PhotoRowControls
            contentId={contentId}
            contentBlockId={id}
            mediaRowHeight={mediaRowHeight}
            mediaRowAlignment={mediaRowAlignment}
          />
        </ToolbarContainer>
      )}
      <Box
        ref={containerRef}
        sx={{
          display: !isCover ? 'flex' : 'auto',
          '& img': {
            width: 'auto',
          },
          justifyContent: !mediaIsEmpty
            ? 'inherit'
            : mediaRowAlignment === MEDIA_ROW_ALIGNMENT_LEFT
            ? 'flex-start'
            : mediaRowAlignment === MEDIA_ROW_ALIGNMENT_CENTER
            ? 'center'
            : 'flex-end',
        }}
      >
        {mediaIsEmpty && (
          <>
            <AddMediaIcon
              handleSelect={handleAddMedia}
              media
              height={rowHeight}
              width={isCover ? '100%' : proportionalWidth}
              presetTargets={presetTargets}
              label={'Add image'}
              hideTags={addMediaHideTags}
              //defaultAllowNoTags={} //don't prompt for tags
              setCategory={addMediaSetCategory}
            />
            <div></div>
          </>
        )}

        {!mediaIsEmpty && (
          <SortablePhotos
            contentId={contentId}
            contentBlockId={id}
            media={media}
            handleAddMedia={handleAddMedia}
            multiRow={multiRow}
            mediaRowAlignment={mediaRowAlignment}
            mediaRowHeight={mediaRowHeight}
            mediaRowMeta={mediaRowMeta}
            presetTargets={presetTargets}
            height={MEDIA_ROW_HEIGHT_MAP[mediaRowHeight] || mediaRowMeta}
            addMediaHideTags={addMediaHideTags}
            addMediaSetCategory={addMediaSetCategory}
            photoButtons={photoButtons}
          />
        )}
      </Box>
    </div>
  )
}

const PhotoRowControls = ({
  contentId,
  contentBlockId,
  mediaRowHeight,
  mediaRowAlignment,
}) => {
  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)

  // Store last state, unless it is cover, in which case default to left
  const [lastSelectedAlignment, setLastSelectedAlignment] = useState(
    mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER
      ? MEDIA_ROW_ALIGNMENT_LEFT
      : mediaRowAlignment
  )

  const toggleHeight = newHeight => {
    // containerRef.current.style.height = `${newHeight}px`
    const photos = document.getElementsByClassName(
      `articlePhoto-${contentBlockId}`
    )

    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i]
      photo.style.height = `${newHeight}px`
    }
  }

  const handleMediaRowHeightChange = event => {
    const height = event.target.value
    toggleHeight(MEDIA_ROW_HEIGHT_MAP[height])
    dispatchUpdateContentBlock({
      contentId,
      id: contentBlockId,
      mediaRowHeight: height,
      mediaRowMeta: MEDIA_ROW_HEIGHT_MAP[height],
    })
  }

  const handleMediaRowAlignmentChange = event => {
    const alignment = event.target.value
    if (alignment !== MEDIA_ROW_ALIGNMENT_COVER) {
      setLastSelectedAlignment(alignment)
    }
    dispatchUpdateContentBlock({
      contentId,
      id: contentBlockId,
      mediaRowAlignment: alignment,
    })
  }

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <HeightIcon
        fontSize="small"
        color="grey"
        sx={{ transform: 'rotate(90deg)' }}
      />
      <FormControl>
        <Select
          value={mediaRowHeight}
          variant="standard"
          onChange={handleMediaRowHeightChange}
          disabled={mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER}
          input={<ArticleControlsSelect />}
        >
          <MenuItem value={MEDIA_ROW_HEIGHT_SMALL}>Small</MenuItem>
          <MenuItem value={MEDIA_ROW_HEIGHT_MEDIUM}>Medium</MenuItem>
          <MenuItem value={MEDIA_ROW_HEIGHT_LARGE}>Large</MenuItem>
          <MenuItem value="CUSTOM" disabled sx={{ display: 'none' }}>
            Custom
          </MenuItem>
        </Select>
      </FormControl>

      {mediaRowAlignment === MEDIA_ROW_ALIGNMENT_LEFT ? (
        <AlignHorizontalLeftIcon fontSize="small" color="grey" />
      ) : mediaRowAlignment === MEDIA_ROW_ALIGNMENT_CENTER ? (
        <AlignHorizontalCenterIcon fontSize="small" color="grey" />
      ) : (
        <AlignHorizontalRightIcon fontSize="small" color="grey" />
      )}
      <FormControl>
        <Select
          value={lastSelectedAlignment}
          onChange={handleMediaRowAlignmentChange}
          variant="standard"
          disabled={mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER}
          input={<ArticleControlsSelect />}
        >
          <MenuItem value={MEDIA_ROW_ALIGNMENT_LEFT}>Left</MenuItem>
          <MenuItem value={MEDIA_ROW_ALIGNMENT_CENTER}>Centre</MenuItem>
          <MenuItem value={MEDIA_ROW_ALIGNMENT_RIGHT}>Right</MenuItem>
        </Select>
      </FormControl>

      <WidgetsIcon fontSize="small" color="grey" />
      <FormControl>
        <Select
          value={
            mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER
              ? MEDIA_ROW_ALIGNMENT_COVER
              : lastSelectedAlignment
          }
          variant="standard"
          onChange={handleMediaRowAlignmentChange}
          input={<ArticleControlsSelect />}
        >
          <MenuItem value={MEDIA_ROW_ALIGNMENT_COVER}>Full Width</MenuItem>
          <MenuItem value={lastSelectedAlignment}>Carousel</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )
}

export default MediaRow
