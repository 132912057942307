import { MenuItem } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetTreeAndNavigate } from '../app'

const ChangeMyTreeIndividualButton = ({ fromTreeSlug }) => {
  const history = useHistory()
  const setTree = useSetTreeAndNavigate()

  const navigateToChangeMyself = async () => {
    await setTree(fromTreeSlug)

    history.push(`/${fromTreeSlug}/change-myself`)
  }

  return (
    <MenuItem onClick={navigateToChangeMyself} sx={{ ml: 1 }}>
      Change who you are in tree
    </MenuItem>
  )
}

export default ChangeMyTreeIndividualButton
