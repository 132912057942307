import { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { searchContent, selectContent } from './writeArticleSlice'

const useStyles = makeStyles(theme => ({
  chooseContentInput: {
    flexShrink: 0,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chooseContent: {
    width: '50%',
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
}))

const ChooseContent = ({ instanceType, onSelectContent, className }) => {
  const classes = useStyles()
  const contentSearchResults = useSelector(selectContent)
  const dispatchSearchContent = useActionDispatcher(searchContent)

  const handleSearchContent = async query => {
    dispatchSearchContent({ query, instanceType })
  }

  return (
    <div className={clsx([classes.chooseContent, className])}>
      <ContentAutocomplete
        onSearch={handleSearchContent}
        onSelectContent={content => {
          if (content && content.id) {
            onSelectContent(content.id)
          } else {
            onSelectContent(undefined)
          }
        }}
        options={contentSearchResults}
      />
    </div>
  )
}

const ContentAutocomplete = ({ onSearch, onSelectContent, options }) => {
  const classes = useStyles()
  const [value, setValue] = useState({})
  const handleChange = (event, newValue) => {
    setValue(newValue)
    onSelectContent(newValue)
  }

  const [inputValue, setInputValue] = useState('')
  const handleInputChange = (event, newInputValue) => {
    onSearch(newInputValue)
    setInputValue(newInputValue)
  }

  const noOptionsText =
    inputValue === '' ? 'Type to search...' : 'No matches found'

  return (
    <div className={classes.chooseContentInput}>
      <Autocomplete
        autoComplete
        getOptionLabel={option => option.display || ''}
        inputValue={inputValue}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            label="Select album"
            variant="outlined"
            fullWidth
          />
        )}
        value={value}
      />
    </div>
  )
}

export default ChooseContent
