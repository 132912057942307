import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import clip from 'text-clipper'

import { useActionDispatcher } from 'src/modules/app'
import ArticleBody from 'src/modules/content/ArticleBody'
import ArticleTags from 'src/modules/content/ArticleTags'
import { Container } from 'src/modules/ui'

import { PublicContext } from '../contexts'
import PublicArticleHeader from './PublicArticleHeader'
import {
  fetchPublicArticle,
  fetchPublicArticleStub,
  selectPublicContent,
} from './contentSlice'
import HtmlHeader from 'src/modules/app/HtmlHeader'
import { useNotification } from 'src/modules/app'
import { useEmbed, usePrerender } from '../hooks'
import { LoadingIndicator } from '../../ui'
import { DEFAULT_ARTICLE_DISPLAY_CONFIG } from '../../content/Article'
import { Divider } from '@mui/material'
import { Sources } from '../../content/SourcesUI'
import {
  INSTANCE_TYPE_DOCUMENT,
  PATH_SEGMENT_DOCUMENT,
  PUBLIC_ROOT,
} from '../../app/links'

import { convertUIGedDate } from '../../common/gedcomDateParser'
import { useUrlTypeFromUrl } from '../../app'

const DEFAULT_BLOCK_LIMIT = 2

/* we could match on the external id ot a slug based on the title */
const isArticleMatch = (article, id) => {
  return article?.id === id || article?.contentSlug === id
}

const PublicArticleContainer = ({
  match,
  config = DEFAULT_ARTICLE_DISPLAY_CONFIG,
  onArticleLoaded,
  cachedArticle,
  fetchPublicArticleStubAction = fetchPublicArticleStub,
  fetchPublicArticleAction = fetchPublicArticle,
  selectPublicContentSelector = selectPublicContent,
  showPreviewControls,
  blogAboutMeUrl,
}) => {
  const { id } = match.params
  const { treeSlug } = useContext(PublicContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const isDocumentPath = location.pathname.includes(PATH_SEGMENT_DOCUMENT)

  const dispatchFetchPublicArticleStub = useActionDispatcher(
    fetchPublicArticleStubAction
  )
  const dispatchFetchPublicArticle = useActionDispatcher(
    fetchPublicArticleAction
  )
  let article = useSelector(selectPublicContentSelector)
  article = article ? article : cachedArticle?.article

  const [loading, setLoading] = useState(true)

  usePrerender(article !== undefined)

  const { showError } = useNotification()

  /*
   do a stub load first and then another full load if you get the same number of blocks as the DEFAULT_BLOCK_LIMIT
   various cruft here to detect loading which I am not thrilled about
   */
  useEffect(() => {
    const fetchArticle = async () => {
      await dispatchFetchPublicArticle({
        id,
        treeSlug,
      })
      if (onArticleLoaded) {
        dispatch(onArticleLoaded(article))
      }
      setLoading(false)
    }

    const fetchArticleStub = async () => {
      const block_limit = isDocumentPath ? 1 : DEFAULT_BLOCK_LIMIT

      try {
        await dispatchFetchPublicArticleStub({
          id,
          treeSlug,
          block_limit: block_limit,
        }).unwrap()
      } catch (err) {
        if (err.status === 404) {
          showError('The content you have requested does not exist')
          history.push(`/`)
        }
      }
    }

    const currentBlockCount = article
      ? article?.contentBlocks?.length
      : undefined
    const totalBlockCount = article ? article?.totalBlockCount : undefined
    if (
      dispatchFetchPublicArticleStub.status !== 'loading' &&
      (currentBlockCount === undefined || !isArticleMatch(article, id))
    ) {
      fetchArticleStub()
    } else if (
      dispatchFetchPublicArticle.status !== 'loading' &&
      fetchPublicArticleStubAction !== fetchPublicArticleAction &&
      currentBlockCount < totalBlockCount
    ) {
      fetchArticle()
    } else {
      setLoading(false)
    }
  }, [
    dispatchFetchPublicArticleStub,
    dispatchFetchPublicArticle,
    history,
    id,
    showError,
    article,
    treeSlug,
    dispatch,
    isDocumentPath,
    onArticleLoaded,
    fetchPublicArticleAction,
    fetchPublicArticleStubAction,
  ])

  if (!isArticleMatch(article, id)) {
    return (
      <Container showBackGround={config.showBackGround}>
        <LoadingIndicator />
      </Container>
    )
  }

  return (
    <PublicArticle
      article={article}
      loading={loading}
      config={config}
      treeSlug={treeSlug}
      showPreviewControls={showPreviewControls}
      blogAboutMeUrl={blogAboutMeUrl}
    />
  )
}

const PublicArticle = ({
  article,
  loading,
  config,
  treeSlug,
  showPreviewControls,
  blogAboutMeUrl,
}) => {
  const { contentBlocks, links, id } = article
  const urlType = useUrlTypeFromUrl()
  const allSources = contentBlocks
    .flatMap(block => block.sources)
    .filter(n => n)

  const description = () => {
    const textContentBlocks = article.contentBlocks.filter(
      block => block.type === 'TEXT'
    )

    let textContent = ''
    textContentBlocks.forEach(block => {
      textContent += block.textContent + ' '
    })

    const parsedText = clip(textContent, 200, {
      html: true,
      maxLines: 3,
    })

    const cleanText = parsedText.replace(/<\/?[^>]+(>|$)/g, '')

    return cleanText
  }

  const headerProps = {
    title: article?.title,
    type: 'article',
    image: article.previewThumbnail,
    url: window.location.href,
    description: description(),
    published_time: article?.publishedAt,
    modified_time: article?.modified,
    author: `${article?.author.knownAs || article?.author.givenName} ${
      article?.author.surname
    }`,
    structuredDataType: 'Article',
    blogAboutMeUrl: blogAboutMeUrl,
    taggedIndividualNames: article?.links
      .filter(article => article.instanceType === 'individual')
      .map(article => article.display),
  }

  const embed = useEmbed()

  const mediaDetailShowTakenOnDate = article.type !== INSTANCE_TYPE_DOCUMENT

  const subtitle = article.dateOfOriginGed
    ? convertUIGedDate(article.dateOfOriginGed)
    : null

  return (
    <>
      {config.showHTMLHeaders && <HtmlHeader {...headerProps} />}
      <Container showBackGround={embed ? false : config.showBackGround}>
        {config.showFullHeader && (
          <PublicArticleHeader
            article={article}
            backButton={true}
            treeSlug={treeSlug}
            subtitle={subtitle}
            subtitleContainerMb={{ xs: 4, md: 2 }}
            showPreviewControls={showPreviewControls}
          />
        )}
        <ArticleBody
          contentBlocks={contentBlocks}
          treeSlug={article?.treeSlug || treeSlug}
          enableLinks={article?.originalTreeAllowPublicVisibility}
          isPublic={true}
          contentType={article.type}
          mediaDetailShowTranscription={true}
          mediaDetailShowTakenOnDate={mediaDetailShowTakenOnDate}
        />
        {loading ? <LoadingIndicator /> : null}
        {config.showSources && allSources && allSources.length > 0 && (
          <>
            <Divider variant="middle" sx={{ mb: 1, ml: 0, mr: 0 }} />
            <Sources
              sources={allSources}
              contentBlockId={undefined}
              contentId={id}
              editMode={false}
              collapsible={false}
              initiallyExpanded={false}
              collatePhotos={true}
            />
          </>
        )}
        {links.length > 0 && urlType === PUBLIC_ROOT && (
          <ArticleTags links={links} />
        )}
      </Container>
    </>
  )
}

export default PublicArticleContainer
