import { v4 as uuidv4 } from 'uuid'
import { NODE_TYPE_ADD_INDIVIDUAL_NODE } from '../api/graphNode'

import { ADD_TYPE_SELF } from './constants'

class AddIndividualNode {
  constructor({
    typeToAdd,
    addedFromIndividualNode,
    otherParent = undefined,
    user = undefined,
    children = [],
    id,
  }) {
    if (typeToAdd === ADD_TYPE_SELF && !user) {
      throw Error('User is required')
    }
    this.nodeType = NODE_TYPE_ADD_INDIVIDUAL_NODE

    this.typeToAdd = typeToAdd
    this.user = user
    this.otherParent = otherParent
    this.addedFromIndividualNode = addedFromIndividualNode
    this.children = children

    // Attributes to allow this to be drawn with graph nodes
    this.id = id || uuidv4()
    this.x = 0
    this.y = 0
    this.size = [1, 1]
    this.width = 1
    this.height = 1
    this.individualNodesInDisplayOrder = [this]
  }

  isIndividualRightOfPartner() {
    return false
  }

  get leftX() {
    return this.x - 0.5
  }

  get rightX() {
    return this.x + 0.5
  }
}

export default AddIndividualNode
