import React, { useState } from 'react'
import { useFormik } from 'formik'
import copy from 'copy-to-clipboard'
import {
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { useActionDispatcher } from '../app'
import {
  clearTextGenerationResults,
  openAiChatGeneration,
  selectChatMessage,
  selectTextGenerationResults,
} from './servicesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const FormContainer = styled('div')(({ theme }) => ({
  maxWidth: '700px',
  minWidth: '500px',
}))

const InputForm = styled('form')(({ theme }) => ({}))
const Results = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}))

const useStyles = makeStyles(theme => ({
  resultsContainer: {
    overflow: 'scroll',
    maxHeight: '300px',
  },
  buttonBar: {
    display: 'flex',
    gap: '2px',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  meta_fields: {
    display: 'flex',
  },
  textGenerationCreateButtonText: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const AiDisclaimerText = () => (
  <Typography color="primary">
    Everyone's talking about AI content creation as the 'next big thing'. It
    will transform the world around us, but for now its results can be eyebrow
    raising and highly amusing. This AI test generator uses ChatGPT to create
    first drafts. Accept and enjoy its quirkiness, and have fun being one of its
    'early adopters'!
  </Typography>
)

const AiTextPromptContainer = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [currentPrompt, setCurrentPrompt] = useState('')
  const dispatchOpenAiTextGeneration = useActionDispatcher(openAiChatGeneration)
  const textGenerationResults = useSelector(selectTextGenerationResults)

  const handleTextGeneration = values => {
    setCurrentPrompt(values.prompt)
    dispatchOpenAiTextGeneration(values)
  }

  const formik = useFormik({
    initialValues: {
      prompt: currentPrompt,
      temperature: 0.5,
      max_tokens: 100,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
      model: 'text-davinci-003',
    },
  })

  const chatText = useSelector(selectChatMessage)

  return (
    <FormContainer>
      {!textGenerationResults && (
        <InputForm onSubmit={formik.handleSubmit} className={classes.form}>
          <TextareaAutosize
            minRows={10}
            id="prompt"
            name="prompt"
            placeholder="Enter a prompt e.g write a history of the USS Saratoga"
            value={formik.values.prompt}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div style={{ whiteSpace: 'pre-wrap' }}>{chatText}</div>
          <div className={classes.buttonBar}>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              color="primary"
              variant="contained"
              onClick={() => handleTextGeneration(formik.values)}
              type="submit"
              disabled={dispatchOpenAiTextGeneration.status === 'loading'}
            >
              {dispatchOpenAiTextGeneration.status === 'loading' ? (
                <CircularProgress />
              ) : (
                'Generate Text'
              )}
            </Button>
          </div>
        </InputForm>
      )}
      {textGenerationResults && (
        <Results>
          <div className={classes.resultsContainer}>
            {textGenerationResults}
          </div>
          <hr />
          <div className={classes.buttonBar}>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              color="primary"
              variant="contained"
              onClick={() => {
                const stripDoublenewLines = textGenerationResults.replace(
                  /\n\s*\n/g,
                  '\n'
                )
                copy(stripDoublenewLines, { format: 'text/plain' })
              }}
              type="submit"
            >
              Copy to Clipboard
            </Button>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              variant="outlined"
              onClick={() => dispatch(clearTextGenerationResults())}
              type="submit"
            >
              Reset
            </Button>
          </div>
        </Results>
      )}
    </FormContainer>
  )
}

export default AiTextPromptContainer
