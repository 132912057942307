import { useSelector } from 'react-redux'

import { Box, Stack, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { Button, IconButton, ConfirmDialog, Link } from 'src/modules/ui'

import { useActionDispatcher } from 'src/modules/app/hooks'

import {
  addChild,
  addFather,
  addMother,
  addPartner,
  selectNodeDirectory,
  updateIndividual,
  removeFather,
  removeMother,
  removeParents,
  removePartner,
} from './viewerSlice'
import {
  childrenWithSpouse,
  isHiddenIndividual,
  isUnknownIndividual,
  siblingsWithEachParent,
} from './api/nodeDirectory'
import { ChooseOrAddIndividualDialog } from './ChooseOrAddIndividual'
import { styled } from '@mui/system'
import { useObjectLink } from '../app/links'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'
import {
  formatIndividualName,
  formatIndividualWithYears,
} from '../ui/individualUtils'
import { ACTION_EDIT } from '../app/appConstants'

export const Relation = ({
  relativeName,
  relationType,
  individual,
  canRemove = true,
  onRemove,
  handleCloseModal,
  isPublic = false,
}) => {
  const relativeDisplayName = relativeName.split(' ')[0]
  const individualLink = useObjectLink(INSTANCE_TYPE_INDIVIDUAL, individual.id)
  const displayName = formatIndividualName(individual)

  return (
    <Box
      boxShadow={2}
      borderRadius={1}
      my={0.6}
      p={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {individual.hasPhoto && !individual.photo ? (
          <img
            alt="Loading Spinner"
            src="/loading-spinner.svg"
            width={34}
            height={34}
          />
        ) : (
          <img
            alt={displayName}
            src={
              individual.photo
                ? individual.photo.fileThumbnail
                : individual.gender === 'F'
                ? '/female-placeholder.png'
                : '/person-placeholder.png'
            }
            width={34}
            height={34}
            style={{ borderRadius: '50%' }}
          />
        )}
        {isUnknownIndividual(individual.id) ||
        (isPublic && isHiddenIndividual(individual)) ? (
          <Typography ml={1}>
            {isUnknownIndividual(individual.id)
              ? formatIndividualWithYears({ ...individual, shortenName: true })
              : 'Hidden'}
          </Typography>
        ) : (
          <Link to={individualLink} onClick={handleCloseModal}>
            <Typography ml={1}>
              {formatIndividualWithYears({ ...individual, shortenName: true })}
            </Typography>
          </Link>
        )}
      </Box>
      {!isPublic && (
        <ConfirmDialog
          onConfirm={onRemove}
          submitText="Remove"
          trigger={props => (
            <IconButton
              permissionAction={ACTION_EDIT}
              permissionParams={{
                instanceType: INSTANCE_TYPE_INDIVIDUAL,
                instance: individual,
                debug: formatIndividualWithYears(individual),
              }}
              {...props}
              disabled={!canRemove}
              size="small"
            >
              <RemoveCircleIcon fontSize="1rem" />
            </IconButton>
          )}
        >
          <Typography>
            Are you sure you want to remove {displayName} as{' '}
            {relativeDisplayName}
            's {relationType}?
          </Typography>
        </ConfirmDialog>
      )}
    </Box>
  )
}

export const ChooseOrAddButton = ({
  relationType,
  onChosenOrAdded,
  individual,
  surname,
}) => {
  return (
    <ChooseOrAddIndividualDialog
      onChosenOrAdded={onChosenOrAdded}
      individual={individual}
      defaultSurname={surname}
      trigger={({ onClick }) => (
        <Button
          permissionAction={ACTION_EDIT}
          permissionParams={{
            instance: individual,
            instanceType: INSTANCE_TYPE_INDIVIDUAL,
          }}
          size="small"
          onClick={onClick}
          variant="text"
          startIcon={<AddCircleIcon fontSize="1rem" />}
          sx={{ mt: 0.5 }}
        >
          Add {relationType}
        </Button>
      )}
    ></ChooseOrAddIndividualDialog>
  )
}
const RelationHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}))

export const IndividualRelationships = ({ individual, handleCloseModal }) => {
  const dispatchUpdateIndividual = useActionDispatcher(updateIndividual)
  const dispatchAddFather = useActionDispatcher(addFather)
  const dispatchAddMother = useActionDispatcher(addMother)
  const dispatchAddPartner = useActionDispatcher(addPartner)
  const dispatchAddChild = useActionDispatcher(addChild)
  const dispatchRemoveFather = useActionDispatcher(removeFather)
  const dispatchRemoveMother = useActionDispatcher(removeMother)
  const dispatchRemoveParents = useActionDispatcher(removeParents)
  const dispatchRemovePartner = useActionDispatcher(removePartner)

  const isLoading = [
    dispatchUpdateIndividual.status,
    dispatchAddFather.status,
    dispatchAddMother.status,
    dispatchAddPartner.status,
    dispatchAddChild.status,
    dispatchRemoveFather.status,
    dispatchRemoveMother.status,
    dispatchRemoveParents.status,
    dispatchRemovePartner.status,
  ].includes('loading')

  const nodeDirectory = useSelector(selectNodeDirectory)

  const mother =
    individual.bioMother &&
    !isUnknownIndividual(individual.bioMother) &&
    nodeDirectory[individual.bioMother]
  const father =
    individual.bioFather &&
    !isUnknownIndividual(individual.bioFather) &&
    nodeDirectory[individual.bioFather]

  const siblingsWithEachSetOfParents = siblingsWithEachParent(
    individual,
    nodeDirectory
  )

  const spouses = individual.spouses
    .map(id => nodeDirectory[id])
    .filter(spouse => !!spouse)

  const childrenWithEachSpouse = spouses.map(spouse => [
    spouse,
    childrenWithSpouse(individual, spouse, nodeDirectory),
  ])

  const handleFatherAdded = chosenFather => {
    dispatchAddFather({
      individualId: individual.id,
      individualAttributes: chosenFather,
    })
  }

  const handleMotherAdded = chosenMother => {
    dispatchAddMother({
      individualId: individual.id,
      individualAttributes: chosenMother,
    })
  }

  const handlePartnerAdded = chosenPartner => {
    dispatchAddPartner({
      individualId: individual.id,
      individualAttributes: chosenPartner,
    })
  }

  const handleChildAdded = (chosenChild, spouseID) => {
    dispatchAddChild({
      individualId: individual.id,
      individualAttributes: chosenChild,
      otherParentId: spouseID,
    })
  }

  const handleSiblingAdded = (chosenSibling, parentID1, parentID2) => {
    dispatchAddChild({
      individualId: parentID1,
      otherParentId: parentID2,
      individualAttributes: chosenSibling,
    })
  }

  const handleRemoveFather = () => {
    dispatchRemoveFather({ individualId: individual.id })
  }

  const handleRemoveMother = () => {
    dispatchRemoveMother({ individualId: individual.id })
  }

  const handleRemoveOneParent = (child, parentId) => {
    if (child.bioFather === parentId) {
      return dispatchRemoveFather({ individualId: child.id })
    } else if (child.bioMother === parentId) {
      return dispatchRemoveMother({ individualId: child.id })
    } else {
      console.error(
        `viewerSlice.handleRemoveOneParent(): neither the given child '${child.id}'s mother ('${child.bioMother}') nor father ('${child.bioFather}') is the given parentId '${parentId}'`
      )
    }
  }

  const handleRemovePartner = partnerId => {
    dispatchRemovePartner({
      individualId: individual.id,
      relationId: partnerId,
    })
  }

  return (
    <Stack sx={{ opacity: isLoading ? 0.2 : 1 }}>
      <RelationHeader>Parents</RelationHeader>
      <Box>
        {father ? (
          <Relation
            handleCloseModal={handleCloseModal}
            relativeName={individual.knownAs || individual.givenName}
            relationType="father"
            individual={father}
            onRemove={handleRemoveFather}
          />
        ) : (
          <ChooseOrAddButton
            surname={individual.surname}
            individual={individual}
            relationType="father"
            onChosenOrAdded={handleFatherAdded}
          />
        )}
      </Box>
      <Box>
        {mother ? (
          <Relation
            handleCloseModal={handleCloseModal}
            relativeName={individual.knownAs || individual.givenName}
            relationType="mother"
            individual={mother}
            onRemove={handleRemoveMother}
          />
        ) : (
          <ChooseOrAddButton
            individual={individual}
            relationType="mother"
            onChosenOrAdded={handleMotherAdded}
          />
        )}
      </Box>

      <RelationHeader>Siblings</RelationHeader>
      {siblingsWithEachSetOfParents.map(([[mother, father], siblings]) => (
        <Box key={[mother?.id, father?.id]}>
          <Typography>
            By {formatIndividualName(mother)} {'&'}{' '}
            {formatIndividualName(father)}
          </Typography>
          <Box sx={{ ml: 3 }}>
            {siblings.map(sibling => (
              <Box key={sibling.id}>
                <Relation
                  handleCloseModal={handleCloseModal}
                  relativeName={individual.knownAs || individual.givenName}
                  relationType="sibling"
                  individual={sibling}
                  canRemove={false}
                />
              </Box>
            ))}
            <ChooseOrAddButton
              individual={individual}
              relationType="sibling"
              onChosenOrAdded={chosenSibling =>
                handleSiblingAdded(chosenSibling, mother?.id, father?.id)
              }
            />
          </Box>
        </Box>
      ))}

      {siblingsWithEachSetOfParents.length === 0 && (
        <Typography>
          You need to add a mother and/or father to define siblings
        </Typography>
      )}

      <RelationHeader>Significant others and children</RelationHeader>
      {childrenWithEachSpouse.map(([spouse, children]) => (
        <Box key={spouse.id}>
          <Relation
            handleCloseModal={handleCloseModal}
            relativeName={individual.knownAs || individual.givenName}
            type="spouse"
            individual={spouse}
            canRemove={!children.length}
            onRemove={() => handleRemovePartner(spouse.id)}
          />
          <Box sx={{ ml: 3 }}>
            {children.map(child => (
              <Box key={child.id}>
                <Relation
                  handleCloseModal={handleCloseModal}
                  relativeName={individual.knownAs || individual.givenName}
                  relationType="child"
                  individual={child}
                  onRemove={() => handleRemoveOneParent(child, individual.id)}
                />
              </Box>
            ))}
            <ChooseOrAddButton
              individual={individual}
              relationType="child"
              surname={
                (individual.gender === 'M'
                  ? individual.surname
                  : spouse?.gender === 'M'
                  ? spouse?.surname
                  : individual.surname) || ''
              }
              onChosenOrAdded={chosenChild =>
                handleChildAdded(chosenChild, spouse.id)
              }
            />
          </Box>
        </Box>
      ))}
      <Box>
        <ChooseOrAddButton
          individual={individual}
          relationType="child with unknown parent"
          onChosenOrAdded={chosenChild => handleChildAdded(chosenChild)}
          surname={individual.surname}
        />
      </Box>
      <Box>
        <ChooseOrAddButton
          individual={individual}
          relationType="significant other"
          onChosenOrAdded={handlePartnerAdded}
        />
      </Box>
    </Stack>
  )
}
