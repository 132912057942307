import { useSelector } from 'react-redux'

import { selectUser } from 'src/modules/auth/authSlice'
import { PublicContext } from 'src/modules/public/contexts'
import { useContext, useMemo } from 'react'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { isEmpty } from 'lodash'
import {
  generateLinkForObject,
  generatePublicLinkForObject,
  INSTANCE_TYPE_INDIVIDUAL,
} from 'src/modules/app/links'
import {
  createUnion,
  createUnknownSpouse,
  isUnknownIndividual,
  unionID,
} from './api/nodeDirectory'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { getQueryParams } from '../../utils'
import { selectPublicContent } from '../public/content/contentSlice'

export const useLinkToInstance = (type, instance) => {
  const authorisedUser = useSelector(selectUser)
  let treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { treeSlug: publicTreeSlug } = useContext(PublicContext)
  const { treeOverride } = getQueryParams()
  const article = useSelector(selectPublicContent)

  const canSeeTreeVisibilityIndividuals = !!authorisedUser
  const isPublicVisibility = instance.visibility === VISIBILITY_PUBLIC

  if (!(canSeeTreeVisibilityIndividuals || isPublicVisibility)) {
    return ''
  }
  if (isUnknownIndividual(instance.id)) {
    return ''
  }

  if (treeSlug && !treeOverride) {
    return generateLinkForObject(
      treeSlug,
      INSTANCE_TYPE_INDIVIDUAL,
      instance.id
    )
  } else {
    let publicSlug = publicTreeSlug
    if (article && article?.treeSlug) {
      publicSlug = article?.treeSlug
    }
    return generatePublicLinkForObject(
      publicSlug,
      INSTANCE_TYPE_INDIVIDUAL,
      instance.id
    )
  }
}

const handleUnknownSubTreeUnions = (node, visibleNodes) => {
  const hasUnknownSpouse = node.spouses?.find(spouse =>
    spouse.includes('unknown')
  )
  if (!node.isUnion && node?.spouses?.length > 0 && hasUnknownSpouse) {
    let unknownChildren = []
    const unknownSpouse = createUnknownSpouse(node)
    const uID = unionID(unknownSpouse.id, node.id)

    visibleNodes.forEach(node => {
      if (
        node.bioMother === unknownSpouse.id ||
        node.bioFather === unknownSpouse.id
      ) {
        unknownChildren.push(node.id)
      }
    })

    if (unknownChildren.length > 0) {
      const unknownUnion = createUnion(
        uID,
        node,
        unknownSpouse,
        unknownChildren
      )

      visibleNodes.push(unknownUnion)
      visibleNodes.push(unknownSpouse)
    }
  }
}

export const useSubTreeNodes = (nodeDirectory, subTree) => {
  return useMemo(() => {
    let visibleNodes = [],
      selectedIndividualIds = new Set()
    let unknownNodes = []

    if (subTree && !isEmpty(nodeDirectory)) {
      visibleNodes = subTree.nodes
        .map(n => {
          return { ...nodeDirectory[n.nodeId] }
        })
        .filter(n => !!n)

      visibleNodes?.forEach(node => {
        handleUnknownSubTreeUnions(node, visibleNodes)
      })

      visibleNodes?.push(...unknownNodes)

      selectedIndividualIds = new Set(
        subTree.nodes.filter(n => n.selected).map(n => n.nodeId)
      )
    }
    return [visibleNodes, selectedIndividualIds]
  }, [nodeDirectory, subTree])
}
