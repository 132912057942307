import React, { createContext, useContext } from 'react'

export const ComponentOverrideContext = createContext({})
export const withOverridableComponent = (Component, name) => props => {
  const { [name]: Override } = useContext(ComponentOverrideContext)
  if (Override) {
    return <Override {...props} />
  } else {
    return <Component {...props} />
  }
}
