import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'

import { progressColor } from './utils'

const Progress = ({ value, indicatorSize, thickness = 4 }) => {
  return (
    <Box
      sx={{ position: 'relative', height: indicatorSize, width: indicatorSize }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={indicatorSize}
        thickness={thickness}
        sx={{
          color: '#DDD',
          position: 'absolute',
        }}
      ></CircularProgress>
      <CircularProgress
        color={progressColor(value)}
        variant="determinate"
        value={value}
        thickness={thickness}
        size={indicatorSize}
        sx={{
          position: 'absolute',
        }}
      ></CircularProgress>
    </Box>
  )
}

export default Progress
