import { useEffect } from 'react'
import { Formik } from 'formik'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Button, FormikTextField, Typography, IconButton } from 'src/modules/ui'
import MatGeocoder from 'react-mui-mapbox-geocoder'
import { mapbox_access_token } from '../map/Map'
import ClearIcon from '@mui/icons-material/Clear'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

import { SimpleDialog, FormikRadioGroup } from 'src/modules/ui'
import Map from '../map/Map'
import { formatDegreesAsString } from '../map/Map'

import { v4 as uuidv4 } from 'uuid'

import { WeAreMap } from '../map/MapEdit'

const useStyles = makeStyles(theme => ({}))

const formatSuggestionLabel = suggestion => {
  var res = ''
  if (suggestion.name) {
    res = suggestion.name
    if (suggestion.full_address) {
      if (suggestion.full_address.startsWith(suggestion.name)) {
        res = ''
      } else {
        res = res + ', '
      }
    }
  }
  if (suggestion.full_address) {
    res = res + suggestion.full_address
  }
  return res
}

export const AddressForm = ({
  address = {},
  lookupLabel = 'Address lookup',
  onValuesChanged, // called when any of the inputs change
}) => {
  const debug = false
  const classes = useStyles()

  const handleGeocodedLocationSelected = (
    geocodeInputSelected,
    values,
    setFieldValue
  ) => {
    if (debug)
      console.debug(
        `AddressForm.handleGeocodedLocationSelected(): called with geocodeInputSelected`,
        geocodeInputSelected
      )
    if (!geocodeInputSelected) {
      // value cleared, clear address
      // console.debug(
      //   `AddressForm.handleGeocodedLocationSelected(): called with falsy geocodeInputSelected, clearing values...`
      // )
      /*
      if (values.latiGed) {
        setFieldValue('latiGed', null)
      }
      if (values.longGed) {
        setFieldValue('longGed', null)
      }*/
      if (values.lati) {
        setFieldValue('lati', null)
      }
      if (values.long) {
        setFieldValue('long', null)
      }
      if (values.freeText) {
        setFieldValue('freeText', '')
      }
    } else {
      // this is for when searchApi is "geocoding"
      // if (
      //   geocodeInputSelected.center &&
      //   geocodeInputSelected.center[1] &&
      //   geocodeInputSelected.center[0]
      // ) {
      //   setFieldValue('latiGed', geocodeInputSelected.center[1])
      //   setFieldValue('longGed', geocodeInputSelected.center[0])
      //   setFieldValue('freeText', geocodeInputSelected.place_name)
      // }

      //this is for when searchApi is "searchbox"
      if (
        geocodeInputSelected.geometry &&
        geocodeInputSelected.geometry.coordinates
      ) {
        //setFieldValue('latiGed', geocodeInputSelected.geometry.coordinates[1])
        //setFieldValue('longGed', geocodeInputSelected.geometry.coordinates[0])
        setFieldValue('lati', geocodeInputSelected.geometry.coordinates[1])
        setFieldValue('long', geocodeInputSelected.geometry.coordinates[0])
        setFieldValue(
          'freeText',
          formatSuggestionLabel(geocodeInputSelected.properties)
        )
      }
    }
  }

  let initialValues = {}
  if (address) {
    initialValues = { ...address } // copy as address might be immutable
  }

  if (!initialValues.coordinatesSource) {
    // console.debug(
    //   `AddressForm: initialValues.coordinatesSource not set, defaulting it.`,
    //   initialValues
    // )
    initialValues.coordinatesSource = 'L'
    // console.debug(`AddressForm: initialValues now`, initialValues)
  }

  const ValuesObserver = ({ values }) => {
    useEffect(() => {
      // console.debug('AddressForm.ValuesObserver: values changed', values)

      if (onValuesChanged) {
        onValuesChanged(values)
      }
    }, [values])
  }

  const constructCurrentMap = address => {
    if (address) {
      //if (address.latiGed && address.longGed) {
      if (address.lati && address.long) {
        const currentMap = {
          title: 'Adhoc map',
          mapLinks: [
            {
              id: 'adhoc_map_from_one_address',
              instanceType: 'address',
              target: {
                id: address.id,
                // latiGed: address.latiGed,
                // longGed: address.longGed,
                lati: address.lati,
                long: address.long,
                //photo: location.photo,
              },
            },
          ],
        }

        // console.debug(
        //   `constructCurrentMap(): returning new currentMap:`,
        //   currentMap
        // )
        return new WeAreMap(currentMap)
      }
    }
    // console.debug(`constructCurrentMap(): returning null`)

    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={params => {
        console.debug(`Address.handleSubmit() called`, params)
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
        <>
          {onValuesChanged && <ValuesObserver values={values} />}
          <Box>
            <FormikRadioGroup
              value={values.coordinatesSource}
              optionLabelValues={[
                ['Address lookup', 'L'],
                ['Point on map', 'P'],
                // also can be G for GEDCOM but we're just going to leave that unselected as we don't know where the data came from
              ]}
              name="coordinatesSource"
              setFieldValue={(name, value) => {
                // clear any existing location data when the radiobutton value changes
                setFieldValue(name, value)
                setFieldValue('freeText', '')
                //setFieldValue('longGed', null)
                //setFieldValue('latiGed', null)
                setFieldValue('long', null)
                setFieldValue('lati', null)
              }}
              //disabled={isSubmitting}
            />
          </Box>
          {values.coordinatesSource !== 'P' ? (
            <MatGeocoder // https://github.com/wheredoesyourmindgo/react-mui-mapbox-geocoder
              inputPlaceholder="Type to lookup address to use in maps..."
              accessToken={mapbox_access_token}
              searchApi="searchbox" // or 'geocoding'
              sessionToken={uuidv4()}
              onSelect={result => {
                // console.debug(
                //   `AddressForm.MatGeocoder.onSelect(): called with result:`,
                //   result
                // )
                handleGeocodedLocationSelected(result, values, setFieldValue)
              }}
              onInputClear={e => {
                //console.debug(`AddressForm.MatGeocoder.onInputClear(): called`)
                handleGeocodedLocationSelected(null, values, setFieldValue)
              }}
              showLoader={true}
              //searchTypes="country region postcode district place locality address poi"
              showInputContainer={false}
              inputValue={values.freeText ?? ''}
              textFieldProps={{
                multiline: true,
                label: lookupLabel,
                helperText: 'type to lookup address to use in maps',
                margin: 'none',
                InputProps: {
                  margin: 'none',
                  startAdornment: null,
                  endAdornment: values.freeText ? (
                    <IconButton
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={e => {
                        handleGeocodedLocationSelected(
                          null,
                          values,
                          setFieldValue
                        )
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    ''
                  ),
                  sx: {
                    '&.Mui-focused .MuiIconButton-root': {
                      color: 'primary.main',
                    },
                  },
                },
                inputProps: {
                  readOnly: false,
                  margin: 'none',
                },
              }}
            />
          ) : (
            <>
              {values.latiGed && values.longGed && (
                <>
                  <Typography mt={1} mb={1}>
                    {/* Latitude: {formatDegreesAsString(values.latiGed)},
                    Longitude: {formatDegreesAsString(values.longGed)} */}
                    Latitude: {formatDegreesAsString(values.lati)}, Longitude:{' '}
                    {formatDegreesAsString(values.long)}
                  </Typography>
                </>
              )}
              <SimpleDialog
                fullScreen
                title="select location"
                trigger={triggerProps => (
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    {...triggerProps}
                    className={classes.button}
                    size="small"
                    variant="outlined"
                  >
                    {/* {values.latiGed ? 'Move' : 'Select'} point on map */}
                    {values.lati ? 'Move' : 'Select'} point on map
                  </Button>
                )}
              >
                {({ closeDialog }) => (
                  <Map
                    initialIsEditing={true}
                    closeMap={params => {
                      if (debug)
                        console.debug(
                          `AddressForm.Dialog.Map.closeMap() called by Map mounted in dialog. params:`,
                          params
                        )
                      if (params?.target) {
                        if (
                          params.target.address &&
                          params.target.address.lati &&
                          params.target.address.long
                        ) {
                          setFieldValue('lati', params.target.address.lati)
                          setFieldValue('long', params.target.address.long)
                        } else if (params.target.lati && params.target.long) {
                          //setFieldValue('latiGed', params.lat)
                          //setFieldValue('longGed', params.lng)
                          setFieldValue('lati', params.target.lati)
                          setFieldValue('long', params.target.long)
                        }
                      }

                      closeDialog()
                    }}
                    currentMap={constructCurrentMap(values) ?? new WeAreMap()} // ?? {}
                    initialMapIsFullWindow={true}
                    initialInteractive={true}
                    singlePoint={true}
                    //zoom={values.latiGed && values.longGed ? 12 : undefined}
                    zoom={values.lati && values.long ? 12 : undefined}
                    isArticle={false}
                    allowThreeD={false}
                    //sidePanelShowLatLong={true}
                  />
                )}
              </SimpleDialog>
              <FormikTextField
                fullWidth
                disabled={isSubmitting}
                label="Describe point location"
                margin="none"
                name="freeText"
              />
            </>
          )}
        </>
      )}
    </Formik>
  )
}
