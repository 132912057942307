import React from 'react'
import { styled } from '@mui/material'

import { AncestralFamilies } from '../home/AncestralFamilies'
import { Button, Typography } from 'src/modules/ui'
import { useSelector } from 'react-redux'
import { selectFamilyPyramidLoaded } from './viewerSlice'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const FamilyNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  background: 'white',
  zIndex: 99,
}))

const PaternalSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingRight: theme.spacing(2),
}))

const MaternalSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}))

const PointerButton = styled(Button)({
  pointerEvents: 'visible',
})

const FamilyNavigationControl = ({
  pyramid,
  handleFamilySelected,
  exploredIndividualNode,
  userHomeIndividual,
}) => {
  const familyPyramidLoaded = useSelector(selectFamilyPyramidLoaded)

  return (
    <FamilyNavigation>
      {pyramid?.fathersFamily && (
        <PaternalSide>
          <Typography variant="body2">Paternal Side</Typography>
          <PointerButton
            permissionAction={ACTION_ALL_ACCESS}
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => handleFamilySelected(pyramid.fathersFamily.family)}
            sx={{ mr: 1 }}
          >
            {pyramid.fathersFamily.surname}
          </PointerButton>
        </PaternalSide>
      )}
      {(pyramid?.fathersFamily || pyramid?.mothersFamily) && (
        <AncestralFamilies
          pyramidLoaded={familyPyramidLoaded}
          userHomeIndividual={exploredIndividualNode}
          traceLineageTo={userHomeIndividual}
          family={pyramid}
          onClickName={handleFamilySelected}
          trigger={props => (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              {...props}
              color="secondary"
              size="small"
              variant="outlined"
            >
              Ancestral Families
            </Button>
          )}
        />
      )}
      {pyramid?.mothersFamily && (
        <MaternalSide>
          <Typography variant="body2">Maternal Side</Typography>
          <PointerButton
            permissionAction={ACTION_ALL_ACCESS}
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => handleFamilySelected(pyramid.mothersFamily.family)}
            sx={{ mr: 1 }}
          >
            {pyramid.mothersFamily.surname}
          </PointerButton>
        </MaternalSide>
      )}
    </FamilyNavigation>
  )
}

export default FamilyNavigationControl
