import React from 'react'
import { INSTANCE_TYPE_EVENT } from 'src/modules/app/links'

import HomePageList from './HomePageList'

import { fetchHomePagesEvents, selectHomePagesEvents } from './homeSlice'

const Events = () => {
  return (
    <HomePageList
      type={INSTANCE_TYPE_EVENT}
      action={fetchHomePagesEvents}
      selector={selectHomePagesEvents}
    />
  )
}

export default Events
