import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { Typography } from './index'

/*
 Pseudo Loading Indicator that adds a random increment to a loading indicator to look like something is going on.
 */
export default function LinearPseudoLoadingIndicator({
  title,
  interval = 1000,
  multiplier = 10,
  loop = true, // if reach 100 start again from 0
  complete = false, //if set to true progress jumps to the end and stops
}) {
  // NOTE! In development mode React 18's strict mode causes setProgress() to be executed twice each time it is queued below.
  // Each time it is called with the same input/previous value.
  // As its response is a random value this can cause the progress bar to jump backwards if the second call randomly happens to return
  // a lower value than the first call.
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    if (complete) {
      setProgress(100)
      return
    }

    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (loop && oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * multiplier
        var newProgress = Math.min(oldProgress + diff, 100)
        if (loop && newProgress === 100) {
          newProgress = 0
        }
        if (newProgress === 100) {
          clearTimeout(timer)
        }
        return newProgress
      })
    }, interval)

    return () => {
      clearInterval(timer)
    }
  }, [complete, interval, loop, multiplier])

  return (
    <Box sx={{ width: '100%' }}>
      {title && <Typography variant="h6">{title}</Typography>}
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  )
}
