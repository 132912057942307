import { styled } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'

const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: prop => !['type', 'active'].includes(prop),
})(({ type, active, theme }) => ({
  ...(type === 'primary'
    ? {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      }
    : {}),
  ...(type === 'secondary'
    ? {
        borderColor: active
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
        color: active
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
        '&:hover': {
          borderColor: theme.palette.secondary.main,
          color: theme.palette.secondary.main,
          backgroundColor: 'transparent',
        },
      }
    : {}),
  borderRadius: 50,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  textTransform: 'none',
}))

export default StyledToggleButton
