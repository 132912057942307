const {
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_API_ENDPOINT,
  REACT_APP_STAGE,
  REACT_APP_SENTRY_DSN,
  MUX_ENVIRONMENT_KEY,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_KEY,
  REACT_APP_REFETCH_IMAGE_ACCESS_COOKIE_MS,
  REACT_APP_MAX_NUM_ADMIN_TREES,
  REACT_APP_BUILD_NUMBER,
  REACT_APP_MAPBOX_TOKEN,
} = process.env

const config = {
  build_number: REACT_APP_BUILD_NUMBER || '---',
  api: {
    endpoint: REACT_APP_API_ENDPOINT,
  },
  cognito: {
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  mapbox: {
    token: REACT_APP_MAPBOX_TOKEN,
  },
  sentry: {
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_STAGE,
  },
  stage: REACT_APP_STAGE,
  mux: {
    envKey: MUX_ENVIRONMENT_KEY,
  },
  googleAnalyticsTracking: {
    key: REACT_APP_GOOGLE_ANALYTICS_TRACKING_KEY,
  },
  reFetchImageAccessCookie: {
    ms: parseInt(REACT_APP_REFETCH_IMAGE_ACCESS_COOKIE_MS),
  },
  publicUrl: 'https://weare.xyz',
  maxNumAdminTrees: REACT_APP_MAX_NUM_ADMIN_TREES,
}

export default config
