import React from 'react'

import { LoadingButton } from '@mui/lab'
import { Tooltip } from '@mui/material'
import { usePermissions } from '../auth/authHooks'
import { PERMISSION_DENIED_TYPE_ERROR } from '../auth/permissionUtils'
import PropTypes from 'prop-types'

const primaryStyles = {
  '&.MuiLoadingButton-root': {
    '&:hover': {
      backgroundColor: 'secondary.main',
    },
  },
}

const secondaryStyles = {
  '&.MuiLoadingButton-root': {
    borderColor: 'primary.main',
    color: 'primary.main',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'secondary.main',
      color: 'secondary.main',
    },
  },
}

export const HasAccessTooltip = ({
  children,
  featureAvailable,
  toolTipText,
  customTitle = '',
}) => {
  const title = featureAvailable ? customTitle : toolTipText

  return (
    <Tooltip title={title}>
      <span style={{ cursor: 'pointer' }}>{children}</span>
    </Tooltip>
  )
}

export const Button = ({
  isLoading,
  variant = 'contained',
  color = 'primary',
  disabled,
  permissionAction,
  permissionParams,
  className,
  sx,
  ...props
}) => {
  const checkPermissions = usePermissions()
  const { hasPermission, permissionDeniedReason, permissionDeniedType } =
    checkPermissions(permissionAction, permissionParams)

  if (permissionDeniedType === PERMISSION_DENIED_TYPE_ERROR) {
    //console.log('PERMISSION', 'Button', permissionDeniedType)
  }

  return (
    <HasAccessTooltip
      toolTipText={permissionDeniedReason}
      featureAvailable={hasPermission}
    >
      <LoadingButton
        className={className}
        sx={{
          borderRadius: 50,
          paddingLeft: 2,
          paddingRight: 2,
          textTransform: 'none',
          '&.Mui-disabled': {
            color: `grey.main !important`,
            borderColor: `grey.main !important`,
          },
          ...(color === 'primary' && variant !== 'text' && primaryStyles),
          ...(color === 'secondary' && variant !== 'text' && secondaryStyles),
          ...sx,
        }}
        loading={isLoading}
        disableElevation
        disabled={disabled || !hasPermission}
        variant={variant}
        color={color}
        {...props}
      />
    </HasAccessTooltip>
  )
}

Button.propTypes = {
  permissionAction: PropTypes.string.isRequired,
}

export default Button
