import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { makeStyles } from '@mui/styles'
import React, { useRef, useState } from 'react'
import { Button, LoadingIndicator } from '../ui'
import { Typography } from '@mui/material'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

const useStyles = makeStyles(theme => ({
  pdfLarge: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
  },
  pdfNavigationContainer: {
    position: 'relative',
  },
  pdfPageNunber: {
    position: 'absolute',
    top: '4px',
    left: '4px',
  },
  pdfNavigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'grey',
  },
  pdfContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  navigateButton: {
    margin: '1rem',
  },
  loading: {
    padding: theme.spacing(6),
    backgroundColor: 'white',
  },
}))

export const PDFViewer = ({
  binaryFileSrc,
  width,
  onPageNumberChanged,
  redraw, //used as a trigger by the parent after it has rendered a new sidebar size
}) => {
  const rootRef = useRef()
  const classes = useStyles()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const changePage = inc => {
    const newPageNumber = pageNumber + inc

    //doing this in a useEffect caused it to sometimes be called when the pageNumber hadn't changed
    if (onPageNumberChanged) {
      onPageNumberChanged(newPageNumber)
    }

    setPageNumber(newPageNumber)
  }

  //finger in the air sizing :))
  const pdfWidth = Math.min(
    rootRef?.current?.offsetWidth ? rootRef?.current?.offsetWidth : 600,
    width * 2
  )

  return (
    <div ref={rootRef} className={classes.pdfLarge}>
      <div className={classes.pdfContainer}>
        <div className={classes.pdfNavigationContainer}>
          <div className={classes.pdfNavigation}>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              size="small"
              className={classes.navigateButton}
              disabled={pageNumber === 1}
              onClick={() => changePage(-1)}
            >
              Prev. Page
            </Button>
            <a style={{ textDecoration: 'none' }} href={binaryFileSrc}>
              <Typography
                color="primary"
                variant="body2"
                underline="hover"
                sx={{ m: 1 }}
              >
                Download
              </Typography>
            </a>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              size="small"
              className={classes.navigateButton}
              disabled={pageNumber === numPages}
              onClick={() => changePage(1)}
            >
              Next Page
            </Button>
          </div>
          <div className={classes.pdfPageNunber}>
            <Typography variant="body">
              {pageNumber} of {numPages}
            </Typography>
          </div>
        </div>
        <Document
          loading={
            <>
              <LoadingIndicator className={classes.loading} />
            </>
          }
          file={binaryFileSrc}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          <Page pageNumber={pageNumber} width={pdfWidth} />
        </Document>
      </div>
    </div>
  )
}
