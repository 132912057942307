import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material'

import {
  selectAuthorisedTreeSlug,
  selectUserHomeIndividual,
} from 'src/modules/auth/authSlice'
import { generateTreeLink } from 'src/modules/app/links'

import ProfilePicture from '../content/ProfilePicture'
import Explore from './Explore'
import { ExploredIndividualViewer } from './treeViewers'

import {
  fetchIndividualsForTarget,
  selectIndividualById,
  selectNodeDirectory,
} from './viewerSlice'
import {
  setExploredIndividualId,
  setEditedIndividualId,
} from './exploreTreeSlice'
import FabButton from '../ui/FabButton'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import EmbedCode from '../ui/EmbedCode'
import { Box } from '@mui/system'
import {
  ACTION_ALL_ACCESS,
  ACTION_EDIT_TREE_INDIVIDUALS,
} from '../app/appConstants'
import EditButton from '../ui/EditButton'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  position: 'relative',
  width: theme.maxWidth,
  padding: theme.spacing(0, 2),
}))

const ContainerTop = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 4),
  zIndex: 2,
  width: '100%',
}))

const TreeViewerForIndividual = React.memo(
  ({ individualId, containingComponent }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const treeSlug = useSelector(selectAuthorisedTreeSlug)
    const exploredIndividual = useSelector(selectIndividualById(individualId))
    const nodeDirectory = useSelector(selectNodeDirectory)

    useEffect(() => {
      dispatch(fetchIndividualsForTarget({ treeSlug, target: individualId }))
    }, [treeSlug, dispatch, individualId])

    useEffect(() => {
      dispatch(setExploredIndividualId(individualId))
    }, [individualId, dispatch])

    const handleEditTree = useCallback(async () => {
      await dispatch(setEditedIndividualId(individualId))
      history.push(generateTreeLink(treeSlug, 'edit'))
    }, [dispatch, history, individualId, treeSlug])

    const userHomeIndividual = useSelector(selectUserHomeIndividual)

    const userHomeIndividualFull = useSelector(
      selectIndividualById(userHomeIndividual?.id)
    )
    return (
      <>
        {exploredIndividual && (
          <>
            <ContainerTop>
              <Container>
                <EditButton
                  text="Tree Edit"
                  permissionAction={ACTION_EDIT_TREE_INDIVIDUALS}
                  icon={<AccountTreeOutlinedIcon fontSize={'small'} />}
                  onClick={handleEditTree}
                />
                <Box display="flex" gap={1}>
                  {containingComponent === 'HomeTree' && (
                    <FabButton
                      permissionAction={ACTION_ALL_ACCESS}
                      text="Change home person"
                      icon={
                        <ProfilePicture
                          user={userHomeIndividualFull}
                          size={30}
                          sx={{
                            fontSize: 16,
                          }}
                        ></ProfilePicture>
                      }
                      onClick={e =>
                        history.push(`/${treeSlug}/change-home-person`)
                      }
                    />
                  )}
                  <EmbedCode buttonText={'Embed Tree'} />
                  <Explore
                    selectMenuConfig={{
                      edit: true,
                      visit: true,
                      explore: true,
                    }}
                    trigger={props => (
                      <FabButton
                        permissionAction={ACTION_ALL_ACCESS}
                        text="Full Screen"
                        icon={
                          <FullscreenIcon fontSize="medium" sx={{ mb: 0.2 }} />
                        }
                        {...props}
                      />
                    )}
                    onCloseModal={() =>
                      dispatch(setExploredIndividualId(exploredIndividual.id))
                    }
                  />
                </Box>
              </Container>
            </ContainerTop>
            <ExploredIndividualViewer
              exploredIndividualNode={exploredIndividual}
              nodeDirectory={nodeDirectory}
            />
          </>
        )}
      </>
    )
  }
)

export default TreeViewerForIndividual
