import { INSTANCE_TYPE_DOCUMENT } from '../app/links'
import WriteArticle from './WriteArticle'

export { default as WriteArticle } from './WriteArticle'
export const WriteDocumentArticle = () => {
  return (
    <WriteArticle
      newContentType={INSTANCE_TYPE_DOCUMENT}
      blocksEditable={true}
    ></WriteArticle>
  )
}
