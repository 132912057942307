import React from 'react'
import { Box, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmDialog, IconButton } from '../ui'
import { makeStyles } from '@mui/styles'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { Thumbnail } from '../photo'
import { THUMB_SIZE_MICRO } from '../photo/Thumbnail'

const useStyles = makeStyles(theme => ({
  photo: {
    '& img': {
      height: 'auto',
      maxWidth: 160,
    },
  },
  deleteButtonContainer: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
}))

const AiWizardImage = ({ isEditing, photo, onRemoveImage, onClick }) => {
  const classes = useStyles()
  return (
    <Box position="relative" sx={{ maxWidth: 160 }} mt={1}>
      <Box
        onClick={onClick}
        sx={{
          pointerEvents: isEditing ? 'none' : 'auto',
        }}
      >
        <Thumbnail
          className={classes.photo}
          file={photo.fileThumbnail}
          type={photo.type}
          size={THUMB_SIZE_MICRO}
        />
      </Box>
      {isEditing && (
        <div className={classes.deleteButtonContainer}>
          <ConfirmDialog
            onConfirm={() => {
              onRemoveImage(photo.id)
            }}
            submitText="Remove"
            submitColor="error"
            trigger={props => (
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                size="small"
                color="primary"
                {...props}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          >
            <Typography>Are you sure you want to remove this image?</Typography>
          </ConfirmDialog>
        </div>
      )}
    </Box>
  )
}

export default AiWizardImage
