import React from 'react'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_EVENT } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'
import { fetchLinkedPageEvents, selectLinkedPageEvents } from './pageSlice'

const Events = () => {
  const { linkedPageId } = useParams()

  return (
    <LinkedPageList
      action={fetchLinkedPageEvents}
      selector={selectLinkedPageEvents}
      target={linkedPageId}
      type={INSTANCE_TYPE_EVENT}
    />
  )
}

export const EventsAsSubject = () => {
  const { linkedPageId } = useParams()

  return (
    <LinkedPageList
      action={fetchLinkedPageEvents}
      selector={selectLinkedPageEvents}
      target={linkedPageId}
      queryAsSubject={true}
      type={INSTANCE_TYPE_EVENT}
    />
  )
}

export default Events
