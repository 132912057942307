import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import MediaNavigator from './MediaNavigator'

const MediaPage = () => {
  const { id } = useParams()
  const history = useHistory()

  const handleReportClosed = () => history.goBack()

  return (
    <MediaNavigator
      canRequestNext={false}
      canRequestPrev={false}
      onReportClosed={handleReportClosed}
      photo={{ id }}
    />
  )
}

export default MediaPage
