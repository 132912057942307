import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PublicContext } from '../contexts'
import {
  selectPublicFamilyById,
  selectFamilyForPage,
  fetchFamily,
} from '../tree/treeSlice'
import { usePublicPageUrlSegments } from './hooks'
import BasePublicPageWithTree from './BasePublicPageWithTree'
import { Container } from 'src/modules/ui'
import HtmlHeader from '../../app/HtmlHeader'
import { usePrerender } from '../hooks'
import { resetPublicPageState } from './pageSlice'

const PublicFamiliesPage = () => {
  const { linkedPageId } = usePublicPageUrlSegments()
  const dispatch = useDispatch()
  const { treeSlug } = useContext(PublicContext)

  var familyObj = useSelector(selectPublicFamilyById(linkedPageId))
  var familyForPage = useSelector(selectFamilyForPage)

  const pageLoading = familyObj === undefined && familyForPage === undefined

  usePrerender(pageLoading === false)

  familyObj = familyObj || familyForPage || {}

  useEffect(() => {
    dispatch(resetPublicPageState({ linkedPageId }))
  }, [linkedPageId, dispatch])

  useEffect(() => {
    // load the
    dispatch(fetchFamily({ treeSlug: treeSlug, familyId: linkedPageId }))
  }, [dispatch, treeSlug, familyForPage, linkedPageId])

  const earliestBirthYear = familyObj.earliestBirthYear
    ? `${familyObj.earliestBirthYear}`
    : 'unknown'

  const title = `${familyObj.surname} Family`

  const headerProps = {
    title: title,
    type: 'profile',
    url: window.location.href,
    first_name: familyObj?.surname,
    last_name: 'Family',
  }

  return (
    <>
      {pageLoading ? (
        <Container />
      ) : (
        <>
          <HtmlHeader {...headerProps} />
          <BasePublicPageWithTree
            subTitle={earliestBirthYear}
            title={title}
            treeTitle={title}
            pinnedArticle={familyObj.pinnedArticle}
            pinnedArticleLabelOveride={'Overview'}
          />
        </>
      )}
    </>
  )
}

export default PublicFamiliesPage
