import React, { useState } from 'react'

import PublicPhotoGallery from 'src/modules/public/photo/PublicPhotoGallery'
import PublicPhotoNavigator from 'src/modules/public/photo/PublicPhotoNavigator'

const PublicContentPhotos = ({ media, mediaDetailShowTranscription }) => {
  const [clickedPhoto, setClickedMedia] = useState(null)

  const handleDialogClose = () => {
    setClickedMedia(null)
  }

  const handleClick = index => {
    setClickedMedia(media[index])
  }

  const maxWidth = media.length === 2 ? 800 : media.length === 1 ? 400 : 'auto'

  return (
    <div style={{ maxWidth }}>
      {!!clickedPhoto && (
        <PublicPhotoNavigator
          media={media}
          initialPhoto={clickedPhoto}
          onClose={handleDialogClose}
          mediaDetailShowTranscription={mediaDetailShowTranscription}
        />
      )}
      <PublicPhotoGallery media={media} onClickPhoto={handleClick} />
    </div>
  )
}

export default PublicContentPhotos
