import React, { useCallback, useEffect, useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import { useEmbed } from '../public/hooks'

import Container from './Container'
import NavLink from './NavLink'
import { isUndefined } from 'lodash'
import { IndividualFamilySearch } from '../search/IndividualFamilySearch'
import { selectUser } from 'src/modules/auth/authSlice'
import { BookmarkAddButton } from '../app/Bookmarks'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
} from '../app/links'
import { useTheme } from '@mui/styles'
import { useBackgroundImage } from '../../utils'

const TabsContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  position: 'sticky',
  top: theme.headerHeight,
  zIndex: 5,
}))

const TabsBackground = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})(({ backgroundImage, theme }) => ({
  opacity: 0,
  transitionDuration: '0.4s',
  backgroundColor: theme.palette.common.white,
  backgroundImage,
  backgroundPosition: 'center top',
  backgroundPositionY: -theme.headerHeight,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% auto',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  height: '100%',
  width: '100%',
  position: 'absolute',
}))

const TabsInner = styled(
  'div',
  {}
)(({ theme }) => ({
  [theme.breakpoints.up('md')]: { minHeight: 40 },
  overflowX: 'auto',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  maxWidth: theme.maxWidth,
  margin: '0 auto',
  padding: theme.spacing(0, 2),
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
  scrollbarWidth: 'thin',
}))

const TabSubContainer = styled('div', {
  shouldForwardProp: prop => !['maxWidth'].includes(prop),
})(({ theme, maxWidth }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: isUndefined(maxWidth) ? theme.maxWidth : maxWidth,
  margin: '0 auto',
  minHeight: '100vh',
  padding: isUndefined(maxWidth)
    ? theme.spacing(4, 2, 4)
    : theme.spacing(0, 0, 4),
}))

const Tabs = ({
  routes,
  title,
  disableSearch,
  type,
  targetId,
  fullWidthSubRootContainer = false,
}) => {
  const tabsRef = useRef()
  const bgRef = useRef(0)
  const theme = useTheme()
  const backgroundImage = useBackgroundImage({})
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const user = useSelector(selectUser)
  const embed = useEmbed()

  const handleScroll = useCallback(() => {
    const yPos = window.scrollY

    if (tabsRef?.current) {
      const tabsTop = tabsRef.current.offsetTop - theme.headerHeight

      if (yPos >= tabsTop - 2) {
        bgRef.current.style.opacity = 1
      } else {
        bgRef.current.style.opacity = 0
      }
    }
  }, [theme.headerHeight])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <Container
      fullWidthSubRootContainer={fullWidthSubRootContainer}
      title={title}
      prePadding={
        !embed && (
          <TabsContainer ref={tabsRef} className="noneDisplayOnPrint">
            <TabsBackground ref={bgRef} backgroundImage={backgroundImage} />
            <TabsInner className={'joyride_first_time_user_tab_bar'}>
              {routes.map(({ navLabel, path, exact, disabled }, i) => (
                <NavLink
                  bold
                  key={i}
                  to={path}
                  exact={exact}
                  replace
                  disabled={disabled}
                  sx={{
                    whiteSpace: { xs: 'nowrap', md: 'wrap' },
                    pt: { xs: 2, md: 0 },
                    pb: { xs: 2, md: 0 },
                  }}
                >
                  {navLabel}
                </NavLink>
              ))}
              <Box sx={{ flexGrow: 1 }} />
              {[
                INSTANCE_TYPE_EVENT,
                INSTANCE_TYPE_ARTEFACT,
                INSTANCE_TYPE_LOCATION,
                INSTANCE_TYPE_INDIVIDUAL,
                INSTANCE_TYPE_FAMILY,
              ].includes(type) &&
                Boolean(targetId) && (
                  <BookmarkAddButton
                    type={type}
                    fontSize="small"
                    targetId={targetId}
                    sx={{ height: 30, width: 30, mr: 0.6, opacity: 0.9 }}
                  />
                )}
              {!disableSearch && !mobileBreakpoint && user && (
                <IndividualFamilySearch />
              )}
              {!user && (
                <Typography
                  textAlign="right"
                  color="primary"
                  fontFamily="FS Split Serif"
                  fontSize={18}
                  sx={{
                    transitionDuration: '0.5s',
                    transitionTimingFunction: 'ease-out',
                  }}
                >
                  powered by{' '}
                  <a
                    href="https://www.weare.xyz"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'red' }}
                  >
                    weare.xyz
                  </a>
                </Typography>
              )}
            </TabsInner>
          </TabsContainer>
        )
      }
    >
      <Switch>
        {routes.map(
          (
            {
              component,
              route,
              exact,
              fullWidth = false,
              pageType,
              componentParams = {},
            },
            i
          ) => {
            const Component = component
            const maxWidth = fullWidth ? '100%' : undefined
            if (fullWidthSubRootContainer) {
              return (
                <Route key={i} exact={exact} path={route}>
                  <TabSubContainer maxWidth={maxWidth}>
                    <Component pageType={pageType} {...componentParams} />
                  </TabSubContainer>
                </Route>
              )
            } else {
              return (
                <Route key={i} exact={exact} path={route}>
                  <Component pageType={pageType} {...componentParams} />
                </Route>
              )
            }
          }
        )}
      </Switch>
    </Container>
  )
}

export default Tabs
