export const VISIBILITY_PUBLIC = 'PUBLIC'
export const VISIBILITY_TREE = 'TREE'
export const VISIBILITY_DEFAULT = 'DEFAULT'

export const getVisibilityControlConfig = (
  url = '',
  treeAllowPublicVisibility = VISIBILITY_TREE,
  instance_visibility = VISIBILITY_DEFAULT,
  instanceType,
  overrideDisabled = false,
  hasPermission = false,
  defaultLabel,
  permittedShareTypes
) => {
  const sharePublicToolTip = 'click to share a public link to this page'
  let visibilityTooltip = defaultLabel
  let shareToolTip = defaultLabel
  let visibilityControlDisabled = false
  let shareButtonDisabled = true
  let privateTreeShare = false
  let hideShareButton = false

  if (url.includes('write-article')) {
    visibilityControlDisabled = false
    shareButtonDisabled = true
    privateTreeShare = false
    hideShareButton = true
    shareToolTip = 'You cannot share when editing'
  } else if (
    instance_visibility === VISIBILITY_TREE &&
    treeAllowPublicVisibility
  ) {
    shareButtonDisabled = true
    shareToolTip = ''
  } else if (overrideDisabled) {
    shareButtonDisabled = true
    visibilityControlDisabled = true
    visibilityTooltip = 'Unable to make living individuals public'
    shareToolTip = visibilityTooltip
  } else if (
    !treeAllowPublicVisibility &&
    permittedShareTypes.includes(instanceType) &&
    !hasPermission
  ) {
    visibilityControlDisabled = true
    shareButtonDisabled = true
    visibilityTooltip = 'you do not have rights to share.'
    shareToolTip = visibilityTooltip
  } else if (
    !treeAllowPublicVisibility &&
    permittedShareTypes.includes(instanceType) &&
    hasPermission
  ) {
    shareToolTip = sharePublicToolTip
    shareButtonDisabled = false
    privateTreeShare = true
    visibilityControlDisabled = false
  } else if (
    !treeAllowPublicVisibility &&
    !permittedShareTypes.includes(instanceType)
  ) {
    shareButtonDisabled = true
    visibilityControlDisabled = true
    visibilityTooltip = 'Cannot share pages of this type from a private tree.'
    shareToolTip = visibilityTooltip
  } else if (treeAllowPublicVisibility && !hasPermission) {
    shareToolTip = sharePublicToolTip
    shareButtonDisabled = false
    visibilityTooltip = 'you not have rights to change visbility.'
    visibilityControlDisabled = true
  } else {
    shareToolTip = sharePublicToolTip
    shareButtonDisabled = false
    visibilityControlDisabled = false
  }

  return {
    visibilityTooltip,
    shareButtonDisabled,
    visibilityControlDisabled,
    shareToolTip,
    privateTreeShare,
    hideShareButton,
  }
}
