import { usePublicPageUrlSegments } from './hooks'

import PublicLinkedPage from './PublicLinkedPage'
import { useContext } from 'react'
import { PublicContext } from '../contexts'
import { generatePublicTreeRoot } from '../../app/links'

const BasePublicPage = () => {
  const { pageType, linkedPageId } = usePublicPageUrlSegments()
  let { treeSlug } = useContext(PublicContext)
  treeSlug = generatePublicTreeRoot(treeSlug).substring(1)

  return (
    <PublicLinkedPage
      pageType={pageType}
      linkedPageId={linkedPageId}
      publicTreeRoute={treeSlug}
    />
  )
}

export default BasePublicPage
