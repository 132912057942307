import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Box, Checkbox } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'

import { useActionDispatcher } from 'src/modules/app'
import {
  selectIndividualById,
  selectAllIndividuals,
  selectIsNodeDirectoryIsLoaded,
} from 'src/modules/viewer/viewerSlice'
import { Button, Container, Typography } from 'src/modules/ui'

import { selectSelf, selectHomeIndividual } from './treesSlice'
import {
  clearExploredNode,
  setExploredIndividualId,
} from 'src/modules/viewer/exploreTreeSlice'
import { selectUser } from 'src/modules/auth/authSlice'
import { SelectMyselfViewer } from 'src/modules/viewer/treeViewers'
import { setUser } from 'src/modules/auth/authSlice'
import { generateTreeLink } from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  clearSearchResults,
  searchIndividuals,
  selectIndividuals,
} from '../writeArticle/writeArticleSlice'
import { fetchIndividualsForTarget } from '../viewer/viewerSlice'
import { useSetTree } from '../app'
import { matchSorter } from 'match-sorter'
import { INDIVIDUAL_SEARCH_KEYS } from '../ui/individualUtils'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectSelf: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  confirm: {
    marginTop: theme.spacing(2),
  },
}))

export const SelectIndividualMode = {
  newArchive: 'newArchive',
  myself: 'myself',
  homePerson: 'homePerson',
}

export function SelectMyself({ selectIndividualMode }) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const setTree = useSetTree()

  const dispatchSelectSelf = useActionDispatcher(selectSelf)
  const dispatchSelectHomeIndividual = useActionDispatcher(selectHomeIndividual)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  // if existing user is changing individual
  const user = useSelector(selectUser)
  const nodeDirectoryLoaded = useSelector(selectIsNodeDirectoryIsLoaded)
  const allTreeIndividuals = useSelector(selectAllIndividuals)
  const individualId =
    user && user.individualOnTree ? user.individualOnTree.id : null
  const existingUserIndividual = useSelector(selectIndividualById(individualId))
  const dispatchFetchIndividualsForTarget = useActionDispatcher(
    fetchIndividualsForTarget
  )

  const individuals = useSelector(selectIndividuals)

  const dispatchSearchIndividuals = useActionDispatcher(searchIndividuals)

  const handleSearchIndividuals = searchValue =>
    dispatchSearchIndividuals(searchValue)

  const [selectedIndividual, setSelectedIndividual] = useState(
    existingUserIndividual || null
  )

  const [selectedId, setSelectedId] = useState(null)

  const [userIsOnTree, setUserIsOnTree] = useState(true)

  const selectionMade = (event, newValue) => {
    if (newValue?.id && ['click', 'keydown'].includes(event.type)) {
      setSelectedId(newValue.id)
      dispatchFetchIndividualsForTarget({
        treeSlug,
        target: newValue.id,
        forceLoad: true,
        immediateFamily: true,
      })
    } else {
      setSelectedId(null)
      setSelectedIndividual(null)
      dispatch(clearExploredNode())
    }
  }

  /* when the dispatchFetchIndividualsForTarget has completed this should fire */
  useEffect(() => {
    const individualObject = allTreeIndividuals?.find(i => i.id === selectedId)
    if (individualObject) {
      setSelectedIndividual(individualObject)
      dispatch(setExploredIndividualId(selectedId))
    }
  }, [allTreeIndividuals, selectedId, dispatch, setSelectedIndividual])

  const errNote = err => {
    if (err.data) {
      if (err.data.error) {
        if ((err.data.error = 'individual_already_associated')) {
          return 'Individual already associated with another user'
        }
      }
    }
    return 'oops... there was a problem doing that'
  }

  const submitButtonClicked = async () => {
    try {
      const [dispatchFunc, g4aEvent] = (() => {
        switch (selectIndividualMode) {
          case SelectIndividualMode.myself:
            return [dispatchSelectSelf, ga4Events.SELECT_MYSELF_ON_TREE]
          case SelectIndividualMode.homePerson:
            return [dispatchSelectHomeIndividual, ga4Events.SELECT_HOME_PERSON]
          case SelectIndividualMode.newArchive:
            return userIsOnTree
              ? [dispatchSelectSelf, ga4Events.SELECT_MYSELF_ON_TREE]
              : [dispatchSelectHomeIndividual, ga4Events.SELECT_HOME_PERSON]
          default:
            return undefined
        }
      })()

      const user = await dispatchFunc(
        {
          id: selectedIndividual.id,
        },
        {
          errorNotification: err => errNote(err),
          successNotification: 'Individual selected',
        }
      ).unwrap()
      dispatch(setUser(user))
      sendEvent(g4aEvent)
      if (selectIndividualMode === SelectIndividualMode.newArchive) {
        if (userIsOnTree || user.givenName) {
          setTree(treeSlug, true)
          history.push(generateTreeLink(treeSlug, 'home/tree'))
        } else {
          history.push('/complete-profile')
        }
      } else {
        history.push(generateTreeLink(treeSlug, 'home/tree'))
      }
    } catch (err) {}
  }

  const handleInputChange = (event, newInputValue) => {
    if (newInputValue) {
      handleSearchIndividuals(newInputValue)
    } else {
      dispatch(clearSearchResults())
    }
  }

  const fuzzyFilterOptions = (options, { inputValue }) => {
    const match = matchSorter(options, inputValue, {
      keys: INDIVIDUAL_SEARCH_KEYS,
    })
    return match
  }

  const selectControl = (
    <Autocomplete
      id="combo-box-demo"
      freeSolo
      filterOptions={fuzzyFilterOptions}
      autoHighlight
      autoComplete
      autoSelect
      options={individuals || []}
      value={selectedIndividual}
      getOptionLabel={i => {
        if (i?.display) {
          return i.display
        } else {
          return ''
        }
      }}
      renderOption={(props, i) => {
        return (
          <li {...props} key={i.id}>
            {i.display} - b.{i.description}
          </li>
        )
      }}
      style={{ width: 300 }}
      renderInput={params => {
        return (
          <TextField
            data-cy="select_myself_list"
            {...params}
            label="Start typing a name ...."
          />
        )
      }}
      onInputChange={handleInputChange}
      onChange={selectionMade}
    />
  )

  const submitButton = (
    <div className={classes.confirm}>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        disabled={!selectedIndividual}
        onClick={submitButtonClicked}
        variant="outlined"
        size="large"
      >
        Confirm
      </Button>
    </div>
  )
  const dummyButton = (
    <div className={classes.confirm} style={{ visibility: 'hidden' }}>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        variant="outlined"
        size="large"
      >
        &nbsp;
      </Button>
    </div>
  )

  const HeaderText = () => {
    switch (selectIndividualMode) {
      case SelectIndividualMode.myself:
        return 'Yourself'
      case SelectIndividualMode.homePerson:
        return "'Home Person'"
      case SelectIndividualMode.newArchive:
        return "Yourself or 'Home Person'"
      default:
        return undefined
    }
  }

  return (
    <Container className={classes.root}>
      <Typography variant="h1">
        Select <HeaderText />
      </Typography>
      <Box sx={{ mt: 4, mb: 4 }}>
        <>
          <div className={classes.selectSelf}>{selectControl}</div>
          {selectedIndividual && (
            <div className={classes.viewer}>
              <SelectMyselfViewer selectedIndividual={selectedIndividual} />
            </div>
          )}
        </>
        {selectIndividualMode === SelectIndividualMode.newArchive && (
          <div
            style={{
              visibility:
                selectedIndividual &&
                nodeDirectoryLoaded &&
                allTreeIndividuals &&
                allTreeIndividuals.length
                  ? 'visible'
                  : 'hidden',
            }}
          >
            The selected person is me{' '}
            <Checkbox
              data-cy="this_is_me_checkbox"
              checked={userIsOnTree}
              onChange={(e, checked) => {
                setUserIsOnTree(!userIsOnTree)
              }}
            />
          </div>
        )}
        {!nodeDirectoryLoaded ? (
          <>{dummyButton}</>
        ) : (
          <>{userIsOnTree && !selectedIndividual ? null : submitButton}</>
        )}
      </Box>
    </Container>
  )
}
