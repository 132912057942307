import React from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Link as MuiLink, styled } from '@mui/material'
import { makeStyles } from '@mui/styles'

const Link = styled(MuiLink, {
  shouldForwardProp: prop => !['bold'].includes(prop),
})(({ bold = false, theme }) => ({
  color: theme.palette.text.primary,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: `${theme.spacing(3.5)} !important`,
  '&:last-child': {
    marginRight: '0 !important',
  },
  position: 'relative',
  ...(bold && {
    fontWeight: '700 !important',
  }),
  transitionDuration: '1s',
  '&::after': {
    content: '""',
    backgroundColor: theme.palette.text.primary,
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 2,
    opacity: 0,
    transition: 'opacity 0.5s',
  },
  '&.active::after, &:hover::after': {
    opacity: 1,
  },
}))

const useStyles = makeStyles(theme => ({
  disabled: {
    color: `${theme.palette.text.secondary} !important`,
    cursor: 'unset',
    opacity: 0.6,
    '&::after': {
      height: '0px !important',
      opacity: '0 !important',
    },
  },
}))

const NavLink = ({ children, disabled, to, isActive, ...props }) => {
  const classes = useStyles()
  return (
    <Link
      component={RouterNavLink}
      activeClassName={'active'}
      className={disabled ? classes.disabled : ''}
      underline="none"
      to={disabled ? window.location : to}
      isActive={isActive}
      {...props}
    >
      {children}
    </Link>
  )
}

export default NavLink
