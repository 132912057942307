import React, { useMemo, useState } from 'react'
import {
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { sortBy } from 'lodash'
import pluralize from 'pluralize'
import { useSelector } from 'react-redux'
import { matchSorter } from 'match-sorter'

import { useActionDispatcher } from 'src/modules/app'

import {
  searchArtefacts,
  searchContent,
  searchMedia,
  searchEvents,
  searchFamilies,
  searchLocations,
  selectArtefacts,
  selectContent,
  selectEvents,
  selectFamilies,
  selectLocations,
  selectMedia,
} from 'src/modules/writeArticle/writeArticleSlice'
import { formatIndividualWithYears } from '../individualUtils'
import { selectNodes } from 'src/modules/viewer/viewerSlice'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_MEDIA,
} from 'src/modules/app/links'
import { useIsAlphaFeatureUser } from '../../auth/hooks'

export const useSearchLinkTypes = filterToInstanceTypes => {
  const artefacts = useSelector(selectArtefacts)
  const content = useSelector(selectContent)
  const events = useSelector(selectEvents)
  const families = useSelector(selectFamilies)
  const locations = useSelector(selectLocations)
  const media = useSelector(selectMedia)
  const individualNodes = useSelector(selectNodes)
  const dispatchSearchFamilies = useActionDispatcher(searchFamilies)
  const dispatchSearchArtefacts = useActionDispatcher(searchArtefacts)
  const dispatchSearchLocations = useActionDispatcher(searchLocations)
  const dispatchSearchEvents = useActionDispatcher(searchEvents)
  const dispatchSearchContent = useActionDispatcher(searchContent)
  const dispatchSearchMedia = useActionDispatcher(searchMedia)
  const showFeature = useIsAlphaFeatureUser()?.documents

  const individualOptions = useMemo(() => {
    if (individualNodes) {
      const individuals = individualNodes
        .filter(i => i.givenName && i.surname)
        .map(individual => ({
          id: individual.id,
          display: formatIndividualWithYears(individual),
          instanceType: INSTANCE_TYPE_INDIVIDUAL,
        }))
      return sortBy(individuals, 'name')
    } else {
      return []
    }
  }, [individualNodes])

  const searchArticles = query =>
    dispatchSearchContent({
      instanceType: INSTANCE_TYPE_ARTICLE,
      query,
    })

  const searchDocuments = query =>
    dispatchSearchContent({
      instanceType: INSTANCE_TYPE_DOCUMENT,
      query,
    })

  const searchAlbums = query =>
    dispatchSearchContent({
      instanceType: INSTANCE_TYPE_PHOTO_ALBUM,
      query,
    })

  // if filterToInstanceTypes is not provided then all linkTypes
  // will be returned except for the ones in defaultLinkTypesExclude
  const defaultLinkTypesExclude = [INSTANCE_TYPE_MEDIA]

  const linkTypes = [
    {
      id: INSTANCE_TYPE_ARTICLE,
      label: 'Article',
      data: content,
      fetchData: searchArticles,
    },
    {
      id: INSTANCE_TYPE_PHOTO_ALBUM,
      label: 'Album',
      data: content,
      fetchData: searchAlbums,
    },
    {
      id: INSTANCE_TYPE_ARTEFACT,
      label: 'Artefact',
      data: artefacts,
      fetchData: dispatchSearchArtefacts,
    },
    showFeature && {
      id: INSTANCE_TYPE_DOCUMENT,
      label: 'Document',
      data: content,
      fetchData: searchDocuments,
    },
    {
      id: INSTANCE_TYPE_EVENT,
      label: 'Occasion',
      data: events,
      fetchData: dispatchSearchEvents,
    },
    {
      id: INSTANCE_TYPE_FAMILY,
      label: 'Family',
      data: families,
      fetchData: dispatchSearchFamilies,
    },
    {
      id: INSTANCE_TYPE_INDIVIDUAL,
      label: 'Individual',
      data: individualOptions,
    },
    {
      id: INSTANCE_TYPE_LOCATION,
      label: 'Place',
      data: locations,
      fetchData: dispatchSearchLocations,
    },
    {
      id: INSTANCE_TYPE_MEDIA,
      label: 'Media',
      data: media,
      fetchData: dispatchSearchMedia,
    },
  ]
    .filter(x => x)
    .filter(item => {
      if (!filterToInstanceTypes) {
        return !defaultLinkTypesExclude.includes(item.id)
      } else {
        return filterToInstanceTypes.includes(item.id)
      }
    })

  return linkTypes
}

const useStyles = makeStyles(theme => ({
  linkForm: {
    minWidth: 300,
  },
  searchTypes: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

const SearchLinkType = ({
  linkType,
  onClickBack,
  onSearch,
  defaultSearchValue = '',
  onSelectLink,
  showBack = true,
  highlightedOptionIDs = new Set(),
  treeSlugOverride = undefined,
}) => {
  const [value, setValue] = useState()
  const [searchValue, setSearchValue] = useState(defaultSearchValue)
  const classes = useStyles()

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, searchValue, {
      keys: ['display'],
    })

  const handleSearchInputChange = (event, value) => {
    setSearchValue(value)
    if (linkType && linkType.fetchData) {
      linkType.fetchData({ query: value, treeSlug: treeSlugOverride })
    }
  }

  const handleClickBack = () => {
    if (showBack) {
      setSearchValue('')
      onClickBack()
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    onSelectLink(newValue)
  }

  const options =
    linkType?.data?.map(option => ({
      highlighted: highlightedOptionIDs.has(option.id),
      ...option,
    })) || []

  return (
    <div className={classes.searchTypes}>
      {showBack && (
        <ListItem button onClick={handleClickBack} key={linkType.id}>
          <ListItemIcon>
            <ChevronLeftIcon />
          </ListItemIcon>
          <ListItemText primary="back" />
        </ListItem>
      )}
      <ListItem>
        <Autocomplete
          autoComplete
          getOptionLabel={option => option.display}
          renderOption={(props, option) => (
            <RenderOption props={props} option={option} key={option.id} />
          )}
          fullWidth
          inputValue={searchValue}
          noOptionsText={'Nothing found'}
          onChange={handleChange}
          onInputChange={handleSearchInputChange}
          options={options}
          filterOptions={filterOptions}
          openOnFocus
          renderInput={params => (
            <TextField
              {...params}
              autoFocus
              label={`Search ${pluralize(linkType?.label, 2)}`}
            />
          )}
          value={value}
        />
      </ListItem>
    </div>
  )
}

const RenderOption = ({ props, option }) => {
  if (option.earliestBirthYear) {
    return (
      <ListItem {...props}>
        <li style={{ fontWeight: option.highlighted ? 'bold' : 'normal' }}>
          {option.display || 'Untitled'} ({option.earliestBirthYear})
        </li>
      </ListItem>
    )
  } else {
    return (
      <ListItem {...props}>
        <ListItemText>{option.display || 'Untitled'}</ListItemText>
      </ListItem>
    )
  }
}

export default SearchLinkType
