import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Popover } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import clsx from 'clsx'
import truncate from 'lodash/truncate'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { useActionDispatcher } from 'src/modules/app'
import {
  Typography,
  LoadingIndicator,
  ReadableDateTime,
  Link,
} from 'src/modules/ui'
import {
  fetchNotifications,
  selectNotifications,
  markNotificationsSeen,
  selectUnseenNotificationIds,
} from 'src/modules/app/appSlice'
import { generateLinkForObject } from 'src/modules/app/links'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_INFORMATION_REQUEST,
  INSTANCE_TYPE_MEDIA,
} from './links'
import { getNotificationHandler } from './notificationHandlers'

const useStyles = makeStyles(theme => ({
  heading: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  loading: { padding: theme.spacing(2) },
  notification: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  unSeen: {
    backgroundColor: theme.palette.lightGrey.main,
  },
  noNotifications: {
    padding: theme.spacing(2),
  },
  root: {
    width: 400,
  },
}))

const instanceLink = ({
  treeSlug,
  systemEventCode,
  instanceId,
  instanceType,
}) => {
  const LINK_TYPES = {
    CREATE_PHOTO_COMMENT: INSTANCE_TYPE_MEDIA,
    CREATE_ARTICLE_COMMENT: INSTANCE_TYPE_ARTICLE,
    SEND_INFO_REQUEST_TO_ACTIVE_USER: INSTANCE_TYPE_INFORMATION_REQUEST,
    [INSTANCE_TYPE_INFORMATION_REQUEST]: INSTANCE_TYPE_INFORMATION_REQUEST,
  }
  const type = LINK_TYPES[systemEventCode] || LINK_TYPES[instanceType]
  return generateLinkForObject(treeSlug, type, instanceId)
}

const notificationTitle = ({
  systemEventCode,
  instanceTitle,
  instanceType,
}) => {
  const makeTitle = {
    [INSTANCE_TYPE_INFORMATION_REQUEST]: 'Ask Family Request',
    SEND_INFO_REQUEST_TO_ACTIVE_USER: 'Ask Family Request',
  }
  return instanceTitle || makeTitle[instanceType] || makeTitle[systemEventCode]
}

const linkingWords = ({ systemEventCode }) =>
  systemEventCode === 'SEND_INFO_REQUEST_TO_ACTIVE_USER'
    ? 'sent you an'
    : 'commented on'

const Notifications = ({
  isFetching,
  next,
  notifications,
  onClose,
  onFetchMore,
  onOpen,
  page,
  trigger,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    onOpen()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    onClose()
    setAnchorEl(null)
  }
  return (
    <>
      {trigger({ onClick: handleClick })}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.root}>
          {isFetching ? (
            <LoadingIndicator className={classes.loading} />
          ) : notifications.length === 0 ? (
            <div className={classes.noNotifications}>
              <Typography>No notifications yet!</Typography>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={notifications.length}
              next={onFetchMore}
              hasMore={next}
              height={250}
            >
              {notifications.map(notification => (
                <Notification
                  key={notification.id}
                  {...notification}
                  handleClose={handleClose}
                />
              ))}
            </InfiniteScroll>
          )}
          {isFetching && page > 0 && (
            <LoadingIndicator className={classes.loading} />
          )}
        </div>
      </Popover>
    </>
  )
}

const Notification = params => {
  const {
    handleClose,
    initiatorGivenName,
    instanceId,
    instanceType,
    instanceTitle,
    seen,
    systemEventCode,
    systemEventCreated,
  } = params
  const classes = useStyles()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const handler = getNotificationHandler({ notification: params })

  if (handler?.getMessage && handler?.getMessage()) {
    return (
      <>
        <div className={clsx([classes.notification, !seen && classes.unSeen])}>
          <Typography className={classes.heading}>
            {handler.getMessage()}
          </Typography>
          <ReadableDateTime inputStr={systemEventCreated} />
        </div>
      </>
    )
  } else {
    return (
      <Link
        to={instanceLink({
          treeSlug,
          instanceId,
          instanceType,
          systemEventCode,
        })}
        onClick={handleClose}
      >
        <div className={clsx([classes.notification, !seen && classes.unSeen])}>
          <Typography className={classes.heading}>
            {initiatorGivenName}
            &nbsp;
            {linkingWords({ systemEventCode })}&nbsp;
            {truncate(
              notificationTitle({
                instanceTitle,
                instanceType,
                systemEventCode,
              }),
              24
            )}
          </Typography>
          <ReadableDateTime inputStr={systemEventCreated} />
        </div>
      </Link>
    )
  }
}

const NotificationsContainer = ({ trigger }) => {
  const [loading, setLoading] = useState(true)
  const {
    results: notifications,
    next,
    page,
  } = useSelector(selectNotifications)
  const dispatchFetchNotifications = useActionDispatcher(fetchNotifications)
  const dispatchMarkNotificationsSeen = useActionDispatcher(
    markNotificationsSeen
  )
  const setSeenTargets = useSelector(selectUnseenNotificationIds)

  const handleFetchMore = async () => {
    if (dispatchFetchNotifications.status === 'loading') {
      return
    }
    await dispatchFetchNotifications({ page: page + 1 })
  }

  const handleClose = async () => {
    await dispatchMarkNotificationsSeen({ setSeenTargets })
  }

  const handleOpen = async () => {
    await dispatchFetchNotifications({ page: 0 })
    setLoading(false)
  }

  return (
    <Notifications
      isFetching={loading}
      next={next}
      notifications={notifications}
      onClose={handleClose}
      onOpen={handleOpen}
      onFetchMore={handleFetchMore}
      page={page}
      trigger={trigger}
    />
  )
}

export default NotificationsContainer
