import { Box, styled } from '@mui/system'

const MessageBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  margin: `${theme.spacing(2)} auto 0`,
  borderRadius: theme.shape.borderRadius,
}))
export default MessageBox
