import { Formik } from 'formik'
import * as Yup from 'yup'
import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import {
  Button,
  Form,
  FormikTextField,
  FormikCheckbox,
  Typography,
} from 'src/modules/ui'

import { updateProfile, selectUser, fetchUser } from './authSlice'
import FormikTimezoneAutocomplete from '../ui/FormikTimezoneAutocomplete'
import { ProfilePicture } from '../ui/PageTitle'
import { MEDIA_TYPE_PHOTO, SelectMedia } from '../photo'
import { useState } from 'react'
import { useAvailableFeatures } from './hooks'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const UserDetailsForm = ({
  handleSelect,
  presetTargets,
  photoUrl,
  allowSelectFromLibrary = true,
}) => {
  const userCanEdit = useAvailableFeatures()?.user?.editTree

  return (
    <Box
      alignItems="center"
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <SelectMedia
        allowSelectFromLibrary={allowSelectFromLibrary}
        aspectRatio={1}
        onSelect={handleSelect}
        onSelectCropped={handleSelect}
        cropAfterSelect={true}
        presetTargets={presetTargets}
        mediaType={MEDIA_TYPE_PHOTO}
        isProfileImage={true}
        trigger={props => (
          <ProfilePicture
            {...{ photoUrl, withEditMedia: userCanEdit, ...props }}
          />
        )}
        hideTags={true}
      />
      <Stack direction="row" width="100%" gap={2} mt={3}>
        <FormikTextField
          fullWidth
          label="Given Name"
          margin="normal"
          name="givenName"
          required
          autoComplete="given-name"
        />
        <FormikTextField
          fullWidth
          label="Surname"
          margin="normal"
          name="surname"
          required
          autoComplete="family-name"
        />
      </Stack>
      <FormikTextField
        fullWidth
        label="Known As"
        margin="normal"
        name="knownAs"
        autoComplete="nickname"
      />
    </Box>
  )
}

const ProfileForm = ({
  handleSubmit,
  isSubmitting,
  onTimezoneChange,
  handleSelect,
  presetTargets,
  photoUrl,
  ...props
}) => {
  return (
    <Form>
      <UserDetailsForm
        handleSelect={handleSelect}
        presetTargets={presetTargets}
        photoUrl={photoUrl}
      />
      <FormikTextField
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        type="email"
      />
      <Box sx={{ mt: 1 }}>
        <Typography>Notifications:</Typography>
        <FormikCheckbox name="wantsEmail" label="Email" />
      </Box>
      <Box sx={{ mt: 1 }}>
        <FormikTimezoneAutocomplete
          name="timezone"
          label="Timezone"
          onChange={onTimezoneChange}
        />
      </Box>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        color="primary"
        onClick={handleSubmit}
        isLoading={isSubmitting}
        size="large"
        type="submit"
        sx={{ mt: 1 }}
      >
        Update Profile
      </Button>
    </Form>
  )
}

const Profile = ({ ...props }) => {
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const dispatchUpdateProfile = useActionDispatcher(updateProfile)
  const user = useSelector(selectUser)
  const [photo, setPhoto] = useState(user.photo)
  const initialValues = {
    givenName: user.givenName,
    surname: user.surname,
    knownAs: user.knownAs || user.givenName.split(' ')[0],
    email: user.email,
    wantsEmail: user.wantsEmail,
    timezone: user.timezone,
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    wantsEmail: Yup.bool(),
    givenName: Yup.string().required(),
    surname: Yup.string().required(),
  })

  const handleSubmit = async (
    { givenName, surname, knownAs, email, timezone, wantsEmail },
    { setErrors }
  ) => {
    try {
      await dispatchUpdateProfile(
        {
          givenName,
          surname,
          knownAs,
          email,
          wantsEmail,
          timezone,
          photo,
        },
        {
          successNotification: 'User profile updated',
        }
      ).unwrap()
      dispatchFetchUser()
    } catch (error) {
      setErrors(error.data)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <ProfileForm
          {...formik}
          {...props}
          isSubmitting={dispatchUpdateProfile.status === 'loading'}
          onTimezoneChange={(e, newValue) =>
            formik.setFieldValue('timezone', newValue)
          }
          handleSelect={img => setPhoto(img)}
          // presetTargets={presetTargets}
          photoUrl={photo?.fileMedium}
        />
      )}
    </Formik>
  )
}

export default Profile
