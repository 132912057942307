import { useCallback, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { debounce, isEqual } from 'lodash'

const AutoFormSubmitter = () => {
  const { initialValues, submitForm, values } = useFormikContext()
  const debouncedSubmit = useRef(debounce(submitForm, 1000), [])
  const doSubmit = useCallback(
    (...args) => debouncedSubmit.current(...args),
    [debouncedSubmit]
  )

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      doSubmit()
      // Update to title needs to be debounced to prevent constant backend calls.
      // Debounce stops titleDraft from updating quick enough meaning that a user could edit the title, press publish and the new title won't show
      // Below is the easiest solution
      window.titleDraft = values.title
    }
  }, [doSubmit, initialValues, values])

  return null
}

export default AutoFormSubmitter
