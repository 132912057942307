import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useNotification } from '../app'
import { Button } from '../ui'
import { v4 as uuid } from 'uuid'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const YouTubeLinkDialog = ({ onAddURL, open, trigger }) => {
  const [modalOpen, setModalOpen] = useState(open)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [inputVal, setInputVal] = useState('')
  const { showError } = useNotification()

  const handleShowModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    if (!isSubmitting) {
      setInputVal('')
      setModalOpen(false)
    }
  }

  const handleSubmit = async () => {
    let videoForPreview

    if (inputVal) {
      setIsSubmitting(true)
      if (!inputVal.includes('youtube') && !inputVal.includes('youtu.be')) {
        showError('Must be a valid YouTube URL')
        return false
      }
      const regex =
        /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
      const deconstructedUrl = regex.exec(inputVal)
      if (deconstructedUrl && deconstructedUrl?.length >= 3) {
        const youtubeVideoId = deconstructedUrl[3]

        const videoThumbnail = `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`

        const timestampRegex = /t=(\d+)/
        const timestampMatch = inputVal.match(timestampRegex)
        const timestamp = timestampMatch ? Number(timestampMatch[1]) : null

        videoForPreview = {
          id: uuid(),
          name: '',
          description: '',
          youtubeVideoId,
          type: 'video/youtube',
          timestamp,
          file: {
            youtubeVideoId,
            preview: videoThumbnail,
            name: '',
            type: 'video/youtube',
          },
          dateTakenGed: '',
        }
      } else {
        showError('Must be a valid YouTube URL')
        return false
      }

      setIsSubmitting(false)
    }
    onAddURL(videoForPreview)
    handleCloseModal()
  }

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogContent>
          <TextField
            value={inputVal}
            onChange={e => setInputVal(e.target.value)}
            label="Add YouTube video URL"
            sx={{ width: 400 }}
          />
          <DialogActions>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button permissionAction={ACTION_ALL_ACCESS} onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default YouTubeLinkDialog
