import { useCallback, useState } from 'react'
import { Fab, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import LockIcon from '@mui/icons-material/Lock'

import { Tooltip } from 'src/modules/ui'

import { VISIBILITY_PUBLIC, VISIBILITY_TREE } from './visibilityUtils'

const ERROR_MSGS = {
  non_admin_cannot_set_visibility:
    'Only tree administrators can set visibility',
  tree_does_not_allow_public_content: 'This tree does not allow public content',
  living_individual_cannot_be_public:
    'Living individuals cannot be made public',
}
const DEFAULT_ERROR_MSG = 'Something went wrong, please try again later'

export const getErrorNotificationText = err => {
  return ERROR_MSGS[err.data.error] || DEFAULT_ERROR_MSG
}

const visibilities = [
  {
    id: VISIBILITY_PUBLIC,
    label: 'Public: Share this page with your friends and family',
    Icon: PublicIcon,
  },
  {
    id: VISIBILITY_TREE,
    label: 'Private: Only users invited to this tree can view this page',
    Icon: LockIcon,
  },
]

export const visibilityMap = visibilities.reduce((map, { id, ...option }) => {
  map[id] = option
  return map
}, {})

const VisibilityControl = ({
  disabled,
  Icon,
  tooltip,
  onChangeVisibility,
  isLoading,
  iconSize = 'small',
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <Fab
            onClick={handleClick}
            disabled={disabled}
            isLoading={isLoading}
            white
            sx={{ height: 36, width: 36, boxShadow: 'none' }}
          >
            <Icon fontSize={iconSize} />
          </Fab>
        </span>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {visibilities.map(({ id, label, Icon }) => (
          <MenuItem
            onClick={() => onChangeVisibility(id, handleClose)}
            key={id}
          >
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default VisibilityControl
