import React from 'react'
import { styled } from '@mui/material'
import { AppBar } from 'src/modules/ui'
import { Link } from '@mui/material'
import config from '../../config'

export const Logo = styled('img')({
  display: 'block',
  width: 240,
})

const MenuLogo = () => {
  return (
    <>
      <Link href={config.publicUrl}>
        <Logo alt="weare.xyz" src="/logo.png" />
      </Link>
    </>
  )
}

const AuthNavBar = () => {
  return (
    <AppBar
      leftSide={
        <>
          <MenuLogo />
        </>
      }
      rightSide={<></>}
    />
  )
}

export default AuthNavBar
