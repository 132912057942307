import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isUndefined } from 'lodash'
import { selectIndividualById } from 'src/modules/viewer/viewerSlice'
import { useEffect, useState } from 'react'
import { INSTANCE_TYPE_FAMILY } from '../app/links'

export const useLinkedPageTargets = () => {
  const { linkedPageId } = useParams()
  const target = linkedPageId
  const individual = useSelector(selectIndividualById(target))

  const presetTargets = [target]
  if (!isUndefined(individual)) {
    presetTargets.push(individual.family)
  }

  return { target, presetTargets }
}

export const calculateRelatedFamilies = (
  individual,
  fatherOfIndividual,
  motherOfIndividual
) => {
  const fams = []
  if (individual) {
    let paternalFamLink = null
    let maternalFamLink = null
    if (
      fatherOfIndividual &&
      fatherOfIndividual.family &&
      fatherOfIndividual.surname
    ) {
      paternalFamLink = {
        target: fatherOfIndividual.family,
        display: fatherOfIndividual.surname,
        instanceType: INSTANCE_TYPE_FAMILY,
      }
    }

    if (
      motherOfIndividual &&
      motherOfIndividual.family &&
      motherOfIndividual.surname
    ) {
      maternalFamLink = {
        target: motherOfIndividual.family,
        display: motherOfIndividual.surname,
        instanceType: INSTANCE_TYPE_FAMILY,
      }
    }

    if (paternalFamLink) fams.push(paternalFamLink)
    if (maternalFamLink) fams.push(maternalFamLink)
  }
  return fams
}

export const useSelectRelatedFamilies = individual => {
  const [families, setFamilies] = useState([])

  const { bioFather, bioMother, family, surname } = individual
  const fatherOfIndividual = useSelector(selectIndividualById(bioFather))
  const motherOfIndividual = useSelector(selectIndividualById(bioMother))

  useEffect(() => {
    setFamilies(
      calculateRelatedFamilies(
        individual,
        fatherOfIndividual,
        motherOfIndividual
      )
    )
  }, [individual, family, fatherOfIndividual, motherOfIndividual, surname])

  return families
}
