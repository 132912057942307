import { useTheme } from '@mui/material'

function useViewerColours() {
  const { palette } = useTheme()

  return {
    ICON_BACKGROUND: palette.common.white,
    ICON_STROKE: palette.grey.main,
    NODE_BG: palette.common.white,
    NODE_BG_CURRENT_USER: '#89759C', // not in main palette
    NODE_BG_EXPLORED: palette.primary.main,
    NODE_BG_TO_BE_DELETED: 'rgb(180, 30, 60)', // not in main palette
    NODE_TEXT: palette.primary.main,
    NODE_TEXT_CURRENT_USER: palette.common.white,
    NODE_TEXT_EXPLORED: palette.common.white,
    NODE_UNION_STROKE: palette.grey.main,
    NODE_UNION_FILL: palette.common.white,
    NODE_BORDER: palette.primary.main,
    NODE_BORDER_SELECTED: palette.secondary.dark,
    NODE_BORDER_SELECTED_SECONDARY: palette.lightPurple.main,
    NODE_TEXT_DISABLED: palette.text.secondary,
    NODE_BORDER_DISABLED: palette.grey.main,
    NODE_BACKGROUND_DISABLED: palette.toolbarGrey.main,
  }
}

export default useViewerColours
