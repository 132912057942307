import { range } from 'lodash'

export const months = [
  {
    value: 0,
    text: '',
  },
  {
    value: 1,
    text: 'Jan',
  },
  {
    value: 2,
    text: 'Feb',
  },
  {
    value: 3,
    text: 'Mar',
  },
  {
    value: 4,
    text: 'Apr',
  },
  {
    value: 5,
    text: 'May',
  },
  {
    value: 6,
    text: 'Jun',
  },
  {
    value: 7,
    text: 'Jul',
  },
  {
    value: 8,
    text: 'Aug',
  },
  {
    value: 9,
    text: 'Sep',
  },
  {
    value: 10,
    text: 'Oct',
  },
  {
    value: 11,
    text: 'Nov',
  },
  {
    value: 12,
    text: 'Dec',
  },
]

export const isLeapYear = year => {
  // leap year needs to be divisble by 4 and either not divisible by 100 or divisble by both 100 and 400
  const divisibleBy4 = Number.isSafeInteger(year / 4)
  const divisibleBy100 = Number.isSafeInteger(year / 100)
  const divisibleBy400 = Number.isSafeInteger(year / 400)

  if (!divisibleBy4 || (divisibleBy100 && !divisibleBy400)) {
    return false
  } else {
    return true
  }
}

const getDaysArr = numDays => {
  const rangeArr = range(1, numDays + 1)
  return rangeArr.map(day => ({ value: day, text: day.toString() }))
}

export const dayCount = (month, selectedYear) => {
  const monthsWith30Days = [4, 6, 9, 11]

  let dayData = [
    {
      value: 0,
      text: 'Unknown',
    },
  ]

  if (monthsWith30Days.includes(month)) {
    const daysInMonth = 30
    dayData = [...dayData, ...getDaysArr(daysInMonth)]
  } else if (month === 2) {
    if (isLeapYear(selectedYear)) {
      const daysInMonth = 29
      dayData = [...dayData, ...getDaysArr(daysInMonth)]
    } else {
      const daysInMonth = 28
      dayData = [...dayData, ...getDaysArr(daysInMonth)]
    }
  } else {
    const daysInMonth = 31
    dayData = [...dayData, ...getDaysArr(daysInMonth)]
  }

  return dayData
}
