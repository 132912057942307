import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  links: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}))

const FormLinks = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.links}>{children}</div>
}

export default FormLinks
