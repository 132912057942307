import { useSelector } from 'react-redux'
import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'

import { getResponseNameForType } from './Comments'
import ContentCard from './ContentCard'
import { Typography } from 'src/modules/ui'
import LinkWithHash from '../ui/LinkWithHash'
import pluralize from 'pluralize'
import RefreshDialog from '../ui/RefreshDialog'
import {
  INSTANCE_TYPE_ARTICLE,
  generateTreeLink,
  generateLinkForObject,
} from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  generateBlogPreviewLink,
  generatePublicLinkForObject,
  generateTreeOverrideQueryString,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_MEDIA_GROUP,
} from '../app/links'
import { PublicContext } from 'src/modules/public/contexts'
import { selectCurrentTree, selectIsBlogTree } from '../auth/authSlice'
import { ARTICLE_STATE_DRAFT } from '../writeArticle/WriteArticle'
import { BLOG_TREE } from '../common/constants'
import { ConfirmDialog } from '../ui'

const StyledCommentsContainer = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  display: 'flex',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.backgroundGrey.main,
  transform: 'translate3d(0, 0, 0)',
}))

const StyledHashLink = styled(LinkWithHash)({
  textDecoration: 'none',
})

export const ContentInlineComments = ({
  content: { comments = [], type, contentSlug, ...content },
  onDelete,
  newCard,
  user,
  width,
  urlOverride,
}) => {
  const [triggerReload, setTriggerReload] = useState(false)
  const responseName = pluralize(getResponseNameForType(type), true)

  const isBlogTree = useSelector(selectIsBlogTree)
  const currentTree = useSelector(selectCurrentTree)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { treeSlug: publicTreeSlug } = useContext(PublicContext)
  const { id, state } = content

  const url = useMemo(() => {
    if (urlOverride) {
      return urlOverride(publicTreeSlug, type, id, contentSlug)
    }
    if (publicTreeSlug && state === 'PUBLISHED' && !treeSlug) {
      return generatePublicLinkForObject(publicTreeSlug, type, id)
    }
    if (
      isBlogTree ||
      ((type === INSTANCE_TYPE_ARTICLE || type === INSTANCE_TYPE_DOCUMENT) &&
        state === ARTICLE_STATE_DRAFT)
    ) {
      const queryString =
        isBlogTree && currentTree?.slug !== treeSlug
          ? generateTreeOverrideQueryString(currentTree?.slug, BLOG_TREE)
          : ''
      if (state === 'PUBLISHED') {
        return generateBlogPreviewLink(treeSlug, '', id, currentTree?.slug)
      } else {
        const path =
          type === INSTANCE_TYPE_DOCUMENT ? 'edit-document' : 'write-article'
        return generateTreeLink(treeSlug, `${path}/${id}`, queryString)
      }
    } else {
      return generateLinkForObject(treeSlug, type, id)
    }
  }, [
    id,
    state,
    treeSlug,
    publicTreeSlug,
    type,
    contentSlug,
    urlOverride,
    currentTree?.slug,
    isBlogTree,
  ])

  const handleImgDelete = instances => {
    const treeIndividual = instances.some(str =>
      str.includes('tree individual')
    )
    if (treeIndividual) {
      setTriggerReload(true)
    } else {
      onDelete()
    }
  }

  const Card = () => (
    <>
      <RefreshDialog refreshModalOpen={triggerReload} />
      <ContentCard
        {...content}
        disabled={state === 'DRAFT'}
        url={url}
        sx={{ mb: 1 }}
        onDelete={onDelete}
        type={type}
        user={user}
        handleImgDelete={handleImgDelete}
        width={width}
      />
      {type !== INSTANCE_TYPE_MEDIA_GROUP && (
        <StyledCommentsContainer>
          <CommentIcon sx={{ mr: 1 }} />
          <Typography variant="subtitle4">
            <StyledHashLink
              disabled={state === 'DRAFT'}
              smooth
              to={`${url}#${responseName}`}
            >
              {`${responseName} (${comments.length})`}
            </StyledHashLink>
          </Typography>
        </StyledCommentsContainer>
      )}
    </>
  )

  return (
    <Box>
      {state === 'DRAFT' ? (
        <ConfirmDialog
          cancelText={''}
          submitText="OK"
          trigger={props => (
            <div
              onClick={e => {
                props.onClick()
              }}
            >
              <Card />
            </div>
          )}
        >
          <Typography>You cannot edit content in mobile view</Typography>
        </ConfirmDialog>
      ) : (
        <Card />
      )}
    </Box>
  )
}

ContentInlineComments.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
  onDelete: PropTypes.func,
  user: PropTypes.object,
}

const ContentInlineCommentsContainer = ContentInlineComments

export default ContentInlineCommentsContainer
