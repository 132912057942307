import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'

import { Typography } from 'src/modules/ui'

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const FormLink = ({ className, ...props }) => {
  const styles = useStyles()
  return (
    <Typography
      className={clsx([styles.link, className])}
      color="textSecondary"
      {...props}
    />
  )
}

export default FormLink
