import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useNotification } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikPasswordField,
  FIELD_REQUIRED,
} from 'src/modules/ui'

import { checkUserAuthState } from './utils'
import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLinks from './FormLinks'
import FormLink from './FormLink'
import { ACTION_LOGIN } from '../app/appConstants'

export const ResetPasswordForm = ({
  handleSubmit,
  isSubmitting,
  onClickBackToLogin,
  onClickResendCode,
}) => (
  <div>
    <FormHeader
      title="Set Your Password"
      subtitle="Please set a new password"
    />
    <Form>
      <FormikPasswordField fullWidth label="Password" name="password" />
      <FormLinks>
        <FormLink onClick={onClickBackToLogin}>Back to login</FormLink>
      </FormLinks>
      <FormActions>
        <Button
          permissionAction={ACTION_LOGIN}
          onClick={handleSubmit}
          color="primary"
          isLoading={isSubmitting}
          size="large"
          type="submit"
        >
          Set Password
        </Button>
      </FormActions>
    </Form>
  </div>
)

const ResetPassword = ({ onStateChange, user, ...props }) => {
  const { showError } = useNotification()

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(FIELD_REQUIRED),
  })
  const initialValues = { password: '' }

  const handleSubmit = async ({ password }, { setFieldError }) => {
    try {
      const updatedUser = await Auth.completeNewPassword(user, password)
      await checkUserAuthState(updatedUser, onStateChange)
    } catch (err) {
      if (err.code === 'InvalidPasswordException') {
        setFieldError('password', err.message)
      } else {
        showError(err.message)
      }
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => <ResetPasswordForm {...formik} {...props} />}
    </Formik>
  )
}

export default ResetPassword
