import { Box, Checkbox, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MenuItem } from '@mui/material'
import { ConfirmDialog } from '../ui'
import { useActionDispatcher } from '../app'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { selectRootTrees } from 'src/modules/auth/authSlice'
import { copyItemsToOtherTree } from './treesSlice'

const useStyles = makeStyles(theme => ({
  deleteContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const CopyTreeButton = ({ fromTreeSlug }) => {
  const classes = useStyles()
  const copyTreeAction = useActionDispatcher(copyItemsToOtherTree)

  const rootTrees = useSelector(selectRootTrees)

  const [selectedTreeSlug, setSelectedTreeSlug] = useState(null)
  const [iUnderstand, setIUnderstand] = useState(false)
  const [showCopyConfirmationDialog, setShowCopyConfirmationDialog] =
    useState(false)
  const [copiedToTreeSlug, setCopiedToTreeSlug] = useState(null)

  return (
    <Box className={classes.deleteContainer}>
      <ConfirmDialog
        open={showCopyConfirmationDialog}
        onClose={() => {
          setCopiedToTreeSlug(null)
          setShowCopyConfirmationDialog(false)
        }}
        cancelText={null}
        submitText="Ok"
      >
        Copying is in progress. Please wait a few minutes then refresh your
        browser and explore tree "{copiedToTreeSlug}".
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={async () => {
          copyTreeAction(
            { fromTreeSlug: fromTreeSlug, toTreeSlug: selectedTreeSlug },
            { successNotification: 'Tree copying in progress' }
          ).then(params => {
            setCopiedToTreeSlug(selectedTreeSlug)
            setShowCopyConfirmationDialog(true)
            console.debug(`copyTreeAction().then(): params:`, params)
          })
        }}
        title="Copy items into other tree"
        submitText={
          selectedTreeSlug ? `Copy into "${selectedTreeSlug}"` : 'Select tree'
        }
        submitDisabled={!selectedTreeSlug || !iUnderstand}
        submitColor="error"
        cancelColor="primary"
        cancelVariant="contained"
        trigger={props => <MenuItem {...props}>Copy</MenuItem>}
        onClose={() => {
          setSelectedTreeSlug(null)
          setIUnderstand(false)
        }}
      >
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          Copy all items from tree <b>"{fromTreeSlug}"</b> into which other
          tree?
        </Typography>

        <Select
          onChange={event => {
            setSelectedTreeSlug(event.target.value)
            setIUnderstand(false)
          }}
        >
          {rootTrees.map(
            tree =>
              tree.slug !== fromTreeSlug &&
              tree.userIsAdmin && (
                <MenuItem
                  key={tree.slug}
                  value={tree.slug}
                  selected={selectedTreeSlug === tree.slug}
                >
                  {tree.name}
                </MenuItem>
              )
          )}
        </Select>

        {selectedTreeSlug && (
          <>
            <Typography variant="body1" sx={{ marginBottom: 1, marginTop: 1 }}>
              This will copy everything from "{fromTreeSlug}" into{' '}
              <b>"{selectedTreeSlug}"</b>.<br />
              <br />
              This operation could cause problems in "{selectedTreeSlug}" and is
              NOT reversible. We recommend first creating a new tree, with only
              your desired home person in it, and copying both of these trees
              into it.
              <br />
              <br />
              Contact us using the 'Send feedback' button if you'd like direct
              assistance with this feature.
            </Typography>
            I understand{' '}
            <Checkbox
              checked={iUnderstand}
              onChange={(e, checked) => {
                setIUnderstand(checked)
              }}
            />
          </>
        )}
      </ConfirmDialog>
    </Box>
  )
}

export default CopyTreeButton
