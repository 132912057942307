import { createDefaultThemeParams, themeBuilder } from '../../../ui/theme'

export const misc = themeId => {
  let params = createDefaultThemeParams()

  const white = '#FFFFFF'
  const lightGrey = '#D3D3D3'
  const accent = '#000000'

  params.colors.darkPurple = accent
  params.colors.secondaryGrey = lightGrey
  params.colors.grey = white
  params.backgroundImage = `/${themeId}.jpg`

  const theme = themeBuilder(params)

  return theme
}
