import React, { useRef, useState } from 'react'
import { Dialog, Box } from '@mui/material'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import { TabLayout, Typography } from 'src/modules/ui'
import ThumbnailGallery from 'src/modules/photo/ThumbnailGallery'

import UploadAlbum from './UploadAlbum'
import AlbumForm from './AlbumForm'
import AlbumModalLayout from './AlbumModalLayout'

import { useUploadMedia } from './hooks'
import YouTubeLinkDialog from './YouTubeLinkDialog'
import NoTagsConfirmationDialog from '../ui/NoTagsConfirmDialog'

const CreateAlbumDialog = ({
  open = false,
  presetTargets,
  trigger,
  onFinishedUploading,
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)

  const handleOnFinishedUploading = () => {
    handleCloseModal()
    if (onFinishedUploading) {
      onFinishedUploading()
    }
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullWidth
        scroll="paper"
      >
        <CreateAlbum
          onClose={handleCloseModal}
          presetTargets={presetTargets}
          onFinishedUploading={handleOnFinishedUploading}
        />
      </Dialog>
    </>
  )
}

const UploadCounter = ({ existing, uploadedFiles }) => {
  return (
    <>
      {uploadedFiles > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', my: 0.5 }}>
          <PhotoLibraryIcon sx={{ mr: 1 }} />
          <Typography>{uploadedFiles} media to upload</Typography>
        </Box>
      )}
      {existing > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', my: 0.5 }}>
          <PhotoLibraryIcon sx={{ mr: 1 }} />
          <Typography>{existing} selected from library</Typography>
        </Box>
      )}
    </>
  )
}

const CreateAlbum = ({ onFinishedUploading, presetTargets = [], onClose }) => {
  const { uploadMedia, uploading } = useUploadMedia()

  const [previewTiles, setPreviewTiles] = useState([])
  const [existing, setExisting] = useState([])
  const [targets, setTargets] = useState(new Set(presetTargets))
  const handleChangeTags = tags => {
    setTargets(new Set(tags))
  }

  const noTagsDialogRef = useRef()

  const handleSubmit = async ({ albumTitle, allowNoTags = false }) => {
    if (!allowNoTags && targets.size === 0) {
      noTagsDialogRef.current.showDialog(async () => {
        //call this function again but with allowNoTags=true
        handleSubmit({ albumTitle, allowNoTags: true })
      })

      return
    } else {
      try {
        await uploadMedia({
          albumTitle,
          existingMedia: existing,
          previewTiles,
          targets,
        })
        if (onFinishedUploading) {
          onFinishedUploading()
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const addYouTubeVideo = async youtubeVideoPreview => {
    setPreviewTiles([...previewTiles, youtubeVideoPreview])
  }

  const handleSelectExisting = selected => {
    setExisting(selected)
  }

  const initialValues = {
    albumTitle: '',
  }

  const tabs = [
    {
      label: 'Upload New',
      component: (
        <Box sx={{ m: 3, mt: 0 }}>
          <UploadAlbum {...{ previewTiles, setPreviewTiles }} />
        </Box>
      ),
    },
    {
      label: 'From Library',
      component: (
        <Box
          sx={{
            m: 3,
            mt: 0,
            mr: 2.5,
            display: 'flex',
            overflow: 'auto',
            flexGrow: 1,
          }}
        >
          <ThumbnailGallery
            onSelect={handleSelectExisting}
            cropAfterSelect={false}
            open
            multiple
            showFilterInPopover
          />
        </Box>
      ),
    },
    {
      label: (
        <YouTubeLinkDialog
          open={false}
          onAddURL={addYouTubeVideo}
          trigger={props => (
            <Typography onClick={props.onClick}>Add YouTube Video</Typography>
          )}
        />
      ),
      component: <Box sx={{ m: 3, mt: 0 }}></Box>,
      onClick: e => {
        e.preventDefault()
      },
    },
  ]

  return (
    <>
      <NoTagsConfirmationDialog
        ref={noTagsDialogRef}
        proceedWithoutTagsText="Create album without tags"
      />
      <AlbumModalLayout
        onClose={onClose}
        content={<TabLayout sx={{ mt: 2 }} tabs={tabs} />}
        sidePanel={
          <AlbumForm
            {...{
              action: (
                <UploadCounter
                  existing={existing.length}
                  uploadedFiles={previewTiles.length}
                />
              ),
              existing,
              handleChangeTags,
              formTitle: 'Create Album',
              onSubmit: handleSubmit,
              initialValues,
              presetTargets,
              previewTiles,
              setPreviewTiles,
              selectedTargets: targets,
              uploading,
            }}
          />
        }
      />
    </>
  )
}

export default CreateAlbumDialog
