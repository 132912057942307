import { Box } from '@mui/system'
import { makeStyles } from '@mui/styles'
import { Stack, Typography } from '@mui/material'
import clsx from 'clsx'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import SelectMedia from 'src/modules/photo/SelectMedia'
import { useHover } from 'src/modules/app/hooks'

import {
  HorizontalAddElementControl,
  VerticalAddElementControl,
  VerticalAddElementControlBefore,
} from './AddElementControl'
import EditablePhoto from './EditablePhoto'
import SiteWideIcon from '../ui/SiteWideIcon'
import { INSTANCE_TYPE_DOCUMENT } from '../app/links'

export const CONTROL_POSITION_LEFT_RIGHT = 'left-right'
export const CONTROL_POSITION_TOP_BOTTOM = 'top-bottom'

const BASE_Z_INDEX = 0
const CONTROLS_Z_INDEX = BASE_Z_INDEX + 101
const DRAG_Z_INDEX = CONTROLS_Z_INDEX + 1

const usePhotoStyles = makeStyles(theme => ({
  leftRight: {
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
  },
  photo: {
    height: 'auto',
    width: '100%',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  photoColumn: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  editablePhoto: {
    marginRight: 0,
    position: 'relative',
    '& img': {
      height: '100%',
    },
  },
}))

const ADD_IMAGE_TEXT = 'Add image'

export const DraggablePhotoWithFloatingControls = ({
  contentId,
  contentBlockId,
  handleSelectPhoto,
  height,
  id,
  photo,
  controlPosition = CONTROL_POSITION_TOP_BOTTOM,
  handleLoaded,
  presetTargets,
  mediaColumnSize,
  columnWidth,
  isCarousel,
  imageClassName,
  addMediaHideTags = false,
  addMediaDefaultAllowNoTags = false,
  addMediaSetCategory,
  photoButtons = {},
  placeholderStyles,
}) => {
  const classes = usePhotoStyles()

  const [hoverRef, isHovered] = useHover()

  const orderAbove = photo.order
  const orderBelow = photo.order + 1

  const isTopBottom = controlPosition === CONTROL_POSITION_TOP_BOTTOM
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? DRAG_Z_INDEX : BASE_Z_INDEX,
  }

  return (
    <Stack
      className={clsx({
        [classes.leftRight]: !isTopBottom,
        [classes.photoColumn]: isTopBottom,
      })}
      ref={hoverRef}
      direction={isTopBottom ? 'column' : 'row'}
    >
      <AddMedia
        handleSelect={photo => handleSelectPhoto(photo, orderAbove)}
        show={isHovered}
        presetTargets={presetTargets}
        AddComponent={
          isTopBottom
            ? HorizontalAddElementControl
            : VerticalAddElementControlBefore
        }
        hideTags={addMediaHideTags}
        defaultAllowNoTags={addMediaDefaultAllowNoTags}
      />
      <div ref={setNodeRef} style={style}>
        {/* media comes from a template and has not had an image set yet - the provided image should be faded out and clicking it should show the selector to set the image */}
        {photo.templatePlaceholderMocbId ? (
          <AddMediaIcon
            handleSelect={newPhoto => {
              const newPhotoWithMocbId = {
                templatePlaceholderMocbId: photo.templatePlaceholderMocbId,
                ...newPhoto,
              }
              handleSelectPhoto(newPhotoWithMocbId)
            }}
            height={height}
            presetTargets={presetTargets}
            width={(photo.width / photo.height) * height} // width needed because CSS background-image does not push the div out to fit the image
            useImageIcon={true}
            label={
              photo.caption && photo.caption.length
                ? photo.caption
                : 'Click to set image'
            }
            placeholderImageUrl={photo.fileMedium}
            placeholderStyles={placeholderStyles}
          />
        ) : (
          <EditablePhoto
            className={classes.editablePhoto}
            contentId={contentId}
            contentBlockId={contentBlockId}
            height={height}
            ImageProps={{ ...attributes, ...listeners }}
            imageClassName={(classes.photo, imageClassName)}
            photo={photo}
            handleLoaded={handleLoaded}
            mediaColumnSize={mediaColumnSize}
            columnWidth={columnWidth}
            isCarousel={isCarousel} //if false the caption will be shown below the photo
            buttons={photoButtons}
          />
        )}
      </div>
      <AddMedia
        bottom={isTopBottom}
        handleSelect={photo => handleSelectPhoto(photo, orderBelow)}
        show={isHovered}
        presetTargets={presetTargets}
        AddComponent={
          isTopBottom ? HorizontalAddElementControl : VerticalAddElementControl
        }
        hideTags={addMediaHideTags}
        setCategory={addMediaSetCategory}
      />
    </Stack>
  )
}

export const AddMedia = ({
  bottom,
  handleSelect,
  show,
  AddComponent = HorizontalAddElementControl,
  presetTargets,
  hideTags = false,
  setCategory,
}) => {
  return (
    <SelectMedia
      onSelect={handleSelect}
      cropAfterSelect={false}
      presetTargets={presetTargets}
      hideTags={hideTags}
      setCategory={setCategory}
      trigger={props => (
        <Box
          sx={{
            position: 'relative',
            display: show ? 'block' : 'none',
            zIndex: CONTROLS_Z_INDEX,
          }}
        >
          <AddComponent {...props} extraTravel={false} bottom={bottom} show />
        </Box>
      )}
    />
  )
}

const useSelectMediaStyles = makeStyles(theme => ({
  /* used if a placeholder image from a template is passed, fade it out so the CTA text on top can be read */
  fadeBackgroundImage: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
    backgroundBlendMode: 'lighten',
  },
}))

export const AddMediaIcon = ({
  handleSelect,
  width,
  height,
  presetTargets,
  hideTags = false,
  defaultAllowNoTags = false,
  label = ADD_IMAGE_TEXT,
  setCategory,
  useImageIcon = null,
  placeholderImageUrl,
  placeholderStyles,
  mediaType,
  cropAfterSelect = false,
  onSelectCropped,
}) => {
  const classes = useSelectMediaStyles()

  return (
    <SelectMedia
      onSelect={handleSelect}
      onSelectCropped={onSelectCropped}
      cropAfterSelect={cropAfterSelect}
      presetTargets={presetTargets}
      hideTags={hideTags}
      defaultAllowNoTags={defaultAllowNoTags}
      setCategory={setCategory}
      mediaType={mediaType}
      trigger={props => (
        <Box
          sx={{
            width,
            height,
            flexDirection: 'column',
            backgroundImage: `url(${
              placeholderImageUrl ?? '/image-default-3.svg'
            })`,
            backgroundSize: 'cover',
            cursor: 'pointer',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            ...placeholderStyles,
          }}
          className={!!placeholderImageUrl ? classes.fadeBackgroundImage : null}
          {...props}
        >
          {label === ADD_IMAGE_TEXT || useImageIcon ? (
            <img src={'/icon-photo-2.svg'} alt="Select media" />
          ) : (
            <SiteWideIcon
              color="primary"
              instanceType={INSTANCE_TYPE_DOCUMENT}
              fontSize={'large'}
            />
          )}
          <Typography variant="subtitle5" px={4} mt={1} textAlign="center">
            {label}
          </Typography>
        </Box>
      )}
    />
  )
}
