import React, { useCallback, useEffect, useState } from 'react'
import { Button, LoadingIndicator, Typography } from '../ui'
import { Box, Card, CardContent, styled } from '@mui/material'
import {
  useActionDispatcher,
  useGetSubscription,
  useIsFreeTrial,
} from './hooks'
import { fetchCheckout } from './appSlice'
import parse from 'html-react-parser'
import { ACTION_ALL_ACCESS } from './appConstants'

const WideContainer = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.headerHeight,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 2),
    width: 'fit-content',
    maxWidth: '100vw',
  },
}))

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}))

export const currencyMap = {
  usd: '$',
  gbp: '£',
  eur: '€',
  nzd: 'NZ$',
  aud: 'A$',
  zar: 'R',
  cad: 'C$',
}

const PricingCard = ({
  title,
  description = '',
  quotas,
  products,
  currency,
  subscription,
  isFreeTrial,
}) => {
  const dispatchFetchCheckout = useActionDispatcher(fetchCheckout)

  const gbStorage = quotas?.find(quote => quote?.key === 'STORAGE_USAGE')?.limit
  let displayStorage = `${gbStorage}GB`

  if (gbStorage >= 1000) {
    displayStorage = `up to ${gbStorage / 1000}TB (${displayStorage})`
  } else if (gbStorage < 0) {
    displayStorage = 'unlimited'
  } else if (gbStorage < 1) {
    displayStorage = `up to ${gbStorage * 1000}MB`
  } else {
    displayStorage = `up to ${displayStorage}`
  }

  const monthlyProduct = products?.find(product => {
    return product?.prices?.some(price => price?.interval === 'month')
  })
  let monthlyPrice = monthlyProduct?.prices?.find(
    prod => prod?.currency?.toLowerCase() === currency
  )
  if (!monthlyPrice) {
    monthlyPrice = monthlyProduct?.prices?.find(
      prod => prod?.currency?.toLowerCase() === 'usd'
    )
  }

  const yearlyProduct = products?.find(product => {
    return product?.prices?.some(price => price?.interval === 'year')
  })
  let yearlyPrice = yearlyProduct?.prices?.find(
    prod => prod?.currency?.toLowerCase() === currency
  )
  if (!yearlyPrice) {
    yearlyPrice = yearlyProduct?.prices?.find(
      prod => prod?.currency?.toLowerCase() === 'usd'
    )
  }

  const handleClick = async price => {
    if (price?.id) {
      const checkout = await dispatchFetchCheckout({ priceId: price?.id })
      const redirectUrl = checkout?.payload?.redirectUrl

      window.location = redirectUrl
    }
  }

  return (
    <Card
      raised
      sx={{
        borderRadius: 10,
        border: monthlyPrice ? '2px solid #240048' : 'none',
        textAlign: 'center',
        height: 'fit-content',
        flex: 1,
        minWidth: { md: 300, sm: '40%', xs: '90%' },
        maxWidth: { md: 400, sm: '46%', xs: '90%' },
        p: 2,
        opacity: !monthlyPrice && 0.8,
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          color="primary"
          textAlign="center"
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={0.5}
        >
          <Typography
            variant="h3"
            component="h3"
            textAlign="center"
            fontWeight="bold"
          >
            {monthlyPrice
              ? `${currencyMap[currency] || '$'}${
                  monthlyPrice?.unitAmount / 100
                }`
              : 'Free'}
          </Typography>
          {monthlyPrice && <span>pcm</span>}
        </Box>
        {yearlyPrice && (
          <Typography variant="subtitle1" component="h4" textAlign="center">
            {currencyMap[currency] || '$'}
            {yearlyPrice?.unitAmount / 100} pa
          </Typography>
        )}

        {gbStorage && displayStorage && (
          <Typography mb={1} textAlign="center">
            {displayStorage}
          </Typography>
        )}
        {(!subscription || isFreeTrial) &&
          subscription?.priceId !== monthlyPrice?.id && (
            <Box display="flex" gap={2} mb={3} justifyContent="center">
              {monthlyPrice && (
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={dispatchFetchCheckout.status === 'loading'}
                  onClick={() => handleClick(monthlyPrice)}
                  sx={{ mt: 2, whiteSpace: 'nowrap' }}
                >
                  Pay monthly
                </Button>
              )}
              {yearlyPrice && (
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={dispatchFetchCheckout.status === 'loading'}
                  onClick={() => handleClick(yearlyPrice)}
                  sx={{ mt: 2, whiteSpace: 'nowrap' }}
                >
                  Pay yearly
                </Button>
              )}
            </Box>
          )}

        {subscription?.priceId === monthlyPrice?.id && (
          <Typography variant="h4" color="primary" mb={2}>
            Your Current Plan
          </Typography>
        )}
        {description && (
          <Typography variant="body1" component="p" textAlign="left">
            {parse(description, {})}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default function Pricing() {
  const [pricingFeatures, setPricingFeatures] = useState([])
  const subscription = useGetSubscription()
  const isFreeTrial = useIsFreeTrial()

  const getPricingFeatures = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/payments/featuresets/`
    )
    const data = await response.json()

    const sortedData = data.sort((item1, item2) => {
      const yearlyProduct1 = item1?.products?.find(
        product => product?.prices[0]?.interval === 'year'
      )
      const yearlyGBP1 = yearlyProduct1?.prices?.find(
        price => price?.currency === 'gbp'
      )?.unitAmount

      const yearlyProduct2 = item2?.products?.find(
        product => product?.prices[0]?.interval === 'year'
      )
      const yearlyGBP2 = yearlyProduct2?.prices?.find(
        price => price?.currency === 'gbp'
      )?.unitAmount

      if (!yearlyGBP1) {
        return -1
      }
      if (yearlyGBP1 < yearlyGBP2) {
        return -1
      }
      if (yearlyGBP1 > yearlyGBP2) {
        return 1
      }
      return 0
    })

    setPricingFeatures(sortedData)
  }

  const [currency, setCurrency] = useState('')

  const getUserCurrency = useCallback(async () => {
    if (subscription && subscription?.priceCurrency) {
      setCurrency(subscription?.priceCurrency)
    } else {
      try {
        const response = await fetch(
          'https://ssl.geoplugin.net/json.gp?k=5412b3c1581c5252'
        )
        const data = await response.json()
        const currencyCode = data?.geoplugin_currencyCode?.toLowerCase()

        if (currencyCode) {
          setCurrency(currencyCode)
        } else {
          setCurrency('usd')
        }
      } catch (err) {
        console.log(err)
        alert(
          'We cannot detect your location to offer your local currency. Please try a different browser or proceed with USD.'
        )
        setCurrency('usd')
      }
    }
  }, [subscription])

  useEffect(() => {
    if (!currency) {
      getUserCurrency()
    }
  }, [currency, getUserCurrency])

  useEffect(() => {
    if (!pricingFeatures?.length) {
      getPricingFeatures()
    }
  }, [pricingFeatures?.length])

  return (
    <WideContainer>
      <Typography
        textAlign="center"
        variant="h3"
        color="textPrimary"
        sx={{ mb: 4 }}
      >
        Pricing (30 day free family archive trial with all packages)
      </Typography>
      {pricingFeatures?.length && currency ? (
        <CardContainer>
          {pricingFeatures?.map((set, index) => (
            <PricingCard
              {...set}
              key={index}
              currency={currency}
              subscription={subscription}
              isFreeTrial={isFreeTrial}
            />
          ))}
        </CardContainer>
      ) : (
        <LoadingIndicator />
      )}
      <Typography variant="body2" mt={4} mb={2}>
        *Note that you and your invited family users do not lose access in the
        event of payment cancellation or failure. Certain features (content
        creation and editing, premium features) will be restricted, but can be
        re-activated at any time.
      </Typography>
    </WideContainer>
  )
}
