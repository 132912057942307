import { IconButton, Tooltip, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export default function VisibilityInfo() {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const tooltipText = (
    <>
      <p style={{ fontSize: 14 }}>
        We Are[…] family websites are publicly accessible by default, excluding
        tree entries for all those still living. This allows your research and
        commentary to be easily shared and discussed with non - family members
        such as fellow genealogists with overlapping interests, your friends in
        general and also, potentially as yet unknown, members of your family.
      </p>
      <p style={{ fontSize: 14 }}>
        You can change this setting and restrict access to just invited members
        of your family at any time in the 'My Archives' page. Note that in any
        case, specific items such as photos and articles can be individually
        restricted giving you granular control.
      </p>
    </>
  )
  return (
    <Tooltip
      title={tooltipText}
      placement={mobileBreakpoint ? 'top' : 'right'}
      arrow
      open={tooltipOpen}
    >
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onMouseLeave={() => setTooltipOpen(false)}
        onMouseOver={() => setTooltipOpen(true)}
      >
        <InfoOutlinedIcon ml={1} color="primary" />
      </IconButton>
    </Tooltip>
  )
}
