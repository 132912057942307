import {
  Box,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BLOG_TREE } from '../common/constants'
import { selectRootTrees } from '../auth/authSlice'
import { generateTreeLink, useTreeLink } from '../app/links'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { useHistory } from 'react-router-dom'
import { DialogTitle } from './index'
import { clearPhotoOptionsResults } from '../photo/photoSlice'

const AddTreeBlogPostContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  margin: 'auto',
  maxWidth: '960px',
  padding: theme.spacing(1),
}))

const AddTreeBlogPostBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))

const AddTreeBlogPostDialog = ({ trigger, treeSlug }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()
  const path = 'write-article'
  let writeLink = useTreeLink(path)
  let rootTrees = useSelector(selectRootTrees)

  rootTrees = rootTrees?.filter(t => t.treeType !== BLOG_TREE)

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleShowModal = () => {
    setModalOpen(true)
  }

  const handlePostToTree = targetTreeSlug => {
    writeLink = generateTreeLink(targetTreeSlug, path)
    const params = {
      pathname: writeLink,
      state: {
        alsoPublishToTreeSlug: treeSlug,
        visibility: VISIBILITY_PUBLIC,
      },
    }
    dispatch(clearPhotoOptionsResults())
    history.push(params)
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <AddTreeBlogPostContainer>
          <DialogTitle onClose={handleCloseModal}>
            <span>Add Blog Post</span>
          </DialogTitle>
          <AddTreeBlogPostBody>
            <FormControl fullWidth>
              <InputLabel id="tree-selection-label">Pick a tree</InputLabel>
              <Select
                labelId={'tree-selection-label'}
                onChange={event => {
                  handlePostToTree(event.target.value)
                }}
                label={'Pick a tree'}
              >
                {rootTrees.map(tree => (
                  <MenuItem key={tree.slug} value={tree.slug}>
                    {tree.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AddTreeBlogPostBody>
        </AddTreeBlogPostContainer>
      </Dialog>
    </>
  )
}

export default AddTreeBlogPostDialog
