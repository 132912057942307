import { Box, CircularProgress } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import { useActionDispatcher } from 'src/modules/app'
import ArticleTags from 'src/modules/content/ArticleTags'
import ContentHeaderMeta from 'src/modules/content/ContentHeaderMeta'
import { Container, PageTitle, Typography } from 'src/modules/ui'

import {
  fetchPublicContent,
  selectPublicContent,
} from 'src/modules/public/content/contentSlice'
import { PublicContext } from '../contexts'
import HtmlHeader from '../../app/HtmlHeader'
import { usePrerender } from '../hooks'
import { formatIndividualName } from '../../ui/individualUtils'
import { ShareButton } from '../../visibility/InstanceVisibilityControl'
import { useShare } from '../../app'
import PhotoSlideshow from '../../photo/PhotoSlideshow'
import { getQueryParams } from '../../../utils'
import { Button } from '../../ui'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'
import { useFullScreen } from '../../photo/hooks'
import MediaList from '../../photo/MediaList'
import {
  fetchPublicContentBlockMedia,
  selectPublicContentBlockMedia,
} from './photoSlice'

const PublicAlbumPage = () => {
  const album = useSelector(selectPublicContent)
  const { id } = useParams()
  const location = useLocation()
  const { slideshow } = getQueryParams(location)
  const dispatchFetchAlbum = useActionDispatcher(fetchPublicContent)
  const { treeSlug } = useContext(PublicContext)
  const albumMedia = useSelector(selectPublicContentBlockMedia)
  const handleShare = useShare(true)
  const slideshowContainerRef = useRef(null)
  const [slideshowOpen, setSlideshowOpen] = useState(slideshow?.open)
  const enterFullScreen = useFullScreen(slideshowContainerRef)
  const dispatchPublicFetchContentBlockMedia = useActionDispatcher(
    fetchPublicContentBlockMedia
  )

  const handleOpenSlideshow = () => {
    enterFullScreen()
    setSlideshowOpen(true)
  }

  usePrerender(album !== undefined)

  useEffect(() => {
    const allMedia = slideshow?.open === true ? true : false

    dispatchFetchAlbum(
      { id, treeSlug, allMedia },
      {
        errorNotification: false,
      }
    )
  }, [dispatchFetchAlbum, id, treeSlug, slideshow?.open])

  const slideshowOptions = {
    ...slideshow,
    open: slideshowOpen,
  }

  const headerProps = {
    title: album?.title,
    type: 'article',
    image: albumMedia[0]?.fileThumnail || albumMedia[0]?.fileMedium,
    url: window.location.href,
    published_time: album?.publishedAt,
    modified_time: album?.modified,
    author: formatIndividualName(album?.author),
  }

  useEffect(() => {
    if (album?.albumContentBlockId && album?.id === id) {
      dispatchPublicFetchContentBlockMedia({
        id: album?.albumContentBlockId,
        page: 0,
        treeSlug,
      })
    }
  }, [dispatchPublicFetchContentBlockMedia, album, treeSlug, id])

  const onFetchMore = () => {
    if (
      dispatchPublicFetchContentBlockMedia.status === 'loading' ||
      !album?.albumContentBlockId
    ) {
      return
    }
    dispatchPublicFetchContentBlockMedia({
      id: album.albumContentBlockId,
      page: albumMedia.page + 1,
      treeSlug,
    })
  }

  const refreshMedia = () => {
    if (album?.albumContentBlockId) {
      dispatchPublicFetchContentBlockMedia({
        id: album.albumContentBlockId,
        page: 0,
        treeSlug,
      })
    }
  }

  return (
    <>
      <HtmlHeader {...headerProps} />
      <Container>
        {dispatchFetchAlbum.status === 'error' ? (
          <Typography>Error fetching album</Typography>
        ) : dispatchFetchAlbum.status === 'loading' ? (
          <Typography>Loading album...</Typography>
        ) : album ? (
          <>
            <PhotoSlideshow
              ref={slideshowContainerRef}
              media={album?.media}
              options={slideshowOptions}
              onClose={() => setSlideshowOpen(false)}
            />
            <PageTitle title={album.title} />
            <ContentHeaderMeta
              {...{ ...album, visibility: null }}
              linkToComments={false}
              actions={
                <>
                  <Box sx={{ ml: 1 }}>
                    <ShareButton onClickShare={handleShare} isPublic={true} />
                  </Box>
                  {slideshow?.open && (
                    <Button
                      onClick={handleOpenSlideshow}
                      permissionAction={ACTION_ALL_ACCESS}
                      sx={{ ml: 1 }}
                    >
                      View Slideshow
                    </Button>
                  )}
                </>
              }
            />
            {album.links.length > 0 && <ArticleTags links={album.links} />}
            {albumMedia?.results && album?.id === id && (
              <Box sx={{ mt: 5 }}>
                {albumMedia?.results ? (
                  <MediaList
                    onFetchMore={onFetchMore}
                    media={albumMedia}
                    status={dispatchPublicFetchContentBlockMedia.status}
                    refreshMedia={refreshMedia}
                  />
                ) : (
                  <CircularProgress />
                )}
              </Box>
            )}
          </>
        ) : null}
      </Container>
    </>
  )
}

export default PublicAlbumPage
