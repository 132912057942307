import { Box } from '@mui/material'
import React from 'react'
import {
  CentredRowWithHorizontalLine,
  CentredRowWithVerticalLine,
} from './AddElementControl'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

export default function ResizePhotoGroupControl({
  onMouseDown,
  width,
  height,
  show,
  alignment,
}) {
  return (
    <Box
      sx={{
        width: width || '100%',
        height: height,
        opacity: show ? 1 : 0,
      }}
    >
      {alignment === 'vertical' ? (
        <CentredRowWithVerticalLine
          style={{ cursor: 'col-resize' }}
          onMouseDown={onMouseDown}
        >
          <Box
            sx={{
              display: 'flex',
              flexDireciton: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              transform: 'rotate(90deg)',
            }}
          >
            <UnfoldMoreIcon fontSize="large" />
          </Box>
        </CentredRowWithVerticalLine>
      ) : (
        <CentredRowWithHorizontalLine
          style={{ cursor: 'row-resize' }}
          onMouseDown={onMouseDown}
        >
          <Box
            sx={{
              display: 'flex',
              flexDireciton: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UnfoldMoreIcon fontSize="large" />
          </Box>
        </CentredRowWithHorizontalLine>
      )}
    </Box>
  )
}
