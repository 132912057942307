import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useField } from 'formik'

const useStyles = makeStyles(theme => ({
  label: {
    cursor: 'default',
  },
}))

export const FormikCheckbox = ({
  fullWidth,
  name,
  value,
  label = '',
  hideValidation,
  onChange,
  ...props
}) => {
  const classes = useStyles()
  const [{ onChange: formikOnChange, ...field }, { touched, error }] = useField(
    {
      type: 'checkbox',
      name: name,
      value: value,
    }
  )

  const hasError = Boolean(touched && error)

  const handleChange = event => {
    formikOnChange(event)
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} {...field} {...props} />}
        label={label}
        className={classes.label}
      />
      {hasError && !hideValidation && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default FormikCheckbox
