import React from 'react'
import { useField } from 'formik'
import { FormControlLabel, Checkbox, FormControl } from '@mui/material'

const FormikBooleanField = ({ name, label, disabled }) => {
  // useField() will return field props based on the name attribute
  const [field, meta] = useField({ name, type: 'checkbox' })
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            disabled={disabled}
            checked={field.value}
            error={hasError}
          />
        }
        label={label}
      />
    </FormControl>
  )
}

export default FormikBooleanField
