import { useSelector } from 'react-redux'
import {
  selectIsFullNodeDirectoryIsLoaded,
  selectPartialCall,
} from './viewerSlice'
import { LoadingIndicator } from '../ui'
import React from 'react'
import { Box } from '@mui/material'
import { selectUserHomeIndividual } from '../auth/authSlice'

/*
 * checks if the tree is loaded before displaying children
 * */
const TreeLoadingGuard = ({
  children,
  title,
  mt,
  partialCallKey,
  isLoadingOveride = false,
}) => {
  //so check if the partial load is working if not then add the individual on the tree as a partial load - this should almost always work
  // left in an catch all of the full tree load and loading override.

  const isFullNodeDirectoryLoaded = useSelector(
    selectIsFullNodeDirectoryIsLoaded
  )

  const homeIndividual = useSelector(selectUserHomeIndividual)
  const partialCallLoaded = useSelector(
    selectPartialCall(partialCallKey || homeIndividual?.id)
  )

  const loaded =
    partialCallLoaded || isFullNodeDirectoryLoaded || isLoadingOveride

  return (
    <>
      {!loaded ? (
        <Box mt={mt || 0}>
          <LoadingIndicator title={title || 'Loading Tree'} />
        </Box>
      ) : (
        children
      )}
    </>
  )
}

export default TreeLoadingGuard
