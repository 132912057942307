import { useCallback, useState } from 'react'
import {
  FormControlLabel,
  FormGroup,
  Input,
  Stack,
  Switch,
} from '@mui/material'

import { useTheme } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box } from '@mui/system'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { createOrUpdateGoal, fetchGoals } from './goalsSlice'
import { Button, IconButton, Typography } from 'src/modules/ui'
import { HUMAN_READABLE_TARGETS, MONTHLY_TARGET_TYPES } from './targets'
import { isUndefined } from 'lodash'
import {
  ASSISTANT_HEIGHT_PX,
  ASSISTANT_WIDTH_PX,
  N_GOALS_WRAP,
} from './constants'
import { isEmpty } from 'lodash'
import MessageBox from './MessageBox'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const GoalEditor = ({
  existingGoals,
  onFinishEditing,
  showSetGoalsEncouragement,
}) => {
  // Keep track of the goals that have been edited
  const [editedGoals, setEditedGoals] = useState({})
  const handleGoalChanged = useCallback(
    (targetType, target) =>
      setEditedGoals({ ...editedGoals, [targetType]: target }),
    [editedGoals]
  )

  // Set up editable values for all the possible monthly goals
  const existingTargetsByType = Object.fromEntries(
    existingGoals.map(goal => [goal.targetType, goal.target])
  )
  const editableGoals = MONTHLY_TARGET_TYPES.map(targetType => [
    targetType,
    !isUndefined(editedGoals[targetType])
      ? editedGoals[targetType]
      : existingTargetsByType[targetType] || 0,
  ])

  // Allow user to set or cancel goal reminders.
  const [shouldRemind, setShouldRemind] = useState(
    existingGoals.length === 0 || existingGoals.some(goal => goal.shouldRemind)
  )
  const handleRemindersToggled = useCallback(
    event => {
      setShouldRemind(event.target.checked)
      // When should remind changes, need to change all existing
      // and edited goals
      setEditedGoals({ ...existingTargetsByType, ...editedGoals })
    },
    [editedGoals, existingTargetsByType]
  )

  // Save the goals which have changed
  const dispatchCreateOrUpdateGoal = useActionDispatcher(createOrUpdateGoal)
  const dispatchFetchGoals = useActionDispatcher(fetchGoals)
  const [saving, setSaving] = useState(false)
  const handleSaveGoals = useCallback(async () => {
    if (!isEmpty(editedGoals)) {
      setSaving(true)
      await Promise.all(
        Object.entries(editedGoals).map(([targetType, target]) =>
          dispatchCreateOrUpdateGoal({ targetType, target, shouldRemind })
        )
      )
      await dispatchFetchGoals()
      setSaving(false)
    }
    onFinishEditing()
  }, [
    dispatchCreateOrUpdateGoal,
    dispatchFetchGoals,
    editedGoals,
    onFinishEditing,
    shouldRemind,
  ])

  return (
    <Stack
      direction="column"
      sx={{ width: '100%', minHeight: ASSISTANT_HEIGHT_PX }}
    >
      <Typography variant="h4">
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          onClick={onFinishEditing}
          noBackground={true}
          size="small"
        >
          <ArrowBackIosIcon
            fontSize="small"
            sx={{ position: 'relative', top: -2 }}
          />
        </IconButton>
        Monthly goals
      </Typography>
      {!showSetGoalsEncouragement && (
        <Typography sx={{ mt: 1 }}>
          Set personal goals for each month to give yourself encouragement
        </Typography>
      )}
      {showSetGoalsEncouragement && (
        <MessageBox>
          <Typography>
            Fantastic effort, your site is really coming to life! I'd like to
            help you eat that family history elephant one bite at a time. Set
            yourself some simple monthly goals and I'll send you some friendly
            encouragement once in a while. Before you know it your site will be
            bulging with content to share with your family. Good luck and best
            wishes, Gene
          </Typography>
        </MessageBox>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: 'center',
            flexGrow: 1,
            flexWrap: 'wrap',
            mt: 2,
          }}
        >
          {editableGoals.map(([targetType, target], i) => (
            <EditGoal
              targetType={targetType}
              target={target}
              key={targetType}
              onGoalChanged={handleGoalChanged}
            ></EditGoal>
          ))}
        </Stack>
      </Box>

      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Switch checked={shouldRemind} onChange={handleRemindersToggled} />
          }
          label="Remind me about my goals"
        />
      </FormGroup>

      <Button
        permissionAction={ACTION_ALL_ACCESS}
        isLoading={saving}
        onClick={handleSaveGoals}
      >
        Save monthly goals
      </Button>
    </Stack>
  )
}

const EditGoal = ({ disabled, targetType, target, onGoalChanged }) => {
  const theme = useTheme()

  const handleChange = useCallback(
    event => onGoalChanged(targetType, event.target.value),
    [targetType, onGoalChanged]
  )

  return (
    <Box
      sx={{
        width: ASSISTANT_WIDTH_PX / N_GOALS_WRAP,
        p: 1,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          width: 60,
          height: 60,
          background:
            target > 0 ? theme.palette.info.light : theme.palette.grey[300],
          borderRadius: '50%',
          pt: 1.7,
          pl: 1.9,
          pr: 0.5,
        }}
      >
        <Input
          variant="standard"
          value={target}
          onChange={handleChange}
          type="number"
          disabled={disabled}
          disableUnderline={true}
          inputProps={{ min: 0 }}
          sx={{
            display: 'inline-block',
            color: 'white',
            fontSize: 16,
            fontWeight: 'bold',
            input: {
              textAlign: 'center',
            },
          }}
        />
      </Box>
      <Typography>{HUMAN_READABLE_TARGETS[targetType]}</Typography>
    </Box>
  )
}

export default GoalEditor
