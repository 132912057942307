import { useActionDispatcher } from 'src/modules/app'

import { createSubTree, selectNodes } from './viewerSlice'
import { buildMinimumGraph } from './api/graphOps'
import { useSelector } from 'react-redux'

const CreateSubTree = ({
  trigger,
  selectedIndividualIds,
  onCreated,
  caption = '',
}) => {
  const dispatchCreateSubTree = useActionDispatcher(createSubTree)

  const allIndividuals = useSelector(selectNodes)

  const handleCreateSubTree = async () => {
    const nodeSelection = buildMinimumGraph(
      allIndividuals,
      selectedIndividualIds
    )
    const individualIdsWithSelected = nodeSelection
      .filter(individualNode => !individualNode.id.includes('unknown'))
      .map(individualNode => {
        return {
          nodeId: individualNode.id,
          selected: selectedIndividualIds.has(individualNode.id),
        }
      })
    let subTree
    try {
      subTree = await dispatchCreateSubTree(
        {
          nodes: individualIdsWithSelected,
          caption,
        },
        {
          successNotification: 'Sub tree created.',
        }
      ).unwrap()
    } catch (err) {
      return
    }
    if (onCreated) {
      onCreated(subTree)
    }
  }

  return trigger({ onClick: handleCreateSubTree })
}
export default CreateSubTree
