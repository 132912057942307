import { createSlice } from '@reduxjs/toolkit'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import pickBy from 'lodash/pickBy'
const PHOTO_PAGE_SIZE = 10

const SLICE_NAME = 'photo'

export const fetchPublicMediaDetail = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicMediaDetail`,
  ({ mediaId, treeSlug }) => {
    return api.get(`/public/${treeSlug}/media/${mediaId}/`)
  }
)

export const fetchPublicContentBlockMedia = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicContentBlockMedia`,
  ({ id, page, treeSlug = 0 }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit
    const queryStringParameters = pickBy({
      offset,
      limit,
    })
    return api.get(`/public/${treeSlug}/content_block/${id}/media/`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  mediaDetail: null,
  contentBlockMedia: {
    page: null,
    count: null,
    results: null,
  },
}

export const photoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    ...handlePaginatedAction(
      fetchPublicContentBlockMedia,
      state => state.contentBlockMedia
    ),
    [fetchPublicMediaDetail.pending]: state => {
      state.mediaDetail = null
    },
    [fetchPublicMediaDetail.fulfilled]: (state, { payload }) => {
      state.mediaDetail = payload
    },
  },
})

export const selectPublicContentBlockMedia = state =>
  state.public.photo.contentBlockMedia

export const selectPublicMediaDetail = state => state.public.photo.mediaDetail

export default photoSlice.reducer
