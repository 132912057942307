import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'

import { selectUser } from 'src/modules/auth/authSlice'
import { Tabs } from 'src/modules/ui'
import { generateTreeLink } from 'src/modules/app/links'

import { generateTreeRoute } from 'src/modules/app/links'
import HtmlHeader from '../app/HtmlHeader'

import {
  NAV_KEY_BLOG_ROLL,
  NAV_KEY_BLOG_SETTINGS,
  NAV_KEY_DRAFT_POSTS,
  NAV_KEY_MEDIA,
  NAV_KEY_POSTS,
} from '../app/links'
import { useEmbed } from '../public/hooks'
import { UserJoyride } from '../app/joyrides'
import { selectUserIsFirstTimeUser } from '../auth/authSlice'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import BlogPosts from './BlogPosts'
import DraftBlogPosts from './DraftBlogPosts'
import BlogSettings from './BlogSettings'
import BlogAboutMe from './BlogAboutMe'
import { useParams } from 'react-router-dom'
import HomePhotoList from './HomePhotoList'

const createHomeRoutesForTree = (treeSlug, showDocuments) => [
  {
    navKey: NAV_KEY_POSTS,
    navLabel: 'Published Posts',
    exact: true,
    path: generateTreeLink(treeSlug, 'blog'),
    route: generateTreeRoute('blog'),
    component: BlogPosts,
  },
  {
    navKey: NAV_KEY_DRAFT_POSTS,
    navLabel: 'Unpublished Posts',
    exact: true,
    path: generateTreeLink(treeSlug, 'blog/unpublished'),
    route: generateTreeRoute('blog/unpublished'),
    component: DraftBlogPosts,
  },
  {
    navKey: NAV_KEY_MEDIA,
    navLabel: 'Media',
    exact: true,
    path: generateTreeLink(treeSlug, 'blog/media'),
    route: generateTreeRoute('blog/media'),
    component: HomePhotoList,
  },

  {
    navKey: NAV_KEY_BLOG_SETTINGS,
    navLabel: 'Blog Settings',
    exact: true,
    path: generateTreeLink(treeSlug, `blog/settings`),
    route: generateTreeRoute('blog/settings'),
    component: BlogSettings,
  },
  {
    navKey: NAV_KEY_BLOG_ROLL,
    navLabel: 'About Me',
    exact: true,
    path: generateTreeLink(treeSlug, `blog/about-me`),
    route: generateTreeRoute('blog/about-me'),
    component: BlogAboutMe,
  },
]

export const Title = ({ titleText, treeSlug, isPublic, fromPublic }) => {
  const embed = useEmbed()

  const embedTitle = (
    <Typography variant="h1" color="primary">
      {titleText ? titleText : 'We are making family history'}
    </Typography>
  )

  const fullScreenTitle = (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1" ml={'auto'}>
        {titleText ? titleText : 'We are making family history'}
      </Typography>
      <Box ml="auto"></Box>
    </Box>
  )

  return <>{embed ? embedTitle : fullScreenTitle}</>
}

const Home = () => {
  const user = useSelector(selectUser)
  const isFirstTimeUser = useSelector(selectUserIsFirstTimeUser)
  let { treeSlug } = useParams()
  const showFeature = useIsAlphaFeatureUser()?.documents
  const routes = useMemo(
    () => createHomeRoutesForTree(treeSlug, showFeature),
    [treeSlug, showFeature]
  )

  const treeName = user?.currentTree?.name

  const title = useMemo(
    () => (
      <Title
        titleText={treeName}
        treeSlug={treeSlug}
        isPublic={user?.currentTree?.allowPublicVisibility}
      />
    ),
    [treeName, treeSlug, user?.currentTree?.allowPublicVisibility]
  )

  const headerProps = {
    title: 'Home',
    type: 'website',
    url: window.location.href,
    description: 'We Are [showcasing your family history like never before]',
  }

  return (
    <>
      <HtmlHeader {...headerProps} />
      <Tabs title={title} routes={routes} fullWidthSubRootContainer={true} />
      <UserJoyride
        joyRideName={'joyride_first_time_user'}
        runCondition={isFirstTimeUser}
      />
    </>
  )
}

export default Home
