import {
  changeDepth,
  getBlockBeforeSelectedBlock,
  getSelectedBlock,
  isListBlock,
} from 'draftjs-utils'

import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease'
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease'

import { MenuIconButton } from './MenuIconButton'

const OPTION_INDENT = 'indent'

export const IndentationOptions = ({ editorState, onChange }) => {
  const currentBlock = getSelectedBlock(editorState)

  const handleIndentOutdent = value => {
    if (value === OPTION_INDENT) {
      adjustDepth(1)
    } else {
      adjustDepth(-1)
    }
  }

  const adjustDepth = adjustment => {
    const newState = changeDepth(editorState, adjustment, 4)
    if (newState) {
      onChange(newState)
    }
  }

  /**
   * Indent is only allowed on bullet and numbered lists, and not
   * on the first item.
   */
  const previousBlock = getBlockBeforeSelectedBlock(editorState)
  const isIndentDisabled =
    !previousBlock ||
    !isListBlock(currentBlock) ||
    previousBlock.get('type') !== currentBlock.get('type') ||
    previousBlock.get('depth') < currentBlock.get('depth')

  const isOutdentDisabled =
    !currentBlock ||
    !isListBlock(currentBlock) ||
    currentBlock.get('depth') <= 0

  return (
    <>
      <MenuIconButton
        disabled={isIndentDisabled}
        onClick={() => handleIndentOutdent(OPTION_INDENT)}
      >
        <FormatIndentIncreaseIcon fontSize="small" />
      </MenuIconButton>
      <MenuIconButton
        disabled={isOutdentDisabled}
        onClick={handleIndentOutdent}
      >
        <FormatIndentDecreaseIcon fontSize="small" />
      </MenuIconButton>
    </>
  )
}
