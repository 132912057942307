import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'

import { useField } from 'formik'

export const FormikRadioGroup = ({
  name,
  optionLabelValues,
  label = '',
  value,
  hideValidation,
  setFieldValue,
  disabled,
}) => {
  const [, { touched, error }] = useField({
    type: 'radio',
    name,
  })

  const hasError = Boolean(touched && error)

  const handleChange = event => {
    setFieldValue(name, event.currentTarget.value)
  }

  return (
    <>
      {hasError && !hideValidation && <FormHelperText>{error}</FormHelperText>}

      <FormControl component="fieldset" error={hasError}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup value={value} onChange={handleChange} row>
          {optionLabelValues.map(([optionLabel, optionValue]) => (
            <FormControlLabel
              value={optionValue}
              control={<Radio />}
              key={`${optionLabel}-${optionValue}`}
              label={optionLabel}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default FormikRadioGroup
