import { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Toolbar } from './Toolbar'

import { SourceLink } from '../../content/SourcesUI'
import { findSourceById } from 'src/modules/content/SourcesCommon'
import { useSelector } from 'react-redux'
import {
  selectArticle,
  selectContentBlockFromArticle,
} from '../../writeArticle/writeArticleSlice'
import { bodyTextStyles } from '../../content/Article'

export const TOOLBAR_STYLE_FLOATING_RIGHT = 'floatingRight'
export const TOOLBAR_STYLE_FLOATING_LEFT = 'floatingLeft'
export const TOOLBAR_STYLE_FIXED = 'fixed'

const floatingToolbarCommonStyles = theme => {
  return {
    position: 'absolute',
    top: theme.spacing(-4),
    background: 'none',
    border: 'none',
  }
}

const useStyles = makeStyles(theme => ({
  editor: {
    fontFamily: "'IBM Plex Sans', sans-serif",
    overflow: 'hidden',
    '& .public-DraftStyleDefault-block': {
      margin: '0 0 1rem',
    },
    marginBottom: '-1rem',
  },
  floatingRight: {
    right: 108,
    ...floatingToolbarCommonStyles(theme),
  },
  floatingLeft: {
    left: theme.spacing(-1),
    ...floatingToolbarCommonStyles(theme),
  },
  fixed: {
    border: 'none',
    background: 'none',
    padding: 0,
    marginBottom: 0,
  },
  bodyStyle: bodyTextStyles,
}))

/**
 * Link, defined below, is passed editorState as a prop when the RichTextField is first renders, but
 * whilst RichTextField and Toolbar re-render upon any typing into the textbox, the Link does not re-render
 * so retains an out-of-date editorState which is never updated.
 *
 * The 'delete source' functionality is triggered by Link because it is about a particular link and the UI
 * with the delete button is shown when clicking on the Link. Deleting a source means deleting the link to it
 * from the content of the RichTextField (which looks like <a instance-type='source'.../>) - if it performs
 * that operation the out-of-date editorState given to it on the original render then any user typing in the
 * meantime will be removed/undone.
 *
 * RichTextField does re-render on each keypress so its editorState prop DOES update, but experimentation showed
 * that creating a getEditorState() function within RichTextField and passing it to the Link still returns the
 * original, pre-typing editorState when called from within Link. Perhaps something about draft.js's customDecorators
 * implementation memoizes the function?
 *
 * So this not particularly pretty or react-like workaround keeps a copy of latestEditorState and a function to retrieve it
 * outside of the components, just as a global, static property of RichTextField.js. When passed to Link and called it
 * always returns an up-to-date editorState value.
 *
 * Feel free to tidy up!
 */
var latestEditorState

const getLatestEditorState = () => {
  const es = latestEditorState
  // console.debug(`RichTextField.getLatestEditorState(): returning latestEditorState:`, es)
  // console.debug(`RichTextField.getLatestEditorState(): latestEditorState.getCurrentContent blocks:`,es.getCurrentContent()._map._root.entries[0][1]._list._tail.array)
  return es
}

// There is a known bug in the Editor component which causes console warning:
// "Can't call setState on a component that is not yet mounted."
// See PR for details - https://github.com/jpuri/react-draft-wysiwyg/pull/1044
const RichTextField = ({
  onDialogVisible,
  editorState,
  dontTakeFocus,
  showToolbar,
  onEditorStateChange,
  toolbarStyle = TOOLBAR_STYLE_FLOATING_RIGHT,
  toolbarClass,
  isFocused,
  onFocus,
  onBlur,
  contentId,
  contentBlockId,
  outOfDateSources, //TODO why are sources from props out of date?
  editSource, //function to call to open a dialog to edit a source
  bodyStyle,
  dontOverrideRichTextBodyStyle,
  ...props
}) => {
  latestEditorState = editorState
  const classes = useStyles()
  const toolbarClassName = clsx(classes[toolbarStyle], toolbarClass)

  const ref = useRef()
  useEffect(() => {
    if (ref.current && isFocused) {
      ref.current.focusEditor()
    }
  }, [ref, isFocused])

  const handleBlur = useCallback(
    e => {
      // Prevent blurring when toolbar inputs take focus
      if (e.target.nodeName === 'INPUT') {
        return
      }
      onBlur && onBlur(e)
    },
    [onBlur]
  )

  const Link = props => {
    const data = props.contentState.getEntity(props.entityKey).getData()

    const articleState = useSelector(selectArticle)

    //const fetchedSources = useSelector(selectSources)
    const fetchSources = contentBlockId => {
      return selectContentBlockFromArticle(articleState, contentBlockId).sources
    }

    const getSources = contentBlockId => {
      //console.debug(`RichTextField.Link.getSources(): using selector to get sources for contentBlockId '${contentBlockId}':`)
      const sources = fetchSources(contentBlockId)
      //console.debug(`RichTextField.Link.getSources(): used selector to get sources for contentBlockId '${contentBlockId}':`, sources)
      return sources
    }

    if (data && data.instanceType === 'source') {
      const sourceId = data.contentId
      //console.debug("RichTextField.Link: sourceId:", sourceId)
      if (sourceId && sourceId !== undefined && sourceId !== 'undefined') {
        const sources = getSources(contentBlockId)
        //console.debug("RichTextField.Link: outOfDateSources:", outOfDateSources)
        //console.debug("RichTextField.Link: sources:", sources)
        const source = findSourceById(sources, sourceId)
        //console.debug(`RichTextField.Link: editSource '${editSource}'`)
        if (source) {
          //          console.debug(
          //            `RichTextField.Link: returning SourceLink for sourceId '${sourceId}' numberWithinArticle: '${source.numberWithinArticle}':`,
          //            source
          //          )
          return (
            <SourceLink
              onDialogVisible={onDialogVisible}
              contentId={contentId}
              source={source}
              editMode={true}
              editSource={editSource}
              onEditorStateChange={onEditorStateChange}
              getCurrentEditorState={getLatestEditorState}
              entity={props.contentState.getEntity(props.entityKey)}
            />
          )
        } else {
          console.error(
            `RichTextField.Link: sourceId '${sourceId}' not found in contentBlockId '${contentBlockId}'.`
          )
          return (
            <span title={`sourceId: ${sourceId}`}> [source not found] </span>
          )
        }
      } else {
        console.error(
          `RichTextField.Link: draft.js entity with instanceType 'source' has no sourceId set as its contentId.`,
          data
        )
        return <span title={`sourceId: ${sourceId}`}> [source not found] </span>
      }
    } else {
      const { url } = props.contentState.getEntity(props.entityKey).getData()

      return (
        <a
          href={url}
          style={{
            color: '#ff0000',
            textDecoration: 'underline',
          }}
        >
          {props.children}
        </a>
      )
    }
  }

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      )
    }, callback)
  }

  const decorator = {
    strategy: findLinkEntities,
    component: Link,
  }
  const customDecorators = [decorator]

  const normalBlockStyleFn = contentBlock => {
    if (dontOverrideRichTextBodyStyle === true) {
      return
    }
    const textTypesToStyle = [
      'unstyled',
      'blockquote',
      'unordered-list-item',
      'ordered-list-item',
    ]
    const type = contentBlock.getType()
    if (textTypesToStyle.includes(type)) {
      if (bodyStyle) {
        return bodyStyle
      } else {
        return classes.bodyStyle
      }
    }
  }

  return (
    <>
      <Editor
        ref={ref}
        editorClassName={classes.editor}
        toolbarHidden={!isFocused && !showToolbar}
        onFocus={onFocus}
        onBlur={handleBlur}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        stripPastedStyles={true}
        blockStyleFn={normalBlockStyleFn}
        toolbar={{
          options: [],
          inline: {
            // Required to enable keyboard shortcuts but not displayed
            options: ['bold', 'italic', 'underline'],
          },
        }}
        toolbarCustomButtons={[
          <Toolbar
            contentBlockId={contentBlockId}
            editSource={editSource}
            fixed={toolbarStyle === TOOLBAR_STYLE_FIXED}
            disableBlockquote={props.textBlockProps?.layout === 'MEDIA_COLUMN'}
            //other props are passed to Toolbar by code inside Editor
          />,
        ]}
        toolbarClassName={toolbarClassName}
        customDecorators={customDecorators}
        spellCheck={true}
        {...props}
      />
    </>
  )
}

export default RichTextField
