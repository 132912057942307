import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { BLOG_TREE } from '../common/constants'
import { selectRootTrees } from '../auth/authSlice'
import { Button, ConfirmDialog, DialogTitle } from './index'
import { updateArticle } from '../writeArticle/writeArticleSlice'
import { useActionDispatcher } from '../app'
import {
  VISIBILITY_DEFAULT,
  VISIBILITY_PUBLIC,
  VISIBILITY_TREE,
} from '../visibility/visibilityUtils'
import { setVisibility } from '../visibility/visibilitySlice'
import { getErrorNotificationText } from '../visibility/VisibilityControl'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const AddTreeBlogPostContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  margin: 'auto',
  maxWidth: '960px',
  padding: theme.spacing(1),
}))

const AddTreeBlogPostBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}))

const AddArticleToBlogDialog = ({
  trigger,
  contentId,
  article,
  defaultTargets = [],
  treeAllowPublicVisibility,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  let rootTrees = useSelector(selectRootTrees)
  const dispatchUpdateArticle = useActionDispatcher(updateArticle)
  const dispatchSetVisibility = useActionDispatcher(setVisibility)
  const [targetSlugs, setTargetSlugs] = useState(defaultTargets)

  rootTrees = rootTrees?.filter(t => t.treeType === BLOG_TREE)

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleShowModal = () => {
    setModalOpen(true)
  }

  const addToBlog = async () => {
    await dispatchUpdateArticle({
      id: contentId,
      alsoPublishedIn: targetSlugs,
    })
    if (article?.visibility !== VISIBILITY_PUBLIC) {
      await dispatchSetVisibility(
        { instanceId: contentId, visibility: VISIBILITY_PUBLIC },
        {
          errorNotification: getErrorNotificationText,
          successNotification: 'Visibility changed',
        }
      ).unwrap()
    }
    handleCloseModal()
  }

  const handleClick = () => {
    if (
      article.visibility === VISIBILITY_TREE ||
      (!treeAllowPublicVisibility && article.visibility === VISIBILITY_DEFAULT)
    ) {
      setOpenConfirmDialog(true)
    } else {
      addToBlog()
    }
  }

  const handleChange = event => {
    if (targetSlugs?.includes(event.target.value)) {
      const newArr = targetSlugs?.filter(item => item !== event.target.value)
      setTargetSlugs(newArr)
    } else {
      setTargetSlugs(prevState => [...prevState, event.target.value])
    }
  }

  return (
    <div style={{ display: !rootTrees?.length ? 'none' : 'auto' }}>
      <ConfirmDialog
        submitText={'Continue'}
        open={openConfirmDialog}
        onConfirm={addToBlog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <Typography>
          Your article is PRIVATE, adding this page to a blog will make this
          page and its content public. Living individuals will not be shown. ALL
          other content will remain private. Do you want to continue?
        </Typography>
      </ConfirmDialog>
      {trigger({ onClick: handleShowModal })}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <AddTreeBlogPostContainer>
          <DialogTitle onClose={handleCloseModal}>
            <span>Add Blog Post</span>
          </DialogTitle>
          <AddTreeBlogPostBody>
            <FormControl fullWidth sx={{ flexDirection: 'column' }}>
              <FormGroup>
                {rootTrees.map(tree => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={targetSlugs?.includes(tree.slug)}
                        value={tree.slug}
                        onChange={handleChange}
                      />
                    }
                    label={tree.name}
                  />
                ))}
              </FormGroup>

              <Button
                permissionAction={ACTION_ALL_ACCESS}
                sx={{ mt: 1 }}
                onClick={handleClick}
              >
                Confirm
              </Button>
            </FormControl>
          </AddTreeBlogPostBody>
        </AddTreeBlogPostContainer>
      </Dialog>
    </div>
  )
}

export default AddArticleToBlogDialog
