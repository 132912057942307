import React from 'react'
import { styled } from '@mui/material'

import Typography from './Typography'

const StyledForm = styled('form')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  '& .MuiFormControl-marginNormal': {
    marginTop: theme.spacing(1),
  },
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.grey.main}`,
    '&.Mui-focused': {
      borderColor: theme.palette.lightGrey.main,
    },
    '&:hover': {
      borderColor: theme.palette.lightGrey.main,
    },
  },
}))

const StyledInputGroup = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '&:last-child': {
    marginBottom: 0,
  },
}))

const StyledCheckboxGroup = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1.5),
  padding: theme.spacing(0.5, 1.5),
}))

const StyledInputRow = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1.5),
  padding: theme.spacing(0.5, 1.5),
}))

const Form = ({ ...props }) => <StyledForm {...props} />

const InputGroup = ({ children, title }) => (
  <StyledInputGroup>
    {title && (
      <Typography variant="h4" color="primary">
        {title}
      </Typography>
    )}
    {children}
  </StyledInputGroup>
)

const CheckboxGroup = ({ children, title }) => (
  <StyledCheckboxGroup>
    {title && (
      <Typography variant="h4" color="primary">
        {title}
      </Typography>
    )}
    {children}
  </StyledCheckboxGroup>
)

const InputRow = ({ children }) => <StyledInputRow>{children}</StyledInputRow>

Form.CheckboxGroup = CheckboxGroup
Form.InputGroup = InputGroup
Form.InputRow = InputRow

export default Form
