import React from 'react'
import { useParams } from 'react-router-dom'
import { INSTANCE_TYPE_FAMILY } from 'src/modules/app/links'

import LinkedPageList from './LinkedPageList'

const Families = () => {
  const { linkedPageId } = useParams()

  const handleClick = () => {}
  return (
    <LinkedPageList
      canCreateItem={false}
      target={linkedPageId}
      queryAsSubject={true}
      handleClick={handleClick}
      type={INSTANCE_TYPE_FAMILY}
    />
  )
}

export default Families
