import { ArticlePhoto } from 'src/modules/content/ArticlePhoto'
import {
  MEDIA_ROW_HEIGHT_MAP,
  MEDIA_MARGIN_RIGHT,
} from 'src/modules/writeArticle/contentBlockConstants'

import { PrivatePhoto } from '../photo/PublicPhotoGallery'

const PublicArticlePhoto = ({
  photo,
  height,
  mediaRowHeight,
  mediaColumnSize,
  ...props
}) => {
  const { visibility } = photo

  const privatePhoto = {
    height: height
      ? height
      : MEDIA_ROW_HEIGHT_MAP[mediaRowHeight || mediaColumnSize],
    width: height,
    marginRight: MEDIA_MARGIN_RIGHT / 8,
  }

  return (
    <>
      {visibility === 'TREE' ? (
        <PrivatePhoto photo={privatePhoto} />
      ) : (
        <ArticlePhoto
          {...{
            photo,
            mediaRowHeight,
            mediaColumnSize,
            height,
            ...props,
            allowAnnotationViewer: true,
          }}
        />
      )}
    </>
  )
}

export default PublicArticlePhoto
