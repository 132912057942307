import React, { useEffect, useState } from 'react'
import { useActionDispatcher, useNotification } from '../app'
import {
  createFactSource,
  createSourceCitationLink,
  fetchAllSources,
  fetchSourceCitationLinkForSourceId,
} from './pageSlice'
import { useParams } from 'react-router-dom'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { Button, DialogTitle, FormikTextField } from '../ui'
import { Formik } from 'formik'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import { SelectSource } from './SelectSource'
import { useTheme } from '@mui/material/styles'
import AddSourceCitationMediaDialogForm from './AddSourceCitationMediaDialogForm'
const SELECT_SOURCE_MODE = 'SELECT_SOURCE_MODE'
const CREATE_SOURCE_MODE = 'CREATE_SOURCE_MODE'

const EditFactSourceDialog = params => {
  const theme = useTheme()
  const formikRef = React.useRef() // Ref to access Formik instance

  const {
    trigger,
    modalOpen,
    isEditing,
    selectedSource,
    handleShowModal,
    handleCloseModal,
    linkSource,
    createSource,
    handleEditSource,
    selectSource,
    initialValues,
    formDisplayMode,
    setFormDisplayMode,
    resetForm,
    previewTiles,
    setPreviewTiles,
    linkedPageId,
  } = useEditSourceDialogFunctions(params)

  const sourceFieldSx = {
    backgroundColor:
      formDisplayMode === SELECT_SOURCE_MODE
        ? theme.palette.toolbarGrey.main
        : 'transparent',
  }

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} fullWidth>
        <DialogTitle>{isEditing ? 'Edit' : 'Create'} Source</DialogTitle>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={
            isEditing
              ? handleEditSource
              : Boolean(selectedSource)
              ? linkSource
              : createSource
          }
        >
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
            <>
              <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormStack>
                    <Typography>Source Details:</Typography>
                    {formDisplayMode === SELECT_SOURCE_MODE && (
                      <SelectSource
                        formDisplayMode={formDisplayMode}
                        onSelectSource={s => selectSource(s, setFieldValue)}
                      />
                    )}
                    <SelectSourceControls
                      isEditing={isEditing}
                      formDisplayMode={formDisplayMode}
                      setFormDisplayMode={setFormDisplayMode}
                      resetForm={mode => resetForm(mode, formikRef.current)}
                    />
                    <FormikTextField
                      fullWidth
                      sx={sourceFieldSx}
                      label="Title"
                      disabled={
                        Boolean(formDisplayMode === SELECT_SOURCE_MODE) ||
                        isSubmitting
                      }
                      name="title"
                    />
                    <FormikTextField
                      sx={sourceFieldSx}
                      fullWidth
                      label="Author"
                      disabled={
                        Boolean(formDisplayMode === SELECT_SOURCE_MODE) ||
                        isSubmitting
                      }
                      name="author"
                    />
                    <FormikTextField
                      sx={sourceFieldSx}
                      fullWidth
                      multiline
                      label="Notes"
                      disabled={
                        Boolean(formDisplayMode === SELECT_SOURCE_MODE) ||
                        isSubmitting
                      }
                      name="note"
                    />
                  </FormStack>
                  <FormStack>
                    <Typography>Source Citation:</Typography>
                    <FormikTextField
                      fullWidth
                      label="Page"
                      disabled={isSubmitting}
                      name="citation.page"
                    />
                    <FormikGedDatePicker
                      fullWidth
                      label="Date"
                      disabled={isSubmitting}
                      margin="normal"
                      name="citation.date"
                      setFieldValue={setFieldValue}
                    />
                    <FormikTextField
                      fullWidth
                      label="Text"
                      disabled={isSubmitting}
                      name="citation.text"
                    />
                    {initialValues?.citation?.note && (
                      <FormikTextField
                        fullWidth
                        label="Citation Note"
                        disabled={isSubmitting}
                        name="citation.note"
                      />
                    )}
                    <AddSourceCitationMediaDialogForm
                      values={values}
                      linkedPageId={linkedPageId}
                      setFieldValue={setFieldValue}
                      previewTiles={previewTiles}
                      setPreviewTiles={setPreviewTiles}
                    />
                  </FormStack>
                </Box>
              </DialogContent>
              <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={handleCloseModal}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                  disabled={values === initialValues}
                >
                  {isEditing ? 'Save' : 'Add'}
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

const FormStack = ({ children }) => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="stretch"
    my={1.5}
    p={1}
    borderColor="primary"
    border={0.1}
    boxShadow={2}
    borderRadius={1}
  >
    {children}
  </Stack>
)

const SelectSourceControls = ({
  isEditing,
  formDisplayMode,
  setFormDisplayMode,
  resetForm,
}) => {
  const linkStyle = { fontSize: '0.8rem', cursor: 'pointer' }

  return (
    <Box
      sx={{
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        marginBottom: '0.5rem',
        marginTop: '1rem',
      }}
    >
      {formDisplayMode === SELECT_SOURCE_MODE && (
        <Link
          underline="always"
          sx={linkStyle}
          onClick={() => {
            if (isEditing) setFormDisplayMode(CREATE_SOURCE_MODE)
            else resetForm(CREATE_SOURCE_MODE)
          }}
        >
          {isEditing ? 'edit source' : 'create new source'}
        </Link>
      )}
      {formDisplayMode === CREATE_SOURCE_MODE && (
        <Link
          underline="always"
          sx={linkStyle}
          onClick={() => {
            if (isEditing) setFormDisplayMode(SELECT_SOURCE_MODE)
            else resetForm(SELECT_SOURCE_MODE)
          }}
        >
          cancel
        </Link>
      )}
    </Box>
  )
}

const useEditSourceDialogFunctions = ({
  factId,
  source,
  sourceCitation = {},
  sourceObj,
  trigger,
  refreshFacts,
  editSource,
  updateSources,
  linkMediaToSource,
}) => {
  const isEditing = Boolean(source)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedSource, setSelectedSource] = useState('')
  const [previewTiles, setPreviewTiles] = useState([])
  const dispatchCreateFactSource = useActionDispatcher(createFactSource)
  const dispatchFetchAllSources = useActionDispatcher(fetchAllSources)
  const dispatchFetchSourceCitationLinkForSourceId = useActionDispatcher(
    fetchSourceCitationLinkForSourceId
  )
  const dispatchCreateSourceCitationLink = useActionDispatcher(
    createSourceCitationLink
  )

  const { showError, showSuccess } = useNotification()
  const { linkedPageId } = useParams()
  const [formDisplayMode, setFormDisplayMode] = useState(SELECT_SOURCE_MODE)

  const handleShowModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
    setSelectedSource('')
    setFormDisplayMode(SELECT_SOURCE_MODE)
  }

  const updateFacts = () => {
    refreshFacts()
    setFormDisplayMode(SELECT_SOURCE_MODE)
    handleCloseModal()
  }

  const getGedComLines = fields => {
    const attrs = [
      {
        tagName: 'TITL',
        lineValue: !fields.title ? 'untitled' : fields.title,
        childLines: [],
      },
      {
        tagName: 'AUTH',
        lineValue: fields.author,
        childLines: [],
      },
      {
        tagName: 'NOTE',
        lineValue: fields.note,
        childLines: [],
      },
    ]

    return attrs
  }

  const linkSource = async fields => {
    if (factId) {
      await dispatchCreateSourceCitationLink({
        setTargets: [factId],
        source: {
          ...selectedSource,
          sourceCitationDetails: fields?.citation,
        },
        citationMedia: fields?.citationMedia?.map(addedObj => addedObj.id),
      })
    }

    const attrs = getGedComLines(fields)

    const updatedSource = {
      ...selectedSource,
      gedcomLines: attrs,
      citationDetails: fields?.citation,
      title: fields.title,
    }

    updateSources(updatedSource)
    updateFacts()
  }

  const createSource = async fields => {
    const attrs = getGedComLines(fields)

    const newSource = await dispatchCreateFactSource({
      setTargets: factId,
      gedcomLines: attrs,
      citationDetails: fields?.citation,
    })

    const sourceCitation = await dispatchFetchSourceCitationLinkForSourceId({
      sourceId: newSource.payload.id,
    })

    if (newSource.meta.requestStatus === 'fulfilled') {
      let updatedSource = newSource.payload
      if (linkMediaToSource) {
        const mediaOnSource = fields?.citationMedia?.map(
          addedObj => addedObj.id
        )

        await linkMediaToSource(
          mediaOnSource,
          sourceCitation?.payload?.results[0]
        )

        //updatedSource.citationMedia =
      }

      updateSources(updatedSource)

      showSuccess('New source created')
      updateFacts()
    } else {
      showError('Unable to create a new source')
    }
  }

  const handleEditSource = async fields => {
    await editSource(fields)
    updateFacts()
  }

  const selectSource = (source, setFieldValue) => {
    if (source?.id !== selectedSource?.id) {
      setSelectedSource(source)
      setFieldValue('title', source?.title || '')
      setFieldValue('author', source?.author || '')
      setFieldValue('note', source?.note || '')
    } else {
      setSelectedSource(undefined)
      setFieldValue('title', '')
      setFieldValue('author', '')
      setFieldValue('note', '')
    }
  }

  useEffect(() => {
    if (!isEditing) {
      dispatchFetchAllSources({ page: 0 })
    }
  }, [isEditing, dispatchFetchAllSources])

  const initialValues = {
    title: sourceObj.title,
    author: sourceObj.author,
    citation: sourceObj.citation,
    note: sourceObj.note,
    type: sourceObj.type,
    media: source?.media || [],
    citationMedia: sourceCitation?.citationMedia || [],
  }

  const resetForm = (mode, formikActions) => {
    setFormDisplayMode(mode)
    setSelectedSource(undefined)
    formikActions.setFieldValue('title', '')
    formikActions.setFieldValue('author', '')
    formikActions.setFieldValue('note', '')
  }

  return {
    linkedPageId,
    trigger,
    modalOpen,
    isEditing,
    selectedSource,
    previewTiles,
    setPreviewTiles,
    handleShowModal,
    handleCloseModal,
    linkSource,
    createSource,
    handleEditSource,
    selectSource,
    initialValues,
    formDisplayMode,
    setFormDisplayMode,
    resetForm,
  }
}

export default EditFactSourceDialog
