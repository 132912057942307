import { useState } from 'react'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { createMedia, uploadVideoToS3 } from './photoSlice'
import {
  binaryFileTypeMatcher,
  fileTypeMatcher,
  youTubeTypeMatcher,
} from './utils'

export const useFullScreen = elRef => {
  const enterFullScreen = () => {
    if (elRef.current) {
      if (elRef.current.requestFullscreen) {
        elRef.current.requestFullscreen()
      } else if (elRef.current.mozRequestFullScreen) {
        elRef.current.mozRequestFullScreen()
      } else if (elRef.current.webkitRequestFullscreen) {
        elRef.current.webkitRequestFullscreen()
      } else if (elRef.current.msRequestFullscreen) {
        elRef.current.msRequestFullscreen()
      }
    }
  }

  return enterFullScreen
}
export const useUploadMedia = () => {
  const dispatchCreateMedia = useActionDispatcher(createMedia)
  const dispatchUploadVideoToS3 = useActionDispatcher(uploadVideoToS3)

  const [uploadingToS3, setUploadingToS3] = useState(false)

  const uploadMedia = async ({
    existingMedia = [],
    previewTiles = [],
    ...values
  }) => {
    let albumMedia = []
    let albumId = values.albumId

    const photosWithDescription = previewTiles.filter(({ file }) =>
      fileTypeMatcher(file, 'image')
    )

    const videoFiles = previewTiles.filter(({ file }) =>
      fileTypeMatcher(file, 'video')
    )

    //pdfs
    const binaryFilesWithDescription = previewTiles.filter(({ file }) =>
      binaryFileTypeMatcher(file)
    )

    const youTubeIdsWithDescription = previewTiles.filter(({ file }) =>
      youTubeTypeMatcher(file, 'youtube')
    )

    const videoObjectNames = videoFiles.map(({ title }) => title)

    for (const [index, vid] of videoObjectNames.entries()) {
      const videosPayload = {
        existingMedia,
        photosWithDescription: [],
        binaryFilesWithDescription: [],
        youTubeIdsWithDescription: [],
        videoObjectNames: [vid],
        ...values,
        albumId,
      }

      const response = await dispatchCreateMedia(videosPayload, {
        errorNotification: err => {
          if (err.status === 413) {
            return 'Your video upload is a bit on the large side, try uploading smaller videos'
          } else {
            return undefined
          }
        },
      }).unwrap()
      setUploadingToS3(true)
      const videoPromises = response.videos.map((videoResponse, i) => {
        // Front end is contracted to return all video responses in the same
        // order as object names were sent.
        const { file } = videoFiles[i]
        const uploadData = videoResponse.uploadData
        const media = videoResponse.media
        return dispatchUploadVideoToS3({
          media,
          file,
          uploadData,
        }).unwrap()
      })
      await Promise.allSettled(videoPromises)
      setUploadingToS3(false)

      const { album } = response
      if (album) {
        albumId = album.id
        if (index === videoObjectNames.length - 1) {
          albumMedia = album.media
        }
      }
    }

    const uploadOne = async (index, payload, payloadLength) => {
      const response = await dispatchCreateMedia(payload, {
        errorNotification: err => {
          if (err.status === 413) {
            return 'Your upload is a bit on the large side, try uploading smaller files'
          } else {
            return undefined
          }
        },
      }).unwrap()

      const { album } = response
      if (album) {
        albumId = album.id

        if (index === payloadLength - 1) {
          albumMedia = album.media
        }
      }

      return response
    }

    let response = []

    const existingMediaPayload = {
      existingMedia,
      binaryFilesWithDescription: [],
      youTubeIdsWithDescription: [],
      photosWithDescription: [],
      videoObjectNames: [],
      ...values,
      albumId,
    }

    if (existingMedia.length) {
      const { existingMedia } = await uploadOne(0, existingMediaPayload, 1)
      response = [...response, ...existingMedia]
    }

    const photoPayload = photo => {
      const photosWithDescription = photo ? [photo] : []
      let payload = {
        existingMedia: [],
        binaryFilesWithDescription: [],
        youTubeIdsWithDescription: [],
        photosWithDescription,
        videoObjectNames: [],
        ...values,
        albumId,
      }
      return payload
    }

    for (const [index, photo] of photosWithDescription.entries()) {
      const payload = photoPayload(photo)
      const { photos } = await uploadOne(index, payload, 1)
      response = [...response, ...photos]
    }

    const binaryPayload = binaryFile => {
      const binaryFilesWithDescription = binaryFile ? [binaryFile] : []
      let payload = {
        existingMedia: [],
        binaryFilesWithDescription,
        photosWithDescription: [],
        videoObjectNames: [],
        youTubeIdsWithDescription: [],
        ...values,
        albumId,
      }
      return payload
    }

    const youTubePayload = youTubeVideoData => {
      const youTubeIdsWithDescription = youTubeVideoData
        ? [youTubeVideoData]
        : []
      let payload = {
        existingMedia: [],
        binaryFilesWithDescription: [],
        photosWithDescription: [],
        videoObjectNames: [],
        youTubeIdsWithDescription,
        ...values,
        albumId,
      }
      return payload
    }

    for (const [index, file] of binaryFilesWithDescription.entries()) {
      const payload = binaryPayload(file)
      const { binaryFiles } = await uploadOne(index, payload, 1)
      response = [...response, ...binaryFiles]
    }

    for (const [index, file] of youTubeIdsWithDescription.entries()) {
      const payload = youTubePayload(file)
      const { youtubeVideoIds } = await uploadOne(index, payload, 1)
      response = [...response, ...youtubeVideoIds]
    }

    if (albumMedia.length > 0) {
      return albumMedia
    } else {
      return response
    }
  }
  const uploading = dispatchCreateMedia.status === 'loading' || uploadingToS3

  return {
    uploadMedia,
    uploading,
  }
}
