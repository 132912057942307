import React from 'react'
import { styled, Tooltip } from '@mui/material'
import { AddArticleToBlogButton, ArticleEditButton } from './ArticleHeaderMeta'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { ShareButton } from '../visibility/InstanceVisibilityControl'
import { useShare } from '../app'
import { AuthorLink, HeaderComments, PublishedAt } from './ContentHeaderMeta'
import { IconButton } from '../ui'
import PushPinIcon from '@mui/icons-material/PushPin'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { ACTION_EDIT } from '../app/appConstants'
import { INSTANCE_TYPE_ARTICLE } from '../app/links'

const UnPinArticleButton = ({
  id,
  iconSize = 'medium',
  onClickUnPin,
  article = {},
}) => {
  return (
    <>
      <Tooltip title="Unpin">
        <IconButton
          permissionAction={ACTION_EDIT}
          permissionParams={{
            instanceType: INSTANCE_TYPE_ARTICLE,
            instance: article,
          }}
          onClick={onClickUnPin}
          color="primary"
          white
        >
          <PushPinIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>
    </>
  )
}

const ArticleInLineHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const LeftBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  alignItems: 'center',
}))

const RightBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  alignItems: 'center',
}))

const ArticleInLineHeader = ({
  article,
  config,
  handleUnPinArticle,
  onArticleEdit,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const { publishedAt, comments, visibility } = article
  const handleShare = useShare()

  return (
    <ArticleInLineHeaderContainer>
      <LeftBlock>
        {config.showInLineHeaderAuthor && (
          <AuthorLink author={article.author} />
        )}
        {config.showInLineHeaderAuthor && (
          <PublishedAt publishedAt={publishedAt} />
        )}
        {config.showInLineHeaderAuthor && (
          <HeaderComments
            linkToComments={false}
            commentCount={comments.length}
          />
        )}
      </LeftBlock>
      <RightBlock>
        {config.showInLineHeaderShare && (
          <ShareButton
            isPublic={visibility === VISIBILITY_PUBLIC}
            onClickShare={handleShare}
            iconSize={'medium'}
          />
        )}
        {config.showInLineHeaderUnPin && (
          <UnPinArticleButton
            id={article.id}
            article={article}
            onClickUnPin={handleUnPinArticle}
          />
        )}
        {config.showInLineHeaderEdit && (
          <ArticleEditButton
            permissionAction={ACTION_EDIT}
            permissionParams={{
              instance: article,
              instanceType: INSTANCE_TYPE_ARTICLE,
            }}
            onArticleEdit={onArticleEdit}
            treeSlug={treeSlug}
            id={article.id}
            linkToPath={'write-article'}
          />
        )}
        {config.showInLineHeaderAddToBlog && (
          <AddArticleToBlogButton treeSlug={treeSlug} article={article} />
        )}
      </RightBlock>
    </ArticleInLineHeaderContainer>
  )
}

export default ArticleInLineHeader
