import { InputBase } from '@mui/material'
import { styled } from '@mui/styles'

export const ArticleControlsSelect = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    background: 'none',
    border: 'none',
    padding: theme.spacing(1),
    '&:focus': {
      background: 'none',
      border: 'none',
    },
  },
}))
