import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useNotification } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikPasswordField,
  FormikTextField,
  FIELD_REQUIRED,
} from 'src/modules/ui'

import { checkUserAuthState } from './utils'
import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLinks from './FormLinks'
import FormLink from './FormLink'
import { ACTION_LOGIN } from '../app/appConstants'
import { AuthState } from './Authprovider'

// The old password policy is min 8 chars, uppercase and lowercase and number and symbol.
// The current password policy is min 10 chars, uppercase and lowercase.
// As users could have created their password under by either the old or new poicy we must set a
// minimum of the two policies here - min 8 chars uppercase and lowercase.
const minimumPasswordPolicyRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/
const minimumPasswordPolicyDescription =
  'Must contain at least 8 characters, one uppercase and one lowercase'

export const LoginForm = ({
  handleSubmit,
  isSubmitting,
  onClickForgottenPassword,
  onClickGoToSignUpPage,
  values,
  isValid,
  dirty,
}) => {
  return (
    <div>
      <FormHeader
        title="We Are [making our family unforgettable]"
        subtitle="Login"
      />{' '}
      <Form>
        <FormikTextField
          fullWidth
          label="Email"
          margin="normal"
          name="username"
          type="email"
          autoComplete="email"
        />
        <FormikPasswordField
          fullWidth
          label="Password"
          margin="normal"
          name="password"
          autoComplete="current-password"
        />
        <FormLinks>
          <FormLink onClick={onClickForgottenPassword}>
            Forgotten password
          </FormLink>
        </FormLinks>
        <FormActions style={{ alignItems: 'center' }}>
          <FormLink
            onClick={e => {
              onClickGoToSignUpPage({
                email: values.username,
              })
            }}
          >
            Go to signup page
          </FormLink>

          <Button
            permissionAction={ACTION_LOGIN}
            color="primary"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            size="large"
            type="submit"
            disabled={!(isValid && dirty)}
          >
            Login
          </Button>
        </FormActions>
      </Form>
    </div>
  )
}

const Login = ({
  onStateChange,
  onClickForgottenPassword,
  onClickGoToSignUpPage,
  prefillEmail,
  ...props
}) => {
  const { showError } = useNotification()
  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().email().required(FIELD_REQUIRED),
    password: Yup.string()
      .trim()
      .required(FIELD_REQUIRED)
      .matches(minimumPasswordPolicyRegEx, minimumPasswordPolicyDescription),
  })

  const initialValues = {
    username: prefillEmail ?? '',
    password: '',
  }

  const handleSubmit = async ({ username, password }) => {
    try {
      const cleanPassword = password.trim()
      const cleanUsername = username.trim()
      const user = await Auth.signIn(cleanUsername, cleanPassword)
      await checkUserAuthState(user, onStateChange)
    } catch (err) {
      if (err.code === 'NotAuthorizedException') {
        showError(err.message)
      } else if (err.code === 'UserNotFoundException') {
        showError('Incorrect username or password.')
      } else if (err.code === 'PasswordResetRequiredException') {
        showError(
          'Password reset required, starting "Forgotten password" process...'
        )
        onStateChange(AuthState.ForgotPassword, {
          email: username.trim(),
          reset: true,
        })
      } else {
        showError()
      }
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {formik => (
        <LoginForm
          {...formik}
          {...props}
          onClickForgottenPassword={() =>
            onClickForgottenPassword({
              email: formik.values.username,
            })
          }
          onClickGoToSignUpPage={onClickGoToSignUpPage}
        />
      )}
    </Formik>
  )
}

export default Login
