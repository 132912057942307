import { getSelectedBlock } from 'draftjs-utils'
import { RichUtils } from 'draft-js'

import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'

import { Divider, ToggleButton } from '@mui/material'
import { InlineFormatOptions } from './InlineFormatOptions'
import { EditorToggleButtonGroup } from './EditorToggleButtonGroup'
import { BlockTypeDropdown } from './BlockTypeDropdown'

const INLINE_STYLE_BOLD = 'BOLD'
const INLINE_STYLE_ITALIC = 'ITALIC'
const INLINE_STYLE_UNDERLINED = 'UNDERLINE'

const BLOCK_STYLE_UNORDERED = 'unordered-list-item'
const BLOCK_STYLE_ORDERED = 'ordered-list-item'

export const StandardOptions = ({
  editorState,
  onChange,
  disableBlockquote,
}) => {
  const currentBlock = getSelectedBlock(editorState)
  let listType = currentBlock && currentBlock.get('type')
  if (![BLOCK_STYLE_ORDERED, BLOCK_STYLE_UNORDERED].includes(listType)) {
    listType = null
  }

  const toggleBlockType = (e, blockType) => {
    if (!blockType) {
      blockType = 'unstyled'
    }
    const newState = RichUtils.toggleBlockType(editorState, blockType)
    if (newState) {
      onChange(newState)
    }
  }

  return (
    <>
      <BlockTypeDropdown
        editorState={editorState}
        onChange={onChange}
        disableBlockquote={disableBlockquote}
      />

      <InlineFormatOptions
        editorState={editorState}
        onChange={onChange}
        formats={[
          [INLINE_STYLE_BOLD, FormatBoldIcon],
          [INLINE_STYLE_ITALIC, FormatItalicIcon],
          [INLINE_STYLE_UNDERLINED, FormatUnderlinedIcon],
        ]}
      />
      <Divider flexItem orientation="vertical" sx={{ my: 1 }} />

      <EditorToggleButtonGroup
        size="small"
        value={listType}
        exclusive
        onChange={toggleBlockType}
      >
        <ToggleButton size="small" value={BLOCK_STYLE_UNORDERED}>
          <FormatListBulletedIcon fontSize="small" />
        </ToggleButton>
        <ToggleButton size="small" value={BLOCK_STYLE_ORDERED}>
          <FormatListNumberedIcon fontSize="small" />
        </ToggleButton>
      </EditorToggleButtonGroup>
    </>
  )
}
