import React from 'react'

import { useLinkedPageTargets } from 'src/modules/page/hooks'
import { INSTANCE_TYPE_ARTICLE } from '../../app/links'
import PublicPageContentList from './PublicPageContentList'

const PublicResearch = () => {
  const { target } = useLinkedPageTargets()
  return (
    <PublicPageContentList
      target={target}
      state="PUBLISHED"
      block_type="TEXT"
      block_limit="1"
      pageType={INSTANCE_TYPE_ARTICLE}
    />
  )
}

export default PublicResearch
