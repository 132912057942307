import {
  FillLayerSpecification,
  ImageSource,
  ImageSourceSpecification,
  Map,
  Popup,
} from 'mapbox-gl'
import {
  getImageSourceId,
  getImageLayerId,
  FourCoordinates,
  getViewOnlyImageMouseSourceId,
  getViewOnlyImageMouseLayerId,
} from './MapImageHelpers'

import Slider from '@mui/material/Slider'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { createPortal } from 'react-dom'
import mapboxgl from 'mapbox-gl'
import { WeAreMap } from './MapEdit'

export interface MapImageHookResponse {
  addImageToGlobe: CallableFunction
}

const INITIAL_IMAGE_OPACITY = 50

interface ImageOpacitySliderProps {
  mapinst: Map
  imageId: string
  sliderWidth?: any
}
export const ImageOpacitySlider = ({
  mapinst,
  imageId,
  sliderWidth,
}: ImageOpacitySliderProps) => {
  const imageLayerId = getImageLayerId(imageId)

  const getImageOpacity = () => {
    if (imageLayerId) {
      const value = mapinst.getPaintProperty(imageLayerId, 'raster-opacity')
      if (typeof value === 'number') {
        return value * 100
      }
    }
  }

  const initialOpacity = getImageOpacity()
  const [opacity, setOpacity] = useState(Math.round(initialOpacity ?? 50))

  console.debug(`MapImage.ImageOpacitySlider: rendering, opacity: ${opacity}`)

  const handleImageOpacityChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (typeof newValue === 'number') {
      newValue = Math.round(newValue)
      setOpacity(newValue)

      if (imageLayerId) {
        mapinst.setPaintProperty(imageLayerId, 'raster-opacity', newValue / 100)
      }
    }
  }

  // When shown at the top of the page in our overlay this inherits font etc
  // from body.
  // When shown within a mapbox popup those body styles are overridden by
  // Mapbox's map-container's styles

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // can't see a typography style that matches that applied to 'body'
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 400,
        fontSize: '1rem',
      }}
    >
      Opacity:{' '}
      <Slider
        aria-label="opacity"
        onChange={handleImageOpacityChange}
        value={opacity}
        sx={{
          marginLeft: 2,
          marginRight: 2,
          width: sliderWidth,
        }}
      />
      <Box sx={{ minWidth: '5ch', textAlign: 'right' }}>{opacity}%</Box>
    </Box>
  )
}

interface ImageOpacitySliderWithPopupProps {
  mapinst: Map
  showOpacitySliderForImageId: string
  setMouseIsOverPopupForImageId: CallableFunction
  setMouseIsOverImageId: CallableFunction
}

/**
 * Mounted in Map JSX when isEditing is false
 */
export const ImageOpacitySliderWithPopup = ({
  mapinst,
  showOpacitySliderForImageId,
  setMouseIsOverPopupForImageId,
  setMouseIsOverImageId,
}: ImageOpacitySliderWithPopupProps) => {
  const debug = false
  const mapboxPopupRef = useRef<Popup>()
  const popupMouseUpListenerRef =
    useRef<(this: Window, ev: MouseEvent) => void>() // set when pointer leaves the slider area with the mouse button held down

  if (debug)
    console.debug(
      `MapImage.ImageOpacitySliderWithPopup rendering: showOpacitySliderForImageId: ${showOpacitySliderForImageId}, mapboxPopupRef.current: ${mapboxPopupRef.current}`
    )

  useEffect(() => {
    console.debug(
      `MapImage.ImageOpacitySliderWithPopup useEffect([]) running: mapboxPopupRef.current: ${mapboxPopupRef.current}, popupMouseUpListenerRef.current: ${popupMouseUpListenerRef.current}`
    )

    return () => {
      if (debug)
        console.debug(
          `MapImage.ImageOpacitySliderWithPopup dismounting: mapboxPopupRef.current: ${mapboxPopupRef.current}, popupMouseUpListenerRef.current: ${popupMouseUpListenerRef.current}`
        )

      if (popupMouseUpListenerRef.current) {
        window.removeEventListener('mouseup', popupMouseUpListenerRef.current)
        popupMouseUpListenerRef.current = undefined
      }

      if (mapboxPopupRef.current) {
        console.debug(
          `MapImage.ImageOpacitySliderWithPopup dismounting: mapboxPopupRef.current is set: ${mapboxPopupRef.current}, will remove then clear ref...`
        )
        mapboxPopupRef.current.remove()
        mapboxPopupRef.current = undefined
      }
    }
  }, [debug])

  if (!showOpacitySliderForImageId) {
    if (mapboxPopupRef.current) {
      mapboxPopupRef.current.remove()
      mapboxPopupRef.current = undefined
      setMouseIsOverPopupForImageId(undefined)
      setMouseIsOverImageId(undefined)
    }
    return null
  }

  const createImageOpacitySliderPopup = (imageId: string) => {
    if (debug)
      console.debug(
        `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderPopup(): called with imageId: ${imageId}`
      )

    const imageSourceId = getImageSourceId(imageId)
    const imageSource: any = mapinst.getSource(imageSourceId)

    if (!imageSource) {
      console.error(
        `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderPopup(): could not get imageSource for imageId: ${imageId}`
      )

      return
    }

    const coordinates = imageSource.coordinates[0] // 0 is the top left corner

    const popup = new mapboxgl.Popup({
      className: 'image-opacity-popup',
      maxWidth: 'none', // fit contents
      closeButton: false,
      anchor: 'bottom-left',
    })
      .setLngLat(coordinates)
      .setHTML(`<div id="slider-container-for-image-${imageId}"></div>`)
      .addTo(mapinst)

    mapboxPopupRef.current = popup

    if (debug)
      console.debug(
        `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer(): created popup, element:`,
        popup.getElement()
      )

    const popupRootElement = popup.getElement()

    if (!popupRootElement) {
      return
    }

    popupRootElement.addEventListener('mouseenter', function () {
      if (debug)
        console.debug(
          `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseenter(): pointer is over popup - popupMouseUpListenerRef.current:`,
          popupMouseUpListenerRef.current
        )
      if (popupMouseUpListenerRef.current) {
        if (debug)
          console.debug(
            `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseenter(): popupMouseUpListenerRef is set, deregistering it...`
          )
        window.removeEventListener('mouseup', popupMouseUpListenerRef.current)
        popupMouseUpListenerRef.current = undefined
      }
      setMouseIsOverPopupForImageId(imageId)
    })

    popupRootElement.addEventListener('mouseleave', function (e) {
      // don't close the slider if user is currently dragging it
      if (debug) console.debug(`mouseleave() called with event`, e)
      if (!e.buttons) {
        setMouseIsOverPopupForImageId(undefined)
        if (debug)
          console.debug(
            `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseleave(): pointer is no longer over popup and button is not down`
          )
      } else {
        if (debug)
          console.debug(
            `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseleave(): pointer is no longer over popup but button is down - registering global mouseUp listener...`
          )
        if (popupMouseUpListenerRef.current) {
          window.removeEventListener('mouseup', popupMouseUpListenerRef.current)
        }
        popupMouseUpListenerRef.current = (e: any) => {
          if (popupMouseUpListenerRef.current) {
            window.removeEventListener(
              'mouseup',
              popupMouseUpListenerRef.current
            )
            popupMouseUpListenerRef.current = undefined
          }
          if (debug)
            console.debug(
              `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseleave().mouseup().popupMouseUpListener(): mouseup after leaving popup, calling setMouseIsOverPopupForImageId(undefined)...`
            )
          setMouseIsOverPopupForImageId(undefined)
        }

        window.addEventListener('mouseup', popupMouseUpListenerRef.current)
        if (debug)
          console.debug(
            `MapImage.ImageOpacitySliderWithPopup.createImageOpacitySliderContainer().popupRootElement.mouseleave().mouseup(): set popupMouseUpListenerRef.current:`,
            popupMouseUpListenerRef.current
          )
      }
    })
  }

  if (!mapboxPopupRef.current) {
    createImageOpacitySliderPopup(showOpacitySliderForImageId)
  }

  if (!mapboxPopupRef.current) {
    return
  }

  const popupRootElement = mapboxPopupRef.current.getElement()
  if (!popupRootElement) {
    return
  }
  const elementInsidePopup = popupRootElement.children[1]

  // create a Slider and position it inside the Mapbox popup
  return createPortal(
    <Box>
      <ImageOpacitySlider
        imageId={showOpacitySliderForImageId}
        mapinst={mapinst}
        sliderWidth={'250px'}
      />
    </Box>,
    elementInsidePopup
  )
}

/**
 *  Mouse events are not triggered on Mapbox layers
 *  for raster sources. So add an invisible layer at the same location as
 *  the image and attach the given mouseOver/mouseOver event listeners to it
 *
 *  Called by enableViewOnlyModeForMapImage()
 */
const addMouseAreaToMapImage = (
  mapinst: Map,
  imageId: string,
  onMouseOverImage: (ev: mapboxgl.MapEvent, imageId: string) => void,
  onMouseOutImage: (ev: mapboxgl.MapEvent, imageId: string) => void,
  onMouseClickImage: (ev: mapboxgl.MapEvent, imageId: string) => void
) => {
  const debug = true

  const imageSourceId = getImageSourceId(imageId)
  const imageSource: ImageSource | undefined = mapinst.getSource(imageSourceId)

  if (!imageSource) {
    console.error(
      `useMapImage.addMouseAreaToMapImage(): mapinst.getSource(imageSourceId) returned null - imageSourceId: ${imageSourceId}`
    )
    return
  }

  const mouseSourceId = getViewOnlyImageMouseSourceId(imageId)
  try {
    // this will error if the source already exists
    const mouseSource = mapinst.addSource(mouseSourceId, {
      type: 'geojson',
      data: {
        type: 'Polygon',
        coordinates: [[...imageSource.coordinates, imageSource.coordinates[0]]],
      },
    })

    const mouseLayerId = getViewOnlyImageMouseLayerId(imageId)
    if (debug)
      console.debug(
        `useMapImage.addMouseAreaToMapImage(): creating layer mouseLayerId '${mouseLayerId}' for source:`,
        mouseSource
      )
    const mouseLayerConfig: FillLayerSpecification = {
      id: mouseLayerId,
      source: mouseSourceId,
      type: 'fill',
      paint: {
        'fill-opacity': 0,
      },
      layout: {},
    }

    mapinst.addLayer(mouseLayerConfig)

    if (debug)
      console.debug(
        `useMapImage.addMouseAreaToMapImage(): adding onMouseEnter/onMouseLeave/click for mouseLayerId '${mouseLayerId}'...`
      )

    if (onMouseOverImage) {
      const wrapper = (ev: mapboxgl.MapEvent) => {
        onMouseOverImage(ev, imageId)
      }
      mapinst.on('mouseenter', mouseLayerId, wrapper)
      mapinst.on('touchstart', mouseLayerId, wrapper)
    }

    if (onMouseOutImage) {
      const wrapper = (ev: mapboxgl.MapEvent) => {
        onMouseOutImage(ev, imageId)
      }

      mapinst.on('mouseout', mouseLayerId, wrapper)
      mapinst.on('touchcancel', mouseLayerId, wrapper)
    }

    if (onMouseClickImage) {
      if (debug)
        console.debug(
          `useMapImage.addMouseAreaToMapImage(): registering map click event for mouseLayerId '${mouseLayerId}'...`
        )

      const wrapper = (ev: mapboxgl.MapEvent) => {
        onMouseClickImage(ev, imageId)
      }

      mapinst.on('click', mouseLayerId, wrapper)
    }
  } catch (error) {
    //meh
  }
}

/**
 * Remove the layer and source created by addMouseAreaToMapImage()
 */
const removeMouseAreaFromMapImage = (mapinst: Map, imageId: string) => {
  const debug = true

  const mouseSourceId = getViewOnlyImageMouseSourceId(imageId)
  const mouseLayerId = getViewOnlyImageMouseLayerId(imageId)
  if (debug)
    console.debug(
      `useMapImage.removeMouseAreaFromMapImage(): removing layer mouseLayerId '${mouseLayerId}' then mouseSourceId '${mouseSourceId}'...:`
    )
  try {
    mapinst.removeLayer(mouseLayerId)

    mapinst.removeSource(mouseSourceId)
  } catch (error) {
    //meh
  }
}

/**
 * Hook that does nothing other than return function addImageToGlobe()
 * Contains no state or refs
 *
 * @returns
 */
export function useMapImage(): MapImageHookResponse {
  // BE CAREFUL WITH ANY STATE OR REFS HERE as useMapImage() is called every time Map re-renders

  /**
   * Adds an image as a MapBox source with the given coordinates and adds a layer using it.
   *
   * Called from map onLoad()
   *
   */
  const addImageToGlobe = (
    mapinst: Map,
    imageId: string,
    url: string,
    rotatedImageBorderCoords: FourCoordinates, // getRotatedImageWithLevelBoundaryBoxCoords calculates
    initialImageOpacity: number = INITIAL_IMAGE_OPACITY
  ) => {
    const debug = false
    if (debug) {
      console.debug(`useMapImage.addImageToGlobe(): called with url: ${url}`)

      console.debug(
        `useMapImage.addImageToGlobe(): called with rotatedImageBorderCoords`,
        rotatedImageBorderCoords
      )
    }
    if (!rotatedImageBorderCoords) {
      console.error(
        `useMapImage.addImageToGlobe(): called with no georeferenced coordinates`
      )
      return
    }

    if (rotatedImageBorderCoords.length !== 4) {
      console.error(
        `useMapImage.addImageToGlobe(): rotatedImageBorderCoords must be an array of 4 elements`,
        rotatedImageBorderCoords
      )
      return
    }

    const source: ImageSourceSpecification = {
      type: 'image',
      url: url,
      coordinates: rotatedImageBorderCoords, // must be 4 coords
      //not allowed... data: { bearing: rotateDegrees },
    }

    const imageSourceId = getImageSourceId(imageId)
    mapinst.addSource(imageSourceId, source)

    const imageLayerId = getImageLayerId(imageId)

    // place new layer before MapboxDraw layer if it exists
    const drawLayer = mapinst.getLayer('gl-draw-polygon-fill-inactive.cold')
    if (debug) {
      if (drawLayer) {
        console.debug(
          `useMapImage.addImageToGlobe(): MapboxDraw layer exists, adding new image layer before it`
        )
      } else {
        console.debug(
          `useMapImage.addImageToGlobe(): MapboxDraw layer does not exist adding new image layer to the top of the layer stack`
        )
      }
    }

    mapinst.addLayer(
      {
        id: imageLayerId,
        type: 'raster',
        source: imageSourceId,
        paint: {
          'raster-fade-duration': 0,
        },
        metadata: {},
      },
      drawLayer ? 'gl-draw-polygon-fill-inactive.cold' : undefined
    )

    mapinst.setPaintProperty(
      imageLayerId,
      'raster-opacity',
      initialImageOpacity / 100
    )

    // TODO add alt-mousewheel event to change image opacity in non-edit-mode

    if (debug)
      console.debug(
        `useMapImage.addImageToGlobe(): returning imageSourceId: '${imageSourceId}', imageLayerId: '${imageLayerId}'`
      )

    return [imageSourceId, imageLayerId]
  } // end of addImageToGlobe()

  return { addImageToGlobe: addImageToGlobe }
} // end of hook

interface MapImageOverlayJSXProps {
  mapinst: Map
  currentMap: WeAreMap
  editMode: boolean
  mapOnLoadComplete: boolean
}

/**
 * Adds mouseover listeners on the images layered onto the globe and shows
 * an opacity slider when the mouse pointer touches an image.
 * The slider stays onscreen until either:
 *  - a mouse button is pressed on the current image or map
 *  - edit button is pressed
 *
 *
 * Images are added to the globe in map.onload() so this needs to wait for that
 * before it can add its listeners,
 *
 */
export const MapImageOverlayJSX = ({
  mapinst,
  currentMap,
  editMode,
  mapOnLoadComplete,
}: MapImageOverlayJSXProps) => {
  const debug = false

  const [showOpacitySliderForImageId, setShowOpacitySliderForImageId] =
    useState<string>()
  const showOpacitySliderForImageIdRef = useRef<string>() // kept up-to-date by a useEffect so that event listeners can get the latest value

  useEffect(() => {
    showOpacitySliderForImageIdRef.current = showOpacitySliderForImageId
  }, [showOpacitySliderForImageId])

  console.debug(
    `useMapImage().MapImageOverlayJSX: rendering, mapOnLoadComplete: ${mapOnLoadComplete}, editMode: ${editMode}, showOpacitySliderForImageId: ${showOpacitySliderForImageId}...`
  )

  const onMapClick = (ev: mapboxgl.MapEvent) => {
    setShowOpacitySliderForImageId(undefined)
  }

  const enableViewOnlyModeForMapImage = useCallback(
    (
      mapinst: Map,
      imageId: string,
      onMouseOverImage?: (ev: mapboxgl.MapEvent, imageId: string) => void,
      onMouseOutImage?: (ev: mapboxgl.MapEvent, imageId: string) => void,
      onMouseClickImage?: (ev: mapboxgl.MapEvent, imageId: string) => void
    ) => {
      const debug = true

      if (debug)
        console.debug(
          `useMapImage().MapImageOverlayJSX.enableViewOnlyModeForMapImage(): calling (en|dis)ableViewOnlyModeForMapImage()...`
        )

      const onMouseOverImage2 = (ev: mapboxgl.MapEvent, imageId: string) => {
        console.debug(
          `MapImageOverlayJSX.enableViewOnlyModeForMapImage().onMouseOverImage2(): calling setShowOpacitySliderForImageId() with imageId '${imageId}'...` //  showOpacitySliderForImageId: '${showOpacitySliderForImageId}'`
        )

        setShowOpacitySliderForImageId(imageId)

        if (onMouseOverImage) {
          onMouseOverImage(ev, imageId)
        }
      }
      const onMouseOutImage2 = (ev: mapboxgl.MapEvent, imageId: string) => {
        console.debug(
          `MapImageOverlayJSX.enableViewOnlyModeForMapImage().onMouseOutImage2(): doing nothing.` // showOpacitySliderForImageId already: '${showOpacitySliderForImageId}'`
        )

        // do nothing, leave the slider onscreen in case the user is moving towards it   setShowOpacitySliderForImageId(undefined)

        if (onMouseOutImage) {
          onMouseOutImage(ev, imageId)
        }
      }

      const onMouseClickImage2 = (ev: mapboxgl.MapEvent, imageId: string) => {
        //showOpacitySliderForImageIdRef is out of date because this event listener function is running outside of the react
        //context, so use the ref
        const existingValue = showOpacitySliderForImageIdRef.current
        if (debug)
          console.debug(
            `MapImageOverlayJSX.enableViewOnlyModeForMapImage().onMouseClickImage2(): called with imageId '${imageId}, showOpacitySliderForImageIdRef.current: ${showOpacitySliderForImageIdRef.current}, calling setShowOpacitySliderForImageId()...`
          )
        if (existingValue === imageId) {
          setShowOpacitySliderForImageId(undefined)
        } else {
          setShowOpacitySliderForImageId(imageId)
        }

        if (onMouseClickImage) {
          onMouseClickImage(ev, imageId)
        }
      }

      if (debug)
        console.debug(
          `MapImageOverlayJSX.MapImageOverlayJSX.enableViewOnlyModeForMapImage(): called with imageId '${imageId}, calling addMouseAreaToMapImage()...`
        )

      addMouseAreaToMapImage(
        mapinst,
        imageId,
        onMouseOverImage2,
        onMouseOutImage2,
        onMouseClickImage2
      )
    },
    []
  )

  const disableViewOnlyModeForMapImage = useCallback(
    (mapinst: Map, imageId: string) => {
      removeMouseAreaFromMapImage(mapinst, imageId)
    },
    []
  )

  const enableViewOnlyMode = useCallback(
    (mapinst: Map, currentMap: WeAreMap) => {
      if (mapinst) {
        mapinst.on('click', onMapClick)
      }

      currentMap?.mapLayerImageLinks?.forEach(imageLink => {
        enableViewOnlyModeForMapImage(mapinst, imageLink.target.id)
      })
    },
    [enableViewOnlyModeForMapImage]
  )

  const disableViewOnlyMode = useCallback(
    (mapinst: Map, currentMap: WeAreMap) => {
      if (mapinst) {
        mapinst.off('click', onMapClick)
      }
      currentMap?.mapLayerImageLinks?.forEach(imageLink => {
        disableViewOnlyModeForMapImage(mapinst, imageLink.target.id)
      })
    },
    [disableViewOnlyModeForMapImage]
  )

  // observes editMode and mapOnLoadComplete, calls enableViewOnlyModeForMapImage or disableViewOnlyModeForMapImage
  useEffect(() => {
    console.debug(
      `useMapImage().MapImageOverlayJSX.useEffect([editMode]): mapOnLoadComplete: ${mapOnLoadComplete}. editMode: ${editMode}, calling (en|dis)ableViewOnlyModeForMapImage()...`
    )

    if (!mapOnLoadComplete) {
      return
    }
    if (editMode) {
      disableViewOnlyMode(mapinst, currentMap)
    } else {
      enableViewOnlyMode(mapinst, currentMap)
    }

    return () => {
      if (debug)
        console.debug(
          `useMapImage().MapImageOverlayJSX: dismounting, calling disableViewOnlyMode()...`
        )

      disableViewOnlyMode(mapinst, currentMap)
    }
  }, [
    currentMap,
    debug,
    disableViewOnlyMode,
    editMode,
    enableViewOnlyMode,
    mapOnLoadComplete,
    mapinst,
  ])

  if (!mapOnLoadComplete) {
    if (debug)
      console.debug(
        `MapImageOverlayJSX: rendering, mapOnLoadComplete: ${mapOnLoadComplete}, returning null`
      )
    return <></>
  }
  return (
    <Box mt={1}>
      {showOpacitySliderForImageId && (
        <Box
          sx={{
            backgroundColor: 'white',
            px: '10px', // match the padding on the mapbox popup
            py: '2px',
            borderRadius: '4px',
          }}
        >
          <ImageOpacitySlider
            key={`image-opacity-slider-for-image-id-${showOpacitySliderForImageId}`}
            mapinst={mapinst}
            imageId={showOpacitySliderForImageId}
            sliderWidth={'250px'}
          />
        </Box>
      )}
    </Box>
  )
}
