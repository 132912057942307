import { useState } from 'react'
import clsx from 'clsx'

import { Box, Input } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import DrawIcon from '@mui/icons-material/Draw'
import Crop from '@mui/icons-material/Crop'

import { useActionDispatcher } from 'src/modules/app'
import { IconButton, Typography } from 'src/modules/ui'

import {
  removeMediaFromBlock,
  cropPhotoOnBlock,
  addCaptionToPhotoFromBlock,
  editPhotoOnBlock,
} from './writeArticleSlice'

import ArticlePhoto from 'src/modules/content/ArticlePhoto'
import CreateCroppedPhotoDialog from 'src/modules/photo/ImageCropper'
import { useHover } from '../app/hooks'
import EditButton from '../ui/EditButton'
import LayersIcon from '@mui/icons-material/Layers'
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall'
import { imageCaptionStyles } from '../content/Article'
import { MEDIA_TYPE_PDF, MEDIA_TYPE_VIDEO_YOUTUBE } from '../photo'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

import MediaNavigatorDialog from 'src/modules/photo/MediaNavigator'

const usePhotoStyles = makeStyles(theme => ({
  root: {
    '&:hover .edit': {
      visibility: 'visible',
    },
  },
  removeButton: {
    background: 'white',
    marginRight: theme.spacing(1),
  },
  cropButton: {
    background: 'white',
  },
  buttonContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    display: 'none',
    width: '100%',
  },
  showButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  mediaCaptionContainer: {
    display: 'flex',
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
  },
  mediaCaption: {
    display: 'inline-block',
    width: '100%',
    textWrap: true,
    '& *': {
      padding: '1px',
    },
  },
  mediaCaptionInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mediaCaptionText: {
    color: theme.palette.textSecondary,
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  edit: {
    visibility: 'hidden',
    marginLeft: theme.spacing(1),
  },
  editPhotoCaption: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& *': {
      padding: '1px',
    },
  },
  checkAndClearContainer: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    '& *': {
      padding: '2px 0px',
    },
  },
}))

export const EditablePhoto = ({
  contentId,
  contentBlockId,
  height,
  photo,
  mediaColumnSize,
  isCarousel, //if false the caption will be shown below the photo
  buttons = {}, // all default to on, can disable buttons by setting name to false
  ...props
}) => {
  const isNonEditableMedia =
    photo.youtubeVideoId?.length === 11 ||
    photo.type === MEDIA_TYPE_VIDEO_YOUTUBE ||
    photo.type === MEDIA_TYPE_PDF
  const classes = usePhotoStyles()
  const dispatchRemovePhotoFromBlock = useActionDispatcher(removeMediaFromBlock)
  const dispathCropPhotoOnBlock = useActionDispatcher(cropPhotoOnBlock)
  const dispathEditPhotoOnBlock = useActionDispatcher(editPhotoOnBlock)
  const [photoToCrop, setPhotoToCrop] = useState(null)
  const [photoToAnnotate, setPhotoToAnnotate] = useState(null)

  const handleEditPhotoOnBlock = async updates => {
    await dispathEditPhotoOnBlock({
      id: contentBlockId,
      contentId,
      photo: photo.id,
      order: photo.order,
      ...updates,
    })
  }

  const handleAddCroppedPhoto = async (croppedPhoto, oldPhoto) => {
    await dispathCropPhotoOnBlock({
      id: contentBlockId,
      contentId,
      oldPhoto: oldPhoto.id,
      newPhoto: croppedPhoto.id,
      order: oldPhoto.order,
      caption: oldPhoto.caption,
    })
    setPhotoToCrop(null)
  }

  const toggleBoxShadow = () => {
    if (!photo.boxShadow || photo.boxShadow === 'none') {
      handleEditPhotoOnBlock({
        boxShadow: '-10px 10px 15px 0px #000000',
        borderRadius: photo.borderRadius,
      })
    } else if (photo.boxShadow === '-10px 10px 15px 0px #000000') {
      handleEditPhotoOnBlock({
        boxShadow: '10px 10px 15px 0px #000000',
        borderRadius: photo.borderRadius,
      })
    } else {
      handleEditPhotoOnBlock({
        boxShadow: 'none',
        borderRadius: photo.borderRadius,
      })
    }
  }

  const toggleBorderRadius = () => {
    if (!photo.borderRadius || photo.borderRadius === 0) {
      handleEditPhotoOnBlock({
        borderRadius: 15,
        boxShadow: photo.boxShadow,
      })
    } else {
      handleEditPhotoOnBlock({
        borderRadius: 0,
        boxShadow: photo.boxShadow,
      })
    }
  }

  const handleRemovePhoto = photo => {
    dispatchRemovePhotoFromBlock({
      contentId,
      id: contentBlockId,
      mediaId: photo.id,
      order: photo.order,
    })
  }

  const [hoverRef, isHovered] = useHover()
  return (
    <Box
      ref={hoverRef}
      className={classes.root}
      sx={{
        height: '100%',
        position: 'relative',
        width: '100%',
      }}
    >
      <ArticlePhoto
        photo={photo}
        contentId={contentId}
        contentBlockId={contentBlockId}
        editable={true}
        height={height}
        mediaColumnSize={mediaColumnSize}
        {...props}
      >
        <div
          className={clsx(
            classes.buttonContainer,
            isHovered && classes.showButtonContainer
          )}
        >
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            className={classes.removeButton}
            onClick={() => handleRemovePhoto(photo)}
          >
            <DeleteIcon />
          </IconButton>
          {!isNonEditableMedia && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0.6,
              }}
            >
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                className={classes.cropButton}
                onClick={() => setPhotoToAnnotate(photo)}
              >
                <DrawIcon />
              </IconButton>
              <MediaNavigatorDialog
                photo={photoToAnnotate}
                onReportClosed={() => setPhotoToAnnotate(null)}
                onRequestNext={undefined}
                onRequestPrev={undefined}
                canRequestNext={false}
                canRequestPrev={false}
                handleDelete={undefined}
                refreshMedia={undefined}
                editingImageAnnotations={true}
                onExitEditingImageAnnotations={() => {
                  setPhotoToAnnotate(null)
                }}
              />
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                className={classes.cropButton}
                onClick={() => setPhotoToCrop(photo)}
              >
                <Crop />
              </IconButton>
              <CreateCroppedPhotoDialog
                photo={photoToCrop}
                onDoneCropping={croppedPhoto =>
                  handleAddCroppedPhoto(croppedPhoto, photo)
                }
                onCroppingCancelled={() => setPhotoToCrop(null)}
              />
              {buttons['shadow'] !== false && (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  className={classes.cropButton}
                  onClick={() => toggleBoxShadow()}
                >
                  <LayersIcon />
                </IconButton>
              )}
              {buttons['borderRadius'] !== false && (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  className={classes.cropButton}
                  onClick={() => toggleBorderRadius()}
                >
                  <PhotoSizeSelectSmallIcon />
                </IconButton>
              )}
            </Box>
          )}
        </div>
      </ArticlePhoto>
      {!isCarousel && (
        <Caption
          contentId={contentId}
          contentBlockId={contentBlockId}
          photo={photo}
        />
      )}
    </Box>
  )
}

export const Caption = ({ contentId, contentBlockId, photo, className }) => {
  const classes = usePhotoStyles()

  const dispatchAddCaptionToPhotoFromBlock = useActionDispatcher(
    addCaptionToPhotoFromBlock
  )

  const handleCaptionSubmit = async () => {
    try {
      await dispatchAddCaptionToPhotoFromBlock({
        contentId,
        id: contentBlockId,
        mediaId: photo.id,
        order: photo.order,
        caption,
      })
      stopEditing()
    } catch (err) {}
  }

  const [isEditing, setIsEditing] = useState(false)

  const startEditing = () => {
    setCaption(photo.caption)
    setIsEditing(true)
  }

  const stopEditing = () => {
    setIsEditing(false)
    setCaption(photo.caption)
  }

  const [caption, setCaption] = useState(photo?.caption || '')

  const handleCaptionChange = event => {
    setCaption(event.target.value)
  }

  return (
    <div className={clsx(classes.mediaCaptionContainer, className)}>
      {isEditing ? (
        <EditCaption
          caption={caption}
          handleCaptionChange={handleCaptionChange}
          handleCaptionSubmit={handleCaptionSubmit}
          photo={photo}
          stopEditing={stopEditing}
        />
      ) : (
        <DisplayCaption photo={photo} startEditing={startEditing} />
      )}
    </div>
  )
}

export const DisplayCaption = ({ photo, startEditing }) => {
  const classes = usePhotoStyles()
  return (
    <div className={classes.mediaCaption}>
      <div className={classes.mediaCaptionInner}>
        <Typography
          className={classes.mediaCaptionText}
          style={imageCaptionStyles}
        >
          {photo?.caption || ''}
        </Typography>
        <EditButton
          permissionAction={ACTION_ALL_ACCESS}
          className={clsx('edit', classes.edit)}
          onClick={startEditing}
          fontSize="small"
        />
      </div>
    </div>
  )
}

const EditCaption = ({
  caption,
  handleCaptionChange,
  handleCaptionSubmit,
  stopEditing,
}) => {
  const classes = usePhotoStyles()
  return (
    <div className={classes.editPhotoCaption}>
      <Input
        autoFocus
        fullWidth={true}
        value={caption}
        placeholder="Add a caption..."
        onChange={handleCaptionChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleCaptionSubmit()
          }
        }}
      />
      <div className={classes.checkAndClearContainer}>
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          onClick={() => handleCaptionSubmit()}
          disabled={false}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton permissionAction={ACTION_ALL_ACCESS} onClick={stopEditing}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  )
}

export default EditablePhoto
