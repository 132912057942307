import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material'
import { Box } from '@mui/system'

import { updateContentBlock } from './writeArticleSlice'

import { useActionDispatcher } from 'src/modules/app'
import {
  setExploredIndividualId,
  EXPLORE_VIEW_MODE_SUBTREE,
  addIdToSelectedIndividualIds,
  setExploreViewMode,
  setArbitraryVisibleIndividualIds,
} from 'src/modules/viewer/exploreTreeSlice'
import Explore from 'src/modules/viewer/Explore'
import { SubTreeViewer } from 'src/modules/viewer/treeViewers'
import { useSubTreeNodes } from 'src/modules/viewer/hooks'
import { Button } from 'src/modules/ui'
import { selectNodeDirectory } from '../viewer/viewerSlice'
import { Caption } from './EditablePhoto'
import TreeLoadingGuard from '../viewer/TreeLoadingGuard'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_ARTICLE } from '../app/links'
import { selectUserHomeIndividual } from '../auth/authSlice'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover .edit': {
      visibility: 'visible',
    },
  },
  selectPeopleToolbar: {
    position: 'absolute',
    top: theme.spacing(-4.25),
    right: theme.spacing(14),
    zIndex: 1000,
  },
  addBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blockContents: {
    position: 'absolute',
  },
  subTreeControls: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  selectTree: {
    width: '200px',
    marginRight: theme.spacing(3),
  },
}))

const Icon = styled('img')({})

const SubTreeBlock = ({ contentId, id, isHovered, isFocused, subTree }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const [visibleNodes, selectedIndividualIds] = useSubTreeNodes(
    nodeDirectory,
    subTree
  )

  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)

  const userHomeIndividual = useSelector(selectUserHomeIndividual)

  const handleOnCreateSubTree = subTree => {
    // Immediately set the new sub tree on this block
    dispatchUpdateContentBlock({
      contentId,
      id,
      setSubTree: subTree.id,
    })
  }

  const triggerRef = useRef()
  const exploreTrigger = ({ onClick }) => {
    triggerRef.current = onClick
  }

  const handleClickSelectPeople = event => {
    if (subTree) {
      dispatch(setExploreViewMode(EXPLORE_VIEW_MODE_SUBTREE))
      dispatch(setArbitraryVisibleIndividualIds(visibleNodes.map(n => n.id)))
      for (let selectedId of selectedIndividualIds) {
        dispatch(addIdToSelectedIndividualIds(selectedId))
      }
    } else {
      dispatch(setExploredIndividualId(userHomeIndividual.id))
    }
    triggerRef.current(event)
  }

  const hasVisibleNodes = !!visibleNodes.length

  return (
    <TreeLoadingGuard isLoadingOveride={hasVisibleNodes}>
      <Box
        className={clsx({
          [classes.root]: true,
          [classes.addBlock]: !hasVisibleNodes,
        })}
      >
        {hasVisibleNodes && isHovered && (
          <Box className={classes.selectPeopleToolbar}>
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_ARTICLE }}
              color="primary"
              onClick={handleClickSelectPeople}
            >
              Select people
            </Button>
          </Box>
        )}
        {!hasVisibleNodes && (
          <Box sx={{ position: 'relative' }}>
            <Icon src="/tree-background.svg" />
            <Box
              sx={{
                position: 'absolute',
                top: '45%',
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                permissionAction={ACTION_CREATE}
                permissionParams={{ instanceType: INSTANCE_TYPE_ARTICLE }}
                color="primary"
                onClick={handleClickSelectPeople}
              >
                Select people
              </Button>
            </Box>
          </Box>
        )}
        <Box
          className={clsx({
            [classes.blockContents]: !hasVisibleNodes,
          })}
        >
          <Box>
            {hasVisibleNodes && (
              <SubTreeViewer
                visibleNodes={visibleNodes}
                selectedIndividualIds={selectedIndividualIds}
              />
            )}
          </Box>
          {subTree && (
            <Box py={2}>
              <Caption
                photo={subTree}
                contentId={contentId}
                contentBlockId={id}
              />
            </Box>
          )}
        </Box>
        <Explore
          selectMenuConfig={{ select: true, explore: true }}
          closeAfterSubTreeCreated={true}
          onCreateSubTree={handleOnCreateSubTree}
          trigger={exploreTrigger}
          subTreeCaption={subTree?.caption}
        />
      </Box>
    </TreeLoadingGuard>
  )
}

export default SubTreeBlock
