import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { BookmarkAddButton } from 'src/modules/app/Bookmarks'
import {
  generateTreeLink,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
} from 'src/modules/app/links'

import ContentHeaderMeta from './ContentHeaderMeta'
import EditButton from '../ui/EditButton'
import { useLocation } from 'react-router-dom'
import { selectTreeBySlug } from '../auth/authSlice'
import { INSTANCE_TYPE_BLOG_POST } from '../app/links'
import AddArticleToBlogDialog from '../ui/AddArticleToBlogDialog'
import FabButton from '../ui/FabButton'
import AddIcon from '@mui/icons-material/Add'
import { ACTION_ADD_TO_BLOG, ACTION_EDIT } from '../app/appConstants'
import LinkWithPermissions from '../ui/LinkWithPermissions'

export const ArticleEditButton = ({
  id,
  treeSlug,
  onArticleEdit,
  linkToPath,
  permissionAction,
  permissionParams,
}) => {
  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      {onArticleEdit ? (
        <EditButton
          permissionAction={permissionAction}
          permissionParams={permissionParams}
          onClick={onArticleEdit}
        />
      ) : (
        <LinkWithPermissions
          permissionAction={permissionAction}
          permissionParams={permissionParams}
          style={{ textDecoration: 'none' }}
          color="primary"
          to={generateTreeLink(
            treeSlug,
            `${linkToPath ? linkToPath : 'write-article'}/${id}`
          )}
        >
          <EditButton
            permissionAction={permissionAction}
            permissionParams={permissionParams}
            fontSize="small"
          />
        </LinkWithPermissions>
      )}
    </Box>
  )
}

export const AddArticleToBlogButton = ({ treeSlug, article }) => {
  const tree = useSelector(selectTreeBySlug(treeSlug))
  const treeType = tree?.treeType
  return (
    <>
      {treeType !== INSTANCE_TYPE_BLOG_POST && (
        <AddArticleToBlogDialog
          treeSlug={treeSlug}
          treeAllowPublicVisibility={tree?.allowPublicVisibility}
          defaultTargets={article?.alsoPublishedIn}
          contentId={article?.id}
          article={article}
          trigger={props => (
            <FabButton
              permissionAction={ACTION_ADD_TO_BLOG}
              permissionParams={{
                instance: article,
                instanceType: INSTANCE_TYPE_ARTICLE,
                tree: tree,
              }}
              text="Add to blog"
              onClick={props.onClick}
              icon={<AddIcon fontSize="small" sx={{ mb: 0.2 }} />}
            />
          )}
        />
      )}
    </>
  )
}

const ArticleHeaderMeta = article => {
  const { id, comments, publishedAt } = article
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const location = useLocation()
  const isEditing =
    location?.pathname?.includes('write-article') ||
    location?.pathname?.includes('edit-document')

  return (
    <ContentHeaderMeta
      {...article}
      commentCount={comments.slength}
      actions={
        <Box sx={{ display: 'flex', gap: 1 }}>
          {!isEditing && publishedAt && (
            <ArticleEditButton
              treeSlug={treeSlug}
              id={id}
              linkToPath={
                article.type === INSTANCE_TYPE_DOCUMENT
                  ? 'edit-document'
                  : 'write-article'
              }
              permissionAction={ACTION_EDIT}
              permissionParams={{
                instance: article,
                instanceType: INSTANCE_TYPE_ARTICLE,
              }}
            />
          )}

          {!isEditing && (
            <AddArticleToBlogButton treeSlug={treeSlug} article={article} />
          )}
          <Box>
            <BookmarkAddButton
              type={INSTANCE_TYPE_ARTICLE}
              targetId={id}
              grey
            />
          </Box>
        </Box>
      }
    />
  )
}

export default ArticleHeaderMeta
