import React from 'react'
import { makeStyles } from '@mui/styles'

import RelationSection from './RelationSection'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}))

const GenerationalSection = ({
  generation,
  availableRoles,
  showRolesHelpDialog,
}) => {
  const classes = useStyles()
  const relationTypes = [...new Set(generation.map(ind => ind.relationship))]

  if (relationTypes.includes('Siblings') && relationTypes[0] !== 'Siblings') {
    relationTypes.reverse()
  }
  if (relationTypes.includes('Parents') && relationTypes[0] !== 'Parents') {
    relationTypes.reverse()
  }

  const relations = relationTypes.map(r =>
    generation.filter(ind => ind.relationship === r)
  )

  return (
    <div className={classes.root}>
      {relations.map(r => (
        <RelationSection
          relation={r}
          key={`relSection-${r[0].relationship}`}
          availableRoles={availableRoles}
          showRolesHelpDialog={showRolesHelpDialog}
        />
      ))}
    </div>
  )
}

export default GenerationalSection
