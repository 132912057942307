import { useMemo } from 'react'

import { processIndividualsAndUnionsToGraphNodes } from '../api/graphNode'
import { makeXCoordinates0based } from '../api/graphOps'
import { DagManager } from '../api/layout'
import TreeViewer, {
  INITIAL_ZOOM_NON_EXPLORE_MODE,
  ZOOM_IN_MULTIPLIER,
} from './TreeViewer'
import { FOCUS_MODE_SCROLL } from './constants'

const SubTreeViewer = ({
  visibleNodes,
  selectedIndividualIds,
  preview,
  isPublic,
  allowDetailsScreenOnLinks = true,
}) => {
  const [nodes, links, nLayers] = useMemo(() => {
    const coreGraphNodes = processIndividualsAndUnionsToGraphNodes(visibleNodes)
    const dagManager = new DagManager(coreGraphNodes)
    return dagManager.layout(coreGraphNodes)
  }, [visibleNodes])
  makeXCoordinates0based(nodes)

  const zoom = () => {
    if (nLayers > 3) {
      return INITIAL_ZOOM_NON_EXPLORE_MODE
    } else if (nLayers === 3) {
      return 1.1
    } else {
      return ZOOM_IN_MULTIPLIER
    }
  }

  return (
    <TreeViewer
      allowDragAndZoom={false}
      exploreNodeOnClick={false}
      focusMode={FOCUS_MODE_SCROLL}
      links={links}
      navigateToNodeOnClick={allowDetailsScreenOnLinks}
      nLayers={nLayers}
      nodes={nodes}
      preview={preview}
      selectedIndividualIds={selectedIndividualIds}
      showNodeContextMenu={false}
      isSubTree={true}
      isPublic={isPublic}
      widthMultiplier={1.25}
      initialZoom={zoom()}
      defaultHeight={400}
    />
  )
}

export default SubTreeViewer
