import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
}))

const FormActions = ({ children, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.actions} style={style}>
      {children}
    </div>
  )
}

export default FormActions
