export const fileTypeMatcher = (file, type) => {
  return file.type.split('/')[0] === type
}

export const youTubeTypeMatcher = (mediaItem, type) => {
  return mediaItem.type.split('/')[1] === type
}

export const binaryFileTypeMatcher = file => {
  return ['application/pdf'].includes(file.type)
}

export const pdfFileMatcher = file => {
  return file.type === 'application/pdf'
}
