import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { chooseFamilyId } from 'src/modules/viewer/exploreTreeSlice'

import { resetPageState } from 'src/modules/page/pageSlice'

import Page, {
  getLineageTreeRoute,
  getHistoryRoute,
  getDocumentsRoute,
  getPhotosRoute,
  getLocationsRoute,
  getArtefactsRoute,
  getEventsRoute,
  getFamilyProfileRoute,
} from './Page'
import { INSTANCE_TYPE_FAMILY } from 'src/modules/app/links'
import InstanceVisibilityControl from 'src/modules/visibility/InstanceVisibilityControl'
import {
  fetchFamily,
  selectFamilyForPage,
  selectIsNodeDirectoryIsLoaded,
} from '../viewer/viewerSlice'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useIsAlphaFeatureUser } from '../auth/hooks'
import LoadingGuard from '../viewer/LoadingGuard'

const FamilyPage = () => {
  const dispatch = useDispatch()
  const showFeature = useIsAlphaFeatureUser()?.documents
  const { linkedPageId } = useParams()
  const isNodeDirectoryLoaded = useSelector(selectIsNodeDirectoryIsLoaded)
  var familyObj = undefined // useSelector(selectFamilyByFamilyId(linkedPageId))
  var familyForPage = useSelector(selectFamilyForPage)
  familyObj = familyForPage

  useEffect(() => {
    if (!familyForPage || familyForPage.id !== linkedPageId) {
      // if the familyObj from the loaded tree is not available, try loading the family from the server
      dispatch(fetchFamily({ familyId: linkedPageId }))
    }
  }, [dispatch, familyForPage, linkedPageId])

  useEffect(() => {
    if (isNodeDirectoryLoaded) {
      dispatch(chooseFamilyId(linkedPageId))
    }
    dispatch(resetPageState({ linkedPageId }))
  }, [linkedPageId, dispatch, isNodeDirectoryLoaded])

  const earliestBirthYear = familyObj?.earliestBirthYear
    ? `${familyObj.earliestBirthYear}`
    : 'unknown'

  const title = familyObj?.surname ? `${familyObj?.surname} Family` : ''

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const routes = useMemo(
    () =>
      [
        getFamilyProfileRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
          localLabelOverride: 'Overview',
        }),
        getLineageTreeRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          makeDefault: true,
          localLabelOverride: `${familyObj?.surname ? title : 'Family'} Tree`,
          treeSlug,
        }),
        getHistoryRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
        }),
        showFeature &&
          getDocumentsRoute({
            pageType: INSTANCE_TYPE_FAMILY,
            linkedPageId,
            treeSlug,
          }),
        getPhotosRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
        }),
        getEventsRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
        }),
        getArtefactsRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
        }),
        getLocationsRoute({
          pageType: INSTANCE_TYPE_FAMILY,
          linkedPageId,
          treeSlug,
        }),
      ].filter(x => x),
    [familyObj?.surname, linkedPageId, title, treeSlug, showFeature]
  )

  return (
    <LoadingGuard title={'loading family .. '} isLoadingOveride={!familyObj}>
      <Page
        fullWidthSubRootContainer={true}
        targetId={linkedPageId}
        target={familyObj}
        type={INSTANCE_TYPE_FAMILY}
        routes={routes}
        showTags={false}
        title={title}
        subTitle={earliestBirthYear}
        editComponent={
          <InstanceVisibilityControl
            id={familyObj?.id}
            type={INSTANCE_TYPE_FAMILY}
            visibility={familyObj?.visibility}
            iconSize="medium"
          />
        }
      />
    </LoadingGuard>
  )
}

export default FamilyPage
