import { Autocomplete, TextField, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { selectIndividualById } from './viewerSlice'
import {
  selectExploredIndividualId,
  selectChosenFamilyId,
} from 'src/modules/viewer/exploreTreeSlice'
import { isNull } from 'lodash'
import { useActionDispatcher } from '../app'
import {
  clearSearchResults,
  searchFamilies,
  searchIndividuals,
  selectFamilies,
  selectIndividuals,
} from '../writeArticle/writeArticleSlice'

import {
  clearPublicSearchResults,
  publicSearchFamilies,
  publicSearchIndividuals,
  selectPublicSearchFamilies,
  selectPublicSearchIndividuals,
} from '../public/tree/treeSlice'
import { matchSorter } from 'match-sorter'
import { INDIVIDUAL_SEARCH_KEYS } from '../ui/individualUtils'

const useStyles = makeStyles(theme => ({
  descendedFrom: {
    fontWeight: 'bold',
  },
  search: {
    width: '100%',
  },
}))

/**
 * Individual and/or Family selector widget.
 *
 * If `traceLineageTo` is supplied, the selection will be limited to just
 * the families from which that individual is descended.
 */
export const SelectFamilyOrIndividualPartial = ({
  onSelectFamily,
  onSelectIndividual,
  onDeselect,
  traceLineageTo = undefined,
  showSelectFamily = true,
  maintainExploredIndividualState = true,
  allFamilies,
  nodeDirectory,
  setFamilyToPreview,
  individual,
  sharedByIndividual,
  isPublic,
  hideSearchWithoutOptions,
  title = 'Search',
  overrideBlur = false,
  autoSelect = true,
  individualLabel = 'Individuals',
}) => {
  /* unused vars abalue to be removed RGS */
  const dispatch = useDispatch()
  const classes = useStyles()
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  let familiesResults = useSelector(selectFamilies)
  let individualsResults = useSelector(selectIndividuals)
  const dispatchSearchFamilies = useActionDispatcher(searchFamilies)
  const dispatchSearchIndividuals = useActionDispatcher(searchIndividuals)
  let publicFamiliesResults = useSelector(selectPublicSearchFamilies)
  let publicIndividualsResults = useSelector(selectPublicSearchIndividuals)
  const dispatchPublicSearchFamilies = useActionDispatcher(publicSearchFamilies)
  const dispatchPublicSearchIndividuals = useActionDispatcher(
    publicSearchIndividuals
  )

  const clearAllSearchResults = () => {
    if (isPublic) {
      dispatch(clearPublicSearchResults())
    } else {
      dispatch(clearSearchResults())
    }
  }

  let families = isPublic ? publicFamiliesResults : familiesResults

  families = families.map(({ id, display, surname, earliestBirthYear }) => {
    return {
      family: id,
      surname: display || surname,
      earliestBirthYear,
    }
  })

  const [familyValue, setFamilyValue] = useState(null)
  const [familyInputValue, setFamilyInputValue] = useState('')

  const handleSearchFamilies = async searchValue => {
    if (isPublic) {
      await dispatchPublicSearchFamilies(searchValue)
    } else {
      await dispatchSearchFamilies(searchValue)
    }
  }

  const handleSearchIndividuals = async searchValue => {
    if (isPublic) {
      await dispatchPublicSearchIndividuals(searchValue)
    } else {
      await dispatchSearchIndividuals(searchValue)
    }
  }

  const handleFamilyInputChange = (event, newInputValue) => {
    setFamilyInputValue(newInputValue)
    if (newInputValue) {
      handleSearchFamilies(newInputValue)
    } else {
      clearAllSearchResults()
    }
  }

  useEffect(() => {
    handleSearchFamilies('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFamilyChange = (event, newValue) => {
    setFamilyValue(newValue)
    if (isNull(newValue)) {
      onDeselect && onDeselect()
    } else {
      onSelectFamily(newValue?.family)
      // Clear the other input:
      setIndividualInputValue('')
      setIndividualValue(null)
    }
  }

  const filteredOptions = families.filter(o => o.family !== familyValue)
  filteredOptions.sort((a, b) => {
    if (a.descendedFrom && !b.descendedFrom) {
      return -1
    }
    if (!a.descendedFrom && b.descendedFrom) {
      return 1
    }
    return 0
  })
  const familyNoOptionsText =
    familyInputValue === '' ? 'Type to search...' : 'No matches found'

  const familyOptionLabel = option => {
    const earliestBirthYear = option.earliestBirthYear
      ? `(${option.earliestBirthYear})`
      : ''
    return `${option.surname} ${earliestBirthYear}`
  }

  const exploredIndividualId = useSelector(selectExploredIndividualId)
  const exploredIndividual = useSelector(
    selectIndividualById(exploredIndividualId)
  )

  const individualNodes = Object.values(
    isPublic ? publicIndividualsResults : individualsResults
  )

  const [individualValue, setIndividualValue] = useState(null)
  const [individualInputValue, setIndividualInputValue] = useState('')

  const handleIndividualInputChange = (event, newInputValue) => {
    setIndividualInputValue(newInputValue)
    if (newInputValue) {
      handleSearchIndividuals(newInputValue)
    } else {
      clearAllSearchResults()
    }
  }
  const handleIndividualChange = (event, newValue) => {
    setIndividualValue(newValue)
    if (isNull(newValue)) {
      onDeselect && onDeselect()
    } else {
      onSelectIndividual(newValue?.id, newValue)
    }
  }

  const selectedFamilyId = useSelector(selectChosenFamilyId)
  useEffect(() => {
    if (maintainExploredIndividualState) {
      // monitor the currently explored individual and clear this
      // input if they change to a value that does not agree
      if (exploredIndividual?.id !== individualValue?.id) {
        setIndividualInputValue('')
      }
      // Remove family input if an individual is explored
      if (!selectedFamilyId) {
        setFamilyInputValue('')
      }
    }
  }, [
    individualValue,
    exploredIndividual,
    selectedFamilyId,
    maintainExploredIndividualState,
  ])

  const individualNoOptionsText =
    individualInputValue === '' ? 'Type to search...' : 'No matches found'

  const handlePreviewFamily = family => {
    //disabled for partial loading
    // if (!setFamilyToPreview) return
    //
    // setFamilyToPreview(family)
  }

  const fuzzyFilterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: INDIVIDUAL_SEARCH_KEYS,
    })
  }

  const handleOnBlur = () => {
    setIndividualInputValue('')
    dispatch(clearSearchResults())
  }

  return (
    <>
      <div className={classes.search}>
        <div>{title}</div>
        <Autocomplete
          freeSolo
          filterOptions={fuzzyFilterOptions}
          autoHighlight
          autoSelect={autoSelect}
          onBlur={!overrideBlur ? handleOnBlur : null}
          getOptionLabel={option => {
            return option?.display || ''
          }}
          inputValue={individualInputValue}
          multiple={false}
          noOptionsText={individualNoOptionsText}
          renderOption={(props, i) => {
            return (
              <li {...props} key={i.id}>
                {i.display} - b.{i.description}
              </li>
            )
          }}
          onChange={handleIndividualChange}
          onInputChange={handleIndividualInputChange}
          options={individualNodes}
          renderInput={params => (
            <TextField {...params} label={individualLabel} fullWidth />
          )}
          value={individualValue}
        />
      </div>
      {showSelectFamily && (
        <div className={classes.search}>
          <Autocomplete
            freeSolo
            autoHighlight
            autoSelect
            getOptionLabel={familyOptionLabel}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.id}
                className={clsx(
                  option.descendedFrom ? classes.descendedFrom : '',
                  props.className
                )}
                onMouseOver={
                  !mobileBreakpoint ? () => handlePreviewFamily(option) : null
                }
                onMouseLeave={
                  !mobileBreakpoint ? () => handlePreviewFamily({}) : null
                }
              >
                {familyOptionLabel(option)}
              </li>
            )}
            inputValue={familyInputValue}
            multiple={false}
            noOptionsText={familyNoOptionsText}
            onChange={handleFamilyChange}
            onInputChange={handleFamilyInputChange}
            options={filteredOptions}
            renderInput={params => (
              <TextField {...params} label="Families" fullWidth />
            )}
            value={familyValue}
          />
        </div>
      )}
    </>
  )
}

export default SelectFamilyOrIndividualPartial
