const _ = require('lodash')

/*
 removes punctiation from a Question
 */
export const cleanQuestions = formDefinition => {
  return formDefinition.map(item => {
    const cleanQuestion = (item.Question = item.Question.replace(
      /[^\w\s]/g,
      ''
    ))
    return { ...item, Question: cleanQuestion }
  })
}

export const toLongGender = shortGender => {
  const genderMap = { M: 'Male', F: 'Female', U: 'Unknown' }
  return genderMap[shortGender]
}

export const createQuestionGroups = (formDefinition, selectedInstance) => {
  let questionIndex = 1
  const groupedFormDefinition = formDefinition.reduce(
    (acc, item) => {
      if (!acc.page[item.Section]) {
        acc.page[item.Section] = []
        acc.groups.push(item.Section)
      }

      if (selectedInstance && item.Attr) {
        const existingValue = selectedInstance[item.Attr]
        if (existingValue) {
          if (item.Type.trim().toLowerCase() === 'gender') {
            item.DefaultValue = toLongGender(existingValue)
          } else {
            item.DefaultValue = existingValue
          }
        }
      }
      item.questionIndex = questionIndex
      questionIndex = questionIndex + 1

      acc.page[item.Section].push(item)
      return acc
    },
    { page: {}, groups: [] }
  )

  return groupedFormDefinition
}

/*
 translates the formik values into a payload that can be sent to the backend
 removes any empty values and moves any images into a separate array
 */
export const form2PayloadTranslator = (
  selectedWizard,
  wizardDefinition,
  formValues,
  selectedInstance
) => {
  const questionMap = {}
  wizardDefinition.forEach(item => {
    questionMap[item.Question] = item
  })

  let completedFormValues = {}
  let images = []
  Object.keys(formValues).forEach(key => {
    const value = formValues[key]
    if (value) {
      const question = questionMap[key]
      if (question && question.Type.trim().toLowerCase() === 'image') {
        images.push(value)
      } else {
        if (value.trim() !== '') {
          completedFormValues[key] = value
        }
      }
    }
  })

  return {
    selectedInstanceId: selectedInstance?.id,
    selectedWizard: selectedWizard.id,
    selectedWizardTitle: selectedWizard.title,
    values: completedFormValues,
    images: _.flatten(images).map(image => image.id), // flatten the array of arrays and return only the ids
  }
}
