import {
  Dialog,
  DialogContent,
  IconButton,
  styled,
  useMediaQuery,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import { usePrismicDocumentByUID, SliceZone } from '@prismicio/react'
import { components } from '../prismic'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const HelpContainer = styled('div')({
  overflow: 'scroll',
  height: '100%',
  padding: '1rem',
})

export const HelpPage = ({ open = false, trigger }) => {
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => setModalOpen(true)
  const handleCloseModal = () => setModalOpen(false)
  const [document] = usePrismicDocumentByUID('page', 'help')

  const dialogContent = (
    <HelpContainer style={{ width: mobileBreakpoint ? 'auto' : '60vw' }}>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleCloseModal}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CloseIcon />
      </IconButton>
      {document && (
        <SliceZone slices={document.data.body} components={components} />
      )}
    </HelpContainer>
  )

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        fullScreen={mobileBreakpoint ? true : false}
      >
        <DialogContent sx={{ pb: 3 }}>{dialogContent}</DialogContent>
      </Dialog>
    </>
  )
}
