import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { RectClipPath } from '@visx/clip-path'
import { Group } from '@visx/group'
import { Zoom } from '@visx/zoom'

import { Button } from 'src/modules/ui'

import { selectUserHomeIndividual } from 'src/modules/auth/authSlice'
import { selectNodeDirectory, selectIndividualById } from '../viewerSlice'
import { IndividualContextMenu } from './ContextMenu'
import CreateSubTree from '../CreateSubTree'
import {
  CollapsedGenerationsNode,
  DrawnGraphNode,
  DrawnLink,
} from './DrawnNodes'
import {
  FLEXIBLE_HEIGHT_PADDING,
  INDVDL_NODE_GRID_HEIGHT,
  INDVDL_NODE_GRID_WIDTH,
  MIN_VIEWER_HEIGHT,
} from './constants'
import { buildMinimumGraph, intersection } from '../api/graphOps'
import { scrollEventIsMouseWheel } from 'src/utils'
import {
  clearArbitraryVisibleIndividualIds,
  clearSelectedIndividualIds,
  EXPLORE_VIEW_MODE_PREVIEW_SUBTREE,
  selectExploredIndividualId,
  selectClickedIndividualId,
  setExploreViewMode,
  setArbitraryVisibleIndividualIds,
  clearClickedIndividualId,
  selectAddIndividualNode,
} from 'src/modules/viewer/exploreTreeSlice'
import { useNotification } from 'src/modules/app/hooks'
import { selectPublicIndividualById } from 'src/modules/public/tree/treeSlice'
import { useStyles } from './viewerStyles'
import { useSetUpAndScrollTreeContainer } from './hooks'
import {
  FOCUS_MODE_SCROLL,
  SIZE_MODE_FIT_NODES,
  SIZE_MODE_FIT_SCREEN,
} from './constants'
import { calculateInitialTransform } from './utils'
import AddIndividualDialog from './AddIndividualDialog'
import { selectPublicNodeDirectory } from '../../public/tree/treeSlice'
import {
  addIdToSelectedIndividualIds,
  removeIdFromSelectedIndividualIds,
  selectArbitraryVisibleIndividualIds,
} from '../exploreTreeSlice'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

export const INITIAL_ZOOM_NON_EXPLORE_MODE = 1
export const ZOOM_IN_MULTIPLIER = 1.2
const ZOOM_OUT_MULTIPLIER = 0.8

const ZOOM_MIN = 0.25
const ZOOM_MAX = 4

const TreeViewer = ({
  nodes,
  links,
  nLayers,
  preview,
  focusMode,
  focusModeTarget,
  graphNodeKeyModifier = '',
  selectedIndividualIds,
  initialZoom = INITIAL_ZOOM_NON_EXPLORE_MODE,
  sizeMode = SIZE_MODE_FIT_NODES,
  fitScreenVerticalProportion = 0.9,
  allowDragAndZoom = true,
  exploreNodeOnClick = false,
  navigateToNodeOnClick = false,
  showNodeContextMenu = false,
  allowCreateSubTree,
  selectMenuConfig = {},
  onCreateSubTree,
  onCloseViewerModal,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  treeBackgroundStyles = {},
  exploredIndividualSelector,
  nodesInactive,
  isPublic,
  isSubTree,
  widthMultiplier = 1,
  subTreeCaption,
  oneClickSelect = false,
  disableZoom = false,
  defaultHeight = 0,
}) => {
  const classes = useStyles()

  const [actualHeight, setActualHeight] = useState(defaultHeight)
  const [actualWidth, setActualWidth] = useState(0)

  // Depending on the configuration, centre the view on the explored individual
  // by horizontal scrolling the div
  const [scrollRef, widthToFitAllNodes] = useSetUpAndScrollTreeContainer({
    sizeMode,
    focusMode,
    graphNodes: nodes,
    initialZoom,
    targetIndividualNode: focusModeTarget,
  })

  // Set the height to fit the nodes if required
  useEffect(() => {
    if (sizeMode === SIZE_MODE_FIT_NODES && nodes?.length) {
      let heightToFitAllNodes =
        INDVDL_NODE_GRID_HEIGHT * nLayers + FLEXIBLE_HEIGHT_PADDING
      setActualHeight(Math.max(heightToFitAllNodes, MIN_VIEWER_HEIGHT))
      setActualWidth(widthToFitAllNodes)
    }
  }, [nLayers, nodes, sizeMode, widthToFitAllNodes])

  // Set the height to fit the screen if required
  useEffect(() => {
    const handleResize = () => {
      if (sizeMode === SIZE_MODE_FIT_SCREEN) {
        setActualWidth(document.documentElement.clientWidth)
        setActualHeight(document.documentElement.clientHeight)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [sizeMode, fitScreenVerticalProportion])

  const classNames = clsx(
    focusMode === FOCUS_MODE_SCROLL ? classes.horizontalScrollContainer : []
  )

  //setAddIndividualNode() is called on a click of an Unknown spouse by DrawnIndividual.handleOnClick(), that will pop up the AddIndividualDialog mounted below
  const addIndividualNode = useSelector(selectAddIndividualNode)

  return (
    <div className={classes.treeBackground} style={{ ...treeBackgroundStyles }}>
      <div ref={scrollRef} className={classNames}>
        <>
          <LaidOutNodesViewer
            nodes={nodes || []}
            links={links || []}
            allowDragAndZoom={allowDragAndZoom}
            width={actualWidth * widthMultiplier}
            height={actualHeight}
            initialZoom={initialZoom}
            exploreNodeOnClick={exploreNodeOnClick}
            navigateToNodeOnClick={navigateToNodeOnClick}
            preview={preview}
            selectedIndividualIds={selectedIndividualIds}
            showNodeContextMenu={showNodeContextMenu}
            selectMenuConfig={selectMenuConfig}
            focusMode={focusMode}
            allowCreateSubTree={allowCreateSubTree}
            onCreateSubTree={onCreateSubTree}
            onCloseViewerModal={onCloseViewerModal}
            graphNodeKeyModifier={graphNodeKeyModifier}
            navigateToNodeOnDoubleClick={navigateToNodeOnDoubleClick}
            navigateToNodeOnDoubleClickHandler={
              navigateToNodeOnDoubleClickHandler
            }
            exploredIndividualSelector={exploredIndividualSelector}
            nodesInactive={nodesInactive}
            isPublic={isPublic}
            isSubTree={isSubTree}
            subTreeCaption={subTreeCaption}
            oneClickSelect={oneClickSelect}
            disableZoom={disableZoom}
          />

          <AddIndividualDialog addIndividualNode={addIndividualNode} />
        </>
      </div>
    </div>
  )
}

function LaidOutNodesViewer({
  nodes,
  links,
  width,
  height,
  allowDragAndZoom,
  exploreNodeOnClick,
  navigateToNodeOnClick,
  preview,
  selectedIndividualIds = new Set(),
  showNodeContextMenu,
  selectMenuConfig = {},
  focusMode,
  allowCreateSubTree,
  onCreateSubTree,
  onCloseViewerModal,
  initialZoom,
  graphNodeKeyModifier,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  exploredIndividualSelector,
  nodesInactive,
  isPublic,
  isSubTree,
  subTreeCaption,
  oneClickSelect = false,
  disableZoom,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { showError } = useNotification()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const publicNodeDirectory = useSelector(selectPublicNodeDirectory)

  // The explored individual is the one that has been most recently
  // selected for exploration - centred and expanded above and below.
  const exploredIndividualId = useSelector(selectExploredIndividualId)
  const selector = exploredIndividualSelector || selectIndividualById
  const exploredIndividual = useSelector(selector(exploredIndividualId))
  const userHomeIndividual = useSelector(selectUserHomeIndividual)
  const arbitraryVisibleNodeIds = useSelector(
    selectArbitraryVisibleIndividualIds
  )

  const clickedIndividualId = useSelector(selectClickedIndividualId)
  let clickedIndividual = useSelector(selectIndividualById(clickedIndividualId))

  // To support context menus on public trees:
  const publicClickedIndividual = useSelector(
    selectPublicIndividualById(clickedIndividualId)
  )
  clickedIndividual = clickedIndividual || publicClickedIndividual
  let clickedGraphNode
  if (nodes.length && clickedIndividual) {
    for (let graphNode of nodes) {
      const clickedInnerNode = graphNode.innerNodes.find(i =>
        i.individualIDs.has(clickedIndividual.id)
      )
      if (clickedInnerNode) {
        clickedGraphNode = graphNode
        break
      }
    }
  }

  const numSelected = selectedIndividualIds.size
  links = links.map(link => (
    <DrawnLink link={link} key={graphNodeKeyModifier + link.id} />
  ))

  const handleClearSelection = () => {
    dispatch(clearArbitraryVisibleIndividualIds())
    dispatch(clearSelectedIndividualIds())
  }

  const handlePreviewSubtree = useCallback(() => {
    if (numSelected < 2) {
      showError('You must select at least two individuals')
      return
    }
    dispatch(setExploreViewMode(EXPLORE_VIEW_MODE_PREVIEW_SUBTREE))
    const nodes = buildMinimumGraph(
      Object.values(isPublic ? publicNodeDirectory : nodeDirectory),
      selectedIndividualIds
    )
    dispatch(setArbitraryVisibleIndividualIds(nodes.map(({ id }) => id)))
  }, [
    dispatch,
    nodeDirectory,
    numSelected,
    selectedIndividualIds,
    isPublic,
    showError,
    publicNodeDirectory,
  ])

  const handleClickAway = useCallback(() => {
    if (clickedIndividual) {
      dispatch(clearClickedIndividualId())
    }
  }, [dispatch, clickedIndividual])

  const [zoomLevel, setZoom] = useState(initialZoom)

  const zoom = useCallback(
    multiplier => {
      const newZoom = zoomLevel * multiplier
      if (newZoom >= ZOOM_MIN && newZoom <= ZOOM_MAX) {
        setZoom(newZoom)
      }
    },
    [zoomLevel, setZoom]
  )
  const zoomIn = useCallback(() => {
    zoom(ZOOM_IN_MULTIPLIER)
  }, [zoom])

  const zoomOut = useCallback(() => {
    zoom(ZOOM_OUT_MULTIPLIER)
  }, [zoom])

  const handleWheel = useCallback(
    e => {
      if (!scrollEventIsMouseWheel(e)) {
        return
      }
      e.deltaY < 0 ? zoomIn() : zoomOut()
    },
    [zoomIn, zoomOut]
  )

  useEffect(() => {
    if (isSubTree) {
      setZoom(initialZoom)
    }
  }, [initialZoom, isSubTree])

  const initialTransform = useMemo(
    () =>
      calculateInitialTransform(
        nodes,
        exploredIndividual,
        userHomeIndividual,
        width,
        height,
        focusMode,
        zoomLevel
      ),
    [
      nodes,
      userHomeIndividual,
      exploredIndividual,
      width,
      height,
      focusMode,
      zoomLevel,
    ]
  )

  //do select/deselect when no menu e.e subtree demo
  useEffect(() => {
    if (oneClickSelect && clickedIndividual) {
      if (selectedIndividualIds.has(clickedIndividual?.id)) {
        dispatch(removeIdFromSelectedIndividualIds(clickedIndividual?.id))
        dispatch(clearClickedIndividualId())
      }
      if (!selectedIndividualIds.has(clickedIndividual?.id)) {
        dispatch(addIdToSelectedIndividualIds(clickedIndividual?.id))
        dispatch(clearClickedIndividualId())
      }
    }
  }, [oneClickSelect, clickedIndividual, dispatch, selectedIndividualIds])

  const showSubTreemenu =
    ((showNodeContextMenu && selectMenuConfig.select) || oneClickSelect) &&
    !!numSelected

  return (
    <div
      className={classes.treeViewer}
      style={{ margin: 'auto', width: `${width}px` }}
    >
      <Zoom
        key={JSON.stringify(initialTransform)}
        width={width}
        height={height}
        initialTransformMatrix={initialTransform}
      >
        {zoom => {
          return (
            <div
              className={classes.viewWindow}
              style={{
                width: width,
                margin: '0 auto',
              }}
            >
              <svg width={width} height={height} style={{ display: 'block' }}>
                <RectClipPath id="zoom-clip" width={width} height={height} />

                {/* The "frame" we will draw on */}
                {/* Dismisses context menu when clicked, and allows zoom/drag */}
                {allowDragAndZoom && (
                  <rect
                    width={width}
                    height={height}
                    className={classes.treeViewerBackground}
                    onClick={handleClickAway}
                    onMouseDown={zoom.dragStart}
                    onMouseLeave={() => {
                      if (zoom.isDragging) zoom.dragEnd()
                    }}
                    onMouseMove={zoom.dragMove}
                    onMouseUp={zoom.dragEnd}
                    onTouchEnd={zoom.dragEnd}
                    onTouchMove={zoom.dragMove}
                    onTouchStart={zoom.dragStart}
                    onWheel={!disableZoom ? handleWheel : null}
                  />
                )}

                <Group transform={zoom.toString()}>
                  {links}
                  {nodes.map((graphNode, i) =>
                    graphNode.isCollapsedGenerations ? (
                      <CollapsedGenerationsNode
                        key={graphNode.id}
                        graphNode={graphNode}
                      />
                    ) : (
                      <DrawnGraphNode
                        key={graphNodeKeyModifier + graphNode.id}
                        graphNode={graphNode}
                        allowNodeClick={showNodeContextMenu || oneClickSelect}
                        exploreNodeOnClick={exploreNodeOnClick}
                        navigateToNodeOnClick={navigateToNodeOnClick}
                        preview={preview}
                        selectedIndividualIds={selectedIndividualIds}
                        navigateToNodeOnDoubleClick={
                          navigateToNodeOnDoubleClick
                        }
                        navigateToNodeOnDoubleClickHandler={
                          navigateToNodeOnDoubleClickHandler
                        }
                        nodesInactive={nodesInactive}
                        isPublic={isPublic}
                        isSubTree={isSubTree}
                      />
                    )
                  )}
                </Group>
              </svg>
              {!!clickedIndividual &&
                clickedGraphNode &&
                showNodeContextMenu && (
                  <IndividualContextMenu
                    individualNode={clickedIndividual}
                    selectMenuConfig={selectMenuConfig}
                    onNavigate={onCloseViewerModal}
                    {...contextMenuProps(
                      clickedGraphNode,
                      clickedIndividual,
                      zoom,
                      selectedIndividualIds
                    )}
                  />
                )}
              {allowDragAndZoom && !disableZoom && (
                <div className={classes.zoomControl}>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    className={classes.zoomControlButton}
                    variant="outlined"
                    onClick={zoomIn}
                  >
                    +
                  </Button>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    className={classes.zoomControlButton}
                    variant="outlined"
                    onClick={zoomOut}
                  >
                    -
                  </Button>
                </div>
              )}
              {showSubTreemenu && (
                <div className={classes.treeControl}>
                  {allowCreateSubTree && numSelected > 1 && (
                    <>
                      <div
                        style={{
                          display:
                            arbitraryVisibleNodeIds.size === 0
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <Button
                          permissionAction={ACTION_ALL_ACCESS}
                          variant="outlined"
                          color="primary"
                          className={classes.treeControlButton}
                          onClick={handlePreviewSubtree}
                        >
                          {onCreateSubTree
                            ? 'Preview sub tree'
                            : 'Create sub tree'}
                        </Button>
                      </div>
                      <div
                        style={{ display: onCreateSubTree ? 'block' : 'none' }}
                      >
                        <CreateSubTree
                          selectedIndividualIds={selectedIndividualIds}
                          onCreated={onCreateSubTree}
                          caption={subTreeCaption}
                          trigger={props => (
                            <Button
                              permissionAction={ACTION_ALL_ACCESS}
                              className={classes.treeControlButton}
                              variant="outlined"
                              color="primary"
                              {...props}
                            >
                              Create sub tree
                            </Button>
                          )}
                        />
                      </div>
                    </>
                  )}
                  <div>
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      variant="outlined"
                      className={classes.treeControlButton}
                      onClick={() => handleClearSelection()}
                    >
                      Clear selection
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )
        }}
      </Zoom>
    </div>
  )
}

export const contextMenuProps = (
  graphNode,
  individualNode,
  zoom,
  selectedIndividualIds
) => {
  // Defines where to put the context menu relative to the node. We need to
  // use zoom's transform matrix to map SVG coords to HTML absolute position.
  const { scaleX, translateX, scaleY, translateY } = zoom.transformMatrix
  const idx = graphNode.individualNodesInDisplayOrder
    .map(n => n.id)
    .indexOf(individualNode.id)

  let left = (graphNode.x + idx) * INDVDL_NODE_GRID_WIDTH
  //not using the below line but leaving it in case we want to use it later
  //let leftOffset = (-graphNode.width * 0.5 + 0.5) * INDVDL_NODE_GRID_WIDTH
  left = left * scaleX + translateX - INDVDL_NODE_GRID_WIDTH
  const top =
    (-20 + graphNode.y * INDVDL_NODE_GRID_HEIGHT) * scaleY + translateY

  const isSelected = !!intersection(selectedIndividualIds, [individualNode.id])
    .size

  return { top, left, isSelected }
}

export default TreeViewer
