import { useActionDispatcher } from '../app'
import { fetchMediaDetail } from '../photo/photoSlice'
import { Stack } from '@mui/material'
import AddMediaDialog from '../photo/AddMediaDialog'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { THUMB_SIZE_SMALL } from '../photo/Thumbnail'
import React from 'react'
import AiWizardImage from './AiWizardImage'

const AiWizardAddMediaDialogForm = ({
  values,
  linkedPageId,
  setFieldValue,
  previewTiles,
  setPreviewTiles,
  disabled,
  name,
  selectedInstance,
}) => {
  const dispatchFetchMediaDetail = useActionDispatcher(fetchMediaDetail)
  const finishUploadingMedia = async (media, setFieldValue) => {
    const mediaDetailPromises = media.map(async mediaInstance => {
      if (typeof mediaInstance === 'string') {
        const mediaDetail = await dispatchFetchMediaDetail({
          mediaId: mediaInstance,
        })
        return mediaDetail.payload
      } else {
        return mediaInstance
      }
    })

    const mediaDetailList = await Promise.all(mediaDetailPromises)

    return mediaDetailList
  }

  const media4Name = values[name] || []

  return (
    <>
      {!disabled && (
        <>
          <AddMediaDialog
            album={true}
            showYouTube={false}
            targets={selectedInstance ? [selectedInstance.id] : []}
            shouldTagImages={true}
            onFinishedUploading={async media => {
              const uploadedMedia = await finishUploadingMedia(media)

              const newMedia = uploadedMedia.filter(uploadedMediaItem => {
                //chekck if an object with the same ID exists
                return !media4Name?.some(
                  mediaItem => mediaItem.id === uploadedMediaItem.id
                )
              })

              setFieldValue(name, [...media4Name, ...newMedia])
            }}
            previewTiles={previewTiles}
            setPreviewTiles={tiles => {
              setPreviewTiles(tiles)
            }}
            trigger={props => (
              <Button
                sx={{ mt: 1 }}
                size="small"
                disabled={disabled}
                permissionAction={ACTION_ALL_ACCESS}
                {...props}
              >
                {name}
              </Button>
            )}
          />
        </>
      )}
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-start"
        justifySelf="flex-start"
        maxWidth={THUMB_SIZE_SMALL * 6}
        spacing={1}
        mt={1}
      >
        {media4Name?.length > 0 &&
          media4Name?.map((photo, index) => {
            return (
              <AiWizardImage
                key={photo?.id}
                photo={photo}
                isEditing={true}
                onRemoveImage={mediaId => {
                  let newValues = [...media4Name]
                  newValues.splice(index, 1)
                  setFieldValue(name, newValues)
                }}
              />
            )
          })}
      </Stack>
    </>
  )
}

export default AiWizardAddMediaDialogForm
