import React, { useState } from 'react'
import { Dialog, DialogContent, Box, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Formik } from 'formik'

import { Typography, Button, IconButton } from 'src/modules/ui'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(3, 3, 0),
}))

const DateTakenDialog = ({
  id,
  handleUpdateDates,
  onOpen = () => {},
  onClose = () => {},
  dateTakenGed,
  trigger,
  open = false,
}) => {
  const [modalOpen, setModalOpen] = useState(open)

  const handleShowModal = () => {
    setModalOpen(true)
    onOpen()
  }
  const handleCloseModal = () => {
    setModalOpen(false)
    onClose()
  }

  const handleSubmit = async ({ dateTakenGed }) => {
    try {
      handleUpdateDates({ id, dateTakenGed })
    } catch (err) {
      console.log(err)
    } finally {
      handleCloseModal()
    }
  }

  const initialValues = {
    dateTakenGed,
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} fullWidth onClose={handleCloseModal}>
        <Title>
          <Typography variant="h7">Photo Taken On</Typography>
          <Box sx={{ mr: -1, ml: 'auto' }}>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Title>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormikGedDatePicker
                  fullWidth
                  disabled={isSubmitting}
                  label="Taken On"
                  name="dateTakenGed"
                  margin="normal"
                  setFieldValue={setFieldValue}
                />
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    color="primary"
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DateTakenDialog
