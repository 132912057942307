import Button from '../ui/Button'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { DialogTitle, LoadingIndicator } from '../ui'

import { useAvailableFeatures, useIsAlphaFeatureUser } from '../auth/hooks'
import SelectFamilyOrIndividualPartial from '../viewer/SelectFamilyOrIndividualPartial'
import AiBiographyPromptForm from './AiBiographyFactsForm'
import { AiDisclaimerText } from './AiTextPromptContainer'
import AiChatContainer from './AiChatContainer'
import {
  openAiBiographyPromptGeneration,
  resetChatStream,
} from './servicesSlice'
import { useActionDispatcher } from '../app'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLinkedFacts, selectLinkedFacts } from '../page/pageSlice'
import { formatIndividualName } from '../ui/individualUtils'
import { convertUIGedDate } from '../common/gedcomDateParser'
import { handleAddress } from '../page/FactsList'
import { selectIsBlogTree } from '../auth/authSlice'
import { useLocation } from 'react-router-dom'
import { getQueryParams } from '../../utils'
import { ACTION_ALL_ACCESS, ACTION_MAKE_AI_REQUEST } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}))

const shrink = (str, max_len) => {
  if (str) {
    return str.substring(0, max_len)
  } else {
    return ''
  }
}

const SelectIndividual = ({ status, onSelectIndividual }) => {
  return (
    <>
      <SelectFamilyOrIndividualPartial
        title={'Select Individual'}
        onSelectIndividual={onSelectIndividual}
        showSelectFamily={false}
      />
      <br />
      <p>
        <AiDisclaimerText />
      </p>
    </>
  )
}

export const SELECT_INDIVIDUAL_STATUS = 'select_individual_status'
export const FACTS_FORM_STATUS = 'facts_form_status'
export const CHAT_FORM_STATUS = 'chat_form_status'

const AiBiographyDialogButtonBar = ({
  status,
  setStatus,
  handleCloseModal,
  handleGenerate,
}) => {
  const handleBack = () => {
    if (status === FACTS_FORM_STATUS) {
      setStatus(SELECT_INDIVIDUAL_STATUS)
    }

    if (status === CHAT_FORM_STATUS) {
      setStatus(FACTS_FORM_STATUS)
    }
  }

  return (
    <>
      {status === CHAT_FORM_STATUS && (
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          variant="outlined"
          onClick={() => setStatus(SELECT_INDIVIDUAL_STATUS)}
          sx={{ mr: 1 }}
        >
          Reset
        </Button>
      )}
      {(status === FACTS_FORM_STATUS || status === CHAT_FORM_STATUS) && (
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          variant="outlined"
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
      )}
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        variant="outlined"
        onClick={handleCloseModal}
        sx={{ mr: 1 }}
      >
        Close
      </Button>
      {status === FACTS_FORM_STATUS && (
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          onClick={handleGenerate}
          sx={{ mr: 1 }}
        >
          Generate
        </Button>
      )}
    </>
  )
}

const AiBiographyChatForm = () => {
  return (
    <>
      <AiChatContainer />
    </>
  )
}

const AiDialog = ({ trigger }) => {
  const dispatch = useDispatch()
  const dispatchOpenAiBiographyPromptGeneration = useActionDispatcher(
    openAiBiographyPromptGeneration
  )
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const allFacts = useSelector(selectLinkedFacts)
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState(SELECT_INDIVIDUAL_STATUS)
  const [individual, setIndividual] = useState(null)
  const [factFormValues, setFactFormValues] = useState({})
  const [allFactOptions, setAllFactOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const handleShowModal = () => {
    dispatch(resetChatStream())
    setIndividual(null)
    setAllFactOptions([])
    setFactFormValues({})
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setStatus(SELECT_INDIVIDUAL_STATUS)
    setModalOpen(false)
  }

  const handleSelectIndividual = (individualId, searchIndividual) => {
    //nb searchIndividual is ot a full individual here just the search results
    const fetchFacts = async individualId => {
      setStatus(FACTS_FORM_STATUS)
      setLoading(true)
      await dispatchFetchLinkedFacts({ target: individualId, allFacts: false })
      setLoading(false)
    }

    setIndividual(searchIndividual)
    fetchFacts(individualId)
  }

  const handleFactsFormChange = values => {
    setFactFormValues(values)
  }

  const handleGenerate = () => {
    const params = {
      prompt: factFormValues.prompt,
      facts: factFormValues.facts,
      target: individual.id,
    }

    dispatchOpenAiBiographyPromptGeneration(params).then(() =>
      setStatus(CHAT_FORM_STATUS)
    )
  }

  useEffect(() => {
    if (allFacts) {
      const factStrings = allFacts
        ?.filter(
          f =>
            (f.factType !== 'BIRTH' || f.factType !== 'DEATH') &&
            f.relationship !== ''
        )
        .map(
          ({
            factType,
            recordedDatesGed,
            fromDateGed,
            relationship,
            individual,
            address,
            notes,
          }) =>
            `${factType.toLowerCase()} ${
              relationship
                ? `of ${relationship} ${formatIndividualName(individual)}`
                : ''
            } ${
              (recordedDatesGed?.length || fromDateGed) &&
              `(${convertUIGedDate(
                recordedDatesGed?.[0] || fromDateGed
              )}) ${handleAddress(address)} ${shrink(notes, 40)}`
            }`
        )
      setAllFactOptions(factStrings)
    }
  }, [allFacts])

  return (
    <div>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>
          AI Biography Generator {individual ? `: ${individual?.display}` : ''}
        </DialogTitle>
        <>
          <DialogContent>
            {status === SELECT_INDIVIDUAL_STATUS && (
              <SelectIndividual onSelectIndividual={handleSelectIndividual} />
            )}
            {status === FACTS_FORM_STATUS && allFactOptions && !loading && (
              <AiBiographyPromptForm
                individual={individual}
                onChange={handleFactsFormChange}
                allFactOptions={allFactOptions}
              />
            )}
            {status === FACTS_FORM_STATUS && loading && <LoadingIndicator />}
            {status === CHAT_FORM_STATUS && <AiBiographyChatForm />}
          </DialogContent>
          <DialogActions sx={{ mr: 1, mb: 1 }}>
            <AiBiographyDialogButtonBar
              status={status}
              setStatus={setStatus}
              handleCloseModal={handleCloseModal}
              handleGenerate={handleGenerate}
            />
          </DialogActions>
        </>
      </Dialog>
    </div>
  )
}

export const AiBiographyGeneratorButton = ({ actionText }) => {
  const canEditTree = useAvailableFeatures()?.tree?.editTree
  const classes = useStyles()
  const showAlphaFeature = useIsAlphaFeatureUser()?.chatGPT
  const isBlogTree = useSelector(selectIsBlogTree)
  const location = useLocation()
  const { treeOverride } = getQueryParams(location)

  const isArticle =
    !isBlogTree && !treeOverride && location.pathname.includes('write-article')

  return (
    <>
      {showAlphaFeature && isArticle && (
        <AiDialog
          trigger={props => (
            <Button
              permissionAction={ACTION_MAKE_AI_REQUEST}
              {...props}
              className={classes.button}
              color="primary"
              size="large"
              featureAvailable={canEditTree}
            >
              {actionText}
            </Button>
          )}
        />
      )}
    </>
  )
}

export default AiBiographyGeneratorButton
