import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { selectUser } from 'src/modules/auth/authSlice'
import { fetchLinkedFeed, selectLinkedFeed } from 'src/modules/page/pageSlice'

import Feed from './Feed'
import { useLinkedPageTargets } from './hooks'

const NewsFeed = () => {
  const { target, presetTargets } = useLinkedPageTargets()

  const dispatchFetchLinkedFeed = useActionDispatcher(fetchLinkedFeed)
  const linkedFeed = useSelector(selectLinkedFeed)
  const user = useSelector(selectUser)

  const dispatchFetchFeed = useCallback(
    ({ before } = {}) => {
      if (before) {
        dispatchFetchLinkedFeed({ target, before })
      } else {
        dispatchFetchLinkedFeed({ target })
      }
    },
    [dispatchFetchLinkedFeed, target]
  )

  return (
    <Feed
      dispatchFetchFeed={dispatchFetchFeed}
      feed={linkedFeed}
      feedActionDispatcher={dispatchFetchLinkedFeed}
      presetTargets={presetTargets}
      user={user}
    />
  )
}

export default NewsFeed
