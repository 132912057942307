import React, { useEffect, useMemo } from 'react'

import { useActionDispatcher } from 'src/modules/app'

import {
  generateBlogLink,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_BLOG_POST,
  INSTANCE_TYPE_TREE,
} from '../app/links'
import Refresh from '../ui/Refresh'
import { fetchDraftHomeBlogPosts, selectDraftHomeBlogPosts } from './homeSlice'
import HomeBlogPostList from './HomeBlogPostList'
import { ActionButtons } from './BlogPosts'
import { ViewBlogButton } from '../ui/actionButtons'
import AddTreeBlogPostDialog from '../ui/AddTreeBlogPostDialog'
import Button from '../ui/Button'
import AddContentDialog, {
  AddContentCardContainer,
  useStyles,
} from '../app/AddContentDialog'
import { template } from 'lodash'
import { useParams } from 'react-router-dom'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

import { ShareButton } from '../visibility/InstanceVisibilityControl'
import { useShare } from '../app'

export const blogCards = [
  {
    title: 'Add blog post to tree',
    text: template('Create a blog post that is linked to a tree'),
    img: INSTANCE_TYPE_TREE,
    component: INSTANCE_TYPE_BLOG_POST,
    permissionAction: ACTION_ALL_ACCESS,
  },
  {
    title: 'Add a plain blog post',
    text: template('Create a simple blog post not connected to any tree'),
    img: INSTANCE_TYPE_ARTICLE,
    path: 'write-article',
    permissionAction: ACTION_ALL_ACCESS,
  },
]

const AddBlogToTreeCard = props => {
  const classes = useStyles()
  const { title, text, img, treeSlug } = props

  return (
    <AddTreeBlogPostDialog
      treeSlug={treeSlug}
      trigger={props => (
        <div className={classes.cardLink} onClick={props.onClick}>
          <AddContentCardContainer text={text} img={img} title={title} />
        </div>
      )}
    />
  )
}

export const cardComponentMap = {
  blogpost: AddBlogToTreeCard,
}

const DraftBlogPosts = () => {
  const { slug } = useParams()
  const handleShare = useShare()

  const treeSlug = slug

  const dispatchFetchDraftHomeBlogPosts = useActionDispatcher(
    fetchDraftHomeBlogPosts
  )

  const fetchBlogPosts = useMemo(() => {
    const fetchPosts = async () => {
      await dispatchFetchDraftHomeBlogPosts({
        page: 0,
        type: INSTANCE_TYPE_ARTICLE,
        block_limit: 1,
        block_type: 'TEXT',
        sort: '-modified',
        treeSlug: treeSlug,
      })
    }
    return fetchPosts
  }, [dispatchFetchDraftHomeBlogPosts, treeSlug])

  useEffect(() => {
    fetchBlogPosts()
  }, [fetchBlogPosts])
  return (
    <>
      <ActionButtons>
        <div />
        <div>
          <ViewBlogButton to={generateBlogLink(treeSlug, 'write-article')} />{' '}
          <AddContentDialog
            customCards={blogCards}
            cardComponents={cardComponentMap}
            trigger={props => (
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                className={'joyride_first_time_user_addcontent'}
                color="primary"
                size="large"
                {...props}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Add Blog Post
              </Button>
            )}
          />
          <ShareButton
            onClickShare={handleShare}
            iconSize={'medium'}
            style={{ marginLeft: 4 }}
          />
        </div>
        <Refresh onClick={fetchBlogPosts} />
      </ActionButtons>
      <HomeBlogPostList
        state={'DRAFT'}
        type={INSTANCE_TYPE_ARTICLE}
        fetchPosts={fetchDraftHomeBlogPosts}
        postsSelector={selectDraftHomeBlogPosts}
      />
    </>
  )
}

export default DraftBlogPosts
