import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Input, MenuItem, Select, Stack } from '@mui/material'
import { Switch, Route } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Button, NavLink } from 'src/modules/ui'
import MyContentLayout from './MyContentLayout'
import MyContentList from './MyContentList'
import InformationRequestDialog from 'src/modules/informationRequest/InformationRequestDialog'
import { fetchMyContent } from 'src/modules/myContent/myContentSlice'
import { selectUser } from 'src/modules/auth/authSlice'
import { useActionDispatcher } from 'src/modules/app'

import {
  generateTreeLink,
  INSTANCE_TYPE_INFORMATION_REQUEST,
} from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { ACTION_CREATE } from '../app/appConstants'

const SENT_PATH = 'my-ask-family-requests'
const RECEIVED_PATH = 'my-ask-family-requests/received'

const StyledInput = props => (
  <Input
    sx={{
      fontWeight: 500,
      position: 'relative',
      top: 1,
      '.MuiSelect-select': {
        padding: 0.5,
      },
    }}
    {...props}
  />
)

const MyInformationRequests = () => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const user = useSelector(selectUser)
  const [state, setState] = useState('PUBLISHED')
  const dispatchFetchContent = useActionDispatcher(fetchMyContent)
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleOpenDialog = () => setDialogOpen(true)
  const handleCloseDialog = () => setDialogOpen(false)
  const handleChangeState = ({ target: { value } }) => setState(value)

  const type = INSTANCE_TYPE_INFORMATION_REQUEST
  const handleInfoRequestSent = () => {
    dispatchFetchContent({ author: user.id, type })
  }

  return (
    <MyContentLayout
      pageTitle="My Ask Family Requests"
      backButton
      createButton={null}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink
              exact
              to={generateTreeLink(authorisedTreeSlug, SENT_PATH)}
              replace
            >
              Sent
            </NavLink>
            <NavLink
              to={generateTreeLink(authorisedTreeSlug, RECEIVED_PATH)}
              replace
            >
              Received
            </NavLink>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <InformationRequestDialog
            onClose={handleCloseDialog}
            onInfoRequestSent={handleInfoRequestSent}
            open={dialogOpen}
          />
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{
              instanceType: INSTANCE_TYPE_INFORMATION_REQUEST,
            }}
            color="primary"
            size="large"
            onClick={handleOpenDialog}
          >
            Create Request
          </Button>
        </Box>
      </Stack>
      <Box sx={{ mb: 2 }}>
        <Select
          onChange={handleChangeState}
          value={state}
          variant="standard"
          input={<StyledInput disableUnderline />}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value="PUBLISHED">Open Requests</MenuItem>
          <MenuItem value="CLOSED">Closed Requests</MenuItem>
        </Select>
      </Box>
      <Switch>
        <Route
          exact
          path={generateTreeLink(authorisedTreeSlug, SENT_PATH)}
          render={() => <MyContentList state={state} type={type} />}
        />
        <Route
          path={generateTreeLink(authorisedTreeSlug, RECEIVED_PATH)}
          render={() => (
            <MyContentList
              author={null}
              state={state}
              type={type}
              recipient={user.individualOnTree.id}
            />
          )}
        />
      </Switch>
    </MyContentLayout>
  )
}

export default MyInformationRequests
