import { createSlice } from '@reduxjs/toolkit'
import { pickBy } from 'lodash'

import api, { createWrappedAsyncThunk } from 'src/api'
import { initiateNodeDirectory } from 'src/modules/viewer/api/nodeDirectory'
import {
  addFamilies,
  updateNodeDirectory,
  updatePhotos,
} from '../../viewer/api/nodeDirectory'
import { INSTANCE_TYPE_FAMILY, INSTANCE_TYPE_INDIVIDUAL } from '../../app/links'

const SLICE_NAME = 'tree'

const isPrerender =
  navigator.userAgent.toLowerCase().indexOf('prerender') !== -1

const search = async (query, treeSlug, instanceType) => {
  if (!query) {
    return []
  }

  const queryStringParameters = {
    q: query,
    instanceTypes: [instanceType],
    offset: 0,
  }

  queryStringParameters.limit = 20

  const results = await api.get(`/public/tree/${treeSlug}/name/`, {
    queryStringParameters,
  })

  return results
}

export const publicSearchFamilies = createWrappedAsyncThunk(
  `${SLICE_NAME}/publicSearchFamilies`,
  (query, { getState }) => {
    const state = getState().public?.tree
    const treeSlug = state?.tree?.slug
    return search(query, treeSlug, INSTANCE_TYPE_FAMILY)
  }
)

export const publicSearchIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/publicSearchIndividuals`,
  (query, { getState }) => {
    const state = getState().public?.tree
    const treeSlug = state?.tree?.slug

    return search(query, treeSlug, INSTANCE_TYPE_INDIVIDUAL)
  }
)

export const fetchPublicIndividualsForTarget = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicIndividualsForTarget`,
  async ({ treeSlug, target }, { getState, dispatch }) => {
    const state = getState().public?.tree

    if (state.partialCallMap[target] || state.nodeFullDirectoryLoaded) {
      return { target: target }
    }

    if (!target || !treeSlug) {
      return { target: undefined }
    }

    const result = await api.get(
      `/public/tree/${treeSlug}/individualsfortarget/${target}`
    )

    return result
  }
)

export const fetchIndividuals = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividuals`,
  treeSlug => {
    return api.get(`/public/tree/${treeSlug}/individuals/`)
  }
)

export const fetchIndividualsPhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsPhotos`,
  treeSlug => api.get(`/public/tree/${treeSlug}/individuals/photos/`)
)

// this is PUBLIC so will not return any images that are not visible or are on individuals who are not visible
export const fetchIndividualPhoto = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualPhoto`,
  ({ targetId }, { getState }) => {
    const state = getState().public?.tree
    var treeSlug = state?.tree?.slug
    if (!treeSlug) {
      treeSlug = state?.treeSlug
    }
    return api.get(`/public/tree/${treeSlug}/individuals/photos/${targetId}`)
  }
)

export const fetchAllIndividualsAndPhotos = treeSlug => {
  return async dispatch => {
    const fetchIndividualsResult = await dispatch(fetchIndividuals(treeSlug))
    if (fetchIndividualsResult.payload) {
      dispatch(fetchIndividualsPhotos(treeSlug))
    } else if (fetchIndividualsResult.error) {
      console.error(
        'Error in fetchIndividualsResult :',
        fetchIndividualsResult.error
      )
    }
  }
}

export const fetchIndividualsForShareby = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsForShareby`,
  ({ sharedById, treeSlug }) => {
    const queryStringParameters = pickBy({
      sharedById,
    })

    if (!treeSlug) {
      return { target: undefined }
    }

    return api.get(`/public/tree/${treeSlug}/individualsforsharedby/`, {
      queryStringParameters,
    })
  }
)

const PYRAMID = 'pyramid'
export const fetchIndividualsForPyramid = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividualsForPyramid`,
  async ({ treeSlug, target }, { getState, dispatch }) => {
    const state = getState().public?.tree

    if (state.partialCallMap[PYRAMID] || !target) {
      return { target: PYRAMID }
    }

    if (!target || !treeSlug) {
      return { target: undefined }
    }

    const result = await api.get(
      `/public/tree/${treeSlug}/individualsforpyramid/${target}`
    )

    // if (state.nodeFullDirectoryLoaded === false) {
    //   dispatch(fetchIndividuals(treeSlug))
    // }

    return result
  }
)

export const fetchPublicIndividualsForLineage = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPublicIndividualsForLineage`,
  async ({ treeSlug, target, familyId }, { getState }) => {
    const state = getState().public?.tree

    const callKey = target + '-' + familyId
    if (state.partialCallMap[callKey] || state.nodeFullDirectoryLoaded) {
      return { target: callKey }
    }
    const result = await api.get(
      `/public/tree/${treeSlug}/individualsforlineage/${familyId}/${target}`
    )
    return result
  }
)

export const fetchIndividual = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchIndividual`,
  payload => {
    return api.get(
      `/public/tree/${payload.treeSlug}/individuals/${payload.individualId}/`
    )
  }
)

export const fetchTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchTree`,
  payload => api.get(`/public/tree/${payload.treeSlug}/tree/`)
)

export const fetchFamilies = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchFamilies`,
  payload => {
    if (isPrerender) {
      return Promise.resolve([])
    }
    return api.get(`/public/tree/${payload.treeSlug}/families/`)
  }
)

export const fetchFamily = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchFamily`,
  payload =>
    api.get(`/public/tree/${payload.treeSlug}/families/${payload.familyId}/`)
)

export const fetchSharedBy = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchSharedBy`,
  ({ sharedById, treeSlug }) => {
    if (isPrerender) {
      return Promise.resolve([])
    }
    const queryStringParameters = pickBy({
      sharedById,
    })
    return api.get(`/public/tree/${treeSlug}/shared-by/`, {
      queryStringParameters,
    })
  }
)

const initialState = {
  families: [],
  nodeDirectory: {},
  rawIndividuals: [],
  sharedByIndividual: undefined,
  currentPageIndividual: undefined,
  currentPageFamily: undefined,
  partialRawIndividualMap: {},
  partialRawIndividualPhotoMap: {},
  partialCallMap: {},
  searchFamilies: [],
  searchIndividuals: [],
  nodeFullDirectoryLoaded: false,
}

const findIndividual = (individuals, id) => {
  return individuals.filter(individual => individual.id === id)[0]
}

export const treeSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updatePublicNodeDirectoryFromContent: (state, { payload }) => {
      /* used to update the node directory from content e.g article */
      if (payload.treeSlug) {
        if (!state.treeSlug) {
          state.treeSlug = payload.treeSlug
        }
      }
      addFamilies(state, payload?.contentFamilies)
      updateNodeDirectory(state, payload?.contentIndividuals)
      updatePhotos(state, payload?.contentIndividualsPhotos)
    },
    clearPublicSearchResults: (state, { payload }) => {
      state.searchFamilies = []
      state.searchIndividuals = []
    },
    manageRequestStatus: (state, { payload }) => {
      state[payload.name] = payload.running
    },
  },
  extraReducers: {
    [fetchSharedBy.fulfilled]: (state, { payload }) => {
      state.sharedByIndividual = payload
      // payload contains only the individual's id, copy the details from the rawIndividuals
      if (state.rawIndividuals.length) {
        state.sharedByIndividual = findIndividual(
          state.rawIndividuals,
          state.sharedByIndividual.id
        )
      } else {
        console.debug(
          `treeSlice.fetchSharedBy.fulfilled(): received sharedByIndividual but rawIndividuals not set yet.`
        )
      }
    },
    [fetchFamilies.fulfilled]: (state, { payload }) => {
      // Add defaults for private families
      state.families = payload.map(f => ({
        surname: 'Hidden',
        earliestBirthYear: null,
        photo: null,
        ...f,
      }))
    },
    [fetchIndividualsForPyramid.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      addFamilies(state, payload?.families)
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.nodeDirectoryLoaded = true
    },
    [fetchPublicIndividualsForLineage.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      addFamilies(state, payload?.families)
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.nodeDirectoryLoaded = true
    },
    [fetchPublicIndividualsForTarget.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      addFamilies(state, payload?.families)
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividualsForShareby.fulfilled]: (state, { payload }) => {
      state.partialCallMap[payload?.target] = payload?.target
      updateNodeDirectory(state, payload?.individuals)
      updatePhotos(state, payload?.photos)
      state.sharedByIndividual = payload?.sharedBy
      state.nodeDirectoryLoaded = true
    },
    [fetchIndividuals.fulfilled]: (state, { payload }) => {
      //update sharedByIndividual if it is set
      if (state.sharedByIndividual) {
        const updateSharedIndividual = findIndividual(
          payload,
          state.sharedByIndividual.id
        )
        if (updateSharedIndividual) {
          state.sharedByIndividual = updateSharedIndividual
        }
      }
      initiateNodeDirectory(state, payload)
      state.nodeFullDirectoryLoaded = true
    },
    [fetchIndividualPhoto.fulfilled]: (state, { payload }) => {
      const photos = [payload]
      updatePhotos(state, photos)
    },
    [fetchTree.fulfilled]: (state, { payload }) => {
      state.tree = payload
    },
    [fetchIndividual.pending]: (state, { payload }) => {
      state.currentPageIndividual = undefined
    },
    [fetchIndividual.fulfilled]: (state, { payload }) => {
      state.currentPageIndividual = payload
    },
    [fetchFamily.pending]: (state, { payload }) => {
      state.currentPageFamily = undefined
    },
    [fetchFamily.fulfilled]: (state, { payload, meta }) => {
      state.currentPageFamily = payload
    },
    [publicSearchIndividuals.fulfilled]: (state, { payload }) => {
      state.searchIndividuals = payload
    },
    [publicSearchFamilies.fulfilled]: (state, { payload }) => {
      state.searchFamilies = payload
    },
  },
})

export const {
  updatePublicNodeDirectoryFromContent,
  clearPublicSearchResults,
  manageRequestStatus,
} = treeSlice.actions

export const selectPublicFamilies = state => state.public[SLICE_NAME].families
export const selectPublicNodeDirectory = state =>
  state.public[SLICE_NAME].nodeDirectory
export const selectPublicIndividualById = individualId => state =>
  state.public[SLICE_NAME].nodeDirectory[individualId]
export const selectPublicIndividualsById = individualIDs => state =>
  Object.entries(state.public[SLICE_NAME].nodeDirectory)
    .filter(([id]) => individualIDs.includes(id))
    .map(([, individual]) => individual)
export const selectPublicFamilyById = searchID => state =>
  state.public[SLICE_NAME].families.find(({ id }) => id === searchID)

export const selectSharedByIndividual = state =>
  state.public[SLICE_NAME].sharedByIndividual

export const selectIndividualForPage = state =>
  state.public[SLICE_NAME].currentPageIndividual

export const selectFamilyForPage = state =>
  state.public[SLICE_NAME].currentPageFamily

export const selectPublicPartialCall = partialCallKey => state => {
  const partialLoaded = state.public[SLICE_NAME].partialCallMap[partialCallKey]
  const fullLoad = state.public[SLICE_NAME].nodeFullDirectoryLoaded
  if (partialLoaded || fullLoad) {
    return true
  } else {
    return false
  }
}

export const selectPublicFamilyPyramidLoaded = state =>
  state.public[SLICE_NAME].partialCallMap[PYRAMID]

export const selectTree = state => state.public[SLICE_NAME].tree

export const selectPublicSearchFamilies = state =>
  state.public[SLICE_NAME].searchFamilies || []

export const selectPublicSearchIndividuals = state =>
  state.public[SLICE_NAME].searchIndividuals || []

export default treeSlice.reducer
