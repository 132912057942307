import { styled, Box } from '@mui/material'

import { Typography } from 'src/modules/ui'
import { INSTANCE_TYPE_INFORMATION_REQUEST } from 'src/modules/app/links'
import {
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_MEDIA_GROUP,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from '../app/links'
import { formatIndividualName } from '../ui/individualUtils'

const CardHeaderRoot = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
const SubTitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const Icon = styled('img')({})

const ContentCardHeader = ({
  authorIndividual,
  count,
  title,
  type,
  authorUser,
}) => {
  const typeTitle =
    title ||
    (type === INSTANCE_TYPE_INFORMATION_REQUEST && 'Ask Family Request')
  const iconMap = {
    [INSTANCE_TYPE_MEDIA_GROUP]: '/icon-photo.svg',
    [INSTANCE_TYPE_PHOTO_ALBUM]: '/icon-photo.svg',
    [INSTANCE_TYPE_ARTICLE]: '/icon-article.svg',
  }

  const displayName = formatIndividualName(authorUser)

  return (
    <CardHeaderRoot>
      <SubTitleContainer>
        <Typography variant="subtitle4">{displayName}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Icon src={iconMap[type]} />
        </Box>
      </SubTitleContainer>
      {typeTitle === 'Photo' ? null : (
        <Box sx={{ mt: 1, mb: 1 }}>
          <Typography variant="h4" color="primary" sx={{ fontSize: 22 }}>
            {typeTitle}
          </Typography>
        </Box>
      )}
    </CardHeaderRoot>
  )
}

export default ContentCardHeader
