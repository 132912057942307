import React, { useEffect } from 'react'
import { useActionDispatcher } from 'src/modules/app'
import { Formik } from 'formik'
import FormikSelect from '../ui/FormikSelect'
import { Button, Form, FormikTextField, FIELD_REQUIRED } from 'src/modules/ui'
import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLinks from './FormLinks'
import FormLink from './FormLink'
import { ACTION_LOGIN } from '../app/appConstants'
import { patchProfileAndState } from './authSlice'
import * as Yup from 'yup'
import { sendPageview, sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

export const NewUserSurvey = () => {
  const options = [
    { label: 'Please select', key: '' }, // need to set displayEmpty=true
    { label: 'Twitter / X', key: 'TWITTER' },
    { label: 'Facebook', key: 'FACEBOOK' },
    { label: 'Magazine', key: 'MAGAZINE' },
    { label: 'Society', key: 'SOCIETY' },
    { label: 'Online forum', key: 'ONLINE_FORUM' },
    { label: 'Word of mouth', key: 'WORD_OF_MOUTH' },
    { label: 'Other...', key: 'OTHER' },
  ]

  const dispatchPatchProfileAndState = useActionDispatcher(patchProfileAndState)

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__SIGNUP_SURVEY)
  }, [])

  const handleNewUserSurveySubmit = async ({ source, other }, errors) => {
    const newSource = other ?? source
    sendEvent(ga4Events.SIGNUP_SURVEY_SUBMITTED)
    await dispatchPatchProfileAndState({ newUserSurveySource: newSource })
  }

  const handleNewUserSurveySkip = async () => {
    sendEvent(ga4Events.SIGNUP_SURVEY_SKIPPED)
    await dispatchPatchProfileAndState({ newUserSurveySource: 'SKIPPED' })
  }

  const validationSchema = Yup.object().shape({
    source: Yup.string().required(FIELD_REQUIRED),
    other: Yup.string().when('source', {
      is: 'OTHER',
      then: Yup.string().required(FIELD_REQUIRED),
    }),
  })

  return (
    <Formik
      initialValues={{}}
      onSubmit={handleNewUserSurveySubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <>
          <FormHeader
            title="Thanks for confirming your email"
            subtitle="Lastly, how did you hear about us?"
          />
          <Form>
            <>
              <FormikSelect
                options={options}
                value={formik.values.source ?? ''}
                name="source"
                onChange={v => formik.setFieldValue('source', v)}
                idPrefix="new-user-source"
                displayEmpty={true}
                sx={{}}
              />
              {formik.values.source === 'OTHER' && (
                <>
                  <FormikTextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label="Please let us know how you heard about us?"
                    name="other"
                  />
                </>
              )}
              <FormActions style={{ alignItems: 'center' }}>
                <FormLinks>
                  <div>
                    <FormLink onClick={handleNewUserSurveySkip}>Skip</FormLink>
                  </div>
                </FormLinks>
                <Button
                  permissionAction={ACTION_LOGIN}
                  onClick={formik.handleSubmit}
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </FormActions>
            </>
          </Form>
        </>
      )}
    </Formik>
  )
}
