import TreeViewer from './TreeViewer'
import {
  INITIAL_ZOOM_EXPLORE_MODE,
  INITIAL_ZOOM_NAVIGATOR_MODE,
  SIZE_MODE_FIT_SCREEN,
} from './constants'

const ExploreTreeViewer = ({
  nodes,
  links,
  nLayers,
  selectedIndividualIds,
  onCreateSubTree,
  focusMode,
  navigatorMode,
  selectMenuConfig,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  allowCreateSubTree = false,
  subTreeCaption,
  onCloseViewerModal,
  isSubTree,
}) => {
  return (
    <TreeViewer
      allowCreateSubTree={allowCreateSubTree}
      allowDragAndZoom={true}
      exploreNodeOnClick={navigatorMode ? true : false}
      focusMode={focusMode}
      initialZoom={
        navigatorMode ? INITIAL_ZOOM_NAVIGATOR_MODE : INITIAL_ZOOM_EXPLORE_MODE
      }
      links={links}
      navigateToNodeOnDoubleClick={navigateToNodeOnDoubleClick}
      navigateToNodeOnDoubleClickHandler={navigateToNodeOnDoubleClickHandler}
      nLayers={nLayers}
      nodes={nodes}
      onCreateSubTree={onCreateSubTree}
      selectedIndividualIds={selectedIndividualIds}
      showNodeContextMenu={navigatorMode ? false : true}
      selectMenuConfig={selectMenuConfig}
      sizeMode={SIZE_MODE_FIT_SCREEN}
      subTreeCaption={subTreeCaption}
      onCloseViewerModal={onCloseViewerModal}
      isSubTree={isSubTree}
    />
  )
}

export default ExploreTreeViewer
