import { MenuItem } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetTreeAndNavigate } from '../app'

const ChangeHomePersonButton = ({ fromTreeSlug }) => {
  const history = useHistory()
  const setTree = useSetTreeAndNavigate()

  const navigateToChangeHomePerson = async () => {
    await setTree(fromTreeSlug)

    history.push(`/${fromTreeSlug}/change-home-person`)
  }

  return (
    <MenuItem onClick={navigateToChangeHomePerson} sx={{ ml: 1 }}>
      Change home person
    </MenuItem>
  )
}

export default ChangeHomePersonButton
