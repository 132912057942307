export const MEDIA_MARGIN_RIGHT = 32

export const MEDIA_COLUMN_ALIGNMENT_LEFT = 'LEFT'
export const MEDIA_COLUMN_ALIGNMENT_RIGHT = 'RIGHT'

export const MEDIA_ROW_COVER_GAP_BETWEEN_IMAGES = 24

export const MEDIA_ROW_HEIGHT_MEDIUM = 'MEDIUM'
export const MEDIA_ROW_HEIGHT_SMALL = 'SMALL'
export const MEDIA_ROW_HEIGHT_LARGE = 'LARGE'

export const MEDIA_ROW_ALIGNMENT_LEFT = 'LEFT'
export const MEDIA_ROW_ALIGNMENT_RIGHT = 'RIGHT'
export const MEDIA_ROW_ALIGNMENT_CENTER = 'CENTER'
export const MEDIA_ROW_ALIGNMENT_COVER = 'COVER'

export const MEDIA_ROW_HEIGHT_MAP = {
  [MEDIA_ROW_HEIGHT_SMALL]: 200,
  [MEDIA_ROW_HEIGHT_MEDIUM]: 300,
  [MEDIA_ROW_HEIGHT_LARGE]: 400,
}
