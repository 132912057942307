import { Tabs } from 'src/modules/ui'
import Users from './Users'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

const AccountAdmin = () => {
  const slug = useSelector(selectAuthorisedTreeSlug)
  const routes = [
    {
      navLabel: `Registered archive members`,
      path: `/${slug}/user-admin/`,
      route: `/${slug}/user-admin/`,
      component: Users,
      exact: true,
    },
  ]

  return <Tabs routes={routes} disableSearch />
}

export default AccountAdmin
