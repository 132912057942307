import { PhotoAlbumCard } from 'src/modules/content/PhotoAlbum'
import { generatePublicLinkForObject } from 'src/modules/app/links'
import { useContext } from 'react'
import { PublicContext } from '../contexts'

const PublicPhotoAlbum = ({ content }) => {
  const { id, type } = content
  const { treeSlug } = useContext(PublicContext)
  const albumRoute = generatePublicLinkForObject(treeSlug, type, id)

  return <PhotoAlbumCard {...{ content, albumRoute }} />
}

export default PublicPhotoAlbum
