import { combineReducers } from 'redux'

import content from './content/contentSlice'
import photo from './photo/photoSlice'
import tree from './tree/treeSlice'
import page from './page/pageSlice'

const reducer = combineReducers({
  content,
  page,
  photo,
  tree,
})

export default reducer
