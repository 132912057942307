import React from 'react'

import HomeContentList from './HomeContentList'

import { INSTANCE_TYPE_DOCUMENT } from '../app/links'

const Documents = () => {
  return (
    <>
      <HomeContentList state="PUBLISHED" type={INSTANCE_TYPE_DOCUMENT} />
    </>
  )
}

export default Documents
