import { useDispatch, useSelector } from 'react-redux'

import { selectUserHomeIndividual } from 'src/modules/auth/authSlice'
import { TreeViewerForIndividual } from 'src/modules/viewer'
import TreeLoadingGuard from '../viewer/TreeLoadingGuard'
import { fetchIndividualsForTarget } from '../viewer/viewerSlice'
import { useEffect } from 'react'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

const HomeTree = ({ postClickHandler }) => {
  const dispatch = useDispatch()
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const homeIndividual = useSelector(selectUserHomeIndividual)

  useEffect(() => {
    if (homeIndividual) {
      dispatch(
        fetchIndividualsForTarget({ treeSlug, target: homeIndividual.id })
      )
    } else {
      console.error(`HomeTree rendered when homeIndividual is not set`)
    }
  }, [treeSlug, homeIndividual, dispatch])

  return (
    <TreeLoadingGuard partialCallKey={homeIndividual?.id} title=" " mt={4}>
      {homeIndividual && (
        <TreeViewerForIndividual
          individualId={homeIndividual.id}
          postClickHandler={postClickHandler}
          containingComponent="HomeTree"
        />
      )}
    </TreeLoadingGuard>
  )
}

export default HomeTree
