import { useCallback, useEffect, useRef, useState } from 'react'

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { useActionDispatcher } from 'src/modules/app'
import CoverMediaRow, {
  useCalculateCoverHeight,
} from 'src/modules/content/CoverMediaRow'
import SimpleCarousel, {
  SimpleCarouselContext,
} from 'src/modules/ui/SimpleCarousel'

import {
  MEDIA_ROW_ALIGNMENT_CENTER,
  MEDIA_ROW_ALIGNMENT_COVER,
  MEDIA_ROW_ALIGNMENT_LEFT,
} from './contentBlockConstants'
import { updateContentBlock } from './writeArticleSlice'
import { reorderPhotos } from '../photo/photoSlice'
import {
  CONTROL_POSITION_LEFT_RIGHT,
  CONTROL_POSITION_TOP_BOTTOM,
  DraggablePhotoWithFloatingControls,
} from './PhotoWithFloatingControls'
import { MODE_EDIT } from '../ui/SimpleCarousel'
import ResizePhotoGroupControl from './ResizePhotoGroupControl'
import { Box, useTheme } from '@mui/material'
import { Caption } from './EditablePhoto'
import { MEDIA_ROW_MIN_HEIGHT } from './MediaRow'
import { RESIZE_DRAG_SIZE } from './PhotoColumn'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

export const MEDIA_ARRANGEMENT_COLUMN = 'COLUMN'
export const MEDIA_ARRANGEMENT_ROW = 'ROW'

const usePhotoStyles = makeStyles(theme => ({
  articlePhotoCaption: {
    '&:hover .edit': {
      visibility: 'visible',
    },
  },
}))

export const SortablePhotos = ({
  contentId,
  contentBlockId,
  height,
  media,
  arrangement = MEDIA_ARRANGEMENT_ROW,
  handleAddMedia,
  mediaRowAlignment,
  mediaRowHeight,
  multiRow = false,
  presetTargets,
  mediaColumnSize,
  columnWidth,
  mediaRowMeta,
  addMediaHideTags = false,
  addMediaDefaultAllowNoTags = false,
  addMediaSetCategory,
  photoButtons,
  placeholderStyles,
}) => {
  const classes = usePhotoStyles()

  const [photoCache, setPhotoCache] = useState(media)
  const coverRef = useRef()
  const theme = useTheme()
  const coverHeight = useCalculateCoverHeight(coverRef.current, photoCache)
  const dispatchUpdateContentBlock = useActionDispatcher(updateContentBlock)

  const handleCaptionWidth = () => {
    const photos = document.getElementsByClassName(
      `articlePhoto-${contentBlockId}`
    )
    const captions = document.getElementsByClassName(
      `articlePhotoCaption-${contentBlockId}`
    )
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i]
      const caption = captions[i]

      if (caption) {
        caption.style.width = `${photo.offsetWidth}px`
      }
    }
  }

  useEffect(() => {
    setPhotoCache(media)
  }, [media])

  const handleResize = mouseDownEvent => {
    const photos = document.getElementsByClassName(
      `articlePhoto-${contentBlockId}`
    )
    const captions = document.getElementsByClassName(
      `articlePhotoCaption-${contentBlockId}`
    )

    const initHeight = photos[0].offsetHeight

    const initialMousePos = mouseDownEvent.clientY
    let newHeight

    function onMouseMove(mouseMoveEvent) {
      const mousePos = mouseMoveEvent.clientY
      const heightDif = mousePos - initialMousePos
      newHeight = (initHeight + heightDif).toFixed(2)

      if (newHeight < MEDIA_ROW_MIN_HEIGHT) newHeight = MEDIA_ROW_MIN_HEIGHT

      for (let i = 0; i < photos.length; i++) {
        const photo = photos[i]
        const caption = captions[i]

        photo.style.height = `${newHeight}px`
        if (caption) {
          caption.style.width = `${photo.offsetWidth}px`
        }
      }
    }

    function onMouseUp() {
      dispatchUpdateContentBlock({
        contentId,
        id: contentBlockId,
        mediaRowHeight: 'CUSTOM',
        mediaRowMeta: newHeight,
      })
      document.body.removeEventListener('mousemove', onMouseMove)
    }
    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  const dispatchReorderPhotos = useActionDispatcher(reorderPhotos)
  const handleDragEnd = useCallback(
    event => {
      let {
        active: { id: activeId },
        over: { id: overId },
      } = event
      if (activeId === overId) {
        return
      }
      activeId = activeId.split('-')[0]
      overId = overId.split('-')[0]
      const oldIndex = photoCache.findIndex(({ id }) => id === activeId)
      const newIndex = photoCache.findIndex(({ id }) => id === overId)
      const newPhotoCache = arrayMove(photoCache, oldIndex, newIndex)
      const putBelowPhotoId = newIndex
        ? newPhotoCache[newIndex - 1].id
        : undefined
      setPhotoCache(newPhotoCache)
      dispatchReorderPhotos({
        contentId,
        id: contentBlockId,
        photo: activeId,
        putBelowPhoto: putBelowPhotoId,
      })
    },
    [contentId, dispatchReorderPhotos, contentBlockId, photoCache]
  )

  const isRow = arrangement === MEDIA_ARRANGEMENT_ROW
  const isCover = isRow && mediaRowAlignment === MEDIA_ROW_ALIGNMENT_COVER
  const strategy = isRow
    ? horizontalListSortingStrategy
    : verticalListSortingStrategy

  const nonAmbiguousPhotoId = photo => `${photo.id}-${photo.order}`

  const itemsById = Object.fromEntries(
    photoCache.map(photo => [nonAmbiguousPhotoId(photo), photo])
  )

  const photoComponents = Object.entries(itemsById).map(
    ([photoDraggableId, photo]) => {
      const props = {
        id: photoDraggableId,
        contentBlockId,
        contentId,
        controlPosition: isRow
          ? CONTROL_POSITION_LEFT_RIGHT
          : CONTROL_POSITION_TOP_BOTTOM,
        handleSelectPhoto: handleAddMedia,
        photo,
      }
      if (isCover) {
        return (
          <DraggablePhotoWithFloatingControls
            {...props}
            height={coverHeight}
            key={nonAmbiguousPhotoId(photo)}
            presetTargets={presetTargets}
            placeholderStyles={placeholderStyles}
          />
        )
      } else if (isRow) {
        return (
          <SimpleCarouselContext.Consumer key={nonAmbiguousPhotoId(photo)}>
            {({ handleLoaded }) => (
              <DraggablePhotoWithFloatingControls
                {...props}
                height={height}
                handleLoaded={handleLoaded}
                presetTargets={presetTargets}
                isCarousel={!multiRow} //false will show the caption below the photo
                imageClassName={`articlePhoto-${contentBlockId}`}
                addMediaHideTags={addMediaHideTags}
                addMediaDefaultAllowNoTags={addMediaDefaultAllowNoTags}
                addMediaSetCategory={addMediaSetCategory}
                photoButtons={photoButtons}
                placeholderStyles={placeholderStyles}
              />
            )}
          </SimpleCarouselContext.Consumer>
        )
      } else {
        return (
          <DraggablePhotoWithFloatingControls
            {...props}
            key={nonAmbiguousPhotoId(photo)}
            presetTargets={presetTargets}
            mediaColumnSize={mediaColumnSize}
            columnWidth={columnWidth}
            placeholderStyles={placeholderStyles}
          />
        )
      }
    }
  )

  const captions = Object.entries(itemsById).map(
    ([photoDraggableId, photo]) => {
      return (
        <SimpleCarouselContext.Consumer key={nonAmbiguousPhotoId(photo)}>
          {() => (
            <Caption
              className={clsx(
                `articlePhotoCaption-${contentBlockId}`,
                classes.articlePhotoCaption
              )}
              photo={photo}
              contentId={contentId}
              contentBlockId={contentBlockId}
              handleCaptionWidth={handleCaptionWidth}
            />
          )}
        </SimpleCarouselContext.Consumer>
      )
    }
  )
  const sensors = useSensors(useSensor(PointerSensor))

  const justifyCarouselContent =
    mediaRowAlignment === MEDIA_ROW_ALIGNMENT_LEFT
      ? 'flex-start'
      : mediaRowAlignment === MEDIA_ROW_ALIGNMENT_CENTER
      ? 'center'
      : 'flex-end'

  let markup = (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={Object.keys(itemsById)} strategy={strategy}>
        {photoComponents}
      </SortableContext>
    </DndContext>
  )
  if (isCover) {
    markup = (
      <CoverMediaRow ref={coverRef}>{!!coverHeight && markup}</CoverMediaRow>
    )
  } else if (isRow) {
    markup = (
      <SimpleCarousel
        mediaRowAlignment={mediaRowAlignment}
        multiRow={multiRow}
        mediaRowHeight={mediaRowHeight}
        mode={MODE_EDIT}
        handleCaptionWidth={handleCaptionWidth}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: justifyCarouselContent,
              flexWrap: multiRow ? 'wrap' : 'nowrap',
            }}
          >
            {markup}
          </Box>
          <div
            className="sliderContainer"
            style={{
              width: theme.maxWidth - 100,
              maxWidth: '90vw',
              position: 'sticky',
              left: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ResizePhotoGroupControl
              onMouseDown={handleResize}
              height={RESIZE_DRAG_SIZE}
              show={true}
              alignment="horizontal"
            />
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: justifyCarouselContent,
              flexWrap: multiRow ? 'wrap' : 'nowrap',
            }}
          >
            {captions}
          </Box>
        </Box>
      </SimpleCarousel>
    )
  }

  return markup
}
