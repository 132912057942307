import React from 'react'

import MediaDropzone from './MediaDropzone'
import { ACCEPT_UPLOAD_ALL } from './index'

const UploadAlbum = ({ previewTiles, setPreviewTiles, hideCaptions }) => {
  return (
    <MediaDropzone
      previewTiles={previewTiles}
      onChangePreviewTiles={setPreviewTiles}
      hideCaptions={hideCaptions}
      acceptFiles={ACCEPT_UPLOAD_ALL}
    />
  )
}

export default UploadAlbum
