/**
 * This file contains operations for navigating the set of
 * all individuals and the links between them.
 */
import sortBy from 'lodash/sortBy'
import { clearLinegageCache } from './lineage'

export function updatePhotos(state, partialRawPhotos, overwrite = false) {
  const partialRawIndividualPhotoMap = state.partialRawIndividualPhotoMap
  partialRawPhotos?.forEach(i => {
    if (!partialRawIndividualPhotoMap[i.id] || overwrite) {
      partialRawIndividualPhotoMap[i.id] = i
    }
  })

  state.partialRawIndividualPhotoMap = partialRawIndividualPhotoMap
  updateIndividualPhotos(state, Object.values(partialRawIndividualPhotoMap))
}

const shouldUpdateIndividual = (newIndividual, partialRawIndividualMap) => {
  if (!newIndividual || !partialRawIndividualMap) {
    return false
  }

  if (!partialRawIndividualMap[newIndividual?.id]) {
    return true
  }

  const existingIndividual = partialRawIndividualMap[newIndividual.id]
  if (!existingIndividual.bioMother && newIndividual.bioMother) {
    return true
  }

  if (!existingIndividual.bioFather && newIndividual.bioFather) {
    return true
  }

  if (newIndividual.photo && !existingIndividual.photo) {
    return true
  }

  return false
}

export function addFamilies(state, newFamilies) {
  if (!newFamilies || newFamilies.length === 0) {
    return
  }

  newFamilies.forEach(family => {
    const existingFam = state.families.find(({ id }) => id === family.id)
    if (!existingFam) {
      state.families.push(family)
    }
  })
}

export function addStubNode(state, payload) {
  if (state.stubNodes[payload]) {
  } else {
    state.stubNodes[payload] = payload
  }
}

export function updateNodeDirectory(state, partialRawIndviduals) {
  if (!partialRawIndviduals) {
    return
  }

  let updated = false

  const partialRawIndividualMap = state.partialRawIndividualMap
  partialRawIndviduals.forEach(i => {
    if (shouldUpdateIndividual(i, partialRawIndividualMap)) {
      partialRawIndividualMap[i.id] = i
      updated = !updated ? true : updated
    }
  })

  if (updated) {
    state.partialRawIndividualMap = partialRawIndividualMap
    initiateNodeDirectory(state, Object.values(partialRawIndividualMap))
  }
}

export function initiateNodeDirectory(state, rawIndividuals) {
  clearLinegageCache()
  if (!rawIndividuals) {
    return
  }

  // Set defaults for important fields
  rawIndividuals = rawIndividuals.map(i => ({
    givenName: '',
    surname: '',
    knownAs: '',
    alias: '',
    yearOfBirth: null,
    yearOfDeath: null,
    spouses: [],
    unions: [],
    children: [],
    ...i,
  }))
  // Store new copies of the individuals in the node directory before we modify them.
  state.rawIndividuals = rawIndividuals.map(i => ({ ...i }))
  const tempLookup = createNodeDirectory(rawIndividuals)
  const processedNodes = processIndividualsAddUnions(tempLookup, rawIndividuals)
  state.nodeDirectory = createNodeDirectory(processedNodes)
  state.livingIndividuals = livingIndividuals(rawIndividuals)
  state.allIndividuals = rawIndividuals
}

export function updateIndividualPhotos(state, individualPhotos) {
  if (!individualPhotos || individualPhotos.length === 0) {
    return
  }

  const idPhotoMap = {}
  for (const individualPhoto of individualPhotos || []) {
    idPhotoMap[individualPhoto.id] = individualPhoto.photo
    const individual = state.nodeDirectory[individualPhoto.id]
    if (individual) {
      state.nodeDirectory[individualPhoto.id].photo = individualPhoto.photo
    } else {
      console.error(
        'updating photos = could not find inividual ' + individualPhoto?.id
      )
    }
  }
  for (const rawIndividual of state.rawIndividuals) {
    if (idPhotoMap[rawIndividual.id]) {
      rawIndividual.photo = idPhotoMap[rawIndividual.id]
      state.partialRawIndividualMap[rawIndividual.id] = rawIndividual
    }
  }
}

export function unionID(spouseID1, spouseID2) {
  const sorted = [spouseID1, spouseID2].sort()
  return `${sorted[0]}-${sorted[1]}`
}

export function unionForParentsOfIndividual(individualNode, nodeDirectory) {
  const uID = unionID(individualNode.bioMother, individualNode.bioFather)
  // May be undefined if individual does not have known parent IDs
  return nodeDirectory[uID]
}

function unknownSpouseID(individualID) {
  return `unknown-spouse-of-${individualID}`
}

export function createUnknownSpouse(individual) {
  // Since the unknown is created purely as a stand in biological parent,
  // we can safely assume opposite sex.

  const otherGender = individual.gender === 'M' ? 'F' : 'M' // unknown defaults to mother
  const unknown = {
    id: unknownSpouseID(individual.id),
    spouses: [individual.id],
    isUnion: false,
    family: individual.family,
    gender: otherGender,
    givenName: 'Unknown',
    surname: '',
    unions: [],
    children: [],
    yearOfBirth: null,
    yearOfDeath: null,
  }

  // this function is called from initiateNodeDirectory() which is called when various things change
  // so this can be called more than once on the same individual - don't duplicate the spouse link
  if (!individual.spouses.includes(unknown.id)) {
    individual.spouses = [...individual.spouses, unknown.id]
  }
  return unknown
}

export function isUnknownIndividual(individualId) {
  return !individualId || individualId.includes('unknown')
}
export function isHiddenIndividual(individual) {
  if (
    !individual.givenName &&
    !individual.surname &&
    !individual.yearOfBirth &&
    !individual.yearOfDeath
  )
    return true
}

export function getMother(individual, nodeDirectory) {
  if (individual) {
    return (
      individual.bioMother &&
      !isUnknownIndividual(individual.bioMother) &&
      nodeDirectory[individual.bioMother]
    )
  } else {
    return null
  }
}

export function getFather(individual, nodeDirectory) {
  if (individual) {
    return (
      individual.bioFather &&
      !isUnknownIndividual(individual.bioFather) &&
      nodeDirectory[individual.bioFather]
    )
  } else {
    return null
  }
}

export function createUnion(uID, individual, spouse, children) {
  return {
    id: uID,
    isUnion: true,
    between: [individual.id, spouse.id],
    children: children,
  }
}

export function createNodeDirectory(nodes) {
  const directory = {}
  for (let node of nodes) {
    directory[node.id] = node
  }
  return directory
}

/**
 * Given a list of interlinked individuals, convert parent-child relationships
 * so that they link via a "union node".
 *
 * @param {*} nodeDirectory
 * @param {*} individuals
 * @returns Array of individuals and unions
 */
export function processIndividualsAddUnions(nodeDirectory, individuals) {
  individuals = sortBy(individuals, 'yearOfBirth')

  const parentRelationships = []
  for (let individual of individuals) {
    individual.isUnion = false
    individual.unions = individual.unions || []

    if (individual.bioFather && individual.bioMother) {
      parentRelationships.push([
        [individual.bioFather, individual.bioMother],
        individual.id,
      ])
    } else if (individual.bioFather) {
      const unknownMotherID = unknownSpouseID(individual.bioFather)
      parentRelationships.push([
        [individual.bioFather, unknownMotherID],
        individual.id,
      ])
    } else if (individual.bioMother) {
      const unknownFatherID = unknownSpouseID(individual.bioMother)
      parentRelationships.push([
        [unknownFatherID, individual.bioMother],
        individual.id,
      ])
    }
    // Account for childless couples
    for (const spouseID of individual.spouses) {
      parentRelationships.push([[individual.id, spouseID]])
    }
  }

  const unions = new Map([])
  for (let [[fatherID, motherID], childID] of parentRelationships) {
    let father = nodeDirectory[fatherID]
    let mother = nodeDirectory[motherID]

    if (!father && !mother) {
      continue
    }

    if (!father && mother) {
      father = createUnknownSpouse(mother)
      nodeDirectory[fatherID] = father
    }

    if (!mother) {
      mother = createUnknownSpouse(father)
      nodeDirectory[motherID] = mother
    }

    const uID = unionID(fatherID, motherID)
    !mother.unions.includes(uID) && mother.unions.push(uID)
    !father.unions.includes(uID) && father.unions.push(uID)

    if (!unions.has(uID)) {
      unions.set(
        uID,
        createUnion(uID, nodeDirectory[fatherID], nodeDirectory[motherID], [])
      )
    }
    if (childID) {
      const child = nodeDirectory[childID]
      child.bioMother = mother.id
      child.bioFather = father.id
      unions.get(uID).children.push(childID)
    }
  }

  // At the end of this process, *all* children relationships should be
  // expressed on unions rather than from individual to individual. Individuals
  // will then link to the unions that produced their children.

  // The full graph of nodes must include both the individual and union nodes:
  return Array.from(Object.values(nodeDirectory)).concat(
    Array.from(unions.values())
  )
}

/**
 * Each time called, add another generation to the end of list by looking
 * through the parents of the last one.
 *
 * @param {*} generations Array of arrays of individuals in each generation
 * @param {*} nodeDirectory
 */
export function recurseBackThroughGeneration(
  generations,
  nodeDirectory,
  seenIndividualIDs
) {
  const previousGeneration = generations[generations.length - 1] || []
  const thisGeneration = []
  for (let individual of previousGeneration) {
    if (individual === undefined) {
      continue
    }
    const bioMother = nodeDirectory[individual.bioMother]
    if (bioMother) {
      thisGeneration.push(bioMother)
    }
    const bioFather = nodeDirectory[individual.bioFather]
    if (bioFather) {
      thisGeneration.push(bioFather)
    }
  }
  if (thisGeneration.length) {
    // Check against cycles that could cause infinite recursion
    const newIndividuals = thisGeneration.filter(
      ({ id }) => !seenIndividualIDs.has(id)
    )
    for (let individual of newIndividuals) {
      seenIndividualIDs.add(individual.id)
    }
    generations.push(newIndividuals)
    recurseBackThroughGeneration(generations, nodeDirectory, seenIndividualIDs)
  }
}

/**
 * Deduplicate the surnames represented by individuals in an array of
 * generations.
 *
 * Only include the surname on the most recent generation on which it
 * appears.
 *
 * [
 *   [{surname: 'Roy'}, {surname: 'Rogers'}],
 *   [{surname: 'Roy'}, {surname: 'Benton'}],
 * ]
 *
 * Will produce:
 *
 * [['Roy', 'Rogers'], ['Benton']]
 *
 * @param {} ancestorsByGeneration
 * @returns
 */
export function squashGenerationsToNamesFirstSeenOnly(
  ancestorsByGeneration,
  familiesById
) {
  const seenFamilyId = new Set([])
  return ancestorsByGeneration.map(generation => {
    const generationSurnames = []
    for (let individual of generation) {
      const familyId = individual.family
      if (!familyId) {
        continue
      }
      if (!seenFamilyId.has(familyId)) {
        seenFamilyId.add(familyId)
        const family = familiesById[familyId]
        //not expecting to get an undefined family here but has been kicking up.  Likley another error or missing call but doing a quick fix for now
        if (family) {
          generationSurnames.push({
            surname: family.surname,
            family: familyId,
          })
        }
      }
    }
    return generationSurnames
  })
}

/**
 * Search the past of the selected invividual to find the surnames
 * of their previous generations, split by mother and father's lines.
 * @param {*} nodeDirectory
 * @param {*} startFromIndividual
 * @returns
 */
export function createPyramid(nodeDirectory, startFromIndividual, allFamilies) {
  const bioMother = nodeDirectory[startFromIndividual?.bioMother]
  const bioFather = nodeDirectory[startFromIndividual?.bioFather]
  const motherAncestors = bioMother ? [[bioMother]] : [[]]
  const fatherAncestors = bioFather ? [[bioFather]] : [[]]
  const familiesById = createNodeDirectory(allFamilies)

  recurseBackThroughGeneration(
    motherAncestors,
    nodeDirectory,
    new Set([bioMother?.id])
  )
  recurseBackThroughGeneration(
    fatherAncestors,
    nodeDirectory,
    new Set([bioFather?.id])
  )

  const motherSide = squashGenerationsToNamesFirstSeenOnly(
    motherAncestors,
    familiesById
  )
  const fatherSide = squashGenerationsToNamesFirstSeenOnly(
    fatherAncestors,
    familiesById
  )

  const fathersFamily = fatherSide[0].length ? fatherSide[0][0] : undefined
  const mothersFamily = motherSide[0].length ? motherSide[0][0] : undefined

  return {
    mothersFamily,
    fathersFamily,
    motherSide,
    fatherSide,
  }
}

export function familiesDescendedFrom(nodeDirectory, individualNode, families) {
  const pyramid = createPyramid(nodeDirectory, individualNode, families)
  const allGenerations = pyramid.motherSide.concat(pyramid.fatherSide)

  const allFamilies = []
  for (let generation of allGenerations) {
    for (let family of generation) {
      allFamilies.push(family)
    }
  }

  return sortBy(allFamilies, 'surname')
}

/**
 Via a depth first recursive search on the tree of parental relationships,
 modify generationalLinks to contain all the parental links from currentNode to
 their first ancestor with the requested surname, plus all further direct
 ancestors with the given surname.

 Example:
 Fred A --- Mary B
 |
 Jim M --- Alice A
 |
 Rex M --- Sally S
 |
 Ira M

 Calling `findGenerationalLinksToFamily(links, nodeDir, <Ira M>, 'A')`
 (where `links` is an empty list that will be modified) will return True.

 After the call has finished recursing, `links` will contain:
 [
 <Fred A>, <-- Oldest ancestor first
 <Alice A>,
 <Rex M>, <-- Doesn't have the surname himself, but links Ira to it
 <Ira M>,
 ]

 If we searched for a family id that wasn't present, the list would be
 empty at the end of the recursion, and the original call would return
 False.
 */
export function findGenerationalLinksToFamily(
  generationalLinks,
  nodeDirectory,
  currentNode,
  familyIdSought
) {
  if (currentNode === undefined) {
    return false
  }
  if (generationalLinks.find(individual => individual.id === currentNode.id)) {
    // Infinite loop prevention
    return false
  }

  generationalLinks.unshift(currentNode)
  const bioMother = nodeDirectory[currentNode.bioMother]
  const bioFather = nodeDirectory[currentNode.bioFather]

  // Recursively search the mother and father's line for matching surnames
  if (
    findGenerationalLinksToFamily(
      generationalLinks,
      nodeDirectory,
      bioFather,
      familyIdSought
    ) ||
    findGenerationalLinksToFamily(
      generationalLinks,
      nodeDirectory,
      bioMother,
      familyIdSought
    )
  ) {
    return true
  } else if (currentNode.family === familyIdSought) {
    // If neither the mother of father match, but currentNode does, we have
    // found the deepest match.

    // If male parent is defined but not from the same family, add them
    if (bioFather) {
      generationalLinks.unshift(bioFather)
    }

    return true
  }

  // If neither mother, father or self match, abort this attempt
  generationalLinks.shift()
  return false
}

/**
 * Given a sequence of individuals that represent the links between generations
 * of a family subtree, ordered chronologically, fill out the tree with spouses
 * and children.
 *
 * @param {*} generationalLinks
 * @param {*} nodeDirectory
 * @returns an array of individuals that should be in the tree.
 */
export function defaultVisibleFromGenerationalLinks(
  generationalLinks,
  nodeDirectory,
  familyIdSought
) {
  const visibleNodeIDs = new Set()

  let finalIndividual = generationalLinks[generationalLinks.length - 1]

  for (let individual of generationalLinks) {
    visibleNodeIDs.add(individual.id)
    // Don't add unions for final individual
    if (individual !== finalIndividual) {
      for (let unionID of individual.unions || []) {
        visibleNodeIDs.add(unionID)
        const union = nodeDirectory[unionID]
        if (union) {
          for (let spouseID of union.between) {
            visibleNodeIDs.add(spouseID)
          }
        } else {
          console.error(
            `could not find union with id '${unionID}' (individualNode.id: '${individual.id}')`
          )
        }
      }
    }

    if (individual.family === familyIdSought) {
      const union = unionForParentsOfIndividual(individual, nodeDirectory)
      if (union) {
        for (let childID of union.children) {
          visibleNodeIDs.add(childID)
          const child = nodeDirectory[childID]
          for (let unionID of child.unions || []) {
            visibleNodeIDs.add(unionID)
            for (let spouseID of nodeDirectory[unionID].between) {
              visibleNodeIDs.add(spouseID)
            }
          }
        }
      }
    }
  }

  return Array.from(visibleNodeIDs).map(id => nodeDirectory[id])
}

export function unionIfExists(nodeDirectory, spouseID1, spouseID2) {
  const spouses = [spouseID1, spouseID2].filter(id => !!id)
  if (spouses.length === 2) {
    const uID = unionID(...spouses)
    return nodeDirectory[uID]
  }
}

/**
 * Supports a specific presentation of a family used e.g. in the
 * 'select myself' view.
 *
 * @param {*} nodeDirectory
 * @param {*} individualNode
 * @returns
 */
export function parentsSiblingsAndChildrenOfIndividual(
  nodeDirectory,
  individualNode
) {
  if (individualNode.isUnion) {
    throw Error('Individual node required')
  }
  const visibleNodeIDs = new Set()
  const coreLineageIDs = new Set([individualNode.id])
  exploreDownwardsRecursive(
    nodeDirectory,
    visibleNodeIDs,
    individualNode,
    1,
    coreLineageIDs
  )
  const parentUnion = unionIfExists(
    nodeDirectory,
    individualNode.bioFather,
    individualNode.bioMother
  )
  if (parentUnion) {
    visibleNodeIDs.add(parentUnion.id)
    for (let individualID of parentUnion.between) {
      visibleNodeIDs.add(individualID)
      coreLineageIDs.add(individualID)
    }
    for (let childID of parentUnion.children) {
      visibleNodeIDs.add(childID)
      coreLineageIDs.add(childID)
    }
  }

  return [
    Array.from(visibleNodeIDs).map(id => nodeDirectory[id]),
    coreLineageIDs,
  ]
}

export function earliestCredibleBirth() {
  const now = new Date()
  return now.getFullYear() - 110
}

export function livingIndividuals(individuals) {
  const earliestCredibleBirthYear = earliestCredibleBirth()
  const living = individuals.filter(individual => {
    if (individual.yearOfDeath) {
      return false
    }
    if (
      individual.yearOfBirth &&
      individual.yearOfBirth >= earliestCredibleBirthYear
    ) {
      return true
    }
    return !individual.yearOfBirth
  })
  return sortBy(living, ['givenName', 'surname'])
}

export function exploreDownwards(
  nodeDirectory,
  individualNode,
  generations = 2,
  coreLineageIDs
) {
  const visibleNodeIDs = new Set()
  exploreDownwardsRecursive(
    nodeDirectory,
    visibleNodeIDs,
    individualNode,
    generations,
    coreLineageIDs
  )
  return Array.from(visibleNodeIDs)
    .map(id => {
      const item = nodeDirectory[id]
      if (!item) {
        console.error(
          `exploreDownwards(): exploreDownwardsRecursive returned visibleNodeIds containing an nodeID '${id}' that is not in nodeDirectory`,
          nodeDirectory
        )
      }
      return item
    })
    .filter(item => item)
}

function exploreDownwardsRecursive(
  nodeDirectory,
  visibleNodeIDs,
  individualNode,
  generations,
  coreLineageIDs = new Set()
) {
  if (!individualNode) {
    return
  }

  coreLineageIDs.add(individualNode.id)
  visibleNodeIDs.add(individualNode.id)
  for (let unionID of individualNode.unions) {
    const union = nodeDirectory[unionID]

    if (union) {
      visibleNodeIDs.add(unionID)
      for (const spouseID of union.between) {
        visibleNodeIDs.add(spouseID)
      }

      if (generations > 0) {
        for (let childID of union.children) {
          visibleNodeIDs.add(childID)
          const child = nodeDirectory[childID]

          exploreDownwardsRecursive(
            nodeDirectory,
            visibleNodeIDs,
            child,
            generations - 1,
            coreLineageIDs
          )
        }
      }
    } else {
      console.error(
        `exploreDownwardsRecursive(): called with individualNode that has a union array entry of unionID '${unionID}' that is not in the nodeDirectory`,
        individualNode
      )
    }
  }
}

export function childrenNotWithNode(
  nodeDirectory,
  individualNode,
  excludeChildrenWithNode
) {
  const childrenIDs = []

  for (const spouseID of individualNode.spouses) {
    if (spouseID === excludeChildrenWithNode.id) {
      continue
    }

    const union = nodeDirectory[unionID(spouseID, individualNode.id)]

    if (union) {
      for (const stepChildID of union.children) {
        childrenIDs.push(stepChildID)
      }
    }
  }
  return childrenIDs.map(id => nodeDirectory[id])
}

export function exploreStepChildren(nodeDirectory, individualNode) {
  const additionalNodeIDs = new Set()

  for (const spouseID of individualNode.spouses) {
    const spouse = nodeDirectory[spouseID]
    for (let spouseSpouseID of spouse.spouses) {
      if (spouseSpouseID === individualNode.id) {
        continue
      }
      additionalNodeIDs.add(spouseSpouseID)
      const uID = unionID(spouseSpouseID, spouseID)
      additionalNodeIDs.add(uID)
      const union = nodeDirectory[uID]

      for (const stepChildID of union.children) {
        additionalNodeIDs.add(stepChildID)
        const stepChild = nodeDirectory[stepChildID]

        for (const stepChildSpouseID of stepChild.spouses) {
          additionalNodeIDs.add(stepChildSpouseID)
          const uID = unionID(stepChildSpouseID, stepChildID)
          additionalNodeIDs.add(uID)
          const union = nodeDirectory[uID]
          for (const stepGrandChildID of union.children) {
            additionalNodeIDs.add(stepGrandChildID)
          }
        }
      }
    }
  }
  return Array.from(additionalNodeIDs).map(id => nodeDirectory[id])
}

export function childrenWithSpouse(
  individual,
  significantOther,
  nodeDirectory
) {
  const uID = unionID(individual.id, significantOther.id)
  const union = nodeDirectory[uID]
  return union?.children?.map(id => nodeDirectory[id]) || []
}

export function siblingsWithEachParent(individual, nodeDirectory) {
  const siblings = []
  const mother = nodeDirectory[individual.bioMother]
  const father = nodeDirectory[individual.bioFather]

  if (mother && father) {
    siblings.push([
      [mother, father],
      childrenWithSpouse(mother, father, nodeDirectory).filter(
        child => child.id !== individual.id
      ),
    ])
  }

  if (mother) {
    for (const spouseID of mother.spouses) {
      if (spouseID === father?.id) {
        continue
      }
      const spouse = nodeDirectory[spouseID]
      siblings.push([
        [mother, spouse],
        childrenWithSpouse(mother, spouse, nodeDirectory),
      ])
    }
  }

  if (father) {
    for (const spouseID of father.spouses) {
      if (spouseID === mother?.id) {
        continue
      }
      const spouse = nodeDirectory[spouseID]
      siblings.push([
        [father, spouse],
        childrenWithSpouse(father, spouse, nodeDirectory),
      ])
    }
  }

  return siblings
}
