import React from 'react'
import { styled } from '@mui/material'

import { Button } from 'src/modules/ui'
import SelectFamilyOrIndividualPartial from './SelectFamilyOrIndividualPartial'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const PointerButton = styled(Button)({
  pointerEvents: 'visible',
})

const SearchTreeControl = ({
  handleDeselection,
  handleFamilySelected,
  handleIndividualSelected,
  handleCurrentIndividualSelected,
  nodeDirectory,
  allFamilies,
  setFamilyToPreview,
  isPublic = false,
}) => (
  <>
    <SelectFamilyOrIndividualPartial
      onDeselect={handleDeselection}
      onSelectFamily={handleFamilySelected}
      onSelectIndividual={handleIndividualSelected}
      nodeDirectory={nodeDirectory}
      allFamilies={allFamilies}
      setFamilyToPreview={setFamilyToPreview}
      isPublic={isPublic}
    />
    {handleCurrentIndividualSelected && (
      <PointerButton
        permissionAction={ACTION_ALL_ACCESS}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleCurrentIndividualSelected}
        sx={{ mt: 2 }}
      >
        Find Me
      </PointerButton>
    )}
  </>
)

export default SearchTreeControl
