import React, { useEffect, useState } from 'react'
import { Box, Stack, Tab } from '@mui/material'

import { Container, Typography } from 'src/modules/ui'

import ProfileForm from './ProfileForm'
import ChangePasswordForm from './ChangePasswordForm'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button } from '../ui'
import { useHistory } from 'react-router-dom'
import { currencyMap } from '../app/Pricing'
import { fetchCustomerPortal } from '../app/appSlice'
import { useActionDispatcher, useGetSubscription, useIsFreeTrial } from '../app'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const YourDetails = () => (
  <Box>
    <Stack spacing={6}>
      <div>
        <ProfileForm />
      </div>
      <div>
        <Typography variant="h4" color="textSecondary">
          Your Password
        </Typography>
        <ChangePasswordForm />
      </div>
    </Stack>
  </Box>
)

const subscriptionIntervalMap = {
  month: 'pcm',
  year: 'pa',
}
const AccountDetails = () => {
  const dispatchFetchCustomerPortal = useActionDispatcher(fetchCustomerPortal)
  const history = useHistory()
  const subscription = useGetSubscription()

  const handleClick = async () => {
    const priceId = subscription?.priceId
    if (priceId) {
      const portal = await dispatchFetchCustomerPortal({ priceId })
      const redirectUrl = portal?.payload?.url

      window.location = redirectUrl
    }
  }

  const isFreeTrial = useIsFreeTrial()

  return (
    <Box>
      <Stack spacing={4}>
        {subscription ? (
          <Box>
            {subscription?.featureSetTitle && (
              <Typography variant="subtitle1" mb={1}>
                Your subscription:{' '}
                <strong>{subscription?.featureSetTitle}</strong>
              </Typography>
            )}
            <Typography variant="subtitle1" mb={1}>
              Subscription price:{' '}
              <strong>
                {subscription?.priceAmountCents
                  ? `${currencyMap[subscription?.priceCurrency]}${
                      subscription?.priceAmountCents / 100
                    } 
                  ${subscriptionIntervalMap[subscription?.interval]}`
                  : 'Free'}
              </strong>
            </Typography>
            {(subscription?.currentSubscriptionPeriodEndDate ||
              subscription?.cancelAtPeriodEnd) && (
              <Typography variant="subtitle1">
                {subscription?.cancelAtPeriodEnd
                  ? 'Your subscription expires on: '
                  : 'Your next payment date: '}
                <strong>
                  {subscription?.currentSubscriptionPeriodEndDate
                    ? new Date(
                        subscription?.currentSubscriptionPeriodEndDate
                      ).toDateString()
                    : 'Never!'}
                </strong>
              </Typography>
            )}
          </Box>
        ) : (
          <Typography>
            You are currently in view only mode. You are unable to edit or
            upload any content, please subscribe to activate edit mode.
          </Typography>
        )}
        <Box display="flex" gap={1}>
          {(!subscription || isFreeTrial) && (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => history.push('/pricing')}
            >
              Upgrade
            </Button>
          )}
          {subscription?.priceId && (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              isLoading={dispatchFetchCustomerPortal.status === 'loading'}
              onClick={handleClick}
            >
              Manage Payments
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

const PersonalSettings = props => {
  const [value, setValue] = useState(0)
  const history = useHistory()

  const handleChange = (e, newValue) => {
    setValue(newValue)
    history.push(`personal-settings?tab=${newValue}`)
  }

  useEffect(() => {
    const search = history.location.search
    const tabNum = Number(search.slice(-1))
    if (tabNum) {
      setValue(tabNum)
    }
  }, [history.location.search])

  return (
    <Container maxWidth="sm">
      <Typography
        textAlign="center"
        variant="h3"
        color="textPrimary"
        sx={{ mb: 4, mt: 4 }}
      >
        Personal Settings
      </Typography>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="Your Details" id={0} />
          <Tab label="Your Account" id={1} />
        </TabList>
        <TabPanel value={0}>
          <YourDetails />
        </TabPanel>
        <TabPanel value={1}>
          <AccountDetails />
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default PersonalSettings
