import { Box, MenuItem, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ConfirmDialog } from '../ui'
import { useActionDispatcher } from '../app'
import { deleteTree } from '../auth/authSlice'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  deleteContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const DeleteTreeButton = ({
  treeSlug,
  manageConfig,
  deleteLabel = 'delete',
}) => {
  const classes = useStyles()
  const deleteTreeAction = useActionDispatcher(deleteTree)
  const [deleteLabelValue, setDeleteLabelValue] = useState('')

  return (
    <Box className={classes.deleteContainer}>
      <ConfirmDialog
        onConfirm={() =>
          deleteTreeAction(treeSlug, {
            successNotification: manageConfig.succcessMessage,
          })
        }
        submitText={manageConfig.deletePrompt}
        submitColor={'error'}
        submitDisabled={deleteLabelValue !== deleteLabel}
        title="You are about to delete a tree!"
        trigger={props => (
          <MenuItem {...props}>{manageConfig.deletePrompt}</MenuItem>
        )}
      >
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          You are about to delete the tree <b>"{treeSlug}"</b>. This cannot be
          undone! To continue type the word 'delete' into the box below and then
          click '{manageConfig.deletePrompt}'
        </Typography>
        <TextField
          id="outlined-basic"
          label="delete"
          variant="outlined"
          onChange={e => setDeleteLabelValue(e.target.value)}
        />
      </ConfirmDialog>
    </Box>
  )
}

export default DeleteTreeButton
