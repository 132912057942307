import React, { useCallback, useEffect, useRef } from 'react'
import { Typography, styled, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const ANIMATION_TIME = '7'
const ELLIPSIS_ANIMATION_TIME = '0.5'

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: theme.spacing(-2),
    height: 'fit-content',
    alignItems: 'flex-start',
  },
  textContainer: {
    animation: `$container ${ANIMATION_TIME}s ease-in-out`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    maxWidth: '100%',
    marginTop: theme.spacing(-1),
    animationDuration: `${ANIMATION_TIME}s`,
  },
  textBox: {
    fontSize: 16,
    maxWidth: 0,
    animation: `$text ${ANIMATION_TIME}s ease-in-out`,
  },
  text: {
    fontSize: 16,
  },
  ellipsis: {
    maxWidth: '100%',
    animation: `$ellipsis ${ANIMATION_TIME}s ease-in-out`,
  },
  animatedText: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
    display: 'inline-block',
    position: 'relative',
    animationDuration: `${ANIMATION_TIME}s`,
  },
  ellipsis0: {
    animation: `$ellipsisJump ${ELLIPSIS_ANIMATION_TIME}s linear`,
    margin: theme.spacing(1.4, 0.3),
    animationDelay: '0.5s',
  },
  ellipsis1: {
    animation: `$ellipsisJump ${ELLIPSIS_ANIMATION_TIME}s linear`,
    animationDelay: '0.6s',
    margin: theme.spacing(1.4, 0.3),
  },
  ellipsis2: {
    animation: `$ellipsisJump ${ELLIPSIS_ANIMATION_TIME}s linear`,
    margin: theme.spacing(1.4, 0.3),
    animationDelay: '0.7s',
  },

  '@keyframes ellipsisJump': {
    '0%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  },

  '@keyframes container': {
    '0%': {
      maxWidth: '100%',
    },
    '17%': {
      maxWidth: '100%',
    },
    '24%': {
      maxWidth: 0,
    },
    '25%': {
      maxWidth: 0,
    },
    '57%': {
      maxWidth: '100%',
    },
    '81%': {
      maxWidth: '100%',
    },
    '92%': {
      maxWidth: 0,
    },
    '93%': {
      maxWidth: 0,
    },
    '100%': {
      maxWidth: '100%',
    },
  },
  '@keyframes text': {
    '0%': {
      maxWidth: 0,
    },

    '24%': {
      maxWidth: 0,
    },
    '24.5%': {
      maxWidth: '100%',
    },
    '92%': {
      maxWidth: '100%',
    },
    '92.5%': {
      maxWidth: 0,
    },
    '100%': {
      maxWidth: 0,
    },
  },
  '@keyframes ellipsis': {
    '0%': {
      maxWidth: '100%',
    },

    '24%': {
      maxWidth: '100%',
    },
    '24.5%': {
      maxWidth: 0,
    },
    '92%': {
      maxWidth: 0,
    },
    '92.5%': {
      maxWidth: '100%',
    },
    '100%': {
      maxWidth: '100%',
    },
  },
}))

const PublicLogo = styled('img')({
  display: 'block',
  width: 156,
  marginTop: 4,
})

const Ellipsis = () => {
  const classes = useStyles()

  const isSafari = window.safari
  return (
    <Box className={clsx(classes.ellipsis, classes.animatedText)}>
      <img
        src="/one-ellipsis.png"
        alt="Ellipsis"
        width="9px"
        height="9px"
        className={classes.ellipsis0}
        style={{ marginBottom: isSafari ? 0 : 11 }}
      />
      <img
        src="/one-ellipsis.png"
        alt="Ellipsis"
        width="9px"
        height="9px"
        className={classes.ellipsis1}
        style={{ marginBottom: isSafari ? 0 : 11 }}
      />
      <img
        src="/one-ellipsis.png"
        alt="Ellipsis"
        width="9px"
        height="9px"
        className={classes.ellipsis2}
        style={{ marginBottom: isSafari ? 0 : 11 }}
      />
    </Box>
  )
}

export default function TextAnimation({
  baseUrl,
  setDisplayBtn,
  setLinkOpacity = () => {},
  tagline = 'THE visual family archive builder to unshackle your family history',
}) {
  const classes = useStyles()
  const linkRef = useRef()

  const handleAnimationStart = useCallback(() => {
    setTimeout(() => {
      setDisplayBtn(true)
      if (linkRef.current) {
        linkRef.current.style.opacity = 1
      }
    }, ANIMATION_TIME * 1000)

    setTimeout(() => {
      setLinkOpacity(1)
    }, 6000)
  }, [setDisplayBtn, setLinkOpacity])

  useEffect(() => {
    const element = document.getElementById('text-anim-container')

    element.addEventListener('animationstart', handleAnimationStart)

    return () => {
      element.removeEventListener('animationstart', handleAnimationStart)
    }
  }, [handleAnimationStart])

  return (
    <div className={classes.container}>
      <div>
        <Link to={`${baseUrl}/home/tree`}>
          <PublicLogo src="/logo-no-ellipsis.png" />
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 8,
          alignItems: 'flex-start',
        }}
      >
        <img src="/left-[.png" alt="Open Bracket" height="34px" />
        <div id="text-anim-container" className={classes.textContainer}>
          <Box className={clsx(classes.textBox, classes.animatedText)}>
            <Typography
              className={classes.text}
              variant="h2"
              mx={1}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                transitionDuration: '1s',
                fontSize: 40,
              }}
            >
              {tagline}
            </Typography>
          </Box>
          <Ellipsis />
        </div>
        <img src="/right-].png" alt="Close Bracket" height="34px" />
      </div>
    </div>
  )
}
