import { PrismicText } from '@prismicio/react'
import YouTube from 'react-youtube'
import { Box } from '@mui/system'
import { Typography } from '../../ui'
import { useMediaQuery } from '@mui/material'
import { useLayoutEffect, useState } from 'react'

const VideoHighlights = ({ slice }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('md'))
  const alignment = slice.primary.alignment === 'left' ? 'row' : 'row-reverse'
  let height = window.innerHeight

  const resize = () => setWidth(window.innerWidth)

  useLayoutEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: mobileBreakpoint ? 'left' : 'center',
        flexDirection: mobileBreakpoint ? 'column' : alignment,
        justifyContent: alignment === 'row' ? 'right' : 'left',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: mobileBreakpoint
            ? '1rem 0'
            : alignment === 'row'
            ? '1rem 2.5rem 1rem 0'
            : '1rem 0 1rem 2.5rem',
        }}
      >
        <Typography variant="h4">
          <PrismicText field={slice.primary.title} />
        </Typography>
        <div>
          <PrismicText field={slice.primary.text} />
        </div>
      </Box>
      <YouTube
        opts={
          mobileBreakpoint
            ? {
                width: '100%',
                height: width / 2 > height ? height * 0.9 : width / 2,
              }
            : {
                width: width * 0.28,
                height: width * 0.16,
              }
        }
        videoId={slice.primary.video_id}
      />
    </Box>
  )
}

export default VideoHighlights
