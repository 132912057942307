import PublicAncestralFamiliesContainer from '../tree/PublicAncestralFamiliesContainer'

const PublicHomePyramid = () => {
  return (
    <div>
      <PublicAncestralFamiliesContainer displayAsDialog={false} />
    </div>
  )
}

export default PublicHomePyramid
