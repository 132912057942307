import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Group } from '@visx/group'
import { Text } from '@visx/text'

import useViewerColours from '../viewerColours'

import { getBorderBoxProps } from './utils'
import {
  ADD_TYPE_CHILD,
  ADD_TYPE_FATHER,
  ADD_TYPE_GRAND_FATHER,
  ADD_TYPE_GRAND_MOTHER,
  ADD_TYPE_MOTHER,
  ADD_TYPE_SELF,
  ADD_TYPE_SIBLING,
  ADD_TYPE_SPOUSE,
  FAR_LEFT,
  FAR_TOP,
  INDVDL_NODE_GRID_HEIGHT,
  INDVDL_NODE_GRID_WIDTH,
  INDVDL_NODE_HEIGHT,
  INDVDL_NODE_PADDING,
  INDVDL_NODE_WIDTH,
} from './constants'
import {
  setAddIndividualNode,
  setHoveredIndividualId,
} from '../exploreTreeSlice'
import { isUndefined } from 'lodash'

const ADD_TYPE_LABELS = {
  [ADD_TYPE_SELF]: 'yourself or home person',
  [ADD_TYPE_FATHER]: 'father',
  [ADD_TYPE_MOTHER]: 'mother',
  [ADD_TYPE_GRAND_FATHER]: 'grandfather',
  [ADD_TYPE_GRAND_MOTHER]: 'grandmother',
  [ADD_TYPE_SIBLING]: 'sibling',
  [ADD_TYPE_CHILD]: 'child',
  [ADD_TYPE_SPOUSE]: 'partner',
}

function DrawnAddIndividualNode({ x, y, addIndividualNode }) {
  const dispatch = useDispatch()

  if (isUndefined(x) && isUndefined(y)) {
    x =
      addIndividualNode.x * INDVDL_NODE_GRID_WIDTH - INDVDL_NODE_GRID_WIDTH / 2
    y = addIndividualNode.y * INDVDL_NODE_GRID_HEIGHT
  }

  const { NODE_BG, NODE_TEXT, NODE_BORDER, NODE_BORDER_SELECTED } =
    useViewerColours()

  const backgroundColor = NODE_BG
  const textColor = NODE_TEXT

  // Mouse hover effect
  const [mouseOver, setMouseOver] = useState(false)
  const setMouseIsOver = useCallback(() => {
    setMouseOver(true)
    dispatch(setHoveredIndividualId(addIndividualNode.id))
  }, [dispatch, addIndividualNode.id])
  const setMouseNotOver = useCallback(() => {
    dispatch(setHoveredIndividualId(null))
    setMouseOver(false)
  }, [dispatch])
  const extraBorderColor = mouseOver ? NODE_BORDER_SELECTED : 'transparent'

  // When this node is clicked, send relevant data to the redux store
  // which can be used to create a form to add an individual.
  const handleOnClick = useCallback(
    e => {
      dispatch(setAddIndividualNode(addIndividualNode))
    },
    [dispatch, addIndividualNode]
  )

  return (
    <Group top={y} left={x} className="fadeIn">
      <rect
        height={INDVDL_NODE_HEIGHT}
        width={INDVDL_NODE_WIDTH}
        y={FAR_TOP}
        x={FAR_LEFT}
        rx={4}
        fill={backgroundColor}
        strokeWidth={1}
      />
      <rect stroke={NODE_BORDER} {...getBorderBoxProps(1, 5)} />
      <rect stroke={extraBorderColor} {...getBorderBoxProps(2, 7)} />
      <Text
        pointerEvents="none"
        x={0}
        y={0}
        fontSize={9}
        fontWeight="bold"
        fontFamily="Arial"
        textAnchor="middle"
        verticalAnchor="middle"
        style={{ pointerEvents: 'none' }}
        fill={textColor}
        width={INDVDL_NODE_WIDTH + 2 * INDVDL_NODE_PADDING}
        children={`Add ${ADD_TYPE_LABELS[addIndividualNode.typeToAdd]}`}
      ></Text>
      <rect
        height={INDVDL_NODE_HEIGHT}
        width={INDVDL_NODE_WIDTH}
        y={FAR_TOP}
        x={FAR_LEFT}
        style={{ cursor: 'pointer' }}
        fill="none"
        pointerEvents="visible"
        onMouseOver={setMouseIsOver}
        onMouseOut={setMouseNotOver}
        onClick={handleOnClick}
      />
    </Group>
  )
}

export default DrawnAddIndividualNode
