import { useEffect, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField, Box, styled, Typography } from '@mui/material'
import { differenceBy } from 'lodash'
import { matchSorter } from 'match-sorter'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { useActionDispatcher } from 'src/modules/app'

import {
  createLink,
  deleteLink,
  logToSearchTrail,
  selectLogToSearchTrail,
} from './writeArticleSlice'
import CreateLinkedPageDialog from '../page/CreateUpdateLinkedPage'
import { INSTANCE_TYPE_FAMILY } from '../app/links'
import { INDIVIDUAL_SEARCH_KEYS } from '../ui/individualUtils'
import { PAGE_DISPLAY_NAME_SINGULAR } from '../page/LinkedPage'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
  },
  inputWithTargets: {
    '& .MuiInput-root': {
      paddingBottom: theme.spacing(0.5),
    },
    '& .MuiFilledInput-root': {
      paddingBottom: theme.spacing(1 / 2),
      paddingTop: theme.spacing(3),
    },
  },
  listbox: {
    padding: 0,
  },
}))

const ADD_NEW = 'fake-id-for-adding-new-option'

const TagField = ({
  subject,
  className,
  createInPlace = false,
  enableFuzzyFilter = false,
  label,
  secondaryLabel = '',
  linkType,
  onSearch,
  onSelectTag,
  onDeselectTag,
  options,
  presetTargets,
  links,
  variant = 'standard',
  highlightedOptionIDs = new Set(),
  setPreviewFamily,
  setPopoverPos,
  alignTreePreview,
  allOptions = [],
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const searchTrail = useSelector(selectLogToSearchTrail(linkType))
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')
  const dispatchCreateLink = useActionDispatcher(createLink)
  const dispatchDeleteLink = useActionDispatcher(deleteLink)
  const [selectedPresetTargets, setSelectedPresetTargets] = useState(
    presetTargets || []
  )

  //next two lines a real hack to get this working with partial tree loads.
  //targets and links are the same thing but all this code had assumed a full load.
  // needs a re write tbh - RGS 26/5/2023
  const linksToOptions = links
    .filter(({ instanceType }) => instanceType === linkType)
    .map(link => ({ ...link, id: link.target }))
  let allOptionsPlusSearchLog = allOptions
    .concat(searchTrail)
    .concat(linksToOptions)

  const [createInPlaceTitle, setCreateInPlaceTitle] = useState('')

  let previewTimeoutId = useRef(null)

  // Populate all the options by making an empty search
  useEffect(() => {
    onSearch('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subject?.links && !value.length) {
      setValue(
        subject.links
          .filter(l => l.instanceType === linkType)
          .map(({ target, display }) => ({
            id: target,
            display,
          }))
      )
    }

    // eslint-disable-next-line
  }, [subject, linkType])

  // Each time, check whether we need to add the preset targets
  useEffect(() => {
    if (selectedPresetTargets.length) {
      const presetOptions = allOptionsPlusSearchLog.filter(({ id }) =>
        selectedPresetTargets.includes(id)
      )

      for (let presetOption of presetOptions) {
        const alreadySelected = value
          .map(({ id }) => id)
          .includes(presetOption.id)

        if (!alreadySelected) {
          setValue(value.concat([presetOption]))
        }
      }
    }
  }, [selectedPresetTargets, allOptionsPlusSearchLog, value, onSelectTag])

  // Remove selected options from available options
  const filterOptions = options => {
    const selectedIds = value.map(v => v.id)
    const filteredOptions = options
      .filter(o => {
        return !selectedIds.includes(o.id)
      })
      .map(option => {
        return {
          add: false,
          highlighted: highlightedOptionIDs.has(option.id),
          ...option,
        }
      })

    return filteredOptions.sort((a, b) => {
      if (a.highlighted && !b.highlighted) {
        return -1
      }
      if (!a.highlighted && b.highlighted) {
        return 1
      }
      return 0
    })
  }
  const filteredOptions = filterOptions(options)
  // Add the option to create a new option
  if (createInPlace) {
    const displayType = PAGE_DISPLAY_NAME_SINGULAR[linkType]
    const addText = inputValue ? `'${inputValue}'` : `new ${displayType}`
    filteredOptions.push({
      add: true,
      display: `Add ${addText}`,
      id: ADD_NEW,
      inputValue,
    })
  }

  // Use a ref to store the open trigger for the create dialog
  const createInPlaceDialogTriggerRef = useRef()
  const createInPlaceDialogTrigger = ({ onClick }) => {
    createInPlaceDialogTriggerRef.current = onClick
  }

  const newTargetSelected = target => {
    if (previewTimeoutId.current) {
      clearTimeout(previewTimeoutId.current)
    }
    onSelectTag(target.id)
    const newItem = {
      instanceType: linkType,
      id: target.id,
      display: target.display,
    }
    setValue(value.concat([newItem]))
    dispatch(logToSearchTrail(newItem))
    if (subject) {
      dispatchCreateLink({
        subject: subject.id,
        target: target.id,
      })
    }
  }

  const handleChange = async (event, newValue) => {
    const validNewValue = newValue.filter(v => !!v.id)
    const toAdd = differenceBy(validNewValue, value, 'id')
    const toRemove = differenceBy(value, validNewValue, 'id')
    toAdd.forEach(async target => {
      if (target.instanceType === INSTANCE_TYPE_FAMILY) {
        setPreviewFamily({})
      }
      if (target.id === ADD_NEW) {
        setCreateInPlaceTitle(target.inputValue)
        createInPlaceDialogTriggerRef.current(true)
        return
      } else {
        newTargetSelected(target)
        setValue(newValue)
      }
    })
    toRemove.forEach(async target => {
      setValue(newValue)
      if (selectedPresetTargets.includes(target.id)) {
        setSelectedPresetTargets(
          selectedPresetTargets.filter(id => id !== target.id)
        )
      }
      onDeselectTag(target.id)
      if (newValue.length === 0) {
        setSelectedPresetTargets([])
      }
      subject &&
        dispatchDeleteLink({
          subject: subject.id,
          target: target.id,
        })
    })
  }

  const handleInputChange = (event, newInputValue) => {
    onSearch(newInputValue)
    setInputValue(newInputValue)
  }

  const handleOnBlur = () => {
    setInputValue('')
    if (setPreviewFamily) {
      setPreviewFamily({})
    }
  }

  const noOptionsText =
    inputValue === '' ? 'Type to search...' : 'No matches found'

  const handleCreateInPlace = createdObject => {
    newTargetSelected({
      instanceType: linkType,
      id: createdObject.id,
      display: createdObject.title,
    })
  }
  const fuzzyFilterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {
      keys: INDIVIDUAL_SEARCH_KEYS,
    })
  }

  const handleHover = (e, option) => {
    if (previewTimeoutId.current) {
      clearTimeout(previewTimeoutId.current)
    }
    let rect
    if (e.nativeEvent.target.localName === 'p') {
      rect = e.nativeEvent.target.getBoundingClientRect()
    } else {
      rect = e.nativeEvent.target.children[0].getBoundingClientRect()
    }
    if (alignTreePreview === 'left') {
      setPopoverPos({
        x: rect.x,
        y: rect.y,
      })
    } else {
      setPopoverPos({
        x: rect.x + rect.width,
        y: rect.y,
      })
    }

    previewTimeoutId.current = setTimeout(() => {
      setPreviewFamily(option)
    }, 1000)
  }

  const handleHoverEnd = (e, option) => {
    if (previewTimeoutId.current) {
      clearTimeout(previewTimeoutId.current)
    }
    setPreviewFamily({})
  }

  const renderOption = (props, option) => {
    if (option.instanceType === INSTANCE_TYPE_FAMILY) {
      return (
        <li
          {...props}
          key={option.id}
          onMouseOver={e => handleHover(e, option)}
          onMouseLeave={e => handleHoverEnd(e, option)}
        >
          <Typography
            sx={{
              fontWeight: option.highlighted ? 'bold' : 'normal',
            }}
          >
            {option.display}{' '}
            {option.earliestBirthYear ? `(${option.earliestBirthYear})` : null}
          </Typography>
        </li>
      )
    } else {
      return (
        <li
          {...props}
          key={option.id}
          style={{ fontWeight: option.highlighted ? 'bold' : 'normal' }}
        >
          {option.display}
        </li>
      )
    }
  }

  return (
    <div className={className}>
      <Autocomplete
        filterOptions={
          enableFuzzyFilter ? fuzzyFilterOptions : (options, state) => options
        }
        autoComplete
        classes={{
          listbox: classes.listbox,
        }}
        ChipProps={{ size: 'small' }}
        freeSolo
        groupBy={option => option.add}
        getOptionLabel={option => option.display}
        inputValue={inputValue}
        ListboxProps={{ sx: { overflow: 'hidden', maxHeight: '100%' } }}
        multiple={true}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={handleOnBlur}
        options={filteredOptions}
        renderGroup={RenderGroup}
        renderOption={(props, option) => renderOption(props, option)}
        renderInput={params => (
          <TextField
            {...params}
            label={(label.toUpperCase() + ' ' + secondaryLabel).trim()}
            fullWidth
            variant={variant}
            className={clsx({
              [classes.root]: true,
              [classes.inputWithTargets]: value.length > 0,
            })}
          />
        )}
        value={value}
      />
      {createInPlace && (
        <CreateLinkedPageDialog
          initialTitle={createInPlaceTitle}
          onCreate={handleCreateInPlace}
          trigger={createInPlaceDialogTrigger}
          type={linkType}
        />
      )}
    </div>
  )
}

const StyledGroup = styled(Box, {
  shouldForwardProp: prop => prop !== 'group',
})(({ group, theme }) => ({
  padding: theme.spacing(1, 0),
  maxHeight: 275,
  overflow: 'auto',
  ...(group && {
    padding: theme.spacing(1, 0),
    borderTop: `1px solid ${theme.palette.lightGrey.main} !important`,
  }),
}))

const RenderGroup = props => <StyledGroup {...props} />

export default TagField
