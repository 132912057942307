import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Dialog, DialogContent } from '@mui/material'

import { DialogTitle } from 'src/modules/ui'

const SimpleDialog = forwardRef(
  (
    {
      trigger,
      children,
      open = false,
      onClose,
      title,
      showCloseButton,
      fullWidth,
      maxWidth,
      closeOnBackdropClick = true,
      ...props
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(open)

    useEffect(() => {
      setModalOpen(open)
    }, [open])

    const handleShowModal = () => {
      setModalOpen(true)
    }

    const handleCloseModal = (event, reason) => {
      if (closeOnBackdropClick || reason !== 'backdropClick') {
        setModalOpen(false)
        if (onClose) {
          onClose()
        }
      }
    }

    useImperativeHandle(ref, () => ({
      setModalOpen,
    }))

    return (
      <>
        {trigger && trigger({ onClick: handleShowModal })}
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          {...props}
        >
          {showCloseButton && (
            <DialogTitle onClose={handleCloseModal}>{title}</DialogTitle>
          )}
          <DialogContent>
            {children instanceof Function
              ? children({
                  closeDialog: handleCloseModal,
                })
              : children}
          </DialogContent>
        </Dialog>
      </>
    )
  }
)

export default SimpleDialog
