import { useDispatch } from 'react-redux'
import { downloadMediaFromLatestGedcomForTree } from '../auth/authSlice'

import { fetchNotifications, markNotificationsSeen } from './appSlice'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from './appConstants'
import React, { useCallback } from 'react'
import {
  useActionDispatcher,
  useGetSubscription,
  useIsFreeTrial,
  useNotification,
} from './hooks'
import { useHistory } from 'react-router-dom'
import { fetchIndividualsForTarget } from '../viewer/viewerSlice'
import { getTreeSlugFromStore } from '../auth/utils'

const NOTIFICATION_SEVERITY_INFO = 2
//below not used atm!
//const NOTIFICATION_SEVERITY_DEFAULT = 1
// const NOTIFICATION_SEVERITY_WARN = 3
// const NOTIFICATION_SEVERITY_ERROR = 4

export const getNotificationHandler = params => {
  const { notification } = params

  if (notification?.systemEventCode === 'GENEALOGY_FILE_UPLOAD_STATE_UPDATED') {
    return new GenealogyFileUploadStateUpdatedHandler(params)
  } else if (
    notification?.systemEventCode ===
    'NOTIFY_GEDCOM_UPLOADER_THEY_CAN_DOWNLOAD_MEDIA'
  ) {
    return new NotifyGedcomUploaderTheyCanDownloadMediaHandler(params)
  }

  return new DummyHandler(params)
}

const useHandleNotificationsUpdated = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { showInfoMessage } = useNotification()
  const dispatchMarkNotificationsSeen = useActionDispatcher(
    markNotificationsSeen
  )
  const subscription = useGetSubscription()
  const isFreeTrial = useIsFreeTrial()

  const handleNotificationsUpdated = useCallback(
    notifications => {
      if (!notifications) {
        return
      }

      const authorisedTreeSlug = getTreeSlugFromStore()

      notifications =
        notifications.filter(
          notification => notification.severity === NOTIFICATION_SEVERITY_INFO
        ) || []
      const setSeenTargets = []

      notifications.forEach(notification => {
        const handler = getNotificationHandler({
          notification,
          subscription,
          isFreeTrial,
        })

        const {
          snackbarMessage,
          secondMessage,
          autoHideDuration,
          persist,
          markSeen,
          secondMessageAction,
          postNotification,
        } = handler?.getPayload({ dispatch, authorisedTreeSlug, history })

        if ((persist || !notification.seen) && snackbarMessage) {
          // define fn to be called when user closes the snackbar
          const markNotificationDismissed = snackbarKey => {
            dispatchMarkNotificationsSeen({
              setSeenTargets: [],
              setDismissedTargets: [notification.id],
              authorisedTreeSlug,
            }).then(response => {
              handleNotificationsUpdated(response?.payload)
            })
          }

          showInfoMessage(snackbarMessage, {
            persist,
            secondMessage,
            secondMessageAction,
            autoHideDuration,
            closeSnackbar: persist ? markNotificationDismissed : null,
            preventDuplicate: true,
            key: notification.id,
          })
        }

        if (typeof postNotification === 'function') {
          if (!notification.seen) {
            postNotification()
          }
        } else {
          if (postNotification) {
            console.warn(
              `notificationsHandlers.handleNotificationsUpdated(): postNotification is set but is not a functon`,
              postNotification
            )
          }
        }

        if (markSeen) {
          setSeenTargets.push(notification.id)
        }
      })

      // seenTargets will only contain notifications that resulted in a snackbar
      // and had markSeen=true, otherwise this will mess with other notifications
      // that are remaining to be seen in the bell drop-down
      if (setSeenTargets.length > 0) {
        dispatchMarkNotificationsSeen({
          setSeenTargets,
          setDismissedTargets: [],
          authorisedTreeSlug,
        })
      }
    },
    [
      dispatch,
      dispatchMarkNotificationsSeen,
      history,
      isFreeTrial,
      showInfoMessage,
      subscription,
    ]
  )

  return handleNotificationsUpdated
}

export const useGetThenHandleNotifications = () => {
  const dispatch = useDispatch()

  const handleNotificationsUpdated = useHandleNotificationsUpdated()

  // called every n seconds from a timer created in TreeApp.js, and once when the timer is started
  const getThenHandleNotifications = useCallback(() => {
    const authorisedTreeSlug = getTreeSlugFromStore()

    //console.debug(
    //  `getThenHandleNotifications(): called, authorisedTreeSlug: '${authorisedTreeSlug}'`
    //)

    if (authorisedTreeSlug) {
      dispatch(
        fetchNotifications({ page: 0, treeSlug: authorisedTreeSlug })
      ).then(response => {
        handleNotificationsUpdated(response?.payload?.results)
      })
    }
  }, [dispatch, handleNotificationsUpdated])

  return getThenHandleNotifications
}

/******************************
 * HANDLERS
 *****************************/

const DownloadAllMediaButton = ({
  dispatch,
  authorisedTreeSlug,
  closeSnackbar,
  id,
}) => {
  if (!dispatch || !authorisedTreeSlug) {
    return null
  }

  const downloadMedia = () => {
    dispatch(downloadMediaFromLatestGedcomForTree(authorisedTreeSlug))
    closeSnackbar(id)
  }

  return (
    <span>
      <Button
        onClick={downloadMedia}
        sx={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
        permissionAction={ACTION_ALL_ACCESS}
      >
        Download All
      </Button>
    </span>
  )
}

// class DummyHandler {
//   constructor({ notification, subscription, isFreeTrial }) {
//     this.notification = notification
//     this.subscription = subscription
//     this.isFreeTrial = isFreeTrial
//   }
//
//   getPayload({ dispatch, authorisedTreeSlug }) {
//     let secondMessageAction = ({ closeSnackbar, id }) => (
//       <DownloadAllMediaButton
//         dispatch={dispatch}
//         authorisedTreeSlug={authorisedTreeSlug}
//         closeSnackbar={closeSnackbar}
//         id={id}
//       />
//     )
//
//     return {
//       message: 'I AM A MESSAGE',
//       secondMessage:
//         'I am a second message and I am a bit large tbh and I hope I wrap a bit so I can see the button old fruit',
//       persist: true,
//       secondMessageAction,
//     }
//   }
// }

class DummyHandler {
  getPayload() {
    return {}
  }
}

class GenealogyFileUploadStateUpdatedHandler {
  constructor({ notification, subscription, isFreeTrial }) {
    this.notification = notification
    this.subscription = subscription
    this.isFreeTrial = isFreeTrial
  }

  getMessage() {
    if (this.notification?.instanceType === 'file_upload') {
      if (this.notification?.instanceTitle?.endsWith('_FAILED')) {
        return 'GEDCOM import failed'
      } else if (this.notification?.instanceTitle?.endsWith('_COMPLETE')) {
        return 'GEDCOM import complete!'
      } else {
        return 'GEDCOM import continuing in the background...'
      }
    }
  }

  getPayload({ dispatch, authorisedTreeSlug, history }) {
    if (
      this.notification?.instanceTitle?.startsWith('PHASE_2_') &&
      !this.notification?.instanceTitle?.endsWith('_COMPLETE') &&
      !this.notification?.instanceTitle?.endsWith('_FAILED')
    ) {
      //console.debug(
      //  `GenealogyFileUploadStateUpdatedHandler.getPayload(): import still in progress, instanceTitle: ${this.notification?.instanceTitle}`
      //)

      var postNotification = null
      // if OBJEs have finished then call fetchIndividualsForTarget() to redraw the tree with newly acquired images
      if (this.notification?.instanceTitle === 'PHASE_2_INDIS') {
        //console.debug(
        //  `GenealogyFileUploadStateUpdatedHandler.getPayload(): notification had instanceTitle 'PHASE_2_INDIS', setting fun postNotification to call fetchIndividualsForTarget()...`
        //)
        // we've got past importing images, refresh the currently onscreen tree so the user sees them immediately
        postNotification = () => {
          console.debug(
            `GenealogyFileUploadStateUpdatedHandler.getPayload(): notification had instanceTitle 'PHASE_2_INDIS', dispatching to fetchIndividualsForTarget(treeSlug: '${authorisedTreeSlug}', forceLoad: true)...`
          )
          dispatch(
            fetchIndividualsForTarget({
              treeSlug: authorisedTreeSlug,
              forceLoad: true,
            })
          )
          // wanted to change the timeout so it polls more frequently whilst we're waiting for the OBJEs (images) to provide that 'gedcom to joy' moment,
          // but it seems there is no way to obtain getThenHandleNotifications which needs to be executed by the timer.
        }
      }
      return {
        snackbarMessage: this.getMessage(),
        markSeen: false, // we want to show the bell count whilst the import is still running
        postNotification: postNotification,
      }
    } else if (this.notification?.instanceTitle === 'PHASE_2_COMPLETE') {
      let secondMessageAction = ({ closeSnackbar, id }) => (
        <DownloadAllMediaButton
          dispatch={dispatch}
          authorisedTreeSlug={authorisedTreeSlug}
          closeSnackbar={closeSnackbar}
          id={id}
        />
      )

      let secondMessage

      if (
        this?.notification?.instanceDetail?.remainingDownloadableObjesCount > 0
      ) {
        const downloaded =
          this?.notification?.instanceDetail?.downloadedObjesCount
        const total = this?.notification?.instanceDetail?.downloadableObjesCount
        secondMessage = `${downloaded} of ${total} media items downloaded. `
        if (this.isFreeTrial) {
          secondMessage = secondMessage + 'Please subscribe to download more.'
          secondMessageAction = (
            <Button
              color="secondary"
              variant="outlined"
              sx={{ marginLeft: '15px', whiteSpace: 'nowrap' }}
              permissionAction={ACTION_ALL_ACCESS}
              onClick={() => history.push('/pricing')}
            >
              Subscribe
            </Button>
          )
        } else if (this.subscription) {
          secondMessage =
            secondMessage +
            `Would you like to download the rest now?\nIf not you can do it later from the tree's menu on your 'Trees' page.`
        }
      }

      return {
        snackbarMessage: this.getMessage(),
        secondMessage,
        secondMessageAction,
        persist: true,
        markSeen: false,
      }
    }

    return {}
  }
}

class NotifyGedcomUploaderTheyCanDownloadMediaHandler {
  constructor({ notification, subscription, isFreeTrial }) {
    this.notification = notification
    this.subscription = subscription
    this.isFreeTrial = isFreeTrial
  }

  getMessage() {
    return 'GEDCOM media import available'
  }

  getPayload({ dispatch, authorisedTreeSlug }) {
    if (
      this.notification?.systemEventCode ===
        'NOTIFY_GEDCOM_UPLOADER_THEY_CAN_DOWNLOAD_MEDIA' &&
      this.notification?.instanceType === 'file_upload' &&
      ['PHASE_2_COMPLETE', 'PHASE_2_FAILED'].includes(
        this.notification?.instanceTitle
      )
    ) {
      const total =
        this?.notification?.instanceDetail?.downloadableObjesCount ?? 0
      const downloaded =
        this?.notification?.instanceDetail?.downloadedObjesCount ?? 0
      const remaining = total - downloaded
      if (!remaining) {
        console.error(
          `NotifyGedcomUploaderTheyCanDownloadMediaHandler.getPayload(): did not find any gedcom media downloads available. Notification:`,
          this?.notification
        )
        return {}
      }
      let secondMessage = `There are ${remaining} media items in your GEDCOM file available for download. Would you like to download them now?\nIf not you can do it later from the tree's menu on your 'Trees' page.`

      let secondMessageAction = ({ closeSnackbar, id }) => (
        <DownloadAllMediaButton
          dispatch={dispatch}
          authorisedTreeSlug={authorisedTreeSlug}
          closeSnackbar={closeSnackbar}
          id={id}
        />
      )

      return {
        snackbarMessage: this.getMessage(),
        secondMessage,
        secondMessageAction,
        persist: true,
        markSeen: false,
      }
    }

    return {}
  }
}
