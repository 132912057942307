import { useCallback, useEffect, useState } from 'react'
import { EditorState, Modifier } from 'draft-js'

import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import CloseIcon from '@mui/icons-material/Close'

import { Dialog, DialogContent, styled } from '@mui/material'

import Picker from 'emoji-picker-react'

import { IconButton } from 'src/modules/ui'
import { Box } from '@mui/system'
import { MenuIconButton } from './MenuIconButton'

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  zIndex: 1,
}))

export const EmojiOptions = ({ editorState, onChange }) => {
  const [pickerVisible, setPickerVisible] = useState(false)
  const handleOpenPicker = useCallback(
    () => setPickerVisible(true),
    [setPickerVisible]
  )
  const handleClosePicker = useCallback(
    () => setPickerVisible(false),
    [setPickerVisible]
  )

  const addEmoji = useCallback(
    emoji => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        emoji,
        editorState.getCurrentInlineStyle()
      )

      onChange(EditorState.push(editorState, contentState, 'insert-characters'))
      setClosePickerInEffect(true)
    },
    [editorState, onChange]
  )

  // For some reason, closing the picker in the same cycle as editing the
  // text causes the changes to be lost.
  const [closePickerInEffect, setClosePickerInEffect] = useState(false)
  useEffect(() => {
    if (closePickerInEffect) {
      handleClosePicker()
      setClosePickerInEffect(false)
    }
  }, [closePickerInEffect, handleClosePicker])

  return (
    <>
      <Dialog
        open={pickerVisible}
        onClose={handleClosePicker}
        maxWidth={false}
        scroll="paper"
      >
        <Box sx={{ position: 'relative' }}>
          <CloseButton onClick={handleClosePicker}>
            <CloseIcon />
          </CloseButton>
          <DialogContent>
            <Box>
              <Picker
                disableAutoFocus={true}
                native={true}
                onEmojiClick={(event, emoji) => addEmoji(emoji.emoji)}
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <MenuIconButton onClick={handleOpenPicker}>
        <EmojiEmotionsIcon fontSize="small" />
      </MenuIconButton>
    </>
  )
}
