import { API } from '@aws-amplify/api'
import { Auth } from 'aws-amplify'
import { createAsyncThunk } from '@reduxjs/toolkit'

const makeApi = method => (path, init) => {
  if (path.startsWith('/public/')) {
    // if we have a logged-in user we don't want to send the Authorization header
    // to public API operations as the response won't be cacheable
    if (!init) {
      init = {
        headers: {},
      }
    } else {
      if (!init.headers) {
        init.headers = {}
      }
    }
    init.headers['Authorization'] = null
  }
  const promise = API[method]('default', path, init)
  promise.abort = () => API.cancel(promise)
  return promise
}

export const getUserSession = async () => {
  try {
    const session = await Auth.currentSession()
    const accessToken = session.getIdToken().getJwtToken()

    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  } catch (error) {
    console.error('Error retrieving user session:', error)
  }
}

export const get = makeApi('get')
export const post = makeApi('post')
export const patch = makeApi('patch')
export const put = makeApi('put')
export const del = makeApi('del')

const api = {
  get,
  post,
  patch,
  put,
  del,
}

// Wraps createAsyncThunk to handle api error responses with a payload
export const createWrappedAsyncThunk = (type, payloadCreator, options) =>
  createAsyncThunk(
    type,
    async (arg, thunkApi) => {
      const { rejectWithValue, signal } = thunkApi
      const promise = payloadCreator(arg, thunkApi)
      signal.addEventListener('abort', () => {
        if (promise.abort) {
          promise.abort()
        }
      })
      try {
        return await promise
      } catch (error) {
        if (!error.response || error.response.data == null) {
          throw error
        }
        throw rejectWithValue({
          status: error.response.status,
          data: error.response.data,
        })
      }
    },
    options
  )

export const handlePending =
  (stateAccessor, { setOnRequest }) =>
  (state, { meta }) => {
    const stateKey = stateAccessor(state)
    const page = meta.arg && meta.arg.page

    if (setOnRequest !== undefined && (page === undefined || page === 0)) {
      Object.assign(stateKey, setOnRequest)
    }
    if (page != null) {
      stateKey.page = page
    }
  }

export const handleFulfilled =
  (stateAccessor, { payloadAccessor = p => p }) =>
  (state, { meta, payload }) => {
    const stateKey = stateAccessor(state)
    const data = payloadAccessor(payload)
    const page = meta.arg && meta.arg.page
    if (page && page > 0) {
      const { results, ...resultRest } = data
      Object.assign(stateKey, {
        page,
        results: stateKey.results
          ? stateKey.results.concat(data.results)
          : data.results,
        ...resultRest,
      })
    } else {
      Object.assign(stateKey, data)
    }
  }

export const handlePaginatedAction = (action, stateAccessor, _options = {}) => {
  const defaults = {
    setOnRequest: undefined,
    pending: () => {},
    fulfilled: () => {},
  }
  const optionsWithDefaults = {
    ...defaults,
    ..._options,
  }
  const { pending, fulfilled, ...options } = optionsWithDefaults

  return {
    [action.pending]: (...args) => {
      handlePending(stateAccessor, options)(...args)
      pending(...args)
    },
    [action.fulfilled]: (...args) => {
      handleFulfilled(stateAccessor, options)(...args)
      fulfilled(...args)
    },
  }
}

export default api
