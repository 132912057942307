import React from 'react'
import { INSTANCE_TYPE_ARTEFACT } from 'src/modules/app/links'

import HomePageList from './HomePageList'

import { fetchHomePagesArtefacts, selectHomePagesArtefacts } from './homeSlice'

const Artefacts = () => {
  return (
    <HomePageList
      type={INSTANCE_TYPE_ARTEFACT}
      action={fetchHomePagesArtefacts}
      selector={selectHomePagesArtefacts}
    />
  )
}

export default Artefacts
