import { useEffect, useRef } from 'react'

import api from 'src/api'

export const SYSTEM_EVENT_STATE_PROCESSING = 'PROCESSING'
export const SYSTEM_EVENT_STATE_DONE = 'DONE'
export const SYSTEM_EVENT_STATE_FAILED = 'FAILED'

const POLL_DELAY = 1000

export function useWaitForSystemEvent(stateCallback, systemEventId) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = stateCallback
  }, [stateCallback])

  // Set up the interval.
  useEffect(() => {
    if (!systemEventId) {
      return
    }
    async function tick() {
      const systemEvent = await api.get(`/system-event/${systemEventId}/state/`)
      if (systemEvent) {
        if (
          [SYSTEM_EVENT_STATE_DONE, SYSTEM_EVENT_STATE_FAILED].includes(
            systemEvent.state
          )
        ) {
          clearInterval(id)
        }
        savedCallback.current(systemEvent.state)
      }
    }
    let id = setInterval(tick, POLL_DELAY)
    return () => {
      clearInterval(id)
    }
  }, [systemEventId])
}
