import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { NavLink } from 'src/modules/ui'

import PublicPagePhotoList from './PublicPagePhotoList'
import PublicPageContentList from './PublicPageContentList'
import { usePublicPageUrlSegmentsWithTab } from './hooks'
import {
  ALBUMS_TAB,
  INSTANCE_TYPE_PHOTO_ALBUM,
  MEDIA_TAB,
} from 'src/modules/app/links'
import { PUBLIC_ROOT } from 'src/modules/app/links'

export const PublicPhotosBase = ({ albumRoute, linkedPageId, photoRoute }) => {
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink exact to={photoRoute} replace>
              All Media
            </NavLink>
            <NavLink to={albumRoute} replace>
              Albums
            </NavLink>
          </Stack>
        </Box>
      </Stack>

      <Switch>
        <Route
          exact
          path={photoRoute}
          render={() => <PublicPagePhotoList target={linkedPageId} />}
        />
        <Route
          path={albumRoute}
          render={() => (
            <PublicPageContentList
              target={linkedPageId}
              pageType={INSTANCE_TYPE_PHOTO_ALBUM}
            />
          )}
        />
      </Switch>
    </div>
  )
}

const PublicPhotos = () => {
  const { slug, pathSegment, linkedPageId } = usePublicPageUrlSegmentsWithTab()

  const photoRoute = `/${PUBLIC_ROOT}/${slug}/${pathSegment}/${linkedPageId}/${MEDIA_TAB}`
  const albumRoute = `/${PUBLIC_ROOT}/${slug}/${pathSegment}/${linkedPageId}/${ALBUMS_TAB}`

  return <PublicPhotosBase {...{ albumRoute, linkedPageId, photoRoute }} />
}

export default PublicPhotos
