import { Dialog } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import MuiIconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

//a DialogTitle that includes a close button on the right
const CloseButtonDialogTitle = props => {
  const { children, closeButtonOnClick } = props

  //  console.debug(
  //    'CloseButtonDialogTitle: mounting... called with children:',
  //    children
  //  )

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      {closeButtonOnClick ? (
        <MuiIconButton
          aria-label="close"
          onClick={closeButtonOnClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </MuiIconButton>
      ) : null}
    </DialogTitle>
  )
}

CloseButtonDialogTitle.propTypes = {
  children: PropTypes.node,
  closeButtonOnClick: PropTypes.func.isRequired,
}

//a dialog with a close button that cannot be closed by clicking on the backdrop
export const CloseButtonDialog = ({
  dialogTitle,
  open, // reactive state boolean with current state of dialog
  closeButtonOnClick, // fn to call to close the dialog - sets open to false
  children,
  //...props
}) => {
  //  console.debug(
  //    `CloseButtonDialog: mounting... called with dialogTitle: '${dialogTitle}', open: '${open}'`
  //  )

  const close = (event, reason) => {
    closeButtonOnClick(event, reason)
  }

  // checks that the reason was NOT clicking on the backdrop
  const handleDialogOnClose = (event, reason) => {
    //    console.debug(
    //      "CloseButtonDialog.handleDialogOnClose(): checking close reason is not 'backdropClick':",
    //      reason
    //    )
    if (reason !== 'backdropClick') {
      close(event, reason)
    }
  }

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleDialogOnClose}>
          <CloseButtonDialogTitle
            closeButtonOnClick={e => close(e, 'closeButtonClicked')}
          >
            {dialogTitle}
          </CloseButtonDialogTitle>
          {children}
        </Dialog>
      )}
    </>
  )
}
