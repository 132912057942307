import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/system'
import { RectClipPath } from '@visx/clip-path'
import { Zoom } from '@visx/zoom'
import { Group } from '@visx/group'

import { isNaN } from 'lodash'

import { FOCUS_MODE_SCROLL } from './constants'
import { useStyles } from './viewerStyles'
import {
  clearAddIndividualNode,
  clearClickedIndividualId,
  selectSelectedIndividualIds,
} from '../exploreTreeSlice'
import { DrawnGraphNode, DrawnLink } from './DrawnNodes'
import { calculateInitialTransform } from './utils'
import DrawnAddIndividualNode from './DrawnAddIndividualNode'
import {
  NODE_TYPE_ADD_INDIVIDUAL_NODE,
  NODE_TYPE_GRAPH_NODE,
} from '../api/graphNode'
import { LoadingIndicator } from 'src/modules/ui'
import { IndividualContextMenu } from './ContextMenu'
import { contextMenuProps } from './TreeViewer'

const EditTreeViewer = ({
  nodes,
  links,
  width,
  height,
  clickedIndividual,
  onCloseViewerModal,
  showNodeContextMenu,
  exploreOveride,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedIndividualIds = useSelector(selectSelectedIndividualIds)

  let clickedGraphNode
  if (nodes.length && clickedIndividual) {
    for (let graphNode of nodes) {
      const clickedInnerNode = graphNode.innerNodes.find(i =>
        i.individualIDs.has(clickedIndividual.id)
      )
      if (clickedInnerNode) {
        clickedGraphNode = graphNode
        break
      }
    }
  }

  const drawnNodes = nodes.map(node => {
    let drawnNode
    if (node.nodeType === NODE_TYPE_GRAPH_NODE) {
      drawnNode = (
        <DrawnGraphNode
          key={node.id}
          selectedIndividualIds={selectedIndividualIds}
          graphNode={node}
          allowNodeClick={true}
          exploreNodeOnClick={false}
          navigateToNodeOnClick={false}
          navigateToNodeOnDoubleClick={false}
        />
      )
    } else if (node.nodeType === NODE_TYPE_ADD_INDIVIDUAL_NODE) {
      drawnNode = (
        <DrawnAddIndividualNode key={node.id} addIndividualNode={node} />
      )
    }
    return drawnNode
  })

  const handleClickAway = useCallback(() => {
    dispatch(clearClickedIndividualId())
    dispatch(clearAddIndividualNode())
  }, [dispatch])

  links = links.map(link => <DrawnLink link={link} key={link.id} />)

  const initialTransform = useMemo(
    () =>
      calculateInitialTransform(
        nodes,
        undefined,
        undefined,
        width,
        height,
        FOCUS_MODE_SCROLL,
        1.0
      ),
    [nodes, width, height]
  )

  if (!nodes.length || isNaN(width)) {
    return <LoadingIndicator />
  }

  return (
    <Box sx={{ position: 'relative', margin: 'auto', width: `${width}px` }}>
      <Zoom
        key={JSON.stringify(initialTransform)}
        width={width}
        height={height}
        initialTransformMatrix={initialTransform}
      >
        {zoom => {
          return (
            <div
              className={classes.viewWindow}
              style={{
                width: width,
                margin: '0 auto',
              }}
            >
              <svg width={width} height={height} style={{ display: 'block' }}>
                <RectClipPath id="zoom-clip" width={width} height={height} />
                <rect
                  width={width}
                  height={height}
                  style={{ opacity: 0 }}
                  onClick={handleClickAway}
                />
                <Group transform={zoom.toString()}>
                  {links}
                  {drawnNodes}
                </Group>
              </svg>
              {!!clickedIndividual &&
                clickedGraphNode &&
                showNodeContextMenu && (
                  <IndividualContextMenu
                    individualNode={clickedIndividual}
                    selectMenuConfig={{ edit: true, explore: true }}
                    onNavigate={onCloseViewerModal}
                    onExploreOveride={exploreOveride}
                    onCloseParentDialog={handleClickAway}
                    {...contextMenuProps(
                      clickedGraphNode,
                      clickedIndividual,
                      zoom,
                      selectedIndividualIds
                    )}
                  />
                )}
            </div>
          )
        }}
      </Zoom>
    </Box>
  )
}

export default EditTreeViewer
