import { bottomNLayers, graphNodesCentroid, topNLayers } from '../api/graphOps'
import {
  EXPLORED_INDIVIDUAL_SIDE_PADDING,
  FAR_LEFT,
  FAR_TOP,
  FOCUS_MODE_EXPLORE_INDIVIDUAL,
  FOCUS_MODE_EXPLORE_LINEAGE,
  FOCUS_MODE_SCROLL,
  FOCUS_MODE_TOP_OF_TREE,
  INDVDL_NODE_GRID_HEIGHT,
  INDVDL_NODE_GRID_WIDTH,
  INDVDL_NODE_HEIGHT,
  INDVDL_NODE_WIDTH,
} from './constants'

export const getBorderBoxProps = (borderWidth, radius) => {
  const boxOffset = borderWidth / 2
  return {
    x: FAR_LEFT - boxOffset,
    y: FAR_TOP - boxOffset,
    width: INDVDL_NODE_WIDTH + borderWidth,
    height: INDVDL_NODE_HEIGHT + borderWidth,
    fill: 'none',
    strokeWidth: borderWidth,
    rx: radius,
  }
}

export function calculateInitialTransform(
  nodes,
  exploredIndividual,
  userHomeIndividual,
  width,
  height,
  focusMode,
  initialZoom
) {
  let initialX = 0,
    initialY = 0

  let xFocusNodes
  let yFocusNodes = nodes
  let exploredGraphNode, currentUserGraphNode
  if (exploredIndividual) {
    exploredGraphNode = nodes.find(graphNode =>
      graphNode.individualIDs.has(exploredIndividual?.id)
    )
    if (userHomeIndividual) {
      currentUserGraphNode = nodes.find(graphNode =>
        graphNode.individualIDs.has(userHomeIndividual?.id)
      )
    }
  }
  if (nodes.length) {
    if (focusMode === FOCUS_MODE_SCROLL) {
      // In this mode, horizontal centring is handled by scrolling instead of
      // translation. We do however need to shift the nodes right to account for
      // the padding factor:
      initialX =
        (EXPLORED_INDIVIDUAL_SIDE_PADDING + 1) *
        INDVDL_NODE_GRID_WIDTH *
        initialZoom
    } else if (focusMode === FOCUS_MODE_EXPLORE_INDIVIDUAL) {
      if (exploredGraphNode) {
        xFocusNodes = [exploredGraphNode]
      } else {
        xFocusNodes = nodes
      }
    } else if (focusMode === FOCUS_MODE_TOP_OF_TREE) {
      xFocusNodes = topNLayers(nodes, 4)
      yFocusNodes = xFocusNodes
    } else if (focusMode === FOCUS_MODE_EXPLORE_LINEAGE) {
      const graphNode = exploredGraphNode || currentUserGraphNode
      if (graphNode) {
        xFocusNodes = [graphNode]
      } else {
        xFocusNodes = nodes
      }
      yFocusNodes = bottomNLayers(nodes, 4)
    }
  }
  if (xFocusNodes?.length) {
    const xCentroid = graphNodesCentroid(xFocusNodes)
    initialX = width / 2 - xCentroid.x * INDVDL_NODE_GRID_WIDTH * initialZoom
  }
  if (yFocusNodes?.length) {
    const yCentroid = graphNodesCentroid(yFocusNodes)
    initialY = height / 2 - yCentroid.y * INDVDL_NODE_GRID_HEIGHT * initialZoom
  }

  return {
    scaleX: initialZoom,
    scaleY: initialZoom,
    translateX: initialX,
    translateY: initialY,
    skewX: 0,
    skewY: 0,
  }
}
