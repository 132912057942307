import React from 'react'
import { Divider } from '@mui/material'

import { Typography } from 'src/modules/ui'

import LinkChips from './LinkChips'

const ArticleTags = ({ links }) => {
  return (
    <div>
      <Divider variant="middle" sx={{ mb: 1, ml: 0, mr: 0 }} />
      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        TAGS
      </Typography>
      <LinkChips links={links} sx={{ mb: 4 }} />
    </div>
  )
}

export default ArticleTags
