/* eslint react/prop-types: "warn", react/no-unused-prop-types: "warn", react/forbid-prop-types: "warn", react/destructuring-assignment: "warn",
          jsdoc/require-jsdoc: "warn", jsdoc/check-param-names: "warn", jsdoc/require-param-type: "warn", jsdoc/newline-after-description: "warn", jsdoc/check-alignment: "warn"
*/

/**
 * A 'source' is an entry in table history.SourceLink. The model extends Link which uses the ContentType
 * framework to relate to any other thing e.g. article, individual, artefact etc.
 *
 * Sources can be a freeText, (URL and description), or a photo. Extending them to add other properties
 * should be straightforward. SourceType is not stored but is determined in the front-end after loading -
 * see SourcesCommon.js setSourceTypes().
 *
 * Sources are rendered like wikipedia - as superscript numbers in brackets e.g. '[1]'. Clicking the
 * number. They are rendered by <SourceLink> below, called in slightly different ways:
 * - edit mode uses the Entity decorator feature of draft.js - ui/editor/RichTextField.js returns a SourceLink
 *   from below
 *
 * - non-edit-mode ContentTextBlocks use html-react-parser with parseOptions.js parse()
 *
 * - sources associated with a ContentBlock but with no link in the ContentBlock text are shown by a line of
 *   SourceLinks below the contentblock by <SourceRefLinks> below
 *
 */

import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Menu,
  MenuItem,
  Collapse,
  FormControlLabel,
  Switch,
  Popover,
  InputAdornment,
  Box,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'

import MediaNavigatorDialog from 'src/modules/photo/MediaNavigator'

import { Formik, useFormikContext } from 'formik'
import { ConfirmDialog, FormikTextField, IconButton } from '../ui'

import ArticlePhoto from 'src/modules/content/ArticlePhoto'
import AutoFormSubmitter from 'src/modules/writeArticle/AutoFormSubmitter'
import SelectMedia from 'src/modules/photo/SelectMedia'

import * as Yup from 'yup' // text entry validation

import { SourceType, SourcePropType } from 'src/modules/content/SourcesCommon'

import { useActionDispatcher } from '../app'
import {
  createEmptySource,
  deleteSource,
  saveSource,
  noteSourceBeChangedAgain,
  preSaveSource,
  createNewSource,
} from 'src/modules/writeArticle/writeArticleSlice'

import { v4 as uuidv4 } from 'uuid'
import FormikSelect from '../ui/FormikSelect'
import Typography from '../ui/Typography'
import Button from '../ui/Button'
import { CloseButtonDialog } from '../ui/CloseButtonDialog'
import { DialogActions, DialogContent } from '@mui/material'
import { handleRemoveEntity } from '../ui/editor/RemoveLink'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

/** @typedef {module:Source} Source */

const useStyles = makeStyles(theme => ({
  sourcesHeaderRow: {
    display: 'flex',
  },
  sourcesHeader: {},
  sourcesAddButtonContainer: {},
  toggleShowSources: {
    marginLeft: theme.spacing(2),
  },
  sourcesList: {
    //ul
    listStyleType: 'none',
    marginTop: 0,
    paddingLeft: 5,
  },
  edit: {
    /* REVIEW: this notation for composite class definitions isn't very nice because sourcesList can't be referenced as a property of the classes object */
    /*'& .sourcesList': {  //ul
      listStyleType: 'none',
      marginTop: 0,
      paddingLeft: 5,
    },*/

    '& .sourceContainer': {
      //li
      marginBottom: 20,
    },
  },
  sourceForm: {
    /*background: 'white',*/
    display: 'flex', // without this the delete button takes up an entire row
    padding: 0,
  },
  sourceDeleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  urlDescription: {
    '&:not(:empty):after': {
      content: "': '",
    },
  },
  sourceFieldsContainer: {
    flexGrow: 1,
  },

  selectMediaAddPhotoButton: {
    alignSelf: 'center',
  },

  photoRowXSmall: {
    width: '100%',
    display: 'flex',
    //height: 180,        // leave height unrestrained to allow for the [photo n] label and caption
    overflow: 'auto',
    '& img': {
      height: 140,
      width: 'auto',
    },
  },

  removeSourcePhotoButton: {
    background: 'white',
    //marginRight: theme.spacing(1),
  },
  removeSourcePhotoButtonContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  photoContainer: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

//utility function used to avoid lint errors everywhere
//eslint-disable-next-line react-hooks/exhaustive-deps
const useEffectOnlyOnce = func => useEffect(func, [])

const useSourceRefLinksStyles = makeStyles(theme => ({
  sourcesRefLinksContainer: {
    marginBottom: theme.spacing(2),
  },
  /*sourcesList: {  // ul
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    margin: 0,
  },
  sourceContainer: {   // li
    display: 'inline-block',
    //marginRight: theme.spacing(1),
    marginRight: 5,
  },*/
}))

/**
 * Renders a list of numbered anchor links to sources that are not linked in contentBlock text, detail expected to be at the bottom of the article
 *
 *
 * @param {object} props
 * @param {number} props.contentId
 * @param {Source[]} props.sources
 * @param {boolean} props.collatePhotos
 */
export const SourceRefLinks = ({ contentId, sources, collatePhotos }) => {
  // console.debug(`SourceRefLinks: called with sources:`, sources)

  const classes = useSourceRefLinksStyles()

  let filteredSources = []
  if (sources) {
    //first filter out the sources that are linked in the article text
    filteredSources = sources.filter(source => !source.linkedInText)

    if (collatePhotos) {
      //reorder the array so that the photos come last
      filteredSources = [
        ...filteredSources.filter(source => source.type !== SourceType.PHOTO),
        ...filteredSources.filter(source => source.type === SourceType.PHOTO),
      ]
    }
  }
  return (
    <>
      {filteredSources.length > 0 && (
        <div className={classes.sourcesRefLinksContainer}>
          {filteredSources.map((source, idx) => {
            return (
              <span key={source.id}>
                <SourceLink
                  contentId={contentId}
                  source={source}
                  editMode={false}
                />
                &nbsp;
              </span>
            )
          })}
        </div>
      )}
    </>
  )
}
SourceRefLinks.propTypes = {
  contentId: PropTypes.number,
  // eslint-disable-next-line
  sources: PropTypes.arrayOf(PropTypes.object),
  collatePhotos: PropTypes.bool,
}

/**
 * @callback FormikValidationWatcherOnErrorChangeCallback
 * @param {object} errors
 * @param {object} values
 */

/**
 * @callback FormikValidationWatcherPassFormikContextCallback
 * @param {object} formikContext - Formik's context  // FormikContextType<any>
 */

/**
 * Add as a Formik child to receive callbacks:
 *  - provide the formikContext on render
 *  - errors every time validation status changes
 *
 * @typedef {object} FormikValidationWatcherProps
 * @property {FormikValidationWatcherOnErrorChangeCallback} onErrorChange
 * @property {FormikValidationWatcherPassFormikContextCallback} passFormikContext
 */
const FormikValidationWatcher = ({ onErrorChange, passFormikContext }) => {
  // console.debug(
  //   'FormikValidationWatcher(): rendering, called with onErrorChange:',
  //   onErrorChange
  // )

  const { errors, validateForm, values } = useFormikContext()

  const [prevErrorState, setPrevErrorState] = useState(null)

  const fc = useFormikContext()
  useEffect(() => {
    //console.debug('FormikValidationWatcher.useEffect(): running...')
    if (onErrorChange) {
      const thereAreSomeErrors = Object.keys(errors).length !== 0
      //all this pullava is because of react-hooks/exhaustive-deps. Really we only want 'errors' in useEffect's dependency array after this function but the linter complains that values
      //needs to be in the array too, which means this code gets fired all the time when actually we only want it to run when the errors object changes
      if (prevErrorState === null || prevErrorState !== thereAreSomeErrors) {
        setPrevErrorState(thereAreSomeErrors)
        // console.debug('FormikValidationWatcher.useEffect(): errors changed...')
        if (!thereAreSomeErrors) {
          //} && isSubmitting) {
          // console.debug('FormikValidationWatcher.useEffect(): errors clear!')
        } else {
          // console.debug('FormikValidationWatcher.useEffect(): errors present.')
        }
        // console.debug(
        //   'FormikValidationWatcher.useEffect(): calling onErrorChange(errors, values)...',
        //   errors,
        //   values
        // )
        onErrorChange(errors, values)
      } else {
        // console.debug(
        //   'FormikValidationWatcher.useEffect(): no change in errors.'
        // )
      }
    } else {
      // console.debug(
      //   'FormikValidationWatcher.useEffect(): onErrorChange not set.'
      // )
    }

    //if (Object.keys(errors).length === 0) { //} && isSubmitting) {
    //  console.debug("FormikValidationWatcher: calling fc.submitForm()...")
    //  fc.submitForm()
    //}
  }, [errors, onErrorChange, values, prevErrorState])

  //only want to do this once!
  //It seems that errors is updated to be empty when the form is rendered, without running the validation.
  //So run it from here.
  useEffectOnlyOnce(() => {
    if (onErrorChange) {
      // console.debug(
      //   'FormikValidationWatcher.useEffect(): calling validateForm()...'
      // )
      validateForm()
    }
  })

  //const fc = useFormikContext()
  if (passFormikContext) {
    passFormikContext(fc)
  }

  return <></>
}
FormikValidationWatcher.propTypes = {
  onErrorChange: PropTypes.func.isRequired,
  passFormikContext: PropTypes.func.isRequired,
}

/**
 * Renders an ArticlePhoto with a MediaNavigatorDialog when clicked
 *
 * @param {object} props
 * @param {string} props.className
 * @param {object} props.photo
 * @param {Function} props.onClick
 */
const ClickableArticlePhoto = ({ className, photo, onClick }) => {
  const [clickedPhoto, setClickedPhoto] = useState(null)

  const handleClick = () => {
    setClickedPhoto(photo)
    onClick()
  }
  return (
    <>
      {!clickedPhoto && (
        <ArticlePhoto
          className={className}
          editable={false}
          imageClassName={undefined}
          photo={photo}
          onClick={handleClick}
          handleLoaded={undefined}
          children={undefined}
          height={undefined}
        />
      )}

      {!!clickedPhoto && (
        <MediaNavigatorDialog
          photo={clickedPhoto}
          onReportClosed={() => setClickedPhoto(null)}
          onRequestNext={undefined}
          onRequestPrev={undefined}
          canRequestNext={undefined}
          canRequestPrev={undefined}
          handleDelete={undefined}
        />
      )}
    </>
  )
}
ClickableArticlePhoto.propTypes = {
  className: PropTypes.string,
  photo: PropTypes.shape({ id: PropTypes.string }),
  onClick: PropTypes.func,
}

/**
 * @callback SourceSubmitFunc
 * @param {string} contentId
 * @param {Source} data
 * @param {object} formikActions   // FormikHelpers<Source>
 */

/**
 * @callback DeleteSourceFunc
 * @param {string} sourceId
 * @param {string} contentBlockId
 * @param {string} contentId
 */

/**
 * @callback EditSourceFunc
 * @param {Source} source
 * @param {boolean} isCreateNew
 * @param {string} dialogTitle
 * @param {SourceSubmitFunc} onSubmitFn - called when the submit button is clicked when the form is valid
 * @param {Function} onDeleteFn
 */

const useSourceLinkStyles = makeStyles(theme => ({
  photoContainer: {
    marginRight: '0 !important',
    //height: 140,
    '& img': {
      height: 140,
      width: 'auto',
    },
  },
  link: {
    cursor: 'pointer',
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
}))

/**
 * Renders a link representing a source which appears as a superscript number in square brackets e.g. [1].
 * clicking it pops up a flat dialog with source details and optionally an edit link.
 *
 * called from:
 *  - parseOptions.js parse(), used by html-react-parser called by ContentTextBlock
 *  - SourceRefLinks below (a list of numbered anchor links to sources that are not linked in contentBlock text)
 *  - RichTextField.js
 *
 * @param {object} props
 * @param {string} props.contentId
 * @param {Source} props.source
 * @param {boolean} props.editMode
 * @param {EditSourceFunc} [props.editSource]
 * @param {object} [props.onEditorStateChange]
 * @param {Function} [props.getCurrentEditorState]
 * @param {object} [props.entity]
 * @param {Function} [props.onDialogVisible]
 */
export const SourceLink = ({
  contentId,
  source,
  editMode,
  editSource = undefined,
  onEditorStateChange,
  getCurrentEditorState,
  entity,
  onDialogVisible,
}) => {
  const classes = useSourceLinkStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [showImagePreview, setShowImagePreview] = useState(true)

  const handlePopoverOpen = event => {
    if (onDialogVisible) {
      onDialogVisible(true)
    }
    setShowImagePreview(true)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    if (onDialogVisible) {
      onDialogVisible(false)
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  //console.debug('SourcesUI.SourceLink: source:', source)
  if (!source) {
    console.error(`SourcesUI.SourceLink: called with null source`)
    console.trace()
  }

  const dispatchSaveSource = useActionDispatcher(saveSource) //saveSource is a thunk imported from writeArticleSlice.js
  const dispatchDeleteSource = useActionDispatcher(deleteSource) // thunk imported from writeArticleSlice.js

  const dispatch = useDispatch()

  //passed to formik (possibly inside dialog) to be called when the form is submitted
  const handleEditSourceSubmit = (contentId, values, formikActions) => {
    //    console.debug(
    //      `SourceLink.handleEditSourceSubmit(): source for contentBlockId '${values.contentBlockId}' creating/updating... values:`,
    //      values
    //    )

    if (!values.contentBlockId) {
      console.error(
        `SourceLink.handleEditSourceSubmit(): values.contentBlockId is null. values:`,
        values
      )
    }

    //console.debug("EditableSourceDialog.handleEditSourceSubmit(): calling dispatchSaveSource(). values:", values)
    const sourceToSave = preSaveSource(values, values.contentBlockId, dispatch)
    dispatchSaveSource({
      contentId: contentId,
      contentBlockId: values.contentBlockId,
      sourceToSave: sourceToSave,
      formikActions: formikActions,
      alreadyInState: true,
    })
    //    console.debug(
    //      'SourceLink.handleEditSourceSubmit(): dispatchSaveSource() returned. values:',
    //      values
    //    )

    //savedCallback() will be called after the new source has been saved and added to state
  }

  const deleteSourceLinkInContentBlock = (
    link,
    contentId,
    contentBlockId,
    sourceId
  ) => {
    console.debug(
      `SourceLink.deleteSourceLinkInContentBlock(): called for sourceId '${sourceId}' link`,
      link
    )

    // SourceLink's props are set at render time as the RichTextField is rendered and editorState is immutable,
    // so it is probably out-of-date by now. Get an updated one.
    const editorState = getCurrentEditorState()
    // console.debug(
    //   `SourceLink.deleteSourceLinkInContentBlock(): calling handleRemoveEntity(editorState: ${editorState}, onEditorStateChange: ${onEditorStateChange})...`
    // )
    handleRemoveEntity(editorState, onEditorStateChange, entity, sourceId)
    // console.debug(
    //   `SourceLink.deleteSourceLinkInContentBlock(): handleRemoveEntity() done.`
    // )
  }

  return (
    <>
      <sup>
        <button
          role={'link'}
          onClick={e => {
            handlePopoverOpen(e)
            e.preventDefault()
            //return false
          }}
          className={classes.linkButton}
        >
          [{source.numberWithinArticle}]
        </button>
      </sup>
      <Popover
        id="mouse-over-popover"
        //sx={{
        //  pointerEvents: 'none',
        //}}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            display: !showImagePreview ? 'none' : 'auto',
          },
        }}
      >
        <Typography sx={{ p: 1 }}>
          {(() => {
            switch (source.type) {
              case SourceType.FREE_TEXT:
                return source.freeText
              case SourceType.URL:
                return (
                  <>
                    {source.urlDescription}
                    <p>
                      <a
                        href={source.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {source.url}
                      </a>
                    </p>
                  </>
                )
              case SourceType.PHOTO:
                return (
                  <ClickableArticlePhoto
                    className={classes.photoContainer}
                    photo={source.media}
                    onClick={() => setShowImagePreview(false)}
                  />
                )
              default:
                //case undefined:
                return <></>
            }
          })()}
        </Typography>

        {
          // EDIT link
          editMode && (
            <div style={{ width: '100%', display: 'flex' }}>
              {contentId && (
                <>
                  <ConfirmDialog
                    onConfirm={() => {
                      deleteSourceLinkInContentBlock(
                        this,
                        contentId,
                        source.contentBlockId,
                        source.id
                      )
                      dispatchDeleteSource({
                        contentId,
                        contentBlockId: source.contentBlockId,
                        sourceId: source.id,
                      })
                      //close popover
                      handlePopoverClose()
                    }}
                    submitText="Delete"
                    trigger={props => (
                      <>
                        <IconButton
                          permissionAction={ACTION_ALL_ACCESS}
                          {...props}
                          size="small"
                          color="primary"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  >
                    <Typography>Delete this source?</Typography>
                  </ConfirmDialog>
                </>
              )}
              <Typography sx={{ p: 1 }} style={{ marginLeft: 'auto' }}>
                {/*<a href={`#source_${source.id}`} onClick={(e) => jumpToEditSource(source)}>edit</a>*/}
                <button
                  role={'link'}
                  onClick={e => {
                    handlePopoverClose()
                    //setOpenEditDialog(true)
                    if (editSource) {
                      //if the <Dialog ...> is mounted in this component, which is itself mounted inside
                      //the RichTextField, the textfields inside the dialog keep losing focus to the RichTextField behind it.
                      //So instead the Dialog is mounted at the parent level (WriteArticle) and the function to open it passed in as a prop (editSource).
                      //fn editSource is passed as a prop from RichTextField.js, which is passed into it by EditableSourceDialog
                      //deleteSourceLinkInContentBlock is defined above and captures properties from the edittext/draft.js that are needed to edit the content to remove the link
                      editSource(
                        source,
                        false,
                        'Edit source',
                        handleEditSourceSubmit,
                        deleteSourceLinkInContentBlock
                      )
                    } else {
                      console.error(
                        "SourceLink.editButton.onClick(): 'editSource' not provided."
                      )
                    }
                  }}
                  className={classes.linkButton}
                >
                  edit
                </button>
              </Typography>
            </div>
          )
        }
      </Popover>
    </>
  )
}

SourceLink.propTypes = {
  contentId: PropTypes.string,
  source: SourcePropType.isRequired,
  editMode: PropTypes.bool,
  editSource: PropTypes.func,
  //editorState: PropTypes.func,
  onEditorStateChange: PropTypes.func,
  getCurrentEditorState: PropTypes.func,
  //deleteSource: PropTypes.func,
  entity: PropTypes.object, //eslint-disable-line react/forbid-prop-types
  onDialogVisible: PropTypes.func,
}

const useEditableSourceDialogStyles = makeStyles(theme => ({
  linkForm: {
    minWidth: 300,
  },

  photoContainer: {
    height: 140,
    '& img': {
      height: 140,
      width: 'auto',
    },
    marginTop: theme.spacing(2),
  },
}))

/**
 * Renders a dialog containing an EditableSource which is opened with data passed at runtime by a child calling the provided `editSource` function
 *
 * @param {object} props
 * @param {string} props.contentId
 * @param {string} props.contentBlockId
 * @param {any} props.onDialogVisible
 * @param {any} props.children
 */
export const EditableSourceDialog = ({
  contentId,
  contentBlockId,
  onDialogVisible,
  children,
}) => {
  const classes = useEditableSourceDialogStyles()

  const [currentRuntimeProps, setCurrentRuntimeProps] = React.useState()

  //this function is passed to the children: the toolbar's create button and the RichTextField's decorator (SourceLink in this file) that renders links' popup with edit button
  /**
   * @type {EditSourceFunc}
   */
  const editSource = (
    existingSource,
    isCreateNew,
    dialogTitle,
    onSubmit,
    onDelete
  ) => {
    if (!existingSource && !contentBlockId) {
      console.error(
        'SourcesUI.js EditableSourceDialog.editSource(): called with null source when contentBlockId is null'
      )
    }

    // console.debug(
    //   `SourcesUI.tsx EditableSourceDialog.editSource(): called with existingSource:`,
    //   existingSource
    // )

    if (onDialogVisible) {
      onDialogVisible(true)
    }

    const sourceToEdit = existingSource
      ? { ...existingSource, lockDuringSave: true }
      : createNewSource({
          id: undefined,
          type: null,
          newSourceProps: {
            focusTextInput: true,
            contentBlockId: contentBlockId,
            linkedInText: true,
          },
        }) //set the type to '' because Mui's select does not like undefined

    setCurrentRuntimeProps({
      showTypeSelect: !existingSource,
      source: sourceToEdit,
      isCreateNew: isCreateNew,
      dialogTitle: dialogTitle,
      onSubmitFn: onSubmit,
      submitButtonLabel: existingSource
        ? 'Update source'
        : 'Create source & insert link',
      onDeleteFn: onDelete,
    })
  }

  //grab a reference to formik's submitForm function from the formik context
  let formikSubmitForm
  //EditableSource calls this with the formikContext
  const formikCallback = newFormikContext => {
    if (newFormikContext) {
      //console.debug("SourcesUI.tsx EditableSourceDialog.formikCallback(): called with formikContext.handleSubmit:", formikContext.handleSubmit)
      formikSubmitForm = newFormikContext.submitForm
    } else {
      // console.debug(
      //   'SourcesUI.js EditableSourceDialog.formikCallback(): called with null formikContext'
      // )
    }
  }

  //called from the submit button below
  // this submits the formik form (using formik's normal flow), on success formik will call handleFormikSubmit()
  const submitButtonOnClick = () => {
    if (formikSubmitForm) {
      formikSubmitForm()
    }
  }

  const handleFormikSubmit = (contentId, values, formikHelpers) => {
    if (currentRuntimeProps) {
      currentRuntimeProps.onSubmitFn(contentId, values, formikHelpers) // a SourceSubmitFunc
      setCurrentRuntimeProps(undefined)
      if (onDialogVisible) {
        onDialogVisible(false)
      }
    } else {
      console.error(
        'SourcesUI.js EditableSourceDialog.handleFormikSubmit(): called when currentRuntimeProps is null'
      )
    }
  }

  // enables/disables the submit button depending on if the form fields have errors
  const [canSubmit, setCanSubmit] = useState(false)

  return (
    <>
      {currentRuntimeProps && (
        <>
          <CloseButtonDialog
            open={currentRuntimeProps !== null}
            closeButtonOnClick={() => {
              //close the dialog by setting value to falsey
              setCurrentRuntimeProps(undefined)
              if (onDialogVisible) {
                onDialogVisible(false)
              }
            }}
            dialogTitle={currentRuntimeProps.dialogTitle}
          >
            <DialogContent>
              <div className={classes.linkForm}>
                <EditableSource
                  contentId={contentId}
                  contentBlockId={contentBlockId}
                  showTypeSelect={currentRuntimeProps.showTypeSelect}
                  formikCallback={formikCallback}
                  source={currentRuntimeProps.source}
                  handleFormikSubmit={handleFormikSubmit}
                  classes={classes}
                  autosave={false}
                  onErrorChange={(errors, values) => {
                    // console.debug(
                    //   'SourcesUI.js.EditableSourceDialog.onErrorChange(): errors changed... errors, values:',
                    //   errors,
                    //   values
                    // )
                    const valid = Object.keys(errors).length === 0
                    if (valid) {
                      //} && isSubmitting) {
                      // console.debug(
                      //   'SourcesUI.js.EditableSourceDialog.onErrorChange(): errors clear!'
                      // )
                    } else {
                      // console.debug(
                      //   'SourcesUI.js.EditableSourceDialog.onErrorChange(): errors present.'
                      // )
                    }
                    if (valid !== canSubmit) {
                      setCanSubmit(valid)
                      //canSubmit = valid
                    }
                  }}
                  handleDeleteSource={undefined}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <div style={{ width: '100%', display: 'flex' }}>
                {!currentRuntimeProps.isCreateNew ? (
                  <ConfirmDialog
                    submitText="Delete"
                    onConfirm={() => {
                      // console.debug(
                      //   `SourcesUI.EditableSourceDialog.Delete onConfirm(): deleting source - calling currentRuntimeProps.onDeleteFn()...`,
                      //   currentRuntimeProps.source
                      // )

                      //onDeleteFn is defined as SourceLink.deleteSourceLinkInContentBlock(), passed to editSource() - defined above in this EditableSourceDialog and passed as a prop to SourceLink
                      //editSource() sets the onDeleteFn as a property of currentRuntimeProps

                      currentRuntimeProps.onDeleteFn(
                        null,
                        contentId,
                        currentRuntimeProps.source.contentBlockId,
                        currentRuntimeProps.source.id
                      )
                      setCurrentRuntimeProps(undefined)
                      if (onDialogVisible) {
                        onDialogVisible(false)
                      }
                    }}
                    trigger={props => (
                      <IconButton
                        permissionAction={ACTION_ALL_ACCESS}
                        {...props}
                        size="large"
                        aria-label="delete"
                        className={undefined}
                        isLoading={undefined}
                        handleSubmit={undefined}
                        innerRef={undefined}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  >
                    <Typography>Delete this source?</Typography>
                  </ConfirmDialog>
                ) : (
                  <></>
                )}
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  color="primary"
                  disabled={!canSubmit}
                  isLoading={false}
                  onClick={submitButtonOnClick}
                  className={undefined}
                  style={{ marginLeft: 'auto' }}
                >
                  {
                    currentRuntimeProps.submitButtonLabel //            active={false}
                  }
                </Button>
              </div>
            </DialogActions>
          </CloseButtonDialog>
        </>
      )}
      {children({ editSource })}
    </>
  )
}
EditableSourceDialog.propTypes = {
  contentId: PropTypes.string,
  contentBlockId: PropTypes.string,
  onDialogVisible: PropTypes.func,
  children: PropTypes.func.isRequired, //a func rather than a node because this component passes `editSource` as an argument
}

/**
 * @param {object} props
 * @param {string} props.contentId
 * @param {string} props.contentBlockId
 * @param {boolean} props.showTypeSelect
 * @param {DeleteSourceFunc} [props.handleDeleteSource]
 * @param {SourceSubmitFunc} props.handleFormikSubmit
 * @param {object} props.classes
 * @param {Source} props.source
 * @param {boolean} props.autosave
 * @param {FormikValidationWatcherOnErrorChangeCallback} [props.onErrorChange]
 * @param {(formikContext)} [props.formikCallback]
 */
export const EditableSource = ({
  contentId,
  contentBlockId,
  showTypeSelect,
  handleDeleteSource,
  handleFormikSubmit,
  classes,
  source,
  autosave,
  onErrorChange,
  formikCallback,
}) => {
  // console.debug(`EditableSource: rendering form for source:`, source)
  //TODO add property source.focusOnRender, which will be set to true when created and then set to false

  if (!contentBlockId) {
    if (source.contentBlockId) {
      contentBlockId = source.contentBlockId
    } else {
      throw Error(
        'contentBlockId must be not null - either as component prop or source property'
      )
    }
  } else {
    if (source.contentBlockId !== contentBlockId) {
      console.error(
        `EditableSource: contentBlockId '${contentBlockId}' was passed but source.contentBlockId is: '${source.contentBlockId}'`
      )
    }
  }

  let formikContext
  const localFormikCallback = newFormikContext => {
    formikContext = newFormikContext

    if (formikCallback) {
      formikCallback(newFormikContext)
    }
  }

  //take a copy of the current source so edits to it are isolated until we save - when the global redux source will be updated by reducer writeArticleSlice.saveSource.fulfilled()
  //useEffect() to update it if it changes externally AND HAS NOT BEEN EDITED IN THE FORM!
  //localSource is not updated by any form edits unless explicity done below - which it is for the type selectlist
  const [localSource, setLocalSource] = React.useState(source)

  //if the redux global source changes, update the one used in this editor only if this editor is not dirty
  React.useEffect(() => {
    // console.debug(
    //   `EditableSource.useEffect(source): redux source change, checking if local form has been touched...`
    // )

    if (formikContext && formikContext.dirty) {
      // console.debug(
      //   `EditableSource.useEffect(source): redux source changed but local form is dirty, not updating localSource, just copying latest value of 'saving'...`
      // )
      //setLocalSource({...localSource, saving: source.saving})
      setLocalSource(currentLocalSource => {
        return { ...currentLocalSource, saving: source.saving }
      })
    } else {
      // console.debug(
      //   `EditableSource.useEffect(source): redux global source changed and local form not dirty, updating localSource to:`,
      //   source
      // )
      setLocalSource(source)
    }
  }, [source]) //eslint-disable-line react-hooks/exhaustive-deps
  // do not want to run this every time the formikContext changes as it runs too often.
  //TODO find solution to useEffect() dependencies that require ignoring lint check

  //this is updated with the last freetext or url field added to the page, used so we can focus the newly created textfield
  const latestTextFieldRef = useRef(null)

  const focusTextFieldNow = () => {
    //console.debug(`EditableSource.useEffect(null): localSource.focusTextInput: ${localSource.focusTextInput}, latestTextFieldRef:`, latestTextFieldRef)
    //if (focusInput && latestTextFieldRef && latestTextFieldRef.current) {
    if (
      localSource.focusTextInput &&
      latestTextFieldRef &&
      latestTextFieldRef.current
    ) {
      //TODO set source.focusTextInput = false after setting focus once
      //source.focusTextInput = false
      // console.debug(
      //   `EditableSource.focusTextFieldNow(): called when localSource.focusTextInput and latestTextFieldRef are set, focussing latestTextFieldRef...`,
      //   latestTextFieldRef.current
      // )
      latestTextFieldRef.current.focus()
    } else {
      // console.debug(
      //   `EditableSource.focusTextFieldNow(): called when localSource.focusTextInput or latestTextFieldRef is not set. localSource.focusTextInput, latestTextFieldRef:`,
      //   localSource.focusTextInput,
      //   latestTextFieldRef.current
      // )
    }
  }
  //this function is wrapped in a useCallback because if not it causes a warning when used in an effect because it may change and trigger the effect
  const memoizedFocusTextFieldNow = React.useCallback(focusTextFieldNow, [
    localSource.focusTextInput,
  ])

  //set focus on each render - used when adding a new source to the list in edit mode
  React.useEffect(() => {
    // console.debug(
    //   `EditableSource.useEffect(always): calling focusTextFieldNow()...`
    // )
    focusTextFieldNow()
  })

  const [newTextFieldAppeared, setNewTextFieldAppeared] = React.useState(false)

  const focusTextFieldOnNextRender = React.useCallback(() => {
    setNewTextFieldAppeared(true)
  }, [])
  //const memoizedFocusTextFieldOnNextRender = React.useCallback(focusTextFieldOnNextRender, [])
  React.useEffect(() => {
    // console.debug(
    //   `EditableSource.useEffect(): focus effect called, newTextFieldAppeared: ${newTextFieldAppeared}, checking it is true...`
    // )
    if (newTextFieldAppeared) {
      //need to check this because this effect will also be called when memoizedFocusTextFieldNow changes, which will be when localSource.focusTextInput changes
      // console.debug(
      //   `EditableSource.useEffect(): focus effect called, newTextFieldAppeared is true, calling memoizedFocusTextFieldNow(). latestTextFieldRef:`,
      //   latestTextFieldRef
      // )
      setNewTextFieldAppeared(false)
      //focusTextFieldNow()
      memoizedFocusTextFieldNow()
      // console.debug(
      //   `EditableSource.useEffect(): memoizedFocusTextFieldNow() done.`
      // )
    }
  }, [newTextFieldAppeared, memoizedFocusTextFieldNow])

  const sourceTypes = SourceType.toArray()

  // console.debug(
  //   `EditableSource: sourceTypes ready for FormikSelect options:`,
  //   sourceTypes
  // )

  const getSourceTypeKey = st => {
    // console.debug(
    //   `EditableSource.getSourceTypeKey(): called with sourceType:`,
    //   st
    // )
    if (st) {
      return st.getKey()
    } else {
      return null
    }
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().defined() /*.strict(true).min(999),*/,
    url: Yup.string()
      .nullable()
      .url('Must be a valid website URL beginning http:// or https://')
      .when('type', {
        is: SourceType.URL,
        then: Yup.string().required(),
      }),
    urlDescription: Yup.string().nullable().when('type', {
      is: SourceType.URL,
      then: Yup.string().required(),
    }),
    freeText: Yup.string().nullable().when('type', {
      is: SourceType.FREE_TEXT,
      then: Yup.string().required(),
    }),
    mediaId: Yup.string().nullable().when('type', {
      is: SourceType.PHOTO,
      then: Yup.string().required(),
    }),
  })

  // PHOTO

  const selectMediaTrigger = {
    onClick: () => {
      console.error(
        'SourcesUI.EditableSource.selectMediaTrigger(): default onClick(), should have been overridden!'
      )
    }, //this onClick function is overridden by <SelectMedia />, this code can call it to open the picker dialog
    onClickDebug: e => {
      //console.debug("SourcesUI.EditableSource.selectMediaTrigger(): clicked, calling onClick(), event:", e)
      selectMediaTrigger.onClick()
      //console.debug("SourcesUI.EditableSource.selectMediaTrigger(): onClick() returned.")
    },
  }

  const [selectedPhoto, setSelectedPhoto] = useState(null)

  const typeSelectListOnChange = useCallback(
    (key, setFieldValue) => {
      //name='type'
      // console.debug(
      //   'EditableSource.typeSelectListOnChange(): you chose: ' + key
      // )

      const st = SourceType[key]
      //values.type = st
      // console.debug(
      //   "EditableSource.typeSelectListOnChange(): that's sourceType: ",
      //   st
      // )

      setFieldValue('type', st)

      //const stReversed : SourceType = SourceType[st]

      /*console.debug("calling setLocalSource() with updated source.type:", st)
    setLocalSource( (prevState: Source) => ({
      ...prevState,
      type: st
    }))*/

      //this effectively just sets a flag that causes the next useEffect() call to bring focus to a textfield
      focusTextFieldOnNextRender()
    },
    [focusTextFieldOnNextRender]
  )

  /**
   * The form doesn't clear existing/previous input values when the type dropdown is changed in case the user wants
   * to change it back again within the same dialog. But we don't want unused/incorrect values to be saved to the db.
   *
   * Sets unused values to null because the server won't clear existing values in properties that are not mentioned
   *
   * @param {Source} values
   */
  const clearOtherValues = values => {
    if (values.type !== SourceType.FREE_TEXT) {
      values.freeText = null
    }

    if (values.type !== SourceType.URL) {
      values.url = null
      values.urlDescription = null
    }

    if (values.type !== SourceType.PHOTO) {
      values.media = null
      values.mediaId = null
    }
  }

  return (
    //can set Formik's enableReinitialize to true if we are careful when initialValues changes so that it doesn't overwrite user's typing
    //in between save HTTP request and response
    <Formik
      initialValues={localSource}
      onSubmit={(values, helpers) => {
        clearOtherValues(values)
        handleFormikSubmit(contentId, values, helpers)
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        errors,
        touched,
        values,
      }) => (
        <>
          <div className={classes.sourceForm}>
            {handleDeleteSource && (
              <div className={classes.sourceDeleteButtonContainer}>
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  disabled={
                    localSource.id.startsWith('newRecord') && isSubmitting
                  }
                  onClick={() => {
                    // console.debug(
                    //   `EditableSource.deleteOnClick(): contentBlockId: '${contentBlockId}', source.contentBlockId: '${source.contentBlockId}', calling handleDeleteSource()...`
                    // )
                    handleDeleteSource(
                      localSource.id,
                      contentBlockId,
                      contentId
                    )
                  }}
                  aria-label="delete"
                  className={undefined}
                  isLoading={undefined}
                  handleSubmit={undefined}
                  innerRef={undefined}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
            <div className={classes.sourceFieldsContainer}>
              {showTypeSelect && ( //helperText='What type of source is this?'
                //Mui's Select's 'name' prop doesn't seem to work as we'd prefer with enums. For name='type' below it looks at source.type which is an enum but it gets the value assigned to the enum's property
                // - sometimes a display string - rather than the enum property name.
                <FormikSelect
                  label="Source type"
                  options={sourceTypes}
                  value={getSourceTypeKey(values && values.type) || ''}
                  name="type"
                  idPrefix="source-type"
                  onChange={key => {
                    typeSelectListOnChange(key, setFieldValue)
                  }}
                />
              )}
              {(() => {
                //switch(localSource.type) {
                switch (values.type) {
                  case SourceType.FREE_TEXT: //  inputProps={{ref: latestTextFieldRef}} style={{ outline: "none" }}
                    return (
                      <FormikTextField
                        multiline={true}
                        fullWidth
                        label="Free text"
                        name="freeText"
                        helperText="Describe the evidence"
                        inputProps={{ ref: latestTextFieldRef }}
                        InputProps={{
                          readOnly:
                            localSource.lockDuringSave && localSource.saving,
                          endAdornment: (
                            <InputAdornment position="end">
                              <div>
                                <div
                                  style={{
                                    visibility: localSource.saving
                                      ? 'visible'
                                      : 'hidden',
                                  }}
                                >
                                  <CircularProgress />
                                </div>
                                {/*<div style={  localSource.saving ? {opacity: 0, transition: "all 250ms linear 5s" } : {} }  >
                              <CheckCircleIcon
                                  style={{ color: "#05cc30" }}
                              ></CheckCircleIcon>
                            </div> */}
                              </div>
                              {/*

                                className={`alert alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}

                                onTransitionEnd={() => setShowingAlert(false)}
                          !errors.email && touched.email && (

                              <CheckCircleIcon
                                  style={{ color: "#05cc30" }}
                              ></CheckCircleIcon>
                          )*/}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  case SourceType.URL:
                    return (
                      <span>
                        <FormikTextField
                          inputProps={{ ref: latestTextFieldRef }}
                          fullWidth
                          label="Web link"
                          name="url"
                          helperText="The URL to the third-party resource, e.g. https://nationalarchives.org.uk/documents/23245345.pdf"
                        />{' '}
                        <FormikTextField
                          fullWidth
                          label="Web link description"
                          name="urlDescription"
                          helperText="What is this a web link to?"
                        />
                      </span>
                    )
                  // edit-mode non-collated photos not implemented
                  case SourceType.PHOTO:
                    return (
                      <>
                        <SelectMedia
                          mediaType="PHOTO"
                          onSelect={photo => {
                            // console.debug(
                            //   `SourcesUI.EditableSource.SelectMedia.onSelect(): called with photo:`,
                            //   photo
                            // )
                            //values.media = photo
                            setSelectedPhoto(photo)
                            setFieldValue('mediaId', photo.id)
                          }}
                          cropAfterSelect={false}
                          trigger={o => {
                            //SelectMedia calls this function and passes it an onClick function which we can call to show the media picker
                            // console.debug(
                            //   `SourcesUI.selectMediaTriggerSetter(): called with o`,
                            //   o
                            // )
                            selectMediaTrigger.onClick = o.onClick
                            return <div></div>
                          }}
                          aspectRatio={undefined}
                          onSelectCropped={undefined}
                        />
                        <Button
                          permissionAction={ACTION_ALL_ACCESS}
                          isLoading={false}
                          disabled={false}
                          onClick={selectMediaTrigger.onClickDebug}
                          className={undefined}
                        >
                          Choose or upload photo
                        </Button>

                        {selectedPhoto && (
                          <ClickableArticlePhoto
                            className={classes.photoContainer}
                            photo={selectedPhoto}
                          />
                        )}
                      </>
                    )
                  default:
                    //case undefined:
                    return <></> //<SourceTypeSelectList onClickItem={onClickSelectedSourceType} source={{}} />
                  //default:
                }
              })()}
            </div>
            {autosave && <AutoFormSubmitter />}
            {(onErrorChange || localFormikCallback) && (
              <FormikValidationWatcher
                onErrorChange={onErrorChange}
                passFormikContext={localFormikCallback}
              />
            )}
          </div>
        </>
      )}
    </Formik>
  )
}
EditableSource.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentBlockId: PropTypes.string.isRequired,
  showTypeSelect: PropTypes.bool.isRequired,
  handleDeleteSource: PropTypes.func,
  handleFormikSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object, //eslint-disable-line react/forbid-prop-types
  // not happy about this but not about to copy/paste the styles definition from above
  source: SourcePropType.isRequired,
  autosave: PropTypes.bool,
  onErrorChange: PropTypes.func,
  formikCallback: PropTypes.func,
}

/* @typedef {Object} SourceTypeMenuProps
 * @property {any} addMenuAnchorEl
 * @property {(sourceType:SourceType)=>void} onClickAddMenuItem
 * @property {()=>void} closeAddMenu
 * @property {boolean} [open]
 */
export const SourceTypeMenu = ({
  addMenuAnchorEl,
  onClickAddMenuItem,
  closeAddMenu,
  open,
}) => {
  const onSelection = async sourceType => {
    await onClickAddMenuItem(sourceType)
  }
  return (
    <Menu
      id="add-menu"
      anchorEl={addMenuAnchorEl}
      keepMounted
      open={open === undefined ? Boolean(addMenuAnchorEl) : Boolean(open)}
      onClose={closeAddMenu}
    >
      <MenuItem onClick={() => onSelection(SourceType.URL)}>
        Link to a web page
      </MenuItem>
      <MenuItem onClick={() => onSelection(SourceType.PHOTO)}>Photo</MenuItem>
      <MenuItem onClick={() => onSelection(SourceType.FREE_TEXT)}>
        Free text
      </MenuItem>
    </Menu>
  )
}
SourceTypeMenu.propTypes = {
  addMenuAnchorEl: PropTypes.func,
  onClickAddMenuItem: PropTypes.func,
  closeAddMenu: PropTypes.func,
  open: PropTypes.bool,
}

/**
 * Renders:
 *   a header line: Sources (<count>)  <expand/collapsed toggle> (if in edit mode: <add source button>)
 *   a collapsible section containing:
 *     a line per non-photo source:
 *       if in edit mode, a form containing text fields for URL and URL description or free text
 *       if in non-edit mode, displaying URL and URL description or free text
 *     photo sources at the bottom
 *     if in edit mode a non-visible SelectMedia component, activated by the add source button
 *     a MediaNavigatorDialog activated by clicking on a photo
 *     a scrollable row of photos that have already been added, if in edit mode each has a delete button
 *
 *
 *
 *   'add source' button shows a popup of source types - free text/url/photo
 *
 *   Text fields are automatically saved 1 second after typing.
 *
 *
 * @param {object} props
 * @param {Source[]} props.sources
 * @param {string} props.contentBlockId
 * @param {string} props.contentId
 * @param {boolean} props.editMode
 * @param {boolean} [props.showAddButton = false]
 * @param {boolean} [props.collapsible = true]
 * @param {boolean} [props.initiallyExpanded = false]
 * @param {boolean} [props.collatePhotos = true]
 * @param {string} props.title - section header for the exandable block
 * @param {boolean} props.hideSourceNumber
 * @param {boolean} props.SourceContainer - Element to wrap source info (to change design if required)
 */
export const Sources = ({
  sources,
  contentBlockId,
  contentId,
  editMode,
  showAddButton = false,
  collapsible = true,
  initiallyExpanded = false,
  collatePhotos = true,
  title = 'SOURCES',
  hideSourceNumber = false,
  SourceContainer = Box,
}) => {
  // console.debug(
  //   `Sources: FC called with contentBlockId ${contentBlockId} and contentId ${contentId}, editMode: ${editMode}, sources:`,
  //   sources
  // )
  const classes = useStyles()

  const dispatch = useDispatch()

  const [expanded, setExpanded] = React.useState(
    initiallyExpanded || !collapsible
  )

  const dispatchSaveSource = useActionDispatcher(saveSource) //saveSource is a thunk imported from writeArticleSlice.js
  const dispatchDeleteSource = useActionDispatcher(deleteSource) // thunk imported from writeArticleSlice.js
  const dispatchNoteSourceBeChangedAgain = payload => {
    dispatch(noteSourceBeChangedAgain(payload)) //noteSourceBeChangedAgain is an action not a thunk
  }

  //called by AutoFormSubmitter after the user has stopped typing for 1000ms
  const onSourceFormSubmit = async (
    contentBlockId,
    contentId,
    source,
    formikActions
  ) => {
    // console.debug(
    //   `SourcesUI.onSourceFormSubmit(): called with contentBlockId: '${contentBlockId}', contentId: '${contentId}', source and formikActions`,
    //   source,
    //   formikActions
    // )

    //if we don't have an id from the API yet and a call is currently inflight, don't call the API again concurrently or two records will be created.
    if (source.id.startsWith('newRecord') && source.firstSaveCalled) {
      // update this source object in redux global state to set property saveAgain = true
      // this will be checked when the inflight call ends in reducer 'saveSource.fulfilled'
      // console.debug(
      //   `SourcesUI.onSourceFormSubmit(): not saving again because id is still '${source.id}' and source.firstSaveCalled is: '${source.firstSaveCalled}', calling dispatchNoteSourceBeChangedAgain()...`
      // )
      const arg = {
        sourceId: source.id,
        contentBlockId: contentBlockId,
      }
      dispatchNoteSourceBeChangedAgain(arg) //calls writeArticleSlice.reducers.noteSourceBeChangedAgain()

      // console.debug(
      //   `SourcesUI.onSourceFormSubmit(): dispatchNoteSourceBeChangedAgain() returned.`
      // )
    } else {
      source.firstSaveCalled = true
      source.saveAgain = false

      const preSaved = preSaveSource(source, contentBlockId, dispatch)

      const arg = {
        contentBlockId: contentBlockId,
        contentId: contentId,
        sourceToSave: preSaved,
        formikActions: formikActions,
      }
      dispatchSaveSource(arg) //calls writeArticleSlice.saveSource
    }
  }

  //passed to EditableSource
  const handleDeleteSource = async (sourceId, contentBlockId, contentId) => {
    // console.debug(
    //   `SourcesUI.handleDeleteSource(): contentBlockId: '${contentBlockId}', sourceId: '${sourceId}', calling dispatchDeleteSource()...`
    // )
    dispatchDeleteSource({ sourceId, contentBlockId, contentId })
  }

  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null)
  const closeAddMenu = () => {
    setAddMenuAnchorEl(null)
  }
  const onClickAddMenuItem = async sourceType => {
    // console.debug(
    //   `SourcesUI.onClickAddMenuItem(): menu item clicked: ${sourceType}`
    // )

    //let setLatestTextFieldFocus = false
    switch (sourceType) {
      case SourceType.PHOTO:
        // console.debug(
        //   `SourcesUI.onClickAddMenuItem(): calling selectMediaTrigger.onClick()...`,
        //   selectMediaTrigger
        // )
        selectMediaTrigger.onClick()
        break
      default:
        //setLatestTextFieldFocus = true
        // focusTextInput is checked by an EditableSource.useEffect(), if true and a ref is set it will set the focus
        await dispatch(
          createEmptySource({
            contentBlockId,
            contentId,
            sourceType,
            newSourceProps: { focusTextInput: true },
          })
        ) //createEmptySource is from writeArticleSlice
      //console.debug("onClickAddMenuItem() called createEmptySource(), created new source: ", newSource)
    }
    setExpanded(true)
    closeAddMenu()

    //TODO perhaps this, and so setLatestTextFieldFocus, could be moved into EditableSource?
    //if (setLatestTextFieldFocus) {
    //  console.debug(`SourcesUI.onClickAddMenuItem(): createEmptySource() returned, focussing latestTextFieldRef...`, latestTextFieldRef)
    //  latestTextFieldRef && latestTextFieldRef.current && latestTextFieldRef.current.focus();
    //}
  }
  const selectMediaTrigger = {
    onClick: () => {
      //placeholder
    }, //this onClick function is overridden by <SelectMedia />, this code can call it to open the picker dialog
  }
  // called by SelectMedia's onSelect
  const handleAddPhotoSource = (photoArg, formikActions) => {
    // console.debug(
    //   `SourcesUI.handleAddPhotoSource(): called with photoArg: ${photoArg}`
    // )

    const newSource = {
      type: SourceType.PHOTO,
      //media: photoArg,
      mediaId: photoArg.id,
      id: `newRecord${uuidv4()}`, //will be changed after it's saved to the db
      firstSaveCalled: false,
      saving: false,
      lockDuringSave: false,
      saveAgain: false,
    }

    const arg = {
      contentBlockId: contentBlockId,
      contentId: contentId,
      sourceToSave: newSource,
      formikActions: formikActions,
    }

    try {
      // console.debug(
      //   `SourcesUI.handleAddPhotoSource(): called with photoArg: ${photoArg}`
      // )
      // console.debug(
      //   `SourcesUI.handleAddPhotoSource(): calling dispatchSaveSource(): ${arg}`
      // )

      dispatchSaveSource(arg) //calls writeArticleSlice.saveSource

      //TODO error handling when calling dispatchSaveSource()?

      // dispatchAddPhotoSource(  //calls writeArticleSlice.addPhotoSource
      //   { contentId, contentBlockId, newSource},
      //   {
      //     errorNotification: (err: any) => err.data && err.data.photo,
      //   }
      // ).unwrap()
    } catch (err) {}
  }

  // click on photo to see detail
  const [clickedPhoto, setClickedPhoto] = useState(null)
  //const handleDialogClose = () => {
  //  setClickedPhoto(null)
  //}

  const handleFormikSubmit = async (contentId, data, formikActions) => {
    // console.debug(
    //   `SourcesUI.handleFormikSubmit(): calling onSourceFormSubmit(), data:`,
    //   data
    // )
    await onSourceFormSubmit(contentBlockId, contentId, data, formikActions)
  }

  //this is updated with the last freetext or url field added to the page, used so we can focus the newly created textfield
  //const latestTextFieldRef: MutableRefObject<any> = useRef(null)

  let photoSources = []

  if (sources && sources.length > 0) {
    //sources = sources.sort((a, b) => ((a.numberWithinArticle === undefined) ? 0 : a.numberWithinArticle ) > ((b.numberWithinArticle === undefined) ? 0 : b.numberWithinArticle) ? 1 : -1)
    sources = [...sources].sort((a, b) => {
      const aNum =
        a.numberWithinArticle === undefined ? 0 : a.numberWithinArticle
      const bNum =
        b.numberWithinArticle === undefined ? 0 : b.numberWithinArticle
      return aNum > bNum ? 1 : -1
    })

    photoSources = sources.filter(src => src.type === SourceType.PHOTO)
  }

  return (
    <div className={`${editMode ? classes.edit : ''}`}>
      <div className={classes.sourcesHeaderRow}>
        <div className={classes.sourcesHeader}>
          {editMode || sources.length > 0 ? (
            <Typography variant="subtitle2">
              {title} ({sources.length}):
              {collapsible && sources.length > 0 && (
                <FormControlLabel
                  className={classes.toggleShowSources}
                  control={
                    <Switch
                      checked={expanded}
                      onChange={() => {
                        setExpanded(prev => !prev)
                      }}
                    />
                  }
                  label="Show"
                />
              )}
            </Typography>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.sourcesAddButtonContainer}>
          {editMode && showAddButton && (
            <>
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                isLoading={false}
                aria-label="add"
                onClick={e => setAddMenuAnchorEl(e.currentTarget)}
                className={undefined}
                handleSubmit={undefined}
                innerRef={undefined}
              >
                <AddIcon />
              </IconButton>
              <SourceTypeMenu
                addMenuAnchorEl={addMenuAnchorEl}
                onClickAddMenuItem={onClickAddMenuItem}
                closeAddMenu={closeAddMenu}
              />
            </>
          )}
        </div>
      </div>

      <Collapse in={expanded}>
        {/*<ul className={`${classes.sourcesList} ${editMode ? classes.edit : ''}`}>*/}
        {/*<ul className={clsx(classes.sourcesList, {[classes.sourcesList.edit]: editMode })}> */}

        {expanded && (
          <ul className={classes.sourcesList}>
            {/* <ul className='sourcesList'>*/}
            {sources.map((source, idx) => {
              return (
                (!collatePhotos || source.type !== SourceType.PHOTO) && (
                  <li
                    key={source.id}
                    className={'sourceContainer'}
                    id={`source_${source.id}`}
                  >
                    {/* {console.debug(
                      `Sources: editMode: ${editMode}, rendering source ${idx}:`,
                      source
                    )} */}

                    {editMode ? (
                      <>
                        {/* {console.debug(
                          `Sources:  calling <EditableSource /> for contentBlockId '${contentBlockId}', source:`,
                          source
                        )} */}
                        <EditableSource
                          contentId={contentId}
                          contentBlockId={contentBlockId}
                          showTypeSelect={false}
                          handleDeleteSource={handleDeleteSource}
                          handleFormikSubmit={handleFormikSubmit}
                          classes={classes}
                          source={source}
                          autosave={true}
                        />
                      </>
                    ) : (
                      <SourceContainer>
                        <Typography>
                          {!hideSourceNumber &&
                            `[${source.numberWithinArticle}]`}
                          {(() => {
                            switch (source.type) {
                              case SourceType.FREE_TEXT:
                                return source.freeText
                              case SourceType.URL:
                                return (
                                  <>
                                    <span className={classes.urlDescription}>
                                      {source.urlDescription}
                                    </span>
                                    <a
                                      href={source.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {source.url}
                                    </a>
                                  </>
                                )
                              case SourceType.PHOTO:
                                return (
                                  <ArticlePhoto
                                    className={classes.photoContainer}
                                    imageClassName={undefined}
                                    editable={false}
                                    photo={source.media}
                                    key={source.media.id}
                                    onClick={() =>
                                      setClickedPhoto(source.media)
                                    }
                                    children={undefined}
                                    handleLoaded={undefined}
                                    height={undefined}
                                  />
                                )
                              default:
                                throw Error(
                                  `unexpected source.type '${source.type}'`
                                )
                            }
                          })()}
                        </Typography>
                      </SourceContainer>
                    )}
                  </li>
                )
              )
            })}

            {editMode && (
              <SelectMedia
                mediaType="PHOTO"
                onSelect={handleAddPhotoSource}
                cropAfterSelect={false}
                trigger={o => {
                  //SelectMedia calls this function and passes it an onClick function which we can call to show the media picker
                  //console.debug(`SourcesUI.selectMediaTriggerSetter(): called with o`, o)
                  selectMediaTrigger.onClick = o.onClick
                  return <div></div>
                }}
                aspectRatio={undefined}
                onSelectCropped={undefined}
              />
            )}

            {!!clickedPhoto && (
              <MediaNavigatorDialog
                photo={clickedPhoto}
                onReportClosed={() => setClickedPhoto(null)}
                onRequestNext={undefined}
                onRequestPrev={undefined}
                canRequestNext={undefined}
                canRequestPrev={undefined}
                handleDelete={undefined}
              />
            )}

            {(collatePhotos && photoSources.length) > 0 && (
              <li className={'sourceContainer'} id="source_photos">
                <div className={classes.photoRowXSmall}>
                  {photoSources.map((source, idx) => (
                    <div key={source.id}>
                      {' '}
                      {/* <div style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}} id={`source_${source.id}`}> */}
                      {!editMode && (
                        <Typography>
                          [{source.numberWithinArticle}] photo
                        </Typography>
                      )}
                      <ArticlePhoto
                        className={classes.photoContainer}
                        editable={false}
                        imageClassName={undefined}
                        photo={source.media}
                        onClick={() => setClickedPhoto(source.media)}
                        handleLoaded={undefined}
                        height={undefined}
                      >
                        {editMode && (
                          <div
                            className={classes.removeSourcePhotoButtonContainer}
                          >
                            <IconButton
                              permissionAction={ACTION_ALL_ACCESS}
                              className={classes.removeSourcePhotoButton}
                              onClick={() =>
                                handleDeleteSource(
                                  source.id,
                                  source.contentBlockId || contentBlockId,
                                  contentId
                                )
                              }
                              isLoading={undefined}
                              handleSubmit={undefined}
                              innerRef={undefined}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </ArticlePhoto>
                    </div>
                  ))}
                </div>
              </li>
            )}
          </ul>
        )}
      </Collapse>
    </div>
  )
}

Sources.propTypes = {
  sources: PropTypes.arrayOf(SourcePropType).isRequired,
  contentBlockId: PropTypes.string,
  contentId: PropTypes.string,
  editMode: PropTypes.bool,
  showAddButton: PropTypes.bool,
  collapsible: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
  collatePhotos: PropTypes.bool,
  title: PropTypes.string,
  hideSourceNumber: PropTypes.bool,
  SourceContainer: PropTypes.element,
}
