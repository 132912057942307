import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useMemo } from 'react'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

const IndividualSearchBox = ({
  onChange,
  onClear,
  busy = false,
  selector,
  searchTermAction,
}) => {
  const dispatch = useDispatch()
  const value = useSelector(selector)

  const debouncedOnChange = useMemo(
    () =>
      debounce(newValue => {
        if (onChange) {
          onChange && onChange(newValue)
        }
      }, 500), // 500ms delay
    [onChange]
  )

  const handleClear = async () => {
    await dispatch(searchTermAction(''))
    onClear && onClear()
  }

  const handleChange = async event => {
    await dispatch(searchTermAction(event.target.value))
    debouncedOnChange(event.target.value)
  }

  return (
    <Stack direction={'row'} sx={{ alignItems: 'center', width: '100%' }}>
      <TextField
        fullWidth
        variant="standard"
        value={value}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {busy && <CircularProgress size={'1rem'} />}
    </Stack>
  )
}

export default IndividualSearchBox
