import { TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'

import { useActionDispatcher } from 'src/modules/app'

import {
  searchSources,
  selectGedComSources,
} from '../writeArticle/writeArticleSlice'

export const SelectSource = ({ onSelectSource, initialValues = null }) => {
  const sources = useSelector(selectGedComSources)
  const dispatchSearchSources = useActionDispatcher(searchSources)

  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')

  // Populate all the options by making an empty search
  useEffect(() => {
    const initialise = async () => {
      await dispatchSearchSources('')
    }
    initialise()
  }, [dispatchSearchSources, setValue, initialValues])

  useEffect(() => {
    if (sources.length && initialValues && !value.length) {
      const presetSources = sources.filter(l => initialValues.includes(l.id))
      if (presetSources.length) {
        setValue(presetSources)
        onSelectSource(presetSources.map(l => l.id))
      }
    }
  }, [sources, initialValues, onSelectSource, value])

  const handleInputChange = (event, newInputValue) => {
    dispatchSearchSources(newInputValue)
    setInputValue(newInputValue)
  }
  const handleChange = (event, newValue) => {
    onSelectSource(newValue)
  }

  const filteredOptions = sources.filter(o => o.id !== value)
  const noOptionsText =
    inputValue === '' ? 'Type to search...' : 'No matches found'

  return (
    <div>
      <Autocomplete
        autoComplete
        freeSolo
        getOptionKey={option => option.id}
        getOptionLabel={option => option.display || ''}
        inputValue={inputValue}
        multiple={false}
        noOptionsText={noOptionsText}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={filteredOptions}
        renderInput={params => (
          <TextField
            {...params}
            label="type to search & select sources ..."
            fullWidth
          />
        )}
        value={value}
      />
    </div>
  )
}

export default SelectSource
