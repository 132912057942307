import { intersection, isEmpty, isUndefined } from 'lodash'
import { LEFT_SIDE, RIGHT_SIDE } from './explore'
import { createDirectoryById, findGraphNode } from './graphOps'
import {
  childrenNotWithNode,
  exploreDownwards,
  unionIfExists,
} from './nodeDirectory'

export const NODE_TYPE_GRAPH_NODE = 'GraphNode'
export const NODE_TYPE_ADD_INDIVIDUAL_NODE = 'AddIndividualNode'

/**
 * Given a list of visible individual nodes and union nodes in a graph
 * structure, produce a list of GraphNodes that combine all this data.
 *
 * The resulting GraphNodes will each contain one or more of the individual
 * and union nodes, and will be linked together via their `children` attributes
 * into a tree structure.
 */
export const processIndividualsAndUnionsToGraphNodes = (
  visibleIndividualsAndUnions,
  coreLineageIDs = undefined,
  side = undefined
) => {
  const visibleNodeDirectory = createDirectoryById(visibleIndividualsAndUnions)
  const visibleNodeIDs = new Set(visibleNodeDirectory.keys())

  const remainingIndividuals = new Set(
    visibleIndividualsAndUnions.filter(n => !n.isUnion)
  )
  const individualIdToGraphNodes = new Map()

  // Process all unions to graph nodes, combining unions where they share
  // individuals
  for (const unionNode of visibleIndividualsAndUnions.filter(n => n.isUnion)) {
    const individualNodes = unionNode.between
      .map(id => visibleNodeDirectory.get(id))
      .filter(n => !!n)

    const innerNode = new InnerNode({
      individualNodes: individualNodes,
      visibleNodeIDs,
      unionNode,
      coreLineageIDs,
    })

    const existingGraphNodes = individualNodes
      .map(n => individualIdToGraphNodes.get(n.id))
      .filter(g => !!g)

    let [addedToGraphNode, discardedGraphNode] = existingGraphNodes
    if (isUndefined(addedToGraphNode)) {
      // Neither individual exists on another graph node. Create a new one.
      addedToGraphNode = new GraphNode(innerNode, side)
    } else {
      // One or both of the individuals have already been added to a graph node.
      addedToGraphNode.addInnerNode(innerNode)

      if (
        !isUndefined(discardedGraphNode) &&
        addedToGraphNode.id !== discardedGraphNode.id
      ) {
        for (const innerNode of discardedGraphNode.innerNodes) {
          addedToGraphNode.addInnerNode(innerNode)
        }
        for (const individualId of discardedGraphNode.individualIDs) {
          individualIdToGraphNodes.set(individualId, addedToGraphNode)
        }
      }
    }

    // Record that individuals have been associated with graph nodes
    for (const individualNode of individualNodes) {
      remainingIndividuals.delete(individualNode)
      individualIdToGraphNodes.set(individualNode.id, addedToGraphNode)
    }
  }

  // Create graph nodes for remaining individuals not in unions
  for (const individualNode of remainingIndividuals) {
    const innerNode = new InnerNode({
      individualNodes: [individualNode],
      visibleNodeIDs,
      undefined,
      coreLineageIDs,
    })
    const graphNode = new GraphNode(innerNode, side)
    individualIdToGraphNodes.set(individualNode.id, graphNode)
  }

  const allGraphNodes = new Set(individualIdToGraphNodes.values())

  // Record the links between graph nodes
  for (let graphNode of allGraphNodes) {
    for (let innerNode of graphNode.innerNodes) {
      for (let childIndividualId of innerNode.childIndividualIds) {
        graphNode.addChild(individualIdToGraphNodes.get(childIndividualId).id)
      }
    }
    // After links to children have been added "fix" the graph node,
    // performing the final operations to make it ready to draw.
    graphNode.fix()
  }
  return Array.from(allGraphNodes)
}

function cloneWithChildren(
  graphNode,
  parentGraphNode,
  graphNodeDirectory,
  newGraphNodes
) {
  const newGraphNode = graphNode.clone()
  newGraphNodes.push(newGraphNode)
  parentGraphNode.children = parentGraphNode.children.map(id =>
    id === graphNode.id ? newGraphNode.id : id
  )
  for (const childGraphNodeId of [...newGraphNode.children]) {
    const childGraphNode = graphNodeDirectory.get(childGraphNodeId)
    if (childGraphNode) {
      cloneWithChildren(
        childGraphNode,
        newGraphNode,
        graphNodeDirectory,
        newGraphNodes
      )
    }
  }
}

function checkChildrenUniquePaths(
  graphNode,
  parentGraphNode,
  graphNodeDirectory,
  seenGraphNodeIds,
  newGraphNodes
) {
  if (!graphNode) {
    return
  }

  if (seenGraphNodeIds.has(graphNode?.id)) {
    cloneWithChildren(
      graphNode,
      parentGraphNode,
      graphNodeDirectory,
      newGraphNodes
    )
    // Do not continue search on this graph node's children
    return
  }
  seenGraphNodeIds.add(graphNode?.id)

  // Depth first recursion - for each child look at all of their children
  // before proceeding to the next
  for (let childId of graphNode.children) {
    const childGraphNode = graphNodeDirectory.get(childId)
    if (!childGraphNode) {
      //should not get here but we do sometimes we do on tricky trees.  Probably hiding another issue but lets at
      //least avoid the crashes for now !!! rgs 30/8/2022
    } else {
      checkChildrenUniquePaths(
        childGraphNode,
        graphNode,
        graphNodeDirectory,
        seenGraphNodeIds,
        newGraphNodes
      )
    }
  }
}

export function fixIntermarriage(
  rootGraphNode,
  graphNodes,
  existingIds = new Set()
) {
  const graphNodeDirectory = createDirectoryById(graphNodes)
  const newGraphNodes = []
  checkChildrenUniquePaths(
    rootGraphNode,
    null,
    graphNodeDirectory,
    existingIds,
    newGraphNodes
  )
  return newGraphNodes
}

export function parentsAndSiblingsOfIndividualAsGraphNodes(
  nodeDirectory,
  individualNode,
  individualGraphNode
) {
  const parentUnion = unionIfExists(
    nodeDirectory,
    individualNode.bioFather,
    individualNode.bioMother
  )
  if (!parentUnion) {
    return []
  }
  const parents = [individualNode.bioFather, individualNode.bioMother]
    .filter(id => !!id)
    .map(id => nodeDirectory[id])
  const siblingIDs = parentUnion.children.filter(id => id !== individualNode.id)

  const visibleNodeIDs = new Set([
    individualNode.id,
    individualNode.bioFather,
    individualNode.bioMother,
    ...siblingIDs,
  ])

  const rootInnerNode = new InnerNode({
    individualNodes: parents,
    visibleNodeIDs,
    unionNode: parentUnion,
    coreLineageIDs: visibleNodeIDs,
  })
  const rootGraphNode = new GraphNode(rootInnerNode)
  rootGraphNode.addChild(individualGraphNode.id)

  const siblingGraphNodes = []
  for (let siblingID of siblingIDs) {
    const siblingGraphNode = new GraphNode(
      new InnerNode({
        individualNodes: [nodeDirectory[siblingID]],
        visibleNodeIDs,
        coreLineageIDs: visibleNodeIDs,
      })
    )
    siblingGraphNode.fix()
    siblingGraphNodes.push(siblingGraphNode)
    rootGraphNode.addChild(siblingGraphNode.id)
  }
  rootGraphNode.fix()
  return [rootGraphNode, siblingGraphNodes]
}

/*
 * A graph node is something that can be laid out and drawn on the graph in one
 * spot.  Internally, it can have different make-ups, but whatever is inside
 * will be drawn as one node of the graph.
 */
export class GraphNode {
  constructor(initialNode, side) {
    this.nodeType = NODE_TYPE_GRAPH_NODE
    this.x = undefined
    this.y = undefined
    this.height = 1
    this.side = side

    // Nodes that will be drawn together in this node
    this.innerNodes = [initialNode]

    // IDs of the individuals that will be drawn together in this node
    this.individualIDs = new Set(initialNode.individualIDs)

    // IDs of the other *graph nodes* that are children of this one
    // Because the links are from graph node to graph node, they must be
    // added after all graph nodes are constructed, and before `fix()`
    // is called.
    this.children = []
    this._fixed = false

    // Initialise display properties which will be populated during `fix()`
    this.centralInnerNode = undefined
    this.leftOtherUnions = []
    this.rightOtherUnions = []
    this.individualNodesInDisplayOrder = []

    // For step children, record which individual node is the parent
    this.stepChildrenParents = {}

    // The ID must be constant after this graphnode is "fixed":
    this.fixedID = undefined

    // Keep track of how many times this object cloned, so we can give unique ids
    this.nClones = 0
  }

  addInnerNode(innerNode) {
    this.innerNodes.push(innerNode)
    for (let individualId of innerNode.individualIDs) {
      this.individualIDs.add(individualId)
    }
  }
  addChild(childGraphNodeId) {
    if (this._fixed) {
      throw Error('Cannot add child after graph node is fixed')
    }
    if (this.children.includes(childGraphNodeId)) {
      return
    }
    this.children.push(childGraphNodeId)
  }

  /**
   * After all inner nodes have been added, "fix" the GraphNode by
   * performing extra operations necessary before display.
   */
  fix() {
    if (this._fixed) {
      throw Error('Fix cannot be called twice')
    }
    this.fixedID = this.id
    this._fixed = true

    this.leftOtherUnions = []
    this.rightOtherUnions = []

    // Base case - graph node only contains one individual
    if (this.individualIDs.size === 1) {
      this.individualNodesInDisplayOrder.push(
        this.innerNodes[0].individualNodes[0]
      )
      this.centralInnerNode = this.innerNodes[0]
      return
    }

    // First choice for central node is the lineage root
    this.centralInnerNode = this.innerNodes.find(n => n.isLineageRoot)

    // Second choice is core lineage with children
    if (isUndefined(this.centralInnerNode)) {
      this.centralInnerNode = this.innerNodes.find(
        n => !!n.childIndividualIds.length && n.hasCoreLineage
      )
    }
    // Third choice is any core lineage
    if (isUndefined(this.centralInnerNode)) {
      this.centralInnerNode = this.innerNodes.find(n => n.hasCoreLineage)
    }

    // In the absence of other info, make an arbitrary selection:
    if (isUndefined(this.centralInnerNode)) {
      this.centralInnerNode = this.innerNodes[0]
    }

    // Default union ordering
    this.leftHandCentralPartner = this.centralInnerNode.malePartner
    this.rightHandCentralPartner = this.centralInnerNode.femalePartner
    if (!(this.rightHandCentralPartner && this.leftHandCentralPartner)) {
      // If cannot establish gender ordering, accept whatever is given
      this.leftHandCentralPartner = this.centralInnerNode.individualNodes[0]
      this.rightHandCentralPartner = this.centralInnerNode.individualNodes[1]
    }

    this.leftOtherPartners = []
    this.rightOtherPartners = []
    const nonCentralInnerNodes = this.innerNodes.filter(
      n => n.id !== this.centralInnerNode.id
    )
    for (let innerNode of nonCentralInnerNodes) {
      const partnerInCommon = innerNode.partnerInCommon(this.centralInnerNode)
      if (!partnerInCommon) {
        console.info('No partner in common with central node', innerNode)
      } else if (this.leftHandCentralPartner.id === partnerInCommon.id) {
        this.leftOtherUnions.push(innerNode)
        this.leftOtherPartners.push(innerNode.otherPartner(partnerInCommon.id))
      } else {
        this.rightOtherUnions.push(innerNode)
        this.rightOtherPartners.push(innerNode.otherPartner(partnerInCommon.id))
      }
    }

    this.fixFinal()
  }

  refixWithoutIDChange() {
    const savedFixedID = this.fixedID
    this._fixed = false
    this.fix()
    this.fixedID = savedFixedID
  }

  fixFinal() {
    this.individualNodesInDisplayOrder = this.leftOtherPartners
      .concat([this.leftHandCentralPartner, this.rightHandCentralPartner])
      .concat(this.rightOtherPartners)

    this.innerNodes = this.leftOtherUnions
      .concat([this.centralInnerNode])
      .concat(this.rightOtherUnions)

    this.reorderChildren()
  }

  reorderChildren() {
    // Order children in the order of their parent inner nodes
    const orderedChildren = []
    for (let innerNode of this.innerNodes) {
      for (let childID of innerNode.childIndividualIds) {
        const graphNodeID = this.children.find(gID => gID.includes(childID))
        if (!graphNodeID) {
          console.error('Every individual child should be in a graph node')
        } else {
          // In cases where siblings have the same significant other, they can
          // appear in the same graph node. Ensure the reference is not repeated
          if (!orderedChildren.includes(graphNodeID)) {
            orderedChildren.push(graphNodeID)
          }
        }
      }
    }

    this.children = orderedChildren

    if (this.children.includes(this.id)) {
      console.error('Self parenting', this, this.children)
      this.children = this.children.filter(id => id !== this.id)
    }
  }

  addStepChildren(nodeDirectory, coreLineageNode, generations = 2) {
    const addedGraphNodes = []
    for (let innerNode of this.innerNodes) {
      const otherPartner = innerNode.otherPartner(coreLineageNode.id)
      if (isUndefined(otherPartner)) {
        continue
      }
      const stepChildren = childrenNotWithNode(
        nodeDirectory,
        otherPartner,
        coreLineageNode
      )
      const leftOrRight = this.isIndividualRightOf(
        otherPartner,
        coreLineageNode
      )
        ? RIGHT_SIDE
        : LEFT_SIDE

      for (let childNode of stepChildren) {
        innerNode.addStepChild(childNode, leftOrRight)
        const newGraphNodes = processIndividualsAndUnionsToGraphNodes(
          exploreDownwards(nodeDirectory, childNode, generations - 1)
        )
        addedGraphNodes.push(...newGraphNodes)
        const childRootGraphNode = findGraphNode(newGraphNodes, childNode.id)
        this.stepChildrenParents[childRootGraphNode.id] = otherPartner
        this.children.push(childRootGraphNode.id)
      }
    }
    this.reorderChildren()
    return addedGraphNodes
  }

  flipHorizontal() {
    ;[this.leftOtherUnions, this.rightOtherUnions] = [
      this.rightOtherUnions.reverse(),
      this.leftOtherUnions.reverse(),
    ]
    ;[this.leftOtherPartners, this.rightOtherPartners] = [
      this.rightOtherPartners.reverse(),
      this.leftOtherPartners.reverse(),
    ]
    ;[this.leftHandCentralPartner, this.rightHandCentralPartner] = [
      this.rightHandCentralPartner,
      this.leftHandCentralPartner,
    ]
  }

  flipHorizontalAfterFix() {
    this.flipHorizontal()
    this.fixFinal()
  }

  containsFamilyMember(familyID) {
    return this.innerNodes.some(i =>
      i.individualNodes.some(node => node.family === familyID)
    )
  }

  findIndividualLinkSourceTarget(targetGraphNode, sourceRightOfTarget) {
    let found, sourceIdx
    let targetIdx = 0
    let potentialCross = undefined
    const stepParent = this.stepChildrenParents[targetGraphNode.id]

    for (let targetIndividual of targetGraphNode.individualNodesInDisplayOrder) {
      sourceIdx = 0
      for (let sourceInnerNode of this.innerNodes) {
        if (sourceInnerNode.childIndividualIds.includes(targetIndividual.id)) {
          found = true
          if (!isUndefined(stepParent)) {
            sourceIdx =
              this.individualNodesInDisplayOrder.indexOf(stepParent) - 0.5
          }

          if (sourceRightOfTarget !== undefined) {
            // Check whether a source node on the right is linking to a
            // target node on the left or vice versa.
            const targetIndividualOnRight =
              targetGraphNode.isIndividualRightOfPartner(targetIndividual)
            if (targetIndividualOnRight !== undefined) {
              potentialCross =
                (targetIndividualOnRight && !sourceRightOfTarget) ||
                (!targetIndividualOnRight && sourceRightOfTarget)
            }
          }
          break
        }
        sourceIdx += 1
      }
      if (found) {
        break
      }
      targetIdx += 1
    }
    if (found) {
      return [sourceIdx, targetIdx, potentialCross]
    } else {
      return []
    }
  }

  hideChildren() {
    for (let innerNode of this.innerNodes) {
      innerNode.invisibleChildren = innerNode.childIndividualIds
      innerNode.childIndividualIds = []
    }
    this.children = []
  }

  hideInvisible() {
    for (const innerNode of this.innerNodes) {
      innerNode.invisibleChildren = []
      innerNode.invisibleParents = []
      innerNode.invisibleSpouses = []
      innerNode.invisibleUnions = []
    }
  }

  individualXCoord(individualNode) {
    return (
      this.leftX + this.individualNodesInDisplayOrder.indexOf(individualNode)
    )
  }

  innerNodeContaining(individualNode) {
    return this.innerNodes.find(n => n.individualIDs.has(individualNode.id))
  }

  distanceToPartner(individualNode) {
    const innerUnionNode = this.innerNodeContaining(individualNode)
    if (!innerUnionNode || !innerUnionNode.unionNode) {
      return undefined
    }
    const partner = innerUnionNode.otherPartner(individualNode.id)
    return (
      this.individualXCoord(partner) - this.individualXCoord(individualNode)
    )
  }

  isIndividualRightOf(individualNode, otherIndividualNode) {
    return (
      this.individualNodesInDisplayOrder.indexOf(individualNode) >
      this.individualNodesInDisplayOrder.indexOf(otherIndividualNode)
    )
  }

  isIndividualRightOfPartner(individualNode) {
    const innerUnionNode = this.innerNodeContaining(individualNode)
    if (!innerUnionNode || !innerUnionNode.unionNode) {
      return undefined
    }
    const partner = innerUnionNode.otherPartner(individualNode.id)
    return (
      innerUnionNode.individualNodes.indexOf(individualNode) >
      innerUnionNode.individualNodes.indexOf(partner)
    )
  }

  absoluteDistanceFromCentralInnerNode(innerNodeIdx) {
    const centralInnerNodeIdx = this.innerNodes.indexOf(this.centralInnerNode)
    return Math.abs(centralInnerNodeIdx - innerNodeIdx)
  }

  get leftX() {
    if (isUndefined(this.x)) {
      return undefined
    }
    return this.x - this.individualNodesInDisplayOrder.length / 2
  }

  get rightX() {
    if (isUndefined(this.x)) {
      return undefined
    }
    return this.x + this.individualNodesInDisplayOrder.length / 2
  }

  get width() {
    return this.individualNodesInDisplayOrder.length
  }

  get hasMultiplePartnerships() {
    return (
      this.innerNodes.filter(n => n.isUnion).length > 1 ||
      !isEmpty(this.stepChildrenParents)
    )
  }
  get size() {
    return [this.width, this.height]
  }

  get hasCoreLineage() {
    return this.innerNodes.some(n => n.hasCoreLineage)
  }

  get id() {
    // After fixing, ID is constant regardless of contents of `this.innerNodes`
    if (!isUndefined(this.fixedID)) {
      return this.fixedID
    }

    // Before fixing, ID is a product of the inner node IDs
    return this.innerNodes
      .map(un => un.id)
      .sort()
      .join('--')
  }

  clone() {
    this.nClones += 1

    const newClone = Object.assign(
      Object.create(Object.getPrototypeOf(this)),
      this
    )
    newClone.fixedID = `${this.fixedID}-${this.nClones}`
    return newClone
  }
}

/**
 * Represent an individual or a union that will be drawn, possibly
 * with other InnerNodes, as part of a GraphNode
 */
export class InnerNode {
  constructor({
    individualNodes,
    visibleNodeIDs,
    unionNode = undefined,
    coreLineageIDs,
  }) {
    // List of either 1 or 2 nodes
    this.individualNodes = individualNodes
    this.individualIDs = new Set(individualNodes.map(({ id }) => id))

    // Enforce rules for unions/non-unions
    this.unionNode = unionNode
    if (unionNode) {
      if (individualNodes.length !== 2) {
        throw Error('Too few or many individuals')
      }
    } else {
      if (individualNodes.length !== 1) {
        throw Error('Must supply 1 individual if this is not a union')
      }
    }

    this.childIndividualIds = []
    this.invisibleChildren = []
    visibleNodeIDs = visibleNodeIDs || new Set(individualNodes.map(n => n.id))

    // Union nodes may have children that are not visible
    for (let childId of this.unionNode?.children || []) {
      if (visibleNodeIDs.has(childId)) {
        this.childIndividualIds.push(childId)
      } else {
        this.invisibleChildren.push(childId)
      }
    }

    // Union nodes may have spouses that are not visible
    this.invisibleSpouses = (unionNode?.between || []).filter(
      spouseID => !visibleNodeIDs.has(spouseID)
    )

    this.invisibleParents = []
    this.invisibleUnions = []
    for (let individualNode of individualNodes) {
      this.invisibleParents = this.invisibleParents.concat(
        [individualNode.bioMother, individualNode.bioFather]
          .filter(id => !!id)
          .filter(id => !visibleNodeIDs.has(id))
      )
      this.invisibleUnions = this.invisibleUnions.concat(
        (individualNode.unions || []).filter(uID => !visibleNodeIDs.has(uID))
      )
    }

    this.coreLineageIDs = coreLineageIDs || new Set()
  }

  setParentsAsVisible(graphNode) {
    this.invisibleParents = this.invisibleParents.filter(
      id => !graphNode.individualIDs.has(id)
    )
  }

  otherPartner(individualId) {
    return this.individualNodes.find(({ id }) => id !== individualId)
  }

  genderedPartner(requestedGender) {
    return this.individualNodes.find(({ gender }) => gender === requestedGender)
  }

  partnerInCommon(innerNode) {
    const common = intersection(innerNode.individualNodes, this.individualNodes)
    if (common.length === 1) {
      return common[0]
    }
    if (common.length > 1) {
      throw Error(
        'Separate innerNodes should never have multiple partners in common'
      )
    }
    return undefined
  }

  hasInvisibleParents(individualNode) {
    return !!intersection(
      [individualNode.bioFather, individualNode.bioMother],
      this.invisibleParents
    ).length
  }

  hasInvisibleSpouses(individualNode) {
    return !!intersection(individualNode.unions, this.invisibleUnions).length
  }

  addStepChild(stepChildIndividualNode, leftOrRight) {
    if (leftOrRight === RIGHT_SIDE) {
      this.childIndividualIds.push(stepChildIndividualNode.id)
    } else if (leftOrRight === LEFT_SIDE) {
      this.childIndividualIds.unshift(stepChildIndividualNode.id)
    } else {
      throw Error('invalid side')
    }
  }

  get hasInvisible() {
    return !!(
      this.invisibleParents.length ||
      this.invisibleChildren.length ||
      this.invisibleSpouses.length ||
      this.invisibleUnions.length
    )
  }

  get hasInvisibleChildren() {
    return !!this.invisibleChildren.length
  }

  get malePartner() {
    return this.genderedPartner('M')
  }

  get femalePartner() {
    return this.genderedPartner('F')
  }

  get id() {
    return [...this.individualIDs].sort().join('-')
  }

  get isUnion() {
    return !isUndefined(this.unionNode)
  }

  get width() {
    return this.individualNodes.length
  }

  /**
   * True only for a union node where both partners are involved in the lineage.
   * Short of incest, this generally means they are the top of the tree we're
   * interested in.
   */
  get isLineageRoot() {
    if (isUndefined(this.coreLineageIDs)) {
      return false
    }
    return (
      this.isUnion &&
      this.individualNodes.every(({ id }) => this.coreLineageIDs.has(id))
    )
  }

  /**
   * Are at least one of the individuals in this node part of the wider lineage
   * under examination?
   */
  get hasCoreLineage() {
    if (isUndefined(this.coreLineageIDs)) {
      return true
    }
    return this.individualNodes.some(({ id }) => this.coreLineageIDs.has(id))
  }
}

export class Link {
  constructor(sourceGraphNode, targetGraphNode, isTreeLayout) {
    this.sourceGraphNode = sourceGraphNode
    this.targetGraphNode = targetGraphNode

    const sourceRightOfTarget = sourceGraphNode.x > targetGraphNode.x

    let sourceToFindIndexes
    if (sourceGraphNode.isCollapsedGenerations) {
      sourceToFindIndexes = this.sourceGraphNode.lastHiddenLayerUnion
    } else {
      sourceToFindIndexes = this.sourceGraphNode
    }

    // The target graph node may have multiple individual nodes drawn within.
    // If so, what is the index (0, 1, etc) of the individuals / unions we
    // should be linking from (sourceIdx) and to (targetIdx). This can be used
    // to draw the link line correctly.

    let [sourceIdx, targetIdx, potentialCross] =
      sourceToFindIndexes.findIndividualLinkSourceTarget(
        targetGraphNode,
        sourceRightOfTarget
      )

    this.sourceVerticalOffset =
      sourceGraphNode.absoluteDistanceFromCentralInnerNode(sourceIdx) - 1

    this.sourceIdx = sourceIdx
    this.targetIdx = targetIdx
    this.verticalOffset = 0
    this.potentialCross = potentialCross
  }

  get id() {
    return `${this.sourceGraphNode.id}-->${this.targetGraphNode.id}`
  }
}
