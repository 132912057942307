import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FieldArray, useFormikContext } from 'formik'
import clsx from 'clsx'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { useNotification } from 'src/modules/app/hooks'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { IconButton } from 'src/modules/ui'
import { useActionDispatcher } from 'src/modules/app'
import Button from 'src/modules/ui/Button'
import FormikLinkField from 'src/modules/ui/FormikLinkField'
import DialogTitle from 'src/modules/ui/DialogTitle'
import ConfirmDialog from 'src/modules/ui/ConfirmDialog'
import FormikTextField from 'src/modules/ui/FormikTextField'
import SelectMedia from 'src/modules/photo/SelectMedia'

import { deleteInformationRequest } from './informationRequestSlice'
import EditablePhotoForInfoRequest from './EditablePhotoForInfoRequest'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  addButton: {
    alignSelf: 'center',
  },
  dialogContent: {
    minWidth: '500px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 2, 2),
  },
  editorContainer: {
    marginBottom: theme.spacing(3),
    '& .public-DraftStyleDefault-block': {
      margin: theme.spacing(1, 0),
    },
  },
  uploadContainer: {
    alignSelf: 'flex-start',
    marginLeft: '7px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButtonsCreate: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
  },
  actionButtonsEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  nextButtonContainer: {
    alignSelf: 'flex-start',
  },
  cancelButtonContainer: {
    alignSelf: 'flex-end',
  },
  photoRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  photo: {
    height: 200,
    width: 'auto',
  },
  photoContainer: {
    position: 'relative',
    margin: theme.spacing(1),
  },
  removeButton: {
    background: 'white',
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
}))

const CreateInfoRequestDialog = ({ open, onSubmit, onClose, media, id }) => {
  const classes = useStyles()
  const history = useHistory()
  const { showError } = useNotification()
  const { values } = useFormikContext()

  const dispatchDeleteInfoRequest = useActionDispatcher(
    deleteInformationRequest
  )

  const handleDelete = async () => {
    try {
      await dispatchDeleteInfoRequest(id, {
        successNotification: 'Ask family request deleted',
      }).unwrap()
      history.push(`/${getTreeSlugFromStore()}/my-ask-family-requests`)
    } catch {}
  }

  const handleAddMedia = (m, arrayHelpers) => {
    const media = values.media
    const photoIds = media.map(p => p.id)
    if (photoIds.includes(m.id)) {
      showError('That media item is already added')
    } else {
      arrayHelpers.push(m)
    }
  }

  const handleRemovePhoto = (index, arrayHelpers) => () => {
    arrayHelpers.remove(index)
  }

  const handleCropPhoto = (index, arrayHelpers) => croppedPhoto => {
    arrayHelpers.remove(index)
    arrayHelpers.insert(index, croppedPhoto)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        {open && (
          <div className={classes.dialogContent}>
            <DialogTitle onClose={onClose}>Ask Family!</DialogTitle>
            <DialogContent sx={{ pt: 0, pb: 1 }}>
              <Box sx={{ mb: 3, mt: 1 }}>
                <FormikTextField
                  autoFocus
                  name="title"
                  label="Question"
                  fullWidth
                  multiline
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography gutterBottom>Media</Typography>
                <FieldArray
                  name="media"
                  render={arrayHelpers => (
                    <Box
                      className={classes.photoRow}
                      sx={{ m: -1, mb: media.length === 0 ? 2 : 0 }}
                    >
                      {media.map((photo, index) => (
                        <EditablePhotoForInfoRequest
                          key={index}
                          photo={photo}
                          onDoneCropping={handleCropPhoto(index, arrayHelpers)}
                          onRemovePhoto={handleRemovePhoto(index, arrayHelpers)}
                          imageClassName={classes.photo}
                          className={classes.photoContainer}
                        />
                      ))}
                      <SelectMedia
                        onSelect={media => handleAddMedia(media, arrayHelpers)}
                        cropAfterSelect={false}
                        trigger={props => (
                          <div className={classes.addButton}>
                            <IconButton
                              permissionAction={ACTION_ALL_ACCESS}
                              {...props}
                              color="primary"
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </div>
                        )}
                      />
                    </Box>
                  )}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <FormikLinkField name="links" />
              </Box>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <div
                className={clsx({
                  [classes.actionButtonsCreate]: !id,
                  [classes.actionButtonsEdit]: id,
                })}
              >
                {id ? (
                  <div className={classes.cancelButtonContainer}>
                    <ConfirmDialog
                      onConfirm={handleDelete}
                      trigger={props => (
                        <Button
                          permissionAction={ACTION_ALL_ACCESS}
                          {...props}
                          size="large"
                        >
                          Delete
                        </Button>
                      )}
                    >
                      <Typography>Delete this information request?</Typography>
                    </ConfirmDialog>
                  </div>
                ) : null}
                <div className={classes.nextButtonContainer}>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    color="primary"
                    onClick={onSubmit}
                    size="large"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </>
  )
}

export default CreateInfoRequestDialog
