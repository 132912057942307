import { createExploredIndividualGraph } from '../api/explore'
import TreeViewer from './TreeViewer'
import { FOCUS_MODE_SCROLL } from './constants'
import { useStyles } from './viewerStyles'

const ExploredIndividualViewer = ({
  doAddGreatGrandParents = false,
  exploredIndividualNode,
  nodeDirectory,
  isPublic,
}) => {
  const classes = useStyles()

  const [nodes, links, nLayers] =
    exploredIndividualNode && nodeDirectory
      ? createExploredIndividualGraph(
          exploredIndividualNode,
          nodeDirectory,
          doAddGreatGrandParents
        )
      : []

  return (
    <>
      <div className={classes.shadowContainer}>
        <TreeViewer
          graphNodeKeyModifier={exploredIndividualNode.id}
          allowDragAndZoom={true}
          disableZoom={true}
          exploreNodeOnClick={false}
          focusMode={FOCUS_MODE_SCROLL}
          focusModeTarget={exploredIndividualNode}
          links={links}
          navigateToNodeOnClick={true}
          nLayers={nLayers}
          nodes={nodes}
          isPublic={isPublic}
        />
        <div className={classes.innerShadow} />
      </div>
    </>
  )
}

export default ExploredIndividualViewer
