import { PublicPageListBase } from '../page/PublicPageList'
import { usePublicHomePageUrlSegments } from '../page/hooks'

const PublicHomePageList = () => {
  const { pageType } = usePublicHomePageUrlSegments()

  return <PublicPageListBase pageType={pageType} />
}

export default PublicHomePageList
