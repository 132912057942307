import React from 'react'
import parse from 'html-react-parser'
import { Typography, styled } from '@mui/material'
import clip from 'text-clipper'

import { makeParseOptions } from './parseOptions'
import { isArray } from 'lodash'

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  '& > p:last-child': {
    display: 'inline',
    marginRight: 4,
  },
  overflow: 'hidden',
})

const ContentTextBlock = ({
  layout,
  textContentBlocks,
  variant = 'subtitle3',
  parent,
  ...props
}) => {
  const options = makeParseOptions({
    removeFormatting: true,
  })

  let textContent = ''
  textContentBlocks.forEach(block => {
    textContent += block.textContent
  })

  const getCharCount = () => {
    const width = window.innerWidth
    const divisor = parent === 'ContentCard' && width > 700 ? 18 : 3.8
    const calculateChars = Math.floor(width / divisor)

    if (calculateChars > 350) {
      return 350
    } else {
      return calculateChars
    }
  }

  const charCount = getCharCount()

  const text = parse(
    clip(textContent, charCount, { html: true, maxLines: 3 }),
    {
      ...options,
      replace: domNode => {
        const tagList = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
        if (tagList.includes(domNode.name)) {
          return <></>
        } else if (
          domNode.name === 'a' &&
          domNode.attribs &&
          domNode.attribs['data-instance-type'] === 'source'
        ) {
          //hide source links in article preview lists
          return <></>
        } else if (domNode.name === 'blockquote' || domNode.name === 'a') {
          domNode.name = 'span'
        }
      },
    }
  )

  return (
    <>
      {isArray(text) ? (
        text.map((textItem, idx) => {
          return (
            <StyledTypography key={idx} variant={variant}>
              {textItem}
            </StyledTypography>
          )
        })
      ) : (
        <StyledTypography variant={variant}>{text}</StyledTypography>
      )}
    </>
  )
}

export default ContentTextBlock
