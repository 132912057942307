import { Box, MenuItem, Typography } from '@mui/material'
import { ConfirmDialog } from '../ui'
import { useActionDispatcher } from '../app'
import { makeStyles } from '@mui/styles'
import { createTreeExportJob } from './treesSlice'

const useStyles = makeStyles(theme => ({
  exportContainer: {
    marginLeft: theme.spacing(1),
  },
}))

const ExportTreeButton = ({ treeSlug, exportLabel = 'export' }) => {
  const classes = useStyles()
  const exportTreeAction = useActionDispatcher(createTreeExportJob)

  return (
    <Box className={classes.exportContainer}>
      <ConfirmDialog
        onConfirm={() =>
          exportTreeAction(treeSlug, {
            successNotification: 'Archive export started!',
            errorNotification: err => {
              return err?.data && err.data[0]
            },
          })
        }
        submitText="Export Archive"
        title="You are about to export a tree"
        trigger={props => <MenuItem {...props}>Export Archive</MenuItem>}
      >
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          You are about to export the archive <b>"{treeSlug}"</b>. This will
          process all its content and media and create a downloadable archive.
          This is a big job, and can take several hours to complete. You will
          receive an email when it is done.
        </Typography>
      </ConfirmDialog>
    </Box>
  )
}

export default ExportTreeButton
