import React, { useState } from 'react'
import { useField } from 'formik'
import { TextField, InputAdornment } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { IconButton } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const FormikPasswordField = props => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const [field, meta] = useField(props)

  return (
    <TextField
      {...field}
      {...props}
      type={showPassword ? 'text' : 'password'}
      error={meta.touched && meta.error ? true : false}
      helperText={meta.touched && meta.error ? meta.error : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default FormikPasswordField
