import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Group } from '@visx/group'
import { Line, LinkVerticalStep, Polygon } from '@visx/shape'
import { Text } from '@visx/text'

import clsx from 'clsx'

import { selectUserIndividualOnTree } from 'src/modules/auth/authSlice'
import { formatIndividualDates } from 'src/modules/ui/individualUtils'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'

import { useLinkToInstance } from '../hooks'
import {
  selectHoveredIndividualId,
  setHoveredIndividualId,
  chooseFamilyId,
  selectExploredIndividualId,
  selectClickedIndividualId,
  selectChosenFamilyId,
  setClickedIndividualId,
  setExploredIndividualId,
  selectEditedIndividualId,
  setAddIndividualNode,
} from '../exploreTreeSlice'
import useViewerColours from '../viewerColours'
import Explore from '../Explore'
import {
  ADD_TYPE_SPOUSE,
  FAR_LEFT,
  FAR_TOP,
  INDVDL_NODE_GRID_HEIGHT,
  INDVDL_NODE_GRID_WIDTH,
  INDVDL_NODE_HEIGHT,
  INDVDL_NODE_PADDING,
  INDVDL_NODE_WIDTH,
  INNER_LEFT,
  LINK_STROKE_WIDTH,
  UNION_NODE_HEIGHT,
} from './constants'
import { getBorderBoxProps } from './utils'
import AddIndividualNode from './AddIndividualNode'
import DrawnAddIndividualNode from './DrawnAddIndividualNode'
import { isUnknownIndividual } from '../api/nodeDirectory'

import { selectUser } from 'src/modules/auth/authSlice'
import { selectNodeDirectory } from '../viewerSlice'
import { getTreeCardNameValues } from '../../ui/individualUtils'
import { STATE_DECEASED } from '../CreateOrUpdateIndividualForm'
import { useEmbed } from '../../public/hooks'
import { selectCurrentTree } from '../../auth/authSlice'
import IndividualTreeImage from './IndividualTreeImage'
import { isBlogPost } from '../../app/links'

const HANDLE_SIZE = 5
const HANDLE_OPACITY = 0.5
const NODE_UNION_CIRCLE_RADIUS = 4

export function DrawnIndividual({
  x,
  y,
  preview,
  individual,
  allowNodeClick = false,
  exploreNodeOnClick = false,
  navigateToNodeOnClick = false,
  hasInvisibleChildren,
  hasInvisibleParents,
  hasInvisibleSpouses,
  isSelected,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  nodesInactive,
  isPublic,
  isSubTree,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    NODE_BG,
    NODE_BG_CURRENT_USER,
    NODE_BG_EXPLORED,
    NODE_TEXT,
    NODE_TEXT_CURRENT_USER,
    NODE_TEXT_EXPLORED,
    NODE_BORDER,
    NODE_BORDER_SELECTED,
    NODE_BORDER_SELECTED_SECONDARY,
    NODE_TEXT_DISABLED,
    NODE_BORDER_DISABLED,
    NODE_BACKGROUND_DISABLED,
  } = useViewerColours()

  const userIndividualOnTree = useSelector(selectUserIndividualOnTree)
  const isCurrentUser =
    individual.id === (userIndividualOnTree && userIndividualOnTree.id)

  const exploredIndividualId = useSelector(selectExploredIndividualId)
  const isExplored = individual.id === exploredIndividualId

  const editedIndividualId = useSelector(selectEditedIndividualId)
  const isEdited = individual.id === editedIndividualId

  const clickedIndividualId = useSelector(selectClickedIndividualId)
  const isFocus = individual.id === clickedIndividualId

  const isBlog = isBlogPost(window.location.pathname)

  const [mouseOver, setMouseOver] = useState(false)

  const { line1, line2 } = getTreeCardNameValues(individual)

  const setMouseIsOver = useCallback(() => {
    dispatch(setHoveredIndividualId(individual.id))
    setMouseOver(true)
  }, [dispatch, individual.id])

  const setMouseNotOver = useCallback(() => {
    dispatch(setHoveredIndividualId(null))
    setMouseOver(false)
  }, [dispatch])

  // Check all factors affecting whether we can view this individual's page
  const isUnknown = isUnknownIndividual(individual.id)
  const individualUrl = useLinkToInstance(INSTANCE_TYPE_INDIVIDUAL, individual)

  const user = useSelector(selectUser)
  const nodeDirectory = useSelector(selectNodeDirectory)
  const embed = useEmbed()
  const currentLoggedInTree = useSelector(selectCurrentTree)

  const handleOnClick = useCallback(
    e => {
      const clickCount = e.detail || 0
      //console.debug(`DrawnNodes.DrawnIndividual().handleOnClick(): isUnknown: ${isUnknown}, allowNodeClick: ${allowNodeClick},  exploreNodeOnClick: ${exploreNodeOnClick}, navigateToNodeOnClick: ${navigateToNodeOnClick}, individualUrl: ${individualUrl}, `)

      if (isUnknown && !isPublic) {
        // when a child only has one parent an 'unknown spouse' is made up by initiateNodeDirectory(). This exists only
        // in redux state, it does not go to the api or db.
        // The unknown spouse does not have 'a page' so useLinkToInstance() returns an empty string.
        // If the user clicks on this card we want to pop up the 'add individual' dialog so the user can fill in some
        // details and then create an actual individual.
        const spouseId = individual.spouses[0]

        if (spouseId) {
          const spouse = nodeDirectory[spouseId]

          //individual.children is not set so go look them up from the unions
          const allChildrenOfUnknownSpouse = []
          //console.debug(`DrawnNodes.DrawnIndividual.handleOnClick(): individual.id: '${individual.id}', individual.unions:`, individual.unions)
          for (const unionId of individual.unions) {
            const union = nodeDirectory[unionId]
            //console.debug(`DrawnNodes.DrawnIndividual.handleOnClick(): nodeDirectory['${unionId}']: '${union}'`)
            if (union) {
              allChildrenOfUnknownSpouse.push(...union.children)
            }
          }

          //console.debug(`DrawnNodes.DrawnIndividual.handleOnClick(): isUnknown, calling setAddIndividualNode(). children:`, children)
          dispatch(
            setAddIndividualNode(
              new AddIndividualNode({
                id: individual.id,
                user,
                x: x,
                y: y,
                addedFromIndividualNode: spouse,
                typeToAdd: ADD_TYPE_SPOUSE,
                children: allChildrenOfUnknownSpouse,
              })
            )
          )
        } else {
          console.error(
            `Unknown individual has empty spouses array`,
            individual
          )
        }
        return
      }

      if (allowNodeClick) {
        if (clickCount === 1) {
          dispatch(setClickedIndividualId(individual.id))
        }
      }
      if (navigateToNodeOnClick && individualUrl) {
        if (clickCount === 1) {
          if (embed) {
            const port = `:${window?.location?.port}` || ':'
            const url = `${window?.location.protocol}//${window?.location.hostname}${port}${individualUrl}`
            window.open(url, '_blank')
          } else {
            if (isBlog && isSubTree) {
              window.open(individualUrl, '_blank')
            } else {
              history.push(individualUrl)
            }
          }
        }
      }
      if (exploreNodeOnClick && !isUnknown) {
        if (clickCount === 1) {
          setTimeout(() => {
            dispatch(setExploredIndividualId(individual.id))
          }, 150)
        } else if (clickCount === 2) {
          if (navigateToNodeOnDoubleClick && individualUrl) {
            history.push(individualUrl)
            if (navigateToNodeOnDoubleClickHandler) {
              navigateToNodeOnDoubleClickHandler()
            }
          }
        }
      }
    },
    [
      dispatch,
      exploreNodeOnClick,
      allowNodeClick,
      history,
      individual,
      individualUrl,
      isUnknown,
      navigateToNodeOnClick,
      navigateToNodeOnDoubleClick,
      navigateToNodeOnDoubleClickHandler,
      x,
      y,
      user,
      nodeDirectory,
      isPublic,
      embed,
      isSubTree,
      isBlog,
    ]
  )

  let selectBoxProps = getBorderBoxProps(2, 7)
  const defaultBoxProps = getBorderBoxProps(1, 5)
  const handleProps = {
    size: HANDLE_SIZE,
    opacity: HANDLE_OPACITY,
    sides: 3,
    onClick: handleOnClick,
    style: { cursor: 'pointer' },
  }

  const showHandleBelow = hasInvisibleChildren
  const showHandleAbove = hasInvisibleParents
  const showHandleBeside = hasInvisibleSpouses

  let backgroundColor = NODE_BG
  let textColor = NODE_TEXT
  if (isEdited && !clickedIndividualId) {
    backgroundColor = 'NODE_BG_EXPLORED'
    textColor = NODE_TEXT_EXPLORED
  }
  if (nodesInactive) {
    textColor = NODE_TEXT_DISABLED
    backgroundColor = NODE_BACKGROUND_DISABLED
  } else if (isExplored && !isSubTree) {
    backgroundColor = NODE_BG_EXPLORED
    textColor = NODE_TEXT_EXPLORED
  } else if (isCurrentUser && !isSubTree) {
    backgroundColor = NODE_BG_CURRENT_USER
    textColor = NODE_TEXT_CURRENT_USER
  }
  let opacity = 1
  let extraBorderColor = 'transparent'
  if (isSubTree) {
    if (isSelected) {
      backgroundColor = NODE_BG_CURRENT_USER
      textColor = NODE_TEXT_CURRENT_USER
    } else {
      opacity = 0.6
      extraBorderColor = NODE_BORDER_DISABLED
      selectBoxProps = getBorderBoxProps(1, 5)
    }
  }

  if (mouseOver || isFocus) {
    extraBorderColor = NODE_BORDER_SELECTED
    selectBoxProps = getBorderBoxProps(2, 7)
  }
  if (isSelected) {
    extraBorderColor = NODE_BORDER_SELECTED_SECONDARY
  }

  const pointable =
    isUnknown ||
    exploreNodeOnClick ||
    allowNodeClick ||
    (navigateToNodeOnClick && individualUrl)

  //add a blur filter for alive people if this tree mode = demo_mode
  let blurFilter = ''
  if (currentLoggedInTree?.demoMode) {
    blurFilter = individual?.state === 'DECEASED' ? '' : 'url(#distort)'
  }

  return (
    <Group top={y} left={x} className={clsx(isExplored ? '' : 'fadeIn')}>
      <rect
        height={INDVDL_NODE_HEIGHT}
        width={INDVDL_NODE_WIDTH}
        y={FAR_TOP}
        x={FAR_LEFT}
        rx={4}
        fill={backgroundColor}
        strokeWidth={1}
      />
      {currentLoggedInTree?.demoMode && (
        <filter id="distort">
          <feGaussianBlur stdDeviation="3" />
        </filter>
      )}
      <rect
        stroke={nodesInactive ? NODE_BORDER_DISABLED : NODE_BORDER}
        {...defaultBoxProps}
      />
      <rect stroke={extraBorderColor} {...selectBoxProps} />
      <IndividualTreeImage
        individual={individual}
        isSelected={isSelected}
        preview={preview}
        mouseOver={mouseOver}
        opacity={opacity}
        blurFilter={blurFilter}
        isPublic={isPublic}
        nodeDirectory={nodeDirectory}
      />
      <Text
        pointerEvents="none"
        x={INNER_LEFT}
        y={23}
        fontSize={9}
        fontWeight="bold"
        fontFamily="Arial"
        textAnchor="left"
        capHeight={16}
        style={{ pointerEvents: 'none' }}
        fill={textColor}
        scaleToFit="shrink-only"
        width={INDVDL_NODE_WIDTH + 2 * INDVDL_NODE_PADDING}
        children={line1 || (isPublic ? 'Hidden' : 'Unknown')}
        opacity={mouseOver ? 1 : opacity}
        filter={blurFilter}
      ></Text>
      <Text
        pointerEvents="none"
        x={INNER_LEFT}
        y={32}
        fontSize={9}
        fontWeight="bold"
        textAnchor="left"
        fontFamily="Arial"
        capHeight={16}
        style={{ pointerEvents: 'none' }}
        fill={textColor}
        scaleToFit="shrink-only"
        width={INDVDL_NODE_WIDTH + 2 * INDVDL_NODE_PADDING}
        children={line2}
        opacity={mouseOver ? 1 : opacity}
        filter={blurFilter}
      ></Text>
      {(individual.yearOfBirth ||
        individual.yearOfDeath ||
        individual.state === STATE_DECEASED) && (
        <Text
          pointerEvents="none"
          x={INNER_LEFT}
          y={42}
          fontSize={8}
          fontFamily="Arial"
          textAnchor="left"
          capHeight={16}
          style={{ pointerEvents: 'none' }}
          fill={textColor}
          children={formatIndividualDates({
            dateOfBirthGed: individual.yearOfBirth,
            dateOfDeathGed: individual.yearOfDeath,
            replacement: '?',
            state: individual.state,
          })}
          opacity={mouseOver ? 1 : opacity}
          filter={blurFilter}
        ></Text>
      )}
      <rect
        height={INDVDL_NODE_HEIGHT}
        width={INDVDL_NODE_WIDTH}
        y={FAR_TOP}
        x={FAR_LEFT}
        style={pointable ? { cursor: 'pointer' } : {}}
        fill="none"
        pointerEvents="visible"
        onMouseOver={nodesInactive ? null : setMouseIsOver}
        onMouseOut={nodesInactive ? null : setMouseNotOver}
        onClick={handleOnClick}
      />
      {showHandleBeside && (
        <Polygon
          center={{
            y: FAR_TOP + 5,
            x: -INDVDL_NODE_WIDTH / 2 - HANDLE_SIZE - 2,
          }}
          rotate={60}
          {...handleProps}
        />
      )}
      {showHandleAbove && (
        <Polygon
          center={{
            y: FAR_TOP - HANDLE_SIZE,
            x: -HANDLE_SIZE / 2,
          }}
          rotate={-30}
          {...handleProps}
        />
      )}
      {showHandleBelow && (
        <Polygon
          center={{
            y: FAR_TOP + INDVDL_NODE_HEIGHT + HANDLE_SIZE,
            x: -HANDLE_SIZE / 2,
          }}
          rotate={30}
          {...handleProps}
        />
      )}
    </Group>
  )
}

export function DrawnInnerNode({
  x,
  y,
  partnerDifference,
  innerNode,
  leftIndividual = undefined,
  rightIndividual = undefined,
  selectedIndividualIds,
  exploreNodeOnClick = false,
  allowNodeClick = false,
  navigateToNodeOnClick = false,
  preview,
  distanceFromCentralInnerNode = undefined,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  nodesInactive,
  isPublic,
  isSubTree,
}) {
  const { NODE_UNION_STROKE, NODE_UNION_FILL } = useViewerColours()
  const commonProps = {
    exploreNodeOnClick: exploreNodeOnClick,
    allowNodeClick: allowNodeClick,
    navigateToNodeOnClick: navigateToNodeOnClick,
    navigateToNodeOnDoubleClick: navigateToNodeOnDoubleClick,
    navigateToNodeOnDoubleClickHandler: navigateToNodeOnDoubleClickHandler,
  }

  let from,
    to,
    cx,
    cy = 0
  if (innerNode.isUnion) {
    let xFrom, xTo

    // Draw the union connection on the right or left as appropriate:
    if (!leftIndividual) {
      xFrom = INDVDL_NODE_GRID_WIDTH * partnerDifference
      xTo = 0
      cx = -INDVDL_NODE_GRID_WIDTH / 2
    } else {
      xFrom = 0
      xTo = INDVDL_NODE_GRID_WIDTH * (partnerDifference || 1)
      cx = INDVDL_NODE_GRID_WIDTH / 2
    }
    from = { x: xFrom, y: 0 }
    to = { x: xTo, y: 0 }

    // Use `distanceFromCentralInnerNode` to vary the position of union connections depending
    // on how far from the spouse this individual is drawn:
    if (distanceFromCentralInnerNode) {
      const offset = distanceFromCentralInnerNode * 7
      from.y += offset
      to.y += offset
      cy += offset
    }
  }

  const individualProps = individual => {
    return {
      individual,
      isSelected: selectedIndividualIds.has(individual.id),
      preview,
      hasInvisibleChildren: innerNode.hasInvisibleChildren,
      hasInvisibleParents: innerNode.hasInvisibleParents(individual),
      hasInvisibleSpouses: innerNode.hasInvisibleSpouses(individual),
      ...commonProps,
    }
  }

  if (leftIndividual?.constructor?.name === AddIndividualNode.name) {
    leftIndividual = (
      <DrawnAddIndividualNode
        addIndividualNode={leftIndividual}
        x={0}
        y={0}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
      />
    )
  } else if (leftIndividual) {
    leftIndividual = (
      <DrawnIndividual
        x={0}
        y={0}
        {...individualProps(leftIndividual)}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
        isSubTree={isSubTree}
      />
    )
  }
  if (
    rightIndividual &&
    rightIndividual?.constructor?.name === AddIndividualNode.name
  ) {
    rightIndividual.x += leftIndividual ? 1 : 0
    rightIndividual = (
      <DrawnAddIndividualNode
        addIndividualNode={rightIndividual}
        x={leftIndividual ? INDVDL_NODE_GRID_WIDTH : 0}
        y={0}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
        isSubTree={isSubTree}
      />
    )
  } else if (rightIndividual) {
    rightIndividual = (
      <DrawnIndividual
        x={leftIndividual ? INDVDL_NODE_GRID_WIDTH : 0}
        y={0}
        {...individualProps(rightIndividual)}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
        isSubTree={isSubTree}
      />
    )
  }

  return (
    <Group top={y} left={x}>
      {innerNode.isUnion && (
        <>
          <Line
            from={from}
            to={to}
            stroke="gray"
            strokeWidth={1}
            fill="none"
            className="fadeIn fadeInSlow"
          ></Line>
          <circle
            cy={cy}
            cx={cx}
            r={NODE_UNION_CIRCLE_RADIUS}
            stroke={NODE_UNION_STROKE}
            strokeWidth={1}
            fill={NODE_UNION_FILL}
            className="fadeIn fadeInSlow"
          />
        </>
      )}
      {leftIndividual}
      {rightIndividual}
    </Group>
  )
}

export function DrawnGraphNode({
  graphNode,
  exploreNodeOnClick,
  allowNodeClick,
  navigateToNodeOnClick,
  preview,
  selectedIndividualIds,
  navigateToNodeOnDoubleClick = false,
  navigateToNodeOnDoubleClickHandler,
  nodesInactive,
  isPublic,
  isSubTree,
}) {
  let { y } = graphNode

  const commonInnerNodeProps = {
    exploreNodeOnClick: exploreNodeOnClick,
    allowNodeClick: allowNodeClick,
    navigateToNodeOnClick: navigateToNodeOnClick,
    y: y * INDVDL_NODE_GRID_HEIGHT,
    selectedIndividualIds: selectedIndividualIds,
    navigateToNodeOnDoubleClick: navigateToNodeOnDoubleClick,
    navigateToNodeOnDoubleClickHandler: navigateToNodeOnDoubleClickHandler,
  }

  // Build up a list of inner nodes, drawn according to the order
  // they have been arranged on the graph node
  const drawnInnerNodes = []
  const leftMostX = graphNode.leftX
  let displayOrderIdx = 0

  // For all left side unions, draw just the left partner
  let distanceFromCentralInnerNode = graphNode.leftOtherUnions?.length - 1
  for (let innerNode of graphNode.leftOtherUnions) {
    const leftIndividual =
      graphNode.individualNodesInDisplayOrder[displayOrderIdx]
    drawnInnerNodes.push(
      <DrawnInnerNode
        key={`${graphNode.id}--${innerNode.id}`}
        leftIndividual={leftIndividual}
        partnerDifference={graphNode.distanceToPartner(leftIndividual)}
        distanceFromCentralInnerNode={distanceFromCentralInnerNode}
        innerNode={innerNode}
        preview={preview}
        x={(leftMostX + displayOrderIdx) * INDVDL_NODE_GRID_WIDTH}
        {...commonInnerNodeProps}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
        isSubTree={isSubTree}
      />
    )
    displayOrderIdx += 1
    distanceFromCentralInnerNode -= 1
  }

  const centreDisplayOrderIdx = displayOrderIdx

  // For all right side unions, draw just the right partner.
  displayOrderIdx += graphNode.rightOtherUnions.length + 1
  distanceFromCentralInnerNode = graphNode.rightOtherUnions.length - 1
  for (let innerNode of graphNode.rightOtherUnions.reverse()) {
    const rightIndividual =
      graphNode.individualNodesInDisplayOrder[displayOrderIdx]
    drawnInnerNodes.push(
      <DrawnInnerNode
        key={`${graphNode.id}--${innerNode.id}`}
        preview={preview}
        rightIndividual={rightIndividual}
        partnerDifference={graphNode.distanceToPartner(rightIndividual)}
        distanceFromCentralInnerNode={distanceFromCentralInnerNode}
        innerNode={innerNode}
        x={(leftMostX + displayOrderIdx) * INDVDL_NODE_GRID_WIDTH}
        {...commonInnerNodeProps}
        nodesInactive={nodesInactive}
        isPublic={isPublic}
        isSubTree={isSubTree}
      />
    )
    displayOrderIdx -= 1
    distanceFromCentralInnerNode -= 1
  }

  // Draw the central inner node last so that it is furthest forwards in
  // the order in which layers are drawn.
  drawnInnerNodes.push(
    <DrawnInnerNode
      key={`${graphNode.id}--${graphNode.centralInnerNode.id}`}
      preview={preview}
      leftIndividual={
        graphNode.individualNodesInDisplayOrder[centreDisplayOrderIdx]
      }
      rightIndividual={
        graphNode.individualNodesInDisplayOrder[centreDisplayOrderIdx + 1]
      }
      innerNode={graphNode.centralInnerNode}
      x={(leftMostX + centreDisplayOrderIdx) * INDVDL_NODE_GRID_WIDTH}
      {...commonInnerNodeProps}
      nodesInactive={nodesInactive}
      isPublic={isPublic}
      isSubTree={isSubTree}
    />
  )

  return drawnInnerNodes
}

export function DrawnLink({ link }) {
  const hoveredIndividualId = useSelector(selectHoveredIndividualId)
  const linkData = {
    source: {
      x:
        (link.sourceGraphNode.leftX + link.sourceIdx + 0.5) *
        INDVDL_NODE_GRID_WIDTH,
      y:
        link.sourceGraphNode.y * INDVDL_NODE_GRID_HEIGHT +
        NODE_UNION_CIRCLE_RADIUS +
        link.sourceVerticalOffset * NODE_UNION_CIRCLE_RADIUS,
    },
    target: {
      x: (link.targetGraphNode.leftX + link.targetIdx) * INDVDL_NODE_GRID_WIDTH,
      y:
        link.targetGraphNode.y * INDVDL_NODE_GRID_HEIGHT -
        INDVDL_NODE_HEIGHT / 2,
    },
  }

  // Draw the horizontal lines from different unions at offset heights:
  let percent = 0.8 - link.verticalOffset * 0.1

  // special case for links occurring below "collapsed" generations
  if (link.sourceGraphNode.isCollapsedGenerations) {
    linkData.source.y += 40
    percent -= 0.2
  }
  const targetHovered =
    link.targetGraphNode.individualNodesInDisplayOrder[link.targetIdx]?.id ===
    hoveredIndividualId

  return (
    <LinkVerticalStep
      data={linkData}
      percent={percent}
      stroke={targetHovered ? 'black' : 'gray'}
      strokeWidth={targetHovered ? 2 : 1}
      opacity={1}
      fill="none"
      className="fadeIn fadeInSlow"
    />
  )
}

export function CollapsedGenerationsNode({ graphNode }) {
  let { x, y } = graphNode
  const { NODE_UNION_STROKE, NODE_UNION_FILL } = useViewerColours()
  const dispatch = useDispatch()
  const familyId = useSelector(selectChosenFamilyId)

  const triggerRef = useRef()
  const handleOnClick = () => {
    dispatch(chooseFamilyId(familyId))
    triggerRef.current(true)
  }
  const exploreTrigger = ({ onClick }) => {
    triggerRef.current = onClick
  }

  return (
    <>
      <Explore trigger={exploreTrigger} />
      <Group
        top={y * INDVDL_NODE_GRID_HEIGHT}
        left={x * INDVDL_NODE_GRID_WIDTH}
        className="fadeIn"
      >
        <Text
          pointerEvents="none"
          y={5}
          fontSize={15}
          fontFamily="Arial"
          textAnchor="middle"
          style={{ pointerEvents: 'none' }}
          fill="#444"
          width={120}
          children={`${graphNode.hiddenGenerations} generations`}
        ></Text>
        <circle
          cy={25}
          cx={-25}
          r={UNION_NODE_HEIGHT / 2}
          stroke={NODE_UNION_STROKE}
          strokeWidth={LINK_STROKE_WIDTH}
          fill={NODE_UNION_FILL}
          onClick={handleOnClick}
          style={{ cursor: 'pointer' }}
        />
        <circle
          cy={25}
          r={UNION_NODE_HEIGHT / 2}
          stroke={NODE_UNION_STROKE}
          strokeWidth={LINK_STROKE_WIDTH}
          fill={NODE_UNION_FILL}
          onClick={handleOnClick}
          style={{ cursor: 'pointer' }}
        />
        <circle
          cy={25}
          cx={25}
          r={UNION_NODE_HEIGHT / 2}
          stroke={NODE_UNION_STROKE}
          strokeWidth={LINK_STROKE_WIDTH}
          fill={NODE_UNION_FILL}
          onClick={handleOnClick}
          style={{ cursor: 'pointer' }}
        />
      </Group>
    </>
  )
}
