import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { useNotification } from 'src/modules/app/hooks'
import { Button, Form, FormikTextField, FIELD_REQUIRED } from 'src/modules/ui'

import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLinks from './FormLinks'
import FormLink from './FormLink'
import ForgotPasswordSetNew from './ForgotPasswordSetNew'
import { ACTION_LOGIN } from '../app/appConstants'
import { sendPageview, sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

export const ForgotPasswordForm = ({
  handleSubmit,
  isSubmitting,
  onClickBackToLogin,
  values,
}) => (
  <div>
    <FormHeader title="Forgotten Password" />
    <Form>
      <FormikTextField fullWidth label="Email" name="username" type="email" />
      <FormActions>
        <FormLinks>
          <FormLink
            onClick={e => onClickBackToLogin({ email: values.username })}
          >
            Back to login
          </FormLink>
        </FormLinks>
        <Button
          permissionAction={ACTION_LOGIN}
          color="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          size="large"
          type="submit"
        >
          Request Reset Code
        </Button>
      </FormActions>
    </Form>
  </div>
)

const ForgotPassword = ({
  onStateChange,
  user = {},
  prefillEmail,
  ...props
}) => {
  const { showError, showSuccess } = useNotification()
  const [codeSentToUsername, setCodeSentToUsername] = useState()

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__FORGOT_PASSWORD_PART_1)
  }, [])

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(FIELD_REQUIRED),
  })
  const initialValues = { username: prefillEmail ?? '' }

  const handleSubmit = async ({ username }, { setFieldError }) => {
    try {
      sendEvent(ga4Events.FORGOT_PASSWORD_PART_1_SUBMITTED)
      await Auth.forgotPassword(username)
      setCodeSentToUsername(username)
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setFieldError('username', 'Incorrect username')
      } else {
        showError(err.message)
      }
    }
  }

  const handleResendCode = async () => {
    try {
      await Auth.forgotPassword(codeSentToUsername)
      showSuccess('New code sent')
    } catch (err) {
      showError(err.message)
    }
  }

  useEffect(() => {
    const doSend = async () => {
      const email = user && user.email
      if (email) {
        try {
          await Auth.forgotPassword(email)
          setCodeSentToUsername(email)
        } catch (err) {
          showError(err.message)
        }
      }
    }
    doSend()
  }, [user, showError])

  return codeSentToUsername ? (
    <ForgotPasswordSetNew
      isReset={!!user.reset}
      onClickResendCode={handleResendCode}
      onStateChange={onStateChange}
      username={codeSentToUsername}
      {...props}
    />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => <ForgotPasswordForm {...formik} {...props} />}
    </Formik>
  )
}

export default ForgotPassword
