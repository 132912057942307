import React from 'react'
import { Box } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ReplayIcon from '@mui/icons-material/Replay'

import ContentHeaderMeta from 'src/modules/content/ContentHeaderMeta'
import { Button } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const InformationRequestHeaderMeta = ({
  onClickEdit,
  onClickCloseRequest,
  onClickReopenRequest,
  canCloseAndReopen = false,
  state,
  ...informationRequest
}) => (
  <ContentHeaderMeta
    {...informationRequest}
    sx={{ justifyContent: 'space-between' }}
    visibility={null}
    actions={
      <Box sx={{ display: 'flex', pl: 1, justifySelf: 'flex-end' }}>
        {canCloseAndReopen && (
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {state === 'PUBLISHED' ? (
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                sx={{ ml: 1 }}
                startIcon={<CheckIcon />}
                onClick={onClickCloseRequest}
              >
                Close Request
              </Button>
            ) : state === 'CLOSED' ? (
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                onClick={onClickReopenRequest}
                sx={{ ml: 1 }}
                startIcon={<ReplayIcon />}
                variant="outlined"
              >
                Reopen Request
              </Button>
            ) : null}
          </Box>
        )}
      </Box>
    }
  />
)

export default InformationRequestHeaderMeta
