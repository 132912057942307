import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
// import TwitterIcon from '@mui/icons-material/Twitter'
import CopyrightIcon from '@mui/icons-material/Copyright'

import { Link, IconButton } from 'src/modules/ui'
import config from '../../config'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { generateTreeHomeLink } from './links'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'
import { useEmbed } from '../public/hooks'
import { ACTION_ALL_ACCESS } from './appConstants'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.fadedPurple.main,
}))

const Inner = styled(Box)(({ theme }) => ({
  gridTemplateColumns: '60% 40%',
  gridGap: theme.spacing(2),
  margin: '0 auto',
  maxWidth: theme.maxWidth,
}))

const Logo = styled('img')({
  display: 'block',
})

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}))

const StyledFaceBook = styled(FacebookRoundedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.light,
  },
  fontSize: theme.spacing(5),
}))

const StyledCopyright = styled(CopyrightIcon)(({ theme }) => ({
  display: 'inline-flex',
  marginRight: theme.spacing(1 / 2),
}))

const Footer = () => {
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const embed = useEmbed()
  const location = useLocation()
  const isSubtreeDemo =
    location.pathname.substring(location.pathname.length - 11) === 'subtreedemo'

  return (
    <>
      {!isSubtreeDemo && (
        <Root className="noneDisplayOnPrint">
          {!embed ? (
            <Inner
              sx={{
                py: 4,
                px: 4,
                display: {
                  xs: 'block',
                  sm: 'grid',
                },
              }}
            >
              <RouterLink
                style={{ textDecoration: 'none' }}
                to={generateTreeHomeLink(authorisedTreeSlug)}
              >
                <Logo
                  alt="weare.xyz"
                  src="/logo.png"
                  sx={{ mb: { xs: 2, md: 0 }, width: '30%' }}
                />
              </RouterLink>
              <Box sx={{ mb: { xs: 2, md: 0 } }}>
                <a
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                  href="https://www.facebook.com/wearedotxyz"
                  target="_blank"
                >
                  <IconButton
                    permissionAction={ACTION_ALL_ACCESS}
                    sx={{ mr: 1, p: 0, width: 34, height: 34 }}
                  >
                    <StyledFaceBook />
                  </IconButton>
                </a>
                <a
                  style={{ textDecoration: 'none' }}
                  rel="noreferrer"
                  href="https://www.instagram.com/weare.xyz/"
                  target="_blank"
                >
                  <StyledIconButton
                    permissionAction={ACTION_ALL_ACCESS}
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    <InstagramIcon sx={{ color: 'fadedPurple.main' }} />
                  </StyledIconButton>
                </a>
                {/* Twitter account currently suspended */}
                {/* <a
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
            href="https://twitter.com/wearedotxyz"
            target="_blank"
          >
            <StyledIconButton size="small">
              <TwitterIcon sx={{ color: 'fadedPurple.main' }} />
            </StyledIconButton>
          </a> */}
              </Box>
              <div style={{ display: 'flex' }}>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`${config.publicUrl}/about/`}
                >
                  <Typography
                    color="primary"
                    variant="body2"
                    underline="hover"
                    sx={{ mr: 4 }}
                  >
                    About us
                  </Typography>
                </a>
                <Link
                  sx={{ mr: 4 }}
                  variant="body2"
                  underline="hover"
                  component="button"
                  onClick={() => window.FreshworksWidget('open')}
                >
                  Support
                </Link>
                <Link
                  variant="body2"
                  underline="hover"
                  component="button"
                  onClick={() => window.FreshworksWidget('open')}
                >
                  Report Content
                </Link>
              </div>
              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`${config.publicUrl}/privacy-notice/`}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mr: 4, textDecoration: 'none' }}
                  >
                    Privacy Notice
                  </Typography>
                </a>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`${config.publicUrl}/terms-conditions/`}
                >
                  <Typography variant="body2" color="textSecondary">
                    T&amp;Cs
                  </Typography>
                </a>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body2" component="span">
                  Build: {config.build_number} &nbsp;
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body2" component="span">
                  <StyledCopyright fontSize="inherit" />
                  We Are [...] {new Date().getFullYear()}
                </Typography>
              </Box>
            </Inner>
          ) : (
            <Typography
              textAlign="right"
              color="primary"
              fontFamily="FS Split Serif"
              fontSize={18}
              sx={{
                transitionDuration: '0.5s',
                transitionTimingFunction: 'ease-out',
                p: 1,
              }}
            >
              powered by{' '}
              <a
                href="https://www.weare.xyz"
                target="_blank"
                rel="noreferrer"
                style={{ color: 'red' }}
              >
                weare.xyz
              </a>
            </Typography>
          )}
        </Root>
      )}
    </>
  )
}

export default Footer
