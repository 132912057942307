import { styled } from '@mui/material'
import { makeStyles } from '@mui/styles'

import FamilyNavigationControl from './FamilyNavigationControl'
import NavigateToControl from './NavigateToControl'
import { Button } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const CloseNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '250px',
  zIndex: 100,
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  background: 'white',
}))

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 100,
  },
}))

const TreeViewerMenu = ({
  userHomeIndividual,
  displayName,
  exploredIndividualNode,
  familyObj,
  handleCloseModal,
  handleFamilySelected,
  handleForceCloseModal,
  navigatorMode,
  navigateToFamily,
  navigateToIndividual,
  pyramid,
}) => {
  const classes = useStyles()
  return (
    <>
      <FamilyNavigationControl
        {...{
          pyramid,
          handleFamilySelected,
          exploredIndividualNode,
          userHomeIndividual,
        }}
      />
      {navigatorMode === false && (
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          variant="outlined"
          className={classes.closeButton}
          onClick={handleCloseModal}
        >
          Close
        </Button>
      )}
      {navigatorMode && (
        <CloseNavigation>
          <NavigateToControl
            {...{
              familyObj,
              pyramid,
              navigateToFamily,
              exploredIndividualNode,
              navigateToIndividual,
              displayName,
              handleForceCloseModal,
            }}
          />
        </CloseNavigation>
      )}
    </>
  )
}

export default TreeViewerMenu
