import PublicPageBlogPostList from '../page/PublicPageBlogPostList'

import { Box, styled } from '@mui/material'
import React from 'react'
import { Container } from '../../ui'
import { useTheme } from '@emotion/react'
import { BLOG_ARTICLE_DISPLAY_CONFIG } from '../PublicBlog'
import HtmlHeader from '../../app/HtmlHeader'
import { useSelector } from 'react-redux'
import { selectTree } from 'src/modules/public/tree/treeSlice'

export const BlogContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(32),
  height: '100%',
}))

const PublicBlogHomePage = () => {
  const theme = useTheme()

  const tree = useSelector(selectTree)
  const headerProps = {
    title: tree?.name,
  }
  return (
    <>
      <HtmlHeader {...headerProps} />

      <BlogContent
        style={{
          maxWidth: BLOG_ARTICLE_DISPLAY_CONFIG.maxWidth,
          margin: 'auto',
          marginTop: 32,
          minWidth: '60%',
        }}
      >
        <Container
          showBackGround={false}
          sx={{ backgroundColor: theme.palette.grey.main }}
        >
          <PublicPageBlogPostList
            block_limit={1}
            block_type={'TEXT'}
            limit={4}
          />
        </Container>
      </BlogContent>
    </>
  )
}

export default PublicBlogHomePage
