import { createDefaultThemeParams, themeBuilder } from '../../../ui/theme'

export const weare = themeId => {
  let params = createDefaultThemeParams()

  const light = '#F5F5F5'
  const white = '#FFFFFF'

  params.colors.secondaryGrey = white
  params.colors.grey = light
  params.backgroundImage = `/${themeId}.jpg`

  const theme = themeBuilder(params)

  return theme
}
