import React, { useEffect, useState } from 'react'

import { withOverridableComponent } from 'src/overridable'
import { PhotoListNavigatorDialog } from 'src/modules/photo/MediaNavigator'
import PhotoGallery from 'src/modules/photo/PhotoGallery'

export const FlexBoxGallery = ({
  media,
  onClickPhoto,
  showBorders = false,
  height,
}) => {
  return (
    <div style={{ display: 'flexbox' }}>
      {media.map((photo, index) => (
        <img
          alt={''}
          key={index}
          src={photo.fileMedium}
          style={{
            padding: '2px',
            height: height || 'auto',
            width: height ? 'auto' : '200px',
            objectFit: 'cover',
            cursor: 'pointer',
            boxShadow: showBorders ? photo.boxShadow : 'none',
            borderRadius: showBorders ? photo.borderRadius : 'none',
          }}
          onClick={() => onClickPhoto(index)}
        />
      ))}
    </div>
  )
}

const ContentPhotos = ({
  media,
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
  flexBoxGallery = false,
}) => {
  const [clickedPhoto, setClickedMedia] = useState(null)
  const [resultSets, setResultSets] = useState(media)

  const handleDialogClose = () => {
    setClickedMedia(null)
  }

  const handleClick = index => {
    setClickedMedia(resultSets[index])
  }

  const maxWidth = useState(
    resultSets?.length === 2 ? 800 : resultSets?.length === 1 ? 400 : 'auto'
  )

  const handleDelete = () => {
    const newResults = resultSets.filter(photo => photo.id !== clickedPhoto.id)
    setResultSets(newResults)
  }

  useEffect(() => {
    setResultSets(media)
  }, [media])

  return (
    <div style={{ maxWidth }}>
      {!!clickedPhoto && (
        <PhotoListNavigatorDialog
          media={resultSets}
          initialPhoto={clickedPhoto}
          onClose={handleDialogClose}
          handleDelete={handleDelete}
          mediaDetailEditDialogTitle={mediaDetailEditDialogTitle}
          mediaDetailShowTranscription={mediaDetailShowTranscription}
          mediaDetailOfferEnterTranscription={
            mediaDetailOfferEnterTranscription
          }
          mediaDetailShowTakenOnDate={mediaDetailShowTakenOnDate}
          mediaDetailHideTags={mediaDetailHideTags}
          mediaDetailDefaultAllowNoTags={mediaDetailDefaultAllowNoTags}
        />
      )}
      {!flexBoxGallery && (
        <PhotoGallery media={resultSets} onClickPhoto={handleClick} />
      )}
      {flexBoxGallery && (
        <FlexBoxGallery media={resultSets} onClickPhoto={handleClick} />
      )}
    </div>
  )
}

export default withOverridableComponent(ContentPhotos, 'ContentPhotos')
