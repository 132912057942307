import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Box, Dialog, DialogContent, Stack } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { generateTreeHomeLink } from 'src/modules/app/links'

import { CreateOrUpdateIndividualForm } from './CreateOrUpdateIndividualForm'
import { updateIndividual, deleteIndividual } from './viewerSlice'
import { IndividualRelationships } from './IndividualRelationships'
import DialogTitle from 'src/modules/ui/DialogTitle'
import TreeLoadingGuard from './TreeLoadingGuard'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { fetchLinkedFacts } from '../page/pageSlice'

export const UpdateIndividualRelationshipsDialog = ({
  open = false,
  individual,
  trigger,
  onCloseParentDialog,
}) => {
  const [modalOpen, setModalOpen] = useState(open)
  const handleShowModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    if (onCloseParentDialog) {
      onCloseParentDialog()
    }
    setModalOpen(false)
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <DialogTitle onClose={handleCloseModal}>Edit Individual</DialogTitle>
        <DialogContent>
          <TreeLoadingGuard partialCallKey={individual?.id}>
            <UpdateIndividualRelationships
              handleCloseModal={handleCloseModal}
              individual={individual}
            />
          </TreeLoadingGuard>
        </DialogContent>
      </Dialog>
    </>
  )
}

/*
 dialog for editing individual details and relationships in a list
 */
export const UpdateIndividualRelationshipsListDialog = ({
  individual,
  onCloseParentDialog,
  target,
  pageType,
  isLoadingOveride = false,
}) => {
  const modalOpen = individual ? true : false
  const handleCloseModal = () => {
    if (onCloseParentDialog) {
      onCloseParentDialog()
    }
  }

  let updatedIndividual = individual

  if (target && updatedIndividual) {
    updatedIndividual = { ...individual }
    updatedIndividual.links = [
      ...updatedIndividual.links,
      { type: pageType, target: target, display: 'NEW ONE BABY' },
    ]
  }

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <DialogTitle onClose={handleCloseModal}>Edit Individual</DialogTitle>
        <DialogContent>
          <TreeLoadingGuard
            partialCallKey={updatedIndividual?.id}
            isLoadingOveride={isLoadingOveride}
          >
            {updatedIndividual && (
              <UpdateIndividualRelationships
                handleCloseModal={handleCloseModal}
                individual={updatedIndividual}
              />
            )}
          </TreeLoadingGuard>
        </DialogContent>
      </Dialog>
    </>
  )
}

const UpdateIndividualRelationships = ({
  individual,
  onCancel,
  handleCloseModal,
}) => {
  const history = useHistory()
  const dispatchUpdateIndividual = useActionDispatcher(updateIndividual)
  const dispatchDeleteIndividual = useActionDispatcher(deleteIndividual)
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const saving = dispatchUpdateIndividual.status === 'loading'
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const onSubmit = async individualData => {
    await dispatchUpdateIndividual({
      individualId: individual.id,
      ...individualData,
    })
    await dispatchFetchLinkedFacts({ target: individual.id })
    handleCloseModal()
  }

  const errNote = err => {
    console.debug(
      `UndateIndividualRelationships.errNote(): called with err:`,
      err
    )
    if (err.data) {
      if (err.data.friendlyMessage) {
        return err.data.friendlyMessage
      }
    }
    return 'oops... there was a problem doing that'
  }

  const onDelete = async individualData => {
    try {
      await dispatchDeleteIndividual(
        {
          individualId: individual.id,
        },
        {
          errorNotification: err => errNote(err),
          successNotification: 'Individual deleted',
          errorSnackbarOptions: {
            autoHideDuration: 10000,
          },
        }
      ).unwrap()

      //now need to move the user away from the current page which is the now-deleted individual
      //history.goBack() would work nicely except that if it goes to a tree page that doesn't refresh so still shows the deleted individual.
      //For now just take them to /home and reload the whole site.
      history.push(generateTreeHomeLink(treeSlug))
      window.location.reload()
    } catch (err) {
      //dispatchDeleteIndividual() threw an error and user will have been shown a message
    }
  }

  return (
    <>
      <Stack direction="row">
        <Box sx={{ mr: 3, maxWidth: 720 }}>
          <CreateOrUpdateIndividualForm
            saving={saving}
            individual={individual}
            onSubmit={onSubmit}
            submitButtonLabel="Save Individual Details"
            onDelete={onDelete}
          />
        </Box>
        <Box sx={{ minWidth: '400px' }}>
          <IndividualRelationships
            individual={individual}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      </Stack>
    </>
  )
}
