import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useActionDispatcher } from '../app'
import { selectAuthorisedTreeSlug, selectCurrentTree } from '../auth/authSlice'
import { updateTreeSettings } from '../trees/treesSlice'
import { Button, Container } from '../ui'
import { fetchUser } from '../auth/authSlice'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const ThemePreview = ({ index, title, src, selectTheme, currentTheme }) => {
  const handleThemeId = () => {
    let themeId = ''
    if (title?.toLowerCase() === 'our picks') {
      const splitSrc = src.split('-')[0]
      themeId = splitSrc.substring(1)
    } else {
      themeId = title?.toLowerCase()?.split(' ')?.join('') + index
    }
    return themeId
  }
  const themeId = handleThemeId()
  const isSelected = themeId === currentTheme
  return (
    <Box
      boxShadow={2}
      onClick={() => selectTheme(themeId)}
      sx={{
        cursor: 'pointer',
        border: isSelected ? '2px solid #240048' : '0px solid transparent',
        borderRadius: '8px',
        padding: 0,
        height: 'fit-content',
      }}
    >
      <img
        src={src}
        width={340}
        style={{ borderRadius: 8, maxWidth: '50vw', height: 'auto' }}
        alt="Theme preview"
      />
    </Box>
  )
}

const ThemeSelectorContainer = ({
  images,
  themeCount,
  title,
  selectTheme,
  treeSettings,
}) => {
  const arrayToMap = images || [...Array(themeCount)]

  return (
    <Box display="flex" mb={4} alignItems="center">
      <Typography
        variant="subtitle1"
        sx={{ minWidth: { xs: '15vw', sm: 100 } }}
      >
        {title}
      </Typography>
      <Box display="flex" gap={2} sx={{ overflowX: 'scroll' }}>
        {arrayToMap?.map((img, index) => {
          const src =
            typeof img === 'string'
              ? img
              : `/${title.toLowerCase()}-theme${index}.jpg`
          return (
            <ThemePreview
              index={index}
              title={title}
              src={src}
              selectTheme={selectTheme}
              currentTheme={treeSettings?.theme}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default function BlogSettings() {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const tree = useSelector(selectCurrentTree)
  const dispatchFetchUser = useActionDispatcher(fetchUser)

  const dispatchUpdateTreeSettings = useActionDispatcher(updateTreeSettings)

  const defaultTheme = tree?.treeSettings?.theme || 'weare0'
  const [treeSettings, setTreeSettings] = useState({
    theme: defaultTheme,
  })

  const saveSettings = async () => {
    await dispatchUpdateTreeSettings(
      { treeSlug, updates: treeSettings },
      {
        successNotification: 'Tree settings updated',
      }
    )
    await dispatchFetchUser()
  }

  const selectTheme = themeId => {
    setTreeSettings(prevState => ({ ...prevState, theme: themeId }))
  }

  const getSelectedImage = () => {
    const theme = treeSettings.theme

    const name = theme.substring(0, theme.length - 1)
    const lastCharacter = theme.substring(theme.length - 1)
    if (!isNaN(lastCharacter)) {
      return `/${name}-theme${lastCharacter}.jpg`
    } else {
      return `/${theme}-theme.jpg`
    }
  }

  return (
    <Container showBackGround={false}>
      <Box>
        <Typography mb={2}>Selected theme:</Typography>
        <img
          src={getSelectedImage()}
          width={600}
          style={{ borderRadius: 16, maxWidth: '50vw' }}
          alt="Theme preview"
        />
      </Box>
      <Box display="flex" gap={4} alignItems="center" mb={4} mt={2}>
        <Typography>Choose a theme for your blog</Typography>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          onClick={saveSettings}
          isLoading={
            dispatchFetchUser.status === 'loading' ||
            dispatchUpdateTreeSettings.status === 'loading'
          }
        >
          Save
        </Button>
      </Box>
      <ThemeSelectorContainer
        title="Our Picks"
        images={['/plain-theme.jpg', '/light-theme.jpg', '/dark-theme.jpg']}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="WeAre"
        themeCount={3}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Rustic"
        themeCount={2}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Industrial"
        themeCount={2}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Pioneer"
        themeCount={1}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Seafaring"
        themeCount={3}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Coastal"
        themeCount={2}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Highlands"
        themeCount={5}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
      <ThemeSelectorContainer
        title="Misc"
        themeCount={2}
        selectTheme={selectTheme}
        treeSettings={treeSettings}
      />
    </Container>
  )
}
