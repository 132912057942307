import { PublicPhotosBase } from '../page/PublicPhotos'
import { usePublicHomePageUrlSegments } from '../page/hooks'
import { PUBLIC_ROOT, ALBUMS_TAB, MEDIA_TAB } from 'src/modules/app/links'

const PublicHomePhotos = () => {
  const { slug } = usePublicHomePageUrlSegments()

  const photoRoute = `/${PUBLIC_ROOT}/${slug}/home/${MEDIA_TAB}`
  const albumRoute = `/${PUBLIC_ROOT}/${slug}/home/${ALBUMS_TAB}`

  return <PublicPhotosBase {...{ photoRoute, albumRoute }} />
}

export default PublicHomePhotos
