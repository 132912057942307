import React, { useState } from 'react'
import { useField } from 'formik'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import WeAreDatePicker from './WeAreDatePicker'
import EventIcon from '@mui/icons-material/Event'
import { convertUIGedDate } from '../common/gedcomDateParser'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const FormikGedDatePicker = ({
  helperText,
  setFieldValue,
  label,
  mt,
  fullWidth = true,
  multiple = false,
  ...props
}) => {
  const [field, meta] = useField(props)
  const [displayDate, setDisplayDate] = useState(field.value)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  // console.log('DEBUG FormikGedDatePicker', {
  //   field,
  //   displayDate,
  //   convertUIGedDate: convertUIGedDate(displayDate),
  // })

  const handleChange = gedDateStr => {
    if (!multiple) {
      setFieldValue(field.name, gedDateStr)
      setDisplayDate(gedDateStr)
    } else if (gedDateStr) {
      setFieldValue(field.name, [...displayDate, gedDateStr])
      setDisplayDate(prevState => [...prevState, gedDateStr])
    }
  }

  const handleChangeMultiple = (event, newValue) => {
    setFieldValue(field.name, newValue)
    setDisplayDate(newValue)
  }

  const TextInput = props => {
    return (
      <TextField
        {...props}
        name={field.name + '_display'}
        error={hasError}
        helperText={hasError ? error : helperText}
        label={label}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: field.value ? true : false }}
        aria-readonly
        sx={{ marginTop: mt }}
      />
    )
  }
  return (
    <WeAreDatePicker
      trigger={props => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          {...props}
        >
          {multiple ? (
            <Autocomplete
              filterOptions={(options, state) => options}
              ChipProps={{ size: 'small' }}
              freeSolo
              fullWidth={fullWidth}
              options={[]}
              ListboxProps={{ sx: { overflow: 'hidden', maxHeight: '100%' } }}
              multiple={true}
              onChange={handleChangeMultiple}
              renderInput={params => <TextInput {...params} />}
              value={displayDate}
            />
          ) : (
            <TextInput value={convertUIGedDate(displayDate)} />
          )}
          <IconButton permissionAction={ACTION_ALL_ACCESS} {...props}>
            <EventIcon />
          </IconButton>
        </div>
      )}
      inputDate={!multiple ? field.value : ''}
      onFinishSubmit={handleChange}
    />
  )
}

export default FormikGedDatePicker
