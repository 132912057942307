import PublicArticle from './PublicArticle'
import { fetchPublicBlogPost, fetchPublicBlogPostStub } from './contentSlice'

const BlogPostArticle = props => {
  return (
    <>
      <PublicArticle
        {...props}
        fetchPublicArticleStubAction={fetchPublicBlogPostStub}
        fetchPublicArticleAction={fetchPublicBlogPost}
      />
    </>
  )
}

export default BlogPostArticle
