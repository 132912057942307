import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import Button from './Button'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const RefreshDialog = props => {
  const handlePageRefresh = () => {
    window.location.reload()
  }

  return (
    <Dialog open={props.refreshModalOpen} maxWidth="md">
      <DialogContent>
        <Typography>Changes to content will require a page reload.</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          onClick={() => handlePageRefresh()}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefreshDialog
