import React, { useContext } from 'react'
import { styled, Box, Stack } from '@mui/material'

import { Thumbnail } from 'src/modules/photo'
import { Typography } from 'src/modules/ui'
import { defaultImage } from 'src/modules/content/ArticleCard'
import { formatIndividualWithDates } from '../ui/individualUtils'
import {
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_LOCATION,
} from 'src/modules/app/links'
import { getThumbnailWidth } from '../photo/Thumbnail'
import {
  generatePublicLinkForObject,
  INSTANCE_TYPE_MEDIA,
  PATH_SEGMENT_FOR_INSTANCE_TYPE,
} from '../app/links'
import SiteWideIcon from '../ui/SiteWideIcon'
import { Link } from '../ui'
import { useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { generateLinkForObject } from 'src/modules/app/links'
import { useIsPublic } from '../app'
import { PublicContext } from '../public/contexts'

const SummaryContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  alignSelf: 'center',
}))

const Icon = styled('img')({
  height: '50%',
  width: '10%',
})

const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '4rem',
  overflow: 'hidden',
  alignItems: 'start',
  textOverflow: 'ellipsis',
})

const MoreComponent = ({
  type,
  isTaggedInOtherEvents = false,
  isTaggedInOtherLocations = false,
  photo,
  id,
}) => {
  const isPublic = useIsPublic()
  const privateTreeSlug = useSelector(selectAuthorisedTreeSlug)
  let { treeSlug } = useContext(PublicContext)
  treeSlug = privateTreeSlug || treeSlug

  if (!isTaggedInOtherEvents && !isTaggedInOtherLocations) {
    return null
  }

  if ([INSTANCE_TYPE_LOCATION, INSTANCE_TYPE_EVENT].includes(type) === false) {
    return null
  }

  const color = photo ? 'white' : 'black'

  const privateLink =
    generateLinkForObject(treeSlug, type, id) +
    '/' +
    PATH_SEGMENT_FOR_INSTANCE_TYPE[type] +
    '?hierarchical=true'

  const publicLink =
    generatePublicLinkForObject(treeSlug, type, id) +
    '/' +
    PATH_SEGMENT_FOR_INSTANCE_TYPE[type] +
    '?hierarchical=true'

  const toLink = isPublic ? publicLink : privateLink

  return (
    <Link to={toLink}>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          alignItems: 'center',
          backgroundColor: 'transparent',
          padding: '0.25rem',
          border: `1px solid ${color}`,
        }}
      >
        <Typography variant="subtitle5" sx={{ color: color }}>
          {' '}
          ... more
        </Typography>
        <SiteWideIcon
          instanceType={type}
          fontSize={'small'}
          color={color}
          sx={{ color: color }}
        />
      </Stack>
    </Link>
  )
}

const SummaryCard = ({ photo, type, ...rest }) => {
  const {
    title,
    givenName,
    surname,
    yearOfBirth,
    yearOfDeath,
    isTaggedInOtherEvents,
    isTaggedInOtherLocations,
    id,
  } = rest
  const headerMap = {
    [INSTANCE_TYPE_INDIVIDUAL]: formatIndividualWithDates({
      givenName,
      surname,
      yearOfBirth,
      yearOfDeath,
    }),
    [INSTANCE_TYPE_FAMILY]: `${surname} Family`,
  }
  const iconMap = {
    [INSTANCE_TYPE_LOCATION]: '/icon-location.svg',
    [INSTANCE_TYPE_ARTEFACT]: '/icon-artefact.svg',
    [INSTANCE_TYPE_MEDIA]: '/icon-photo.svg',
    [INSTANCE_TYPE_EVENT]: '/icon-event.svg',
  }
  const header = headerMap[type] || title

  const thumbnailWidth = getThumbnailWidth()

  return (
    <Box
      sx={{
        mr: { xs: 0, md: 4 },
        mt: { xs: 2, md: 4 },
        maxWidth:
          thumbnailWidth === 'auto' ? thumbnailWidth : `${thumbnailWidth}px`,
      }}
    >
      <Thumbnail
        moreComponent={
          <MoreComponent
            type={type}
            isTaggedInOtherEvents={isTaggedInOtherEvents}
            isTaggedInOtherLocations={isTaggedInOtherLocations}
            photo={photo}
            id={id}
          />
        }
        file={photo?.fileMedium || photo?.fileThumbnail || defaultImage}
      />
      <SummaryContainer>
        <HeaderContainer>
          <Typography variant="h4">{header}</Typography>{' '}
          <Icon src={iconMap[type]} />
        </HeaderContainer>
      </SummaryContainer>
    </Box>
  )
}

export default SummaryCard
