import { ActionsContainer } from '../../page/LinkedPageList'
import {
  HomeViewConfigSelectorContainer,
  ViewConfigSelectorContainer,
} from '../../page/ViewConfigSelectorContainer'
import { Box } from '@mui/material'
import Refresh from '../../ui/Refresh'
import React from 'react'

const PublicActionBar = ({
  type,
  hierarchical,
  sort,
  ancestralOnly,
  handleSetViewConfig,
  refresh,
  isHome = false,
  children,
}) => {
  return (
    <ActionsContainer>
      {isHome ? (
        <HomeViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={handleSetViewConfig}
        />
      ) : (
        <ViewConfigSelectorContainer
          type={type}
          sortValue={sort}
          hierarchicalValue={hierarchical}
          ancestralOnlyValue={ancestralOnly}
          handleChange={handleSetViewConfig}
        />
      )}
      {children}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Refresh onClick={refresh} />
      </Box>
    </ActionsContainer>
  )
}

export default PublicActionBar
