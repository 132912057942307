/**
 * Operations that use a graphlib graph to calculate
 */
import * as dagre from 'dagre'
import { isUndefined } from 'lodash'
import { LEFT_SIDE } from './explore'

export function logGraph(visibleNodes) {
  console.log(JSON.stringify(visibleNodes))

  const g = buildGraphLibGraph(visibleNodes)

  console.log(JSON.stringify(dagre.graphlib.json.write(g)))
  console.log('edges', g.edgeCount())
  console.log('cycles', dagre.graphlib.alg.findCycles(g))
  console.log('isAcyclic', dagre.graphlib.alg.isAcyclic(g))

  return g
}

export function buildGraphLibGraph(
  visibleNodes,
  reciprocal = false,
  directed = true
) {
  const g = new dagre.graphlib.Graph({ directed })
  for (let node of visibleNodes) {
    g.setNode(node.id, {
      width: node.width,
      isUnion: node.isUnion,
      gender: node.gender,
      height: node.height,
      label: node.givenName || '',
    })
  }
  for (let individual of visibleNodes) {
    for (let unionID of individual.unions || []) {
      g.setEdge(individual.id, unionID)
      if (reciprocal) {
        g.setEdge(unionID, individual.id)
      }
    }
    for (let childID of individual.children) {
      g.setEdge(individual.id, childID)
      if (reciprocal) {
        g.setEdge(childID, individual.id)
      }
    }
  }
  return g
}

/**
 * Build the minimum subset of visibleNodes in a graph structure
 * that includes all of selected Individuals.
 *
 * @param {*} visibleNodes
 * @param {*} selectedIndividuals
 * @returns
 */
export function buildMinimumGraph(visibleNodes, selectedIndividualIds) {
  const g = buildGraphLibGraph(visibleNodes, true)

  const visibleNodeDirectory = createDirectoryById(visibleNodes)

  const subtreeMembersById = new Map()

  // Choose arbitrary source node
  const sourceId = Array.from(selectedIndividualIds)[0]

  const sourceIndividualNode = visibleNodeDirectory.get(sourceId)
  if (sourceIndividualNode === undefined) {
    console.error('selected individual was not visible')
    return []
  }

  // Start a map of individuals that comprise the minimum graph
  subtreeMembersById.set(sourceId, sourceIndividualNode)

  const paths = dagre.graphlib.alg.dijkstra(g, sourceId)

  // Find the shortest path from the source individual to each of
  // the other individuals, recording the nodes passed along the way
  for (let targetId of selectedIndividualIds) {
    let predecessorId = targetId
    if (paths[predecessorId].distance === Infinity) {
      continue
    }
    while (predecessorId !== sourceId) {
      subtreeMembersById.set(
        predecessorId,
        visibleNodeDirectory.get(predecessorId)
      )
      predecessorId = paths[predecessorId].predecessor
    }
  }

  // Add missing spouses
  for (let n of Array.from(subtreeMembersById.values())) {
    if (n.isUnion) {
      for (let spouseID of n.between) {
        if (
          !subtreeMembersById.has(spouseID) &&
          visibleNodeDirectory.has(spouseID)
        ) {
          subtreeMembersById.set(spouseID, visibleNodeDirectory.get(spouseID))
        }
      }
    }
  }
  return Array.from(subtreeMembersById.values())
}

/**
 * Remove the specified node and return the smaller of the two
 * halves of the graph separated by its removal.
 *
 * @param {*} visibleNodes
 * @param {*} nodeIdToRemove
 * @returns
 */
export function splitTreeAndReturnSmaller(visibleNodes, nodeIdsToRemove) {
  const g = buildGraphLibGraph(visibleNodes)

  for (let nodeId of nodeIdsToRemove) {
    g.removeNode(nodeId)
  }
  const subgraphs = dagre.graphlib.alg.components(g)

  if (subgraphs.length === 1) {
    // Removing this node leaves the graph intact. Remove only these ones.
    return visibleNodes.filter(n => nodeIdsToRemove.includes(n.id))
  }

  let biggestSubgraph
  for (let subgraph of subgraphs) {
    if (biggestSubgraph === undefined) {
      biggestSubgraph = subgraph
    } else if (biggestSubgraph.length < subgraph.length) {
      biggestSubgraph = subgraph
    }
  }
  biggestSubgraph = new Set(biggestSubgraph)
  return visibleNodes.filter(n => !biggestSubgraph.has(n.id))
}

export function graphNodesCentroid(graphNodes) {
  const length = graphNodes.length
  if (length === 0) {
    return undefined
  }
  const [minX, maxX] = minMaxX(graphNodes)
  const [minY, maxY] = minMaxY(graphNodes)

  return {
    x: minX + (maxX - minX) / 2,
    y: minY + (maxY - minY) / 2,
  }
}

export function minMaxX(graphNodes) {
  const xPositions = graphNodes.map(({ x }) => x)
  const maxX = Math.max(...xPositions)
  const minX = Math.min(...xPositions)
  return [minX, maxX]
}

export function minMaxY(graphNodes) {
  const yPositions = graphNodes.map(({ y }) => y)
  const maxY = Math.max(...yPositions)
  const minY = Math.min(...yPositions)
  return [minY, maxY]
}

export function treeHeight(graphNodes) {
  if (!graphNodes.length) {
    return 0
  }
  const [minY, maxY] = minMaxY(graphNodes)
  return maxY - minY + 1
}

export function findGraphNode(graphNodes, individualId) {
  return graphNodes.find(graphNode => graphNode.individualIDs.has(individualId))
}

export function shiftTreeUp(graphNodes, shiftBy = 1) {
  for (let graphNode of graphNodes) {
    graphNode.y -= shiftBy
  }
}

/**
 * Given a list of graph nodes, return a map of y coordinate to
 * all the x coordinates at that level.
 *
 * If we're looking at the right side of a tree, add half the width
 * of the node, so that the x value is the far right of the node.
 *
 * Do the opposite if it's the left side.
 */
function xPositions(graphNodes, rightSide = true) {
  const positions = new Map()
  for (let graphNode of graphNodes) {
    const x = rightSide ? graphNode.rightX : graphNode.leftX
    if (!positions.has(graphNode.y)) {
      positions.set(graphNode.y, [x])
    } else {
      positions.get(graphNode.y).push(x)
    }
  }
  return positions
}

/**
 * Move all the nodes in graphNodesToMove right of fixedGraphNodes.
 *
 * Try to perform the minimum number of rightward steps to accomplish
 * this.
 *
 * If `stayRightOfX` is defined, nodes will end up right of this x
 * coordinate regardless of node positions in `fixedGraphNodes`.
 */
export function shiftTreeRightOfOtherTree(
  graphNodesToMove,
  fixedGraphNodes,
  stayRightOfX = undefined,
  minimumMove = 0
) {
  const fixedCoordsByYPosition = xPositions(fixedGraphNodes, true)
  const movingCoordsByYPosition = xPositions(graphNodesToMove, false)

  let largestDifference = minimumMove
  for (let [y, xPositions] of fixedCoordsByYPosition.entries()) {
    const movingXpositions = movingCoordsByYPosition.get(y)
    // Check whether moving graph has any node at this y level
    if (isUndefined(movingXpositions)) {
      continue
    }

    let rightMostFixedX = Math.max(...xPositions)
    // if stayRightOfX is not defined, this will always be false
    if (rightMostFixedX < stayRightOfX) {
      rightMostFixedX = stayRightOfX
    }

    const leftMostMovingX = Math.min(...movingXpositions)
    const diff = rightMostFixedX - leftMostMovingX
    if (diff > largestDifference) {
      largestDifference = diff
    }
  }

  for (let graphNode of graphNodesToMove) {
    graphNode.x += largestDifference
  }
}

/**
 * Move parentRoot right or left so that it centres over its children
 *
 * This only makes sense to do if the parent node is the only node on
 * its row, as we make no attempt at collision detection.
 *
 * @param {*} motherRoot
 * @param {*} centralNodes
 */
export function centreParentNode(parentRoot, limitX, side) {
  let diff
  if (side === LEFT_SIDE) {
    diff = limitX - parentRoot.rightX
    parentRoot.x += diff
  } else {
    diff = parentRoot.leftX - limitX
    parentRoot.x -= diff
  }
}

export function createLayersMap(nodes) {
  const layers = new Map()
  for (let graphNode of nodes) {
    if (!layers.has(graphNode.y)) {
      layers.set(graphNode.y, [])
    }
    layers.get(graphNode.y).push(graphNode)
  }
  const maxLayer = Math.max(...layers.keys())
  const minLayer = Math.min(...layers.keys())
  return [layers, minLayer, maxLayer]
}

export function bottomNLayers(graphNodes, nLayers) {
  const [, maxLayer] = minMaxY(graphNodes)
  const minLayerToReturn = maxLayer - nLayers
  return graphNodes.filter(n => n.y > minLayerToReturn)
}

export function topNLayers(graphNodes, nLayers) {
  const [minLayer] = minMaxY(graphNodes)
  const maxLayerToReturn = minLayer + nLayers
  return graphNodes.filter(n => n.y < maxLayerToReturn)
}

export function makeXCoordinates0based(graphNodes) {
  const [minX] = minMaxX(graphNodes)
  for (let graphNode of graphNodes) {
    graphNode.x -= minX
  }
}

export function intersection(iterable1, iterable2) {
  const set1 = new Set([...iterable1])
  return new Set([...iterable2].filter(x => set1.has(x)))
}

export function createDirectoryById(graphNodes) {
  const directory = new Map()
  graphNodes.forEach(i => {
    if (i) {
      directory.set(i.id, i)
    } else {
      console.error(
        `createDirectoryById():  graphNodes contains undefined/null item:`,
        i
      )
    }
  })
  return directory
}

function recursiveDetectNodesWithMultiplePathsFromRoot(
  graphNode,
  graphNodeDirectory,
  seenIndividualIds,
  individualIdsWithMultiplePathsFromRoot
) {
  for (let individualId of graphNode.individualIDs) {
    if (seenIndividualIds.has(individualId)) {
      individualIdsWithMultiplePathsFromRoot.add(individualId)
    }
    seenIndividualIds.add(individualId)
  }

  // Depth first recursion - for each child look at all of their children
  // before proceeding to the next
  for (let childId of graphNode.children) {
    const childGraphNode = graphNodeDirectory.get(childId)
    recursiveDetectNodesWithMultiplePathsFromRoot(
      childGraphNode,
      graphNodeDirectory,
      seenIndividualIds,
      individualIdsWithMultiplePathsFromRoot
    )
  }
}

export function detectNodesWithMultiplePathsFromRoot(
  rootGraphNode,
  graphNodes
) {
  const seenIndividualIds = new Set()
  const individualIdsWithMultiplePathsFromRoot = new Set()
  const graphNodeDirectory = createDirectoryById(graphNodes)
  recursiveDetectNodesWithMultiplePathsFromRoot(
    rootGraphNode,
    graphNodeDirectory,
    seenIndividualIds,
    individualIdsWithMultiplePathsFromRoot
  )
  return Array.from(individualIdsWithMultiplePathsFromRoot)
}
