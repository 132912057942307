import ContentPhotos from 'src/modules/content/ContentPhotos'

import { INSTANCE_TYPE_DOCUMENT } from '../app/links'

export const GalleryBlock = ({
  mediaDetailEditDialogTitle,
  mediaDetailShowTranscription,
  mediaDetailOfferEnterTranscription,
  mediaDetailShowTakenOnDate,
  mediaDetailHideTags,
  mediaDetailDefaultAllowNoTags,
  contentType,
  ...block
}) => {
  //default props here if we are in a document
  return (
    <ContentPhotos
      media={block.media}
      mediaDetailEditDialogTitle={
        mediaDetailEditDialogTitle ?? contentType === INSTANCE_TYPE_DOCUMENT
          ? 'Edit page'
          : null
      }
      mediaDetailShowTranscription={
        mediaDetailShowTranscription === undefined
          ? contentType === INSTANCE_TYPE_DOCUMENT
          : mediaDetailShowTranscription
      }
      mediaDetailOfferEnterTranscription={
        mediaDetailOfferEnterTranscription === undefined
          ? contentType === INSTANCE_TYPE_DOCUMENT
          : mediaDetailOfferEnterTranscription
      }
      mediaDetailShowTakenOnDate={
        mediaDetailShowTakenOnDate === undefined
          ? contentType !== INSTANCE_TYPE_DOCUMENT
          : mediaDetailShowTakenOnDate
      }
      mediaDetailHideTags={
        mediaDetailHideTags === undefined ? false : mediaDetailHideTags
      }
      mediaDetailDefaultAllowNoTags={
        mediaDetailDefaultAllowNoTags === undefined
          ? contentType === INSTANCE_TYPE_DOCUMENT
          : mediaDetailDefaultAllowNoTags
      }
    />
  )
}

export default GalleryBlock
