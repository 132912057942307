import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  ConfirmDialog,
  Container,
  Button,
  IconButton,
  Typography,
  LoadingIndicator,
} from 'src/modules/ui'
import { useActionDispatcher } from 'src/modules/app'
import { useNotification } from 'src/modules/app/hooks'
import { fetchUser, selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'

import {
  cancelResolution,
  fetchDraftTree,
  fetchDraftTreeIndividuals,
  fetchDraftTreeRequiredResolutions,
  resolveTree,
  selectDraftTree,
  selectDraftTreeRequiredResolutions,
} from './treesSlice'
import { Resolution } from './Resolution'
import { Box } from '@mui/material'
import { generateTreeHomeLink, INSTANCE_TYPE_TREE } from '../app/links'
import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    marginBottom: theme.spacing(4),
  },
  controlLine: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  conflictsRemaining: {},
  conflictsRemainingZero: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    borderRadius: '5px',
    color: 'white',
    fontWeight: 600,
    background: 'green',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  checkicon: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5), //bump it up a bit so it aligns with the text better
    stroke: 'white',
    strokeWidth: 0.8,
  },
  prevButton: {
    marginRight: theme.spacing(1),
  },
  nextButton: {
    marginLeft: theme.spacing(1),
  },
  finishButton: {
    marginRight: theme.spacing(1),
  },
  treeActions: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: 24,
  },
  resolutionCentre: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resolutionsComplete: {
    textAlign: 'center',
  },
}))

export const ResolveTree = () => {
  const classes = useStyles()
  const history = useHistory()

  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const { showSuccess } = useNotification()
  const { draftTreeSlug } = useParams()

  const dispatchFetchDraftTree = useActionDispatcher(fetchDraftTree)
  const dispatchFetchDraftTreeRequiredResolutions = useActionDispatcher(
    fetchDraftTreeRequiredResolutions
  )
  const dispatchFetchDraftTreeIndividuals = useActionDispatcher(
    fetchDraftTreeIndividuals
  )
  const dispatchFetchUser = useActionDispatcher(fetchUser)
  const dispatchResolveTree = useActionDispatcher(resolveTree)
  const dispatchCancelResolution = useActionDispatcher(cancelResolution)

  const draftTree = useSelector(selectDraftTree)

  const draftTreeRequiredResolutions = useSelector(
    selectDraftTreeRequiredResolutions
  )

  const [resolutionIndex, setResolutionIndex] = useState(0)

  useEffect(() => {
    dispatchFetchDraftTree(draftTreeSlug)
    dispatchFetchDraftTreeRequiredResolutions(draftTreeSlug)
    dispatchFetchDraftTreeIndividuals(draftTreeSlug)
  }, [
    dispatchFetchDraftTree,
    dispatchFetchDraftTreeIndividuals,
    dispatchFetchDraftTreeRequiredResolutions,
    draftTreeSlug,
  ])

  const errorMsg = err => {
    if (err.data?.tree === 'could_not_resolve_relationships') {
      return 'Could not import because not all the conflicts were solved'
    }
    return 'Oops... there was a problem doing that'
  }

  const handleCancelResolution = async () => {
    await dispatchCancelResolution(draftTreeSlug)
    // User's `trees` list will have changed
    await dispatchFetchUser()
    history.push(generateTreeHomeLink(treeSlug))
  }

  const handleResolveTree = async () => {
    try {
      await dispatchResolveTree(draftTreeSlug, {
        errorNotification: errorMsg,
      }).unwrap()
    } catch {
      return
    }
    // User's `trees` list will have changed
    await dispatchFetchUser()
    history.push(generateTreeHomeLink(treeSlug))
  }

  const [multipleResolvedModalOpen, setMultipleResolvedModalOpen] =
    useState(false)
  const [updatedRelativesCount, setUpdatedRelativesCount] = useState(0)

  const handleConflictResolved = async (
    resolutionType,
    updatedRelativesCount
  ) => {
    showSuccess('Conflict resolved')

    if (updatedRelativesCount && updatedRelativesCount > 0) {
      setUpdatedRelativesCount(updatedRelativesCount)
      setMultipleResolvedModalOpen(true)
      dispatchFetchDraftTreeRequiredResolutions.status = null
      const res = await dispatchFetchDraftTreeRequiredResolutions(draftTreeSlug)
      const resolutions = res.payload
      const firstUnresolvedIdx = resolutions.findIndex((res, i) => {
        return (
          res.state !== 'RESOLVED_EXISTING' &&
          res.state !== 'RESOLVED_NEW' &&
          res.state !== 'RESOLVED_IGNORED'
        )
      })

      if (firstUnresolvedIdx > -1) {
        setResolutionIndex(firstUnresolvedIdx)
      } else {
        setResolutionIndex(resolutions.length - 1)
      }
    } else {
      if (resolutionIndex < draftTreeRequiredResolutions.length - 1) {
        setResolutionIndex(resolutionIndex + 1)
      }
    }
  }

  if (
    !draftTree ||
    dispatchFetchDraftTreeRequiredResolutions.status !== 'fulfilled' ||
    dispatchFetchDraftTreeIndividuals.status !== 'fulfilled'
  ) {
    return <LoadingIndicator />
  }

  const resolution = draftTreeRequiredResolutions[resolutionIndex]
  const nResolutionsRequired = draftTreeRequiredResolutions.filter(
    r => r.state === 'PROPOSED'
  ).length

  return (
    <Container className={classes.root}>
      <Typography variant="h2" textAlign="center">
        Resolve the conflicts on {draftTree.name}
      </Typography>
      {draftTreeRequiredResolutions.length === 0 && (
        <div className={classes.resolutionsComplete}>
          <Typography mt={2}>
            Nothing to resolve - everything matched!
          </Typography>
        </div>
      )}
      <Box className={classes.treeActions}>
        <ConfirmDialog
          onConfirm={handleResolveTree}
          trigger={props => (
            <Button
              permissionAction={ACTION_EDIT}
              permissionParams={{
                instance: draftTree,
                instanceType: INSTANCE_TYPE_TREE,
              }}
              className={classes.finishButton}
              {...props}
            >
              Finish resolution
            </Button>
          )}
        >
          <Typography>
            Are you sure you want to finish the resolution process?
          </Typography>
          {nResolutionsRequired !== 0 && (
            <Typography>
              You have conflicts have not been explicitly resolved. These will
              be ignored.
            </Typography>
          )}
        </ConfirmDialog>
        <ConfirmDialog
          onConfirm={handleCancelResolution}
          cancelText="Continue resolving"
          submitText="Cancel resolving"
          trigger={props => (
            <Button permissionAction={ACTION_ALL_ACCESS} {...props}>
              Cancel import
            </Button>
          )}
        >
          <Typography>
            Are you sure you want to cancel this update process?
          </Typography>
        </ConfirmDialog>
        <Dialog
          open={multipleResolvedModalOpen}
          onClose={() => {
            setMultipleResolvedModalOpen(false)
          }}
        >
          <DialogTitle>Conflicts Refreshed</DialogTitle>
          <DialogContent>
            <Typography>
              Also resolved another{' '}
              {updatedRelativesCount === 1
                ? 'relative'
                : updatedRelativesCount + ' relatives'}
              ; refreshed and re-ordered conflicts.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              permissionAction={ACTION_EDIT}
              permissionParams={{
                instance: draftTree,
                instanceType: INSTANCE_TYPE_TREE,
              }}
              onClick={() => setMultipleResolvedModalOpen(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {resolution && (
        <div className={classes.resolutionCentre}>
          <div className={classes.controlLine}>
            <div>&nbsp;</div>
            <div className={classes.controls}>
              <IconButton
                permissionAction={ACTION_EDIT}
                permissionParams={{
                  instance: draftTree,
                  instanceType: INSTANCE_TYPE_TREE,
                }}
                onClick={() => setResolutionIndex(resolutionIndex - 1)}
                disabled={resolutionIndex === 0}
                className={classes.prevButton}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography variant="h3">
                Conflict {resolutionIndex + 1} of{' '}
                {draftTreeRequiredResolutions.length}
              </Typography>
              <IconButton
                permissionAction={ACTION_EDIT}
                permissionParams={{
                  instance: draftTree,
                  instanceType: INSTANCE_TYPE_TREE,
                }}
                onClick={() => setResolutionIndex(resolutionIndex + 1)}
                disabled={
                  resolutionIndex >= draftTreeRequiredResolutions.length - 1
                }
                className={classes.nextButton}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
            {nResolutionsRequired === 0 ? (
              <Typography
                className={classes.conflictsRemainingZero}
                variant="h4"
              >
                All conflicts resolved
                <CheckRoundedIcon className={classes.checkicon} />
              </Typography>
            ) : (
              <Typography className={classes.conflictsRemaining} variant="h4">
                {nResolutionsRequired} conflict
                {nResolutionsRequired === 1 ? '' : 's'} remaining
              </Typography>
            )}
          </div>
          <Resolution
            resolution={resolution}
            onResolved={handleConflictResolved}
          />
        </div>
      )}
    </Container>
  )
}
