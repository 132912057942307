import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Popover, Stack } from '@mui/material'
import { Box } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  Button,
  IconButton,
  LoadingIndicator,
  Typography,
} from 'src/modules/ui'

import { selectGoals, fetchGoals, setReported } from './goalsSlice'
import { TRACKING_PERIOD_INITIAL, TRACKING_PERIOD_MONTHLY } from './targets'
import Goal from './Goal'
import { overallProgressFromGoals } from './utils'
import GoalEditor from './GoalEditor'
import {
  ASSISTANT_HEIGHT_PX,
  ASSISTANT_WIDTH_PX,
  N_GOALS_WRAP,
} from './constants'
import LastMonthReport from './LastMonthReport'
import Progress from './Progress'
import MessageBox from './MessageBox'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const ASSISTANT_STATE_EDITING = 'EDITING'
const ASSISTANT_STATE_VIEWING = 'VIEWING'

const Assistant = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState(ASSISTANT_STATE_VIEWING)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  // Fetch goals on initial load
  const dispatchFetchGoals = useActionDispatcher(fetchGoals)
  useEffect(() => {
    dispatchFetchGoals()
  }, [dispatchFetchGoals, treeSlug])

  const goals = useSelector(selectGoals)
  // Only show goals with targets set
  const [monthlyGoals, activeGoals] = useMemo(() => {
    const mg = goals.filter(
      goal => goal.trackingPeriod === TRACKING_PERIOD_MONTHLY
    )
    const ag = goals.filter(goal => goal.target > 0)
    return [mg, ag]
  }, [goals])

  // Goals can either be initial suggestions, or user-defined.
  const isInitialSuggestions = goals.some(
    goal => goal.trackingPeriod === TRACKING_PERIOD_INITIAL
  )
  const showInitialMessage =
    isInitialSuggestions && goals.some(goal => !goal.progress.reportedToUser)

  const overallProgress = overallProgressFromGoals(activeGoals)
  const showSetGoalsEncouragement =
    isInitialSuggestions && overallProgress >= 100

  useEffect(() => {
    if (showSetGoalsEncouragement) {
      setState(ASSISTANT_STATE_EDITING)
    }
  }, [showSetGoalsEncouragement])

  // Show/hide popover
  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setState(ASSISTANT_STATE_VIEWING)
  }, [])

  // Hide popover on navigation
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      handleClose()
    })
    return () => unlisten()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  // Change assistant state
  const handleEdit = useCallback(() => setState(ASSISTANT_STATE_EDITING), [])
  const handleView = useCallback(() => setState(ASSISTANT_STATE_VIEWING), [])

  // Dismiss initial message
  const dispatchSetReported = useActionDispatcher(setReported)
  const handleSetReported = useCallback(
    () => dispatchSetReported(),
    [dispatchSetReported]
  )

  if (dispatchFetchGoals.status === 'loading') {
    return <LoadingIndicator />
  }
  const itemWidth = ASSISTANT_WIDTH_PX / N_GOALS_WRAP

  const now = new Date()
  const monthName = now.toLocaleString(navigator.language || 'utc', {
    month: 'long',
  })

  return (
    <>
      <Box>
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          onClick={handleClick}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            background: 'white',
          }}
        >
          <img alt="Gene" src="/gene.png" width={40} height={24} />
          <Progress value={overallProgress} indicatorSize={22} thickness={8} />
          <Box sx={{ width: 10 }}></Box>
        </Button>
      </Box>
      <LastMonthReport />
      <Popover
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        sx={{ mt: 1 }}
      >
        <Stack
          direction="column"
          sx={{
            width: ASSISTANT_WIDTH_PX,
            m: 2,
            minHeight: ASSISTANT_HEIGHT_PX,
          }}
        >
          {state === ASSISTANT_STATE_EDITING && (
            <GoalEditor
              showSetGoalsEncouragement={showSetGoalsEncouragement}
              existingGoals={monthlyGoals}
              onFinishEditing={handleView}
            ></GoalEditor>
          )}
          {state === ASSISTANT_STATE_VIEWING && (
            <>
              <Stack direction="row" sx={{ alignContent: 'center' }}>
                <Box sx={{ display: 'inline-block', mr: 1 }}>
                  <img alt="Gene" src="/gene.png" width={40} height={24} />
                </Box>
                <Typography variant="h4">Assistant "Gene"</Typography>
              </Stack>
              {!isInitialSuggestions && (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                  onClick={handleEdit}
                  noBackground={true}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
              {showInitialMessage && (
                <MessageBox>
                  <Typography sx={{ mb: 2 }}>
                    Hi! I'm your assistant "Gene Poole".
                  </Typography>

                  <Typography>
                    Getting your family history written down is one of those
                    elephant eating situations. I'm Gene, here to offer
                    encouragement with a few suggestions to get you warmed up
                    and enjoying yourself. Good luck and best wishes, Gene.
                  </Typography>
                  <Stack direction="row-reverse" sx={{ width: '100%' }}>
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      sx={{ mt: 1 }}
                      onClick={handleSetReported}
                    >
                      OK
                    </Button>
                  </Stack>
                </MessageBox>
              )}
              {showSetGoalsEncouragement && (
                <MessageBox>
                  <Typography>
                    Fantastic effort, your site is really coming to life! I'd
                    like to help you eat that family history elephant one bite
                    at a time. Set yourself some simple monthly goals and I'll
                    send you some friendly encouragement once in a while. Before
                    you know it your site will be bulging with content to share
                    with your family. Good luck and best wishes, Gene
                  </Typography>
                </MessageBox>
              )}
              <Box sx={{ flexGrow: 1, mt: 2 }}>
                <Typography sx={{ mb: 1 }}>
                  {isInitialSuggestions && 'Suggestions'}
                  {!isInitialSuggestions && `${monthName} goals`}
                </Typography>
                {activeGoals.length === 0 && (
                  <>
                    <Typography sx={{ mb: 2 }}>
                      You have not defined any monthly goals.
                    </Typography>
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={handleEdit}
                    >
                      Add monthly goals
                    </Button>
                  </>
                )}
                <Stack
                  direction="row"
                  sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
                >
                  {activeGoals.map(goal => (
                    <Goal
                      goal={goal}
                      key={goal.id}
                      width={itemWidth}
                      indicatorSize={60}
                    ></Goal>
                  ))}
                </Stack>
              </Box>
              {isInitialSuggestions && (
                <>
                  <Typography sx={{ mb: 1 }}>Done with suggestions?</Typography>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={handleEdit}
                  >
                    Add monthly goals
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </Popover>
    </>
  )
}

export default Assistant
