import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Dialog, DialogContent } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import { makeStyles } from '@mui/styles'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { useActionDispatcher } from 'src/modules/app'
import {
  Button,
  DialogTitle,
  IconButton,
  LoadingIndicator,
  Tooltip,
  Typography,
} from 'src/modules/ui'

import {
  createCroppedPhoto,
  cropExistingPhoto,
  revertToOriginal,
  rotatePhotoLeft,
  rotatePhotoRight,
} from './photoSlice'
import { isUndefined } from 'lodash'
import {
  ACTION_ALL_ACCESS,
  ACTION_CREATE,
  ACTION_EDIT,
} from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    display: 'flex',
    paddingTop: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  cropper: {
    position: 'relative',
  },
  reactCrop: {
    // Added to prevent image from being larger than page. ReactCrop uses the natural width and height of image which is normally too big and causes dialog to be taller than the page
    '& img': {
      maxHeight: '60vh',
      width: 'auto',
    },
  },
  cropControls: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hiddenPreview: {
    display: 'none',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const CropExistingMediaDialog = ({
  onCroppingCancelled,
  onDoneCropping,
  onDidNotCrop,
  photo,
}) => {
  const dispatchCropExistingPhoto = useActionDispatcher(cropExistingPhoto)
  const dispatchRotatePhotoLeft = useActionDispatcher(rotatePhotoLeft)
  const dispatchRotatePhotoRight = useActionDispatcher(rotatePhotoRight)
  const dispatchRevertToOriginal = useActionDispatcher(revertToOriginal)
  const isAlteringImage =
    dispatchRotatePhotoLeft.status === 'loading' ||
    dispatchRotatePhotoRight.status === 'loading' ||
    dispatchRevertToOriginal.status === 'loading'

  const handleRotateLeft = useCallback(async () => {
    await dispatchRotatePhotoLeft({ photoId: photo.id }).unwrap()
  }, [dispatchRotatePhotoLeft, photo])

  const handleRotateRight = useCallback(async () => {
    await dispatchRotatePhotoRight({ photoId: photo.id }).unwrap()
  }, [dispatchRotatePhotoRight, photo])

  const handleDoneCropping = useCallback(
    async (...croppedPhotoAttrs) => {
      const croppedPhoto = await dispatchCropExistingPhoto(
        ...croppedPhotoAttrs
      ).unwrap()
      if (onDoneCropping) {
        onDoneCropping(croppedPhoto)
      }
    },
    [dispatchCropExistingPhoto, onDoneCropping]
  )

  const handleRevertToOriginal = useCallback(async () => {
    await dispatchRevertToOriginal({ photoId: photo.id }).unwrap()
  }, [dispatchRevertToOriginal, photo])
  const canRevert = photo?.originalIsAltered

  return (
    <CropPhotoDialog
      isCropping={
        isAlteringImage || dispatchCropExistingPhoto.status === 'loading'
      }
      onCroppingCancelled={onCroppingCancelled}
      onDoneCropping={handleDoneCropping}
      onDidNotCrop={onDidNotCrop}
      photo={photo}
      title="Crop and rotate image"
    >
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleRotateLeft}
        disabled={isAlteringImage}
      >
        <RotateLeftIcon />
      </IconButton>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleRotateRight}
        disabled={isAlteringImage}
      >
        <RotateRightIcon />
      </IconButton>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        onClick={handleRevertToOriginal}
        disabled={!canRevert || isAlteringImage}
      >
        <Tooltip title="Restore Original">
          <HistoryIcon />
        </Tooltip>
      </IconButton>
    </CropPhotoDialog>
  )
}

const CreateCroppedPhotoDialog = ({
  aspectRatio,
  onCroppingCancelled,
  onDoneCropping,
  onDidNotCrop,
  photo,
  submitText,
}) => {
  const dispatchCreateCroppedPhoto = useActionDispatcher(createCroppedPhoto)

  const handleDoneCropping = useCallback(
    async (...croppedPhotoAttrs) => {
      const croppedPhoto = await dispatchCreateCroppedPhoto(
        ...croppedPhotoAttrs
      ).unwrap()
      if (onDoneCropping) {
        onDoneCropping(croppedPhoto)
      }
    },
    [dispatchCreateCroppedPhoto, onDoneCropping]
  )

  return (
    <CropPhotoDialog
      aspectRatio={aspectRatio}
      isCropping={dispatchCreateCroppedPhoto.status === 'loading'}
      onCroppingCancelled={onCroppingCancelled}
      onDoneCropping={handleDoneCropping}
      onDidNotCrop={onDidNotCrop}
      photo={photo}
      submitText={submitText}
    />
  )
}

const CropPhotoDialog = ({
  aspectRatio,
  isCropping,
  onCroppingCancelled,
  onDoneCropping,
  onDidNotCrop,
  photo,
  children,
  submitText = 'Crop Image',
  title = 'Crop image',
}) => {
  const classes = useStyles()
  return (
    <>
      <Dialog open={!!photo} onClose={onCroppingCancelled}>
        <DialogTitle className={classes.title} onClose={onCroppingCancelled}>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.bodyContainer}>
          {photo && (
            <ImageCropper
              isCropping={isCropping}
              aspectRatio={aspectRatio}
              photo={photo}
              onDoneCropping={onDoneCropping}
              onDidNotCrop={onDidNotCrop}
              submitText={submitText}
            >
              {children}
            </ImageCropper>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

const ImageCropper = ({
  aspectRatio,
  isCropping,
  onDidNotCrop,
  onDoneCropping,
  photo,
  children,
  submitText,
}) => {
  const classes = useStyles()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  let initialCrop
  if (photo.isCropped) {
    initialCrop = {
      unit: 'px',
      height: photo.height,
      width: photo.width,
      x: photo.x,
      y: photo.y,
    }
  } else if (!isUndefined(aspectRatio)) {
    initialCrop = {
      unit: '%',
      height: 100,
      aspect: aspectRatio,
    }
  } else {
    initialCrop = {
      unit: '%',
      height: 100,
      width: 100,
    }
  }
  const [crop, setCrop] = useState(initialCrop)
  const [completedCrop, setCompletedCrop] = useState(null)

  const handleDoneCropping = canvas => {
    canvas.toBlob(
      async blob => {
        const originalId = photo.isCropped ? photo.original : photo.id
        onDoneCropping({
          fileName: photo.fileName,
          originalId: originalId,
          file: blob,
          x: Math.floor(crop.x),
          y: Math.floor(crop.y),
          width: Math.floor(crop.width),
          height: Math.floor(crop.height),
        })
      },
      'image/jpeg',
      0.5
    )
  }

  // If photo changes, make sure crop is still valid. Because the image has
  // usually been scaled, it's very hard to keep the existing crop on the
  // new image, but we can reset to something valid.
  useEffect(() => {
    // Skip for initial crop
    if (crop.unit === '%') {
      return
    }
    setCrop({
      unit: '%',
      height: 100,
      width: 100,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo])

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    // This crops the source image to another canvas, which can be exported.
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
  }, [completedCrop])

  return (
    <div className="App">
      <div className={classes.cropper}>
        {isCropping && (
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              background: 'white',
              opacity: 0.5,
              zIndex: 100,
            }}
          >
            <LoadingIndicator />
          </Box>
        )}
        <ReactCrop
          className={classes.reactCrop}
          src={photo.isCropped ? photo.originalFileMedium : photo.fileMedium}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          crossorigin="anonymous"
          keepSelection
        />
      </div>
      <div className={classes.hiddenPreview}>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
      <div className={classes.cropControls}>
        {
          // Parent can supply extra controls
          children
        }
        <Button
          permissionAction={ACTION_CREATE}
          permissionParams={{
            instanceType: INSTANCE_TYPE_MEDIA,
          }}
          className={classes.button}
          disabled={
            isCropping || !completedCrop?.width || !completedCrop?.height
          }
          onClick={() =>
            handleDoneCropping(previewCanvasRef.current, completedCrop)
          }
        >
          {submitText}
        </Button>
        {isUndefined(aspectRatio) && !isUndefined(onDidNotCrop) && (
          <Button
            permissionAction={ACTION_EDIT}
            permissionParams={{
              instanceType: INSTANCE_TYPE_MEDIA,
              instance: photo,
            }}
            disabled={isCropping}
            onClick={() => onDidNotCrop(photo)}
          >
            Use original
          </Button>
        )}
      </div>
    </div>
  )
}
export default CreateCroppedPhotoDialog
