import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState, useEffect, useCallback } from 'react'
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined'
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong'
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined'
import EditIcon from '@mui/icons-material/Edit'
import { Popper } from '@mui/material'

import { Button } from 'src/modules/ui'

import {
  removeIdFromSelectedIndividualIds,
  setExploredIndividualId,
  addIdToSelectedIndividualIds,
  setExploreViewMode,
  clearClickedIndividualId,
} from 'src/modules/viewer/exploreTreeSlice'

import './ContextMenu.css'
import { useHistory } from 'react-router'
import { selectLoggedInUserIsAdminOnCurrentTree } from 'src/modules/auth/authSlice'
import { UpdateIndividualRelationshipsDialog } from '../UpdateIndividualRelationships'
import { useLinkToInstance } from '../hooks'
import { INSTANCE_TYPE_INDIVIDUAL } from 'src/modules/app/links'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

export function IndividualContextMenu({
  individualNode,
  top,
  left,
  isSelected,
  selectMenuConfig = {},
  onNavigate,
  onExploreOveride,
  onCloseParentDialog,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const anchorRef = useRef(null)
  const isAdmin = useSelector(selectLoggedInUserIsAdminOnCurrentTree)

  const isUnknown = individualNode.id.includes('unknown')
  const individualUrl = useLinkToInstance(
    INSTANCE_TYPE_INDIVIDUAL,
    individualNode
  )

  const explore = useCallback(() => {
    dispatch(setExploredIndividualId(individualNode.id))
    dispatch(clearClickedIndividualId())
  }, [dispatch, individualNode.id])

  const navigate = useCallback(() => {
    dispatch(clearClickedIndividualId())
    dispatch(setExploreViewMode(undefined))
    history.push(individualUrl)
    if (onNavigate) {
      onNavigate()
    }
  }, [dispatch, history, individualUrl, onNavigate])

  const select = useCallback(() => {
    dispatch(addIdToSelectedIndividualIds(individualNode.id))
    dispatch(clearClickedIndividualId())
  }, [dispatch, individualNode.id])

  const deselect = useCallback(() => {
    dispatch(removeIdFromSelectedIndividualIds(individualNode.id))
    dispatch(clearClickedIndividualId())
  }, [dispatch, individualNode.id])

  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(true)
  }, [])

  const handleCloseParentDialog = () => {
    onCloseParentDialog()
    if (onExploreOveride) {
      onExploreOveride()
    } else {
      explore()
    }
  }

  return (
    <>
      {open && (
        <Popper
          anchorEl={anchorRef.current}
          open={true}
          placement="right"
          disablePortal={true}
        >
          <div className="node-context-menu">
            {isAdmin && selectMenuConfig.edit && (
              <div className="action">
                <UpdateIndividualRelationshipsDialog
                  onCloseParentDialog={handleCloseParentDialog}
                  individual={individualNode}
                  trigger={({ onClick, ...props }) => (
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      variant="outlined"
                      color="primary"
                      onClick={onClick}
                      fullWidth
                      startIcon={<EditIcon />}
                      {...props}
                    >
                      Edit
                    </Button>
                  )}
                />
              </div>
            )}

            {selectMenuConfig.visit && individualUrl && (
              <div className="action">
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={navigate}
                  startIcon={<SelectAllOutlinedIcon fontSize="small" />}
                >
                  Visit page
                </Button>
              </div>
            )}

            {selectMenuConfig.explore && (
              <div className="action">
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={onExploreOveride ? onExploreOveride : explore}
                  startIcon={<CenterFocusStrongIcon fontSize="small" />}
                >
                  Explore
                </Button>
              </div>
            )}

            {selectMenuConfig.select && !isUnknown && (
              <>
                {!isSelected && (
                  <div className="action">
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={select}
                      startIcon={<SelectAllOutlinedIcon fontSize="small" />}
                    >
                      Select
                    </Button>
                  </div>
                )}

                {isSelected && (
                  <div className="action">
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={deselect}
                      startIcon={<BlockOutlinedIcon fontSize="small" />}
                    >
                      Deselect
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Popper>
      )}
      <div ref={anchorRef} style={{ position: 'absolute', top, left }} />
    </>
  )
}
