import { useActionDispatcher } from '../app'
import { fetchMediaDetail } from '../photo/photoSlice'
import { Stack, Typography } from '@mui/material'
import AddMediaDialog from '../photo/AddMediaDialog'
import { Button } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { THUMB_SIZE_SMALL } from '../photo/Thumbnail'
import FactSourceImage from '../photo/FactSourceImage'
import React from 'react'

const AddSourceCitationMediaDialogForm = ({
  values,
  linkedPageId,
  setFieldValue,
  previewTiles,
  setPreviewTiles,
  disabled,
}) => {
  const dispatchFetchMediaDetail = useActionDispatcher(fetchMediaDetail)
  const finishUploadingMedia = async (media, setFieldValue) => {
    const mediaDetailPromises = media.map(async mediaInstance => {
      if (typeof mediaInstance === 'string') {
        const mediaDetail = await dispatchFetchMediaDetail({
          mediaId: mediaInstance,
        })
        return mediaDetail.payload
      } else {
        return mediaInstance
      }
    })

    const mediaDetailList = await Promise.all(mediaDetailPromises)

    return mediaDetailList
  }

  return (
    <>
      {!disabled && (
        <>
          <Typography my={1}>Link a document or media:</Typography>
          <>
            <AddMediaDialog
              album={true}
              showYouTube={false}
              targets={[linkedPageId]}
              shouldTagImages={true}
              onFinishedUploading={async media => {
                const uploadedMedia = await finishUploadingMedia(media)

                const newMedia = uploadedMedia.filter(uploadedMediaItem => {
                  // Check if an object with the same ID exists in values.citationMedia
                  return !values.citationMedia.some(
                    mediaItem => mediaItem.id === uploadedMediaItem.id
                  )
                })

                setFieldValue('citationMedia', [
                  ...values.citationMedia,
                  ...newMedia,
                ])
              }}
              previewTiles={previewTiles}
              setPreviewTiles={tiles => {
                setPreviewTiles(tiles)
              }}
              trigger={props => (
                <Button
                  disabled={disabled}
                  permissionAction={ACTION_ALL_ACCESS}
                  {...props}
                >
                  Choose or upload photo
                </Button>
              )}
            />
          </>
        </>
      )}
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="flex-start"
        justifySelf="flex-start"
        maxWidth={THUMB_SIZE_SMALL * 6}
        spacing={1}
        mt={1}
      >
        {values?.citationMedia?.length > 0 &&
          values?.citationMedia?.map((photo, index) => {
            return (
              <FactSourceImage
                key={photo?.id}
                photo={photo}
                isEditing={true}
                onRemoveImage={mediaId => {
                  let newValues = [...values?.citationMedia]
                  newValues.splice(index, 1)
                  setFieldValue('citationMedia', newValues)
                }}
              />
            )
          })}
      </Stack>
    </>
  )
}

export default AddSourceCitationMediaDialogForm
