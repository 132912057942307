import React, { useState } from 'react'
import { Box, Dialog, DialogContent } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectNodeDirectory } from './viewerSlice'
import { DialogTitle } from '../ui'
import SelectFamilyOrIndividualPartial from './SelectFamilyOrIndividualPartial'

const SelectFamiliyOrIndidvidualDialog = ({
  trigger,
  showSelectFamily = false,
  onSelectIndividual = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const nodeDirectory = useSelector(selectNodeDirectory)

  const handleShowModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      {trigger && trigger({ onClick: handleShowModal })}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle onClose={handleCloseModal}>Select Individual</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 4 }}>
            <SelectFamilyOrIndividualPartial
              onSelectIndividual={onSelectIndividual}
              showSelectFamily={showSelectFamily}
              maintainExploredIndividualState={false}
              nodeDirectory={nodeDirectory}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SelectFamiliyOrIndidvidualDialog
