import React from 'react'
import { useMediaQuery, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import AncestralFamiliesContainer from 'src/modules/home/AncestralFamiliesContainer'
import { Button, SimpleDialog, IconButton } from 'src/modules/ui'
import SearchTreeControl from './SearchTreeControl'
import NavigateToControl from './NavigateToControl'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

export const useStyles = makeStyles(theme => ({
  mobileButton: {
    paddingLeft: `${theme.spacing(1)} !important`,
    paddingRight: `${theme.spacing(1)} !important`,
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down(370)]: {
      fontSize: '0.725rem',
    },
  },
}))

export const SearchButton = props => {
  const classes = useStyles()
  return (
    <Button
      permissionAction={ACTION_ALL_ACCESS}
      className={classes.mobileButton}
      variant="outlined"
      size="small"
      {...props}
    >
      <SearchIcon fontSize="small" />
    </Button>
  )
}

const MobileTreeViewerMenu = ({
  handleDeselection,
  handleFamilySelected,
  handleIndividualSelected,
  handleCurrentIndividualSelected,
  nodeDirectory,
  allFamilies,
  familyObj,
  pyramid,
  navigateToFamily,
  exploredIndividualNode,
  navigateToIndividual,
  displayName,
  handleForceCloseModal,
}) => {
  const classes = useStyles()

  return (
    <TreeViewerActions
      handleForceCloseModal={handleForceCloseModal}
      closeButtonStyles={{
        ml: 2,
        mr: 2,
        zIndex: 100,
      }}
      actionPositionStyles={{ ml: 2 }}
    >
      <SimpleDialog
        trigger={modalTrigger => (
          <SearchButton onClick={modalTrigger.onClick} />
        )}
      >
        <Box sx={{ width: '200px' }}>
          <SearchTreeControl
            {...{
              handleDeselection,
              handleFamilySelected,
              handleIndividualSelected,
              handleCurrentIndividualSelected,
              nodeDirectory,
              allFamilies,
            }}
          />
        </Box>
      </SimpleDialog>

      <AncestralFamiliesContainer
        trigger={modalTrigger => (
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            variant="outlined"
            className={classes.mobileButton}
            onClick={modalTrigger.onClick}
          >
            Ancestral Families
          </Button>
        )}
      />

      <SimpleDialog
        trigger={modalTrigger => (
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            variant="outlined"
            size="small"
            onClick={modalTrigger.onClick}
            className={classes.mobileButton}
          >
            Navigate To
          </Button>
        )}
      >
        <NavigateToControl
          {...{
            familyObj,
            pyramid,
            navigateToFamily,
            exploredIndividualNode,
            navigateToIndividual,
            displayName,
            handleForceCloseModal,
          }}
        />
      </SimpleDialog>
    </TreeViewerActions>
  )
}

export const TreeViewerActions = ({
  children,
  handleForceCloseModal,
  closeButtonStyles,
  actionPositionStyles,
}) => {
  const smallMobileBreakpoint = useMediaQuery(theme =>
    theme.breakpoints.down(370)
  )
  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          justifyContent: 'space-between',
          ...actionPositionStyles,
          display: smallMobileBreakpoint ? 'block' : 'flex',
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      <IconButton
        permissionAction={ACTION_ALL_ACCESS}
        size="small"
        sx={closeButtonStyles}
        onClick={handleForceCloseModal}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default MobileTreeViewerMenu
