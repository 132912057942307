import { useSelector } from 'react-redux'

import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { selectPublicNodeDirectory } from './treeSlice'
import { formatIndividualName } from '../../ui/individualUtils'
import {
  childrenWithSpouse,
  isUnknownIndividual,
  siblingsWithEachParent,
} from '../../viewer/api/nodeDirectory'
import { Relation } from '../../viewer/IndividualRelationships'

const RelationHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}))

const PublicIndividualRelationships = ({ individual }) => {
  const nodeDirectory = useSelector(selectPublicNodeDirectory)

  const mother =
    individual.bioMother &&
    !isUnknownIndividual(individual.bioMother) &&
    nodeDirectory[individual.bioMother]
  const father =
    individual.bioFather &&
    !isUnknownIndividual(individual.bioFather) &&
    nodeDirectory[individual.bioFather]

  const siblingsWithEachSetOfParents = siblingsWithEachParent(
    individual,
    nodeDirectory
  )

  const spouses = individual.spouses
    .map(id => nodeDirectory[id])
    .filter(spouse => !!spouse)

  const childrenWithEachSpouse = spouses.map(spouse => [
    spouse,
    childrenWithSpouse(individual, spouse, nodeDirectory),
  ])

  return (
    <Stack>
      <RelationHeader>Parents</RelationHeader>
      <Box>
        {father && (
          <Relation
            relativeName={individual.knownAs || individual.givenName}
            relationType="father"
            individual={father}
            isPublic
            canRemove={false}
          />
        )}
      </Box>
      <Box>
        {mother && (
          <Relation
            relativeName={individual.knownAs || individual.givenName}
            relationType="mother"
            individual={mother}
            isPublic
            canRemove={false}
          />
        )}
      </Box>

      <RelationHeader>Siblings</RelationHeader>
      {siblingsWithEachSetOfParents.map(([[mother, father], siblings]) => (
        <Box key={[mother?.id, father?.id]}>
          <Typography>
            By {formatIndividualName(mother)} {'&'}{' '}
            {formatIndividualName(father)}
          </Typography>
          <Box sx={{ ml: 3 }}>
            {siblings.map(sibling => (
              <Box key={sibling.id}>
                <Relation
                  relativeName={individual.knownAs || individual.givenName}
                  relationType="sibling"
                  individual={sibling}
                  canRemove={false}
                  isPublic
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      <RelationHeader>Significant others and children</RelationHeader>
      {childrenWithEachSpouse.map(([spouse, children]) => (
        <Box key={spouse.id}>
          <Relation
            relativeName={individual.knownAs || individual.givenName}
            type="spouse"
            individual={spouse}
            canRemove={false}
            isPublic
          />
          <Box sx={{ ml: 3 }}>
            {children.map(child => (
              <Box key={child.id}>
                <Relation
                  relativeName={individual.knownAs || individual.givenName}
                  relationType="child"
                  individual={child}
                  canRemove={false}
                  isPublic
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Stack>
  )
}

export default PublicIndividualRelationships
