import { createDefaultThemeParams, themeBuilder } from '../../../ui/theme'

export const dark = () => {
  let params = createDefaultThemeParams()

  const darkGrey = '#5A5A5A'
  const lightGrey = '#D3D3D3'
  const accent = '#DDDDDD'

  const titleFont = 'Montserrat'
  const bodyFont = 'Lora'

  params.typography.fontFamily = bodyFont
  params.typography.body1.fontFamily = bodyFont
  params.typography.body2.fontFamily = bodyFont
  params.typography.body3.fontFamily = bodyFont
  params.typography.subtitle1.fontFamily = titleFont
  params.typography.subtitle2.fontFamily = titleFont
  params.typography.subtitle3.fontFamily = titleFont
  params.typography.subtitle3.color = '#DDDDDD'
  params.typography.h1.fontFamily = titleFont
  params.typography.h1Left.fontFamily = titleFont
  params.typography.h2.fontFamily = titleFont
  params.typography.h3.fontFamily = titleFont
  params.typography.h4.fontFamily = titleFont
  params.typography.h5.fontFamily = titleFont
  params.typography.h6.fontFamily = titleFont
  params.colors.darkPurple = accent
  params.colors.secondaryGrey = lightGrey
  params.colors.grey = darkGrey
  params.colors.profileGrey = '#DDDDDD'
  params.backgroundImage =
    'https://images.unsplash.com/photo-1488972685288-c3fd157d7c7a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'

  const theme = themeBuilder(params)

  return theme
}
