import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActionDispatcher } from 'src/modules/app'
import { PublicContext } from '../contexts'
import {
  fetchPublicPagePhotos,
  selectPublicPhotos,
  setPublicViewConfig,
} from './pageSlice'
import { MediaList } from 'src/modules/photo'
import PublicActionBar from './PublicActionBar'
import {
  getViewConfig,
  useViewConfigQueryParams,
} from '../../common/viewConfigUtils'
import { INSTANCE_TYPE_MEDIA } from '../../app/links'
import { useLocation } from 'react-router-dom'

const PublicPagePhotoList = ({ target }) => {
  const { treeSlug } = useContext(PublicContext)
  const location = useLocation()
  const isHome = location.pathname.includes('home')
  const dispatch = useDispatch()
  const viewConfigQueryParams = useViewConfigQueryParams()
  const dispatchFetchPublicPagePhotos = useActionDispatcher(
    fetchPublicPagePhotos
  )
  const media = useSelector(selectPublicPhotos)

  const pageType = INSTANCE_TYPE_MEDIA

  useEffect(() => {
    dispatchFetchPublicPagePhotos({ target, treeSlug, page: 0 })
  }, [dispatchFetchPublicPagePhotos, target, treeSlug])

  const onFetchMore = () => {
    if (dispatchFetchPublicPagePhotos.status === 'loading') {
      return
    }
    dispatchFetchPublicPagePhotos({ target, treeSlug, page: media.page + 1 })
  }

  const { sort, hierarchical, ancestralOnly } = getViewConfig(
    media,
    viewConfigQueryParams
  )

  const handleSetViewConfig = async viewConfig => {
    await dispatch(setPublicViewConfig({ type: pageType, viewConfig }))
    dispatchFetchPublicPagePhotos({ target, treeSlug, page: 0 })
  }

  return (
    <>
      <PublicActionBar
        type={pageType}
        sort={sort}
        hierarchical={hierarchical}
        ancestralOnly={ancestralOnly}
        handleSetViewConfig={handleSetViewConfig}
        isHome={isHome}
      />
      <MediaList
        onFetchMore={onFetchMore}
        media={media}
        status={dispatchFetchPublicPagePhotos.status}
      />
    </>
  )
}

export default PublicPagePhotoList
