import Auth from '@aws-amplify/auth'

import config from 'src/config'

const EXPORT_TOKEN_HEADER = 'X-Export-Token'

export const getExportTokenIfPresent = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get('exporttoken')
}

const awsConfig = {
  Auth: config.cognito,
  API: {
    endpoints: [
      {
        name: 'default',
        custom_header: async () => {
          try {
            const session = await Auth.currentSession()
            const token = session.getIdToken().getJwtToken()
            return {
              Authorization: `Bearer ${token}`,
            }
          } catch (err) {
            const exportToken = getExportTokenIfPresent()
            if (exportToken) {
              return { [EXPORT_TOKEN_HEADER]: exportToken }
            } else {
              console.log("Public endpoint or couldn't get current session")
            }
          }
        },
        ...config.api,
      },
    ],
  },
}

export default awsConfig
