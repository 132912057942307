import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import Crop from '@mui/icons-material/Crop'

import { IconButton } from 'src/modules/ui'
import ArticlePhoto from 'src/modules/content/ArticlePhoto'
import CreateCroppedPhotoDialog from 'src/modules/photo/ImageCropper'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const usePhotoStyles = makeStyles(theme => ({
  removeButton: {
    background: 'white',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  cropButton: {
    background: 'white',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(8),
  },
}))

export const EditablePhoto = ({
  photo,
  onDoneCropping,
  onRemovePhoto,
  className,
  ...props
}) => {
  const classes = usePhotoStyles()
  const [photoToCrop, setPhotoToCrop] = useState(null)

  const handleCropPhoto = croppedPhoto => {
    onDoneCropping(croppedPhoto)
    setPhotoToCrop(null)
  }
  return (
    <div className={className}>
      <ArticlePhoto photo={photo} {...props}>
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          className={classes.removeButton}
          onClick={onRemovePhoto}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          className={classes.cropButton}
          onClick={() => setPhotoToCrop(photo)}
        >
          <Crop />
        </IconButton>
        <CreateCroppedPhotoDialog
          photo={photoToCrop}
          onDoneCropping={croppedPhoto => handleCropPhoto(croppedPhoto)}
          onCroppingCancelled={() => setPhotoToCrop(null)}
        />
      </ArticlePhoto>
    </div>
  )
}

export default EditablePhoto
