import { useCallback } from 'react'
import { Box, styled, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { IconButton } from 'src/modules/ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const ADD_BLOCK_CONTROL_SIZE = 40
const ADD_BLOCK_EXTRA_TRAVEL = 16

const horizontalLineProps = ({ theme }) => {
  return {
    content: '""',
    width: '100%',
    height: '1px',
    background: theme.palette.grey.main,
    display: 'block',
    position: 'relative',
    top: `${ADD_BLOCK_CONTROL_SIZE / 2}px`,
  }
}
const verticalLineProps = ({ theme }) => {
  return {
    content: '""',
    height: '100%',
    width: '1px',
    background: theme.palette.grey.main,
    display: 'block',
    position: 'relative',
    left: `${ADD_BLOCK_CONTROL_SIZE / 2}px`,
  }
}

export const CentredRowWithHorizontalLine = styled('div', {
  shouldForwardProp: prop => !['above', 'extraTravel'].includes(prop),
})(({ above, extraTravel, theme }) => ({
  width: '100%',
  height: ADD_BLOCK_CONTROL_SIZE,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  ...(extraTravel && above && { marginTop: ADD_BLOCK_EXTRA_TRAVEL }),
  ...(extraTravel && !above && { marginBottom: ADD_BLOCK_EXTRA_TRAVEL }),
  '&::before': horizontalLineProps({ theme }),
  '&::after': horizontalLineProps({ theme }),
}))

export const CentredRowWithVerticalLine = styled('div')(({ theme }) => ({
  width: ADD_BLOCK_CONTROL_SIZE,
  height: '92%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '&::before': verticalLineProps({ theme }),
  '&::after': verticalLineProps({ theme }),
}))

const useClickWithOrder = (onClick, order) => {
  return useCallback(() => {
    onClick && onClick(order)
  }, [onClick, order])
}

export const HorizontalAddElementControl = ({
  bottom,
  extraTravel = true,
  onClick,
  show,
  above,
  order = 0,
}) => {
  const handleClick = useClickWithOrder(onClick, order)
  const moveY =
    ADD_BLOCK_CONTROL_SIZE / 2 +
    (bottom ? 16 : 0) +
    (extraTravel ? ADD_BLOCK_EXTRA_TRAVEL : 0)

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          left: 0,
          zIndex: 100,
          [above ? 'top' : 'bottom']: -moveY,
          display: show ? 'block' : 'none',
        }}
      >
        <CentredRowWithHorizontalLine above={above} extraTravel={extraTravel}>
          {above ? (
            <Typography
              sx={{ position: 'absolute', bottom: '60%' }}
              mb={1}
              textAlign="center"
            >
              Add content block
            </Typography>
          ) : null}
          <Box sx={{ borderRadius: '50%' }}>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              className={'joyride_add_content_block'}
              noBackground
              onClick={handleClick}
            >
              <AddCircleIcon
                sx={{ background: 'white', borderRadius: '50%' }}
              />
            </IconButton>
          </Box>
          {!above ? (
            <Typography
              sx={{ position: 'absolute', bottom: -10 }}
              mb={1}
              textAlign="center"
            >
              Add content block
            </Typography>
          ) : null}
        </CentredRowWithHorizontalLine>
      </Box>
    </>
  )
}

export const VerticalAddElementControl = ({
  onClick,
  show,
  before,
  order = 0,
}) => {
  const handleClick = useClickWithOrder(onClick, order)
  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: ADD_BLOCK_CONTROL_SIZE,
        top: 0,
        zIndex: 100,
        [before ? 'left' : 'right']: `-${ADD_BLOCK_CONTROL_SIZE / 2}px`,
        display: show ? 'block' : 'none',
      }}
    >
      {show && (
        <CentredRowWithVerticalLine>
          <Box sx={{ borderRadius: '50%' }}>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              noBackground
              onClick={handleClick}
            >
              <AddCircleIcon
                sx={{ background: 'white', borderRadius: '50%' }}
              />
            </IconButton>
          </Box>
        </CentredRowWithVerticalLine>
      )}
    </Box>
  )
}

export const VerticalAddElementControlBefore = ({
  onClick,
  show,
  order = 0,
}) => {
  return (
    <VerticalAddElementControl
      onClick={onClick}
      show={show}
      order={order}
      before={true}
    />
  )
}
