import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import Clamp from 'react-multiline-clamp'

import { TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import VideocamIcon from '@mui/icons-material/Videocam'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'

import { Typography, IconButton, ConfirmDialog, Tooltip } from 'src/modules/ui'
import DateTakenDialog from './DateTakenDialog'
import { fileTypeMatcher, pdfFileMatcher, youTubeTypeMatcher } from './utils'
import { convertUIGedDate } from '../common/gedcomDateParser'
import { PDF_PREVIEW_IMAGE } from './index'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  img: {
    display: 'block',
    objectFit: 'cover',
    width: 180,
    height: 180,
  },
  imageCardButtons: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  imageContainer: {
    position: 'relative',
  },
  imageContainerDragging: {
    cursor: 'grabbing',
  },
  imageContainerDraggable: {
    cursor: 'grab',
  },
  textField: {
    marginTop: theme.spacing(1),
    width: 180,
    backgroundColor: 'white',
  },
  thumbImage: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #eaeaea',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
    pointerEvents: 'all',
    height: 'max-content',
  },
  thumbImageDragging: {
    boxShadow: '0px 0px 5px 2px rgb(0 0 0 / 10%)',
  },
  iconBorder: {
    border: '1px solid #eaeaea',
    height: 80,
    width: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.purpleShadow.main,
  },
  videoIcon: { width: '50%', height: '50%' },
  videoImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 180,
    height: 180,
    backgroundColor: 'white',
  },
  videoFilename: {
    textAlign: 'center',
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    overflowWrap: 'break-word',
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    flexShrink: 1,
  },
}))

const getPreviewImage = file => {
  if (file === undefined) {
    return ''
  }

  if (typeof file === 'string') {
    return file
  }

  if (fileTypeMatcher(file, 'image') || youTubeTypeMatcher(file, 'youtube')) {
    return file.preview || file
  }

  if (pdfFileMatcher(file)) {
    return PDF_PREVIEW_IMAGE
  }
}

const ImagePreviewTile = ({
  id,
  title,
  name,
  draggable,
  dragging,
  dragProps = {},
  file,
  dateTakenGed,
  onOpenDialog,
  onCloseDialog,
  onRemovePreviewTile = () => {},
  onUpdateTitle = () => {},
  onBlurUpdateTitle = () => {},
  onUpdateDates = () => {},
  hideCaptions = false,
  hideDateTakenDialog = false,
  confirmDialog = false,
  order,
  exifDate,
}) => {
  const classes = useStyles()
  const [oldTitle, setOldTitle] = useState(title)

  return (
    <div
      className={clsx(classes.thumbImage, {
        [classes.thumbImageDragging]: dragging,
      })}
      key={file.name}
    >
      <div
        className={clsx(classes.imageContainer, {
          [classes.imageContainerDraggable]: draggable && !dragging,
          [classes.imageContainerDragging]: dragging,
        })}
        {...dragProps}
      >
        {typeof file !== 'string' &&
        fileTypeMatcher(file, 'video') &&
        !youTubeTypeMatcher(file, 'youtube') ? (
          <div className={classes.videoImage}>
            <div className={classes.iconBorder}>
              <VideocamIcon color="primary" className={classes.videoIcon} />
            </div>
            <div className={classes.videoFilename}>
              <Clamp lines={3}>{file.name}</Clamp>
            </div>
          </div>
        ) : (
          <img
            alt="uploaded"
            src={getPreviewImage(file)}
            className={classes.img}
          />
        )}
        <div className={classes.imageCardButtons}>
          {!hideDateTakenDialog && (
            <DateTakenDialog
              {...{
                id,
                dateTakenGed,
                handleUpdateDates: onUpdateDates,
                onOpen: onOpenDialog,
                onClose: onCloseDialog,
              }}
              trigger={props => (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  sx={{ mr: 1 }}
                  size="small"
                  color="primary"
                  {...props}
                >
                  {dateTakenGed ? (
                    <Tooltip title={`Taken ${convertUIGedDate(dateTakenGed)}`}>
                      <AccessTimeFilledIcon
                        fontSize="small"
                        sx={{
                          verticalAlign: 'middle',
                          display: 'inline-flex',
                        }}
                        color="secondary"
                      />
                    </Tooltip>
                  ) : (
                    <AccessTimeIcon fontSize="small" />
                  )}
                </IconButton>
              )}
            />
          )}
          {confirmDialog ? (
            <ConfirmDialog
              {...{ onOpen: onOpenDialog, onClose: onCloseDialog }}
              onConfirm={() =>
                onRemovePreviewTile({
                  id,
                  file,
                  order,
                })
              }
              submitText="Remove"
              submitColor="error"
              trigger={props => (
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  size="small"
                  color="primary"
                  {...props}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            >
              <Typography>
                Are you sure you want to remove this image from the collection?
              </Typography>
            </ConfirmDialog>
          ) : (
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              size="small"
              color="primary"
              onClick={e =>
                onRemovePreviewTile({
                  id,
                  file,
                })
              }
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      <TextField
        required
        className={classes.textField}
        variant="outlined"
        placeholder="Add a title..."
        value={title || name}
        onChange={e => {
          setOldTitle(title || name)
          onUpdateTitle({
            id,
            oldTitle: title || name,
            newTitle: e.target.value,
            file,
          })
        }}
        onBlur={e => {
          setOldTitle(e.target.value)
          onBlurUpdateTitle({
            id,
            oldTitle,
            newTitle: e.target.value,
          })
        }}
      />
    </div>
  )
}

export default ImagePreviewTile
