import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Explore from './Explore'
import { selectUserHomeIndividual } from 'src/modules/auth/authSlice'
import { setExploredIndividualId } from 'src/modules/viewer/exploreTreeSlice'
import { withOverridableComponent } from 'src/overridable'

const NavigatorViewerWithPyramid = ({
  trigger,
  openExplore = false,
  setOpenExplore,
}) => {
  const dispatch = useDispatch()

  const homeIndividual = useSelector(selectUserHomeIndividual)

  useEffect(() => {
    if (homeIndividual) {
      dispatch(setExploredIndividualId(homeIndividual.id))
    }
  }, [homeIndividual, dispatch])

  return (
    <>
      {homeIndividual && openExplore && (
        <Explore
          navigatorMode={true}
          trigger={trigger}
          openExplore={openExplore}
          setOpenExplore={setOpenExplore}
        />
      )}
    </>
  )
}

export default withOverridableComponent(
  NavigatorViewerWithPyramid,
  'NavigatorViewerWithPyramid'
)
