import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from '@mui/material'

import React from 'react'
import FeedbackButton from './modules/app/FeedbackButton'

const FallBackComponent = ({ error }) => {
  const reLoad = () => {
    window.location.href = '/'
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Card sx={{ padding: '2rem', maxWidth: '500px' }}>
        <CardMedia component="img" width="100%" image="/logo.png" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Oops WeAre experiencing an Error!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {error?.toString()}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={reLoad} size="small">
            Click to reload
          </Button>
        </CardActions>
      </Card>
      <FeedbackButton />
    </Box>
  )
}

export default FallBackComponent
