import ReactGA from 'react-ga4'

export const ga4Events = {
  START_CLICK: 'start_click',
  MEDIA_ADDED: 'media_added',
  ARTICLE_CREATED: 'article_created',
  USER_INVITE_SENT: 'user_invite_sent',
  COMMENT_CREATED: 'comment_created',

  PAGEVIEW__FORGOT_PASSWORD_PART_1: '/forgot_password_part_1', // manual page_view event
  FORGOT_PASSWORD_PART_1_SUBMITTED: 'forgot_password_part_1_submitted',
  PAGEVIEW__FORGOT_PASSWORD_PART_2: '/forgot_password_part_2', // manual page_view event
  FORGOT_PASSWORD_PART_2_SUBMITTED: 'forgot_password_part_2_submitted',
  FORGOT_PASSWORD_PART_2_SUBMITTED_OK: 'forgot_password_part_2_submitted_ok',
  FORGOT_PASSWORD_PART_2_SUBMITTED_ERROR:
    'forgot_password_part_2_submitted_error',

  // auto page view event with location: http..../signup
  SIGNUP_PAGE_SUBMITTED: 'signup_page_submitted',
  SIGNUP_PAGE_SUBMITTED_OK: 'signup_page_submitted_ok',
  SIGNUP_PAGE_SUBMITTED_ERROR: 'signup_page_submitted_error',
  SIGNUP_PAGE_TERMS_AND_CONDITIONS_CLICKED:
    'signup_page_terms_and_conditions_clicked',
  SIGNUP_PAGE_PRIVACY_POLICY_CLICKED: 'signup_page_privacy_policy_clicked',
  SIGNUP_PAGE_GO_TO_LOGIN_PAGE_CLICKED: 'signup_page_go_to_login_page_clicked',

  PAGEVIEW__VERIFY_EMAIL: '/verify_email', // manual page_view event
  VERIFY_EMAIL_PAGE_OPENED_WITH_CODE: 'verify_email_page_opened_with_code',
  VERIFY_EMAIL_PAGE_OPENED_WITH_BAD_CODE:
    'verify_email_page_opened_with_bad_code',
  VERIFY_EMAIL_PAGE_SUBMITTED_BAD_CODE: 'verify_email_page_submitted_bad_code',
  VERIFY_EMAIL_PAGE_SUBMITTED_OTHER_ERROR:
    'verify_email_page_submitted_other_error',
  EMAIL_VERIFIED_OK: 'email_verified_ok', // also fired if code passed in url
  VERIFY_EMAIL_PAGE_CANT_FIND_EMAIL_CLICKED:
    'verify_email_page_cant_find_email_clicked', // help popup

  PAGEVIEW__SIGNUP_SURVEY: '/signup_survey', // manual page_view event
  SIGNUP_SURVEY_SUBMITTED: 'signup_survey_submitted',
  SIGNUP_SURVEY_SKIPPED: 'signup_survey_skipped',

  // auto page view event with location: http..../onboarding
  ONBOARDING_CLICKED_VIEW_DEMO_TREE: 'onboarding_clicked_view_demo_tree',
  ONBOARDING_CLICKED_CREATE_TREE: 'onboarding_clicked_create_tree',

  // auto page view event with location: http..../complete-profile
  COMPLETE_PROFILE_SUBMITTED_OK: 'complete_profile_submitted_ok',

  // can we tell the user has browsed the demo tree?

  // auto page view event with location: http..../create-tree
  PAGEVIEW__CREATE_TREE__SELECT_VISIBILITY:
    '/create-tree/create_tree_select_visibility', // manual page_view event
  CREATE_TREE_SELECT_VISIBILITY_PRIVATE:
    'create_tree_select_visibility_private',
  CREATE_TREE_SELECT_VISIBILITY_PUBLIC: 'create_tree_select_visibility_public', // CreateTreeWizard.handleOnNext() called by TreeVisibilitySelector.onNext

  PAGEVIEW__CREATE_OR_UPDATE_TREE: '/create-tree/create_or_update_tree', // manual page_view event
  CREATE_OR_UPDATE_TREE_CLICKED_DONT_HAVE_GEDCOM:
    'create_or_update_tree_clicked_dont_have_gedcom', // clicked the 'I don't have a gedcom' button
  CREATE_OR_UPDATE_TREE_OPENED_FILE_DIALOG:
    'create_or_update_tree_opened_file_dialog',
  CREATE_OR_UPDATE_TREE_FILE_SELECTED: 'create_or_update_tree_file_selected', //createOrUpdateTree user selects .ged file to upload, yet to confirm tree name
  CREATE_OR_UPDATE_TREE_UPLOADING_GEDCOM:
    'create_or_update_tree_uploading_gedcom', //user selects .ged file, confirms tree name and starts to upload file
  CREATE_OR_UPDATE_TREE_GEDCOM_UPLOADED:
    'create_or_update_tree_gedcom_uploaded', // user's .ged file has POSTed to our API ok, empty tree created, ged not been processed yet
  CREATE_OR_UPDATE_TREE_GEDCOM_UPLOAD_ERROR:
    'create_or_update_tree_gedcom_upload_error', // user's .ged file failed to POST to our API ok. File too big? Timeout? Tree name clash?
  CREATE_OR_UPDATE_TREE_GEDCOM_PROCESSING_ERROR:
    'create_or_update_tree_gedcom_processing_error', // user's .ged file failed process all the way through import phase 1 - CreateOrUpdateTree.onSystemEventStateUpdate()

  //START_WITH_GEDCOM: 'start_with_gedcom',       before 5/1/24 this was recorded after after the user had selected the .ged file, set the tree's name, clicked 'Import GEDCOM', then treesSlice.createTree() returned without error
  //START_WITHOUT_GEDCOM: 'start_without_gedcom', before 5/1/24 this was recorded after the user clicked 'I don't have a GEDCOM' then treeSlice.createEmptyTree() returned ok
  //TREE_CREATED: 'tree_created',                 before 5/1/24 this was recorded by treeSlice.createTree.fulfilled(), NOT when an empty tree was created

  // Will only get TREE_CREATED_FROM_GEDCOM_OK if the user waits on the browser for processing to complete
  TREE_CREATED_FROM_GEDCOM_OK: 'tree_created_from_gedcom_ok', // CreateOrUpdateTree.onSystemEventStateUpdate()
  TREE_CREATED_EMPTY: 'tree_created_empty', // CreateOrUpdateTree.handleCreateEmptyTree() after dispatchCreateEmptyTree() returned without error
  ADMIN_CREATED: 'admin_created',

  BLOG_CREATED: 'blog_created', // ManageTrees.navigateToCreateTree().createBlog()

  // auto page view event with location: http..../<tree_slug>/select-myself
  SELECT_MYSELF_ON_TREE: 'select_myself_on_tree',
  // removed when home_person was added 2024/03/20   SELECT_MYSELF_NOT_ON_TREE: 'select_myself_not_on_tree',
  SELECT_HOME_PERSON: 'select_home_person',

  // auto page view event with location: http....<tree_slug>/home/tree

  ADDCONTENTDIALOG_CLICKED: 'addcontentdialog_clicked',
  ADDCONTENTDIALOG_LINKED_PAGE: 'addcontentdialog_linked_page',
  ADDCONTENTDIALOG_ADDINDIVIDUALIMAGE: 'addcontentdialog_addindividualimage',
  ADDCONTENTDIALOG_ADDMEDIAALBUM: 'addcontentdialog_addmediaalbum',
  ADDCONTENTDIALOG_ADDMEDIA: 'addcontentdialog_addmedia',
  ADDCONTENTDIALOG_ADDCONTENT: 'addcontentdialog_addcontent',
  JOYRIDE_FAMILYINDIVIDUAL_SELECTOR_DIALOG_CLICK:
    'joyride_familyindividual_selector_dialog_click',
  USERJOYRIDE_COMPLETE: 'userjoyride_complete',
  USERJOYRIDE_SKIPPED: 'userjoyride_complete',
  ALBUM_SLIDESHOW_SHARED: 'album_slideshow_shared',
  ALBUM_SLIDESHOW_PREVIEWED: 'album_slideshow_previewed',
  ALBUM_SLIDESHOW_PLAYED_PUBLIC: 'album_slideshow_played_in_public',

  PUBLIC_HEADER_BUTTON_FIND_OUT_MORE: 'public_findoutmore_clicked',
  AIWIZARD_STARTED: 'aiwizard_started',
  AIWIZARD_BLOCK_COMPLETED: 'aiwizard_block_completed',
  AIWIZARD_COMPLETED: 'aiwizard_completed',
  AIWIZARD_CANCELLED: 'aiwizard_block_cancelled',
  SIDEBAR_SHOW: 'sidebar_show',
  SIDEBAR_HIDE: 'sidebar_hide',
  SIDEBAR_STOP_SHOWING: 'sidebar_stop_showing',
}

export const initAnalytics = config => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ReactGA.initialize(config.googleAnalyticsTracking.key, { debug: isDev })
}

export const sendEvent = (eventName, payload) => {
  //console.debug(`AnalyticsUtils.sendEvent(): sending event: '${eventName}'...`)
  ReactGA.event(eventName, payload)
}

export const sendPageview = path => {
  //console.debug(`AnalyticsUtils.sendPageview(): sending path: '${path}'...`)
  ReactGA.send({ hitType: 'pageview', page: path })
}
