import { useState, useEffect, createContext, useRef } from 'react'
import { useSelector } from 'react-redux'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { makeStyles } from '@mui/styles'

import { useActionDispatcher } from 'src/modules/app'
import {
  Typography,
  LoadingIndicator,
  ToggleButton,
  SimpleDialog,
} from 'src/modules/ui'

import UsersSortedByEmail from './UsersSortedByEmail'
import UsersByAlphabet from './UsersByAlphabet'
import UsersByRelationship from './UsersByRelationship'
import IndividualLine from './IndividualLine'
import TypeAheadAdmin from './TypeAheadAdmin'
import {
  fetchAllUsersAndAliveIndividuals,
  selectAllUsersAndAliveIndividuals,
  fetchAvailableRoles,
  selectAvailableRoles,
  fetchPermissionsInfo,
  selectPermissionsInfo,
} from './accountAdminSlice'
import { Box } from '@mui/material'
import { selectAuthorisedTreeSlug, selectUser } from '../auth/authSlice'
import { useTreeSettings } from '../app'
import { FOCUS_ONE_PLACE_STUDY } from '../app/appConstants'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 800,
  },
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  typeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bottomLineHeavy: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
  },
  bottomPadding: {
    paddingBottom: theme.spacing(4),
  },
}))

export const UserContext = createContext({})

// renders intro text and then a grid of which permissions are in which role
// used by RolesHelpDialog
const RolesHelp = () => {
  const permissionsInfo = useSelector(selectPermissionsInfo)

  return (
    <>
      <Box style={{ marginBottom: 16 }}>
        {/* <Typography variant="h3">Overview - User Level Permissions</Typography> */}
        <Typography>
          Each archive is fundamentally managed and controlled by the Super
          Admin, supported by any trusted Admins they might wish to appoint.
          Beneath, there are ever more restrictive user level options designed
          to allow family history information to be provided by a range of
          relatives and other relevant people, whilst protecting the underlying
          integrity of the archive’s content, and in particular the tree
          structure and details.
        </Typography>
        <Typography variant="h8">SUPER ADMIN</Typography>
        <Typography>
          is the ultimate authority for the archive and can carry out any action
          on their own and any other users' content. The Super Admin can alter
          the permission level of all other users.
        </Typography>
        <Typography variant="h8">ADMIN</Typography>
        <Typography>
          is a user trusted by the Super Admin to both regulate and contribute
          to the archive. An Admin can carry out any action on their own and any
          other users' content, except changing the privacy setting of other
          users' content. An Admin can alter the permission level of all other
          users except the Super User and fellow Admins.
        </Typography>
        <Typography
          variant="h10
        6"
        >
          EDITOR
        </Typography>
        <Typography>
          is a user who can add content AND add new individuals to the tree. But
          they cannot edit or delete other people’s content, nor edit/delete
          individuals they have not added.
        </Typography>
        <Typography variant="h8">CONTRIBUTOR</Typography>
        <Typography>
          is a user who can add content but CANNOT add new individuals to the
          tree.
        </Typography>
        <Typography variant="h8">VIEWER</Typography>
        <Typography>
          is a user who can view only AND add comments and generate questions to
          other users with the ‘Ask the family’ (as call all users of an
          archive).
        </Typography>
      </Box>
      {permissionsInfo.loading === false && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Permission</TableCell>
                {permissionsInfo.results.roles.map(role => (
                  <TableCell key={`permission_table_header_row_for_${role.id}`}>
                    {role.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(permissionsInfo.results.permissions).map(
                ([permissionNum, permission]) => (
                  <TableRow
                    key={`permission_row_${permissionNum}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{ whiteSpace: 'pre-line' }}>
                      {permission.desc}
                    </TableCell>
                    {permissionsInfo.results.roles.map(role => (
                      <TableCell align="center">
                        {role.perms.includes(parseInt(permissionNum))
                          ? '✅'
                          : ' '}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export const Users = ({
  loading,
  allUsers,
  title = 'living members',
  IndividualComponent = IndividualLine,
  TypeAheadComponent = TypeAheadAdmin,
  container,
  availableRoles,
  showSectionRegistered = true,
  showSectionPending = true,
  showSectionNotOnTree = true,
  showSectionInviteNotOnTree = true,
  showSectionNotOnTreeBottom = false,
  allowInvite = true,
}) => {
  // this component is used in two places - the 'invite' screen and inside a dialog opened from 'ask family'.
  // prop 'container' is filled with 'invite' or 'info_request'.
  // different IndividualComponent / TypeAheadComponent values are passed in depending on the container.

  const treeSettings = useTreeSettings()

  const user = useSelector(selectUser)
  const classes = useStyles()
  const [mode, setMode] = useState(
    treeSettings?.archiveFocus === FOCUS_ONE_PLACE_STUDY
      ? 'byAlphabet'
      : 'byRelationship'
  )
  const handleToggle = (event, newMode) => {
    setMode(newMode)
  }
  const treeName = user?.currentTree?.name
  const [individualsNotYetInvited, setIndividualsNotYetInvited] = useState(null)

  useEffect(() => {
    if (allUsers) {
      setIndividualsNotYetInvited(
        allUsers
          .filter(ind => ind.givenName || ind.surname)
          .filter(ind => !ind.user)
      )
    } else {
      setIndividualsNotYetInvited(null)
    }
  }, [allUsers, allowInvite])

  const dispatchFetchPermissionsInfo = useActionDispatcher(fetchPermissionsInfo)

  useEffect(() => {
    dispatchFetchPermissionsInfo()
  }, [dispatchFetchPermissionsInfo])

  // pass a ref to SimpleDialog and it can be used to access the setModalOpen function
  const rolesHelpDialogRef = useRef(null)

  const showRolesHelpDialog = () => {
    if (rolesHelpDialogRef && rolesHelpDialogRef.current) {
      rolesHelpDialogRef.current.setModalOpen(true)
    }
  }

  const RolesHelpDialog = () => {
    return (
      <SimpleDialog
        ref={rolesHelpDialogRef}
        title="Overview - User Level Permissions"
        showCloseButton={true}
        fullWidth={true}
        maxWidth={'95%'}
      >
        <RolesHelp />
      </SimpleDialog>
    )
  }

  return (
    <Box sx={{ m: 2 }}>
      <RolesHelpDialog />
      <div className={classes.sectionHeader}>
        <Typography variant="h4" className={classes.bottomMargin}>
          {title === 'living members' ? treeName : null} {title}
        </Typography>
      </div>
      <UserContext.Provider value={{ IndividualComponent, TypeAheadComponent }}>
        <div>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              {/* REGISTERED USERS */}
              {showSectionRegistered && (
                <div className={classes.bottomMargin}>
                  <UsersSortedByEmail
                    users={allUsers
                      .filter(ind => ind.user && ind.user.state === 'ACTIVE')
                      .filter(ind => container === 'invite' || ind.id)} // asking non-family-members not supported, backend model InformationRequestTo requires an Individual
                    headerText={
                      container === 'invite'
                        ? 'Registered users'
                        : 'Registered family members'
                    }
                    availableRoles={availableRoles}
                    showRolesHelpDialog={showRolesHelpDialog}
                  />
                </div>
              )}
              <>
                {/* INVITES PENDING */}
                {showSectionPending && (
                  <div
                    className={`${classes.bottomLineHeavy} ${classes.bottomMargin} ${classes.bottomPadding}`}
                  >
                    <UsersSortedByEmail
                      users={allUsers.filter(
                        ind => ind.user && ind.user.state === 'INVITED'
                      )}
                      headerText="Invites pending"
                      availableRoles={availableRoles}
                      showRolesHelpDialog={showRolesHelpDialog}
                    />
                  </div>
                )}

                {/* NOT ON TREE */}
                {showSectionNotOnTree && (
                  <div className={classes.bottomMargin}>
                    <UsersSortedByEmail
                      users={allUsers
                        .filter(ind => ind.user)
                        .filter(ind => !ind.id)} //no individual.id
                      headerText={'Users not in archive'}
                      availableRoles={availableRoles}
                      showRolesHelpDialog={showRolesHelpDialog}
                    />
                  </div>
                )}

                {/* INVITE USER NOT IN TREE */}
                {allowInvite && showSectionInviteNotOnTree && (
                  <div>
                    <Typography className={classes.header} variant="h6">
                      Invite user not in archive
                    </Typography>
                    <IndividualComponent
                      individual={{}}
                      emailTextFieldPlaceholderText="Enter email address to invite non-family member"
                      removeNameField={true}
                      availableRoles={availableRoles}
                      showRolesHelpDialog={showRolesHelpDialog}
                    />
                  </div>
                )}
              </>
              {
                // type-ahead listing all individuals on tree. Internally separates those who are already invited.
                allowInvite && individualsNotYetInvited && (
                  <div className={[classes.bottomMargin]}>
                    <TypeAheadComponent
                      otherUsers={individualsNotYetInvited}
                      availableRoles={availableRoles}
                    />
                  </div>
                )
              }

              <div className={classes.typeContainer}>
                <ToggleButtonGroup
                  size="small"
                  value={mode}
                  onChange={handleToggle}
                  exclusive
                >
                  <ToggleButton
                    type="primary"
                    selected={mode !== 'byRelationship'}
                    value="byRelationship"
                  >
                    By Relationship
                  </ToggleButton>
                  <ToggleButton
                    type="primary"
                    selected={mode !== 'byAlphabet'}
                    value="byAlphabet"
                  >
                    Alphabetical
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              {mode === 'byRelationship' ? (
                <UsersByRelationship
                  users={allUsers.filter(
                    ind => ind.id && (ind.user || ind.relationship)
                  )} //only individuals on tree who are either (already invited or are a close relative)
                  availableRoles={availableRoles}
                  showRolesHelpDialog={showRolesHelpDialog}
                />
              ) : (
                <UsersByAlphabet
                  users={allUsers.filter(
                    ind => ind.id && (ind.user || ind.relationship)
                  )} //only individuals on tree who are either (already invited or are a close relative)
                  availableRoles={availableRoles}
                  showRolesHelpDialog={showRolesHelpDialog}
                />
              )}

              {/* NOT ON TREE */}
              {showSectionNotOnTreeBottom && (
                <div className={classes.bottomMargin}>
                  <UsersSortedByEmail
                    users={allUsers
                      .filter(ind => ind.user)
                      .filter(ind => !ind.id)} //no individual.id
                    headerText={'Users not in archive'}
                    availableRoles={availableRoles}
                    showRolesHelpDialog={showRolesHelpDialog}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </UserContext.Provider>
    </Box>
  )
}

const UsersContainer = () => {
  const dispatchFetchAllUsersAndAliveIndividuals = useActionDispatcher(
    fetchAllUsersAndAliveIndividuals
  )
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const dispatchFetchAvailableRoles = useActionDispatcher(fetchAvailableRoles)

  const allUsersAndAliveIndividuals = useSelector(
    selectAllUsersAndAliveIndividuals
  )

  const availableRoles = useSelector(selectAvailableRoles)

  useEffect(() => {
    if (treeSlug) {
      dispatchFetchAllUsersAndAliveIndividuals({ treeSlug })
    }
  }, [dispatchFetchAllUsersAndAliveIndividuals, treeSlug])

  useEffect(() => {
    if (treeSlug) {
      dispatchFetchAvailableRoles({ treeSlug })
    }
  }, [dispatchFetchAvailableRoles, treeSlug])

  const user = useSelector(selectUser)

  return (
    //SendInfoRequestDialog also mounts Users
    <Users
      allUsers={allUsersAndAliveIndividuals.results.filter(
        i => i.user?.id !== user.id
      )}
      loading={allUsersAndAliveIndividuals.loading}
      container="invite"
      availableRoles={availableRoles.results}
      showSectionRegistered={false}
      showSectionPending={false}
      //showSectionNotOnTree={false}
      allowInvite={true}
    />
  )
}
export default UsersContainer
