import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Typography,
} from '@mui/material'
import {
  Button,
  ConfirmDialog,
  DialogTitle,
  FIELD_REQUIRED,
  FormikBooleanField,
  FormikTextField,
  IconButton,
  LoadingIndicator,
} from '../ui'
import { Formik } from 'formik'
import FormikSelect from '../ui/FormikSelect'
import FormikGedDatePicker from '../ui/FormikGedDatePicker'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import { useActionDispatcher } from '../app'
import {
  createLinkedFact,
  deleteLinkedFact,
  fetchLinkedFacts,
  updateLinkedFact,
  deleteFactSourceCitationLink,
  createSourceCitationLink,
  selectLinkedFactSources,
  createMediaOnSourceCitationLink,
} from './pageSlice'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import {
  addPartner,
  selectIndividualsById,
  selectNodeDirectory,
} from '../viewer/viewerSlice'
import { IndividualOnFact } from './FactsList'
import { ChooseOrAddButton } from '../viewer/IndividualRelationships'
import { unionIfExists } from '../viewer/api/nodeDirectory'
import { ACTION_ALL_ACCESS, ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_FACT } from '../app/links'
import { PhotoListNavigatorDialog } from '../photo/MediaNavigator'
import { DEFAULT_EDIT_FORM_VALUES, FactListContext } from './Facts'
import EditFactSourceDialog from './EditFactSourceDialog'
import Source from './Source'

const FACT_TYPES = [
  'BIRTH',
  'DEATH',
  'MARRIAGE',
  'DIVORCE',
  'RESIDENCE',
  'OCCUPATION',
  'MILITARY',
  'OBITUARY',
  'ARRIVAL',
  'DEPARTURE',
  'CHRISTENING',
  'RELIGION',
  'ADULT_CHRISTENING',
  'BAPTISM',
  'FIRST_COMMUNION',
  'CONFIRMATION',
  'BAR_MITZVAH',
  'BAS_MITZVAH',
  'BLESSING',
  'BURIAL',
  'CENSUS',
  'CREMATION',
  'EDUCATION',
  'GRADUATION',
  'EMIGRATION',
  'IMMIGRATION',
  'NATURALIZATION',
  'PROBATE',
  'RETIREMENT',
  'CUSTOM',
]

export const UNION_FACT_TYPES = ['MARRIAGE', 'DIVORCE']
const FIXED_FACT_TYPES = ['BIRTH', 'DEATH', ...UNION_FACT_TYPES]

const configureSelectOptions = type => {
  let label = type.toLowerCase().split('_').join(' ')
  label = label.charAt(0).toUpperCase() + label.slice(1)
  return {
    label,
    key: type,
  }
}

const SuggestedIndividuals = ({ individualIds = [], onChosen }) => {
  const individuals = useSelector(selectIndividualsById(individualIds))

  return (
    <Box>
      <Typography>Suggested</Typography>
      <Box>
        {individuals?.map(individual => (
          <Box
            onClick={() => onChosen(individual)}
            my={0.2}
            sx={{
              padding: 0.5,
              transitionDuration: '0.2s',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: '0 0 11px rgba(0,0,0,0.2)',
              },
            }}
          >
            <IndividualOnFact individual={individual} disabled={true} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const EditSpouse = ({ individual, updateSpouse, pageIndividual }) => {
  const dispatchAddPartner = useActionDispatcher(addPartner)
  const { linkedPageId } = useParams()
  const nodeDirectory = useSelector(selectNodeDirectory)

  const { spouse, setSpouse } = useContext(FactListContext)

  const handlePartnerAdded = async chosenPartner => {
    const union = unionIfExists(nodeDirectory, linkedPageId, chosenPartner?.id)
    if (!union) {
      const partnerResponse = await dispatchAddPartner({
        individualId: linkedPageId,
        individualAttributes: chosenPartner,
      })
      setSpouse(partnerResponse?.payload?.[1])
      updateSpouse('individualId', partnerResponse?.payload?.[1]?.id)
    } else {
      setSpouse(chosenPartner)
      updateSpouse('individualId', chosenPartner?.id)
    }
  }

  const handlePartnerRemoved = () => {
    setSpouse(null)
    updateSpouse('individualId', '')
  }

  return (
    <Box my={2}>
      {dispatchAddPartner.status === 'loading' ? (
        <LoadingIndicator />
      ) : spouse && spouse?.id !== linkedPageId ? (
        <Box display="flex" justifyContent="space-between">
          <IndividualOnFact individual={spouse} disabled={true} />
          <ConfirmDialog
            onConfirm={handlePartnerRemoved}
            trigger={props => (
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                {...props}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          >
            Are you sure you want to remove this individual from the fact?
          </ConfirmDialog>
        </Box>
      ) : (
        <>
          <ChooseOrAddButton
            individual={individual}
            relationType="significant other"
            onChosenOrAdded={handlePartnerAdded}
          />
          <SuggestedIndividuals
            onChosen={handlePartnerAdded}
            individualIds={pageIndividual?.spouses}
          />
        </>
      )}
    </Box>
  )
}

const PreferredFact = ({ isSubmitting, preferred, factType, initialValue }) => {
  const preferredFactTypes = [
    'BIRTH',
    'DEATH',
    'OBITUARY',
    'CHRISTENING',
    'BAPTISM',
    'BURIAL',
    'CREMATION',
    'PROBATE',
  ]

  if (initialValue === true) {
    return <Chip label="Preferred" color="secondary" sx={{ ml: -1, mt: 2 }} />
  }

  if (preferredFactTypes.includes(factType)) {
    return (
      <>
        <FormikBooleanField
          label="Preferred Fact"
          disabled={isSubmitting}
          name="preferred"
        />
      </>
    )
  } else {
    return null
  }
}

const EditFactDetails = React.forwardRef(
  (
    {
      initialValues,
      handleSubmit,
      isEditing,
      handleDelete,
      handleCloseModal,
      stopEditMetaData,
      individual,
      unchangedValues,
      pageIndividual,
    },
    ref
  ) => {
    const addressIsFreeText = !initialValues?.address?.gedcomLines?.length > 0
    const validationSchema = Yup.object().shape({
      factType: Yup.string().required(FIELD_REQUIRED),
      date: Yup.string().required(FIELD_REQUIRED),
    })

    const selectOptions = FACT_TYPES.sort().map(type =>
      configureSelectOptions(type)
    )
    const isFixedFactType = FIXED_FACT_TYPES.includes(initialValues.factType)

    const disabledSelectOptions = FIXED_FACT_TYPES.map(type =>
      configureSelectOptions(type)
    )

    return (
      <>
        <DialogTitle>{isEditing ? 'Edit' : 'Add'} Fact</DialogTitle>
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
            <>
              <DialogContent sx={{ pt: 0 }}>
                <FormikSelect
                  label="Fact Type"
                  disabled={isFixedFactType}
                  options={
                    !isFixedFactType ? selectOptions : disabledSelectOptions
                  }
                  value={values.factType}
                  name="factType"
                  onChange={v => setFieldValue('factType', v)}
                />
                {UNION_FACT_TYPES.includes(values.factType) && (
                  <EditSpouse
                    pageIndividual={pageIndividual}
                    individual={individual}
                    updateSpouse={setFieldValue}
                  />
                )}
                {values.factType === 'OCCUPATION' && (
                  <FormikTextField
                    fullWidth
                    label="Detail"
                    disabled={isSubmitting}
                    margin="normal"
                    name="detail"
                  />
                )}
                {values.factType === 'CUSTOM' && (
                  <FormikTextField
                    fullWidth
                    label="Custom Fact Type"
                    disabled={isSubmitting}
                    margin="normal"
                    name="customFactType"
                  />
                )}
                <FormikGedDatePicker
                  required={true}
                  disabled={isSubmitting}
                  label="Date"
                  margin="normal"
                  name="date"
                  setFieldValue={setFieldValue}
                />
                {addressIsFreeText || !isEditing ? (
                  <FormikTextField
                    fullWidth
                    label="Address"
                    disabled={isSubmitting}
                    margin="normal"
                    name="address.freeText"
                  />
                ) : (
                  <Box>
                    {initialValues?.address?.gedcomLines?.map(
                      (field, index) => {
                        return (
                          <FormikTextField
                            fullWidth
                            label={field.tagName}
                            disabled={isSubmitting}
                            margin="normal"
                            name={`address.gedcomLines[${index}].lineValue`}
                          />
                        )
                      }
                    )}
                  </Box>
                )}
                <FormikTextField
                  fullWidth
                  label="Detail"
                  disabled={isSubmitting}
                  margin="normal"
                  name="detail"
                />
                {!stopEditMetaData && (
                  <FormikTextField
                    multiline
                    fullWidth
                    label="Notes"
                    disabled={isSubmitting}
                    margin="normal"
                    name="notes"
                  />
                )}
                <PreferredFact
                  preferred={initialValues.preferred}
                  isSubmitting={isSubmitting}
                  factType={values.factType}
                  initialValue={unchangedValues.preferred}
                />
                <Typography my={1}></Typography>
              </DialogContent>
            </>
          )}
        </Formik>
      </>
    )
  }
)

const ManageFactSources = ({
  factId,
  sources,
  updateSources,
  handleDialogWidth,
  setNavigatorPhotos,
}) => {
  const { linkedPageId } = useParams()
  const dispatchDeleteSourceCitationLink = useActionDispatcher(
    deleteFactSourceCitationLink
  )
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const linkedSources = useSelector(selectLinkedFactSources(factId))
  const dispatchCreateSourceLink = useActionDispatcher(
    createMediaOnSourceCitationLink
  )

  const refreshFacts = () => {
    dispatchFetchLinkedFacts({ target: linkedPageId })
  }

  const deleteSourceLink = async id => {
    try {
      if (!factId) {
        const newSources = sources.filter(source => source.id !== id)
        updateSources(newSources)
      } else {
        await dispatchDeleteSourceCitationLink({ id: id })
      }
    } catch (error) {
      console.log(error)
    }
    dispatchFetchLinkedFacts({ target: linkedPageId })
  }

  const linkMediaToSource = async (mediaIds, source) => {
    mediaIds.forEach(async mediaId => {
      try {
        await dispatchCreateSourceLink({
          mediaId,
          instanceId: source.id,
        })
      } catch (err) {
        console.error(err)
      }
    })
  }

  const factSources = factId ? linkedSources : sources

  if (!factId) {
    return (
      <Box
        mt={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Please Create a fact before adding sources
      </Box>
    )
  }

  return (
    <div style={{ maxHeight: '500px', overflow: 'scroll' }}>
      <EditFactSourceDialog
        factId={factId}
        sourceObj={{ title: '', author: '' }}
        refreshFacts={refreshFacts}
        updateSources={updateSources}
        linkMediaToSource={linkMediaToSource}
        trigger={props => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              permissionAction={ACTION_CREATE}
              permissionParams={{ instanceType: INSTANCE_TYPE_FACT }}
              {...props}
            >
              Add Source
            </Button>
          </Box>
        )}
      />
      <Box mt={1} sx={{ overflowY: 'scroll' }}>
        {factSources?.length > 0 ? (
          <>
            {factSources?.map(sourceCitation => (
              <Source
                linked={Boolean(factId)}
                sourceCitation={sourceCitation}
                handleDelete={deleteSourceLink}
                refreshFacts={refreshFacts}
                handleDialogWidth={handleDialogWidth}
                setNavigatorPhotos={setNavigatorPhotos}
                linkMediaToSource={linkMediaToSource}
                updateSources={updateSources}
              />
            ))}
          </>
        ) : (
          <Typography>No sources yet...</Typography>
        )}
      </Box>
    </div>
  )
}

export default function CreateUpdateFact({
  id,
  open,
  defaultTab = 99,
  individual,
  setFactEditParams,
  formValues,
  setSpouse,
  initialValues,
  pageIndividual,
}) {
  const isEditing = id !== undefined

  const modalOpen = open
  const tabValue = defaultTab
  const [dialogWidth, setDialogWidth] = useState('30%')
  const [sources, setSources] = useState(formValues.sourcesCitationsList || [])
  const initialFormValues = formValues

  const [navigatorPhotos, setNavigatorPhotos] = useState(null)
  const dispatchDeleteFact = useActionDispatcher(deleteLinkedFact)
  const dispatchUpdateFact = useActionDispatcher(updateLinkedFact)
  const dispatchCreateFact = useActionDispatcher(createLinkedFact)
  const dispatchFetchLinkedFacts = useActionDispatcher(fetchLinkedFacts)
  const dispatchCreateSourceCitationLink = useActionDispatcher(
    createSourceCitationLink
  )
  const { linkedPageId } = useParams()
  const nodeDirectory = useSelector(selectNodeDirectory)
  const formRef = useRef()

  const handleChange = (event, newValue) => {
    let formValues = initialFormValues
    if (formRef.current?.values) {
      formValues = { ...formValues, ...formRef.current.values }
    }

    setFactEditParams({
      factId: id,
      editModalOpen: true,
      defaultTab: newValue,
      formValues: formValues,
    })
  }

  const handleCloseModal = () => {
    setSources([])
    // if (formRef.current?.values) {
    //   setInitialFormValues(formRef.current.values)
    // }
    setSpouse(null)
    setFactEditParams(DEFAULT_EDIT_FORM_VALUES)
  }

  const handleSubmit = async fields => {
    if (tabValue === 1) {
      fields = initialFormValues
    } else {
      fields = formRef.current?.values
    }

    if (isEditing) {
      let recordedDates = []
      if (formValues.recordedDatesGed?.length > 0) {
        recordedDates = [...formValues.recordedDatesGed]
      }
      recordedDates[0] = fields.date

      let updates = {
        linkedPageId,
        id: id,
        ...fields,
        recordedDatesGed: recordedDates,
        individualId: linkedPageId,
      }

      if (individual?.individualId !== fields?.individualId) {
        const union = unionIfExists(
          nodeDirectory,
          linkedPageId,
          fields?.individualId
        )
        const targets = union ? [union?.id] : [linkedPageId]
        updates = {
          ...updates,
          setTargets: targets,
        }
      }

      await dispatchUpdateFact({ pageIndividual, ...updates })
    } else {
      const union = unionIfExists(
        nodeDirectory,
        linkedPageId,
        fields?.individualId
      )
      const targets = union ? [union?.id] : [linkedPageId]

      const newFact = await dispatchCreateFact(
        {
          setTargets: targets,
          ...fields,
          recordedDatesGed: [fields.date],
          pageIndividual,
        },
        {
          successNotification: 'Fact created',
        }
      )

      if (sources?.length > 0) {
        sources?.forEach(async source => {
          await dispatchCreateSourceCitationLink({
            setTargets: [newFact?.payload?.id],
            source: {
              ...source,
              media: source?.media?.map(mediaItem => mediaItem.id),
              sourceCitationDetails: source.citationDetails,
            },
          })
        })
      }
    }
    dispatchFetchLinkedFacts({ target: linkedPageId })
    handleCloseModal()
  }
  const handleDelete = async () => {
    try {
      await dispatchDeleteFact({
        id: id,
        pageIndividual,
      }).unwrap()
    } catch (error) {
      console.log(error)
    }
    dispatchFetchLinkedFacts({ target: linkedPageId })
    handleCloseModal()
  }

  const handleDialogWidth = useCallback(() => {
    if (dialogWidth !== 1000) {
      setDialogWidth(1000)
    }
  }, [setDialogWidth, dialogWidth])

  const handleUpdateSources = source => {
    if (Array.isArray(source)) {
      setSources(source)
    } else {
      setSources(prevState => {
        const index = prevState.findIndex(
          existingSource => existingSource.id === source.id
        )

        if (index !== -1) {
          // Source already exists, so update it
          const updatedSources = [...prevState]
          updatedSources[index] = source
          return updatedSources
        } else {
          // Source doesn't exist, so add it
          return [...prevState, source]
        }
      })
    }
  }

  useEffect(() => {
    if (formValues.notes?.length > 400) {
      handleDialogWidth()
    }
  }, [formValues.notes, setDialogWidth, handleDialogWidth])

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{
          // Set to match PhotoListNavigatorDialog at 1100
          zIndex: 1100,
        }}
        PaperProps={{
          sx: {
            minHeight: 520,
            width: dialogWidth,
            maxWidth: '90vw',
          },
        }}
      >
        {modalOpen && (
          <>
            <CloseIcon
              sx={{ margin: '16px 16px 0px auto', cursor: 'pointer' }}
              onClick={handleCloseModal}
            />
            <TabContext value={tabValue}>
              <div style={{ minWidth: 425 }}>
                <TabList
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="Edit fact tabs"
                  centered
                >
                  <Tab label="Edit Fact" id={0} />
                  <Tab label="Manage Sources" id={2} />
                </TabList>
                <TabPanel value={0}>
                  <EditFactDetails
                    ref={formRef}
                    initialValues={initialFormValues}
                    unchangedValues={initialValues}
                    handleSubmit={handleSubmit}
                    isEditing={isEditing}
                    handleDelete={handleDelete}
                    handleCloseModal={handleCloseModal}
                    individual={individual}
                    pageIndividual={pageIndividual}
                  />
                </TabPanel>
                <TabPanel value={1}>
                  <ManageFactSources
                    factId={id}
                    sources={sources}
                    updateSources={source => handleUpdateSources(source)}
                    handleDialogWidth={handleDialogWidth}
                    navigatorPhotos={navigatorPhotos}
                    setNavigatorPhotos={setNavigatorPhotos}
                  />
                </TabPanel>
              </div>
            </TabContext>
            {tabValue === 0 && (
              <DialogActions
                sx={{
                  justifyContent: 'space-between',
                  marginTop: 'auto',
                }}
              >
                {isEditing ? (
                  <ConfirmDialog
                    onConfirm={handleDelete}
                    trigger={props => (
                      <Button
                        permissionAction={ACTION_CREATE}
                        permissionParams={{ instanceType: INSTANCE_TYPE_FACT }}
                        {...props}
                        color="error"
                      >
                        Delete
                      </Button>
                    )}
                  >
                    <Typography>
                      Are you sure you want to delete this fact?
                    </Typography>
                  </ConfirmDialog>
                ) : sources?.length > 0 ? (
                  <ConfirmDialog
                    onConfirm={handleCloseModal}
                    trigger={props => (
                      <Button
                        permissionAction={ACTION_ALL_ACCESS}
                        {...props}
                        color="primary"
                      >
                        Cancel
                      </Button>
                    )}
                  >
                    This fact and its sources will not be saved. Are you sure
                    you want to continue?
                  </ConfirmDialog>
                ) : (
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={handleCloseModal}
                    color="primary"
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  permissionAction={ACTION_CREATE}
                  permissionParams={{ instanceType: INSTANCE_TYPE_FACT }}
                  isLoading={
                    dispatchUpdateFact.status === 'loading' ||
                    dispatchCreateFact.status === 'loading'
                  }
                  onClick={handleSubmit}
                  color="primary"
                >
                  {isEditing ? 'Save' : 'Create'}
                </Button>
              </DialogActions>
            )}
            {tabValue === 1 && (
              <DialogActions
                sx={{
                  justifyContent: 'flex-end',
                  marginTop: 'auto',
                }}
              >
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={() => handleChange(null, 0)}
                  color="primary"
                >
                  {'Back'}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
      {!!navigatorPhotos?.selectedPhoto && (
        <PhotoListNavigatorDialog
          media={navigatorPhotos?.media}
          initialPhoto={navigatorPhotos?.selectedPhoto}
          onClose={() => setNavigatorPhotos(null)}
        />
      )}
    </>
  )
}
