import { createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import _ from 'lodash'

import api, { createWrappedAsyncThunk } from 'src/api'
import { updateTree } from 'src/modules/trees/treesSlice'
import {
  addMother,
  addFather,
  addPartner,
  addChild,
  updateIndividual,
} from '../viewer/viewerSlice'
import { BLOG_TREE } from '../common/constants'
import { fetchNotifications } from '../app/appSlice'

export const initialState = {
  user: null,
  invitedUser: null,
  descendedFromFamilyIds: null,
  authorisedTreeSlug: null,
  treeLoading: false,
}

const SLICE_NAME = 'auth'

/*
 adding delete tree here because the trees are attached to the user and I want to zap the one that is deleted on fullfilled
 */
export const deleteTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteTree`,
  treeSlug => {
    return api.del(`/tree/${treeSlug}/delete/`)
  }
)

export const downloadMediaFromLatestGedcomForTree = createWrappedAsyncThunk(
  `${SLICE_NAME}/downloadMediaFromLatestGedcomForTree`,
  treeSlug => {
    return api.put(`/tree/${treeSlug}/download-remaining-media-from-ged/`)
  }
)
export const createUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/createUser`,
  params => api.post('/account/user/create/', { body: params })
)

export const fetchUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchUser`,
  () => api.get('/account/user/me/')
)

// called from Onboarding.js
export const initialiseUser = createWrappedAsyncThunk(
  `${SLICE_NAME}/initialiseUser`,
  () => api.post('/account/user/initialise/')
)

export const changePassword = createWrappedAsyncThunk(
  `${SLICE_NAME}/changePassword`,
  async ({ oldPassword, newPassword }) => {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
  }
)

// there is no fulfilled reducer for this
// so if none of the callers does anything with the response then
// ?emptyResponse=true could be added to save wasted network traffic
export const updateProfile = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateProfile`,
  args =>
    api.patch('/account/user/me/', {
      body: { ...(args.photo ? { setPhoto: args.photo.id } : {}), ...args },
    })
)

export const patchProfileAndState = createWrappedAsyncThunk(
  `${SLICE_NAME}/patchProfileAndState`,
  args =>
    api.patch('/account/user/me/?emptyResponse=true', {
      body: { ...(args.photo ? { setPhoto: args.photo.id } : {}), ...args },
    })
)

export const respondToInvite = createWrappedAsyncThunk(
  `${SLICE_NAME}/respondToInvite`,

  args => {
    return api.patch('/account/user/invite-response/', { body: args })
  }
)

export const activateInvitee = createWrappedAsyncThunk(
  `${SLICE_NAME}/activateInvitee`,
  args => api.patch('/account/user/activate-user/', { body: args })
)

const updateUserAttributesFromTree = (state, treeSlug) => {
  let tree = state.user.trees.find(
    t => t.slug === treeSlug && t.state === 'ROOT'
  )
  if (tree) {
    state.authorisedTreeSlug = treeSlug
    state.user.currentTree = tree
    state.user.individualOnTree = tree.userIndividual
    state.user.homeIndividual = tree.homeIndividual || tree.userIndividual
    state.user.currentTree.currentUserIsAdmin = tree.userIsAdmin
  }
}

/**
 * if any of the passed individuals are the current tree home person, update
 * the current tree home person in auth state. This updates the family pyramid.
 */
const updateTreeUserIndividual = (state, updatedIndividuals) => {
  // console.debug(
  //   `authSlice.reducer.updateTreeUserIndividual(): called with ${updatedIndividuals.length} updatedIndividuals (isDraft(updatedIndividuals): ${isDraft(updatedIndividuals)}):`,
  //   [...updatedIndividuals]
  // )

  const treeSlug = state.user.currentTree.slug

  const tree = state.user.trees.find(
    t => t.slug === treeSlug && t.state === 'ROOT'
  )

  updatedIndividuals.forEach(updatedIndividual => {
    if (
      tree.userIndividual &&
      tree.userIndividual.id === updatedIndividual.id
    ) {
      // console.debug(
      //   `authSlice.reducer.updateTreeUserIndividual(): updating tree.userIndividual...`,
      //   updatedIndividual
      // )
      tree.userIndividual = updatedIndividual
      updateUserAttributesFromTree(state, treeSlug)
    } else {
      // console.debug(
      //   `authSlice.reducer.updateTreeUserIndividual(): tree(slug '${treeSlug}').userIndividual has a different id (${tree.userIndividual.id}) to the one in the payload (${updatedIndividual.id}), not updating.`
      // )
    }
  })
}

const deleteTreeFromUser = (state, treeSlug) => {
  let trees = state.user.trees.filter(t => t.slug !== treeSlug)
  state.user.trees = trees
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTreeSlug: (state, action) => {
      if (state.user) {
        updateUserAttributesFromTree(state, action.payload)
      }
    },
    setTreeSlugAndFail: (state, action) => {
      if (state.user) {
        updateUserAttributesFromTree(state, action.payload)
      }

      //raise error if user not allowed to access tree
      if (
        !state.user ||
        (action.payload && state.authorisedTreeSlug !== action.payload)
      ) {
        //if this is the user's default tree set a different message so the caller can redirect to the tree list page
        if (state.user && state.user.lastViewedTree.slug === action.payload) {
          throw Error('Access denied to default tree')
        } else {
          throw Error('Access denied')
        }
      }
    },
    setUser: (state, action) => {
      state.user = action.payload
      updateUserAttributesFromTree(state, state.authorisedTreeSlug)
    },
    setTreeLoading: (state, action) => {
      state.treeLoading = action.payload
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.user = payload
      //tracking the first return on firstTimeUse so we know the user is a firstTimer
      if (state.firstTimeUser === undefined) {
        state.firstTimeUser = state.user.firstTimeUser
      }
      updateUserAttributesFromTree(state, state.authorisedTreeSlug)
    },
    [initialiseUser.fulfilled]: (state, { payload }) => {
      state.user = payload
      //tracking the first return on firstTimeUse so we know the user is a firstTimer
      if (state.firstTimeUser === undefined) {
        state.firstTimeUser = state.user.firstTimeUser
      }
      updateUserAttributesFromTree(state, payload?.lastViewedTree?.slug)
    },
    [respondToInvite.fulfilled]: (state, { payload }) => {
      state.invitedUser = payload
    },
    [patchProfileAndState.fulfilled]: (state, { meta, payload }) => {
      // API call doesn't return a response, as it returned ok
      // just copy the changed attributes to the user in state
      if (state?.user) {
        Object.assign(state.user, meta.arg)
      }
    },
    [deleteTree.fulfilled]: (state, { meta }) => {
      deleteTreeFromUser(state, meta.arg)
    },
    [fetchNotifications.fulfilled]: (state, { meta, payload }) => {
      const treeSlug = meta.arg.treeSlug
      const notifications = payload?.results
      if (treeSlug && notifications) {
        // set tree.gedcomImportInProgress
        const tree = state?.user?.trees?.find(t => t.slug === treeSlug)
        if (tree) {
          const reversedNotifications = _.reverse([...notifications])
          const mostRecentUploadNotification = reversedNotifications.find(
            n => n.systemEventCode === 'GENEALOGY_FILE_UPLOAD_STATE_UPDATED'
          )
          if (mostRecentUploadNotification) {
            const inProgress = !['PHASE_2_COMPLETE', 'PHASE_2_FAILED'].includes(
              mostRecentUploadNotification.instanceTitle
            )
            if (inProgress) {
              if (!tree.gedcomImportInProgress) {
                tree.gedcomImportInProgress = inProgress
              }
            } else {
              if (tree.gedcomImportInProgress) {
                tree.gedcomImportInProgress = inProgress
              }
            }
          }
        }
      }
    },
    [downloadMediaFromLatestGedcomForTree.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      const tree = state?.user?.trees?.find(t => t.slug === meta.arg)
      if (tree) {
        // disables the menu item so they don't try to run it twice
        tree.gedcomImportInProgress = true
      }
    },
    [updateTree.fulfilled]: (state, { payload }) => {
      let alteredSlug
      state.user.trees = state.user.trees.map(tree => {
        if (tree.id === payload.id) {
          alteredSlug = payload.slug
          Object.assign(tree, payload)
        }
        return tree
      })
      updateUserAttributesFromTree(state, alteredSlug)
    },
    [addMother.fulfilled]: (state, { payload }) => {
      // console.debug(
      //   `authSlice.extraReducers[addMother.fulfilled]: called with payload:`,
      //   payload
      // )
      updateTreeUserIndividual(state, payload)
    },
    [addFather.fulfilled]: (state, { payload }) => {
      updateTreeUserIndividual(state, payload)
    },
    [addPartner.fulfilled]: (state, { payload }) => {
      // console.debug(
      //   `authSlice.reducer.addPartner.fulfilled(): called with payload of length: ${
      //     payload.length
      //   }, isDraft(payload): ${isDraft(payload)}, payload:`,
      //   [...payload]
      // )
      updateTreeUserIndividual(state, payload)
    },
    [addChild.fulfilled]: (state, { payload }) => {
      updateTreeUserIndividual(state, payload)
    },
    [updateIndividual.fulfilled]: (state, { payload }) => {
      //in case of name change
      // console.debug(`authSlice.extraReducers[updateIndividual.fulfilled]: called with payload:`, payload)
      updateTreeUserIndividual(state, [payload])
    },
  },
})

export const { setTreeSlug, setTreeSlugAndFail, setUser, setTreeLoading } =
  slice.actions

export const selectUser = state => state[SLICE_NAME].user
export const selectUserIsFirstTimeUser = state =>
  !!state[SLICE_NAME].firstTimeUser
export const selectLoggedInUserIsAdminOnCurrentTree = state =>
  !!state[SLICE_NAME].user?.currentTree?.currentUserIsAdmin
export const selectCurrentTree = state => state[SLICE_NAME].user?.currentTree

export const selectIsBlogTree = state =>
  state[SLICE_NAME].user?.currentTree?.treeType === BLOG_TREE

export const selectInvitedUser = state => state[SLICE_NAME].invitedUser
export const selectUserIndividualOnTree = state =>
  state[SLICE_NAME].user?.individualOnTree
export const selectUserHomeIndividual = state =>
  state[SLICE_NAME].user?.homeIndividual
export const selectAuthorisedTreeSlug = state =>
  state[SLICE_NAME].authorisedTreeSlug
export const selectTreeLoading = state => state[SLICE_NAME].treeLoading

export const selectRootTrees = state =>
  state[SLICE_NAME].user.trees.filter(t => t.state === 'ROOT')
export const selectDraftTrees = state =>
  state[SLICE_NAME].user.trees.filter(t => t.state === 'RESOLVING')
export const selectNonDemoTrees = state =>
  state[SLICE_NAME].user.trees.filter(t => t.treeType !== 'DEMO')
export const selectTreeBySlug = slug => state =>
  state[SLICE_NAME]?.user?.trees?.find(t => t.slug === slug)

export default slice.reducer
