import React from 'react'
import { INSTANCE_TYPE_LOCATION } from 'src/modules/app/links'

import HomePageList from './HomePageList'

import { fetchHomePagesLocations, selectHomePagesLocations } from './homeSlice'

const Locations = () => {
  return (
    <HomePageList
      type={INSTANCE_TYPE_LOCATION}
      action={fetchHomePagesLocations}
      selector={selectHomePagesLocations}
    />
  )
}

export default Locations
