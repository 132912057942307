export function slugFromName(name) {
  let slug = name?.toLowerCase()
  slug = slug?.replace(/[^\w\s-]/g, '')
  slug = slug?.replace(/\s+/g, '-')
  slug = slug?.replace(/-$/, '')

  return slug
}

export const stripGedFromTreeName = name => {
  return name.substr(0, name.length - 4)
}
