import React from 'react'
import { Box, styled } from '@mui/material'
import {
  MediaTypeOverlay,
  PDFOverlayIcon,
  YouTubeOverlayIcon,
} from '../photo/PhotoGallery'
import { MEDIA_TYPE_PDF } from '../photo'

const Root = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  '& .zoomImage': {
    transition: 'transform 0.5s',
    transformOrigin: 'center',
  },
  '&:hover .zoomImage': {
    transform: 'scale(1.02)',
  },
  '& .zoomImageTranslated': {
    transition: 'transform 0.5s',
    transformOrigin: 'center left',
  },
  '&:hover .zoomImageTranslated': {
    transform: 'scale(1.02) translateX(-50%)',
  },
})

const maxHeight = 425 // originally was 850 changed to 850 * 0.50

const BlurredBackground = ({ url }) => (
  <Box
    sx={{
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${url})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
  >
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(8px)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    ></Box>
  </Box>
)

const StyledImage = styled('img')(({ theme }) => ({
  position: 'relative',
  zIndex: 3,
  display: 'block',
}))

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.backgroundGrey.main,
}))

const ContentPreview = ({ children, columnWidth, media, minHeight = 350 }) => {
  if (!media) {
    return <StyledBox>{children}</StyledBox>
  }

  const isYouTubeLink = media?.url?.includes('youtube')
  const isPDF = media?.type === MEDIA_TYPE_PDF
  const scaleX = columnWidth / media.width
  const scaleY = Math.min(maxHeight / media.height, scaleX)
  const scale = Math.min(scaleX, scaleY)
  const height = media.height * scale
  const width = media.width * scale

  return (
    <Root>
      {height < minHeight ? (
        <Box sx={{ width: '100%' }}>
          <BlurredBackground url={media.url} />
          <Box sx={{ position: 'relative' }}>
            <StyledImage
              src={media.url}
              sx={{
                height: height,
                width: columnWidth,
              }}
              className="zoomImage"
            />
            {isYouTubeLink && (
              <MediaTypeOverlay>
                <YouTubeOverlayIcon editable={false} />
              </MediaTypeOverlay>
            )}
            {isPDF && (
              <MediaTypeOverlay>
                <PDFOverlayIcon />
              </MediaTypeOverlay>
            )}
          </Box>
          <Box
            sx={{
              paddingLeft: 2,
              paddingTop: 2,
              paddingBottom: 2,
              paddingRight: 3,
              background:
                'linear-gradient(180deg, rgba(247,246,248,0.8) 0%, rgba(247,246,248,1) 100%)',
              position: 'relative',
              zIndex: 3,
              marginRight: '-1px',
            }}
          >
            {children}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height,
              width: columnWidth,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: '50%',
                width,
                transform: 'translateX(-50%)',
                bottom: 0,
                backgroundImage: `url(${media.url})`,
                backgroundPosition: 'top center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              className="zoomImageTranslated"
            ></Box>
            {(width < columnWidth || height < minHeight) && (
              <BlurredBackground url={media.url} />
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: 2,
              background:
                'linear-gradient(180deg, rgba(247,246,248,0.8) 0%, rgba(247,246,248,1) 100%)',
              zIndex: 3,
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Root>
  )
}

export default ContentPreview
