import { useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CodeIcon from '@mui/icons-material/Code'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS'

import { Divider, Popover } from '@mui/material'

import { MenuIconButton } from './MenuIconButton'
import { TextAlignOptions } from './TextAlignOptions'
import { InlineFormatOptions } from './InlineFormatOptions'
import { EmojiOptions } from './EmojiOptions'
import { IndentationOptions } from './IndentationOptions'
import { HistoryOptions } from './HistoryOptions'
import { ToolbarContainer } from 'src/modules/ui/ToolbarContainer'

const INLINE_STYLE_MONOSPACE = 'CODE'
const INLINE_STYLE_STRIKETHROUGH = 'STRIKETHROUGH'

export const EditorToolbarDropdown = ({ editorState, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MenuIconButton onClick={handleClick}>
        <ArrowDropDownIcon fontSize="small" />
      </MenuIconButton>

      <Popover
        open={open}
        disableAutoFocus={true}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <ToolbarContainer elevation={1}>
          <Divider flexItem orientation="vertical" sx={{ my: 1 }} />

          <TextAlignOptions editorState={editorState} onChange={onChange} />
          <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
          <InlineFormatOptions
            editorState={editorState}
            onChange={onChange}
            formats={[
              [INLINE_STYLE_MONOSPACE, CodeIcon],
              [INLINE_STYLE_STRIKETHROUGH, StrikethroughSIcon],
            ]}
          />
          <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
          <EmojiOptions editorState={editorState} onChange={onChange} />
          <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
          <IndentationOptions editorState={editorState} onChange={onChange} />
          <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
          <HistoryOptions editorState={editorState} onChange={onChange} />
        </ToolbarContainer>
      </Popover>
    </>
  )
}
