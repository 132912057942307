import React, { useState } from 'react'
import { Box, Dialog, styled } from '@mui/material'
import { useActionDispatcher } from '../app'
import { searchContent, selectContent } from '../writeArticle/writeArticleSlice'
import { useSelector } from 'react-redux'
import { INSTANCE_TYPE_ARTICLE } from '../app/links'
import SearchLinkType from '../ui/editor/SearchLinkType'
import { DialogTitle } from '../ui'

const SearchLinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  flexGrow: 1,
  minHeight: 200,
  minWidth: 425,
}))

const SearchArticleDialog = ({ trigger, onSelectArticle }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatchSearchContent = useActionDispatcher(searchContent)
  const content = useSelector(selectContent)

  const searchArticles = query =>
    dispatchSearchContent({
      instanceType: INSTANCE_TYPE_ARTICLE,
      query,
    })

  const linkType = {
    id: INSTANCE_TYPE_ARTICLE,
    label: 'Article',
    data: content,
    fetchData: searchArticles,
  }

  const performSearch = value => {
    if (linkType && linkType.fetchData) {
      linkType.fetchData(value)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleShowModal = () => {
    performSearch('')
    setModalOpen(true)
  }

  return (
    <>
      {trigger({ onClick: handleShowModal })}

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth={false}
        scroll="paper"
      >
        <SearchLinkContainer>
          <DialogTitle onClose={handleCloseModal}>
            Select Article to Pin
          </DialogTitle>
          <SearchLinkType
            linkType={linkType}
            showBack={false}
            onSearch={performSearch}
            onSelectLink={onSelectArticle}
          />
        </SearchLinkContainer>
      </Dialog>
    </>
  )
}

export default SearchArticleDialog
