import React from 'react'
import { useField } from 'formik'
import { FormHelperText, Input } from '@mui/material'
import { styled } from '@mui/material'

const StyledInput = styled(Input)(({ theme }) => ({
  ...theme.typography.subtitle2,
}))

const FormikInput = ({ hideValidation, ...props }) => {
  const [field, meta] = useField(props)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  return (
    <>
      <StyledInput
        disableUnderline={true}
        {...field}
        {...props}
        error={hasError}
      />
      {hasError && !hideValidation && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </>
  )
}

export default FormikInput
