import React, { useState, useMemo, useEffect } from 'react'

import TypeAheadBase from '../informationRequest/TypeAheadBase'

const TypeAheadAdmin = ({ otherUsers }) => {
  const alreadyInvitedUsers = useMemo(
    () => otherUsers.filter(u => u.user),
    [otherUsers]
  )
  const notYetInvitedUsers = useMemo(
    () => otherUsers.filter(u => !u.user),
    [otherUsers]
  )
  const [value, setValue] = useState(alreadyInvitedUsers)

  useEffect(() => {
    setValue(alreadyInvitedUsers)
  }, [alreadyInvitedUsers])

  return (
    <TypeAheadBase
      notYetInvitedUsers={notYetInvitedUsers}
      value={value}
      setValue={setValue}
    />
  )
}

export default TypeAheadAdmin
