import React, { useState, useRef } from 'react'
import { ACTION_ALL_ACCESS, ACTION_INVITE_USERS } from '../app/appConstants'
import { makeStyles } from '@mui/styles'
import {
  FormControl,
  MenuItem,
  TextField as Input,
  Select,
  DialogActions,
} from '@mui/material'

import { SimpleDialog } from 'src/modules/ui'
import { Formik, Form } from 'formik'

import { useSelector } from 'react-redux'

import SendIcon from '@mui/icons-material/Send'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

import InfoIcon from '@mui/icons-material/Info'

import clsx from 'clsx'

import { useActionDispatcher } from 'src/modules/app/hooks'
import { Button, ConfirmDialog, Typography, IconButton } from 'src/modules/ui'

import {
  disableUser,
  enableUser,
  inviteUser,
  resendInvite,
  cancelInvite,
  updateUser,
  fetchAllUsersAndAliveIndividuals,
  selectPermissionsInfo,
} from './accountAdminSlice'
import EditButton from '../ui/EditButton'
// import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'
import {
  formatIndividualName,
  formatIndividualTagsAndSearch,
} from '../ui/individualUtils'
import { selectAuthorisedTreeSlug } from '../auth/authSlice'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  disabled: {
    color: 'gray',
    '& *': {
      color: 'gray',
    },
  },
  invited: {
    // this is set when the 'resend invite' and 'cancel invite' buttons will be shown below the email.
    // They are excluded from the flexbox layout as as they break the email address' vertical alignment with the other items on the row.
    // So add bottom padding to allow for them.
    paddingBottom: theme.spacing(4),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '30%',
  },
  emailOuterContainer: {
    display: 'flex',
    width: '30%',
  },
  emailOuterContainerNoUser: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '70%',
  },
  emailTextContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    textWrap: true,
    '& *': {
      padding: '1px',
    },
  },
  roleNameContainer: {
    width: '8%',
  },
  roleHelpButtonContainer: {},
  changeRoleButtonContainer: {
    width: '11%',
  },
  userStateContainer: {
    display: 'flex',
    width: '8%',
  },
  deleteContainer: {
    width: '5%',
  },
  button: {
    marginRight: theme.spacing(1 / 2),
  },
  checkAndClearContainer: {
    display: 'flex',
    '& *': {
      padding: '2px 0px',
    },
  },
  editRoleDialogSelectAndHelpContainer: {
    display: 'flex',
    gap: 16,
  },
  inputLabel: {
    '&.focused': {
      color: 'purple',
    },
    '&.shrink': {
      backgroundColor: 'grey',
    },
  },
  deleteText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'red',
  },
}))

const roleHelpTextStyles = makeStyles(theme => ({
  root: {
    fontSize: 14,
  },
  listContainer: {
    '& div': {
      display: 'flex',
      gap: 4,
      '& div': {
        whiteSpace: 'pre-line',
      },
    },
  },
}))

export const OneRoleHelpText = ({ roleId }) => {
  const classes = roleHelpTextStyles()

  const permissionsInfo = useSelector(selectPermissionsInfo)

  if (!roleId) {
    return <></>
  }

  if (permissionsInfo.loading !== false) {
    return <></>
  }

  // permissionsInfo.results.roles is an array of objects so the items in it need to be searched
  const role = permissionsInfo.results.roles.filter(r => r.id === roleId)[0]

  if (!role) {
    console.error(`OneRoleHelpText(): could not find role with id '${roleId}'`)
    return <></>
  }

  const permissionNums = role.perms

  // unwrap all the permissions to an array and add a 'included' property
  const allPermissions = Object.entries(
    permissionsInfo.results.permissions
  ).map(([permNum, perm]) => {
    const included = permissionNums.includes(parseInt(permNum))
    return { included: included, ...perm }
  })

  return (
    <div className={classes.root}>
      <div style={{ marginBottom: 2 }}>Provides:</div>
      <div className={classes.listContainer}>
        {allPermissions
          .filter(p => p.included)
          .map((p, idx) => (
            <div key={`perm-included-${idx}`}>
              <div>✅</div>
              <div>{p.desc}</div>
            </div>
          ))}
      </div>
      <div style={{ marginTop: 8, marginBottom: 2 }}>Does not provide:</div>
      <div className={classes.listContainer}>
        {allPermissions
          .filter(p => !p.included)
          .map((p, idx) => (
            <div key={`perm-excluded-${idx}`}>
              <div>❌</div>
              <div>{p.desc}</div>
            </div>
          ))}
      </div>
    </div>
  )
}

const IndividualLine = ({
  individual,
  handleDelete,
  emailTextFieldPlaceholderText,
  allowEditEmail,
  removeNameField = false,
  //availableRoles: the roles this user has permission to set
  availableRoles, // = {id: { id: 'sdf3qdx', name: 'SuperAdmin', description: 'all power' }}
  showRolesHelpDialog,
}) => {
  const classes = useStyles()
  const dispatchDisableUser = useActionDispatcher(disableUser)
  const dispatchEnableUser = useActionDispatcher(enableUser)
  const dispatchInviteUser = useActionDispatcher(inviteUser)
  const dispatchResendInvite = useActionDispatcher(resendInvite)
  const dispatchCancelInvite = useActionDispatcher(cancelInvite)
  const dispatchUpdateUser = useActionDispatcher(updateUser)
  const dispatchFetchUsers = useActionDispatcher(
    fetchAllUsersAndAliveIndividuals
  )
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const handleRoleFormikSubmit = async params => {
    const newRoleIdOnTree = params.selectedRoleId
    const updateUserRes = await dispatchUpdateUser(
      {
        id: individual.user?.id,
        params: { setRoleIdOnTree: newRoleIdOnTree },
      },
      {
        successNotification: `User's role updated`,
        errorNotification: `Could not update role`,
      }
    )
    if (updateUserRes.meta.requestStatus === 'fulfilled') {
      if (params.closeDialog) {
        params.closeDialog()
      }
      setIsEditing(false)
    } else {
      console.error(
        `IndividualLine.handleRoleFormikSubmit(): dispatchUpdateUser did not fulfil correctly - updateUserRes.meta.requestStatus: '${updateUserRes.meta.requestStatus}'`,
        updateUserRes
      )
    }
  }
  // email
  const [isEditing, setIsEditing] = useState(false)
  const startEditing = () => {
    setUserEmail(individual.user.email)
    setIsEditing(true)
  }

  const stopEditing = () => {
    setIsEditing(false)
    setUserEmail(individual.user?.email)
  }
  const [userEmail, setUserEmail] = useState(individual.user?.email)

  const handleEmailChange = event => {
    setUserEmail(event.target.value)
  }

  // disable/enable
  const handleDisableUser = () =>
    dispatchDisableUser(individual.user?.id, {
      successNotification: 'User disabled',
    })

  const handleEnableUser = () =>
    dispatchEnableUser(individual.user?.id, {
      successNotification: 'User enabled',
    })

  const handleInviteUser = async () => {
    try {
      await dispatchInviteUser(
        { individual_id: individual.id, email: userEmail },
        {
          successNotification: 'User invited',
          errorNotification: err => err.data && err.data.email,
        }
      ).unwrap()
      stopEditing()
      if (!individual || !individual.id) {
        // it's a not-on-tree user, clear the field so another email can be added, and refresh the list of existing invitees
        setUserEmail(null)
      }
      dispatchFetchUsers({ treeSlug })
    } catch (err) {}
  }

  const handleResendInvite = () =>
    dispatchResendInvite(
      { id: individual.user?.id },
      {
        successNotification: 'Invite has been resent',
        errorNotification: err => err.data && err.data.email,
      }
    )

  const handleCancelInvite = async () => {
    await dispatchCancelInvite(
      { id: individual.user?.id },
      {
        successNotification: 'Invite has been cancelled',
        errorNotification: err => err.data && err.data.email,
      }
    )
    dispatchFetchUsers()
  }

  const toggleDisabledUser = e => {
    if (e.target.value === 'ACTIVE') {
      handleEnableUser()
    } else {
      handleDisableUser()
    }
  }

  const userIsDisabled =
    individual.user?.state === 'DISABLED' ||
    individual.user?.treeState === 'DISABLED'

  var allowEditRole = false
  if (individual && individual.user && availableRoles) {
    allowEditRole =
      !userIsDisabled &&
      (!individual.user.roleIdOnTree ||
        (availableRoles[individual.user.roleIdOnTree] &&
          availableRoles[individual.user.roleIdOnTree].manageable))
  }

  const getDisplayName = (individual, prefix) => {
    var res = ''
    if (individual) {
      if (individual.givenName) {
        res += individual.givenName + ' '
      }
      if (individual.surname) {
        res += individual.surname + ' '
      }
    }

    if (prefix && res !== '') {
      res = prefix + res
    }
    return res
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.disabled]:
          individual.user?.state === 'DISABLED' ||
          individual.user?.treeState === 'DISABLED',
        [classes.invited]: individual.user?.state === 'INVITED',
      })}
    >
      {!removeNameField && (
        <div className={classes.nameContainer}>
          <Typography variant="body1">
            <>
              {individual.id ? (
                <>
                  {formatIndividualTagsAndSearch(individual)}
                  {individual.relationship && <> ({individual.relationship})</>}
                </>
              ) : (
                '(user not entered name yet)'
              )}
            </>
          </Typography>
        </div>
      )}

      {/* email */}
      {!individual.user ? (
        <div className={classes.emailOuterContainer}>
          <NoUser
            individual={individual}
            userEmail={userEmail}
            handleEmailChange={handleEmailChange}
            handleInviteUser={handleInviteUser}
            setUserEmail={setUserEmail}
            emailTextFieldPlaceholderText={emailTextFieldPlaceholderText}
          />
        </div>
      ) : (
        <>
          <div className={classes.emailOuterContainer}>
            {individual.user.state === 'INVITED' ? (
              <InvitedUser
                individual={individual}
                userEmail={userEmail}
                handleEmailChange={handleEmailChange}
                handleEmailSubmit={handleInviteUser}
                stopEditing={stopEditing}
                setUserEmail={setUserEmail}
                isEditing={isEditing}
                startEditing={startEditing}
                handleResendInvite={handleResendInvite}
                handleCancelInvite={handleCancelInvite}
                allowEditEmail={allowEditEmail}
              />
            ) : individual.user.state === 'DISABLED' ? (
              <ActiveUser
                individual={individual}
                userEmail={userEmail}
                handleEmailChange={handleEmailChange}
                handleEmailSubmit={handleInviteUser}
                stopEditing={stopEditing}
                setUserEmail={setUserEmail}
                isEditing={isEditing}
                startEditing={startEditing}
              />
            ) : (
              <DisplayEmail individual={individual} />
            )}
          </div>

          {/* current role */}
          <div className={classes.roleNameContainer}>
            <Typography variant="body1">
              {individual.user.roleIdOnTree &&
              availableRoles[individual.user.roleIdOnTree]
                ? availableRoles[individual.user.roleIdOnTree].name
                : ''}
            </Typography>
          </div>

          {/* roles help/info button */}
          <div className={classes.roleHelpButtonContainer}>
            <IconButton
              permissionAction={ACTION_INVITE_USERS}
              disabled={userIsDisabled}
              style={{
                visibility: showRolesHelpDialog ? 'visible' : 'hidden',
                background: 'none',
                padding: 0,
                lineHeight: 0,
              }}
              handleSubmit={e => {
                showRolesHelpDialog()
              }}
            >
              <InfoIcon color="primary" />
            </IconButton>
          </div>

          {/* change role button */}
          <div className={classes.changeRoleButtonContainer}>
            {/* 'change role' button opens dialog */}
            <SimpleDialog
              trigger={triggerProps => (
                <Button
                  permissionAction={ACTION_INVITE_USERS}
                  {...triggerProps}
                  className={classes.button}
                  size="small"
                  color={allowEditRole ? 'secondary' : 'secondaryGrey'}
                  variant="outlined"
                  disabled={!allowEditRole}
                >
                  Change role
                </Button>
              )}
              title={`Edit role ${getDisplayName(individual, ' for ')}`}
              showCloseButton={true}
            >
              {paramsFromSimpleDialog => (
                <>
                  <Formik
                    initialValues={{
                      selectedRoleId: individual.user.roleIdOnTree,
                    }}
                    onSubmit={params =>
                      handleRoleFormikSubmit({
                        ...params,
                        closeDialog: paramsFromSimpleDialog.closeDialog,
                      })
                    }
                  >
                    {formik => (
                      <div>
                        <div
                          className={
                            classes.editRoleDialogSelectAndHelpContainer
                          }
                        >
                          <div>
                            <FormControl
                              variant="standard"
                              className={classes.formControl}
                            >
                              <Form>
                                <Select
                                  name="selectedRoleId"
                                  id={`selectRole-${individual.id}`}
                                  value={formik.values.selectedRoleId}
                                  //disabled={roleDropdownDisabled}
                                  onChange={formik.handleChange}
                                >
                                  {Object.values(availableRoles).map(
                                    (role, idx) => (
                                      <MenuItem
                                        key={`select-option-for-role-${role.id}`}
                                        value={role.id}
                                        disabled={!role.manageable}
                                      >
                                        {role.name}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </Form>
                            </FormControl>
                          </div>
                          <OneRoleHelpText
                            roleId={formik.values.selectedRoleId}
                          />
                        </div>
                        <DialogActions>
                          <Button
                            permissionAction={ACTION_INVITE_USERS}
                            color="primary"
                            //disabled={!canSubmit}
                            onClick={formik.handleSubmit}
                          >
                            Update
                          </Button>
                        </DialogActions>
                      </div>
                    )}
                  </Formik>
                </>
              )}
            </SimpleDialog>
          </div>

          {/* user state, possible dropdown depending on caller permissions */}
          <div className={classes.userStateContainer}>
            {individual.user &&
              (individual.user.state !== 'INVITED' ? (
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id={`selectUserState-${individual.id}`}
                    value={individual.user.treeState}
                    onChange={toggleDisabledUser}
                    defaultValue="ACTIVE"
                  >
                    <MenuItem value={'ACTIVE'}>Active</MenuItem>
                    <MenuItem value={'DISABLED'}>Disabled</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Typography variant="body1">Invited</Typography>
              ))}
          </div>

          {individual.relationship === 'Other' &&
            !individual.user &&
            handleDelete && (
              <IconButton
                permissionAction={ACTION_INVITE_USERS}
                onClick={() => handleDelete(individual.id)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
        </>
      )}
    </div>
  )
}

const NoUser = ({
  individual,
  userEmail,
  handleEmailChange,
  handleInviteUser,
  emailTextFieldPlaceholderText = 'Add email to invite...',
}) => {
  const classes = useStyles()
  const buttonRef = useRef(null)
  const textRef = useRef(null)
  const individualDisplayName = formatIndividualName(individual) || userEmail
  return (
    <>
      <Input
        className={classes.TextField}
        id={`${individual.id}-no-user`}
        name={`${individual.id}-no-user`}
        placeholder={emailTextFieldPlaceholderText}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            buttonRef.current.click()
          }
        }}
        onChange={handleEmailChange}
        fullWidth={true}
        inputRef={textRef}
        // Need to confuse Google Chrome as it ignores "off".
        autoComplete="do-not-fill-me-in"
      />
      {userEmail && userEmail !== '' ? (
        <ConfirmDialog
          onConfirm={handleInviteUser}
          trigger={props => (
            <IconButton
              permissionAction={ACTION_INVITE_USERS}
              {...props}
              size="small"
              color="primary"
              input
              ref={buttonRef}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          )}
        >
          <Typography>Invite {individualDisplayName}?</Typography>
        </ConfirmDialog>
      ) : null}
    </>
  )
}

const InvitedUser = ({
  individual,
  userEmail,
  handleEmailChange,
  handleEmailSubmit,
  stopEditing,
  setUserEmail,
  isEditing,
  startEditing,
  handleResendInvite,
  handleCancelInvite,
  allowEditEmail = true,
}) => {
  const classes = useStyles()
  return isEditing ? (
    <EditEmail
      individual={individual}
      userEmail={userEmail}
      handleEmailChange={handleEmailChange}
      handleEmailSubmit={handleEmailSubmit}
      stopEditing={stopEditing}
      setUserEmail={setUserEmail}
      inviteResentNotification={true}
    />
  ) : (
    <div>
      {/* this div needed to allocate layout space for the email text */}
      <DisplayEmail individual={individual} startEditing={startEditing} />
      <div style={{ position: 'absolute' }}>
        {/* position: 'absolute' makes this box be ignored by the flexbox positioning, so the
          email address is still aligned in the vertical-center as if these buttons didn't exist
          so it still lines up with the other fields on the same row */}
        <ConfirmDialog
          onConfirm={handleResendInvite}
          trigger={props => (
            <Button
              permissionAction={ACTION_INVITE_USERS}
              {...props}
              className={classes.button}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Resend invite
            </Button>
          )}
        >
          <Typography>
            Resend invite to {individual.givenName} {individual.surname}{' '}
            {individual.user.email}?
          </Typography>
        </ConfirmDialog>
        <ConfirmDialog
          onConfirm={handleCancelInvite}
          trigger={props => (
            <Button
              permissionAction={ACTION_INVITE_USERS}
              {...props}
              className={classes.button}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Cancel invite
            </Button>
          )}
        >
          <Typography>
            Cancel invite to {individual.givenName} {individual.surname}{' '}
            {individual.user.email}?
          </Typography>
        </ConfirmDialog>
        {allowEditEmail && (
          <Button
            permissionAction={ACTION_INVITE_USERS}
            className={classes.button}
            size="small"
            color="secondary"
            variant="outlined"
            onClick={startEditing}
          >
            Edit
          </Button>
        )}
      </div>
    </div>
  )
}

const ActiveUser = ({
  individual,
  userEmail,
  handleEmailChange,
  handleEmailSubmit,
  stopEditing,
  setUserEmail,
  isEditing,
  startEditing,
}) => {
  return isEditing ? (
    <EditEmail
      individual={individual}
      userEmail={userEmail}
      handleEmailChange={handleEmailChange}
      handleEmailSubmit={handleEmailSubmit}
      stopEditing={stopEditing}
      setUserEmail={setUserEmail}
    />
  ) : (
    <>
      <DisplayEmail individual={individual} startEditing={startEditing} />
      <EditButton permissionAction={ACTION_ALL_ACCESS} onClick={startEditing} />
    </>
  )
}

const DisplayEmail = ({ individual, startEditing }) => {
  const classes = useStyles()
  return (
    <div className={classes.emailTextContainer}>
      <Typography
        className={classes.emailText}
        variant="body1"
        id={`${individual.id}-display-email`}
      >
        {individual.user.email}
      </Typography>
    </div>
  )
}

const EditEmail = ({
  individual,
  userEmail,
  handleEmailChange,
  handleEmailSubmit,
  stopEditing,
  inviteResentNotification,
}) => {
  const classes = useStyles()
  const buttonRef = useRef(null)
  return (
    <>
      <Input
        id={`${individual.id}-edit`}
        name={`${individual.id}-edit`}
        fullWidth={true}
        value={userEmail}
        onChange={handleEmailChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            buttonRef.current.click()
          }
        }}
        autoComplete="do-not-fill-me-in"
      />
      <div className={classes.checkAndClearContainer}>
        <IconButton
          permissionAction={ACTION_INVITE_USERS}
          onClick={() => handleEmailSubmit()}
          disabled={individual.user?.email === userEmail}
          ref={buttonRef}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton
          permissionAction={ACTION_INVITE_USERS}
          onClick={stopEditing}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
    </>
  )
}

export default IndividualLine
