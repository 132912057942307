import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import CommentIcon from '@mui/icons-material/Comment'
import { Badge, Box, Divider, Fab, Paper } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message'
import { useDispatch, useSelector } from 'react-redux'
import pluralize from 'pluralize'
import Clamp from 'react-multiline-clamp'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useActionDispatcher } from 'src/modules/app'
import {
  generateLinkForObject,
  INSTANCE_TYPE_INFORMATION_REQUEST,
} from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import ProfilePicture from 'src/modules/content/ProfilePicture'
import {
  Button,
  Link,
  LoadingIndicator,
  ReadableDateTime,
  Typography,
} from 'src/modules/ui'

import InformationRequestDialog from './InformationRequestDialog'
import {
  fetchInformationRequests,
  selectInformationRequests,
  selectMediaPageOpen,
  selectLinkablePageOpen,
  getUnseenInfoRequests,
  selectUnseenInfoRequests,
  createSeenInfoRequest,
  clearUnseenInfoRequests,
} from './informationRequestSlice'
import { ACTION_CREATE } from '../app/appConstants'
import { usePermissions } from '../auth/authHooks'

const bottom = 20
const right = 20
const zIndex = 1200 // mui dialogs are at z-index 1300
const fabSx = {
  position: 'fixed',
  right,
  bottom,
  zIndex,
}

const AskFamilyButton = ({ askFamilyRequestCount }) => {
  const informationRequests = useSelector(selectInformationRequests)
  const unseenInfoRequests = useSelector(selectUnseenInfoRequests)
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const dispatchFetchInformationRequests = useActionDispatcher(
    fetchInformationRequests
  )
  const dispatchGetUnseenInfoRequests = useActionDispatcher(
    getUnseenInfoRequests
  )
  const dispatchCreateSeenInfoRequest = useActionDispatcher(
    createSeenInfoRequest
  )

  const mediaOpen = useSelector(selectMediaPageOpen)
  const linkTarget = useSelector(selectLinkablePageOpen)

  const filterArg = useMemo(() => {
    let arg
    if (mediaOpen) {
      arg = { media: mediaOpen.id }
    } else if (linkTarget) {
      arg = { target: linkTarget.target }
    }
    return arg
  }, [linkTarget, mediaOpen])

  const fetchRequests = useCallback(
    (page = 0) => {
      if (filterArg) {
        dispatchFetchInformationRequests({ page, ...filterArg })
        if (page === 0) {
          dispatchGetUnseenInfoRequests(filterArg)
        }
      }
    },
    [dispatchFetchInformationRequests, dispatchGetUnseenInfoRequests, filterArg]
  )

  useEffect(() => {
    fetchRequests()
    return () => dispatch(clearUnseenInfoRequests())
  }, [dispatch, fetchRequests])

  const handleFetchMore = useCallback(() => {
    fetchRequests(informationRequests.page + 1)
  }, [fetchRequests, informationRequests.page])

  const markAsSeenAndRefetch = useCallback(
    async filterArg => {
      await dispatchCreateSeenInfoRequest(filterArg)
      dispatchGetUnseenInfoRequests(filterArg)
    },
    [dispatchCreateSeenInfoRequest, dispatchGetUnseenInfoRequests]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
    if (filterArg) {
      markAsSeenAndRefetch(filterArg)
    }
  }, [markAsSeenAndRefetch, filterArg])

  const handleClick = useCallback(event => {
    setOpen(true)
  }, [])

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
    setOpen(false)
  }, [])
  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const informationRequestsWithUnread = useMemo(() => {
    if (!informationRequests || !unseenInfoRequests) {
      return
    }
    const resultsWithSeenFlags = informationRequests.results.map(res => {
      const unread = unseenInfoRequests.unseenIds.includes(res.id)
      return { ...res, unread: unread }
    })
    return { ...informationRequests, results: resultsWithSeenFlags }
  }, [informationRequests, unseenInfoRequests])

  return (
    <>
      <InformationRequestDialog
        initialMedia={mediaOpen ? [mediaOpen] : undefined}
        linkTarget={linkTarget}
        onClose={handleCloseDialog}
        onInfoRequestSent={fetchRequests}
        open={dialogOpen}
      />
      {open && (
        <AskFamilyMenu
          typeDisplay={
            mediaOpen
              ? 'photo'
              : linkTarget
              ? linkTarget.instanceType
              : undefined
          }
          informationRequests={
            linkTarget || mediaOpen ? informationRequestsWithUnread : undefined
          }
          onClickOpen={handleOpenDialog}
          onClickClose={handleClose}
          onFetchMore={handleFetchMore}
          status={dispatchFetchInformationRequests.status}
        />
      )}
      <AskFamilyFab
        askFamilyRequestCount={unseenInfoRequests?.unseenCount}
        open={open}
        onClickClose={handleClose}
        onClickOpenDialog={handleClick}
      />
    </>
  )
}

const AskFamilyMenu = ({
  informationRequests,
  onClickOpen,
  onClickClose,
  onFetchMore,
  status,
  typeDisplay,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        border: '3px solid',
        borderColor: 'primary.main',
        position: 'fixed',
        bottom: bottom + 60,
        right,
        zIndex,
        maxWidth: 400,
      }}
    >
      <Box>
        <Box sx={{ padding: 2 }}>
          <Typography color="primary" sx={{ mb: 2 }}>
            Need help with something{!!typeDisplay && ` on this ${typeDisplay}`}
            ?<br />
            Ask your family!
          </Typography>
          <Button
            permissionAction={ACTION_CREATE}
            permissionParams={{
              instanceType: INSTANCE_TYPE_INFORMATION_REQUEST,
            }}
            onClick={onClickOpen}
            sx={{
              '&.MuiLoadingButton-root': {
                backgroundColor: 'toolbarGrey.main',
                borderColor: 'primary.main',
                color: 'primary.main',
                borderRadius: 2,
              },
            }}
            startIcon={<MessageIcon />}
          >
            Send Ask Family! Request
          </Button>
        </Box>
        {informationRequests && informationRequests.results.length > 0 && (
          <>
            <Divider sx={{ mx: 2, mb: 2 }} />
            <Typography sx={{ mx: 2 }}>
              Requests about this {typeDisplay}
            </Typography>
            <Box
              id="ask-family"
              sx={{
                maxHeight: '30vh',
                overflowY: 'auto',
                pr: 2,
              }}
            >
              <InfiniteScroll
                dataLength={informationRequests.results.length}
                next={onFetchMore}
                hasMore={!!informationRequests.next}
                scrollableTarget="ask-family"
              >
                {informationRequests.results.map(request => (
                  <InformationRequest
                    {...request}
                    onRequestClicked={onClickClose}
                    key={request.id}
                  />
                ))}
              </InfiniteScroll>
              {status === 'loading' && informationRequests.page > 0 && (
                <LoadingIndicator />
              )}
            </Box>
          </>
        )}
      </Box>
    </Paper>
  )
}

const AskFamilyFab = ({
  askFamilyRequestCount,
  open,
  onClickClose,
  onClickOpenDialog,
}) => {
  const checkPermissions = usePermissions()
  const { hasPermission } = checkPermissions(ACTION_CREATE, {
    instanceType: INSTANCE_TYPE_INFORMATION_REQUEST,
  })

  if (!hasPermission) {
    return null
  }

  return open ? (
    <Fab
      onClick={onClickClose}
      variant="contained"
      size="medium"
      key="fab"
      sx={fabSx}
    >
      <CloseIcon />
    </Fab>
  ) : (
    <Fab
      onClick={onClickOpenDialog}
      variant="extended"
      color="primary"
      key="fab"
      sx={{ ...fabSx, pr: 0.5 }}
    >
      Ask Family
      <Badge color="secondary" badgeContent={askFamilyRequestCount}>
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ml: 1,
            height: 40,
            width: 40,
          }}
        >
          <MessageIcon color="primary" />
        </Box>
      </Badge>
    </Fab>
  )
}

const InformationRequest = ({
  author,
  comments,
  contentBlocks,
  id,
  onRequestClicked,
  publishedAt,
  title,
  unread,
}) => {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const text = useMemo(() => {
    const { textContent } = contentBlocks.find(block => block.type === 'TEXT')
    var divContainer = document.createElement('div')
    divContainer.innerHTML = textContent
    return divContainer.textContent || divContainer.innerText || ''
  }, [contentBlocks])

  return (
    <Link
      sx={{ position: 'relative', pl: 2, display: 'block' }}
      onClick={onRequestClicked}
      to={generateLinkForObject(
        treeSlug,
        INSTANCE_TYPE_INFORMATION_REQUEST,
        id
      )}
      underline="none"
    >
      {unread && <UnreadDot />}
      <Box sx={{ display: 'flex', my: 2 }}>
        <ProfilePicture
          size={40}
          user={{ individualOnTree: author }}
          sx={{ mr: 1 }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="textPrimary">
              {author.givenName} {author.surname}
              &nbsp;
            </Typography>
            <Typography
              sx={{ fontSize: '0.825rem', color: 'secondaryGrey.main' }}
            >
              {'\u2022'}
              &nbsp;
              <ReadableDateTime inputStr={publishedAt} />
            </Typography>
          </Box>
          {!!title && <Typography color="textPrimary">{title}</Typography>}
          <Typography>
            <Clamp lines={2}>{text}</Clamp>
          </Typography>
          <RepliesIndicator comments={comments} />
        </Box>
      </Box>
    </Link>
  )
}

const RepliesIndicator = ({ comments }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }}
  >
    <CommentIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
    <Typography>{pluralize('reply', comments.length, true)}</Typography>
  </Box>
)

const UnreadDot = () => (
  <Typography
    sx={{
      position: 'absolute',
      left: 5,
      top: 4,
      fontSize: '1.25rem',
      color: 'secondary.main',
    }}
  >
    {'\u2022'}
  </Typography>
)

export default AskFamilyButton
