import { Route, Switch, useRouteMatch } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import PublicMediaDetail from './photo/PublicMediaDetail'
import PublicContentPhotos from './content/PublicContentPhotos'
import PublicArticlePhoto from './content/PublicArticlePhoto'
import PublicSubTreeBlock from './content/PublicSubTreeBlock'
import PublicPhotoNavigator from './photo/PublicPhotoNavigator'
import { PATH_SEGMENT_BLOG_POSTS, PUBLIC_BLOG } from '../app/links'
import { ComponentOverrideContext } from 'src/overridable'
import { PublicContext } from './contexts'
import { Content } from './Public'
import HtmlHeader from '../app/HtmlHeader'
import PublicBlogHomePage from './home/PublicBlogHomePage'
import PublicBlogNavBar from './PublicBlogNavBar'
import {
  Box,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { fetchTree, selectTree } from './tree/treeSlice'
import { useActionDispatcher } from '../app'
import { useSelector } from 'react-redux'
import {
  plain,
  industrial,
  rustic,
  weare,
  light,
  dark,
  pioneer,
  misc,
} from '../blog/themes'
import { DEFAULT_ARTICLE_DISPLAY_CONFIG } from '../content/Article'
import PublicPinnedArticle from './page/PublicPinnedArticle'
import { PINNED_ARTICLE_DISPLAY_CONFIG } from '../page/PinnedArticle'
import { getQueryParams } from '../../utils'
import BlogPreviewArticle from './content/BlogPreviewArticle'
import BlogPostArticle from './content/BlogPostArticle'

const RouteInner = styled(Box)(({ theme, maxWidth, themeId }) => ({
  margin: 'auto',
  marginTop: theme.headerHeight,
  padding: theme.spacing(6),
  width: 'fit-content',
  height: 'fit-content',
  minWidth: '60%',
  justifySelf: 'center',
  backgroundColor: theme.palette.grey.main,
  maxWidth: maxWidth,
  boxShadow: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}))

export const BLOG_ARTICLE_DISPLAY_CONFIG = {
  ...DEFAULT_ARTICLE_DISPLAY_CONFIG,
  showBackGround: false,
  showComments: false,
  showTags: false,
  showSources: true,
  maxWidth: 900,
}

const THEME_MAP = {
  plain: plain,
  weare: weare,
  light: light,
  dark: dark,
  pioneer: pioneer,
  industrial: industrial,
  rustic: rustic,
  misc: misc,
}

const PublicBlog = () => {
  const {
    url,
    params: { treeSlug },
  } = useRouteMatch(`/${PUBLIC_BLOG}/:treeSlug/`)
  const mobileBreakpoint = useMediaQuery('(max-width:900px)')

  const queryParams = getQueryParams()

  const dispatchFetchTree = useActionDispatcher(fetchTree)
  const tree = useSelector(selectTree)

  const themeId = tree?.treeSettings?.theme || 'weare0'
  let theme = THEME_MAP['misc'.replace(/[0-9]/g, '')](themeId)
  if (themeId.replace(/[0-9]/g, '') in THEME_MAP) {
    theme = THEME_MAP[themeId.replace(/[0-9]/g, '')](themeId)
  }

  // Fetch tree treeSlug changes
  useEffect(() => {
    dispatchFetchTree({
      treeSlug: queryParams.treeOverride || treeSlug,
    }).unwrap()
  }, [dispatchFetchTree, treeSlug, queryParams.treeOverride])

  const handleThemeImports = useCallback(() => {
    if (themeId.includes('industrial')) {
      return import('../blog/themes/industrial/industrialStylesheet.css')
    } else if (themeId.includes('rustic')) {
      return import('../blog/themes/rustic/rusticStylesheet.css')
    } else {
      return import('../blog/themes/defaultStylesheet.css')
    }
  }, [themeId])

  useEffect(() => {
    handleThemeImports()
  }, [handleThemeImports])

  return (
    <ComponentOverrideContext.Provider
      value={{
        MediaDetail: PublicMediaDetail,
        ContentPhotos: PublicContentPhotos,
        ArticlePhoto: PublicArticlePhoto,
        SubTreeBlock: PublicSubTreeBlock,
        PhotoListNavigatorDialog: PublicPhotoNavigator,
      }}
    >
      <PublicContext.Provider value={{ treeSlug }}>
        <ThemeProvider theme={theme}>
          <PublicBlogNavBar
            url={url}
            title={tree?.name}
            theme={theme}
            shouldShowImage={Boolean(themeId.includes('weare'))}
            backgroundImage={theme?.backgroundImage}
          />
          <Content
            id="blog-container"
            sx={{
              height: 'fit-content',
              minHeight: '100vh',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${theme?.backgroundImage})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'fixed',
                top: 0,
                zIndex: -1,
              }}
            />
            <Switch>
              <Route
                path={`${url}/about-me`}
                render={props => (
                  <RouteInner
                    maxWidth={
                      mobileBreakpoint
                        ? '100vw'
                        : BLOG_ARTICLE_DISPLAY_CONFIG.maxWidth
                    }
                    themeId={themeId}
                  >
                    <HtmlHeader title={`${tree?.name} - About me`} />

                    <Typography variant="h1" color="primary">
                      About me
                    </Typography>
                    <PublicPinnedArticle
                      id={tree?.aboutMe?.id}
                      config={PINNED_ARTICLE_DISPLAY_CONFIG}
                    />
                  </RouteInner>
                )}
              />
              <Route
                path={`${url}/${PATH_SEGMENT_BLOG_POSTS}/:id`}
                render={props => (
                  <RouteInner
                    maxWidth={
                      mobileBreakpoint
                        ? '100vw'
                        : BLOG_ARTICLE_DISPLAY_CONFIG.maxWidth
                    }
                    themeId={themeId}
                  >
                    {queryParams.blogPreview ? (
                      <BlogPreviewArticle
                        {...props}
                        config={BLOG_ARTICLE_DISPLAY_CONFIG}
                      />
                    ) : (
                      <BlogPostArticle
                        {...props}
                        blogAboutMeUrl={`${url}/about-me`}
                        config={BLOG_ARTICLE_DISPLAY_CONFIG}
                      />
                    )}
                  </RouteInner>
                )}
              />
              <Route path={`${url}/`} component={PublicBlogHomePage} />
            </Switch>
          </Content>
        </ThemeProvider>
      </PublicContext.Provider>
    </ComponentOverrideContext.Provider>
  )
}

export default PublicBlog
