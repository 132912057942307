import React from 'react'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'

import Button from './Button'
import { PERMISSION_DENIED_TYPE_FEATURE } from '../auth/permissionUtils'
import { usePermissions } from '../auth/authHooks'

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'inherit',
  },
}))

const ButtonLink = ({
  children,
  to,
  target = '_self',
  permissionAction,
  permissionParams,
  ...props
}) => {
  const classes = useStyles()

  const checkPermissions = usePermissions()
  const { hasPermission, permissionDeniedType } = checkPermissions(
    permissionAction,
    permissionParams
  )

  const button = (
    <>
      <Button
        color="primary"
        size="large"
        permissionAction={permissionAction}
        permissionParams={permissionParams}
        {...props}
      >
        {children}
      </Button>
    </>
  )

  if (hasPermission) {
    return (
      <RouterLink
        target={target}
        className={classes.root}
        to={
          permissionDeniedType === PERMISSION_DENIED_TYPE_FEATURE
            ? '/pricing'
            : to
        }
      >
        {button}
      </RouterLink>
    )
  } else {
    return button
  }
}

export default ButtonLink
