import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { capitalize } from 'lodash'

import {
  Box,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/system'
import { isEqual } from 'lodash'

import { useActionDispatcher } from 'src/modules/app'
import { formatDate } from 'src/utils'
import {
  Container,
  IconButton,
  Link,
  LoadingIndicator,
  PageTitle,
  Typography,
} from 'src/modules/ui'
import { defaultImage } from 'src/modules/content/ArticleCard'

import {
  DEFAULT_SEARCH_LIMIT,
  searchSite,
  selectSiteSearchResults,
  selectSiteSearchResultsCount,
} from './searchSlice'
import { SearchTextField } from './SearchTextField'
import {
  useObjectLink,
  INSTANCE_TYPE_ARTICLE,
  INSTANCE_TYPE_DOCUMENT,
  INSTANCE_TYPE_PHOTO_ALBUM,
  INSTANCE_TYPE_FAMILY,
  INSTANCE_TYPE_INDIVIDUAL,
  INSTANCE_TYPE_MEDIA,
  INSTANCE_TYPE_EVENT,
  INSTANCE_TYPE_ARTEFACT,
  INSTANCE_TYPE_LOCATION,
} from 'src/modules/app/links'
import { PAGE_DISPLAY_NAME_SINGULAR } from '../page/LinkedPage'
import { selectIsBlogTree } from '../auth/authSlice'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const SiteSearchResultPreviewThumbnail = styled('img')(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  objectFit: 'cover',
  marginRight: theme.spacing(1),
}))

const SiteSearchResult = ({
  id,
  description,
  display,
  instanceType,
  previewThumbnail,
  publishedAt,
}) => {
  const displayInstanceType = PAGE_DISPLAY_NAME_SINGULAR[instanceType]
  let metadata = capitalize(displayInstanceType)
  if (
    [
      INSTANCE_TYPE_ARTICLE,
      INSTANCE_TYPE_DOCUMENT,
      INSTANCE_TYPE_PHOTO_ALBUM,
    ].includes(instanceType)
  ) {
    if (publishedAt) {
      metadata += ` - ${formatDate(publishedAt)}`
    } else {
      metadata += ` - not published`
    }
  }
  const placeholder =
    instanceType === INSTANCE_TYPE_INDIVIDUAL
      ? '/person-placeholder.png'
      : defaultImage
  const url = useObjectLink(instanceType, id)
  return (
    <Box
      sx={{
        mb: 2,
        p: 2,
        background: 'lightGrey',
        border: '1px solid darkGrey',
      }}
    >
      <Link to={url} underline="none">
        <Stack direction="row">
          <SiteSearchResultPreviewThumbnail
            src={previewThumbnail.fileThumbnail || placeholder}
            alt={display}
          />
          <Stack>
            <Typography variant="h5">{display}</Typography>
            <Typography variant="subtitle4">{metadata}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Stack>
        </Stack>
      </Link>
    </Box>
  )
}

export const SiteSearch = () => {
  const [instanceTypes, setTypes] = useState(null)
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')

  const isBlogTree = useSelector(selectIsBlogTree)

  const location = useLocation()

  useEffect(() => {
    // First time we load this component, set the query if supplied in URL
    const querystring = qs.parse(location.search.substr(1))
    const q = querystring.q
    if (q) {
      setQuery(q)
      doSearch(q, instanceTypes, page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatchSearchSite = useActionDispatcher(searchSite)
  const results = useSelector(selectSiteSearchResults)
  const resultsCount = useSelector(selectSiteSearchResultsCount)

  const haveSearched = dispatchSearchSite.status !== 'idle'
  const isLoading = dispatchSearchSite.status === 'loading'
  const noResults = haveSearched && !isLoading && results.length === 0

  const handleQueryChanged = useCallback(
    e => setQuery(e.target.value),
    [setQuery]
  )

  const doSearch = useCallback(
    (query, instanceTypes, page) => {
      if (query) {
        dispatchSearchSite({ query, instanceTypes, page })
      }
    },
    [dispatchSearchSite]
  )

  const handleSearchSite = useCallback(() => {
    doSearch(query, instanceTypes, page)
  }, [doSearch, page, query, instanceTypes])

  const handleQueryKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSearchSite()
      }
    },
    [handleSearchSite]
  )

  const handlePaginationChange = useCallback(
    (e, value) => {
      setPage(value - 1)
      doSearch(query, instanceTypes, value - 1)
    },
    [doSearch, query, setPage, instanceTypes]
  )

  const handleTypeChange = useCallback(
    (e, value) => {
      setTypes(value)
      setPage(0)
      doSearch(query, value, 0)
    },
    [doSearch, query, setTypes]
  )

  return (
    <Container>
      <PageTitle backButton title={'Search'} />
      <Box sx={{ width: 250 }}>
        <SearchTextField
          onChange={handleQueryChanged}
          onKeyPress={handleQueryKeyPress}
          value={query}
          placeholder="Search..."
          InputProps={{
            endAdornment: (
              <IconButton
                permissionAction={ACTION_ALL_ACCESS}
                onClick={handleSearchSite}
              >
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box sx={{ mt: 2, mb: 2 }}>
          {!isBlogTree && (
            <>
              <ToggleButtonGroup
                onChange={handleTypeChange}
                variant="text"
                exclusive
                value={instanceTypes}
                sx={{
                  '& .MuiToggleButtonGroup-grouped': {
                    border: 0,
                    '&.Mui-disabled': {
                      border: 0,
                    },
                    '&:not(:first-of-type)': {},
                    '&:first-of-type': {},
                  },
                }}
              >
                <ToggleButton value={null} selected={!instanceTypes}>
                  All results
                </ToggleButton>

                <ToggleButton
                  value={[INSTANCE_TYPE_ARTICLE]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_ARTICLE])}
                >
                  Articles
                </ToggleButton>

                <ToggleButton
                  value={[INSTANCE_TYPE_DOCUMENT]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_DOCUMENT])}
                >
                  Documents
                </ToggleButton>

                <ToggleButton
                  value={[INSTANCE_TYPE_PHOTO_ALBUM, INSTANCE_TYPE_MEDIA]}
                  selected={isEqual(instanceTypes, [
                    INSTANCE_TYPE_PHOTO_ALBUM,
                    INSTANCE_TYPE_MEDIA,
                  ])}
                >
                  Photos
                </ToggleButton>
                <ToggleButton
                  value={[INSTANCE_TYPE_INDIVIDUAL]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_INDIVIDUAL])}
                >
                  People
                </ToggleButton>
                <ToggleButton
                  value={[INSTANCE_TYPE_FAMILY]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_FAMILY])}
                >
                  Families
                </ToggleButton>
                <ToggleButton
                  value={[INSTANCE_TYPE_EVENT]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_EVENT])}
                >
                  Occasions
                </ToggleButton>
                <ToggleButton
                  value={[INSTANCE_TYPE_ARTEFACT]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_ARTEFACT])}
                >
                  Artefacts
                </ToggleButton>
                <ToggleButton
                  value={[INSTANCE_TYPE_LOCATION]}
                  selected={isEqual(instanceTypes, [INSTANCE_TYPE_LOCATION])}
                >
                  Places
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
        </Box>
      </Stack>
      <Box sx={{ minHeight: 200 }}>
        {results.map(searchResult => (
          <SiteSearchResult key={searchResult.id} {...searchResult} />
        ))}
        {isLoading && <LoadingIndicator />}
        {noResults && <Typography variant="body1">No results</Typography>}
      </Box>
      <Pagination
        count={Math.floor(resultsCount / DEFAULT_SEARCH_LIMIT)}
        size="small"
        page={page + 1}
        onChange={handlePaginationChange}
      />
    </Container>
  )
}

export default SiteSearch
