import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { LoadingIndicator } from 'src/modules/ui'
import { selectPublicNodeDirectory } from '../tree/treeSlice'
import { SubTreeViewer } from 'src/modules/viewer/treeViewers'
import { useSubTreeNodes } from 'src/modules/viewer/hooks'
import { useSubTreeStyles } from '../../content/SubTreeBlock'
import { imageCaptionStyles } from '../../content/Article'

const PublicSubTreeBlock = ({ subTree, allowDetailsScreenOnLinks = true }) => {
  const classes = useSubTreeStyles()
  const nodeDirectory = useSelector(selectPublicNodeDirectory)
  const [visibleNodes, selectedIndividualIds] = useSubTreeNodes(
    nodeDirectory,
    subTree
  )

  const readyToDisplay = !!(subTree && visibleNodes.length)

  return (
    <Box sx={{ position: 'relative' }}>
      {!readyToDisplay && <LoadingIndicator />}
      {readyToDisplay && (
        <SubTreeViewer
          allowDetailsScreenOnLinks={allowDetailsScreenOnLinks}
          visibleNodes={visibleNodes}
          selectedIndividualIds={selectedIndividualIds}
          preview
          isPublic={true}
        />
      )}
      {subTree?.caption && (
        <Box py={2} textAlign="center">
          <Typography
            className={classes.mediaCaption}
            style={imageCaptionStyles}
          >
            {subTree.caption}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default PublicSubTreeBlock
