import React from 'react'
import { Box, Stack } from '@mui/material'

import { Container, PageTitle } from 'src/modules/ui'

const MyContentLayout = ({
  children,
  backButton,
  createButton,
  onClickCreate = () => {},
  pageTitle,
}) => {
  return (
    <Container>
      <PageTitle title={pageTitle} backButton={backButton} showTags={false} />
      {createButton ? (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2, display: { xs: 'none', md: 'block' } }}
        >
          <Box>{createButton}</Box>
        </Stack>
      ) : null}
      {children}
    </Container>
  )
}

export default MyContentLayout
