import { Box } from '@mui/material'
import { useContext } from 'react'

import ContentCard from 'src/modules/content/ContentCard'
import {
  generatePublicLinkForObject,
  INSTANCE_TYPE_MEDIA_GROUP,
} from 'src/modules/app/links'
import { PublicContext } from '../contexts'

const PublicContentCard = ({ content: { type, ...content }, width }) => {
  const { id } = content
  const { treeSlug } = useContext(PublicContext)
  let url
  if (type !== INSTANCE_TYPE_MEDIA_GROUP) {
    url = generatePublicLinkForObject(treeSlug, type, id)
  }

  return (
    <Box>
      <ContentCard
        {...content}
        type={type}
        url={url}
        sx={{ mb: 1 }}
        width={width}
      />
    </Box>
  )
}

export default PublicContentCard
