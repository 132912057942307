import { Box, Fab } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import React from 'react'

const fabSx = {
  position: 'fixed',
  left: 20,
  bottom: 20,
  zIndex: 1200, // mui dialogs are at z-index 1300
}

const FeedbackButton = () => {
  const showFeedBack = () => {
    window.FreshworksWidget('open')
  }

  return (
    <Fab
      onClick={showFeedBack}
      variant="extended"
      color="secondary"
      sx={{ ...fabSx, pr: 0.5 }}
    >
      Send Feedback
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ml: 1,
          height: 40,
          width: 40,
        }}
      >
        <HelpIcon color="secondary" />
      </Box>
    </Fab>
  )
}

export default FeedbackButton
