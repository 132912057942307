import { useContext, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Box } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import { LoadingIndicator } from 'src/modules/ui'

import {
  CloseButton,
  MediaChips,
  MediaFile,
  NextButton,
  PhotoAuthor,
  PhotoContainer,
  PhotoDescription,
  PhotoName,
  PrevButton,
  Root,
  SidePanel,
  SidePanelMeta,
  calcSidePanelWidth,
  TranscriptionMeta,
  PhotoTranscription,
} from 'src/modules/photo/MediaDetail'
import { IconButton } from 'src/modules/ui'

import { fetchPublicMediaDetail, selectPublicMediaDetail } from './photoSlice'
import { PublicContext } from '../contexts'
import HtmlHeader from '../../app/HtmlHeader'
import { ShareButton } from '../../visibility/InstanceVisibilityControl'
import { useNotification } from '../../app'
import { generatePublicLinkForObjectWithSiteUrl } from '../../app/links'
import copy from 'copy-to-clipboard'
import { handleImgName } from '../../photo/MediaDropzone'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'
import { PhotoDates } from '../../photo/MediaDetail'

const stringContainsText = s => {
  return !!s && s.trim() !== '' && s.trim() !== '<p></p>'
}

const hasTranscriptionContent = (md, ti) => {
  return md && md.transcription && stringContainsText(md.transcription[ti])
}

const PublicMediaDetail = ({
  photoListItem,
  canRequestPrev,
  canRequestNext,
  onClose,
  onBack,
  onClickPrev,
  onClickNext,
  showTranscription,
}) => {
  const dispatchFetchMediaDetail = useActionDispatcher(fetchPublicMediaDetail)
  const { treeSlug } = useContext(PublicContext)
  const { showSuccess } = useNotification()

  useEffect(() => {
    if (photoListItem.isCropped) {
      dispatchFetchMediaDetail({ mediaId: photoListItem.original, treeSlug })
    } else {
      dispatchFetchMediaDetail({ mediaId: photoListItem.id, treeSlug })
    }

    setTranscriptionIndex(0)
  }, [dispatchFetchMediaDetail, photoListItem, treeSlug])

  const mediaDetail = useSelector(selectPublicMediaDetail)

  const [expandTranscription, setExpandTranscription] = useState(null) // if null calcSidePanelWidth will show wide if there's a transcription entered and narrow if not.

  //for photos this is always 0 but PDFs can contain multiple pages and we want
  //a transcription per page. The database column Media.transcription is a Postgres Array
  //so can store multiple transcriptions.
  //This is set by onPdfPageNumberChanged() which is passed into PDFViewer
  const [transcriptionIndex, setTranscriptionIndex] = useState(0)
  const [sidePanelIsWide, setSidePanelIsWide] = useState(null)
  const [sidePanelWidth, setSidePanelWidth] = useState()

  const recalculateSidePanelWidth = useCallback(() => {
    const transcriptionContentPresent = hasTranscriptionContent(
      mediaDetail,
      transcriptionIndex
    )

    const [newSidePanelWidth, widerSidePanel] = calcSidePanelWidth({
      showTranscription,
      transcriptionContentPresent,
      expandTranscription,
    })

    setSidePanelIsWide(widerSidePanel)
    setSidePanelWidth(newSidePanelWidth)
  }, [mediaDetail, showTranscription, transcriptionIndex, expandTranscription])

  const pageChanged = () => {
    if (expandTranscription !== null) {
      setExpandTranscription(null)
    }

    recalculateSidePanelWidth()
  }

  // called when mediaDetail.id changes - a new media item has been navigated to
  useEffect(() => {
    if (transcriptionIndex !== 0) {
      setTranscriptionIndex(0)
    }

    pageChanged()
  }, [mediaDetail, setTranscriptionIndex]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    recalculateSidePanelWidth()
  }, [expandTranscription]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    pageChanged()
  }, [transcriptionIndex]) //eslint-disable-line react-hooks/exhaustive-deps

  //passed to PDFViewer and called on prev/next page button click
  const onPdfPageNumberChanged = newPageNumber => {
    setTranscriptionIndex(newPageNumber - 1)

    // this will trigger the above useEffect() that has transcriptionIndex
  }

  if (!mediaDetail) {
    return <LoadingIndicator />
  }

  const { visibility, ...mediaRest } = mediaDetail

  const handleShare = () => {
    const url = generatePublicLinkForObjectWithSiteUrl(
      treeSlug,
      'media',
      mediaDetail.id
    )
    copy(url)
    showSuccess('Copied url to clipboard')
  }

  const headerProps = {
    title: handleImgName(mediaDetail.title),
    type: 'website',
    image: mediaDetail?.fileMedium || mediaDetail?.fileThumbnail,
    url: window.location.href,
  }

  return (
    <>
      <HtmlHeader {...headerProps} />
      <Root>
        <PhotoContainer>
          {onBack ? (
            <Box
              sx={{
                position: 'absolute',
                left: 16,
                top: 16,
                zIndex: 1,
              }}
            >
              <IconButton permissionAction={ACTION_ALL_ACCESS} onClick={onBack}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Box>
          ) : (
            <CloseButton permissionAction={ACTION_ALL_ACCESS} onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}
          <PrevButton
            disabled={!canRequestPrev}
            onClick={onClickPrev}
            permissionAction={ACTION_ALL_ACCESS}
          >
            <ChevronLeftIcon />
          </PrevButton>
          <MediaFile
            mediaDetail={mediaDetail}
            onPdfPageNumberChanged={onPdfPageNumberChanged}
          />
          <NextButton
            permissionAction={ACTION_ALL_ACCESS}
            disabled={!canRequestNext}
            onClick={onClickNext}
          >
            <ChevronRightIcon />
          </NextButton>
        </PhotoContainer>
        <SidePanel sidePanelWidth={sidePanelWidth}>
          <SidePanelMeta>
            <Box display="flex" flexDirection="row" gap={2}>
              <PhotoAuthor mediaDetail={mediaRest} />
              <Box>
                <ShareButton onClickShare={handleShare} isPublic={true} />
              </Box>
            </Box>
            <PhotoName mediaDetail={mediaDetail} />
            <MediaChips mediaDetail={mediaDetail} />
            <PhotoDescription mediaDetail={mediaDetail} />
            <PhotoDates mediaDetail={mediaDetail} />
            {showTranscription && (
              <TranscriptionMeta>
                <PhotoTranscription
                  transcriptionText={
                    mediaDetail.transcription
                      ? mediaDetail.transcription[transcriptionIndex]
                      : ''
                  }
                  treeSlug={treeSlug}
                  canEdit={false}
                  setExpandTranscription={setExpandTranscription}
                  handleCollapseTranscriptionOnClick={() => {
                    setExpandTranscription(false)
                  }}
                  sidePanelIsWide={sidePanelIsWide}
                />
              </TranscriptionMeta>
            )}
          </SidePanelMeta>
        </SidePanel>
      </Root>
    </>
  )
}

export default PublicMediaDetail
