import React, { useState } from 'react'
import AddMediaDialog from 'src/modules/photo/AddMediaDialog'
import { Button } from '../ui'
import { useAvailableFeatures } from '../auth/hooks'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'

const AddMediaToPageDialog = props => {
  const [previewTiles, setPreviewTiles] = useState([])
  const canEditTree = useAvailableFeatures()?.tree?.editTree

  return (
    <AddMediaDialog
      {...props}
      {...{ previewTiles, setPreviewTiles }}
      trigger={props => (
        <Button
          permissionAction={ACTION_CREATE}
          permissionParams={{ instanceType: INSTANCE_TYPE_MEDIA }}
          color="primary"
          size="large"
          featureAvailable={canEditTree}
          {...props}
        >
          Manage Media
        </Button>
      )}
    />
  )
}

export default AddMediaToPageDialog
