import { Box, Popper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAuthorisedTreeSlug,
  selectUserHomeIndividual,
} from '../auth/authSlice'
import { LineageViewer } from '../viewer/treeViewers'
import {
  fetchIndividualsForLineage,
  selectFamilyByFamilyId,
} from '../viewer/viewerSlice'
import { styled } from '@mui/styles'
import LoadingIndicator from './LoadingIndicator'

const BACKGROUND_COLOR = '#f8f8f8'

const CustomPopper = styled(Popper)(({ theme }) => ({
  zIndex: 10000,
  width: '50%',
  maxWidth: '50vw',
  height: '70vh',
  maxHeight: '70vh',
  backgroundColor: BACKGROUND_COLOR,
  borderRadius: 10,
  boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.1)',
}))

export function FamilyIdentifierTree({ option, fullScreen }) {
  const treeSlug = useSelector(selectAuthorisedTreeSlug)
  const familyObj = useSelector(selectFamilyByFamilyId(option?.id))
  const homeIndividual = useSelector(selectUserHomeIndividual)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        fetchIndividualsForLineage({
          treeSlug,
          target: homeIndividual.id,
          familyId: option.id,
        })
      )
    }

    fetchData()
  }, [treeSlug, homeIndividual, option, dispatch])

  return (
    <>
      {familyObj ? (
        <Box
          sx={{
            backgroundColor: BACKGROUND_COLOR,
            height: '100%',
            width: '100%',
            borderRadius: 10,
          }}
        >
          <Typography variant="h4" textAlign="center" p={2}>
            {option.display || option.surname}{' '}
            {option.earliestBirthYear ? `(${option.earliestBirthYear})` : null}
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              overflowX: 'scroll',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: BACKGROUND_COLOR,
              paddingLeft: 12,
              boxShadow: '5px 20px 15px 5px rgba(0,0,0,0.3)',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                height: '100%',
              }}
            >
              {homeIndividual && (
                <LineageViewer
                  family={familyObj}
                  traceLineageTo={homeIndividual}
                  showTotalGenerations={fullScreen ? 100 : 4}
                  previewViewer={true}
                  explore={fullScreen}
                />
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

export default function FamilyTreeTooltip({
  setPreviewFamily,
  option,
  popoverPos,
  alignTreePreview,
}) {
  let xPosStyles = { left: popoverPos.x + 60 }
  if (alignTreePreview === 'left') {
    xPosStyles = { left: 'unset', right: window.innerWidth - popoverPos.x + 30 }
  }

  let top = popoverPos.y - window.innerHeight * 0.45 + 1
  if (top < 5) {
    top = 5
  }
  if (top > window.innerHeight * 0.3) {
    top = window.innerHeight * 0.3
  }
  return (
    <CustomPopper
      open={option?.id ? true : false}
      placement="bottom-end"
      style={{
        position: 'fixed',
        bottom: 'unset',
        right: 'unset',
        top: top,
        ...xPosStyles,
      }}
      onMouseEnter={() => setPreviewFamily(option)}
      onMouseLeave={() => setPreviewFamily({})}
    >
      <FamilyIdentifierTree option={option} />
    </CustomPopper>
  )
}
