import { combineReducers } from 'redux'

import accountAdmin from './modules/accountAdmin/accountAdminSlice'
import app from './modules/app/appSlice'
import auth from './modules/auth/authSlice'
import content from './modules/content/contentSlice'
import exploreTree from './modules/viewer/exploreTreeSlice'
import goals from './modules/goals/goalsSlice'
import home from './modules/home/homeSlice'
import informationRequest from './modules/informationRequest/informationRequestSlice'
import myContent from './modules/myContent/myContentSlice'
import page from './modules/page/pageSlice'
import photo from './modules/photo/photoSlice'
import search from './modules/search/searchSlice'
import publicReducer from './modules/public/reducer'
import trees from './modules/trees/treesSlice'
import viewer from './modules/viewer/viewerSlice'
import writeArticle from './modules/writeArticle/writeArticleSlice'
import services from './modules/services/servicesSlice'
import map from './modules/map/mapSlice'

const createRootReducer = routerReducer =>
  combineReducers({
    accountAdmin,
    app,
    auth,
    content,
    exploreTree,
    goals,
    home,
    informationRequest,
    myContent,
    page,
    photo,
    public: publicReducer,
    search,
    trees,
    viewer,
    writeArticle,
    services,
    router: routerReducer,
    map,
  })

export default createRootReducer
