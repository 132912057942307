import { isNull, isString, sortBy } from 'lodash'
import { earliestCredibleBirth } from '../viewer/api/nodeDirectory'
import { convertUIGedDate } from '../common/gedcomDateParser'

export const STATE_ALIVE = 'ALIVE'
export const STATE_DECEASED = 'DECEASED'
export const STATE_UNKNOWN = 'UNKNOWN'
const MAX_WORD_SIZE = 12

const truncateWithEllipses = (text, max) => {
  return text.substr(0, max - 1) + (text.length > max ? '...' : '')
}

export const getTreeCardNameValues = param => {
  if (!param) {
    return { line1: '', line2: '' }
  }

  const { givenName, knownAs, surname, alias } = param

  if (alias) {
    const words = alias.split(' ')
    if (words.length === 1) {
      return { line1: alias.trim(), line2: '' }
    } else if (words.length > 1) {
      const first2Words = words[0] + ' ' + words[1]
      if (first2Words.length < MAX_WORD_SIZE) {
        return {
          line1: first2Words,
          line2: truncateWithEllipses(
            alias.substring(first2Words.length).trim(),
            MAX_WORD_SIZE
          ),
        }
      } else {
        return {
          line1: words[0],
          line2: truncateWithEllipses(
            alias.substring(words[0].length).trim(),
            MAX_WORD_SIZE
          ),
        }
      }
    }
  }

  let givenNameWords = givenName ? givenName.trim().split(' ') : ['']

  const firstName = knownAs ? knownAs.trim() : givenNameWords[0]

  return {
    line1: firstName ? firstName.trim() : '',
    line2: surname ? truncateWithEllipses(surname.trim(), MAX_WORD_SIZE) : '',
  }
}

export const getIndividualTreeTitle = ({
  givenName,
  knownAs,
  surname,
  alias,
}) => {
  if (alias) {
    return posessive(alias)
  } else {
    return posessive(knownAs || givenName?.split(' ')[0] || '')
  }
}

export const formatIndividualName = individual => {
  const {
    givenName,
    knownAs,
    surname,
    shortenGivenName = true,
    alias,
    title,
  } = individual || {}
  let titleDisplay = title ? ` (${title})` : ''
  if (alias) {
    return `${alias}${titleDisplay}`
  }

  let firstName = givenName
  if (givenName && shortenGivenName) {
    firstName = givenName.split(' ')[0]
  }
  if (knownAs || firstName || surname) {
    return `${knownAs || firstName} ${surname}${titleDisplay}`
  } else return ''
}

export const formatIndividualInitials = individual => {
  if (!individual) {
    return ''
  }

  const { givenName, knownAs, surname, alias } = individual

  if (alias) {
    return alias[0].toUpperCase()
  } else if (knownAs) {
    return [knownAs, surname].filter(a => a).map(n => n[0].toUpperCase())
  } else {
    return [givenName, surname].filter(a => a).map(n => n[0].toUpperCase())
  }
}

export const formatIndividualWithDates = ({
  dateOfBirthGed,
  dateOfDeathGed,
  prefix,
  givenName,
  surname,
  suffix,
  alias,
  state,
  knownAs,
}) => {
  return `${prefix ? `${prefix} ` : ''}${givenName ? `${givenName} ` : ''}${
    alias && knownAs && knownAs !== givenName ? `"${knownAs}" ` : ''
  }${surname ? `${surname} ` : ''}${
    suffix ? `${suffix} ` : ''
  }(${formatIndividualDates({
    dateOfBirthGed,
    dateOfDeathGed,
    state,
  })})`
}

export const formatIndividualWithYears = ({
  yearOfBirth,
  yearOfDeath,
  prefix,
  givenName,
  knownAs,
  surname,
  suffix,
  alias,
  state,
  shortenName = false,
  replacement = '?',
}) => {
  const firstName = shortenName ? givenName.split(' ')[0] : givenName

  const name = formatIndividualName({
    prefix,
    givenName: firstName,
    knownAs,
    surname,
    suffix,
    alias,
  })

  const dateOfBirth = yearOfBirth || null
  const dateOfDeath = yearOfDeath || null

  return `${name} (${formatIndividualDates({
    dateOfBirthGed: dateOfBirth,
    dateOfDeathGed: dateOfDeath,
    replacement,
    state,
  })})`
}

export const formatIndividualSimpleName = ({ givenName, surname, alias }) => {
  if (alias) {
    return alias
  } else {
    return `${givenName} ${surname}`
  }
}

export const posessive = name => {
  if (name) {
    if (name.endsWith('s')) {
      return `${name}'`
    } else {
      return `${name}'s`
    }
  } else {
    return name
  }
}

export const formatIndividualDates = ({
  dateOfBirthGed,
  dateOfDeathGed,
  replacement = '?',
  state = STATE_UNKNOWN,
}) => {
  let dob = dateOfBirthGed
  let dod = dateOfDeathGed
  if (isString(dateOfBirthGed)) {
    dob = convertUIGedDate(dateOfBirthGed) || null
  }
  if (isString(dateOfDeathGed)) {
    dod = convertUIGedDate(dateOfDeathGed) || null
  }

  let birth = !dob ? replacement : dob

  let death
  if (dod) {
    death = dod
  } else {
    if (state === STATE_ALIVE) {
      death = undefined
    } else {
      death = replacement
    }
  }

  // If yearOfDeath is not set, and this person was born recently enough,
  // assume they are alive.
  if (state !== STATE_DECEASED && isNull(dod) && !isNull(dob)) {
    if (earliestCredibleBirth() < new Date(dob).getFullYear()) {
      death = undefined
    }
  }
  if (death) {
    return `${birth} - ${death}`
  } else {
    return `${birth} -`
  }
}

export const formatDraftIndividualWithDates = ({
  dateOfBirthGed,
  dateOfDeathGed,
  prefix,
  givenName,
  surname,
  suffix,
  alias,
  state,
}) => {
  let dateOfBirth = dateOfBirthGed || null
  let dateOfDeath = dateOfDeathGed || null
  let name
  if (alias) {
    name = alias
  } else {
    name = `${prefix ? prefix : ''} ${givenName ? givenName : ''} ${
      surname ? surname : ''
    } ${suffix ? suffix : ''}`
  }
  return `${name} (${formatIndividualDates({
    dateOfBirthGed: dateOfBirth,
    dateOfDeathGed: dateOfDeath,
    state,
  })})`
}

export const formatIndividualTagsAndSearch = (
  individual,
  sharedByIndividual
) => {
  const alias = individual.alias ? `${individual.alias}, ` : ''

  if (individual?.id === sharedByIndividual?.id) {
    const forename = individual.knownAs
      ? individual.knownAs
      : individual.givenName
    return `${forename || ''} ${individual.surname || ''}`
  }

  let forename = individual.givenName
  const knownAs = individual.knownAs
  let nameArr = forename?.split(' ')
  if (knownAs && forename !== knownAs) {
    if (nameArr.includes(knownAs)) {
      const index = nameArr.indexOf(knownAs)
      nameArr[index] = `"${nameArr[index]}"`
      forename = nameArr.join(' ')
    } else {
      forename += ` "${knownAs}"`
    }
  }
  return `${alias}${forename || ''} ${individual.surname || ''}`
}

export const createIndividualsSearchOptions = (
  individualNodes,
  sharedByIndividual
) => {
  const individuals = individualNodes
    .filter(individual => individual.givenName !== 'Unknown')
    .filter(individual => individual.givenName || individual.surname)
    .map(individual => ({
      id: individual.id,
      name: `${individual.givenName} ${individual.surname}`,
      alias: individual.alias,
      knownAs: individual.knownAs,
      surname: individual.surname,
      title: individual.title,
      display: `${formatIndividualTagsAndSearch(
        individual,
        sharedByIndividual
      )} (${formatIndividualDates({
        dateOfBirthGed: individual.yearOfBirth,
        dateOfDeathGed: individual.yearOfDeath,
        state: individual.state,
      })})`,
    }))
  return sortBy(individuals, 'name')
}

export const INDIVIDUAL_SEARCH_KEYS = [
  'name',
  'givenName',
  'surname',
  'title',
  'display',
  'alias',
  'knownAs',
]
