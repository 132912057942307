import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { Box, styled } from '@mui/material'

import { useActionDispatcher } from 'src/modules/app'
import {
  useWaitForSystemEvent,
  SYSTEM_EVENT_STATE_DONE,
  SYSTEM_EVENT_STATE_FAILED,
} from 'src/modules/systemEvent/hooks'
import { Button, LoadingIndicator, Typography } from 'src/modules/ui'

import { fetchUser } from 'src/modules/auth/authSlice'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { generateTreeLink } from 'src/modules/app/links'
import { useSetTree } from 'src/modules/app/hooks'

import { EditTreeName } from './EditTreeName'
import {
  createTree,
  selectCreatedTree,
  createDraftTree,
  clearCreatedTree,
  createEmptyTree,
} from './treesSlice'
import { ga4Events, sendPageview, sendEvent } from '../analytics/AnalyticsUtils'
import { stripGedFromTreeName } from './utils'
import { VISIBILITY_PUBLIC } from '../visibility/visibilityUtils'
import { ACTION_CREATE } from '../app/appConstants'
import { INSTANCE_TYPE_TREE } from '../app/links'

const MODE_CREATE = 'CREATE'
const MODE_UPDATE = 'UPDATE'

const STATE_WAITING = 'WAITING'
const STATE_NAMING = 'NAMING'
const STATE_PROCESSING = 'PROCESSING'
const STATE_DONE = 'DONE'
const STATE_DONE_EMPTY = 'DONE_EMPTY'
const STATE_FAILED = 'FAILED'
const STATE_FAILED_UPLOAD = 'STATE_FAILED_UPLOAD'
const STATE_FAILED_PROCESSING = 'STATE_FAILED_PROCESSING'

export const CentreContainer = styled('div')(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
}))

export function CreateOrUpdateTree({
  treeSlugToUpdate,
  treeVisibility = VISIBILITY_PUBLIC,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)
  const setTree = useSetTree()

  const [componentState, setComponentState] = useState(STATE_WAITING)
  const [createTreeFile, setCreateTreeFile] = useState(null)
  const [createTreeName, setCreateTreeName] = useState('')

  const dispatchCreateTree = useActionDispatcher(createTree)
  const dispatchCreateEmptyTree = useActionDispatcher(createEmptyTree)
  const dispatchCreateDraftTree = useActionDispatcher(createDraftTree)

  const mode = treeSlugToUpdate ? MODE_UPDATE : MODE_CREATE

  const dispatchFetchUser = useActionDispatcher(fetchUser)

  const createTreeResult = useSelector(selectCreatedTree)
  const systemEventId = createTreeResult.systemEvent.id

  const [movedOn, setMovedOn] = useState(false)

  useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__CREATE_OR_UPDATE_TREE)
  }, [])

  // called only from the 'Import GEDCOM' button after a tree name has been accepted
  // reducer in treesSlice.js used to fire event TREE_CREATED but event firing is now centralised to here
  const handleCreateTree = useCallback(async () => {
    sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_UPLOADING_GEDCOM)
    setComponentState(STATE_PROCESSING)
    try {
      await dispatchCreateTree({
        file: createTreeFile,
        name: createTreeName,
        visibility: treeVisibility,
      }).unwrap()
      sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_UPLOADED)
    } catch {
      sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_UPLOAD_ERROR)
      setComponentState(STATE_FAILED_UPLOAD)
    }
  }, [createTreeFile, createTreeName, dispatchCreateTree, treeVisibility])

  const handleCreateEmptyTree = useCallback(async () => {
    sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_CLICKED_DONT_HAVE_GEDCOM)
    try {
      await dispatchCreateEmptyTree({ visibility: treeVisibility })
      setComponentState(STATE_DONE_EMPTY)
      sendEvent(ga4Events.TREE_CREATED_EMPTY)
      sendEvent(ga4Events.ADMIN_CREATED)
    } catch {
      setComponentState(STATE_FAILED)
    }
  }, [dispatchCreateEmptyTree, treeVisibility])

  const onDrop = useCallback(
    async acceptedFiles => {
      sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_FILE_SELECTED)
      try {
        const file = new File(
          [acceptedFiles[0]],
          stripGedFromTreeName(acceptedFiles[0].name)
        )
        if (mode === MODE_CREATE) {
          setComponentState(STATE_NAMING)
          setCreateTreeFile(file)
          setCreateTreeName(file.name)
        } else if (mode === MODE_UPDATE) {
          setComponentState(STATE_PROCESSING)
          try {
            await dispatchCreateDraftTree({ file, treeSlugToUpdate }).unwrap()
          } catch {
            setComponentState(STATE_FAILED)
          }
        } else {
          throw Error('Invalid mode')
        }
      } catch (err) {
        console.error(
          `CreateOrUpdateTree.onDrop(): error checking dropped file`,
          err
        )
        setComponentState(STATE_FAILED)
      }
    },
    [dispatchCreateDraftTree, mode, treeSlugToUpdate]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({ accept: '.ged', onDrop, noClick: true, noKeyboard: true })

  const onSystemEventStateUpdate = useCallback(
    state => {
      if (state === SYSTEM_EVENT_STATE_DONE) {
        sendEvent(ga4Events.TREE_CREATED_FROM_GEDCOM_OK)
        sendEvent(ga4Events.ADMIN_CREATED)

        setComponentState(STATE_DONE)
      } else if (state === SYSTEM_EVENT_STATE_FAILED) {
        sendEvent(ga4Events.CREATE_OR_UPDATE_TREE_GEDCOM_PROCESSING_ERROR)
        setComponentState(STATE_FAILED_PROCESSING)
      }
    },
    [setComponentState]
  )

  useWaitForSystemEvent(onSystemEventStateUpdate, systemEventId)

  useEffect(() => {
    const afterDone = async () => {
      if (movedOn) {
        return
      }
      setMovedOn(true)
      // Fetch the user with the newly created tree
      await dispatchFetchUser()

      if (mode === MODE_CREATE) {
        await setTree(createTreeResult.tree.slug)
        const route =
          componentState === STATE_DONE_EMPTY ? 'edit' : 'select-myself'
        history.push(generateTreeLink(createTreeResult.tree.slug, route))
      } else {
        history.push(
          generateTreeLink(
            authorisedTreeSlug,
            `resolve-tree/${createTreeResult.tree.slug}`
          )
        )
      }
    }

    if ([STATE_DONE, STATE_DONE_EMPTY].includes(componentState)) {
      afterDone()
    }
    return () => {
      if (componentState === STATE_DONE) {
        // Clean up the state for next time.
        dispatch(clearCreatedTree())
      }
    }
  }, [
    authorisedTreeSlug,
    componentState,
    createTreeResult,
    dispatch,
    dispatchFetchUser,
    history,
    mode,
    setTree,
    movedOn,
  ])

  const handleTreeNameChanged = useCallback(
    name => {
      setCreateTreeName(name)
    },
    [setCreateTreeName]
  )

  return (
    <>
      <div {...getRootProps()}>
        {componentState !== STATE_DONE_EMPTY && (
          <>
            <input data-cy="import_ged_drag-and-drop" {...getInputProps()} />

            <Box sx={{ mb: 4, mt: 2 }}>
              {mode === MODE_CREATE && (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h1" color="primary">
                      Create your archive
                    </Typography>
                  </Box>
                  {componentState === STATE_WAITING && (
                    <Typography color="textSecondary" variant="subtitle1">
                      Upload your GEDCOM file to tell your story
                    </Typography>
                  )}
                  {componentState === STATE_NAMING && createTreeFile && (
                    <>
                      <Typography color="textSecondary" variant="subtitle1">
                        Name your archive
                      </Typography>
                      <EditTreeName
                        initialName={createTreeFile.name}
                        onTreeNameChanged={handleTreeNameChanged}
                      />
                    </>
                  )}
                </>
              )}
              {mode === MODE_UPDATE && (
                <>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h1" color="primary">
                      Update your archive
                    </Typography>
                  </Box>
                  <Typography color="textSecondary" variant="subtitle1">
                    To start adding new data, import an updated family tree.
                  </Typography>
                </>
              )}
            </Box>
            {componentState !== STATE_PROCESSING &&
              componentState !== STATE_DONE &&
              componentState !== STATE_DONE_EMPTY && (
                <Button
                  permissionAction={ACTION_CREATE}
                  permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  onClick={
                    componentState !== STATE_NAMING
                      ? () => {
                          sendEvent(
                            ga4Events.CREATE_OR_UPDATE_TREE_OPENED_FILE_DIALOG
                          )
                          openFileDialog()
                        }
                      : handleCreateTree
                  }
                  isLoading={componentState === STATE_PROCESSING}
                >
                  Import GEDCOM
                </Button>
              )}
            <Box sx={{ mt: 4 }}>
              {isDragActive ? (
                <Typography>Drop file to import...</Typography>
              ) : componentState === STATE_PROCESSING ? (
                <>
                  <Typography>Processing tree...</Typography>
                  <LoadingIndicator />
                </>
              ) : componentState === STATE_FAILED ||
                componentState === STATE_FAILED_UPLOAD ||
                componentState === STATE_FAILED_PROCESSING ? (
                <Typography color="red" sx={{ mb: 2 }}>
                  {componentState === STATE_FAILED_UPLOAD ? (
                    <>
                      Sorry, that gedcom file failed to upload. Please try
                      again. If this continues please contact us using 'Send
                      Feedback' or the 'Support' link at the bottom of the page.
                    </>
                  ) : (
                    <>
                      Sorry, we could not process that gedcom file. Please try
                      again. If this continues please contact us using 'Send
                      Feedback' or the 'Support' link at the bottom of the page.
                    </>
                  )}
                </Typography>
              ) : (
                <Typography>&nbsp;</Typography>
              )}
            </Box>
            {mode === MODE_CREATE &&
              !createTreeFile &&
              componentState !== STATE_PROCESSING &&
              componentState !== STATE_DONE && (
                <Box>
                  <Button
                    permissionAction={ACTION_CREATE}
                    permissionParams={{ instanceType: INSTANCE_TYPE_TREE }}
                    color="secondary"
                    variant="outlined"
                    size="large"
                    onClick={handleCreateEmptyTree}
                    isLoading={componentState === STATE_PROCESSING}
                  >
                    I don't have a GEDCOM
                  </Button>
                </Box>
              )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            ></Box>
          </>
        )}
      </div>
    </>
  )
}
