import React from 'react'
import { Box, Stack } from '@mui/material'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectUser } from 'src/modules/auth/authSlice'
import {
  fetchMyContent,
  fetchMyPhotos,
} from 'src/modules/myContent/myContentSlice'
import CreateMultiplePhotosDialog from 'src/modules/photo/CreateMultiple'
import CreateAlbumDialog from 'src/modules/photo/CreateAlbum'
import { NavLink } from 'src/modules/ui'

import MyContentLayout from './MyContentLayout'
import MyContentList from './MyContentList'
import MyPhotoList from './MyPhotoList'
import { generateTreeRoute } from 'src/modules/app/links'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import {
  generateTreeLink,
  INSTANCE_TYPE_PHOTO_ALBUM,
} from 'src/modules/app/links'

import { CreateAlbumButton, UploadMediaButton } from '../ui/actionButtons'

const PHOTO_PATH = 'my-media'
const ALBUM_PATH = 'my-media/albums'
const PHOTO_ROUTE = generateTreeRoute(PHOTO_PATH)
const ALBUM_ROUTE = generateTreeRoute(ALBUM_PATH)

const MyPhotos = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const matchPhotoListRoute = useRouteMatch(PHOTO_ROUTE)
  const matchAlbumRoute = useRouteMatch(ALBUM_ROUTE)
  const authorisedTreeSlug = useSelector(selectAuthorisedTreeSlug)

  const handleOnFinishedUploadingPhotos = () => {
    if (matchPhotoListRoute && matchPhotoListRoute.isExact) {
      dispatch(
        fetchMyPhotos({
          author: user.id,
          page: 0,
        })
      )
    } else if (matchAlbumRoute && matchAlbumRoute.isExact) {
      dispatch(
        fetchMyContent({
          author: user.id,
          page: 0,
          sort: '-published_at',
          type: INSTANCE_TYPE_PHOTO_ALBUM,
        })
      )
    }
  }

  return (
    <MyContentLayout pageTitle="My Media" backButton createButton={null}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <Stack direction="row" sx={{ lineHeight: '1.75rem' }}>
            <NavLink
              exact
              to={generateTreeLink(authorisedTreeSlug, PHOTO_PATH)}
              replace
            >
              All Media
            </NavLink>
            <NavLink
              to={generateTreeLink(authorisedTreeSlug, ALBUM_PATH)}
              replace
            >
              Albums
            </NavLink>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {matchPhotoListRoute.isExact && (
            <CreateMultiplePhotosDialog
              onFinishedUploading={handleOnFinishedUploadingPhotos}
              trigger={props => <UploadMediaButton {...props} />}
            />
          )}
          {matchAlbumRoute && (
            <CreateAlbumDialog
              onFinishedUploading={handleOnFinishedUploadingPhotos}
              trigger={props => <CreateAlbumButton {...props} />}
            />
          )}
        </Box>
      </Stack>
      <Switch>
        <Route
          exact
          path={generateTreeLink(authorisedTreeSlug, PHOTO_PATH)}
          render={() => <MyPhotoList />}
        />
        <Route
          path={generateTreeLink(authorisedTreeSlug, ALBUM_PATH)}
          render={() => <MyContentList type={INSTANCE_TYPE_PHOTO_ALBUM} />}
        />
      </Switch>
    </MyContentLayout>
  )
}

export default MyPhotos
