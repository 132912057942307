import Auth from '@aws-amplify/auth'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useNotification } from 'src/modules/app/hooks'
import {
  Button,
  Form,
  FormikPasswordField,
  FormikTextField,
  FIELD_REQUIRED,
} from 'src/modules/ui'

import FormActions from './FormActions'
import FormHeader from './FormHeader'
import FormLink from './FormLink'
import { ACTION_LOGIN } from '../app/appConstants'
import { AuthState } from './Authprovider'
import { sendPageview, sendEvent, ga4Events } from '../analytics/AnalyticsUtils'

export const ForgotPasswordSetNewForm = ({
  handleSubmit,
  isReset,
  isSubmitting,
  onClickBackToLogin,
  onClickResendCode,
}) => (
  <div>
    <FormHeader
      title={isReset ? 'Password Reset Required' : 'Forgotten Password part 2'}
      subtitle={
        <>
          Enter the verification code from the email we just sent to your email
          address, and then enter a new password.
          <br />
          <br />
          The email will be from '<b>no-reply@verificationemail.com</b>' and
          have the subject '<b>Forgot your WeAre.xyz password</b>'.
          <br />
          Please check your spam or junk mail folder.
          <br />
          <br />
          You can request another email by clicking 'Request a new code' below
          but you will no longer be able to use the code from previous emails.
        </>
      }
    />
    <Form>
      <FormikTextField
        fullWidth
        label="Verification Code"
        name="code"
        margin="normal"
        autoComplete="one-time-code"
      />
      <FormikPasswordField
        fullWidth
        label="New password"
        name="password"
        margin="normal"
        autoComplete="new-password"
      />
      <FormActions style={{ alignItems: 'center' }}>
        <FormLink onClick={onClickResendCode}>Request a new code</FormLink>
        <FormLink onClick={onClickBackToLogin}>Go to login page</FormLink>
        <Button
          permissionAction={ACTION_LOGIN}
          onClick={handleSubmit}
          color="primary"
          isLoading={isSubmitting}
          size="large"
          type="submit"
        >
          Set New Password
        </Button>
      </FormActions>
    </Form>
  </div>
)

const ForgotPasswordSetNew = ({ onStateChange, username, ...props }) => {
  React.useEffect(() => {
    sendPageview(ga4Events.PAGEVIEW__FORGOT_PASSWORD_PART_2)
  }, [])

  const { showError, showSuccess } = useNotification()

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(FIELD_REQUIRED),
    password: Yup.string().required(FIELD_REQUIRED),
  })
  const initialValues = { code: '', password: '' }

  const handleSubmit = async ({ code, password }, { setFieldError }) => {
    try {
      //call AWS amplify
      sendEvent(ga4Events.FORGOT_PASSWORD_PART_2_SUBMITTED)
      await Auth.forgotPasswordSubmit(username, code, password)
      sendEvent(ga4Events.FORGOT_PASSWORD_PART_2_SUBMITTED_OK)
      showSuccess('New password set successfully')
      onStateChange(AuthState.SignIn)
    } catch (err) {
      sendEvent(ga4Events.FORGOT_PASSWORD_PART_2_SUBMITTED_ERROR)
      if (err.code === 'CodeMismatchException') {
        setFieldError('code', 'Bad code supplied')
      } else if (err.code === 'InvalidPasswordException') {
        setFieldError('password', err.message)
      } else {
        showError(err.message)
      }
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => <ForgotPasswordSetNewForm {...formik} {...props} />}
    </Formik>
  )
}

export default ForgotPasswordSetNew
