import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@mui/system'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { debounce, isNumber, sum } from 'lodash'

import { IconButton } from 'src/modules/ui'
import { MEDIA_MARGIN_RIGHT } from '../writeArticle/contentBlockConstants'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import useDetectPrint from 'react-detect-print'

export const MODE_EDIT = 'edit'
export const MODE_DISPLAY = 'display'

const DRAG_ACTIVATION_DISTANCE = 20
export const DRAG_STATE_DONE = 'done'

const HideOnPrintBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  '@media print': {
    display: 'none',
  },
})

export const SimpleCarouselContext = React.createContext({
  handleLoaded: () => {},
})

const InnerCarousel = styled(Box)(
  ({ theme, multiRow, showButtons, justifyContent }) => ({
    display: 'flex',
    flexWrap: multiRow ? 'wrap' : 'no-wrap',
    flexGrow: 1,
    overflow: 'hidden',
    // Prevent browser from interpreting native touch action
    touchAction: 'none',
    overflowX: multiRow ? null : 'scroll',
    padding: theme.spacing(0, 2.5),
    justifyContent: showButtons ? 'flex-start' : justifyContent,
    '@media print': {
      flexWrap: 'wrap', // Force flexWrap to 'wrap' when printing
    },
  })
)

const StyledBox = styled(Box)(
  ({ theme, mobileBreakpoint, showButtons, arrowOffset }) => ({
    display: 'flex',
    overflow: mobileBreakpoint ? 'hidden' : undefined, // Using undefined instead of null for MUI v5 compatibility
    transform:
      !mobileBreakpoint && showButtons ? `translateX(-60px)` : undefined,
    width: !mobileBreakpoint
      ? showButtons
        ? `calc(100% + ${arrowOffset})`
        : '100%'
      : undefined,
    '@media print': {
      transform: 'none',
      width: '100%',
    },
  })
)

const SimpleCarousel = ({
  children,
  mediaRowAlignment,
  multiRow = false,
  mode, // MODE_EDIT/MODE_DISPLAY
  handleCaptionWidth,
}) => {
  const [scrollPositions, setScrollPositions] = useState([])
  const [scrollPositionsIdx, setScrollPositionIdx] = useState(0)
  const [currentScrollPos, setCurrentScrollPos] = useState(0)
  const theme = useTheme()

  let mobileBreakpoint = useMediaQuery(theme => theme.breakpoints.down('1340'))
  const isPrinting = useDetectPrint()
  mobileBreakpoint = isPrinting ? false : mobileBreakpoint // don't use mobile breakpoint when printing

  // Allow parent component to signal when children have changed width
  // to prompt recalculation of scroll positions
  const [loadedImagesCounter, setLoadedImagesCounter] = useState(0)
  const handleLoaded = useCallback(() => {
    setLoadedImagesCounter(loadedImagesCounter + 1)
  }, [setLoadedImagesCounter, loadedImagesCounter])

  const [mediaRowNode, setMediaRowNode] = useState(null)
  const [screenWidth, setScreenWidth] = useState(null)
  const [showButtons, setShowButtons] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const mediaRowRef = useCallback(
    mediaRowNode => {
      if (mediaRowNode === null) {
        return
      }
      setMediaRowNode(mediaRowNode)
      const viewWidth = mediaRowNode.clientWidth
      const mediaRow =
        mode === MODE_DISPLAY
          ? mediaRowNode
          : mediaRowNode.children[0].children[0]
      let childrenWidths = Array.from(mediaRow.children).map(
        child => child.offsetWidth + MEDIA_MARGIN_RIGHT
      )

      const childrenWidthsTotal = sum(childrenWidths)
      const shouldShowButtons = viewWidth < childrenWidthsTotal
      setShowButtons(shouldShowButtons)

      let runningWidthTotal = 0
      let currentScrollPosition = 0
      const calculatedScrollPositions = [0]

      for (let currentChildWidth of childrenWidths) {
        if (runningWidthTotal + currentChildWidth > viewWidth) {
          currentScrollPosition = currentScrollPosition + runningWidthTotal
          calculatedScrollPositions.push(currentScrollPosition)
          runningWidthTotal = 0
        }
        runningWidthTotal += currentChildWidth
      }
      setScrollPositions(calculatedScrollPositions)

      const currentScroll = calculatedScrollPositions[scrollPositionsIdx]
      if (!showButtons && shouldShowButtons) {
        // Reset scroll position when showing controls
        setScrollPositionIdx(0)
        setDisabledLeft(true)
        setDisabledRight(false)
      } else if (mediaRowNode.scrollLeft === 0) {
        // If current scroll is 0 then ensure position is 0 too
        setScrollPositionIdx(0)
      } else if (currentScroll && mediaRowNode.scrollLeft !== currentScroll) {
        // If current scroll is less than what it should be then
        // try and move into position
        handleMove(scrollPositionsIdx, scrollPositions.length)
      }

      if (handleCaptionWidth) handleCaptionWidth()
    },
    // carousel calculations rerun as images loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedImagesCounter, screenWidth, mediaRowAlignment]
  )

  const [disabledLeft, setDisabledLeft] = useState(true)
  const [disabledRight, setDisabledRight] = useState(false)

  const handleMove = useCallback(
    (moveToIdx, limit, scrollBehavior = 'smooth') => {
      if (!mediaRowNode || moveToIdx === limit) {
        return
      }
      mediaRowNode.scrollTo({
        left: scrollPositions[moveToIdx],
        behavior: scrollBehavior,
      })
      setScrollPositionIdx(moveToIdx)
    },
    [mediaRowNode, scrollPositions, setScrollPositionIdx]
  )

  const handleLeft = useCallback(() => {
    let scrollPosIndex = scrollPositionsIdx - 1
    const reversePositions = [...scrollPositions].reverse()
    const reverseIndex = reversePositions.findIndex(pos => {
      return pos < currentScrollPos
    })

    scrollPosIndex = scrollPositions.indexOf(reversePositions[reverseIndex])
    handleMove(scrollPosIndex, -1)
  }, [scrollPositionsIdx, scrollPositions, handleMove, currentScrollPos])

  const handleRight = useCallback(() => {
    let scrollPosIndex = scrollPositionsIdx + 1
    scrollPosIndex = scrollPositions.findIndex(pos => {
      return pos > currentScrollPos
    })
    handleMove(scrollPosIndex, scrollPositions.length)
  }, [scrollPositionsIdx, scrollPositions, handleMove, currentScrollPos])

  const handleScroll = e => {
    let element = e.target
    const distanceFromFarRight =
      element.scrollWidth - element.scrollLeft - element.clientWidth
    const atFarRight = Math.abs(distanceFromFarRight) < 1
    setDisabledRight(atFarRight)
    setDisabledLeft(element.scrollLeft === 0)
    setCurrentScrollPos(element.scrollLeft)
    if (element.scrollLeft === 0) {
      setScrollPositionIdx(0)
    }
  }
  const debouncedHandleScroll = useCallback(
    e => debounce(handleScroll, 250)(e),
    []
  )

  const [dragState, setDragState] = useState(null)
  const handlePointerDown = useCallback(
    e => {
      if (mode === MODE_DISPLAY) {
        // Initialise drag state to first X position seen
        setDragState(e.pageX)
      }
    },
    [setDragState, mode]
  )
  const handlePointerUp = useCallback(
    () => dragState && setDragState(null),
    [setDragState, dragState]
  )
  const handlePointerMove = useCallback(
    e => {
      if (isNumber(dragState)) {
        const amount = e.pageX - dragState
        if (Math.abs(amount) > DRAG_ACTIVATION_DISTANCE) {
          amount > 0 ? handleLeft() : handleRight()
          setDragState(DRAG_STATE_DONE)
        }
      }
    },
    [handleLeft, handleRight, dragState, setDragState]
  )

  // We need to accomodate to for the arrows + box shadow on carousel images so that they display well and inline with other content blocks
  const arrowOffset = `80px + ${theme.spacing(5)}`
  return (
    <StyledBox
      mobileBreakpoint={mobileBreakpoint}
      showButtons={showButtons}
      arrowOffset={arrowOffset}
    >
      <SimpleCarouselContext.Provider value={{ handleLoaded }}>
        {showButtons && !multiRow && (
          <HideOnPrintBox>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              className="hideVisibilityOnPrint"
              noBackground
              disabled={disabledLeft}
              onClick={handleLeft}
              style={{ width: 40 }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </HideOnPrintBox>
        )}
        <InnerCarousel
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          multiRow={multiRow}
          showButtons={showButtons}
          justifyContent={mediaRowAlignment?.toLowerCase()}
          ref={mediaRowRef}
          onScroll={debouncedHandleScroll}
        >
          {dragState === DRAG_STATE_DONE && (
            <Box
              // Intercepts clicks which would pass through to photos after dragging
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
            ></Box>
          )}
          {children}
        </InnerCarousel>
        {showButtons && !multiRow && (
          <HideOnPrintBox>
            <IconButton
              permissionAction={ACTION_ALL_ACCESS}
              className="hideVisibilityOnPrint"
              noBackground
              disabled={disabledRight}
              onClick={handleRight}
              style={{ width: 40 }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </HideOnPrintBox>
        )}
      </SimpleCarouselContext.Provider>
    </StyledBox>
  )
}

export default SimpleCarousel
