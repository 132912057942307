/*
This slice governs what the user is looking at on the family tree. The
user can select individuals, families, or arbitrary subsets of nodes
to display.

This mode of navigation is applicable both to the public tree seen by
an unauthorised user, and the full tree seen by a logged in user.
*/
import { createSlice } from '@reduxjs/toolkit'
import {
  createSubTree,
  fetchFamilies,
  fetchIndividualsForLineage,
  fetchIndividualsForTarget,
} from './viewerSlice'
import { createWrappedAsyncThunk } from '../../api'
import {
  fetchPublicIndividualsForLineage,
  fetchPublicIndividualsForTarget,
} from '../public/tree/treeSlice'

const SLICE_NAME = 'exploreTree'

export const EXPLORE_VIEW_MODE_FAMILY = 'family'
export const EXPLORE_VIEW_MODE_INDIVIDUAL = 'individual'
export const EXPLORE_VIEW_MODE_SUBTREE = 'subtree'
export const EXPLORE_VIEW_MODE_PREVIEW_SUBTREE = 'preview-subtree'

const initialState = {
  exploredIndividualId: undefined,
  clickedIndividualId: undefined,
  chosenFamilyId: undefined,
  exploreViewMode: EXPLORE_VIEW_MODE_INDIVIDUAL,
  hoveredIndividualId: undefined,
  editedIndividualId: undefined,
  addIndividualProperties: undefined,

  arbitraryVisibleIndividualIds: new Set(),
  selectedIndividualIdsSet: new Set(),
}

/* note this has same action name as the non public - just using a different fetch */
export const setPublicExploredIndividualId = createWrappedAsyncThunk(
  `${SLICE_NAME}/setExploredIndividualId`,
  async (individualId, { dispatch, getState }) => {
    const treeSlug = getState().public?.tree?.slug

    await dispatch(
      fetchPublicIndividualsForTarget({
        treeSlug,
        target: individualId,
      })
    )

    return individualId
  }
)

export const setExploredIndividualId = createWrappedAsyncThunk(
  `${SLICE_NAME}/setExploredIndividualId`,
  async (individualId, { dispatch, getState }) => {
    const treeSlug = getState().auth.authorisedTreeSlug

    await dispatch(
      fetchIndividualsForTarget({
        treeSlug,
        target: individualId,
      })
    )

    return individualId
  }
)

/* note this has same action name as the non public - just using a different fetch */
export const choosePublicFamilyId = createWrappedAsyncThunk(
  `${SLICE_NAME}/chooseFamilyId`,
  async (familyId, { dispatch, getState }) => {
    const treeSlug = getState().public?.tree?.slug
    const sharedByIndividual = getState().public?.tree?.sharedByIndividual

    await dispatch(
      fetchPublicIndividualsForLineage({
        treeSlug,
        target: sharedByIndividual?.id,
        familyId: familyId,
      })
    )

    return familyId
  }
)

export const chooseFamilyId = createWrappedAsyncThunk(
  `${SLICE_NAME}/chooseFamilyId`,
  async (familyId, { dispatch, getState }) => {
    const treeSlug = getState().auth.authorisedTreeSlug
    const homeIndividual = getState().auth.user?.homeIndividual

    await dispatch(
      fetchIndividualsForLineage({
        treeSlug,
        target: homeIndividual?.id,
        familyId: familyId,
      })
    )

    return familyId
  }
)

export const exploreTreeSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetExploreTreeState: state => {
      return initialState
    },
    clearChosenFamilyId: (state, action) => {
      state.chosenFamilyId = undefined
      state.exploredIndividualId = undefined
    },
    clearExploredNode: state => {
      state.exploreViewMode = undefined
      state.exploredIndividualId = undefined
      state.exploreViewMode = EXPLORE_VIEW_MODE_INDIVIDUAL
    },
    setClickedIndividualId: (state, action) => {
      state.clickedIndividualId = action.payload
    },
    clearClickedIndividualId: state => {
      state.clickedIndividualId = undefined
    },
    setExploreViewMode: (state, action) => {
      state.exploreViewMode = action.payload
    },
    setHoveredIndividualId: (state, action) => {
      state.hoveredIndividualId = action.payload
    },

    setEditedIndividualId: (state, action) => {
      state.clickedIndividualId = undefined
      state.editedIndividualId = action.payload
    },
    clearEditedIndividualId: (state, action) => {
      state.editedIndividualId = undefined
    },
    setAddIndividualNode: (state, action) => {
      state.addIndividualProperties = action.payload
    },
    clearAddIndividualNode: state => {
      state.addIndividualProperties = undefined
    },

    clearSelectedIndividualIds: (state, action) => {
      state.selectedIndividualIdsSet = new Set()
    },
    removeIdFromSelectedIndividualIds: (state, action) => {
      const deselectedNodeId = action.payload
      state.selectedIndividualIdsSet = new Set(
        Array.from(state.selectedIndividualIdsSet).filter(
          id => id !== deselectedNodeId
        )
      )
    },
    addIdToSelectedIndividualIds: (state, action) => {
      state.selectedIndividualIdsSet.add(action.payload)
    },
    setArbitraryVisibleIndividualIds: (state, action) => {
      state.clickedIndividualId = undefined
      state.arbitraryVisibleIndividualIds = new Set(Array.from(action.payload))
    },
    clearArbitraryVisibleIndividualIds: state => {
      state.clickedIndividualId = undefined
      state.arbitraryVisibleIndividualIds = new Set()
    },
  },
  extraReducers: {
    [createSubTree.fulfilled]: state => {
      state.selectedIndividualIdsSet = new Set()
    },
    [fetchFamilies.fulfilled]: (state, { payload }) => {
      state.chosenFamilyId = undefined
    },
    [setExploredIndividualId.fulfilled]: (state, { payload }) => {
      state.chosenFamilyId = undefined
      state.clickedIndividualId = undefined
      state.exploredIndividualId = payload
      state.exploreViewMode = EXPLORE_VIEW_MODE_INDIVIDUAL
    },
    [chooseFamilyId.fulfilled]: (state, { payload }) => {
      state.chosenFamilyId = payload
      state.clickedIndividualId = undefined
      state.exploreViewMode = EXPLORE_VIEW_MODE_FAMILY
    },
  },
})

export const {
  clearChosenFamilyId,
  clearExploredNode,
  setClickedIndividualId,
  setExploreViewMode,
  clearClickedIndividualId,
  setHoveredIndividualId,
  clearSelectedIndividualIds,
  clearArbitraryVisibleIndividualIds,
  removeIdFromSelectedIndividualIds,
  addIdToSelectedIndividualIds,
  setAddIndividualNode,
  clearAddIndividualNode,
  setEditedIndividualId,
  clearEditedIndividualId,
  setArbitraryVisibleIndividualIds,
  resetExploreTreeState,
} = exploreTreeSlice.actions

export const selectAddIndividualNode = state =>
  state[SLICE_NAME].addIndividualProperties
export const selectEditedIndividualId = state =>
  state[SLICE_NAME].editedIndividualId
export const selectClickedIndividualId = state =>
  state[SLICE_NAME].clickedIndividualId
export const selectHoveredIndividualId = state =>
  state[SLICE_NAME].hoveredIndividualId
export const selectExploredIndividualId = state =>
  state[SLICE_NAME].exploredIndividualId
export const selectChosenFamilyId = state => state[SLICE_NAME].chosenFamilyId
export const selectExploreViewMode = state => state[SLICE_NAME].exploreViewMode

export const selectSelectedIndividualIds = state =>
  state[SLICE_NAME].selectedIndividualIdsSet
export const selectArbitraryVisibleIndividualIds = state =>
  state[SLICE_NAME].arbitraryVisibleIndividualIds

export default exploreTreeSlice.reducer
