import React, { useState } from 'react'
import {
  Box,
  Chip,
  Dialog,
  FormHelperText,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { useField } from 'formik'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { IconButton } from 'src/modules/ui'
import SearchLinkType, {
  useSearchLinkTypes,
} from 'src/modules/ui/editor/SearchLinkType'
import { ACTION_ALL_ACCESS } from '../app/appConstants'

const FormikLinkField = props => {
  const [{ value }, meta, { setTouched, setValue }] = useField(props)
  const { touched, error } = meta
  const hasError = Boolean(touched && error)

  const [modalOpen, setModalOpen] = React.useState(false)

  const handleOpenTagList = () => setModalOpen(true)
  const handleCloseTagList = () => setModalOpen(false)

  const handleSelectLink = ({ id, ...link }) => {
    setModalOpen(false)
    setTouched(true)
    if (!value.find(v => v.target === id)) {
      setValue([...value, { target: id, ...link }])
    }
  }

  const handleDelete = target => {
    const newValue = value.filter(link => link.target !== target)
    setTouched(true)
    setValue(newValue)
  }

  return (
    <div>
      <Typography gutterBottom>Related to:</Typography>
      <Box sx={{ m: -0.5 }}>
        {value.map(link => (
          <Chip
            label={link.display}
            onDelete={() => handleDelete(link.target)}
            sx={{ m: 0.5 }}
            key={link.target}
          />
        ))}
        <IconButton
          permissionAction={ACTION_ALL_ACCESS}
          onClick={handleOpenTagList}
          color="primary"
          sx={{ my: 0.5 }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      {hasError && <FormHelperText error>{error}</FormHelperText>}
      <Dialog hideBackdrop open={modalOpen} onClose={handleCloseTagList}>
        <TagList onSelectLink={handleSelectLink} />
      </Dialog>
    </div>
  )
}

const TagList = ({ onSelectLink }) => {
  const linkTypes = useSearchLinkTypes()
  const [type, setType] = useState()
  const linkType = linkTypes.find(t => t.id === type)

  const handleBack = () => setType()

  const handleSelectType = (e, type) => {
    setType(type.id)
    if (type.fetchData) {
      type.fetchData('')
    }
  }

  const handleSearch = value => {
    if (linkType && linkType.fetchData) {
      linkType.fetchData(value)
    }
  }

  const handleSelectLink = link => {
    onSelectLink(link)
    setType()
  }

  return (
    <Box sx={{ minWidth: 300 }}>
      {!type ? (
        <List>
          {linkTypes.map(type => (
            <ListItem
              button
              onClick={e => handleSelectType(e, type)}
              key={type.id}
            >
              <ListItemText primary={type.label} />
              <ListItemSecondaryAction>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <SearchLinkType
          linkType={linkType}
          onClickBack={handleBack}
          onSearch={handleSearch}
          onSelectLink={handleSelectLink}
        />
      )}
    </Box>
  )
}

export default FormikLinkField
