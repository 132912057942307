import React from 'react'
import { Box } from '@mui/material'

import ContentPhotos from 'src/modules/content/ContentPhotos'
import TextBlock from '../content/TextBlock'

const InformationRequestBody = ({ className, contentBlocks }) => {
  const blockMap = {
    TEXT: TextBlock,
    MEDIA_ROW: ContentPhotos,
  }

  const allArticlePhotos = contentBlocks.reduce((allPhotos, { media = [] }) => {
    return [...allPhotos, ...media]
  }, [])

  const orderedBlocks = [...contentBlocks].sort((a, b) => {
    return a.media.length - b.media.length
  })

  return (
    <div className={className}>
      {orderedBlocks.map((block, idx) => {
        const Block = blockMap[block.type]
        return (
          <Box sx={{ py: 1 }} key={idx}>
            <Block allArticlePhotos={allArticlePhotos} {...block} />
          </Box>
        )
      })}
    </div>
  )
}

export default InformationRequestBody
